/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SignatoryType {
    PreventionAdvisor = 'PreventionAdvisor',
    HierarchicalLine = 'HierarchicalLine',
    Other = 'Other'
}

export function SignatoryTypeFromJSON(json: any): SignatoryType {
    return SignatoryTypeFromJSONTyped(json, false);
}

export function SignatoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignatoryType {
    return json as SignatoryType;
}

export function SignatoryTypeToJSON(value?: SignatoryType | null): any {
    return value as any;
}

