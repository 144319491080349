import arxs from 'infra/arxs';
import React from 'react';
import Error from './Error';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, id: arxs.uuid.generate() };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    arxs.logger.error(`{error} {stack} - {correlationId}`, error, errorInfo.componentStack, this.state.id);
  }

  render() {
    if (this.state.hasError) {
      const props = { title: "Oeps... er ging iets mis.", message: `De achterliggende fout werd gelogd met referentie ${this.state.id}, u kan deze gebruiken in eventuele communicatie.` };
      return <Error {...props} />;
    }

    return this.props.children;
  }
}