import React, { Component } from "react";
import SignaturePad from "react-signature-pad-wrapper"

import arxs from "infra/arxs";
import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import Toaster from 'components/util/Toaster';

import "./SignatureEditor.scss";
import { ObjectDocumentType } from "infra/api/contracts";

export const createSignatureEditor = (userName, onSubmit) => {
    const state = {
        title: arxs.t("controls.signature_editor.title"),
        content: <SignatureEditor userName={userName} onSubmit={onSubmit} />,
        fitContentWidth: true,
        fitContentHeight: true,
        canMaximize: false,
    };
    return state;
};

class SignatureEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    onSubmit = () => {
        if (this.editor) {
            if (this.editor.isEmpty()) {
                Toaster.error(arxs.t("controls.signature_editor.empty"));
            } else {
                fetch(this.editor.toDataURL("image/jpeg"))
                    .then(res => res.blob())
                    .then(blob => this.saveImage(blob));
            }
        }
    }

    onClear = () => {
        this.editor && this.editor.instance.clear();
    }

    saveImage = (blob) => {
        const toBlobTagsString = (tags) => {
            if (tags === undefined) {
              return undefined;
            }
        
            const tagPairs = [];
            for (const key in tags) {
              if (Object.prototype.hasOwnProperty.call(tags, key)) {
                const value = tags[key];
                tagPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
              }
            }
        
            return tagPairs.join("&");
          }

        const { userName, onSubmit } = this.props;
        arxs.ApiClient.shared.blob.getPutAuthorization(`${userName}_signature`, ObjectDocumentType.Image)
            .then(putAuthorizationUrl => {
                let hash;

                const tags = { correlationKey: new Date().format_yyyyMMddhhmmsshhh() };

                const headers = {
                    "x-ms-date": new Date().toISOString(),
                    "x-ms-version": "2019-12-12",
                    "x-ms-blob-type": "BlockBlob",
                    'x-ms-tags': toBlobTagsString(tags)
                };

                var ajaxRequest = new XMLHttpRequest();

               

                ajaxRequest.onreadystatechange = function (aEvt) {
                    if (ajaxRequest.readyState === 4) {
                        hash = ajaxRequest.getResponseHeader("Content-MD5");

                        const image = {
                            url: putAuthorizationUrl,
                            hash: hash,
                            type: ObjectDocumentType.Image,
                            name: `${userName}_signature`,
                            contentType: "image/jpeg",
                            isPreferred: true
                        };

                        onSubmit(image);
                    }
                };

                ajaxRequest.open("PUT", putAuthorizationUrl, true);
                ajaxRequest.setRequestHeader("Content-Type", "image/jpeg");

                for (const key of Object.keys(headers)) {
                    ajaxRequest.setRequestHeader(key, headers[key]);
                }

                ajaxRequest.send(blob);
            });
    }

    setEditorRef = (editor) => (this.editor = editor)

    render() {
        return <div className="signature-editor">
            <div className="signature-editor-signature">
                <SignaturePad
                    ref={this.setEditorRef}
                    options={{ minWidth: 1, maxWidth: 5, penColor: "rgb(0, 10, 26)", backgroundColor: "rgb(255, 255, 255)" }}
                    redrawOnResize={true}
                />
            </div>
            <div className="signature-editor-controls">
                <div className="signature-editor-controls-clear" title={arxs.t("controls.signature_editor.clear")} onClick={this.onClear}>
                    <i className="far fa-trash-alt"></i>
                </div>
            </div>
            <HorizontalSeparator />
            <div className="signature-editor-submit" onClick={this.onSubmit}>
                {arxs.t("controls.signature_editor.submit")}
            </div>
        </div>
    }
}
export default SignatureEditor;