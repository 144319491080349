import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import { PlanningMoment, RelationshipType, Relationship } from "infra/api/contracts";

import { CardClassProps } from "./CardContracts";

function getValue(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref.name
    ? ref.name
    : Object.keys(ref).some((x) => x)
    ? ref
    : undefined;
}

function padLeadingZeros(num: number, size: number) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

function timeDiff(date1: Date, date2: Date) {
  let diff = date2.getTime() - date1.getTime();

  let msec = diff;
  let hh = Math.floor(msec / 1000 / 60 / 60);
  msec -= hh * 1000 * 60 * 60;
  let mm = Math.floor(msec / 1000 / 60);

  return `${hh}u${padLeadingZeros(mm, 2)}`;
}

export default function CardPlanning(props: CardClassProps) {
  const initialLookups = {
    planningMomentsBySubjectMap: {},
  };

  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  const { card } = props;

  const planningMomentKey: any = `${card.module}:${card.id}`;
  const planningMoments = getValue(lookups.planningMomentsBySubjectMap, { id: planningMomentKey }) || [];
  const planningMoment = planningMoments.filter((x: PlanningMoment) => !x.isDeleted)[0];
  const planningStart = planningMoment && planningMoment.start
    && `${arxs.dateTime.formatDate(planningMoment.start)} ${arxs.dateTime.formatTime(planningMoment.start)}`;
  const planningDuration = planningMoment && planningMoment.start && planningMoment.end
    && timeDiff(new Date(planningMoment.start), new Date(planningMoment.end));

  return <>
    {card.targetDate && (
        <div>{arxs.dateTime.formatDate(card.targetDate)}</div>
    )}
    {planningStart && (
        <div>
            <i className="far fa-clock"></i> {planningStart}
        </div>
    )}
    {planningDuration && (
        <div>
            <i className="far fa-stopwatch"></i> {planningDuration}
        </div>
    )}
    <div>
    <i className="far fa-user"></i>{" "}
    {card.numberOfAssignees ||
        (card.relationships || []).filter((x: Relationship) => x.type === RelationshipType.Assignee).length}
    </div>
    {/* <PlanningProgress subject={card} /> */}
  </>;
}