import { ArXsState } from 'infra/arxs';
import ModuleMetadata, { BoardViewType } from 'modules/ModuleMetadata'
import TrustBoard from './TrustBoard';
import TrustWizard from './TrustWizard';

const getTrustMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = "Trust";

  return {
    module,
    title: arxs.t("modules.trust"),
    icon: "far fa-user",
    path: "/trust",
    base: {
      name: "Trust",
      route: "/trust",
      readAction: "Trust.Read",
      writeAction: "Trust.Write",
      getResource: () => arxs.Api.getResource("trust"),
      subscribe: (id, module, handler) => arxs.Api.lookups.subscribeToId(module, id, handler),
    },
    detailspane: [
      {
        title: arxs.t("details_pane.employee_information"),
        initiallyOpen: true,
        fields: [
          { name: "trusteeTenant" },
          { name: "ownershipMode" },
          { name: "legalStructureScopes" },
          { name: "branchScopes" },
          { name: "roleScopes" },
        ]
      },
    ],
    allowedDocumentTypes: [],
    statuses: [],
    actions: [],
    wizard: {
      wizardClass: TrustWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              { name: "trusteeTenant" },
              { name: "ownershipMode" },
            ],
            [
              { name: "legalStructureScopes", type: "cardlist", props: { modules: ["SchoolGroup"] } },
              { name: "branchScopes", type: "cardlist", props: { modules: ["School"] } },
              { name: "roleScopes", type: "cardlist", props: { modules: ["UserRole"] } },
            ],
          ]
        }
      ]
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        userRoleMap: {},
        trusts: [],
      },
      gridColums:
        [
          { name: "trusteeTenant" },
          { name: "ownershipMode" },
          { name: "legalStructureScopes" },
          { name: "branchScopes" },
          { name: "roleScopes" },
        ],
      boardClass: TrustBoard,
      views: [BoardViewType.Grid],
    }
  };
}
export default getTrustMetaData;
