import React, { Component } from 'react';
import { Prompt } from 'react-router-dom'
import Collapsible from 'react-collapsible';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';

import Toaster from 'components/util/Toaster';
import Field from 'components/controls/Field';
import Button from 'components/controls/Button';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import ItemList from 'components/controls/ItemList';

import './MiniGridSettings.scss';

export default class MiniGridSettings extends Component {
  constructor(props) {
    super(props);

    this.getRequiredFields = this.getRequiredFields.bind(this);
    this.validate = this.validate.bind(this);

    const module = this.props.module;
    const metadata = arxs.moduleMetadataRegistry.get(module);

    const injectedState = (this.props.location || {}).state;

    this.state = {
      stepIndex: 0,
      steps: [],
      pristine: this.props.pristine || { tags: [], images: [], documents: [] },
      data: { ...this.props.data, ...injectedState },
      validation: {},
      metadata,
      isDirty: false
    };
  }

  getLookups() {
    return {};
  }

  deserialize(from) {
    return {
      ...from,
    };
  }

  serialize(from) {
    return {
      ...from,
    };
  }

  componentDidMount() {
    this.setState({
      steps: this.props.steps.map(x => ({ ...x, ref: React.createRef() })),
    });

    const resource = this.state.metadata.settings.getResource();

    resource.get()
      .then(pristine => {
        const mapped = this.deserialize(pristine);
        this.setState({ pristine: mapped });
      });

    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.getLookups(), lookups => this.setState({ ...lookups }))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  scrollTo = (ref) => {
    ref.current.innerRef.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
  }

  navigateToStep = (stepIndex) => {
    this.setState({ stepIndex }, () => this.scrollTo(this.state.steps[stepIndex].ref));
  }

  setField(definition, fieldName, value) {
    const data = { ...this.state.data, [fieldName]: value };
    this.setData(data, () => {
      if (definition.onChange) {
        definition.onChange(fieldName);
      }
    });
  }

  navigateToHome = () => window.location.assign(this.state.metadata.base.route);

  handleCancel = () => {
    this.navigateToHome();
  }

  handleSave = () => {
    const payload = this.serialize({ ...this.state.pristine, ...this.state.data });

    if (this.validateImpl()) {
      const resource = this.state.metadata.settings.getResource();
      resource.post(payload)
        .then(response => {
          switch (response.status) {
            case 200:
              response.json().then(() => {
                this.setData(null, () => {
                  Toaster.success(arxs.t("wizard.save_successful"));

                  window.onbeforeunload = null;
                });
              });
              break;
            case 422:
              response.json().then(this.handleValidationErrors);
              break;
            default:
              arxs.logger.info("Unhandled response from Settings.handleSave()", response);
          }
        });
    }
  }

  getRequiredFields(getCurrentFieldValue) {
    return [];
  }

  validate(getCurrentFieldValue, preValidation) {
    return preValidation;
  }

  validateImpl = () => {
    const schemaName = this.state.metadata.settings.name;
    const schema = arxs.Api.getSchema(schemaName) || {};

    const { data, pristine } = this.state;
    const getCurrentFieldValue = fieldName => data[fieldName] === undefined ? pristine[fieldName] : data[fieldName];
    const requiredFields = this.getRequiredFields(getCurrentFieldValue);

    let validation = {};
    for (const [key] of Object.entries(schema.properties)) {
      const value = getCurrentFieldValue(key);
      const required = (schema.required && schema.required.indexOf(key) > -1) || (requiredFields.indexOf(key) > -1);
      const fieldSchema = schema.properties[key];
      const isArray = fieldSchema.type === "array";
      if ((!value || (isArray && value.length === 0)) && required) {
        validation[key] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.${key}`) }) };
      }
    }

    validation = this.validate(getCurrentFieldValue, validation);

    this.setState({ validation });

    return Object.keys(validation).length === 0;
  }

  handleValidationErrors = (validationErrors) => {
    console.log(validationErrors);
  }

  setData = (data, callback) => {
    if (!data) {
      this.setState({ isDirty: false }, callback);
      window.onbeforeunload = null;
      return;
    }

    data = data || {};
    const isDirty = Object.keys(data).length > 0;
    this.setState({ data, isDirty }, callback);
    window.onbeforeunload = isDirty ? () => true : null;
  }

  render() {
    const schemaName = this.state.metadata.settings.name;
    const schema = arxs.Api.getSchema(schemaName) || {};
    const properties = schema.properties || {};

    const securityContext = arxs.securityContext.buildForUserContext();

    const { data, pristine } = this.state;

    const getCurrentFieldValue = fieldName => data[fieldName] === undefined ? pristine[fieldName] : data[fieldName];
    const getField = (definition) => {
      const isFieldName = (typeof definition === "string");
      const fieldName = isFieldName ? definition : definition.name;
      const parentName = isFieldName ? null : definition.parent;
      const field = properties[fieldName];

      if (!field) {
        arxs.logger.info("Field {fieldName} not found in {module}", fieldName, module);
        return { name: fieldName };
      }

      const getter = () => getCurrentFieldValue(fieldName);
      const setter = value => {
        this.setField(definition, fieldName, value);
      };
      const parentGetter = parentName ? () => getCurrentFieldValue(parentName) : null;

      const required = schema.required && schema.required.indexOf(fieldName) > -1;
      const validation = this.state.validation[fieldName];
      const values = definition.values;
      const title = definition.title;
      const unit = definition.unit;
      const getSecurityContext = () => definition.getSecurityContext(getCurrentFieldValue);

      const code = typeof(definition.code) === "function" ? definition.code(this.stateProxy) : definition.code;
      const typeOverride = definition.type;

      return {
        name: fieldName,
        title,
        schema: field,
        code,
        readOnly: this.props.readOnly,
        required,
        unit,
        getter,
        setter,
        parentGetter,
        validation,
        values,
        securityContext,
        getSecurityContext,
        typeOverride,
      };
    };

    const splitIntoFieldPerRowOnMobile = (context, row) => {
      let rows = [row];
      if (context.platform.isMobile) {
        rows = [];
        for (const field of row) {
          rows.push([field]);
        }
      }
      return rows;
    };

    const mapToDefinition = (fieldNameOrDefinition) => {
      return (typeof fieldNameOrDefinition === "string") ? { name: fieldNameOrDefinition } : fieldNameOrDefinition;
    };

    const renderField = (context, def, stepIndex, rowIndex, fieldIndex) => {
      const key = `step-${stepIndex}-${def.name}`;

      if (def.label) {
        return <label key={key}>{def.label}</label>;
      } else if (def.type === "itemlist") {
        const setChildField = (child, fieldName, value) => {
          const items = getCurrentFieldValue(def.name) || [];
          const index = items.indexOf(child);
          if (index > -1) {
            child[fieldName] = value;
            items[index] = child;
          }
          const data = { ...this.state.data, [def.name]: items };
          this.setData(data, () => {
            if (def.onChange) {
              def.onChange(def.name);
            }
          });
        };

        return <ItemList
          key={key}
          field={getField(def)}
          setField={setChildField}
          names={def.children}
          readOnly={this.props.readOnly}
          securityContext={securityContext}
          moduleSettings={true}
        />
      }
      return <Field key={key} field={getField(def)} noHeader={def.noHeaders} />;
    }

    return <GlobalContext.Consumer>
      {(context) => <div className="minigrid-settings">
        <div className="minigrid-settings-body">
          {Object.keys(this.state.validation).length > 0 && <ul className="validation-summary">
            {Object.entries(this.state.validation).map(([_, value], i) => <li key={`validation-${i}`}>{value.error}</li>)}
          </ul>}

          <div className="steps">
            {this.state.steps
              .map((step, stepIndex) => (
                <Collapsible
                  key={`step-${stepIndex}`}
                  trigger={<div className="header">
                    <div className="circle">{stepIndex + 1}</div>
                    <div className="title">{step.title}</div>
                  </div>}
                  ref={step.ref}
                  className="step"
                  openedClassName="step"
                  open={true}
                >
                  <div className="body">
                    {(step.fields || [])
                      .flatMap((row) => splitIntoFieldPerRowOnMobile(context, row))
                      .map((row, rowIndex) => <div className="row" key={`step-${stepIndex}-row-${rowIndex}`}>
                        {row.length === 0
                          ? (<HorizontalSeparator />)
                          : row
                            .map(mapToDefinition)
                            .map((def, fieldIndex) => renderField(context, def, stepIndex, rowIndex, fieldIndex))}
                      </div>)}
                  </div>
                </Collapsible>))}
          </div>

          {!this.props.readOnly && <div className="minigrid-settings-buttons">
            <Button className="minigrid-settings-button minigrid-settings-cancel" onClick={this.handleCancel} title={arxs.t("wizard.cancel")}>
              <i className="fad fa-undo-alt"></i>
            </Button>
            <Button className="minigrid-settings-button minigrid-settings-save icon" onClick={this.handleSave} title={arxs.t("wizard.save")}>
              <i className="fad fa-save"></i>
            </Button>
          </div>}
        </div>
        <Prompt
          when={this.state.isDirty}
          message={arxs.t("common.confirm_navigation")} />
      </div>}
    </GlobalContext.Consumer>;
  }
}
