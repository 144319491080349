import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import GlobalActions from "modules/GlobalActions";
import ClassicActions from "modules/ClassicActions";
import { IncidentV2Status, OriginModuleEnum } from "infra/api/contracts";

class IncidentActions {
  getActions = (module) => {
    return GlobalActions.getActions(module)
      .concat(ClassicActions.getActions(module))
      .concat([
        {
          name: "complete",
          module: module,
          icon: arxs.actions.icons["complete"],
          getTitle: () => arxs.actions.titles["complete"],
          onClick: (state) => this.complete(state),
        },
        {
          name: "to_investigate",
          module: module,
          icon: arxs.actions.icons["to_investigate"],
          getTitle: () => arxs.actions.titles["to_investigate"],
          onClick: (state) => this.toInvestigate(state),
        },
      ]);
  };

  getGlobalActions(module) {
    let globalActions = [{
      name: "create_incident",
      module: module,
      icon: arxs.actions.icons["create_incident"],
      getTitle: () => arxs.actions.titles["create_incident"],
      onClick: (state) => this.createIncident(state, module),
      singleSelectionOnly: true
    }];
    return globalActions;
  }

  allowedSubjectModules = [
    OriginModuleEnum.SchoolGroup,
    OriginModuleEnum.School,
  ]

  createIncident(state, module) {
    const { cards, history } = state;
    if (cards.length === 1) {
      const card = cards[0];
      const status = IncidentV2Status.Pending;

      let context;

      if (this.allowedSubjectModules.contains(module)) {
        context = { id: card.id, module: card.module };
      } else {
        arxs.logger.error(`Trying to create incident for unsupported module '${module}'`);
        return;
      }

      history.push({ pathname: "/incident/create", state: { context, status } });
    }
    return new Promise(() => { });
  }

  complete = (state) => {
    const ids = state["ids"] || [state.item.objectId];
    return arxs.ApiClient.safety.incident
      .complete(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.incident.complete_confirmation"))
      );
  };

  toInvestigate = (state) => {
    const ids = state["ids"] || [state.item.objectId];
    return arxs.ApiClient.safety.incident
      .toInvestigate(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.incident.to_investigate_confirmation"))
      );
  };

  dropActions = () => {
    return {
      onDropInToInvestigate: (state) => {
        this.toInvestigate(state);
      },
      onDropInCompleted: (state) => {
        this.complete(state);
      },
    };
  };
}
export default new IncidentActions();
