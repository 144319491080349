import React from "react";
import { createRiskValueSelectionPopup } from "components/controls/riskAnalysis/RiskValueSelectionPopup";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import RiskAnalysisValue from "components/controls/riskAnalysis/RiskAnalysisValue";

import "./RiskAnalysis.scss";

export default class RiskAnalysis extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "{}");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }
    
    setData = (state, context) => {
        this.setter(state);
        context.popup.close();
    }

    render() {
        const settings = this.getSettings();
        const value = this.getter();

        const openRiskMethodEditor = (context) => {
            const riskValueSelectionPopup = createRiskValueSelectionPopup(settings.method, value
                , (state) => this.setData(state, context)
                , context.popup.close);
            context.popup.show(riskValueSelectionPopup);
        }

        return (<GlobalContext.Consumer>
            {
                (context) => <div className={`form-control question risk ${this.props.required && "required"} ${this.props.requiredWithoutValue && "missing-value"}`}>
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field risk">
                        <RiskAnalysisValue value={value} readOnly={true} type={settings.method} />
                        {!this.props.readOnly && <div className="form-control-field-action" onClick={() => openRiskMethodEditor(context)}><i className="fas fa-balance-scale-right"></i></div>}
                    </div>
                    {this.renderFooter(context)}
                </div>}
        </GlobalContext.Consumer>)
    }
}