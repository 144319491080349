import React, { useState, useEffect, Fragment } from 'react';
import { RRule, rrulestr } from 'rrule';
import _ from 'lodash';

import arxs from 'infra/arxs';
import { usePrevious, useGatedInitialValue } from 'infra/tools/ReactExtensions';
import Field from 'components/controls/Field';
import RadioGroup from 'components/controls/radio/RadioGroup';

import './Scheduler.scss';

const maps = {
  recurrencePattern: toTwoWayMap({
    "None": "NONE",
    "Daily": "DAILY",
    "Weekly": "WEEKLY",
    "Monthly": "MONTHLY",
    "Yearly": "YEARLY"
  }),
  weekOfMonth: toTwoWayMap({
    "First": "1",
    "Second": "2",
    "Third": "3",
    "Fourth": "4",
    "Last": "-1",
  }),
  daysOfWeek: toTwoWayMap({
    "1": "MO",
    "2": "TU",
    "4": "WE",
    "8": "TH",
    "16": "FR",
    "32": "SA",
    "64": "SU",
  }),
  dayOfWeek: toTwoWayMap({
    "Monday": "MO",
    "Tuesday": "TU",
    "Wednesday": "WE",
    "Thursday": "TH",
    "Friday": "FR",
    "Saturday": "SA",
    "Sunday": "SU",
  }),
  monthOfYear: toTwoWayMap({
    "January": "1",
    "February": "2",
    "March": "3",
    "April": "4",
    "May": "5",
    "June": "6",
    "July": "7",
    "August": "8",
    "September": "9",
    "October": "10",
    "November": "11",
    "December": "12",
  })
};

function toTwoWayMap(serialize) {
  const deserialize = Object.keys(serialize)
    .reduce((acc, x) => ({ [serialize[x]]: x, ...acc }), {});

  return { serialize, deserialize };
}
function parseRules(rule) {
  return rule.split(";")
    .flatMap(x => {
      const tokens = x.split("=");
      return tokens.length === 2
        ? [{
          key: tokens[0],
          value: tokens[1],
        }]
        : [];
    })
    .reduce((acc, x) => ({ ...acc, [x.key.toUpperCase()]: x.value }), {});
}
function deserializeRecurrenceRule(data) {
  const recurrencePattern = maps.recurrencePattern.deserialize;
  const daysOfWeek = maps.daysOfWeek.deserialize;
  const dayOfWeek = maps.dayOfWeek.deserialize;
  const monthOfYear = maps.monthOfYear.deserialize;
  const weekOfMonth = maps.weekOfMonth.deserialize;
  const rules = parseRules(data.recurrenceRule);
  const props = {
    recurrencePattern: recurrencePattern[rules["FREQ"]],
  };

  const daysOfWeekRaw = rules["BYDAY"];
  const calculatedBySetPos = (daysOfWeekRaw || "").match(/-?\d+/g);
  const calculatedDaysOfWeek = (calculatedBySetPos && daysOfWeekRaw.substring(daysOfWeekRaw.indexOf(calculatedBySetPos) + calculatedBySetPos[0].length))
    || daysOfWeekRaw;
  const dayOfMonthValue = (Object.keys(data).some(x => x === "dayOfMonth") && data.dayOfMonth)
    || arxs.tryParseInt(rules["BYMONTHDAY"]);
  const dayOfWeekValue = (Object.keys(data).some(x => x === "dayOfWeek") && data.dayOfWeek)
    || dayOfWeek[calculatedDaysOfWeek];
  const weekOfMonthValue = (Object.keys(data).some(x => x === "weekOfMonth") && data.weekOfMonth)
    || weekOfMonth[calculatedBySetPos || rules["BYSETPOS"]];
  const monthOfYearValue = ((Object.keys(data).some(x => x === "monthOfYear") && data.monthOfYear)
    || ((rules["BYMONTH"] || "").split(",")).map(x => monthOfYear[x]));

  if (props.recurrencePattern === "None") {
    return props;
  } else {
    props.interval = arxs.tryParseInt(rules["INTERVAL"]) || 1;
  }

  if (props.recurrencePattern === "Weekly"
    && daysOfWeekRaw) {
    const daysOfWeekValue = daysOfWeekRaw
      .split(",")
      .reduce((acc, x) => parseInt(acc) + parseInt((daysOfWeek[x] || 0)), 0);
    return {
      ...props,
      daysOfWeek: daysOfWeekValue,
    };
  } else if (props.recurrencePattern === "Monthly") {
    if (dayOfMonthValue) {
      return {
        ...props,
        dayOfMonth: dayOfMonthValue,
        index: 0
      };
    } else if (dayOfWeekValue && weekOfMonthValue) {
      return {
        ...props,
        dayOfWeek: dayOfWeekValue,
        weekOfMonth: weekOfMonthValue,
        index: 1
      };
    }
  } else if (props.recurrencePattern === "Yearly") {
    if (monthOfYearValue && dayOfMonthValue) {
      return {
        ...props,
        monthOfYear: monthOfYearValue,
        dayOfMonth: dayOfMonthValue,
        index: 0
      };
    } else if (monthOfYearValue && dayOfWeekValue && weekOfMonthValue) {
      return {
        ...props,
        monthOfYear: monthOfYearValue,
        dayOfWeek: dayOfWeekValue,
        weekOfMonth: weekOfMonthValue,
        index: 1
      };
    }
  }

  return { ...props };
}
function getNumberOfOccurrences(data) {
  if (data && !data.endDate) {
    return undefined;
  }
  const occurrences = getFutureOccurrences(data);
  return occurrences.length > 0 ? occurrences.length : undefined;
}
// Before passing dates to RRule, convert it to UTC
// https://github.com/jakubroztocil/rrule/issues/556
export function getFutureOccurrences(data, amount) {
  if (data && data.recurrenceRule) {
    const options = RRule.parseString(data.recurrenceRule);
    if (options.freq !== RRule.None) {
      let dtstart = new Date();
      if (data.startDate) {
        let startDT = new Date(data.startDate);
        startDT.setHours(0,0,0,0); //Set midnight
        startDT.setDate(startDT.getDate() + 1); //move next day
        startDT.setSeconds(startDT.getSeconds() + 1);
        dtstart = startDT;
      }
      const tzOffset = dtstart.getTimezoneOffset();
      const until = data.endDate ? new Date(data.endDate) : undefined;
      const rule = new RRule({ ...options, dtstart, until });
      const takeUntilPredicate = amount > 0 ? (_, i) => i < amount + 1 : undefined;
      const dateFarInTheFuture = arxs.dateTime.addDays(new Date(), 365 * 21);
      const dates = rule.between(new Date(), dateFarInTheFuture, false, takeUntilPredicate)
        .map(x => arxs.dateTime.addMinutes(x, tzOffset))
        .slice(0, amount);
      return dates;
    }
  }
  return [];
}
function getEndDate(data) {
  if (data && data.numberOfOccurrences) {
    const recurrenceRule = serializeRecurrenceRule(data);
    if (data.recurrencePattern && data.recurrencePattern !== "None") {
      const options = RRule.parseString(recurrenceRule);
      let dtstart = new Date();
      if (data.startDate) {
        const startDate = new Date(data.startDate);
        if (dtstart < startDate) {
          dtstart = startDate;
        }
      }
      const rule = new RRule({ ...options, dtstart, count: data.numberOfOccurrences });
      const dates = rule.all();
      return (new Date(dates[dates.length - 1])).addSeconds(1);
    }
    return undefined;
  }
  return undefined;
}
function deserialize(data) {
  const props = deserializeRecurrenceRule(data);

  let newData = {
    ...data,
    startDate: data.start,
    endDate: data.end,
    ...props,
  }

  newData.numberOfOccurrences = getNumberOfOccurrences(newData);

  return newData;
}
function serializeRecurrenceRule(data) {
  // For more info:
  // - https://www.telerik.com/kendo-react-ui/components/scheduler/recurring/
  // - https://datatracker.ietf.org/doc/html/rfc5545

  const recurrencePattern = maps.recurrencePattern.serialize;
  const daysOfWeek = maps.daysOfWeek.serialize;
  const dayOfWeek = maps.dayOfWeek.serialize;
  const monthOfYear = maps.monthOfYear.serialize;
  const weekOfMonth = maps.weekOfMonth.serialize;

  let recurrenceRule = `FREQ=${recurrencePattern[data.recurrencePattern]}`;

  if (data.recurrencePattern !== "None") {
    recurrenceRule += `;INTERVAL=${data.interval || 1}`;
  }

  if (data.recurrencePattern === "Weekly") {
    const byday = Object.keys(daysOfWeek)
      .map(flag => parseInt(flag))
      .flatMap(flag => (data.daysOfWeek & flag) === flag ? [daysOfWeek[flag]] : [])
      .coalesce(["MO"])
      .join(",");
    recurrenceRule += `;BYDAY=${byday}`;
  } else if (data.recurrencePattern === "Monthly") {
    if (data.dayOfMonth) {
      recurrenceRule += `;BYMONTHDAY=${data.dayOfMonth}`;
    } else if (data.dayOfWeek && data.weekOfMonth) {
      recurrenceRule += `;BYDAY=${weekOfMonth[data.weekOfMonth]}${dayOfWeek[data.dayOfWeek]}`;//;BYSETPOS=${weekOfMonth[data.weekOfMonth]}`;
    }
  } else if (data.recurrencePattern === "Yearly") {
    if (data.monthOfYear && data.dayOfMonth) {
      recurrenceRule += `;BYMONTH=${data.monthOfYear.map(x => monthOfYear[x])};BYMONTHDAY=${data.dayOfMonth || 1}`;
    } else if (data.monthOfYear && data.dayOfWeek && data.weekOfMonth) {
      recurrenceRule += `;BYMONTH=${data.monthOfYear.map(x => monthOfYear[x])};BYDAY=${weekOfMonth[data.weekOfMonth]}${dayOfWeek[data.dayOfWeek]}`;//;BYSETPOS=${weekOfMonth[data.weekOfMonth]}`;
    }
  }

  return recurrenceRule;
}
function serialize(data) {
  if (!data) {
    return null;
  }

  return {
    ...data,
    id: data.id,
    start: data.startDate,
    end: data.endDate,
    recurrenceRule: serializeRecurrenceRule(data),
    recurrenceExceptions: [],
    notificationPattern: data.notificationPattern,
    notificationCount: data.notificationCount,
  };
}
function getInitialState(allowedPatterns) {
  const defaultPattern = (allowedPatterns || []).firstOrDefault() || "None";

  return {
    recurrencePattern: defaultPattern,
    interval: 1,
    startDate: new Date(),
    notificationPattern: "Week",
    notificationCount: 1
  };
}

export default function Scheduler(props) {
  const { onChange } = props;
  const schema = arxs.Api.getSchema("Schedule");

  const prevValue = usePrevious(props.value);

  const [data, setData] = useState(null);

  const getFieldValue = (fieldName) => data ? data[fieldName] : undefined;

  const setDataAndPersist = (newData) => {
    if (onChange) {
      setData(newData);
      onChange(serialize(newData));
    }
  };

  useEffect(() => {
    if (props.value
      && !_.isEqual(props.value, prevValue)
      && (!_.isEqual((prevValue || {}).recurrenceRule, props.value.recurrenceRule))
    ) {
      const deserialized = deserialize(props.value);
      setData(deserialized);
    }
  }, [props.value]);

  useGatedInitialValue(setDataAndPersist, props.isLoaded, props.value, getInitialState(props.allowedRecurrencePatternValues));

  const getField = (fieldName) => {
    let field = {
      name: fieldName,
      title: "",
      code: "",
      schema: schema.properties[fieldName],
      readOnly: props.readOnly,
      getter: () => getFieldValue(fieldName),
      setter: fieldValue => {
        if (onChange) {
          const newData = { ...data, [fieldName]: fieldValue };

          switch (fieldName) {
            case "recurrencePattern":
              newData.index = 0;
              break;
            case "dayOfMonth":
              newData.index = 0;
              break;
            case "weekOfMonth":
            case "dayOfWeek":
              newData.index = 1;
              break;
            case "numberOfOccurrences":
              newData.endDate = getEndDate(newData);
              break;
            case "endDate":
              newData.numberOfOccurrences = getNumberOfOccurrences(newData);
              break;
            default: break;
          };

          setDataAndPersist(newData);
        }
      }
    };

    if (fieldName === "recurrencePattern" && props.allowedRecurrencePatternValues) {
      const filterValues = (x) => { return props.allowedRecurrencePatternValues.includes(x) };
      field.filter = filterValues;
      field.props = { ...field.props, required: true };
    }

    if (fieldName === "notificationPattern") {
      if (props && props.notificationPattern && props.notificationPattern.title) {
        field.title = props.notificationPattern.title;
      }
    }

    switch (fieldName) {
      case "interval":
      case "dayOfMonth":
      case "notificationCount":
        field.width = "50px";
        break;
      case "weekOfMonth":
      case "dayOfWeek":
        field.width = "150px";
        break;
      default: break;
    }

    return field;
  };

  const onChangeRadio = (index) => {
    const newData = {
      ...data,
      index: index
    };

    switch (index) {
      case 0:
        newData.weekOfMonth = undefined;
        newData.dayOfWeek = undefined;
        break;
      case 1:
        newData.dayOfMonth = undefined;
        break;
      default: break;
    }

    setDataAndPersist(newData);
  }

  const recurrencePattern = getFieldValue("recurrencePattern");
  const isRecurring = recurrencePattern && recurrencePattern !== "None";

  if (data && props.readOnly) {
    const lang = {
      dayNames: [
        arxs.t("enums.DayOfWeek.Sunday"),
        arxs.t("enums.DayOfWeek.Monday"),
        arxs.t("enums.DayOfWeek.Tuesday"),
        arxs.t("enums.DayOfWeek.Wednesday"),
        arxs.t("enums.DayOfWeek.Thursday"),
        arxs.t("enums.DayOfWeek.Friday"),
        arxs.t("enums.DayOfWeek.Saturday"),
      ],
      monthNames: [
        arxs.t("enums.MonthOfYear.January"),
        arxs.t("enums.MonthOfYear.February"),
        arxs.t("enums.MonthOfYear.March"),
        arxs.t("enums.MonthOfYear.April"),
        arxs.t("enums.MonthOfYear.May"),
        arxs.t("enums.MonthOfYear.June"),
        arxs.t("enums.MonthOfYear.July"),
        arxs.t("enums.MonthOfYear.August"),
        arxs.t("enums.MonthOfYear.September"),
        arxs.t("enums.MonthOfYear.October"),
        arxs.t("enums.MonthOfYear.November"),
        arxs.t("enums.MonthOfYear.December")
      ],
    };

    if (!data) {
      return <></>;
    }
    const getText = (key) => { return arxs.t(`controls.scheduler.rrule_strings.${key}`) || key };
    const capitalize = (str) =>( str.length > 0 && str.charAt(0).toUpperCase() + str.substring(1)) || "";

    const recurrenceString = (data.recurrenceRule && data.recurrenceRule !== "FREQ=NONE" && rrulestr(data.recurrenceRule).toText(getText, lang)) || "";
    const startString = new Date(data.start || data.startDate).toLocaleDateString("nl-BE");
    const endString = data.end && new Date(data.end).toLocaleDateString("nl-BE");
    const periodString = !data.end
      ? arxs.t("controls.scheduler.period_from", { start: startString })
      : arxs.t("controls.scheduler.period_from_to", { from: startString, to: endString });

    const notificationPatterns = {
      "Day": data.notificationCount === 1 ? arxs.t("controls.scheduler.rrule_strings.day") : arxs.t("controls.scheduler.rrule_strings.days"),
      "Week": data.notificationCount === 1 ? arxs.t("controls.scheduler.rrule_strings.week") : arxs.t("controls.scheduler.rrule_strings.weeks"),
      "Month": data.notificationCount === 1 ? arxs.t("controls.scheduler.rrule_strings.month") : arxs.t("controls.scheduler.rrule_strings.months"),
    };

    const notificationString = arxs.t(
      "controls.scheduler.notification_on", {
      interval: data.notificationCount,
      daysWeeksMonths: notificationPatterns[data.notificationPattern]
    });

    const plannedOccurrences = getFutureOccurrences(data, 4);

    return <div>
      <div>{capitalize(recurrenceString)} {periodString}.</div>
      <div>{capitalize(notificationString)}.</div>
      <div>&nbsp;</div>
      <label>{arxs.t("controls.scheduler.next_planned_records")}</label>
      <span>{plannedOccurrences.map(x => arxs.dateTime.formatDate(x)).concat(["..."]).join(", ")}</span>
    </div>;
  }

  const toTitle = (pattern) => {
    switch (pattern) {
      case "Daily": return arxs.t("controls.scheduler.day(s)");
      case "Weekly": return arxs.t("controls.scheduler.week(s)");
      case "Monthly": return arxs.t("controls.scheduler.month(s)");
      case "Yearly": return arxs.t("controls.scheduler.year(s)");
      default: break;
    }
  };

  const plannedOccurrences = getFutureOccurrences(data, 4);

  return (
    <div className={`scheduler ${props.className || ""}`}>
      <Fragment>
        <div className="row">
          <Field field={getField("recurrencePattern")} required />
        </div>
        {isRecurring && <div className="row">
          <Fragment><div className="field-spacer">
            {arxs.t("controls.scheduler.every")}
          </div>
            <Field field={getField("interval")} noHeader style={{ flex: "none" }} />
            <div className="field-spacer">{toTitle(recurrencePattern)}
            </div>
          </Fragment>
        </div>}
        {recurrencePattern === "Weekly" && <div className="row">
          <Field field={getField("daysOfWeek")} className="scheduler-day-of-week" />
        </div>}
        {recurrencePattern === "Monthly" && <RadioGroup onChange={onChangeRadio} index={data.index} readOnly={props.readOnly}>
          {
            [({ readOnly }) => <div className="row">
              <div className="field-spacer">
                {arxs.t("controls.scheduler.on_day")}
              </div>
              <Field field={getField("dayOfMonth")} noHeader disabled={readOnly} />
            </div>,
            ({ readOnly }) => <div className="row">
              <div className="field-spacer">
                {arxs.t("controls.scheduler.on_the")}
              </div>
              <Field field={getField("weekOfMonth")} noHeader disabled={readOnly} style={{ width: "150px", flex: "none" }} />
              <Field field={getField("dayOfWeek")} noHeader disabled={readOnly} style={{ width: "150px", flex: "none" }} />
            </div>]
          }
        </RadioGroup>}
        {recurrencePattern === "Yearly" && <RadioGroup onChange={onChangeRadio} index={data.index} readOnly={props.readOnly}>
          {
            [
              ({ readOnly }) => <div className="row">
                <div className="field-spacer">
                  {arxs.t("controls.scheduler.on")}
                </div>
                <Field field={getField("dayOfMonth")} noHeader disabled={readOnly} style={{ flex: "none" }} />
                <Field field={getField("monthOfYear")} noHeader disabled={readOnly} style={{ width: "230px", flex: "none" }} />
              </div>,
              ({ readOnly }) => <div className="row">
                <div className="field-spacer">
                  {arxs.t("controls.scheduler.on_the")}
                </div>
                <Field field={getField("weekOfMonth")} noHeader disabled={readOnly} style={{ flex: "none" }} />
                <Field field={getField("dayOfWeek")} noHeader disabled={readOnly} style={{ flex: "none" }} />
                <div className="field-spacer">
                  {arxs.t("controls.scheduler.of")}
                </div>
                <Field field={getField("monthOfYear")} noHeader disabled={readOnly} style={{ width: "230px", flex: "none" }} />
              </div>
            ]
          }
        </RadioGroup>}
        {isRecurring && <div className="row">
          <Field field={getField("startDate")} style={{ flex: "none" }} />
          <Field field={getField("endDate")} style={{ flex: "none" }} />
          <div className="field-spacer">
            {arxs.t("controls.scheduler.orAfter")}
          </div>
          <Field field={getField("numberOfOccurrences")} style={{ flex: "none" }} />
        </div>}

        <div className="row">
          {isRecurring && <Fragment>
            <Field field={getField("notificationPattern")} style={{ flex: "none" }} />
            <Field field={getField("notificationCount")} />
          </Fragment>}
        </div>

        <div className="row">
          <div className="field full-width">
            <label>{arxs.t("controls.scheduler.next_planned_records")}</label>
            <span>{plannedOccurrences.map(x => arxs.dateTime.formatDate(x)).concat(["..."]).join(", ")}</span>
          </div>
        </div>
      </Fragment>
    </div>
  );
}