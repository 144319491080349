/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskRequestStatus {
    Draft = 'Draft',
    Pending = 'Pending',
    Accepted = 'Accepted',
    Refused = 'Refused',
    OnHold = 'OnHold',
    ExecutionStarted = 'ExecutionStarted',
    ExecutionOverdue = 'ExecutionOverdue',
    Completed = 'Completed'
}

export function TaskRequestStatusFromJSON(json: any): TaskRequestStatus {
    return TaskRequestStatusFromJSONTyped(json, false);
}

export function TaskRequestStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskRequestStatus {
    return json as TaskRequestStatus;
}

export function TaskRequestStatusToJSON(value?: TaskRequestStatus | null): any {
    return value as any;
}

