import React from "react";
import arxs from 'infra/arxs';
import { createRiskValueSelectionPopup } from "components/controls/riskAnalysis/RiskValueSelectionPopup";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import RiskAnalysisValue from "components/controls/riskAnalysis/RiskAnalysisValue";
import DropDown from "components/controls/DropDown";
import { TextArea } from "components/controls/TextArea";

import "./RiskMeasurement.scss";

export default class RiskMeasurement extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }

    renderMeasurement = (context, item, domains, phases, index, onItemChange) => {
        const { readOnly } = this.props;
        const settings = this.getSettings();
        const value = this.getter();

        const getTextValue = (field) => {
            return (item || {})[field] || "";
        }

        const onDropDownChange = (state, field) => {
            onFieldChange(state.id, field);
        }

        const onFieldChange = (state, field) => {
            let newItem = (value || [])[index];
            newItem[field] = state;
            (value || [])[index] = newItem;
            onItemChange(value);
        }

        const openRiskMethodEditor = (context, field, onChange) => {
            const riskValueSelectionPopup = createRiskValueSelectionPopup(settings.method, (item || {})[field], onChange, context.popup.close);
            context.popup.show(riskValueSelectionPopup);
        }

        const selectedDomain = (domains || []).filter(x => x.id === (item || {}).domain)[0];
        const selectedPhase = (phases || []).filter(x => x.id === (item || {}).phase)[0];

        return <li key={`risk-measurement-li-${item.id}`}>
            <div className="risk-measurement">
                <div className="risk-measurement-header">
                    <div className="risk-measurement-header-line">
                        <div className="risk-measurement-header-line-values">
                            <label>{arxs.t("forms.items.riskmeasurement.domain")}: </label>
                            {!readOnly && <DropDown
                                id={`domain-${item.id}`}
                                className="risk-measurement-domain"
                                items={domains}
                                readOnly={readOnly}
                                disableClearButton={true}
                                selected={selectedDomain}
                                onChange={(state) => onDropDownChange(state, "domain")}
                                placeholder={arxs.t("forms.items.riskmeasurement.domain")}
                            />}
                            {readOnly && <label>{(selectedDomain || {}).name}</label>}
                        </div>
                        <div className="risk-measurement-header-line-values">
                            <label>{arxs.t("forms.items.riskmeasurement.phase")}: </label>
                            {!readOnly && <DropDown
                                id={`phase-${item.id}`}
                                className="risk-measurement-phase"
                                items={phases}
                                readOnly={readOnly}
                                disableClearButton={true}
                                selected={selectedPhase}
                                onChange={(state) => onDropDownChange(state, "phase")}
                                placeholder={arxs.t("forms.items.riskmeasurement.phase")}
                            />}
                            {readOnly && <label>{(selectedPhase || {}).name}</label>}
                        </div>
                    </div>
                    <div className="risk-measurement-header-line">
                        <div className="risk-measurement-header-line-values">
                            <label>{arxs.t("forms.items.riskmeasurement.danger")}: </label>
                            <TextArea
                                readOnly={readOnly}
                                field={{ getter: () => getTextValue("danger"), setter: (state) => onFieldChange(state, "danger"), multiLine: false }}
                                placeholder={arxs.t("forms.items.riskmeasurement.danger")}
                            />
                        </div>
                    </div>
                </div>
                <div className="risk-measurement-value risk">
                    <div className="risk-measurement-value-header">
                        <label>{arxs.t("forms.items.riskmeasurement.risk_description")}: </label>

                    </div>
                    <div className="risk-measurement-value-value">
                        <TextArea
                            readOnly={readOnly}
                            field={{ getter: () => getTextValue("riskDescription"), setter: (state) => onFieldChange(state, "riskDescription"), multiLine: false }}
                            placeholder={arxs.t("forms.items.riskmeasurement.risk_description")}
                        />
                        <RiskAnalysisValue value={(item || {}).risk} readOnly={true} type={(settings || {}).method} includeRiskValue={true} />
                        {!readOnly && <div className="riskAnalysis-action" onClick={() => openRiskMethodEditor(context, "risk", (state) => onFieldChange(state, "risk"))}><i className="fas fa-balance-scale-right"></i></div>}
                    </div>
                </div>
                <div className="risk-measurement-value prevention">
                    <div className="risk-measurement-value-header">
                        <label>{arxs.t("forms.items.riskmeasurement.prevention_description")}: </label>
                    </div>
                    <div className="risk-measurement-value-value">
                        <TextArea
                            readOnly={readOnly}
                            field={{ getter: () => getTextValue("preventionDescription"), setter: (state) => onFieldChange(state, "preventionDescription"), multiLine: false }}
                            placeholder={arxs.t("forms.items.riskmeasurement.prevention_description")}
                        />
                        <RiskAnalysisValue value={(item || {}).prevention} readOnly={true} type={(settings || {}).method} includeRiskValue={true} />
                        {!readOnly && <div className="riskAnalysis-action" onClick={() => openRiskMethodEditor(context, "prevention", (state) => onFieldChange(state, "prevention"))}><i className="fas fa-balance-scale-right"></i></div>}
                    </div>
                </div>
            </div>
        </li >
    }

    renderMeasurements = (context) => {
        const { codeElements } = this.state;
        const value = this.getter();

        const phaseRoot = ((codeElements || {})["RiskAnalysis.Phase"]) || [];
        const phases = (phaseRoot[0] || {}).children || [];
        const domainRoot = ((codeElements || {})["RiskAnalysis.Domain"]) || [];
        const domains = (domainRoot[0] || {}).children || [];

        const onItemChange = (value) => {
            this.setter(value);
            context.popup.close();
        };

        return <div className="risk-measurements">
            <ul>
                {value.map((line, index) => this.renderMeasurement(context, line, domains, phases, index, onItemChange))}
            </ul>
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className={`form-control risk-measurement`}>
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {this.renderMeasurements(context)}
                    </div>
                    {this.renderFooter(context)}
                </div>}
        </GlobalContext.Consumer>)
    }
}