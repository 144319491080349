import React from "react";
import arxs from "infra/arxs";
import { CardProps } from "./CardContracts";
import UniqueNumber from "./UniqueNumber";
import {
  AttachmentInfo,
  ObjectDocumentType,
  LinkRef,
  OriginModuleEnum,
  LinkType,
} from "infra/api/contracts";

import "./CardSubtitle.scss";


export interface RenderFunction {
  (item: React.ReactNode): React.ReactNode;
}

export interface CardSubtitleProps {
  cardProps: CardProps;
  card: Card;
  render?: RenderFunction;
}

export interface Card {
  uniqueNumber: string;
  module: string;
  numberOfMessages: number;
  numberOfImages: number;
  numberOfDocuments: number;
  lastMessage: Date;
  tenantId: string;
  attachmentInfo?: AttachmentInfo;
  inboundLinks?: LinkRef[];
}

export const CardSubtitle: React.FC<CardSubtitleProps> = (props) => {
  const { card } = props;

  let render = props.render || ((children) => children);

  const userTenant = arxs.Identity.profile?.tenant;
  const isTrusted = card.tenantId && userTenant !== card.tenantId;
  const tenantTitle = (card.tenantId || "").replace("arxsbe", "");

  const imageCount =
    card.numberOfImages ||
    (
      (((card.attachmentInfo || {}).attachments || []).filter(
        (x) => x.type === ObjectDocumentType.Image
      ) || {})[0] || {}
    ).value?.filter((x) => !x.isDeleted).length || 0;
  const isTasklike = [
    OriginModuleEnum.Task,
    OriginModuleEnum.PeriodicMaintenance,
    OriginModuleEnum.PeriodicControl,
  ].contains(card.module);
  const hasPeriodicalOrigin = (card.inboundLinks || []).any(
    (x) =>
      x.type === LinkType.Origin && x.module === OriginModuleEnum.Periodical
  );

  return (
    <h3 className="list-card-subtitle">
      {render(
        <>
          {isTrusted && (
            <div className="list-card-trusted">
              <i className="far fa-handshake" title={tenantTitle}></i>
            </div>
          )}
          {isTasklike && hasPeriodicalOrigin && (
            <div className="list-card-periodical">
              <i className="far fa-repeat"></i>
            </div>
          )}
          <div className="list-card-uniquenumber"><UniqueNumber uniqueNumber={card.uniqueNumber} /></div>
          <div className="list-card-activity-count">
            <i className="fas fa-comment-alt"></i>
            {card.numberOfMessages}
          </div>
          {card.module && card.module !== OriginModuleEnum.Document && (
            <div className="list-card-image-count">
              <i className="fas fa-images"></i>
              {imageCount}
            </div>
          )}
          {/* <div className="list-card-document-count">
        <i className="fas fa-copy"></i>
        {card.numberOfDocuments}
      </div> */}
        </>
      )}
    </h3>
  );
};
