import React, { ReactNode } from "react";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardSignature from "components/card/CardSignature"
import CardActions from "components/card/CardActions";
import CardFormBadge from "components/card/CardFormBadge";
import CardLocation from "components/card/CardLocation";
import CardImage from "components/card/CardImage";
import CardInternalNumber from "components/card/CardInternalNumber";

export default function DecommissioningCard(props: CardClassProps) {
  const { card } = props;

  return (
    <>
      <div className="list-card-left">
        <CardImage {...props} />
      </div>
      <div className="list-card-right">
        <div className="list-card-header">
          <CardSignature {...props} />
        </div>
        <h2>
          <div className="list-card-title" title={card.title}>
            {card.title}
          </div>
          <CardActions {...props.actionProps} />
        </h2>
        <CardSubtitle
          cardProps={props.cardProps}
          card={card}
          render={(children: ReactNode) => (
            <>
              {children}
              <div className="list-card-document-count">
                <i className="fas fa-copy"></i>
                {card.numberOfDocuments}
              </div>
              <CardFormBadge card={card} />
            </>
          )}
        />
        <div className="list-card-details">
          <CardInternalNumber {...props} />
          <div>
            {[card.sort, card.kind, card.type].filter((x) => x).join(" - ")}
          </div>
          <CardLocation card={card} />
        </div>
      </div>
    </>
  );
}
