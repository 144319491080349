import React from "react";
import GlobalContext from "infra/GlobalContext";
import CardList from "components/controls/cardlist/CardList";
import FormItemController from "./FormItemController";

export default class Link extends FormItemController {
    getAdditionalLookups() {
        return {
            safetyInstructionCards: [],
        };
    }

    getter() {
        return this.deserialize((this.props.data || {}).value);
    }

    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue || []);
    }

    renderControl = (context) => {
        const { item, readOnly, card } = this.props;
        
        const value = this.getter();

        const definition = JSON.parse(item.data || "{}");
        const modules = definition.modules || [];
        const action = definition.action;

        const filter = {
            "subject": card.subject,
        };

        return <div className="link-container">
            <CardList
                className={this.props.className}
                securityContext={this.props.securityContext}
                readOnly={readOnly}
                modules={modules}
                value={value}
                prefilter={filter}
                onChange={(value) => this.setter(value)}
                required={false}
                singleSelection={true}
            />
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control images">
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {this.renderControl(context)}
                    </div>
                    {this.renderFooter(context)}
                </div>
            }
        </GlobalContext.Consumer>);
    }
}