import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import AssetActions from "modules/assetmanagement/AssetActions";
import ProtectionEquipmentBoard from "./ProtectionEquipmentBoard";
import ProtectionEquipmentWizard from "./ProtectionEquipmentWizard";
import {
  OriginModuleEnum,
  RelationshipType,
  StatusEnum,
  ObjectDocumentType,
} from "infra/api/contracts";
import AssetCard from "../AssetCard";

const getProtectionEquipmentMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Pbm;

  return {
    module,
    title: arxs.modules.titles[module],
    icon: arxs.modules.icons[module],
    path: "/protectionequipment",
    base: {
      name: "ProtectionEquipment",
      route: "/protectionequipment",
      getResource: () => arxs.Api.getResource("protectionequipment"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "ProtectionEquipment.Write",
      readAction: "ProtectionEquipment.Read",
    },
    settings: {
      name: "ProtectionEquipmentSettings",
      route: "/protectionequipment/settings",
      getImportResource: () => arxs.ApiClient.assets.protectionEquipmentSettings,
      readAction: "ProtectionEquipment.Settings.Write",
      writeAction: "ProtectionEquipment.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "internalNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
      {
        title: arxs.t("details_pane.details"),
        fields: [
          { name: "brand" },
          { name: "model" },
          { name: "purchaseDate" },
          { name: "purchasePrice" },
          { name: "productionDateTime" },
          { name: "depreciationPeriod" },
        ],
      },
      {
        title: arxs.t("details_pane.supplier_customer"),
        fields: [{ name: "supplier" }, { name: "contacts" }],
      },
      {
        title: arxs.t("details_pane.extra_specifications"),
        fields: [
          {
            name: "extraSpecifications",
            children: [{ name: "name" }, { name: "description" }],
          },
          {
            name: "parts",
            children: [{ name: "name" }, { name: "warrantyPeriod" }],
          },
        ],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Manual,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      StatusEnum.Active,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.Expired,
      StatusEnum.OutOfService,
    ],
    statusColorOverride: [
      { status: StatusEnum.Active, color: "status-completed" },
    ],
    actions: AssetActions.getActions(module),
    wizard: {
      wizardClass: ProtectionEquipmentWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  ProtectionEquipmentWizard.setScopeFields(
                    stateProxy,
                    "context"
                  ).then(() =>
                    ProtectionEquipmentWizard.applyProcessFlow(
                      stateProxy,
                      "context"
                    )
                  );
                },
                onLoad: (stateProxy) => {
                  ProtectionEquipmentWizard.getContext(stateProxy);
                },
                title: arxs.t("field.location"),
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "type",
                parent: "kind",
                grandParent: "sort",
                props: { code: "ProtectionEquipment.Sort" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  ProtectionEquipmentWizard.applyProcessFlow(
                    stateProxy,
                    "context"
                  ),
              },
            ],
            [{ name: "brand" }, { name: "model" }, { name: "serialNumber" }],
            [{ name: "internalNumber" }, { name: "class" }],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [{ name: "purchaseDate" }, { name: "purchasePrice" }],
            [
              { name: "productionDateTime" },
              {
                name: "depreciationPeriod",
                unit: arxs.t("unit.month").toLowerCase(),
              },
            ],
            [
              {
                name: "recommendation",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Consultancy],
                  singleSelection: true,
                },
                title: arxs.t("field.recommendation"),
                onChange: (stateProxy) => {
                  ProtectionEquipmentWizard.copyRecommendationData(stateProxy);
                },
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  setFilterOnField: "supplier",
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
            [
              {
                name: "scopes",
                type: "cardlist",
                title: arxs.t("field.scope"),
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                  ],
                  overridePrefilter: true,
                  expandable: true,
                },
              },
            ],
            [
              {
                name: "standards",
                type: "itemlist",
                children: [
                  {
                    name: "standard",
                    code: "NormGroup", // this works for now, until we have multiple objects with norm/type forks
                    filter: (stateProxy: any, row: any, id: number) =>
                      ProtectionEquipmentWizard.getFilteredNorms(
                        stateProxy,
                        row,
                        id
                      ),
                  },
                ],
                noHeaders: true,
                onChange: (stateProxy, fieldName, row, childName) => {
                  ProtectionEquipmentWizard.filterNorms(stateProxy);
                },
                onLoad: (stateProxy) =>
                  ProtectionEquipmentWizard.filterNorms(stateProxy),
              },
            ],
            [
              {
                name: "parts",
                type: "itemlist",
                children: [
                  { name: "name", title: arxs.t(`field.description`) },
                  {
                    name: "warrantyPeriod",
                    unit: arxs.t("unit.month").toLowerCase(),
                  },
                ],
              },
            ],
            [
              {
                name: "extraSpecifications",
                type: "itemlist",
                children: [
                  {
                    name: "name",
                    code: "ProtectionEquipment.ExtraSpecification",
                  },
                  { name: "description", multiLine: false },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              { name: "expirationDate" },
              { name: "expirationNotificationDate" },
            ],
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        recommendationMap: {},
      },
      fieldsWithIdsForDuplication: [
        "extraSpecifications",
        "parts",
        "standards",
      ],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        protectionEquipments: [],
        tagMap: {},
        supplierMap: {},
        employeeMap: {},
        contactMap: {},
        recommendationMap: {},
        combinedInstallationMap: {},
        periodicalMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "sort" },
        { name: "kind" },
        { name: "type" },
        { name: "description" },
        { name: "brand" },
        { name: "model" },
        { name: "serialNumber" },
        { name: "internalNumber" },
        { name: "purchaseDate" },
        { name: "constructionYear" },
        { name: "recommendation" },
        { name: "supplier" },
        { name: "purchasePrice" },
        { name: "depreciationPeriod" },
        { name: "remainingDepreciationPeriod" },
        { name: "depreciationPeriodEnd" },
        { name: "expirationDate" },
        { name: "combinedInstallationUniqueNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: ProtectionEquipmentBoard,
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: AssetCard
  };
};
export default getProtectionEquipmentMetadata;
