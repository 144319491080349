/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskType {
    Internal = 'Internal',
    External = 'External'
}

export function TaskTypeFromJSON(json: any): TaskType {
    return TaskTypeFromJSONTyped(json, false);
}

export function TaskTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskType {
    return json as TaskType;
}

export function TaskTypeToJSON(value?: TaskType | null): any {
    return value as any;
}

