import { ArXsState } from "infra/arxs";
import {
  DocumentStatus,
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
} from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import DocumentManagementWizard from "./DocumentManagementWizard";
import DocumentManagementActions from "./DocumentManagementActions";
import DocumentManagementBoard from "./DocumentManagementBoard";
import DocumentManagementCard from "./DocumentManagementCard";

function isScheduleRecurrent(schedule: any) {
  return !schedule || schedule.recurrenceRule !== 'FREQ=NONE';
}

const getDocumentManagementMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Document;

  return {
    module,
    title: arxs.t("modules.documentmanagement"),
    icon: "fas fa-books",
    path: "/documentmanagement",
    base: {
      name: "DocumentManagementDocument",
      route: "/documentmanagement",
      getResource: () => arxs.Api.getResource("documentmanagement"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Document.Write",
      readAction: "Document.Read",
    },
    settings: {
      name: "DocumentSettings",
      route: "/documentmanagement/settings",
      getResource: () => arxs.Api.getResource("documentmanagement").settings(),
      readAction: "Document.Settings.Read",
      writeAction: "Document.Settings.Write",
    },
    defaultDetailsTabIndex: 2,
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructures" },
          { name: "branches" },
          { name: "description" },
          { name: "creationDate" },
          {
            name: "schedule",
            isVisible: (stateProxy) => isScheduleRecurrent(stateProxy.getField("schedule")),
          },
          {
            name: "revisionDate",
            isVisible: (stateProxy) => !isScheduleRecurrent(stateProxy.getField("schedule")),
          },
          { name: "type" },
        ],
      },
      {
        title: arxs.t("details_pane.authorization"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructureAuthorizations", fullWidth: true },
          { name: "branchAuthorizations", fullWidth: true },
          { name: "employeeAuthorizations", fullWidth: true },
          { name: "userRoleAuthorizations", fullWidth: true },
        ],
      },
      {
        title: arxs.t("details_pane.relationships"),
        initiallyOpen: true,
        fields: [{ name: "relationships" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.MainDocument,
      ObjectDocumentType.AdditionalDocument,
      ObjectDocumentType.Image,
    ],
    singleFileDocumentTypes: [ObjectDocumentType.MainDocument],
    statuses: [
      DocumentStatus.InProcess,
      DocumentStatus.Active,
      DocumentStatus.ToExpire,
      DocumentStatus.Expired,
    ],
    actions: DocumentManagementActions.getActions(OriginModuleEnum.Document),
    wizard: {
      wizardClass: DocumentManagementWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              { name: "title" },
              {
                name: "creationDate",
              },
            ],
            [{ name: "description" }],
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "Document.DocumentKind" },
                type: "codeelementlist",
              },
              {
                name: "status",
                values: [
                  DocumentStatus.InProcess,
                  DocumentStatus.Active,
                  DocumentStatus.ToExpire,
                  DocumentStatus.Expired,
                ],
                props: { required: true, disabledOnEdit: true },
              },
            ],
            [{ name: "tags", type: "tag" }],
            [
              {
                title: arxs.t("field.versions"),
                name: "versions",
                type: "itemlist",
                children: [
                  { name: "version", width: "100px" },
                  { name: "versionDate", width: "150px" },
                  { name: "description", multiLine: false },
                ],
                onChange: (
                  stateProxy: any,
                  fieldName: any,
                  row: any,
                  childName: any
                ) =>
                  DocumentManagementWizard.setDefaultVersionDate(
                    stateProxy,
                    fieldName,
                    row
                  ),
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructures", "branches"].map(getValue);
            return arxs.securityContext.buildForMultipleContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.scope"),
          fields: [
            [
              {
                name: "legalStructures",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
              },
            ],
            [
              {
                name: "branches",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.School],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.read_rights"),
          fields: [
            [
              {
                name: "legalStructureAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
                title: arxs.t("field.legalStructures"),
              },
            ],
            [
              {
                name: "branchAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.School],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
                title: arxs.t("field.branches"),
              },
            ],
            [
              {
                name: "employeeAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "userRoleAuthorizations",
                type: "cardlist",
                props: {
                  modules: ["UserRole"],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                  overridePrefilter: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              {
                name: "schedule",
                props: {
                  allowedRecurrencePatternValues: ["None", "Monthly", "Yearly"],
                },
              },
            ],
            [
              {
                name: "revisionDate",
                isVisible: (stateProxy) =>
                  DocumentManagementWizard.showSimpleExpiration(stateProxy),
              },
              {
                name: "notification",
                isVisible: (stateProxy) =>
                  DocumentManagementWizard.showSimpleExpiration(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              {
                name: "documents",
                type: "document",
              },
              { name: "images", type: "image" },
            ],
          ],
        },
      ],
      fieldsWithIdsForDuplication: ["versions"],
      addNewState: "Active",
      lookups: {
        branchMap: {},
      },
    },
    board: {
      lookups: {
        documents: [],
        legalStructureMap: {},
        branchMap: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        codeElementsById: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructureNames" },
        { name: "branchNames" },
        { name: "scope" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "creationDate" },
        { name: "revisionDate" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "owner" },
        { name: "mostRecentVersion" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: DocumentManagementBoard,
      bucketConfig: [
        {
          module: module,
          status: "InProcess",
          onDrop: (state) =>
            DocumentManagementActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
        {
          module: module,
          status: "Active",
          onDrop: (state) =>
            DocumentManagementActions.dropActions().onDropInActive(state),
          requiredActions: ["activate"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
    },
    cardClass: DocumentManagementCard
  };
};
export default getDocumentManagementMetaData;
