import React from "react";
import ToggleButton from "components/controls/ToggleButton";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";

import "./ToggleButtons.scss";

export default class ToggleButtons extends FormItemController {
    deserialize(serializedValue) {
        return serializedValue;
    }

    serialize(deserializedValue) {
        return deserializedValue;
    }

    getItemClassName = (value) => {
        switch (value.replace(/\s/g,'')){
            case "ok": return "ok";
            case "nietok": return "nok";
            case "nvt": return "neutral";
            default: return;
        }
    }

    setValue = (value) => {
        const currentValue = this.getter();
        const newValue = currentValue != value ? value : undefined;
        this.setter(newValue);
    }

    render() {
        let values = [];
        for (const value of this.props.control.parameters.values) {
            values.push(Object.keys(value)[0]);
        }       

        return (<GlobalContext.Consumer>
            {
                (context) => <div className={`form-control question select ${this.props.required && "required"} ${this.props.requiredWithoutValue && "missing-value"}`}>
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field"><ToggleButton
                        readOnly={this.props.readOnly}
                        value={this.getter()}
                        values={values}
                        required={this.props.required}
                        onChange={(event, value) => this.setValue(value)}
                        getItemClassName={(value) => this.getItemClassName(value)} /></div>

                    {this.renderFooter(context)}
                </div>}
        </GlobalContext.Consumer>)
    }
}