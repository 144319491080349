import { Resource, ResourceLanguage } from 'i18next';
import { ProductType } from 'infra/Types';

import load_en from './en/Loader';
import load_nl from './nl/Loader';
import load_fr from './fr/Loader';

export function getTranslations(productType: ProductType): Resource {
  const nl = load_nl(productType);
  const en = load_en(productType);
  const fr = load_fr(productType);
  const resource = { nl, en, fr };
  return resource;
}

export function getLanguageResource(productType: ProductType, language: string): ResourceLanguage {
  const nl = load_nl(productType);
  const en = load_en(productType);
  const fr = load_fr(productType);
  switch (language) {
    case "en": return en;
    case "fr": return fr;
    default: return nl;
  }
}
