import { ArXsState } from "infra/arxs";
import ModuleMetadata from "modules/ModuleMetadata";
import {
  OriginModuleEnum,
} from "infra/api/contracts";

const getTagMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Tag;

  return {
    module,
    title: arxs.t("modules.tag"),
    icon: "far fa-tags",
    path: "/configuration/tagmanagement",
    base: {
        name: "",
        route: "",
        getResource: () => ({
          getById: () => new Promise(() => { }),
          get: () => new Promise(() => { }),
          post: () => new Promise(() => { })
        }),
        subscribe: (id, module, handler) => { },
        readAction: "",
        writeAction: ""
      },
    settings: {
      name: "TagSettings",
      route: "/configuration/tagmanagement/settings",
      getImportResource: () => arxs.ApiClient.shared.tagSettings,
      readAction: "Tag.Write",
      writeAction: "Tag.Write",
    },
    detailspane: [],
    allowedDocumentTypes: [],
    statuses: [],
    actions: [],
  };
};
export default getTagMetadata;
