/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecommendationStatus {
    InProcess = 'InProcess',
    Requested = 'Requested',
    Refused = 'Refused',
    InTreatment = 'InTreatment',
    ToSign = 'ToSign',
    Active = 'Active'
}

export function RecommendationStatusFromJSON(json: any): RecommendationStatus {
    return RecommendationStatusFromJSONTyped(json, false);
}

export function RecommendationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendationStatus {
    return json as RecommendationStatus;
}

export function RecommendationStatusToJSON(value?: RecommendationStatus | null): any {
    return value as any;
}

