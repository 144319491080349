import React, { Component, Fragment } from "react";
import ListItem from 'components/controls/ListItem';

import './MultiToggleButton.scss';

interface MultiToggleButtonProps {
  value: number,
  values: Array<string>,
  textSelector?(value: number): string,
  renderItem?(value: number): Component,
  onChange?(e: {}, value: number): void,
  getItemClassName?(value: number): string,
  readOnly?: boolean,
}

export default class MultiToggleButton extends Component<MultiToggleButtonProps> {
  onChange = (e: {}, value: number) => {
    if (this.props.onChange && !this.props.readOnly) {
      this.props.onChange(e, this.props.value ^ value);
    }
  }

  isOn = (flag: number) => {
    const enabled = (this.props.value & flag) === flag;
    return enabled;
  }

  render() {
    const { values, textSelector } = this.props;
    const renderItem = this.props.renderItem || ((value: number) => {
      if (textSelector) {
        return <Fragment>{textSelector(value)}</Fragment>
      }
      return <Fragment>{value}</Fragment>;
    });
    const getItemClassName = this.props.getItemClassName || ((value: number) => "");
    const classNames = [
      "toggle-button",
      "multi",
      this.props.readOnly && "disabled"
    ].filter(x => x).join(" ");

    return <div className={classNames}>
      {values.map(x => parseInt(x)).map(x => <ListItem key={x}
        className={`toggle ${this.isOn(x) ? "on" : "off"} ${getItemClassName(x)}`}
        data={x}
        onClick={this.onChange}
      >
        {renderItem(x)}
      </ListItem>)}
    </div>;
  }
}