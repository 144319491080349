import arxs from "infra/arxs";
import {  OriginModuleEnum, RelationshipType } from "infra/api/contracts";
import { SettingStep } from "modules/ModuleMetadata";

const _categoryMap = {
  [OriginModuleEnum.EquipmentInstallation]: "EquipmentInstallation.Sort",
  [OriginModuleEnum.Pbm]: "ProtectionEquipment.Sort",
  [OriginModuleEnum.HazardousSubstance]: "HazardousSubstance.Sort",
};

const _kindMap = {
  [OriginModuleEnum.Labourmeans]: "LabourMeans.Kind",
  [OriginModuleEnum.HazardousSubstance]: "HazardousSubstance.Kind",
  [OriginModuleEnum.EquipmentInstallation]: "EquipmentInstallation.Kind",
  [OriginModuleEnum.Pbm]: "ProtectionEquipment.Kind",
  [OriginModuleEnum.IntangibleAsset]: "IntangibleAsset.Kind",
  [OriginModuleEnum.Room]: "Room.Kind",
};

const _kindParentMap = {
  [OriginModuleEnum.EquipmentInstallation]: "sort",
  [OriginModuleEnum.Pbm]: "sort",
  [OriginModuleEnum.HazardousSubstance]: "sort",
};

const _typeMap = {
  [OriginModuleEnum.Labourmeans]: "LabourMeans.Type",
  [OriginModuleEnum.HazardousSubstance]: "HazardousSubstance.Type",
  [OriginModuleEnum.EquipmentInstallation]: "EquipmentInstallation.Type",
  [OriginModuleEnum.Pbm]: "ProtectionEquipment.Type",
  [OriginModuleEnum.IntangibleAsset]: "IntangibleAsset.Type",
  [OriginModuleEnum.Room]: "Room.Type",
};

const _typeParentMap = {
  [OriginModuleEnum.Labourmeans]: "kind",
  [OriginModuleEnum.HazardousSubstance]: "kind",
  [OriginModuleEnum.EquipmentInstallation]: "kind",
  [OriginModuleEnum.Pbm]: "kind",
  [OriginModuleEnum.IntangibleAsset]: "kind",
  [OriginModuleEnum.Room]: "kind",
};

const _getCodeFromStateProxy = (map: any) => (stateProxy: any) => map[stateProxy.getCurrentFieldValue("module")];

const relationshipTypes = [
  RelationshipType.Responsible,
  RelationshipType.CoResponsible,
  RelationshipType.Cc,
  RelationshipType.PreventionAdvisorSignatory,
  RelationshipType.HierarchicalLineSignatory,
  RelationshipType.OtherSignatory,
];

const assetModules = [
  OriginModuleEnum.Labourmeans,
  OriginModuleEnum.Pbm,
  OriginModuleEnum.EquipmentInstallation,
  OriginModuleEnum.HazardousSubstance,
  OriginModuleEnum.IntangibleAsset,
  OriginModuleEnum.Room,
  OriginModuleEnum.Building,
];

const createAssetProcessFlowSettings = (): Array<SettingStep> => [
  {
    title: arxs.t(`field.processFlows`),
    fields: [
      [
        {
          name: "processFlows",
          type: "itemlist",
          children: [
            { name: "module", values: assetModules },
            { name: "legalStructure" },
            { name: "branch", parent: "legalStructure" },
            { name: "sort", code: _getCodeFromStateProxy(_categoryMap) },
            { name: "kind", parent: _getCodeFromStateProxy(_kindParentMap), code: _getCodeFromStateProxy(_kindMap) },
            { name: "type", parent: _getCodeFromStateProxy(_typeParentMap), code: _getCodeFromStateProxy(_typeMap) },
            { name: "employee" },
            { name: "userRole" },
            { name: "relationshipType", values: relationshipTypes },
          ],
        },
      ],
    ],
  }
];
export default createAssetProcessFlowSettings;