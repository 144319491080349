import React from "react";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import { TextArea } from "components/controls/TextArea";

export default class Remark extends FormItemController {
    deserialize(serializedValue) {
        return serializedValue || "";
    }

    serialize(deserializedValue) {
        return deserializedValue || "";
    }
    
    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className={`form-control question select ${this.props.required && "required"} ${this.props.requiredWithoutValue && "missing-value"}`}>
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {!this.props.readOnly && <TextArea
                            readOnly={this.props.readOnly}
                            field={{ getter: () => this.getter(), setter: value => this.setter(value), multiLine: true }}
                            required={this.props.required}
                        />}
                        {this.props.readOnly && <div className="field-ellipsis">{(this.getter() || "").split("\n")
                            .map(
                                (item, key) => (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            )}</div>}
                    </div>
                    {this.renderFooter(context)}
                </div>}
        </GlobalContext.Consumer>)
    }
}