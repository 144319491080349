import React from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import { MultiYearPlanStatus } from "infra/api/contracts";
import CardLocation from "components/card/CardLocation";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardCategorization from "components/card/CardCategorization";

export default function MultiYearPlanCard(props: CardClassProps) {
    const { card, actionProps, cardProps } = props;
    const { status, end, priority, riskAnalysisValue } = card;

    const priorityClassName = priority && `priority-${priority.toLowerCase()}`;
    const priorityValue = priority && arxs.enums.getTitle("MultiYearPlanPriority", priority);
    const riskValue = riskAnalysisValue && JSON.parse(riskAnalysisValue).value;

    const periodValue = [
        MultiYearPlanStatus.Planned,
        MultiYearPlanStatus.Overdue,
        MultiYearPlanStatus.Completed,
        ].contains(status)
        ? new Date(end).getFullYear()
        : null;

    return (
      <>
        <div className="list-card-right">
          <div className="list-card-header">
            {card.start && card.end && (
              <div>
                <i className="far fa-clock"></i>{" "}
                {arxs.dateTime.formatDate(card.start)} -{" "}
                {arxs.dateTime.formatDate(card.end)}
              </div>
            )}
            {periodValue && <div className="period">{periodValue}</div>}
          </div>
          <h2>
            <div className="list-card-title">{card.title}</div>
            <CardActions {...actionProps} />
          </h2>
          {card.internalReference && (
            <div className="list-card-ref">{card.internalReference}</div>
          )}
          <CardSubtitle
            cardProps={cardProps}
            card={card}
            render={(children) => (
              <>
                <div
                  className={`list-card-priority badge ${priorityClassName}`}
                >
                  {priorityValue}
                </div>
                {riskValue && (
                  <div className={`list-card-riskValue badge`}>{riskValue}</div>
                )}
                {children}
                <div className="list-card-document-count">
                  <i className="fas fa-copy"></i>
                  {card.numberOfDocuments}
                </div>
              </>
            )}
          />
          <div className="list-card-details">
            <CardCategorization {...props} />
            <CardLocation card={card} />
          </div>
        </div>
      </>
    );
}