import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';

const getLookupValue = (lookup, ref) => {
  const match = lookup && ref && lookup[ref.id];
  return (match && match.name) || (ref && ref.name) || "";
};

class EmployeeBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const preferredAddress = (card.addresses || []).filter(x => x.isPreferred)[0] || (card.addresses || []).firstOrDefault((x) => x);
    const preferredEmail = (card.emails || []).filter(x => x.isPreferred)[0] || (card.emails || []).firstOrDefault((x) => x);
    const preferredPhone = (card.phones || []).filter(x => x.isPreferred)[0] || (card.phones || []).firstOrDefault((x) => x);

    return {
      ...card,
      birthDate: card.birthDate && new Date(card.birthDate),
      dateInService: card.dateInService && new Date(card.dateInService),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      functions: card.assignments.flatMap(x => x.function).map(x => this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))).join(", "),
      address: preferredAddress && `${preferredAddress.street} ${preferredAddress.number}, ${preferredAddress.zipCode} ${preferredAddress.city}, ${preferredAddress.country}`,
      email: preferredEmail && preferredEmail.email,
      phone: preferredPhone && preferredPhone.number,
      assignmentLocations: card.assignments && card.assignments.map(x => this.getReadableAssignment(x, stateProxy)).join(", ")
    };
  }

  getReadableAssignment = (assignment, stateProxy) => {
    const branchMap = stateProxy.getter("branchMap");
    const branch = getLookupValue(branchMap, assignment.branch);
    if (branch) {
      return branch;
    }

    const legalStructureMap = stateProxy.getter("legalStructureMap");
    const legalStructure = getLookupValue(legalStructureMap, assignment.legalStructure);
    return legalStructure;
  }

  getCardSearchTerms(card) {
    return [
      card.name,
      card.firstname,
      card.surname,
      card.userName,
      card.uniqueNumber,
      card.sex,
      card.nationalNumber,
      card.employeeNumber,
      card.functions,
      card.assignmentLocations,
      ...(card.emails || []).map(x => x.email),
      ...(card.tags || []).map(x => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.employees) {
            lookups.pristine = lookups.employees
              .orderBy(card => card.name)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.employees;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
        });
  }
}
export default new EmployeeBoard();