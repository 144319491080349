import React from "react";

export interface FieldLabelProps {
  id: string;
  showHeader?: boolean;
  title?: string;
  required?: boolean;
  style?: any;
}
export default function FieldLabel(props: FieldLabelProps) {
  if (!props.showHeader) {
    return <></>;
  }
  if (!props.title) {
    return <label>&nbsp;</label>;
  }
  return <label htmlFor={props.id} style={props.style}>
    {props.title}
    {props.required && <span> *</span>}
  </label>;
}