export default {
  parseQuotes: function(str) {
    var openQuote = false;
    var parsed = [];
    var quote = '';
    var text = '';

    for (var i = 0; i < str.length; i++) {
      var item = str[i];
      if (item === '"' && !openQuote) {
        openQuote = true;
        parsed.push({ type: 'text', value: text });
        text = '';
      }
      else if (item === '"' && openQuote) {
        openQuote = false;
        parsed.push({ type: 'quote', value: quote });
        quote = '';
      }
      else if (openQuote) quote += item;
      else text += item;  
    }

    if (openQuote) parsed.push({ type: 'text', value: '"' + quote });
    else parsed.push({ type: 'text', value: text });

    return parsed;
  },
  lowerCaseFirstLetter: function(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
};