import React, { Component } from 'react';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import Toaster from 'components/util/Toaster';

import './StatusTile.scss';

const _statuses = {
  "healthy": "Healthy", // This is in sync with what dotnet core healthcheck returns
  "server_down": "server_down",
  "network_error": "network_error",
}

export default class StatusTile extends Component {
  state = {
    status: "Healthy"
  }

  componentDidMount() {
    const subscription = arxs.ApiClient.subscribeToFetchEvents(response => {
      if (!response) {
        this.setState({ status: _statuses.network_error });
      } else if (response.status === 502) {
        this.setState({ status: _statuses.server_down });
      } else if (response.ok) {
        this.setState({ status: _statuses.healthy });
      }
    });
    this.setState({ subscription });
  }

  componentWillUnmount() {
    if (this.state.subscription) {
      this.state.subscription.dispose();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.state;
    if (status !== prevState.status) {
      if (status !== _statuses.healthy) {
        Toaster.error(arxs.t("header.status.server_down"));
      } else {
        Toaster.success(arxs.t("header.status.healthy_again"));
      }
    }
  }

  getTitle(status) {
    switch (status) {
      case _statuses.healthy: return arxs.t("header.status.healthy");
      case _statuses.server_down: return arxs.t("header.status.server_down");
      case _statuses.network_error: return arxs.t("header.status.server_down");
      default: return status;
    }
  }

  render() {
    const status = this.state.status;
    const healthy = status === _statuses.healthy;
    const title = this.getTitle(status);
    return <GlobalContext.Consumer>
      {(context) => !healthy && <div className="tile status" title={title}>
        <i className="fas fa-exclamation-triangle"></i>
      </div>}
    </GlobalContext.Consumer>;
  }
}