import React, { useState, useEffect } from "react";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import arxs from "infra/arxs";
import createPopupState from "./GlobalState/PopupState";
import createOptionPopupState from "./GlobalState/OptionPopupState";
import createInputPopupState from "./GlobalState/InputPopupState";
import createPromptPopupState from "./GlobalState/PromptPopupState";
import createMenuState from "./GlobalState/MenuState";
import createThemeState from "./GlobalState/ThemeState";
import createPlatformState from "./GlobalState/PlatformState";
import createDetailsPaneState from "./GlobalState/DetailsPaneState";

const GlobalContext = React.createContext();
export default GlobalContext;
export function GlobalContextProvider(props) {
  const [width, setWidth] = useState(1024);
  const [profile, setProfile] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [platform] = useState(createPlatformState());
  // Can't directly pass the function since it has not been initialized until after the statement
  const [theme, setTheme] = useState(createThemeState(x => setTheme(x)));
  const [menu, setMenu] = useState(createMenuState(x => setMenu(x)));
  const [optionPopup, setOptionPopup] = useState(createOptionPopupState(x => setOptionPopup(x)));
  const [inputPopup, setInputPopup] = useState(createInputPopupState(x => setInputPopup(x)));
  const [promptPopup, setPromptPopup] = useState(createPromptPopupState(x => setPromptPopup(x)));
  
  // These need to be split up in two, as to not create a chicken and egg problem (causes infinite loops)
  const [popup, setPopup] = useState(createPopupState(() => popupState.state, state => setPopupState({ state })));
  const [popupState, setPopupState] = useState(popup);
  const [detailsPane, setDetailsPane] = useState(createDetailsPaneState(() => detailsPaneState.state, state => setDetailsPaneState({ state })));
  const [detailsPaneState, setDetailsPaneState] = useState(detailsPane);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth)
    });

    arxs.Identity.subscribeToAuthenticationUpdate(() => {
      setProfile(arxs.Identity.profile);
      setIsAuthenticated(arxs.Identity.isAuthenticated());
    });
  }, []);

  useEffect(() => {
    setPopup(createPopupState(
      () => popupState.state,
      state => setPopupState({ state: Object.assign(popupState.state, state) }))
    );
  }, [popupState]);
  
  useEffect(() => {
    setDetailsPane(createDetailsPaneState(
      () => detailsPaneState.state,
      state => setDetailsPaneState({ state: Object.assign(detailsPaneState.state, state) }))
    );
  }, [detailsPaneState]);

  return (
    <LocalizationProvider language={arxs.language}>
      <IntlProvider locale={arxs.language}>
        {theme && theme.component}
        <GlobalContext.Provider
          value={{
            profile,
            isAuthenticated,
            platform,
            theme,
            menu,
            optionPopup,
            inputPopup,
            promptPopup,

            popup: {
              ...popup,
              ...popupState.state,
            },

            detailsPane: {
              ...detailsPane,
              ...detailsPaneState.state,
            },
          }}
        >
          {props.children}
        </GlobalContext.Provider>
      </IntlProvider>
    </LocalizationProvider>
  );
}
