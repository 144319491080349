import React, { Component } from 'react';
import Button from 'components/controls/Button';
import arxs from 'infra/arxs';
import { TextArea } from 'components/controls/TextArea';
import Toaster from 'components/util/Toaster';

import './PromptPopup.scss';

export const createPromptPopup = (context, title, prompt, onConfirm, confirmTitle) => {
  context = context || [];
  const state = {
    title: title,
    prompt: prompt,
    onConfirm: onConfirm,
    confirmTitle: confirmTitle || arxs.t("common.confirm"),
  };
  return state;
};

class PromptPopup extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  close = () => {
    this.setState({ dirtyValue: '' });

    if (this.props.close) {
      this.props.close();
    }
  }

  isEmptyOrSpaces(str) {
    return str === undefined || str === null || str.match(/^ *$/) !== null;
  }

  confirm = (action) => {
    if (this.props.required) {
      if (this.isEmptyOrSpaces(this.state.dirtyValue)) {
        Toaster.error(arxs.t('common.input_empty'));
        return;
      }
    }

    this.close();

    if (action) {
      action(this.state.dirtyValue);
    }
  }

  render() {
    return this.props.title ? (<div className="prompt-popup-backdrop">
      <div className={`prompt-popup ${this.props.className || ""}`}>
        <h1>{this.props.title}</h1>
        <div className="prompt-popup-close" onClick={this.close}>
          <i className="fa fa-times"></i>
        </div>
        <div className="prompt-popup-body">
          <div className="prompt-popup-input">
            <TextArea
              autofocus
              disabled
              field={{ getter: () => this.props.prompt, setter: () => {} }}
            />
          </div>
          <div className="buttons">
            <Button className="icon" onClick={() => this.confirm(this.props.onConfirm)}>
              {this.props.confirmTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>) : null;
  }
}
export default PromptPopup;