import React, { useEffect } from "react";

// cloned from https://github.com/Personare/react-freshdesk-widget
export default function FreshdeskWidget(props) {
  useEffect(() => {
    const { name, email } = props;

    props.registerClickHandler(() => {
      if (!window.FreshworksWidget) {
        window.fwSettings = {
          widget_id: 13000000066,
          locale: "nl",
        };

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://widget.freshworks.com/widgets/13000000066.js";
        script.type = "text/javascript";
        document.body.appendChild(script);

        (function () {
          if ("function" != typeof window.FreshworksWidget) {
            const n = function () {
              n.q.push(arguments);
            };
            n.q = [];
            window.FreshworksWidget = n;
          }
        })();
      }

      window.FreshworksWidget("hide", "launcher");

      setTimeout(() => {
        window.FreshworksWidget("open", "ticketForm");
        window.FreshworksWidget("prefill", "ticketForm", {
          name,
          email,
        });
      }, 100);
    });

    return () => {
      if (window.FreshworksWidget) {
        window.FreshworksWidget("close");
      }
    };
  }, []);

  return <></>;
}
