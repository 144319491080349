import Toaster from 'components/util/Toaster';
import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';
import { RelationshipType } from 'infra/api/contracts';

class ActivityEntryWizard extends WizardController {

    onLookupsChange(stateProxy) {
        this.getContext(stateProxy);
    }

    setEndDate = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");

            const startAt = data.startAt;
            const endAt = data.endAt || pristine.endAt;

            let dateEnd;

            const dateStart = new Date(startAt);

            if (endAt) {
                const existingDateEnd = new Date(endAt);
                if (dateStart.getTime() > new Date(endAt).getTime()) {
                    dateEnd = dateStart;
                    dateEnd.setHours(existingDateEnd.getHours(), existingDateEnd.getMinutes(), existingDateEnd.getSeconds());
                } else {
                    dateEnd = new Date(endAt);
                }
            } else {
                dateEnd = dateStart;
            }

            stateProxy.setter({ data: { ...data, endAt: dateEnd } }, resolve);
        });
    }

    checkEndDateAfterStartDate = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");

            const startAt = data.startAt;
            const endAt = data.endAt || pristine.endAt;

            if (!this.isEndDateAfterStartDate(endAt, startAt)) {
                Toaster.error(arxs.t("wizard.validation.out_of_range_greater", { field1: arxs.t("field.endAt"), field2: arxs.t("field.startAt") }));
            }
            resolve();
        });
    }

    isEndDateAfterStartDate = (endDate, startDate) => {
        return new Date(endDate).getTime() >= new Date(startDate).getTime();
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        const startDate = getCurrentFieldValue("startAt");
        const endDate = getCurrentFieldValue("endAt");

        if (endDate && startDate) {
            if (!this.isEndDateAfterStartDate(endDate, startDate)) {
                preValidation["endAt"] = { error: arxs.t("wizard.validation.out_of_range_greater", { field1: arxs.t("field.endAt"), field2: arxs.t("field.startAt") }) };
            }
        }

        const relationships = getCurrentFieldValue("relationships") || [];
        const hasAssignees = relationships.some(x => x.type === RelationshipType.Assignee);
        if (!hasAssignees) {
            preValidation["assignees"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.assignees`) }) };
        }

        const legalStructure = getCurrentFieldValue("legalStructure");
        const branch = getCurrentFieldValue("branch");

        if (!branch && !legalStructure){
            preValidation["context"] = {error: arxs.t("wizard.validation.field_is_required", {field: arxs.t(`field.context`)})};
        }

        return preValidation;
    }
}
export default new ActivityEntryWizard();
