import React from "react";
import Dropzone from 'react-dropzone-uploader';

const ReportHeaderImageUploader = ({ value, onChange }) => {
    const initialFiles = React.useMemo(() => {
      if (!value) return [];
      
      const byteString = atob(value.split(',')[1]);
      const mimeString = value.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      
      const blob = new Blob([ab], {type: mimeString});
      const file = new File([blob], "image.jpg", { type: mimeString, lastModified: Date.now() });
      
      return [file];
    }, [value]);
  
    const handleChangeStatus = ({ meta, file }, status) => {
        if (status === 'done') {
            const reader = new FileReader();

            reader.onload = () => {
              onChange(reader.result);
            };
            resizeImage(file)
              .then(resizedFile => reader.readAsDataURL(resizedFile));
        } else if (status === 'removed') {
            onChange(null);
        }
    };

    const resizeImage = async (file) => {
      return new Promise((resolve) => {
        const img = document.createElement('img');
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 400;
          const maxHeight = 200;
          let width = img.width;
          let height = img.height;
  
          const scale = Math.min(maxWidth / width, maxHeight / height);
          // Don't scale up
          if (scale < 1) {
            width *= scale;
            height *= scale;
          }
  
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.imageSmoothingEnabled = true;
          ctx.imageSmoothingQuality = 'high';
          ctx.drawImage(img, 0, 0, width, height);
  
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(resizedFile);
          }, 'image/jpeg');
        };
      });
    };

    return (
      <Dropzone
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        inputContent={() => <i className="fas fa-plus"></i>}
        initialFiles={initialFiles}
        accept={"image/*"}
      />
    );
  };
  
  export default ReportHeaderImageUploader;