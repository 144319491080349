import arxs from "infra/arxs";
import React, { Component } from "react";

export default class ListItem extends Component {
  constructor(props) {
    super(props);

    const handlerMap = ["onClick", "onClickCapture", "onMouseDown", "onMouseUp", "onMouseMoveCapture", "onMouseDownCapture", "onMouseUpCapture", "onMouseMove", "onKeyDown", "onKeyUp"]
      .reduce((map, key) => {
        map[key] = (event) => {
          if (this.props[key]) {
            this.props[key](event, this.props.data);
          }
        };
        return map;
      }, {});

    this.state = { handlerMap };
  }

  componentDidMount() {
    if (this.props.data === undefined) {
      arxs.logger.warn("ListItem's data is undefined, did you forget to pass it?");
    }
  }

  transformProps = (exclude) => {
    const props = {};
    const map = this.state.handlerMap || {};

    for (const key of Object.keys(this.props)) {
      const handler = map[key];
      if (handler) {
        props[key] = handler;
      } else if (!exclude.some(x => x === key)) {
        props[key] = this.props[key];
      }
    }

    return props;
  }

  render() {
    const props = this.transformProps(["children", "data", "componentRef"]);

    return <div ref={this.props.componentRef} {...props}>
      {this.props.children}
    </div>;
  }
}