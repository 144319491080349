import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import { OriginModuleEnum } from "infra/api/contracts";

class ClassicActions {
  getActions = (module) => {
    let classicActions = [];

    if (!arxs.moduleMetadataRegistry.isModuleAllowed(OriginModuleEnum.PeriodicMaintenance)) {
      classicActions = classicActions.concat([{
        name: "create_maintenance",
        module: module,
        icon: "far fa-tools",
        getTitle: () => arxs.t("actions.maintenance.create"),
        onClick: (state) => this.createMaintenance(state, module),
      }]);
    }
    if (!arxs.moduleMetadataRegistry.isModuleAllowed(OriginModuleEnum.PeriodicControl)) {
      classicActions = classicActions.concat([{
        name: "create_inspection",
        module: module,
        icon: "far fa-check-square",
        getTitle: () => arxs.t("actions.inspection.create"),
        onClick: (state) => this.createInspection(state, module),
      }]);
    }

    return classicActions;
  };

  executeClassicAction = (postPath, successPath, body) => {
    const openNewClassicTab = (path) => {
      window.open(arxs.generateClassicUrl(path), "_blank");
    };
    const openNewLogonTab = (url) => {
      Toaster.error(arxs.t("actions.classic.not_logged_on"));
      openNewClassicTab(url || "/Account/Login?reason=TIMEOUT");
    };

    const form = new FormData();

    for (const pair of Object.entries(body)) {
      form.append(pair[0], pair[1]);
    }

    fetch(`${arxs.classicUrl}${postPath}`, {
      method: "POST",
      credentials: "include",
      redirect: "manual",
      body: form,
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((x) => {
            if (x === true) {
              openNewClassicTab(successPath);
            } else if (typeof x === "string") {
              openNewClassicTab(x);
            }
          });
        } else if (response.status === 0) {
          openNewClassicTab(successPath);
          return;
        } else if (response.status === 403) {
          return response.json().then((x) => {
            return openNewLogonTab(x.redirect);
          });
        }
        return openNewLogonTab();
      })
      .catch(() => openNewLogonTab());
  };

  createRiskAnalysis = (state, module) => {
    const handle = (isShort) => {
      this.executeClassicAction(
        "/RiskAnalysis/CreateStart",
        "/RiskAnalysis/Create",
        {
          ids: state.ids,
          origin: module,
          isShort,
        }
      );
    };

    state.context.optionPopup.show(arxs.t("actions.riskanalysis.create"), [
      {
        title: arxs.t("actions.riskanalysis.normal"),
        handle: () => handle(false),
      },
      {
        title: arxs.t("actions.riskanalysis.short"),
        handle: () => handle(true),
      },
    ]);
  };

  createMaintenance = (state, module) => {
    const handle = (type) => {
      this.executeClassicAction(
        `/PeriodicMaintenance/CreateStart?origin=${module}&type=${type}`,
        "/PeriodicMaintenance/Create",
        {
          id: state.ids,
          "ids[]": state.ids,
        }
      );
    };

    state.context.optionPopup.show(arxs.t("actions.maintenance.create"), [
      {
        title: arxs.t("actions.maintenance.internal"),
        handle: () => handle("Internal"),
      },
      {
        title: arxs.t("actions.maintenance.external"),
        handle: () => handle("External"),
      },
    ]);
  };

  createInspection = (state, module) => {
    const handle = (type) => {
      this.executeClassicAction(
        `/PeriodicControl/CreateStart?origin=${module}&type=${type}`,
        "/PeriodicControl/Create",
        {
          id: state.ids,
          "ids[]": state.ids,
        }
      );
    };

    state.context.optionPopup.show(arxs.t("actions.inspection.create"), [
      {
        title: arxs.t("actions.inspection.internal"),
        handle: () => handle("Internal"),
      },
      {
        title: arxs.t("actions.inspection.external"),
        handle: () => handle("External"),
      },
    ]);
  };
}
export default new ClassicActions();
