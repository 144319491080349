import arxs from 'infra/arxs';
import BoardController from 'modules/BoardController';

class CombinedInstallationBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      title: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      kind: this.getCodeElementValue(card.kind, stateProxy.getter("codeElementsById")),
      type: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(stateProxy.getter("legalStructureMap"), card.legalStructure),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(stateProxy.getter("buildingMap"), card.building),
      location: this.getLookupValue(stateProxy.getter("locationMap"), card.location),

      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks)
    };
  }

  getCardSearchTerms(card) {
    return [
      card.description,
      card.uniqueNumber,
      card.internalNumber,
      card.kind,
      card.type,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      card.owner,
      ...(card.tags || []).map((x) => x.name),
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.combinedInstallations) {
            lookups.pristine = lookups.combinedInstallations
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.combinedInstallations;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));

        });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for CombinedInstallation yet");
  }
}
export default new CombinedInstallationBoard();