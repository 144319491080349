import React, { Component } from 'react';

import './TextInput.scss';

export default class TextInput extends Component {
  render() {
    return (
      <div className={`input-wrapper text-input ${this.props.icon || ""}`}>
        <input type="text"
          value={this.props.value || ""}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder} />
      </div>
    );
  }
}