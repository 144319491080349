import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";
import { createCardLookup } from "components/shell/CardLookup/CardLookup";
import Toaster from "components/util/Toaster";
import AssetActions from "modules/assetmanagement/AssetActions";

class CombinedInstallationActions {
  getActions = (module) => AssetActions.getActions(module);

  getGlobalActions = (module) => {
    return [
      {
        name: "create_combinedinstallation",
        module: module,
        icon: "far fa-link",
        getTitle: () => arxs.t("actions.combined_installation.create"),
        onClick: (state) => this.createCombinedInstallation(state, module),
      },
    ];
  }
  
  createCombinedInstallation = (state, module) => {
    const history = state["history"];
    const assets = state["ids"].map((id) => ({ module, id }));
    state.context.optionPopup.show(
      arxs.t("actions.combined_installation.create"),
      [
        {
          title: arxs.t("actions.combined_installation.add_to_new"),
          handle: () => {
            history.push({
              pathname: `/combinedinstallation/create`,
              state: { assets },
            });
            return new Promise(() => {});
          },
        },
        {
          title: arxs.t("actions.combined_installation.add_to_existing"),
          handle: () => {
            const onApplyFilter = (selectedMap) => {
              const id = Object.keys(selectedMap)[0];
              const assets = state["ids"].map((id) => ({ module, id }));
              arxs
                .moduleMetadataRegistry
                .getResourceByModule(OriginModuleEnum.CombinedInstallation)
                .addToExisting({ id, assets })
                .then(() =>
                  Toaster.success(
                    arxs.t("actions.combined_installation.assets_added")
                  )
                );
              state.context.popup.close();
            };

            arxs
              .moduleMetadataRegistry
              .getResourceByModule(assets[0].module)
              .getById(assets[0].id)
              .then((firstAsset) => {
                const cardLookup = createCardLookup({
                  modules: [OriginModuleEnum.CombinedInstallation],
                  singleSelection: true,
                  onApplyFilter,
                  securityContext: arxs.securityContext.buildForContext(
                    "CombinedInstallation.Write",
                    {
                      ...firstAsset.legalStructure,
                      module: OriginModuleEnum.SchoolGroup,
                    },
                    { ...firstAsset.branch, module: OriginModuleEnum.School },
                    undefined,
                    undefined
                  ),
                });
                state.context.popup.show(cardLookup);
              });
          },
        },
      ]
    );
  }
}
export default new CombinedInstallationActions();