import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';
import { OriginModuleEnum, BulkAction } from "infra/api/contracts";

import "./BulkEditRelationshipsPopup.scss";

const noOp = () => {};

export const createBulkEditRelationshipsPopup = (cards, types, onSubmit, onCancel) => {
  const refs = cards.map(x => ({ objectId: x.id, module: x.module }));
  const close = onCancel || noOp;
  const createConfirm = (action) => onSubmit
    ? (data) => {
      const state = { ...data, refs, action };
      arxs.ApiClient.shared.relationships.edit(state);
      onSubmit();
    }
    : noOp;

  const confirmReplace = createConfirm(BulkAction.Replace);
  const confirmAppend = createConfirm(BulkAction.Append);

  const state = {
    title: arxs.t("actions.bulkeditrelationships.title"),
    content: <BulkEditRelationshipsPopup confirmReplace={confirmReplace} confirmAppend={confirmAppend} close={close} cards={cards} types={types} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function BulkEditRelationshipsPopup(props) {
  const [data, setData] = useState({ relationships: [] });

  const rows = [
    [
      {
        name: "responsible",
        type: "card",
        props: {
          modules: [OriginModuleEnum.Employee],
          condensed: true,
          overridePrefilter: true,
        },
      },
    ],
    [
      {
        name: "relationships",
        type: "relationships",
        props: {
          types: props.types,
        }
      }
    ],
  ];

  const disabled = !data.responsible && data.relationships.length === 0;

  return <div className={`bulk-edit-relationships-popup ${props.className || ""}`}>
    <div className="bulk-edit-relationships-popup-body">
      <FieldSet schemaName="BulkEdit" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`bulk-edit-relationships-popup-cancel`} className="icon alternative" onClick={props.close}>{arxs.t("common.cancel")}</Button>
        <Button key={`bulk-edit-relationships-popup-confirm-replace`} className="icon" onClick={() => props.confirmReplace(data)} disabled={disabled}>{arxs.t("common.replace")}</Button>
        <Button key={`bulk-edit-relationships-popup-confirm-append`} className="icon" onClick={() => props.confirmAppend(data)} disabled={disabled}>{arxs.t("common.append")}</Button>
      </div>
    </div>
  </div>;
}