import React from "react";

import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import Avatar from "components/controls/images/Avatar";

export default function PlanningCard(props: CardClassProps) {
    const { card } = props;

    const assignee = card.assignee
        && arxs.Api.lookups.resolveSubject({
            id: card.assignee.id,
            module: OriginModuleEnum.Employee,
        });
    const avatar =
        assignee &&
        assignee.images &&
        assignee.images.length > 0 &&
        assignee.images[0].url;
    return (
        <>
            <div className="list-card-right">
                {
                    <div className="list-card-header">
                        <div>{arxs.dateTime.formatDate(card.start)}</div>
                        <div>
                        {arxs.dateTime.formatTime(card.start)} -{" "}
                        {arxs.dateTime.formatTime(card.end)}
                        </div>
                    </div>
                }
                <h2>
                    <div className="list-card-title"></div>
                    <div className="list-card-actions">
                    </div>
                </h2>
                <div className="list-card-details">
                    {assignee && (
                        <div className="user-card">
                            <Avatar src={avatar} fullName={assignee.name} />
                            <div className="user-card-names">
                                <div className="user-card-name">{assignee.name}</div>
                                <div className="user-card-username">
                                    {assignee.userName}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}