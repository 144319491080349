import React, { Component } from "react";
import CardList from "components/controls/cardlist/CardList";
import arxs from "infra/arxs";
import { SignatoryType, OriginModuleEnum } from "infra/api/contracts";

class SignatoryList extends Component {
  constructor(props) {
    super(props);

    const field = props.field;
    const name = field.name;
    const schema = field.schema || {};

    let error = null;
    if (schema.type !== "array") {
      error = "Unexpected type";
    }

    this.state = {
      name,
      schema,
      error,
      refSchema: {},
      pristine: [],
      data: [],
    };
  }

  componentDidMount() {
    const name = this.state.name;
    let schema = this.state.schema || {};

    if (schema.type === "array" && schema.items) {
      const link = schema.items["$ref"];

      if (link) {
        const match = link.match(/.*\/([^/]+)/);
        if (match === null) {
          const error = `${name}, unexpected ref`;
          arxs.logger.error("Unexpected ref {fieldName}", name);
          this.setState({ error });
          return;
        }

        const ref = match[1];
        const refSchema = arxs.Api.getSchema(ref);
        if (!refSchema) {
          arxs.logger.error("Schema not found {fieldName} {ref}", name, ref);
          this.setState({ error: `${name}, schema not found` });
          return;
        }

        this.setState({ refSchema });

        if (ref !== "SignatoryRef") {
          const error = `${name} not of type SignatoryRef`;
          arxs.logger.error("Expecting SignatoryRef type {fieldName}", name);
          this.setState({ error });
          return;
        }
      }
    }
  }

  handleDelete = (row) => {
    const { field } = this.props;
    const rows = [...(field.getter() || [])];
    const i = rows.indexOf(row);
    if (i > -1) {
      rows.splice(i, 1);
      field.setter(rows);
    }
  };

  handleAdd = () => {
    const { field } = this.props;
    const rows = [...(field.getter() || [])];
    rows.push({});
    field.setter(rows);
  };

  onChange = (type, values) => {
    const otherValues = (this.props.value || []).filter((x) => x.type !== type);
    const data = otherValues
    .concat(values
      .map((x) => ({
        employee: x.module === "Employee" ? { id: x.id } : null,
        type,
      }))
      .filter(x => x.employee)
    );
    if (this.props.onChange) {
      this.props.onChange(data);
    }
  };

  getAllowedModulesForSignatoryType = (type) => {
    switch (type) {
      case SignatoryType.PreventionAdvisor:
      case SignatoryType.HierarchicalLine:
      case SignatoryType.Other:
        return [OriginModuleEnum.Employee];
      default: return [OriginModuleEnum.Employee];
    }
  };

  render() {
    const types = this.props.types || [];
    const titles = this.props.titles || {};
    let signatoryRefs = this.props.value || [];
    if (!Array.isArray(signatoryRefs)) {
      signatoryRefs = [signatoryRefs];
    }
    const required = this.props.field.required;
    const value = signatoryRefs
      .filter((x) => x.employee)
      .map((x) => ({ id: x.employee.id, module: "Employee", type: x.type, signingDate: x.signingDate, isSignatory: true }));

    const signatures = (this.props.signatures || []);

    return types
      .filter(
        (type) =>
          !this.props.readOnly ||
          value.filter((x) => x.type === type).length > 0
      )
      .map((type) => (
        <CardList
          className={this.props.className}
          key={type}
          title={titles[type] || arxs.t(`enums.SignatoryType.${type}`)}
          readOnly={this.props.readOnly}
          value={value.filter((x) => x.type === type)}
          onChange={(values) => this.onChange(type, values)}
          required={required}
          prefilter={this.props.prefilter}
          securityContext={this.props.securityContext}
          condensed={true}
          modules={this.getAllowedModulesForSignatoryType(type)}
          vertical={this.props.vertical}
          singleSelection={this.props.singleSelection}
          extendData={true}
          signatures={signatures}
        />
      ));
  }
}
export default SignatoryList;
