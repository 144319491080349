import { CommissioningStatus, InstructionCardStatus, OriginModuleEnum, RecommendationStatus, TaskStatus } from "infra/api/contracts";
const readOnlyStatuses = {
    [OriginModuleEnum.Task]: [TaskStatus.Completed, TaskStatus.ToVerify, TaskStatus.MultiYearPlan],
    [OriginModuleEnum.PeriodicControl]: [TaskStatus.Completed, TaskStatus.ToVerify, TaskStatus.MultiYearPlan],
    [OriginModuleEnum.PeriodicMaintenance]: [TaskStatus.Completed, TaskStatus.ToVerify, TaskStatus.MultiYearPlan],
    [OriginModuleEnum.RiskAnalysis]: [TaskStatus.Completed, TaskStatus.ToVerify, TaskStatus.MultiYearPlan],
    [OriginModuleEnum.SafetyInstructionCard]: [InstructionCardStatus.ToSign, InstructionCardStatus.Active, InstructionCardStatus.Inactive],
    [OriginModuleEnum.InstructionCard]: [InstructionCardStatus.ToSign, InstructionCardStatus.Active, InstructionCardStatus.Inactive],
    [OriginModuleEnum.Commissioning]: [CommissioningStatus.ToSign, CommissioningStatus.Active, CommissioningStatus.Inactive],
    [OriginModuleEnum.OutOfCommissioning]: [CommissioningStatus.ToSign, CommissioningStatus.Active, CommissioningStatus.Inactive],
    [OriginModuleEnum.Consultancy]: [RecommendationStatus.Refused, RecommendationStatus.ToSign, RecommendationStatus.Active],
};
export default function formDefinitionSelector(formMap, module, status, formDefinition) {
    const link = formDefinition.link;
    const form = link && formMap ? formMap[link.id] : null;

    let linkedDefinition;
    const statusesForModule = readOnlyStatuses[module];
    if (form && link && statusesForModule && statusesForModule.includes(status)) {
        const historicDefinition = (form.historicDefinitions || []).filter(x => x.hash === formDefinition.link.hash)[0];
        if (historicDefinition) {
            linkedDefinition = historicDefinition;
        } else if (form.formDefinition.hash === formDefinition.link.hash) {
            linkedDefinition = form.formDefinition;
        } else {
            console.error("Couldn't find historic definition for form", link);
        }
    } else {
        linkedDefinition = formDefinition.link && form ? form.formDefinition : formDefinition;
    }
    return [form, linkedDefinition];
}