import React, { useState, useEffect, useCallback } from "react";
import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";
import { createUpload } from "components/controls/upload/Upload";
import { createImagePreview } from "components/controls/images/ImagePreview";
import Collapsible from "react-collapsible";
import { DragableImage } from "components/controls/images/DragableImage";
import { useDrop } from "react-dnd";

import "./ImageOverview.scss";
import { OriginModuleEnum, ObjectDocumentType } from "infra/api/contracts";

export interface IImage {
  name: string;
  url: string;
  previewUrl: string;
  contentType: string;
  hash: string;
  id: string;
}

export interface IImageOverviewProps {
  module: string;
  data: Array<IImage>;
  readOnly: boolean;
  onDelete: (value: IImage) => void;
  onAdd: (value: Array<IImage>) => void;
  onSort: (value: Array<IImage>) => void;
  className?: string;
  correlationKey?: string;
  imageType?: ObjectDocumentType;
  renderInOneLine?: boolean;
}

export interface IImageOverviewState {
  upload?: any;
  dirtyImages: Array<IImage>;
}

export const ImageOverview: React.FC<IImageOverviewProps> = (props) => {
  const [data, setData] = useState<Array<IImage>>([]);
  const [singleFileDocumentTypes, setSingleFileDocumentTypes] = useState<Array<string>>([]);

  const meta = arxs.moduleMetadataRegistry.get(props.module);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
    setSingleFileDocumentTypes(meta.singleFileDocumentTypes || []);
  }, [props.data, meta.singleFileDocumentTypes]);

  const handleAddFiles = (context: {
    popup: { close: () => void; show: (arg0: any) => void };
  }) => {
    const { correlationKey } = props;

    const onSubmit = (newImages: any) => {
      context.popup.close();

      if (props.onAdd) {
        props.onAdd(newImages);
      }
    };

    const upload = createUpload(
      props.module,
      null,
      onSubmit,
      singleFileDocumentTypes,
      [props.imageType || ObjectDocumentType.Image],
      (props.imageType && [props.imageType]) || null,
      correlationKey
    );
    context.popup.show(upload);
  };

  const onDelete = (image: IImage) => {
    if (props.onDelete) {
      props.onDelete(image);
    }
  };

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newData = data.moveItem(dragIndex, hoverIndex);
      setData(newData);

      props.onSort(newData);
    },
    [data, props]
  );

  const getImageStyle = (image?: IImage) => {
    if (image) {
      return {
        background: `url("${image.previewUrl || image.url}") no-repeat center`,
        backgroundSize: "cover",
      };
    }

    return {
      backgroundImage: `url("images/image.png")`,
      backgroundSize: "cover",
    };
  };

  const renderActions = (image: IImage) => {
    if (!props.readOnly) {
      return (
        <div className="image-actions">
          <div
            key="deleteAction"
            className="image-remove"
            title={arxs.t("controls.image_preview.delete")}
            onClick={() => onDelete(image)}
          >
            <i className="far fa-trash-alt"></i>
          </div>
        </div>
      );
    }
  };

  const renderPlaceHolders = (context: any) => {
    if (props.readOnly) {
      if (!data || data.length === 0) {
        return (
          <div className="image" key="empty">
            <div
              className="image preview empty readonly"
              style={getImageStyle()}
              key="empty"
            />
          </div>
        );
      }
    } else {
      if (
        !singleFileDocumentTypes.some((x) => x === ObjectDocumentType.Image) ||
        (singleFileDocumentTypes.some((x) => x === ObjectDocumentType.Image) &&
          data.length === 0)
      ) {
        return (
          <div className="image" key="empty">
            <div
              className="image preview empty"
              onClick={() => handleAddFiles(context)}
              key="empty"
            >
              <i className="fas fa-plus"></i>
            </div>
          </div>
        );
      }
    }
  };

  const renderImage = (context: any, image: IImage, index: number) => {
    const readOnly = props.readOnly ? props.readOnly : data.length === 1;

    return (
      <DragableImage
        className={readOnly ? `preview readOnly` : `preview`}
        onClick={() => context.popup.show(createImagePreview(data))}
        style={getImageStyle(image)}
        alt={image.name}
        key={image.url}
        onDrop={moveImage}
        index={index}
        id={image.url}
        label={index + 1}
        src={
          arxs.ImageProxy.resizeImage(
            image.previewUrl || image.url,
            202,
            202
          ) || ""
        }
        readOnly={readOnly}
      />
    );
  };

  const [, drop] = useDrop({ accept: "Image" });

  const title = [ OriginModuleEnum.SchoolGroup, OriginModuleEnum.School ].includes(props.module as OriginModuleEnum)
    ? arxs.t("wizard.logo")
    : arxs.documentTypes.titles[ObjectDocumentType.Image.toLowerCase()];

  const renderBody = (context: any) => {
    return (
      <div
        className={
          !!!props.renderInOneLine ? `image-content` : "image-content-oneline"
        }
        ref={drop}
      >
        {data.map((image: IImage, i: any) => (
          <div className="image" key={`${i}`}>
            {renderImage(context, image, i)}
            {renderActions(image)}
          </div>
        ))}
        {renderPlaceHolders(context)}
      </div>
    );
  };

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className={`image-overview ${props.className || ""}`}>
          {!props.renderInOneLine && (
            <Collapsible
              trigger={title}
              open={true}
              key={props.imageType || ObjectDocumentType.Image}
            >
              {renderBody(context)}
            </Collapsible>
          )}
          {props.renderInOneLine && renderBody(context)}
        </div>
      )}
    </GlobalContext.Consumer>
  );
};
