import React, { useState, useEffect, Fragment } from "react";
import arxs from "infra/arxs";
import Collapsible from "react-collapsible";
import ReportModuleConfig from "./ReportModuleConfig";
import { SecurityContext } from "infra/SecurityContext";
import StringExtensions from "infra/tools/StringExtensions";

import "./ReportDefinitionDetails.scss";
import {
  OriginModuleEnum,
  ReportDefinitionDetails,
  ReportModuleSetting,
} from "infra/api/contracts";

export interface ReportDefinitionDetailsProps {
  readOnly?: boolean;
  editable?: boolean;
  value: ReportDefinitionDetails;
  onChange(value?: ReportDefinitionDetails): void;
  className?: string;
  securityContext: SecurityContext;
}

interface ReportingEndpoint {
  module: OriginModuleEnum;
  endpoint: any;
}

interface WhitelistedModulesAndField {
  [OriginModuleEnum.NotificationDefect]: Array<string>;
  [OriginModuleEnum.Consultancy]: Array<string>;
  [OriginModuleEnum.ActivityEntry]: Array<string>;
  [OriginModuleEnum.Task]: Array<string>;
  [OriginModuleEnum.IncidentManagement]: Array<string>;
  [OriginModuleEnum.GlobalPreventionPlan]: Array<string>;
  [OriginModuleEnum.PeriodicControl]: Array<string>;
  [OriginModuleEnum.PeriodicMaintenance]: Array<string>;
  [OriginModuleEnum.RiskAnalysis]: Array<string>;
  [OriginModuleEnum.Commissioning]: Array<string>;
  [OriginModuleEnum.OutOfCommissioning]: Array<string>;
  [OriginModuleEnum.SafetyInstructionCard]: Array<string>;
  [OriginModuleEnum.InstructionCard]: Array<string>;
  [OriginModuleEnum.Document]: Array<string>;
}

export default function ReportDefinitionDetailsControl(
  props: ReportDefinitionDetailsProps
) {
  const [reportingEndpoints, setReportingEndpoints] = useState<
    Array<ReportingEndpoint>
  >([]);

  const whiteListedModulesAndFields: WhitelistedModulesAndField = {
    [OriginModuleEnum.NotificationDefect]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "CreatedAt",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status"
    ],
    [OriginModuleEnum.Consultancy]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "CreatedAt",
      "ActivationDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status",
    ],
    [OriginModuleEnum.ActivityEntry]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "CreatedAt",
      "StartAt",
      "Kind",
      "Type",
      "Title",
      "Description",
    ],
    [OriginModuleEnum.Task]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "TargetDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status",
      "ActualDuration",
      "CreatedAt",
    ],
    [OriginModuleEnum.IncidentManagement]: [
      "LegalStructure",
      "Branch",
      "UniqueNumber",
      "Kind",
      "Type",
      "Status",
      "IncidentTime",
      "AccidentInvestigationDate",
      "IsSerious",
      "GeoLocation",
      "IncidentDescription",
      "Analysis",
      "ProposedPreventionMeasures",
      "AppliedPreventionMeasures",
      "FlatRateLeave",
      "WorkOutage",
      "VictimModule",
      "InsuranceAck"
    ],
    [OriginModuleEnum.GlobalPreventionPlan]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "Title",
      "InternalReference",
      "RiskValue",
      "Priority",
      "Description",
      "Goals",
      "WorkingMethod",
      "Sort",
      "Kind",
      "Type",
      "StartDate",
      "EndDate",
      "EstimlatedDuration",
      "EstimatedCost"
    ],
    [OriginModuleEnum.PeriodicMaintenance]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "TargetDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status",
      "ActualDuration",
      "CreatedAt",
    ],
    [OriginModuleEnum.PeriodicControl]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "TargetDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status",
      "ActualDuration",
      "CreatedAt",
    ],
    [OriginModuleEnum.RiskAnalysis]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "TargetDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "Status",
      "ActualDuration",
      "CreatedAt",
    ],
    [OriginModuleEnum.Commissioning]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "ActivationDate",
      "Title",
      "Description",
      "Status",
      "CreatedAt",
    ],
    [OriginModuleEnum.OutOfCommissioning]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "ActivationDate",
      "Title",
      "Description",
      "Status",
      "CreatedAt",
    ],
    [OriginModuleEnum.SafetyInstructionCard]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "ActivationDate",
      "Title",
      "Description",
      "Status",
      "CreatedAt",
    ],
    [OriginModuleEnum.InstructionCard]: [
      "LegalStructure",
      "Branch",
      "Building",
      "Location",
      "UniqueNumber",
      "ReportSubject.UniqueNumber",
      "ReportSubject.Sort",
      "ReportSubject.Kind",
      "ReportSubject.Type",
      "ActivationDate",
      "Title",
      "Description",
      "Status",
      "CreatedAt",
    ],
    [OriginModuleEnum.Document]: [
      "LegalStructure",
      "Branch",
      "UniqueNumber",
      "CreationDate",
      "Kind",
      "Type",
      "Title",
      "Description",
      "VersionNumber",
      "VersionDate",
      "VersionDescription",
      "Status",
      "CreatedAt",
    ],
  };

  useEffect(() => {
    const pristineEndpoints = (arxs.Api.getReportingEndPoints()
      .orderBy((x: any) =>  arxs.modules.titles[x.module])) as Array<ReportingEndpoint>;
    
    const moduleSettings = (props.value || {}).moduleSettings || [];
    const modulesWithDefinition = moduleSettings.map(x => x.module);
    const endpoints = modulesWithDefinition
      .flatMap(module => pristineEndpoints.filter(x => x.module === module))
      .concat(pristineEndpoints.filter(x => !modulesWithDefinition.includes(x.module)));

    setReportingEndpoints(endpoints);
  }, [props.value]);

  const handleEdit = (
    base: ReportModuleSetting,
    modifiedItem: ReportModuleSetting
  ) => {
    const newModuleSetting = { ...base, ...modifiedItem };

    const moduleSettings = (props.value || {}).moduleSettings || [];
    let newModuleSettings = moduleSettings
      .map(x => {
        if (x.module !== base.module) return x;
        return newModuleSetting;
      });

    // If it didn't exist, add it
    if (!newModuleSettings.some(x => x.module === modifiedItem.module)) {
      newModuleSettings = [newModuleSetting].concat(newModuleSettings);
    }

    const newValue = {
      ...props.value,
      moduleSettings: newModuleSettings,
    } as ReportDefinitionDetails;

    props.onChange && props.onChange(newValue);
  };

  const renderReportingEndpointConfig = (
    ep: ReportingEndpoint,
    index: number
  ) => {
    const { readOnly, editable, securityContext, value } = props;

    const item =
      ((value || {}).moduleSettings || []).filter(
        (x) => x.module === ep.module
      )[0] || {};

    const reportSchema = (() => {
      const schemaName: string =
        ep.endpoint.post.responses[200].content["application/json"].schema
          .items["$ref"];
      const splittedSchemaName = schemaName.split("/");
      return arxs.Api.getSchema(
        splittedSchemaName[splittedSchemaName.length - 1]
      );
    })();

    const getSubSchema = (ref: { ["$ref"]: string }) => {
      const splittedSchemaName = ref["$ref"].split("/");
      return arxs.Api.getSchema(
        splittedSchemaName[splittedSchemaName.length - 1]
      );
    };

    const getSchemaForPath = (path: string) => {
      const splittedPath = path.split(".");

      let currentSchema = reportSchema;
      for (let pathPart of splittedPath) {
        const reference = currentSchema.properties[StringExtensions.lowerCaseFirstLetter(pathPart)] as any;
        if (!reference) {
          return reference;
        }
        if (reference["$ref"]) {
          currentSchema = getSubSchema(reference);
        }
      }

      return currentSchema;
    };

    const getBase = () => {
      if (Object.keys(whiteListedModulesAndFields).includes(ep.module)) {
        const key = ep.module as keyof WhitelistedModulesAndField;

        let fieldsInScope = whiteListedModulesAndFields[key]
          .filter((x: string) => getSchemaForPath(x))
          .map((x: string) => x.split(".").map(x => StringExtensions.lowerCaseFirstLetter(x)).join(".") );

        if (!fieldsInScope.some((x) => x.toLowerCase() === "module")) {
          fieldsInScope = fieldsInScope.concat(["module"]);
        }

        return {
          api: { endPoint: ep.endpoint.url, httpMethod: "post" },
          fields: fieldsInScope,
          codeElements: [],
          module: ep.module,
        };
      }

      return {};
    };

    const hasConfig = () => {
      return (
        Object.keys(item).some((x) => x) &&
        item.fields &&
        item.fields.length > 0
      );
    };

    const renderPossibleConfiguration = () => {
      if (hasConfig()) {
        return <i className="far fa-check-circle"></i>;
      }

      return <Fragment></Fragment>;
    };

    const moveModuleConfig = (event: any, index: number, up: boolean) => {
      event.stopPropagation();
      
      const newIndex = up ? index - 1 : index + 1;
      const newModuleSettings = (value.moduleSettings || []).moveItem(index, newIndex);

      props.onChange({
        moduleSettings: newModuleSettings,
      } as ReportDefinitionDetails);
    };

    const renderLocationControl = () => {
      if (hasConfig()) {
        const canUp = index !== 0;
        const canDown =
          index < (props.value.moduleSettings || []).length - 1 &&
          (((props.value.moduleSettings || [])[index + 1] || {}).fields || [])
            .length > 0;

        return (
          <div className="report-definition-details-sort-actions">
            {canUp && (
              <div
                onClick={(event: any) => moveModuleConfig(event, index, true)}
              >
                <i className="fas fa-arrow-up"></i>
              </div>
            )}
            {canDown && (
              <div
                onClick={(event: any) => moveModuleConfig(event, index, false)}
              >
                <i className="fas fa-arrow-down"></i>
              </div>
            )}
          </div>
        );
      }

      return <Fragment></Fragment>;
    };

    const base = getBase();

    return (
      <Collapsible
        key={ep.module}
        open={false}
        trigger={
          <Fragment>
            {arxs.modules.titles[ep.module]} {renderPossibleConfiguration()}{" "}
            {renderLocationControl()}
          </Fragment>
        }
      >
        <ReportModuleConfig
          key={`module.${ep.module}`}
          editable={editable}
          readOnly={readOnly}
          value={item}
          base={base}
          onChange={(modified: ReportModuleSetting) =>
            handleEdit(base, modified)
          }
          securityContext={securityContext}
        ></ReportModuleConfig>
      </Collapsible>
    );
  };

  return (
    <div className={`report-definition-details ${props.className || ""}`}>
      {reportingEndpoints &&
        reportingEndpoints
          .filter((reportingEndpoint: any) =>
            Object.keys(whiteListedModulesAndFields).includes(
              reportingEndpoint.module
            )
          )
          .map((reportingEndpoint: any, index: number) =>
            renderReportingEndpointConfig(reportingEndpoint, index)
          )}
    </div>
  );
}
