import { RecommendationStatus } from 'infra/api/contracts';
import DocumentSigningWizardController from 'modules/DocumentSigningWizardController';
import arxs from 'infra/arxs';

class RecommendationWizard extends DocumentSigningWizardController {
    getRequiredFields(getCurrentFieldValue) {
        return [];
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        this.validateSignature(getCurrentFieldValue, preValidation);

        const needsSignature = getCurrentFieldValue("needsSignature");
        const status = getCurrentFieldValue("status");
        const legalStructure = getCurrentFieldValue("legalStructure");
        const branch = getCurrentFieldValue("branch");

        if (!branch && !legalStructure){
            preValidation["context"] = {error: arxs.t("wizard.validation.field_is_required", {field: arxs.t(`field.context`)})};
        }

        if (needsSignature && status && status === RecommendationStatus.Active){
            preValidation["status"] = {error: arxs.t("wizard.validation.status_signature_mismatch")}
        }

        return preValidation;
    }
}
export default new RecommendationWizard();
