import OlLayerTile from 'ol/layer/Tile';
import OlVectorLayer from 'ol/layer/Vector';
import { GeoJSON } from 'ol/format';
import { bbox } from 'ol/loadingstrategy';
import OlSourceVector from 'ol/source/Vector';
import OlSourceOsm from 'ol/source/OSM';
import OlSourceXyz from 'ol/source/XYZ';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlLayerGroup from 'ol/layer/Group';


// const layerGroup = new OlLayerGroup({
//   name: 'Layergroup',
//   layers: [
//     new OlLayerTile({
//       source: new OlSourceOsm(),
//       name: ''
//     }),
//     new OlLayerTile({
//       maxZoom: 20,
//       source: new OlSourceXyz({
//         url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
//       }),
//       name: "Satellite",
//       visible: false,
//     }),
//     new OlLayerTile({
//       name: 'OSM-Overlay-WMS',
//       minResolution: 0,
//       maxResolution: 200,
//       source: new OlSourceTileWMS({
//         url: 'https://ows.terrestris.de/osm/service',
//         params: {
//           'LAYERS': 'OSM-Overlay-WMS'
//         }
//       }),
//       visible: false,
//     }),
//     new OlVectorLayer({
//       name: 'Perceelgrenzen',
//       minZoom: 16,
//       source: new OlSourceVector({
//         format: new GeoJSON(),
//         url: function (extent) {
//           return `https://geoservices.informatievlaanderen.be/overdrachtdiensten/GRB/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=GRB:ADP&outputFormat=application%2Fjson&srsname=EPSG:3857&bbox=${extent.join(",")},EPSG:3857`
//         },
//         strategy: bbox
//       }),
//       visible: false,
//     }),
//   ]
// });

export enum MapLayerType {
  Osm = 0,
  Base = 1,
  Xyz = 2,
  Wms = 3,
  Vector = 4,
}

export interface MapLayerMetadata {
  name: string,
  type: MapLayerType,
  minZoom?: number | undefined,
  maxZoom?: number | undefined,
  url?: string | undefined,
  params?: any,
  visible?: boolean | undefined,
}

export interface MapMetadata {
  layers: Array<MapLayerMetadata>,
}

export const defaultMapMetadata: MapMetadata = {
  layers: [
    {
      name: "Kaartweergave",
      type: MapLayerType.Osm
    },
    {
      name: "Satellietweergave ",
      type: MapLayerType.Xyz,
      maxZoom: 20,
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
    },
    // {
    //   name: "OSM-Overlay-WMS",
    //   type: MapLayerType.Wms,
    //   url: "https://ows.terrestris.de/osm/service",
    //   params: {
    //     "LAYERS": "OSM-Overlay-WMS"
    //   }
    // },
    {
      name: "Perceelgrenzen",
      type: MapLayerType.Vector,
      minZoom: 16,
      url: "https://geoservices.informatievlaanderen.be/overdrachtdiensten/GRB/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=GRB:ADP&outputFormat=application%2Fjson&srsname=EPSG:3857&bbox="
    }
  ]
};

export const toLayerGroup = (mapMetadata: MapMetadata) => {
  const mapToLayer: (meta: MapLayerMetadata) => any[] = (meta: MapLayerMetadata) => {
    switch (meta.type) {
      case MapLayerType.Osm:
        {
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceOsm(),
            name: meta.name,
            visible: meta.visible || true,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Xyz:
        {
          const opts: any = {
            url: meta.url,
            params: meta.params
          };
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceXyz(opts),
            name: meta.name,
            visible: !!meta.visible,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Wms:
        {
          const opts: any = {
            url: meta.url,
            params: meta.params
          };
          const layer: any = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceTileWMS(opts),
            name: meta.name,
            visible: !!meta.visible,
          };
          return [new OlLayerTile(layer)];
        }
      case MapLayerType.Vector:
        {
          const layer = {
            minZoom: meta.minZoom,
            maxZoom: meta.maxZoom,
            source: new OlSourceVector({
              format: new GeoJSON(),
              url: function (extent) {
                return `${meta.url}${extent.join(",")},EPSG:3857`
              },
              strategy: bbox
            }),
            name: meta.name,
            visible: !!meta.visible,
          };
          return [new OlVectorLayer(layer)];
        }
    }
    return [];
  };
  const layers = mapMetadata.layers.flatMap(mapToLayer);

  const group: any = {
    name: "Layergroup",
    layers: layers
  };
  return new OlLayerGroup(group);
}