import React, { Fragment } from "react";
import arxs from 'infra/arxs'
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import { TextArea } from "components/controls/TextArea";

const DraggableFormListItem = (props) => {
    const { item, dragHandleProps, commonProps } = props;
    const { readOnly, onItemChange, onDeleteItem } = commonProps;

    const onTextChange = (state, field) => {
        onItemChange({ ...item, [field]: state });
    };
    const handleDelete = () => {
        onDeleteItem(item.id);
    };
    const getTextValue = (field) => {
        return (item || {})[field] || "";
    };

    return <div className="prerecommendation-container" key={(item && item.id) || 0}>
        <div className="prerecommendation-line">
            {!readOnly && <div className="drag-handle" {...dragHandleProps}>
                <i className="fa fas fa-grip-vertical"></i>
            </div>}
            <TextArea
                key={"preRecommendation-textarea"}
                readOnly={readOnly}
                field={{ getter: () => getTextValue("value"), setter: (state) => onTextChange(state, "value"), multiLine: false }}
                placeholder={arxs.t("forms.items.prerecommendation.value")} />
            <div className="prerecommendation-actions">
                {!readOnly && item && <div className="prerecommendation-action" onClick={handleDelete}>
                    <i className="far fa-trash-alt"></i>
                </div>}
            </div>
        </div>
    </div>
};

export default class PreRecommendation extends FormItemEditorController {
    lookups = {
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    getPreRecommendationTypes = () => {
        const { codeElements } = this.state;

        const code = 'Recommendation.PreAdvice';

        const preRecommendationKind = ((codeElements || {})[code] || [])[0] || {};
        const preRecommendationTypes = (preRecommendationKind.children || [])
            .map(x => ({ id: x.id, name: x.name }));
        return preRecommendationTypes;
    }

    renderPreRecommendation = (context, onItemChange, onDeleteItem, data) => {
        
    }

    getDraggableItemComponent() { return DraggableFormListItem };

    renderPreRecommendations = (context) => {
        const { codeElementsById } = this.state;
        const { field, readOnly } = this.props;
        const value = field.getter();
        const data = value.data || [];
        const settings = value.settings || {};

        const preRecommendationTypes = this.getPreRecommendationTypes();

        let selectedPreRecommendation;
        if (settings && codeElementsById) {
            if (arxs.uuid.isValid(settings.title)) {
                selectedPreRecommendation = settings.title
                    ? { id: settings.title, name: (codeElementsById[settings.title] || {}).name }
                    : undefined;
            } else if (settings.title) {
                selectedPreRecommendation = { name: settings.title };
            }
        }

        const onItemChange = (state, newSettings) => {
            let newData = (data || []);
            const index = newData.findIndex(x => x.id === state.id);
            if (index === -1) {
                newData.push(state);
            } else {
                newData[index] = state;
            }

            field.setter({ ...value, data: newData, settings: newSettings || settings });
        };
        const onPreRecommendationChange = (state) => {
            field.setter({ ...value, settings: { ...settings, title: (state ? state.id : null) } });
        };
        const onTitleAdd = (state) => {
            if (state) {
                field.setter({ ...value, settings: { ...settings, title: state.id || state.name } });
            }
        };
        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };
        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData, settings: settings });
        };

        return <div className="prerecommendation">
            <DropDown
                id={`prerecommendation-type`}
                key={`prerecommendation-type`}
                className="prerecommendation-type"
                items={preRecommendationTypes}
                selected={selectedPreRecommendation}
                onChange={onPreRecommendationChange}
                onAdd={onTitleAdd}
                readOnly={readOnly}
                placeholder={arxs.t("forms.items.prerecommendation.type")}
            />
            {this.renderDraggableList(data, { readOnly, onItemChange, onDeleteItem })}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}

        </div>;
    } 

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {/* {this.renderTitle(context)} */}
                        {this.renderPreRecommendations(context)}
                    </div>
                    {this.renderEnd(true)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}