import React from 'react';
import CodeElementComponent from './CodeElementComponent';
import { DropDownItem } from '../DropDown';
import MultiSelect from '../MultiSelect';

import './CodeElementMultiSelect.scss';

class CodeElementMultiSelect extends CodeElementComponent {
  clearValue = () => {
    const { selected, selectedParents } = this.props;

    if (!selectedParents || Object.keys(selectedParents).length === 0) {
      if (selected && Object.keys(selected).length > 0) {
        this.props.onChange([]);
      }
    }
  }

  render() {
    const className = `code-element-multiselect ${this.props.className || ""}`
    const placeholder = this.state.items.length > 0 ? this.state.items[0].name : undefined;
    const items = this.state.items.flatMap(x => x.items).filter((x: any) => x.isActive).orderBy(x => x.name);
    const selected = this.props.selected as DropDownItem[];
    const value = (selected || []).map(x => this.state.codeElementsById[x.id]).filter(x => x);

    return (
      <MultiSelect
        className={className}
        selected={value}
        onChange={this.props.onChange}
        placeholder={placeholder}
        items={items}
      />
    );
  }
}
export default CodeElementMultiSelect;