import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';

import './PlanningMomentEditor.scss';

export const createPlanningMomentEditor = (value, onSubmit, onCancel, onDelete) => {
  const state = {
    title: arxs.t("planning.edit_planning.title"),
    content: <PlanningMomentEditor value={value} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
    onSubmit: onSubmit,
    onClose: () => {
      if (onCancel) onCancel();
    }
  };

  return state;
};

function PlanningMomentEditor(props) {
  const planningMoment = props.value;
  const [data, setData] = useState(planningMoment);

  const close = () => props.onCancel && props.onCancel();
  const save = () => props.onSubmit && props.onSubmit(data);
  const remove = () => props.onDelete && props.onDelete(data);

  const rows = [
    [
      {
        name: "isAllDay",
      },
    ],
    // [
    //   {
    //     name: "start",
    //   },
    //   {
    //     name: "end",
    //   },
    // ],
    [
      {
        name: "start",
        props: { showTime: true },
      },
      {
        name: "end",
        props: { showTime: true },
      },
    ],
    [
      {
        name: "title",
      },
    ],
    [
      {
        name: "description",
      },
    ],
  ];

  return <div className={`planning-moment-editor-popup ${props.className || ""}`}>
    <div className="planning-moment-editor-popup-body">
      <FieldSet schemaName="PlanningMoment" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        {props.onDelete && <Button key={`planning-moment-editor-delete`} className="delete" onClick={remove}>{arxs.t("common.delete")}</Button>}
        <Button key={`planning-moment-editor-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`planning-moment-editor-save`} className="icon" onClick={save}>{arxs.t("common.save")}</Button>
      </div>
    </div>
  </div>;
}