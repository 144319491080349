/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ActivityType {
    Comment = 'Comment',
    Event = 'Event',
    StoredFile = 'StoredFile',
    EmailSent = 'EmailSent',
    EmailReceived = 'EmailReceived'
}

export function ActivityTypeFromJSON(json: any): ActivityType {
    return ActivityTypeFromJSONTyped(json, false);
}

export function ActivityTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityType {
    return json as ActivityType;
}

export function ActivityTypeToJSON(value?: ActivityType | null): any {
    return value as any;
}

