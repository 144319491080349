import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';
import { OriginModuleEnum } from "infra/api/contracts";

import './GanttLineEditor.scss';

export const createGanttLineEditor = (value, onSubmit, onCancel) => {
  const state = {
    title: arxs.t("gantt.edit_gantt.title"),
    content: <GanttLineEditor value={value} onSubmit={onSubmit} onCancel={onCancel} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
    onSubmit: onSubmit,
    onClose: () => {
      if (onCancel) onCancel();
    }
  };

  return state;
};

function GanttLineEditor(props) {
  const record = props.value;
  const [data, setData] = useState(record);

  const close = () => props.onCancel && props.onCancel();
  const save = () => props.onSubmit && props.onSubmit(data);

  let rows = [
    [
      {
        name: "title",
      }
    ],
    [
      {
        name: "startDate",
      },
      {
        name: "duration",
        unit: arxs.t("unit.day").toLowerCase(),
        props: { wholeNumber: true }
      }
    ],
    [
      {
        name: "effort",
        unit: arxs.t("unit.hour").toLowerCase(),
        props: { wholeNumber: true }
      },
      {
        name: "completionPercentage",
        unit: arxs.t("unit.percentage"),
        props: { wholeNumber: true }
      }
    ],
  ];

  if (!props.value || (props.value && !props.value.objectId && !props.value.templateId)) {
    rows = rows.concat([[
      {
        name: "module",
        values: [OriginModuleEnum.Task].orderBy(x => x),
      }
    ]]);
  }

  return <div className={`gantt-line-editor-popup ${props.className || ""}`}>
    <div className="gantt-line-editor-popup-body">
      <FieldSet schemaName="GanttLine" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`gantt-line-editor-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`gantt-line-editor-save`} className="icon" onClick={save}>{arxs.t("common.save")}</Button>
      </div>
    </div>
  </div>;
}