import React, { Component } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import TreeView from 'components/controls/treeview/TreeView'
import CodeElementLabel from 'components/controls/codeElements/CodeElementLabel'

import './CodeElementLookup.scss';

export const createCodeElementLookup = (module, onSubmit, singleSelection, rootCode, securityContext, additionalCodes, addParent, excludeCodes) => {
    const state = {
        title: arxs.t("controls.codeelementlookup.title"),
        content: <CodeElementLookup module={module} onSubmit={onSubmit} singleSelection={singleSelection} rootCode={rootCode} securityContext={securityContext} additionalCodes={additionalCodes} addParent={addParent} excludeCodes={excludeCodes} />
    };

    return state;
};

export class CodeElementLookup extends Component {
    lookups = {
        codeElementsById: {},
        codeElementsStructure: {}
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTreeViewItem: {},
            selectedTreeViewItemChildren: [],
            ...this.lookups,
            pristine: {},
            selected: {},
            filteredItems: []
        };

        if (props["module"]) {
            this.state.module = {
                name: props["module"],
                icon: arxs.modules.icons[props["module"]],
                title: arxs.modules.titles[props["module"]]
            };
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    onSubmit = async () => {
        const { singleSelection } = this.props;
        const { selected } = this.state;

        if (singleSelection) {
            const selectedCodeElement = selected[Object.keys(selected)[0]];

            if (selectedCodeElement) {
                let type = selectedCodeElement.type === arxs.codeElementTypes.type ? selectedCodeElement : undefined;
                let kind = type ? type.parent.type === arxs.codeElementTypes.kind ? type.parent : undefined : undefined;
                let sort = kind ? kind.parent.type === arxs.codeElementTypes.sort ? kind.parent : undefined : undefined;

                this.props.onSubmit({ sort, kind, type });
            }
        } else {
            const getCodeElementInfo = (selectedCodeElement) => {
                let type, kind, sort;
                if (selectedCodeElement.type === arxs.codeElementTypes.type) {
                    //regular selection - type
                    type = selectedCodeElement.type === arxs.codeElementTypes.type ? selectedCodeElement : undefined;
                    kind = type ? type.parent.type === arxs.codeElementTypes.kind ? type.parent : undefined : undefined;
                    sort = kind ? kind.parent.type === arxs.codeElementTypes.sort ? kind.parent : undefined : undefined;
                }
                else {
                    //Grouping selection, either kind or sort
                    if (selectedCodeElement.type === arxs.codeElementTypes.kind) {
                        kind = selectedCodeElement;

                        if (selectedCodeElement.parent.type === arxs.codeElementTypes.sort) {
                            sort = selectedCodeElement.parent;
                        }
                    } else if (selectedCodeElement.type === arxs.codeElementTypes.sort) {
                        sort = selectedCodeElement;
                    }
                }

                return { sort, kind, type };
            }

            if (selected) {

                var codeElementInfos = [];
                for (const key of Object.keys(selected)) {
                    codeElementInfos = codeElementInfos.concat([getCodeElementInfo(selected[key])]);
                }

                this.props.onSubmit(codeElementInfos);
            }
        }
    }

    onTreeViewItemClick = (item, children) => {
        this.setState({ selectedTreeViewItem: item, selectedTreeViewItemChildren: children }, () => this.onFilter(children));
    }

    onFilter = (items) => {
        let filteredItems = [];

        const getTypes = (item) => {
            if (item.type === arxs.codeElementTypes.kind) {
                filteredItems = filteredItems.concat(item.items);
            } else {
                if (item.items) {
                    for (const subItem of item.items) {
                        getTypes(subItem);
                    }
                }
            }
        }

        if (items) {
            for (const item of items) {
                getTypes(item);
            }

            this.setState({ filteredItems });
        }
    }

    handleCodeElementSelected = (codeElement) => {
        const { singleSelection } = this.props;
        let selected = this.state.selected;
        if (selected[codeElement.id]) {
            delete selected[codeElement.id];
        } else {
            if (singleSelection) {
                selected = [];
            }
            selected[codeElement.id] = codeElement;
        }
        this.setState({ selected });
    }

    handleCodeElementSelectedFromTree = (item, children) => {
        if (item) {
            this.onTreeViewItemClick(item, children);
            if (item.type === arxs.codeElementTypes.type) {
                this.handleCodeElementSelected(item);
            }
        }
    }

    render() {
        const { selectedTreeViewItem, selectedTreeViewItemChildren, filteredItems } = this.state;
        const { module, rootCode, additionalCodes, excludeCodes } = this.props;

        const renderParentLabel = (context) => {
            return <CodeElementLabel
                data={selectedTreeViewItem}
                id={selectedTreeViewItem.id}
                checkBox
                checked={!!this.state.selected[selectedTreeViewItem.id]}
                onClick={this.handleCodeElementSelected}
                key={selectedTreeViewItem.id}
                readOnly />
        }

        const renderCodeElementLabels = (context) => {
            let labels;
            if (selectedTreeViewItem && selectedTreeViewItemChildren && selectedTreeViewItemChildren.length > 0 && selectedTreeViewItem.type === arxs.codeElementTypes.kind) {
                labels = selectedTreeViewItemChildren
                    .filter(x => !x.isDeleted && !x.readOnly)
                    .map((codeElement) => <CodeElementLabel
                        data={codeElement}
                        id={codeElement.id}
                        checkBox
                        checked={!!this.state.selected[codeElement.id]}
                        onClick={this.handleCodeElementSelected}
                        key={codeElement.id}
                        readOnly />);
            } else {
                labels = (filteredItems || [])
                    .filter(x => !x.isDeleted && !x.readOnly)
                    .map((codeElement) => <CodeElementLabel
                        data={codeElement}
                        id={codeElement.id}
                        checkBox
                        checked={!!this.state.selected[codeElement.id]}
                        onClick={this.handleCodeElementSelected}
                        key={codeElement.id}
                        readOnly />);
            }

            if (this.props.addParent
                && selectedTreeViewItem
                && (selectedTreeViewItem.type === arxs.codeElementTypes.sort || selectedTreeViewItem.type === arxs.codeElementTypes.kind)) {
                    const includingParent = <div className="parent-and-children"><div className="parent">{renderParentLabel(context)}</div><div className="children">{labels}</div></div>
                return includingParent
            }

            return labels;
        }

        const submit = () => {
            if (Object.keys(this.state.selected).length > 0) {
                return <div className="codeElementlookup-submit" onClick={this.onSubmit}>
                    {arxs.t("controls.codeelementlookup.submit", { number: Object.keys(this.state.selected).length })}<i className="fas fa-plus"></i>
                </div>
                    ;
            };
        }

        return (
            <GlobalContext.Consumer>
                {(context) => <div className="codeElementlookup">
                    <div className="codeElementlookup-details">
                        <div className="codeElementlookup-treeview">
                            <div className="codeelementlookuptreeview">
                                <TreeView
                                    allCodes={true}
                                    onItemClick={context.platform.isMobile ? this.handleCodeElementSelectedFromTree : this.onTreeViewItemClick}
                                    module={module}
                                    rootCode={rootCode}
                                    excludeTypes={context.platform.isMobile ? false : true}
                                    securityContext={this.props.securityContext}
                                    onFilter={this.onFilter}
                                    rootExpanded={true}
                                    additionalCodes={additionalCodes}
                                    excludeCodes={excludeCodes}
                                    unforkTypes={true}
                                />
                            </div>
                        </div>
                        {!context.platform.isMobile && <div className="codeElementlookup-labels">{renderCodeElementLabels(context)}</div>}
                    </div>
                    {submit()}
                </div>}
            </GlobalContext.Consumer>
        );
    }
}