import { IncidentManagementSeriousInjuryRangeType, IncidentManagementVictimType, OriginModuleEnum } from 'infra/api/contracts';
import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';

class IncidentWizard extends WizardController {

    onLookupsChange(stateProxy) {
        this.getContext(stateProxy);
    }

    toggleLocationType = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const { geoLocation } = data;

            const newLocationType = geoLocation ? "External" : "Internal";
            const newData = { ...data, locationType: newLocationType };

            stateProxy.setter({ data: newData }, resolve);
        });
    }

    setVictimFields = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const employeeMap = stateProxy.getter("employeeMap");
            const contactMap = stateProxy.getter("contactMap");
            const data = stateProxy.getter("data");
            const { victim } = data;

            let newVictimType;
            let newVictimDepartment;
            let newVictimFunction;

            if (victim) {
                switch (victim.module) {
                    case OriginModuleEnum.Employee:
                        newVictimType = IncidentManagementVictimType.Employee;
                        const employee = employeeMap && employeeMap[victim.id];
                        const preferredAssignment = (employee || {}).assignments.some(x => x.isPreferred) ? employee.assignments.filter(x => x.isPreferred)[0] : employee.assignments[0];
                        newVictimDepartment = (preferredAssignment || {}).division;
                        newVictimFunction = (preferredAssignment || {}).function;
                        break;
                    case OriginModuleEnum.Student:
                        newVictimType = IncidentManagementVictimType.Student;
                        break;
                    case OriginModuleEnum.Contact:
                        newVictimType = IncidentManagementVictimType.ThirdParty;
                        const contact = contactMap && contactMap[victim.id];
                        newVictimDepartment = (contact || {}).department;
                        newVictimFunction = (contact || {}).function;
                        break;
                    default: break;
                }
            } else {
                newVictimType = IncidentManagementVictimType.None;
            }

            const newData = {
                ...data, victimType: newVictimType, victimDepartment
                    : newVictimDepartment, victimFunction: newVictimFunction
            };

            stateProxy.setter({ data: newData }, resolve);
        });
    }

    // Keep this logic below inline with incident post (save) api
    determineIsSerious = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const codeElementsById = stateProxy.getter("codeElementsById");
            const seriousInjuryRanges = stateProxy.getter("seriousInjuryRanges");

            const getCodeOfCodeElement = (codeElement) => {
                if (codeElement) {
                    const foundCodeElement = codeElementsById[codeElement.id];

                    if (foundCodeElement) {
                        return parseInt(foundCodeElement.code);
                    }
                }

                return -1;
            }

            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");

            if (data.isDeadly === true) {
                stateProxy.setter({ data: { ...data, isSerious: true } }, resolve);
            } else {
                if (codeElementsById && seriousInjuryRanges) {
                    const divergentIncidentCodeElement = data.divergentIncident || pristine.divergentIncident;
                    const materialCauseCodeElement = data.materialCause || pristine.materialCause;
                    const injuryTypeCodeElement = data.accidentInjuryType || pristine.accidentInjuryType;
                    const isPermanentInjury = data.isPermanent || pristine.isPermanent;

                    const divergentNumber = getCodeOfCodeElement(divergentIncidentCodeElement);
                    const materialCauseNumber = getCodeOfCodeElement(materialCauseCodeElement);
                    const injuryTypeNumber = getCodeOfCodeElement(injuryTypeCodeElement);

                    const isOnDivergentList = seriousInjuryRanges
                        .filter(x => x.type === IncidentManagementSeriousInjuryRangeType.DivergentIncident)
                        .some(x => divergentNumber >= x.from
                            && divergentNumber <= x.to);
                    const isOnMaterialCauseList = seriousInjuryRanges
                        .filter(x => x.type === IncidentManagementSeriousInjuryRangeType.MaterialCause)
                        .some(x => materialCauseNumber >= x.from
                            && materialCauseNumber <= x.to);
                    const isOnInjuryTypeList = seriousInjuryRanges
                        .filter(x => x.type === IncidentManagementSeriousInjuryRangeType.InjuryType)
                        .some(x => injuryTypeNumber >= x.from
                            && injuryTypeNumber <= x.to);

                    const result = (isOnDivergentList || isOnMaterialCauseList) && (isOnInjuryTypeList || isPermanentInjury);

                    stateProxy.setter({ data: { ...data, isSerious: result } }, resolve);
                }
            }
        });
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        const legalStructure = getCurrentFieldValue("legalStructure");
        const branch = getCurrentFieldValue("branch");

        if (!branch && !legalStructure){
            preValidation["context"] = {error: arxs.t("wizard.validation.field_is_required", {field: arxs.t(`field.context`)})};
        }

        return preValidation;
    }
}
export default new IncidentWizard();
