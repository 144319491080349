import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import { SecurityContext } from "infra/SecurityContext";
import GlobalContext from "infra/GlobalContext";
import { MailAttachmentType, ObjectDocumentType } from "infra/api/contracts";
import { MailAttachment, OriginModuleEnum } from "infra/api/contracts";
import { createDocumentPreview } from "components/controls/documents/DocumentPreview";
import CheckBox from "components/controls/CheckBox";

import "./MailAttachments.scss";

export interface MailAttachmentsProps {
  value: MailAttachment[];
  onChange(value: MailAttachment[]): void;
  readOnly?: boolean;
  securityContext?: SecurityContext;
  id?: string;
  module?: OriginModuleEnum;
  mailId?: string;
}

interface CheckableMailAttachment {
  id?: string | null;
  name?: string | null;
  url?: string | null;
  type?: MailAttachmentType;
}

export default function MailAttachments(props: MailAttachmentsProps) {
  const [data, setData] = useState<CheckableMailAttachment[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const module = props.module;

    if (!props.readOnly && module) {
      const resource = arxs.Api.getResource(module);

      resource.getById(props.id).then((x: any) => {
        const meta = arxs.moduleMetadataRegistry.get(module);
        const reports = ((meta.board || {}).reports || [])
          .filter((report: any) => !!report.alwaysOn === false)
          .map((report: any) => ({
            id: report.name,
            name: arxs.t(`report.${report.name}`) + " " + x.uniqueNumber,
            type: MailAttachmentType.Report,
          }));

        const images = (
          (
            ((x.attachmentInfo || {}).attachments || []).filter(
              (y: any) => y.type === ObjectDocumentType.Image
            )[0] || {}
          ).value || []
        )
          .filter((z: any) => !z.isDeleted)
          .map((a: any) => ({
            id: a.id,
            name:
              (a.props && a.props.name) ||
              (
                ((x.attachmentInfo || {}).storedFiles || []).filter(
                  (b: any) => b.id === a.id
                )[0] || {}
              ).name,
            type: MailAttachmentType.Image,
          }));

        const documents = (
          ((x.attachmentInfo || {}).attachments || [])
            .filter(
              (y: any) =>
                ![
                  ObjectDocumentType.Image,
                  ObjectDocumentType.FormImage,
                  ObjectDocumentType.FormDocument,
                ].includes(y.type)
            )
            .flatMap((c: any) => c.value) || []
        )
          .filter((z: any) => !z.isDeleted)
          .map((a: any) => ({
            id: a.id,
            name:
            (a.props && a.props.name) ||
              (
                ((x.attachmentInfo || {}).storedFiles || []).filter(
                  (b: any) => b.id === a.id
                )[0] || {}
              ).name ||
              (
                ((x.attachmentInfo || {}).documents || []).filter(
                  (b: any) => b.id === a.id
                )[0] || {}
              ).name,
            type: MailAttachmentType.Document,
          }));

        setData(reports.concat(documents).concat(images));
      });
    }
  }, []);

  const handleDelete = (attachment: CheckableMailAttachment) => {
    const newValue = props.value.filter(
      (x) => !(x.id === attachment.id && x.type === attachment.type)
    );
    props.onChange(newValue);
  };

  const renderActions = (attachment: CheckableMailAttachment) => {
    return (
      !props.readOnly && (
        <i
          className="far fa-times"
          onClick={() => handleDelete(attachment)}
        ></i>
      )
    );
  };

  const toggleAttachment = (attachment: CheckableMailAttachment) => {
    let newValue;
    if (isChecked(attachment)) {
      newValue = props.value.filter(
        (x) => !(x.id === attachment.id && x.type === attachment.type)
      );
    } else {
      newValue = props.value.concat([
        { id: attachment.id, name: attachment.name, type: attachment.type },
      ]);
    }
    props.onChange(newValue);
  };

  const renderPopupItem = (attachment: CheckableMailAttachment) => {
    const getIconClass = () => {
      switch (attachment.type) {
        case MailAttachmentType.Report:
          return "far fa-file-pdf";
        case MailAttachmentType.Image:
          return "far fa-image";
        case MailAttachmentType.Document:
          return "far fa-file";
      }
    };
    return (
      <div
        key={attachment.id}
        className="attachments-popup-item"
        onClick={() => toggleAttachment(attachment)}
      >
        <i className={getIconClass()}></i>
        <div className="attachments-popup-item-name">{attachment.name}</div>
        <CheckBox
          className="list-card-checkbox"
          onChange={() => {}}
          checked={isChecked(attachment)}
        />
      </div>
    );
  };

  const handleClick = (context: any, attachment: CheckableMailAttachment) => {
    // Remark: https://trello.com/c/Q2yAnlDe/338-alg-mail-versturen-vanuit-record-implementatie-stap-1
    arxs.ApiClient.shared.attachment
        .retrieveDocument({
          module: OriginModuleEnum.Mailing,
          objectId: props.mailId,
          attachmentId: attachment.id,
          preview: true,
        })
        .then((url: any) => {
          const name = attachment.name || "";
          const downloadInfo = { url };
          const previewInfo = { url, name: name, documentManagementUrl: "" };
          let documentPreview = createDocumentPreview(previewInfo, downloadInfo);
          context.popup.show(documentPreview)
        });
  };

  const isChecked = (attachment: CheckableMailAttachment) => {
    return props.value.any(
      (x) => attachment.id === x.id && attachment.type === x.type
    );
  };

  const className = ["attachment", props.readOnly && "readonly"]
    .filter((x) => x)
    .join(" ");

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className="attachments">
          {(props.value || []).map((attachment) => (
            <div
              key={attachment.id}
              className={className}
              onClick={props.readOnly ? () => handleClick(context, attachment) : undefined}
            >
              <div className="name" title={attachment.name || undefined}>{attachment.name}</div> {renderActions(attachment)}
            </div>
          ))}
          {!props.readOnly && (
            <button className="button icon" onClick={() => setIsAdding(true)}>
              <i className="far fa-plus"></i>
            </button>
          )}
          {isAdding && data && (
            <div className="attachments-popup">
              <i
                className="far fa-times"
                onClick={() => setIsAdding(false)}
              ></i>
              <div className="attachments-popup-items">
                {data.map(renderPopupItem)}
              </div>
            </div>
          )}
        </div>
      )}
    </GlobalContext.Consumer>
  );
}
