import React, { Component, Fragment } from "react";
import AutoSizer from 'react-virtualized-auto-sizer';
import { List } from 'react-virtualized';

import TextInput from 'components/controls/TextInput';
import ListItem from 'components/controls/ListItem';
import CheckBox from "components/controls/CheckBox";

import "./ItemSelector.scss";

export default class ItemSelector extends Component {
  state = {
    searchTerm: "",
    filtered: []
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.filter();
    }
  }

  filter = () => {
    const searchTerm = this.state.searchTerm;
    const filtered = (this.props.data || []).filter(x => x.name.search(new RegExp(searchTerm, "i")) > -1);
    this.setState({ filtered });
  }

  handleChangeSearchTerm = (event) => {
    this.setState({ searchTerm: event.target.value }, this.filter);
  }

  checkboxSelect = (event, item) => {
    this.select(event, item);
  }

  listItemSelect = (event, item) => {
    this.select(event, item);
  }

  select = (event, item) => {
    event.stopPropagation();

    const selected = { ...this.props.selected };
    if (selected[item.id]) {
      delete selected[item.id];
    } else {
      selected[item.id] = true;
    }
    if (this.props.onChange) {
      this.props.onChange(selected);
    }
  }

  renderItem = (item) => {
    const selected = this.props.selected || {};
    return <Fragment>
      {this.props.renderItem ? this.props.renderItem(item) : item.name}
      <CheckBox checked={!!selected[item.id]} />
    </Fragment>;
  }

  render() {
    const className = ["item-list-item", this.props.className].filter(x => x).join(" ");
    const data = (this.state.searchTerm ? this.state.filtered : this.props.data) || [];

    return <div className="item-selector">
      <TextInput
        value={this.state.searchTerm}
        onChange={this.handleChangeSearchTerm}
        placeholder={this.props.placeholder}
        icon="far fa-search" />
      <div className="item-list">
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowCount={data.length}
              rowHeight={61}
              rowRenderer={
                ({ index, isScrolling, key, style }) => {
                  return <ListItem key={key} style={style} className={className} data={data[index]} onClick={this.listItemSelect}>
                    {this.renderItem(data[index])}
                  </ListItem>;
                }
              }
            />
          )}
        </AutoSizer>
      </div>
    </div>;
  }
}