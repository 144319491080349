/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IncidentV2Status {
    Pending = 'Pending',
    ToInvestigate = 'ToInvestigate',
    Completed = 'Completed'
}

export function IncidentV2StatusFromJSON(json: any): IncidentV2Status {
    return IncidentV2StatusFromJSONTyped(json, false);
}

export function IncidentV2StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentV2Status {
    return json as IncidentV2Status;
}

export function IncidentV2StatusToJSON(value?: IncidentV2Status | null): any {
    return value as any;
}

