import React, { Component, Fragment } from "react";
import ListItem from 'components/controls/ListItem';

import './ToggleButton.scss';

interface ToggleButtonProps {
  value: {},
  values: Array<any>,
  textSelector?(value: {}): string,
  renderItem?(value: {}): Component,
  onChange?(_: any, value: {}): void,
  getItemClassName?(value: {}): string,
  readOnly?: boolean,
}

export default class ToggleButton extends Component<ToggleButtonProps> {
  render() {
    const { values, textSelector } = this.props;
    const renderItem = this.props.renderItem || ((value: any) => {
      if (textSelector) {
        return <Fragment>{textSelector(value)}</Fragment>
      }
      return <Fragment>{value}</Fragment>;
    });
    const getItemClassName = this.props.getItemClassName || ((value: {}) => "");
    const onChange = (e: any, value: any) => {
      if (this.props.onChange && !this.props.readOnly) {
        this.props.onChange(e, value);
      }
    };

    const classNames = [
      "toggle-button",
      this.props.readOnly && "disabled"
    ].filter(x => x).join(" ");

    return <div className={classNames}>
      {values.map(x => <ListItem key={x}
        className={`toggle ${this.props.value === x ? "on" : "off"} ${getItemClassName(x)}`}
        data={x}
        onClick={onChange}
      >
        {renderItem(x)}
      </ListItem>)}
    </div>;
  }
}
