import React, { Component, Fragment } from "react";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Avatar from "./images/Avatar";
import arxs from "infra/arxs";

import "./DropDown.scss";
import { FilterDescriptor } from "@progress/kendo-react-dropdowns/common/filterDescriptor";

export interface DropDownItem {
  id: string;
  code?: string;
  name: string;
  content?: React.Component;
  image?: string;
  getImage?(): string | undefined;
  icon?: string;
  getIcon?(): string | undefined;
  title?: string;
  getTitle?(): string | undefined;
  isActive?: boolean;
}

export interface DropDownProps {
  className?: string;
  items: Array<DropDownItem>;
  selected: DropDownItem;
  onChange(value: Array<DropDownItem>): void;
  placeholder?: string;
  idField?: string;
  textField?: string;
  id?: string;
  readOnly?: boolean;
  sortByCode?: boolean;
  width?: string;
  disableClearButton?: boolean;
  onAdd?(value: any): void;
}

interface DropDownState {
  items: Array<DropDownItem>;
  filter?: FilterDescriptor;
}

class DropDown extends Component<DropDownProps, DropDownState> {
  state: DropDownState = {
    items: [],
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps: DropDownProps) {
    const prevItems = prevProps.items;
    const items = this.props.items;
    if (!items.map((x) => x.id).valueEquals(prevItems.map((x) => x.id))) {
      this.refresh();
    }
  }

  refresh = () => {
    let items = this.props.items.filter(x => x.isActive !== false);
    if (this.props.selected) {
      const match = this.props.items.filter(x => x.id === this.props.selected.id)[0];
      if (match && match.isActive === false) {
        items.push(match);
      }
    }
    const filter = this.state.filter;
    if (filter) {
      this.setState({ items: filterBy(items.slice(), filter) });
    } else {
      this.setState({ items });
    }
  };

  handleChange = (event: ComboBoxChangeEvent) => {
    const value = event.value;
    if (this.props.onAdd && value && !value.id) {
      this.props.onAdd(value);
    } else if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
    this.setState({ filter: event.filter }, this.refresh);
  };

  renderItem = (li: any, itemProps: ListItemProps) => {
    const textField = this.props.textField || "name";
    const item = itemProps.dataItem;
    const hasImage = item.getImage || item.image;
    const image = item.getImage ? item.getImage() : item.image;
    const icon = item.getIcon ? item.getIcon() : item.icon;
    const title = item.getTitle ? item.getTitle() : item[textField];
    const classes = [
        "dropdown-item-title",
        // Explicit false comparison since a null/undefined value should be considered true
        item.isActive === false && "strikethrough"
      ]
      .filter(x => x)
      .join(" ");

    const itemChildren = item.content ? (
      item.content
    ) : (
        <Fragment>
          {icon && <div className="dropdown-item-icon"><i className={icon}></i></div>}
          {hasImage && <Avatar src={image} fullName={title} />}
          <div className={classes} title={title}>
            {title}
          </div>
        </Fragment>
      );

    return React.cloneElement(li, li.props, itemChildren);
  };

  render() {
    const items = this.state.items || [];
    const className = this.props.className;
    const placeholder =
      this.props.placeholder || arxs.t("controls.common.select");
    const idField = this.props.idField || "id";
    const textField = this.props.textField || "name";
    const readOnly = this.props.readOnly;
    const width = this.props.width;

    return (
      <ComboBox
        className={`dropdown ${className || ""}`}
        data={items}
        itemRender={this.renderItem}
        value={this.props.selected || null}
        onChange={this.handleChange}
        filterable={true}
        onFilterChange={this.handleFilterChange}
        placeholder={placeholder}
        dataItemKey={idField}
        textField={textField}
        disabled={readOnly}
        style={width ? { width: width } : {}}
        clearButton={!!!this.props.disableClearButton}
        allowCustom={!!this.props.onAdd}
        popupSettings={{ className: "dropdown-popup" }}
      />
    );
  }
}
export default DropDown;
