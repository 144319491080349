import throttle from 'lodash/throttle';

const OFFSET = 50;
const PX_DIFF = 50;

let scrollIncrement = 0;
let isScrolling = false;
let scrollBoardElement = null, detailsPaneElement = null;

const goUp = () => {
    scrollIncrement -= PX_DIFF;
    scrollBoardElement.scrollTop = scrollIncrement;

    if (isScrolling && scrollIncrement >= 0) {
        window.requestAnimationFrame(goUp);
    }
};

const goDown = () => {
    scrollIncrement += PX_DIFF;
    scrollBoardElement.scrollTop = scrollIncrement;

    if (isScrolling && scrollIncrement <= scrollBoardElement.scrollHeight) {
        window.requestAnimationFrame(goDown);
    }
};

const goLeft = () => {
    scrollIncrement -= PX_DIFF;
    scrollBoardElement.scrollLeft = scrollIncrement;

    if (isScrolling && scrollIncrement >= 0) {
        window.requestAnimationFrame(goLeft);
    }
};

const goRight = () => {
    scrollIncrement += PX_DIFF;
    scrollBoardElement.scrollLeft = scrollIncrement;

    if (isScrolling && scrollIncrement <= scrollBoardElement.scrollWidth) {
        window.requestAnimationFrame(goRight);
    }
};

const onDragOver = (event) => {
    const clientRect = scrollBoardElement.getBoundingClientRect();
    const isMouseOnTop = (
        scrollIncrement >= 0 && event.clientY > clientRect.top
        && event.clientY < (clientRect.top + OFFSET)
    );
    const isMouseOnBottom = (
        scrollIncrement <= scrollBoardElement.scrollHeight
        && event.clientY > (window.innerHeight - OFFSET)
        && event.clientY <= window.innerHeight
    );

    const isMouseOnLeft = (
        scrollIncrement >= 0 && event.clientY > clientRect.left
        && event.clientX < (clientRect.left + OFFSET)
    );
    const isMouseOnRight = (
        scrollIncrement <= scrollBoardElement.scrollWidth
        && event.clientX > (window.innerWidth - OFFSET)
        && event.clientX <= window.innerWidth
    );

    if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
        isScrolling = true;
        scrollIncrement = scrollBoardElement.scrollTop;

        if (isMouseOnTop) {
            window.requestAnimationFrame(goUp);
        }
        else {
            window.requestAnimationFrame(goDown);
        }
    } if (!isScrolling && (isMouseOnLeft || isMouseOnRight)) {
        isScrolling = true;
        scrollIncrement = scrollBoardElement.scrollLeft;

        if (isMouseOnLeft) {
            window.requestAnimationFrame(goLeft);
        }
        else {
            window.requestAnimationFrame(goRight);
        }
    }
    else if (!isMouseOnTop && !isMouseOnBottom && !isMouseOnRight && !isMouseOnLeft) {
        isScrolling = false;
    }
};


const throttleOnDragOver = throttle(onDragOver, 100);

const addEventListenerBoardScroll = () => {
    scrollBoardElement = document.querySelector('.board-scroller');
    if (scrollBoardElement) {
        scrollBoardElement.addEventListener('dragover', throttleOnDragOver);
    }

    detailsPaneElement = document.querySelector('.details-pane');
    if (detailsPaneElement) {
        detailsPaneElement.addEventListener('dragover', throttleOnDragOver);
    }
};


const removeEventListenerForBoardScroll = () => {
    if (scrollBoardElement) {
        scrollBoardElement.removeEventListener('dragover', throttleOnDragOver);
    }
    if (detailsPaneElement) {
        detailsPaneElement.removeEventListener('dragover', throttleOnDragOver);
    }
    isScrolling = false;
};

export default {
    addEventListenerBoardScroll,
    removeEventListenerForBoardScroll
};