import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import ProjectWizard from "./ProjectWizard";
import ProjectActions from "./ProjectActions";
import ProjectBoard from "./ProjectBoard";
import {
  OriginModuleEnum,
  ProjectStatus,
  RelationshipType,
  ObjectDocumentType
} from "infra/api/contracts";
import ProjectCard from "./ProjectCard";

const getProjectMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Project;

  return {
    module,
    title: arxs.t("modules.project"),
    icon: "fas fa-tasks-alt",
    path: "/project",
    base: {
      name: "Project",
      route: "/project",
      getResource: () => arxs.Api.getResource("project"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "Project.Read",
      writeAction: "Project.Write",
    },
    settings: {
      name: "ProjectSettings",
      route: "/project/settings",
      getResource: () => arxs.ApiClient.facilitymanagement.projectSettings,
      readAction: "Project.Settings.Read",
      writeAction: "Project.Settings.Write",
      steps: [
        {
          title: arxs.t("field.processFlows"),
          fields: [
            [
              {
                name: "processFlows",
                type: "itemlist",
                children: [
                  { name: "legalStructure" },
                  { name: "branch", parent: "legalStructure" },
                  { name: "kind", code: "Project.Kind" },
                  { name: "type", parent: "kind", code: "Project.Type" },
                  { name: "employee" },
                  { name: "userRole" },
                  {
                    name: "relationshipType",
                    values: [
                      RelationshipType.Responsible,
                      RelationshipType.CoResponsible,
                      RelationshipType.Cc,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      ],
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "title", fullWidth: true },
          { name: "description" },
          { name: "internalNumber" },
          { name: "type" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructures" },
          { name: "branches" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.authorization"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructureAuthorizations", fullWidth: true },
          { name: "branchAuthorizations", fullWidth: true },
          { name: "employeeAuthorizations", fullWidth: true },
          { name: "userRoleAuthorizations", fullWidth: true },
        ],
      },
      {
        title: arxs.t("details_pane.relationships"),
        initiallyOpen: true,
        fields: [{ name: "relationships" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument
    ],
    statuses: [
      ProjectStatus.InProcess,
      ProjectStatus.Active,
      ProjectStatus.OnHold,
      ProjectStatus.Completed,
    ],
    actions: ProjectActions.getActions(OriginModuleEnum.Project),
    wizard: {
      wizardClass: ProjectWizard,
      steps: [
        {
          title: arxs.t("wizard.common.identification"),
          fields: [
            [
              {
                name: "title",
                title: arxs.t("wizard.field.title"),
              },
            ],
            [
              {
                name: "description",
                title: arxs.t("wizard.field.description"),
              }
            ],
            [{ name: "internalNumber" }],
            [
              {
                name: "legalStructures",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  condensed: true,
                },
                onChange: (stateProxy, fieldName: string) =>
                  ProjectWizard.applyprocessFlowForMultiple(stateProxy).then(
                    () => ProjectWizard.syncScopeTree(stateProxy, fieldName)
                  ),
              },
              {
                name: "branches",
                type: "cardlist",
                props: { modules: [OriginModuleEnum.School], condensed: true },
                onChange: (stateProxy, fieldName: string) =>
                  ProjectWizard.applyprocessFlowForMultiple(stateProxy).then(
                    () => ProjectWizard.syncScopeTree(stateProxy, fieldName)
                  ),
              },
            ],
            [
              {
                name: "buildings",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Building],
                  condensed: true,
                },
                onChange: (stateProxy, fieldName: string) =>
                  ProjectWizard.syncScopeTree(stateProxy, fieldName),
              },
              {
                name: "locations",
                type: "cardlist",
                props: { modules: [OriginModuleEnum.Room], condensed: true },
                onChange: (stateProxy, fieldName: string) =>
                  ProjectWizard.syncScopeTree(stateProxy, fieldName),
              },
            ],
            [
              {
                name: "subjects",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                  ],
                },
                onChange: (stateProxy, fieldName: string) =>
                  ProjectWizard.syncScopeTree(stateProxy, fieldName),
              },
            ],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "Project.Kind" },
                parent: "kind",
                title: arxs.t("field.category"),
                onChange: (stateProxy) => {
                  ProjectWizard.applyprocessFlowForMultiple(stateProxy);
                },
              },
              { name: "geoLocation" },
            ],
            [{ name: "tags", type: "tag" }],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructures", "branches"].map(getValue);
            return arxs.securityContext.buildForMultipleContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("wizard.common.details"),
          fields:
            [
              [
                {
                  name: "responsible",
                  type: "cardlist",
                  props: {
                    "modules": [OriginModuleEnum.Employee],
                    "condensed": true,
                    "overridePrefilter": true
                  }
                }
              ],
              [{
                name: "relationships",
                type: "relationshiplist",
                props: {
                  "types": [RelationshipType.CoResponsible, RelationshipType.Cc]
                }
              }],
              [
                {
                  name: "estimatedDuration",
                  unit: arxs.t("unit.day").toLowerCase(),
                  props: { wholeNumber: true }
                },
                { name: "estimatedCost" }
              ],
              [
                { name: "startDate" },
                { name: "status", values: [ProjectStatus.InProcess, ProjectStatus.Active, ProjectStatus.OnHold, ProjectStatus.Completed] },
              ],
            ]
        },
        {
          title: arxs.t("details_pane.authorization"),
          fields: [
            [
              {
                name: "legalStructureAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  condensed: true,
                },
                title: arxs.t("field.legalStructures"),
              },
              {
                name: "branchAuthorizations",
                type: "cardlist",
                props: { modules: [OriginModuleEnum.School], condensed: true },
                title: arxs.t("field.branches"),
              },
            ],
            [
              {
                name: "employeeAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "userRoleAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.UserRole],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      addNewState: ProjectStatus.InProcess,
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
      },
    },
    board: {
      lookups: {
        projects: [],
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        codeElementsById: {},
        taskMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructureNames" },
        { name: "brancheNames" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "responsible" },
        { name: "coResponsibles" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: ProjectBoard,
      bucketConfig: [
        {
          module: module,
          status: ProjectStatus.OnHold,
          onDrop: (state) => ProjectActions.dropActions().onDropInOnHold(state),
          requiredActions: ["hold"],
        },
        {
          module: module,
          status: ProjectStatus.InProcess,
          onDrop: (state) =>
            ProjectActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
        {
          module: module,
          status: ProjectStatus.Active,
          onDrop: (state) => ProjectActions.dropActions().onDropInActive(state),
          requiredActions: ["activate"],
        },
        {
          module: module,
          status: ProjectStatus.Completed,
          onDrop: (state) =>
            ProjectActions.dropActions().onDropInCompleted(state),
          requiredActions: ["complete"],
        },
      ],
      additionalStatuses: [],
      views: [
        BoardViewType.Gantt,
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: ProjectCard
  };
};

export default getProjectMetadata;