import React, { useState, useEffect } from 'react';
import arxs from "infra/arxs";
import { ObjectDocumentType } from 'infra/api/contracts';

import './Avatar.scss';

export default function Avatar(props) {
  const initialLookups = {
    employeeMap: {},
  };

  const [employee, setEmployee] = useState({});
  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  useEffect(() => {
    if (props.userId) {
      setEmployee(lookups.employeeMap[props.userId]);
    }
  }, [props.userId, lookups]);


  const fullName = (employee && employee.name) || props.fullName;
  const firstImageId = ((((((employee || {}).attachmentInfo || {}).attachments || []).filter(x => x.type === ObjectDocumentType.Image)[0] || {}).value || []).filter(x => !x.isDeleted)[0] || {}).id;
  const firstImageUrl = (firstImageId && ((((employee || {}).attachmentInfo || {}).storedFiles || []).filter(x => x.id === firstImageId)[0].url)) || props.src || "";
  let url = (firstImageUrl) || props.src || "";

  if (url === "") {
    url = "images/anonymous.png";
  } else {
    url = arxs.ImageProxy.resizeImage(url, 100, 100);
  }

  return (
    <div className={`avatar ${props.className || ""}`}
      style={{ backgroundImage: `url("${url}")` }}
      title={fullName}></div>
  );
}