import React, { } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import MultiSelect from 'components/controls/MultiSelect';

import "./ModuleSelector.scss";

export default function ModuleSelector(props) {
    const defaultModules = arxs.moduleMetadataRegistry.supportedModules.except(props.exclude || []);
    const modules = (props.modules || defaultModules)
        .filter(arxs.moduleMetadataRegistry.isModuleAllowed)
        .map(x => ({
            icon: arxs.modules.icons[x],
            name: arxs.modules.titles[x],
            key: arxs.modules.keys[x],
            id: arxs.modules.keys[x]
        }))
        .filter(x => x.id);
    return <GlobalContext.Consumer>
        {(context) => <MultiSelect
            className={["module-selector", props.className].filter(x => x).join(" ")}
            placeholder={arxs.t("card_lookup.module")}
            items={modules}
            selected={props.selected.map(x => ({ id: x }))}
            onChange={props.onChange}
            readOnly={props.readOnly}
            singleSelection={props.singleSelection}
        />}
    </GlobalContext.Consumer>;
}