import React, { Component } from "react";

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import { FormStatus, OriginModuleEnum, ObjectDocumentType } from "infra/api/contracts";
import { Feature } from "infra/Features";

import { createInputPopupWithOptions } from 'components/shell/InputPopup/InputPopup';
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import Toaster from 'components/util/Toaster';
import { Spinner } from "components/animations/Spinner";

import FormImportValidator from "./ImportExport/FormImportValidator";
import formControlRegistry, { toControlKey } from "./FormControlRegistry";
import FormSectionEditor from "./FormSectionEditor";
import formDefinitionSelector from "./FormDefinitionSelector";

import "./FormEditor.scss";

const imageMimeTypeMap = {
  'jpg': 'image/jpeg',
  'jpe': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'png': 'image/png',
  'gif': 'image/gif',
  'bmp': 'image/bmp',
  'tiff': 'image/tiff',
  'tif': 'image/tiff',
  'webp': 'image/webp',
  'ico': 'image/x-icon',
  'svg': 'image/svg+xml',
  'heif': 'image/heif',
  'heic': 'image/heic',
  'avif': 'image/avif',
};

export default class FormEditor extends Component {
  lookups = {
    iconByNameMap: {},
    formMap: {},
  }

  constructor(props) {
    super(props);
    
    const whitelistedControls = formControlRegistry.getWhitelistedControls(this.props.module);

    this.state = {
      isLoaded: false,
      isDirty: false,
      whitelistedControls,
      defaultControlIndex: 1,
      ...this.lookups,
    };
  }

  componentDidMount() {
    this.subscriptions = {
        lookups: arxs.Api.lookups
            .subscribe(this.lookups, lookups => this.setState({ ...lookups }))
    };
  }

  componentWillUnmount() {
      if (this.subscriptions) {
          this.subscriptions.lookups.dispose();
      }
  }

  componentDidUpdate() {
    if (this.props.module === OriginModuleEnum.Form) {
      const targetModule = this.getTargetModule();
      if (targetModule !== this.state.targetModule) {
        const whitelistedControls = formControlRegistry.getWhitelistedControls(targetModule);
        this.setState({ targetModule, whitelistedControls });
      }
    }
  }

  serialize = (deserialized) => {
    if (deserialized.link) {
      return {
        link: deserialized.link
      };
    }

    var knownControls = formControlRegistry.getControls();
    const distinctControlIds = deserialized.sections
      .flatMap(s => s.items.map(i => i.control))
      .distinct();
    const distinctControls = distinctControlIds
      .map(id => knownControls[id - 1])
      .map((control, i) => ({ ...control, id: i + 1 }));
    const controlMap = distinctControlIds.toDictionary(x => x, (_, i) => i + 1);

    return {
      controls: distinctControls,
      sections: deserialized.sections
        .map((section, index) => ({
          id: section.id,
          ordinal: index + 1,
          title: section.title,
        })),
      items: deserialized.sections
        .flatMap((section, sectionIndex) => section.items
          .map((item, itemIndex) => ({
            ...item,
            data: item.data ? JSON.stringify(item.data) : null,
            settings: JSON.stringify(item.settings || {}),
            section: section.id,
            ordinal: itemIndex + 1,
            control: controlMap[item.control],
          }))),
      hierarchy: []
    };
  }

  preProcessItemData = (item, attachmentInfo, formAttachmentInfo) => {
    const control = formControlRegistry.getControlById(item.control);
    const storedFiles = (attachmentInfo.storedFiles || []).concat(formAttachmentInfo.storedFiles || []);
    if (control && control.type === "images") {
      const storedFileById = storedFiles.toDictionary(x => x.id);
      item.data = (item.data || [])
        .map(x => typeof(x) === "string" ? ({ ...storedFileById[x], refId: x }) : x);
    }
    return item;
  }

  deserialize = (serialized) => {
    const { stateProxy, module } = this.props;

    let formAttachmentInfo = {};
    if (serialized && serialized.link) {
      if (Object.keys(this.state.formMap).length > 0) {
        const [form, definition] = formDefinitionSelector(this.state.formMap
          , module
          , stateProxy.getField("status")
          , serialized);
        formAttachmentInfo = form.attachmentInfo || {};
        serialized = {
          link: serialized.link,
          isLoaded: true,
          ...definition
        };
      } else {
        serialized = {
          link: serialized.link,
          isLoaded: false,
          controls: [],
          sections: [],
          items: []
        };
      }
    } else {
      serialized = {
        ...serialized,
        isLoaded: true
      };
    }

    const attachmentInfo = stateProxy.getField("attachmentInfo") || {};

    serialized = serialized || {};

    const controls = serialized.controls || [];
    const items = serialized.items || [];
    const sections = serialized.sections || [];

    const controlToKnownControlMap = controls
      .map((control, i) => {
        const knownControl = formControlRegistry.getControl(control);
        const knownControlId = (knownControl && knownControl.id) || this.state.defaultControlIndex;
        return [i + 1, knownControlId];
      })
      .toDictionary(x => x[0], x => x[1])
 
    return {
      link: serialized.link,
      isLoaded: serialized.isLoaded,
      controls: formControlRegistry.getControls(),
      sections: sections
        .orderBy(section => section.ordinal)
        .map(section => ({
          id: section.id,
          title: section.title,
          items: items
            .filter(item => item.section === section.id)
            .orderBy(item => item.ordinal)
            .map(item => ({
              id: item.id,
              type: item.type,
              required: item.required,
              title: item.title,
              control: controlToKnownControlMap[item.control],
              possible_actions: item.possible_actions,
              data: item.data ? JSON.parse(item.data) : null,
              settings: JSON.parse(item.settings || "{}")
            }))
            .map(item => this.preProcessItemData(item, attachmentInfo, formAttachmentInfo))
        }))
    };
  }

  loadForm = async (imported, supressLinking) => {
    if (!imported.formDefinition) {
      Toaster.error(arxs.t("form_editor.import.form_has_no_definition"));
      return;
    }

    const { value, onChange, stateProxy } = this.props;

    const targetControlMap = ((value || {}).controls || [])
      .toDictionary(x => toControlKey(x), x => x.id);

    const formToTargetIndexMap = {};
    const targetControls = ((value || {}).controls || []).slice(0);

    for (const control of imported.formDefinition.controls) {
      const key = toControlKey(control);
      let targetIndex = targetControlMap[key];
      if (targetIndex === undefined) {
        targetIndex = targetControls.length + 1;
        targetControls.push({
          ...control,
          id: targetIndex,
        });
      }
      formToTargetIndexMap[control.id] = targetIndex;
    }

    const formSectionIdMap = imported.formDefinition.sections.toDictionary(x => x.id, _ => arxs.uuid.generate());
    const sections =  ((value || {}).sections || []);
    const targetSections = sections
      .concat(imported.formDefinition.sections
        .map(x => ({
          ...x,
          id: formSectionIdMap[x.id],
          ordinal: sections.length + x.ordinal - 1
        }))
      );

    const formItemIdMap = imported.formDefinition.items.toDictionary(x => x.id, _ => arxs.uuid.generate());
    const targetItems = ((value || {}).items || [])
      .concat(imported.formDefinition.items
        .map(x => ({
          ...x,
          id: formItemIdMap[x.id],
          section: formSectionIdMap[x.section],
          control: formToTargetIndexMap[x.control]
        })));

    const imagesControl = targetControls.filter(x => x.type === "images")[0];
    let attachmentInfo = stateProxy.getField("attachmentInfo") || {};

    if (imagesControl && imported.attachmentInfo) {
      for (let item of targetItems.filter(x => x.control === imagesControl.id)) {
        const imageData = JSON.parse(item.data || "[]");
        const idMap = (imageData).map(x => ({ oldId: x, newId: arxs.uuid.generate() }));

        const formStoredFiles = (imported.attachmentInfo.storedFiles || [])
          .filter(x => imageData.includes(x.id))
          .map(x => ({ ...x, id: idMap.filter(y => y.oldId === x.id)[0].newId }));

        const formAttachments = (imported.attachmentInfo.attachments || [])
          .filter(x => x.type === ObjectDocumentType.FormImage)[0].value.filter(x => imageData.includes(x.id))
          .map(x => ({ ...x, id: idMap.filter(y => y.oldId === x.id)[0].newId }));

        attachmentInfo.storedFiles = (attachmentInfo.storedFiles || []).concat(formStoredFiles);

        if (!attachmentInfo.attachments) {
          attachmentInfo.attachments = [];
        }

        if (!attachmentInfo.attachments.some(x => x.type === ObjectDocumentType.FormImage)) {
          attachmentInfo.attachments.push({ type: ObjectDocumentType.FormImage, value: [] });
        }

        attachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.FormImage)[0].value = attachmentInfo.attachments.filter(x => x.type === ObjectDocumentType.FormImage)[0].value.concat(formAttachments);

        item.data = JSON.stringify(idMap.map(x => x.newId));
      }
    }

    if (!supressLinking && this.props.module !== OriginModuleEnum.Form) {
      const hash = imported.formDefinition.hash;
      stateProxy.setField("attachmentInfo", attachmentInfo, () => onChange({
        link: { id: imported.id, hash },
      }));
      return;
    }

    stateProxy.setField("attachmentInfo", attachmentInfo, () => onChange({
      controls: targetControls,
      items: targetItems,
      sections: targetSections
    }));
  }

  mapUrlToFormImage = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const name = pathname.split('/').pop();
    const extension = name.split('.').pop().toLowerCase();
    let contentType = imageMimeTypeMap[extension] || "image/jpeg";
    return { refId: arxs.uuid.generate(), type: "FormImage", url, name, contentType };
  }

  importForm = async (context) => {
    const { onChange } = this.props;

    const module = this.props.module === OriginModuleEnum.Form ? this.getTargetModule() : this.props.module;
    const promptPopup = createInputPopupWithOptions(context
      , {
        title: arxs.t("form_editor.actions.import"),
        onSubmit: async (json) => {
          if (json) {
            const result = await FormImportValidator.validate(json, module);
            const value = result.value;
  
            const getIconIdOrDefault = (iconName) => {
              const icon = this.state.iconByNameMap[iconName];
              if (icon) {
                return icon.iconId;
              }
              return null;
            };
  
            if (result.valid) {
              const controlMap = (value.definition.controls || []).toDictionary(x => x.id, x => x);
              for (const item of value.definition.items) { 
                const control = controlMap[item.control];
                if (control.type === "images") {
                  const urls = JSON.parse(item.data || "[]");
                  item.data = JSON.stringify(urls.map(url => this.mapUrlToFormImage(url)));
                } else if (control.type === "icons") {
                  const icons = JSON.parse(item.data || "[]");
                  item.data = JSON.stringify(icons.map(getIconIdOrDefault));
                } else if (control.type === "riskAndPreventionMeasures") {
                  const measures = JSON.parse(item.data || "[]");
                  item.data = JSON.stringify(measures
                    .map(measure => ({ ...measure
                      , riskIcon: getIconIdOrDefault(measure.riskIcon)
                      , preventionIcon: getIconIdOrDefault(measure.preventionIcon)
                    })));
                }
              }
              onChange(value.definition);
            } else {
              Toaster.error(result.message);
            }
          }
        },
        showInput: true,
        required: true,
        placeHolder: arxs.t("form_editor.import.paste_token"),
        okTitle: arxs.t("form_editor.import.import"),
        nokTitle: arxs.t("common.cancel"),
      });
    context.inputPopup.show(promptPopup);
  }

  _whitelistedImportModules = [OriginModuleEnum.Consultancy, OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.InstructionCard];
  handleMenu = (context) => {
    const module = this.props.module === OriginModuleEnum.Form ? this.getTargetModule() : this.props.module;

    context.optionPopup.show(
      arxs.t("form_editor.actions.title"),
      [{
        title: arxs.t("form_editor.actions.import"),
        handle: () => {
          this.importForm(context);
        },
      }]
        .filter(_ => arxs.Identity.profile.allowedFeatures[Feature.Form_Import_Export])
        .filter(_ => this._whitelistedImportModules.contains(module))
        .concat([
          {
            title: arxs.t("form_editor.actions.clear"),
            handle: () => {
              this.clearForm();
            },
          },
        ])
    );
  }

  handleAddSection = () => {
    const { value, onChange } = this.props;

    const oldData = this.deserialize(value);
    const sections = oldData.sections
      .concat([
        {
          id: arxs.uuid.generate(),
          title: arxs.t("form_editor.add_section.default_title"),
          items: [],
        }
      ]);
    onChange(this.serialize({ ...oldData, sections }));
  }

  clearForm = () => {
    this.props.onChange(this.serialize({ items: [], sections: [], controls: [] }));
  }

  linkOrCopyExistingForm = (context) => {
    const { securityContext } = this.props;
    const modules = [OriginModuleEnum.Form];

    const onApplyFilter = (state) => {
      context.popup.close();
      if (!state) {
        return;
      }

      const link = Object.keys(state).map(id => ({ id, module: state[id] }))[0];
      if (link) {
        const form = arxs.Api.lookups.resolveSubject(link);
        if (Object.keys(form).length === 0) {
          Toaster.error(arxs.t("form_editor.import.form_not_found"));
        } else {
          this.loadForm(form);
        }

      }
    }

    const moduleFilterPredicate = (card) => {
      if (this.props.module && this.props.module !== OriginModuleEnum.Form) {
        return card.targetModule === this.props.module;
      }

      return true;
    }

    const cardLookup = createCardLookup({
      title: this.props.module === OriginModuleEnum.Form
        ? arxs.t("form_editor.insert_form.insert_existing")
        : arxs.t("form_editor.link_form.link_existing"),
      modules,
      onApplyFilter,
      singleSelection: true,
      securityContext,
      filterPredicate: (card) => [FormStatus.Active, FormStatus.ToExpire].includes(card.status) && moduleFilterPredicate(card)
    });

    this.setState({ cardLookup }, () => context.popup.show(this.state.cardLookup));
  }

  getTargetModule = () => {
    const { module, stateProxy } = this.props;

    let targetModule = module;

    if (!targetModule || module === OriginModuleEnum.Form) {
      targetModule = (stateProxy && stateProxy.getField("targetModule")) || OriginModuleEnum.None;
    }

    return targetModule;
  }

  handleUnlinkForm = (context) => {
    const inputPopup = createInputPopupWithOptions(context,
      {
        title: arxs.t("form_editor.link_form.unlink_form"),
        description: arxs.t("form_editor.link_form.unlink_form_description"),
        onSubmit: () => {
          const link = this.props.value.link;
          if (link && Object.keys(this.state.formMap).length > 0) {
            const form = this.state.formMap[link.id];
            this.loadForm(form, true);
          }
        }
      });

    context.inputPopup.show(inputPopup);
  }

  render() {
    const { value, onChange, readOnly, readOnlyMessage, module, stateProxy } = this.props;
    const { whitelistedControls } = this.state;

    const data = this.deserialize(value);

    const isExternal = value && !!value.link;

    let form = {};
    if (isExternal && Object.keys(this.state.formMap).length > 0) {
      form = this.state.formMap[value.link.id];
    }

    const targetModule = this.getTargetModule();

    const handleOnChange = (sections) => {
      let serialized;
      serialized = this.serialize({ ...data, sections });
      !readOnly && !isExternal && onChange && onChange(serialized);
    };

    const renderSection = (section, sectionIndex) => {
      let attachmentInfo = (stateProxy && stateProxy.getField("attachmentInfo")) || { attachments: [], documents: [], storedFiles: [] };

      return (
        <FormSectionEditor
          key={`section-${sectionIndex}`}
          value={section}
          ordinal={sectionIndex + 1}
          onChange={modifiedSection => {
            let sections;
            if (modifiedSection) {
              sections = data.sections.replace(section, modifiedSection, (l, r) => l.id === r.id);
            } else {
              sections = data.sections.filter(x => x.id !== section.id);
            }
            handleOnChange(sections);
          }}
          onMove={(direction) => {
            let sections = data.sections;
            if (direction === "up" && sectionIndex > 0) {
              sections = data.sections.moveItem(sectionIndex, sectionIndex - 1);
            }
            if (direction === "down" && sectionIndex < data.sections.length - 1) {
              sections = data.sections.moveItem(sectionIndex, sectionIndex + 1);
            }
            handleOnChange(sections);
          }}
          sectionCount={data.sections.length}
          knownControls={whitelistedControls}
          defaultControlIndex={this.state.defaultControlIndex}
          readOnly={readOnly || isExternal}
          module={module}
          targetModule={targetModule}
          attachmentInfo={attachmentInfo}
        />
      );
    };

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="form-editor">
            <div className="form-editor-body">
              {!data.isLoaded && <div className="form-loading"><Spinner /> {arxs.t("form_editor.link_form.loading_form")}</div>}
              {data.isLoaded && <div className="sections">
                {data.sections.map(renderSection)}
              </div>}
            </div>
            {isExternal && <label className="form-editor-is-external-message">
              <i className="far fa-info-circle"></i>
              {arxs.t("form_editor.link_form.linked_form_is_read_only", { uniqueNumber: form.uniqueNumber })}
            </label>}
            {!readOnly && !isExternal && <div className="section-add" onClick={() => this.handleAddSection(context)}>
              {arxs.t("form_editor.add_section.create")} <i className="fas fa-plus"></i>
            </div>}
            {!readOnly && <div className="form-editor-actions">
              {!isExternal && <div className="form-editor-action-insert-existing" onClick={() => this.linkOrCopyExistingForm(context)}>
                <span>{module === OriginModuleEnum.Form ? arxs.t("form_editor.insert_form.insert_existing") : arxs.t("form_editor.link_form.link_existing")}</span>
                <i className="fab fa-wpforms"></i>
              </div>}
              {isExternal && <div className="form-editor-action-unlink-form" onClick={() => this.handleUnlinkForm(context)}>
                <span>{arxs.t("form_editor.link_form.unlink_form")}</span>
                <i className="fab fa-wpforms"></i>
              </div>}
              <div className="form-editor-action-menu" onClick={() => this.handleMenu(context)}>
                <i className="fas fa-bars"></i>
              </div>
            </div>}
            {readOnly && readOnlyMessage && <label className=" ">{readOnlyMessage}</label>}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}