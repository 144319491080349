import React from "react";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardLocation from "components/card/CardLocation";
import CardImage from "components/card/CardImage";
import CardInternalNumber from "components/card/CardInternalNumber";

export default function AssetCard(props: CardClassProps) {
  const { card, cardProps, actionProps } = props;

  return (
    <>
      <div className="list-card-left">
        <CardImage {...props} />
      </div>
      <div className="list-card-right">
        <h2>
          <div className="list-card-title">
            {card.title
              || (typeof card.type === "string" && card.type)
              || card.name}
          </div>
          <CardActions {...actionProps} />
        </h2>
        <CardSubtitle cardProps={cardProps} card={card} />
        <div className="list-card-details">
          <CardInternalNumber {...props} />
          <CardLocation card={card} />
        </div>
        {actionProps.renderFooter && <div className="list-card-footer">
          {actionProps.renderFooter(card)}
        </div>}
      </div>
    </>
  );
}
