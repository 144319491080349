import React from 'react';
import "./Error.scss";
export default function Error(props) {
  const { title, message } = props;
  return <div className="error-boundary">
    <div className="error-title">{title}</div>
    <div className="error-splash">
      <img src="/images/error.png" alt="Error" />
      <span>{message}</span>
    </div>
  </div>;
}