import React, { useEffect, useState } from 'react';

import { Bucket } from 'components/card/Bucket';
import arxs from 'infra/arxs';

import './KanBan.scss';

const getBucketOrder = () => {
  return [
    "Pending",
  
    "InProcess",
    "Requested",
    "Refused",
    "InTreatment",
    "ToCreate",

    "ToBePlanned",
    "OnHold",
    "ToPlan",
    "Closed",
    "ToSign",


    "Accepted",
    "Active",
    "ToCommission1",
    "ToCommission2",
    "InService",
    "ExecutionStarted",
    "ActiveShort",
    "Planned",
    "ExecutionOverdue",
    "Overdue",
    "PlanningOverdue",
    "OverTime",
    "NotSigned",
    "ToBeCarriedOut",
    "CarriedOut",
    "ToVerify",
    "ToBeCompleted",
    "ToExpire",
    "Expired",
    "ToInvestigate",
    "Signed",

    "Completed",
    "MultiYearPlan",

    "Denied",
    "Sick",
    "OutOfService",
    "Inactive",
    "Cancelled",
  ];
};

const _scrollOptions = { behavior: "smooth", inline: "center" };

export default function KanBan(props) {
  const refs = React.useRef([]);
  const [ scrollTimer, setScrollTimer ] = useState(null);

  const scrollBucketIntoView = (index) => {
    const element = refs.current[index];  
    if (!element) {
      return;
    }
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    const timer = setTimeout(() => {
      element.scrollIntoView(_scrollOptions);
      setScrollTimer(null);
    }, 0);
    setScrollTimer(timer);
  };

  useEffect(() => {
    refs.current = refs.current.slice(0, props.data.length);

    if (props.selected) {
      const selectedIndex = props.data.flatMap((x, i) => x.cards.any(card => card.id === props.selected.id) ? [i] : [])[0];
      scrollBucketIntoView(selectedIndex);
    } else {
      const selectedIndex = props.data.flatMap((x, i) => x.cards.length > 0 ? [i] : [])[0];
      scrollBucketIntoView(selectedIndex);
    }
  }, [props.selected, props.data]);

  if (!props.data || props.data.length === 0) {
    return [];
  };

  if (props.data.some(x => x.status)) {
    return getBucketOrder()
      .flatMap(x => {
        const listWithIndex = props.data.flatMap((list, index) => list.status === x ? [{ list, index }] : [])[0];
        if (!listWithIndex) {
          return [];
        }
        const { list, index } = listWithIndex;
        return [<Bucket
          key={x}
          elementRef={el => refs.current[index] = el}
          title={list.title}
          cards={list.cards}
          selected={props.selected}
          onToggle={props.onToggle}
          onCheckChange={props.onCheckChange}
          onDrop={(item) => props.onDrop(list.status, item, props.context, props.history)}
          status={list.status || ""}
          canDrop={(item) => props.canDrop(list.status, item)}
          getCardProps={props.getCardProps}
        />];
      });
  }

  const list = props.data[0];

  return [<Bucket
    key='list'
    title={arxs.t("kanban.common.standard_records")}
    cards={list.cards}
    selected={props.selected}
    onToggle={props.onToggle}
    onCheckChange={props.onCheckChange}
    getCardProps={props.getCardProps}
  />];
}