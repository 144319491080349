import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { RelationshipType } from "infra/api/contracts";

class RecommendationBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const allSignatories =
      (card.signingPreventionAdvisor ? [(card.signingPreventionAdvisor)] : [])
        .concat(card.signingHierarchicalLine ? [(card.signingHierarchicalLine)] : [])
        .concat(card.signatories || []);

    return {
      ...card,
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      activationDate: card.activationDate && new Date(card.activationDate),
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      createdAt: card.createdAt && new Date(card.createdAt),
      owner: this.getEmployeeValue(
        card.relationships.filter((x) => x.type === RelationshipType.Owner),
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.description,
      card.kind,
      card.type,
      card.title,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      card.owner,
      card.internalReference,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    const lookups = arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.recommendations) {
        lookups.pristine = lookups.recommendations
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.recommendations;
      }
      stateProxy.setter(lookups);
    });

    return Promise.resolve({ lookups });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for InstructionCard yet");
  };
}
export default new RecommendationBoard();