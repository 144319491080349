import React, { Fragment } from 'react';

import './DropDownMenu.scss';

class DropDownMenu extends React.Component {
  state = {
    open: false
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  }

  getItem = (item, i) => {
    const key = `dropdown-menu-${i}`;
    const content = item.content;
    const title = item.title || (item.getTitle && item.getTitle());

    let onClick = item.onClick;

    if (this.props['state']) {
      onClick = (event) => { item.onClick(this.props.state, event); this.props.onItemClick && this.props.onItemClick(); };
    }

    if (!title && !content) {
      return <li key={key} className="separator"></li>;
    }
    const icon = item.icon || (item.getIcon && item.getIcon());
    return <li key={key} className={item.className} onClick={onClick}>
      <div className="dropdown-menu-item">
        <div className="dropdown-menu-item-inner">
          {content || <Fragment>
            {icon && <i className={icon}></i>}
            <div className="title" title={title}>{title}</div>
          </Fragment>}
        </div>
      </div>
    </li>;
  }

  render() {
    const items = this.props["items"] || [];
    const hasItems = items.length > 0;
    const chevron = !(this.props.chevron === false);
    const classes = [
      'dropdown-menu-button',
      this.props.className,
      this.state.open && "open",
      !hasItems && "disabled",
    ].filter(x => x).join(" ");
    return (
      <div className={classes} onClick={this.handleClick}>
        <div className="label">
          {this.props.children}
        </div>
        {chevron && <i className="fa fa-chevron-down"></i>}
        {hasItems && <ul className="dropdown-menu">
          {items.map(this.getItem)}
        </ul>}
      </div>
    );
  }
}
export default DropDownMenu;