import { ArXsState } from "infra/arxs";
import { ObjectDocumentType, OriginModuleEnum } from "infra/api/contracts";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import CombinedInstallationActions from "./CombinedInstallationActions";
import CombinedInstallationBoard from "./CombinedInstallationBoard";
import CombinedInstallationWizard from "./CombinedInstallationWizard";
import AssetCard from "../AssetCard";

const getCombinedInstallationMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.CombinedInstallation;

  return {
    module,
    title: arxs.t("modules.combined_installation"),
    icon: "far fa-link",
    path: "/combinedinstallation",
    base: {
      name: "CombinedInstallation",
      route: "/combinedinstallation",
      getResource: () => arxs.Api.getResource("combinedinstallation"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "CombinedInstallation.Read",
      writeAction: "CombinedInstallation.Write",
    },
    settings: {
      name: "CombinedInstallationSettings",
      route: "/combinedinstallation/settings",
      getResource: () =>
        arxs.Api.getResource("combinedinstallation").settings(),
      readAction: "CombinedInstallation.Settings.Write",
      writeAction: "CombinedInstallation.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "internalNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
      {
        title: arxs.t("details_pane.details"),
        fields: [],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.Manual,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [],
    actions: CombinedInstallationActions.getActions(module),
    wizard: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
      },
      wizardClass: CombinedInstallationWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup, OriginModuleEnum.School, OriginModuleEnum.Building, OriginModuleEnum.Room],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  CombinedInstallationWizard.setScopeFields(
                    stateProxy,
                    "context"
                  ).then(() =>
                    CombinedInstallationWizard.applyProcessFlow(
                      stateProxy,
                      "context"
                    )
                  );
                },
                onLoad: (stateProxy) => {
                  CombinedInstallationWizard.getContext(stateProxy);
                },
                title: arxs.t("field.location"),
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructure", "branch"].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "CombinedInstallation.Kind" },
                type: "codeelementlist",
              },
            ],
            [{ name: "internalNumber" }],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.assets"),
          fields: [
            [
              {
                name: "assets",
                type: "cardlist",
                title: arxs.t("field.assets"),
                props: {
                  modules: [
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  setFilterOnField: "branch",
                  expandable: true,
                },
                onLoad: (stateProxy) =>
                  CombinedInstallationWizard.setScopeAndContextFromAssets(
                    stateProxy
                  ),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
    },
    board: {
      allowDuplicate: false,
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        combinedInstallations: {},
        tagMap: {},
        employeeMap: {},
        periodicalMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "kind" },
        { name: "type" },
        { name: "description" },
        { name: "internalNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: CombinedInstallationBoard,
      views: [BoardViewType.Grid, BoardViewType.Tree, BoardViewType.Map],
    },
    cardClass: AssetCard
  };
};
export default getCombinedInstallationMetadata;
