import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import './MaskedDateTimeInput.scss';

class MaskedDateTimeInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            pristine: this.props.value
        };
    }

    componentDidMount() {
        const value = arxs.dateTime.formatDateTime(this.props.value);
        this.setState({ value });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            const value = arxs.dateTime.formatDateTime(this.props.value);
            this.setState({ value });
        }
    }

    getDateTimeFromInput = (value) => {
        if (value && value.length > 1) {
            const dateAndTime = value.split(" ");
            const dateParts = (dateAndTime[0] || "").split("-");
            const timeParts = (dateAndTime[1] || "").split(":")

            if (dateParts.length === 3 && dateParts[2].length === 4 && timeParts.length === 2 && timeParts[1].length === 2) {
                return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1]);
            }
        }

        return value;
    }

    onChange = (event) => {
        let value = this.getDateTimeFromInput(event.target.value);

        const stringValue = value instanceof Date ? arxs.dateTime.formatDateTime(value) : value;

        this.setState({ value: stringValue }, () => {

            if (value instanceof Date || value === undefined || value === "") {
                value = value === "" || value === undefined ? null : value;

                this.props.onChange({
                    value,
                    event,
                    target: this
                });
            }
        })
    }

    onKeyDown = (event) => {
        const { value } = this.state;
        let newValue;
        switch (event.keyCode) {
            case 8:
                event.preventDefault();
                if (value && value === " ") {
                    newValue = "";
                } else {
                    newValue = (value && value.substring(0, value.length)) || value;
                }
                break;
            case 110:
            case 111:
                event.preventDefault();
                if ([2, 5].includes(value.length)) {
                    newValue = value + "-";
                }
                break;
            default:
                switch (value.length) {
                    case 2:
                    case 5:
                        newValue = value + "-";
                        break;
                    case 10:
                        newValue = value + " ";
                        break;
                    case 13:
                        newValue = value + ":";
                        break;
                    default:
                        newValue = value;
                        break;
                }
                break;
        }

        this.setState({ value: newValue });
    }

    render() {
        const { value } = this.state;

        let displayValue = this.getDateTimeFromInput(value);

        if (displayValue instanceof Date) {
            displayValue = arxs.dateTime.formatDateTime(displayValue);
        }

        return (<Fragment>
            <input
                className="masked-datetime-input"
                data-format="00-00-0000 00:00"
                type="text"
                placeholder="dd-mm-jjjj uu:mm"
                onChange={this.onChange}
                value={displayValue}
                onKeyUp={this.onKeyDown}
                maxLength="16" />
        </Fragment>)
    }
}
export default MaskedDateTimeInput;