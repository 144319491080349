import React, { useState, useEffect } from "react";
import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";
import { Icon as IconImage } from "components/controls/images/Icon";

import "./IconOverview.scss";
import { GHSPictogram, Icon, OriginModuleEnum } from "infra/api/contracts";
import { createIconLookup } from "./IconLookup/IconLookup";

export interface IIconOverviewProps {
  data: (GHSPictogram | Icon)[];
  onDelete: (value: GHSPictogram | Icon) => void;
  onAdd: (value: GHSPictogram | Icon) => void;
  className?: string;
  readOnly?: boolean;
  title: string;
  required?: boolean;
  iconType?: any;
  module: OriginModuleEnum;
  singleSelection?: boolean;
  size?: number;
}

export const IconOverview: React.FC<IIconOverviewProps> = (props) => {
  type IconData = {
    image?: { url: string };
    name?: string;
  };

  const initialLookups = {
    ghsPictogramMap: {} as { [key: string]: IconData },
    iconMap: {} as { [key: string]: IconData },
  };
  
  type LookupsType = {
    ghsPictogramMap: { [key: string]: IconData };
    iconMap: { [key: string]: IconData };
  };
  
  const [data, setData] = useState<(GHSPictogram | Icon)[]>([]);
  const [lookups, setLookups] = useState<LookupsType>(initialLookups);

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values: any) => {
        setLookups((prevLookups) => ({ ...prevLookups, ...values }));
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, []);

  const handleAddIcons = (context: {
    popup: { close: () => void; show: (arg0: any) => void };
  }) => {
    const onApplyFilter = (newIcons: any) => {
      context.popup.close();

      if (props.onAdd) {
        props.onAdd(newIcons);
      }
    };

    const iconLookup = createIconLookup({
      onApplyFilter,
      iconType: props.iconType,
      module: props.module,
      singleSelection: props.singleSelection,
    });
    context.popup.show(iconLookup);
  };

  const onDelete = (icon: GHSPictogram | Icon) => {
    if (props.onDelete) {
      props.onDelete(icon);
    }
  };

  const renderActions = (icon: GHSPictogram | Icon) => {
    if (!props.readOnly) {
      return (
        <div className="icon-actions">
          <div
            key="deleteAction"
            className="icon-remove"
            title={arxs.t("controls.image_preview.delete")}
            onClick={() => onDelete(icon)}
          >
            <i className="far fa-trash-alt"></i>
          </div>
        </div>
      );
    }
  };

  const renderPlaceHolders = (context: any) => {
    if (props.readOnly) {
      if (!data || data.length === 0) {
        return (
          <div className="icon-container" key="empty">
            <div
              className="icon-image preview empty readonly"
              style={{
                backgroundImage: `url("images/image.png")`,
                backgroundSize: "cover",
                width: `${size}px`,
                height: `${size}px`,
              }}
              key="empty"
            />
          </div>
        );
      }
    } else {
      if (
        !props.singleSelection ||
        (props.singleSelection && data && data.length === 0)
      )
        return (
          <div className="icon-container" key="empty">
            <div
              className="icon-image preview empty"
              onClick={() => handleAddIcons(context)}
              key="empty"
              style={{
                width: `${size}px`,
                height: `${size}px`,
              }}
            >
              <i className="fas fa-plus"></i>
            </div>
          </div>
        );
    }
  };

  const renderIcon = (
    context: any,
    icon: GHSPictogram | Icon,
    index: number
  ) => {
    const iconId = icon.iconId || "";
    const iconData = lookups.ghsPictogramMap[iconId] || lookups.iconMap[iconId] || {};

    return (
      <IconImage
        src={iconData.image?.url || ""}
        name={iconData.name || ""}
        width={props.size || 87}
        height={props.size || 87}
      />
    );
  };

  const size = props.size ? props.size : 87;

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className={`icon-overview ${props.className || ""}`}>
          {props.title && (
            <label>
              {props.title}
              {props.required && <span> *</span>}
            </label>
          )}
          <div className="icon-content">
            {data.map((icon: GHSPictogram | Icon, i: number) => (
              <div className="icon-container" key={`${i}`}>
                {renderIcon(context, icon, i)}
                {renderActions(icon)}
              </div>
            ))}
            {renderPlaceHolders(context)}
          </div>
        </div>
      )}
    </GlobalContext.Consumer>
  );
};
