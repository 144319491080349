/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DecommissioningStatus {
    InProcess = 'InProcess',
    ToSign = 'ToSign',
    Active = 'Active',
    Inactive = 'Inactive'
}

export function DecommissioningStatusFromJSON(json: any): DecommissioningStatus {
    return DecommissioningStatusFromJSONTyped(json, false);
}

export function DecommissioningStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecommissioningStatus {
    return json as DecommissioningStatus;
}

export function DecommissioningStatusToJSON(value?: DecommissioningStatus | null): any {
    return value as any;
}

