import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

class LocationBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      sortId: card.sort && card.sort.id,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      name: card.name,
      title: card.name,
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      floor: this.getCodeElementValue(
        card.floor,
        stateProxy.getter("codeElementsById")
      ),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),

      buildingCompartment:
        card.buildingCompartment &&
        this.getBuildingCompartment(card.buildingCompartment, stateProxy),
      dimensionHeight:
        card.dimensionRules &&
        this.getDimension("height", card.dimensionRules, stateProxy),
      dimensionWidth:
        card.dimensionRules &&
        this.getDimension("width", card.dimensionRules, stateProxy),
      dimensionLength:
        card.dimensionRules &&
        this.getDimension("length", card.dimensionRules, stateProxy),
      dimensionSurface:
        card.dimensionRules &&
        this.getDimension("surface", card.dimensionRules, stateProxy),
      dimensionVolume:
        card.dimensionRules &&
        this.getDimension("volume", card.dimensionRules, stateProxy),
        periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks),
    };
  }

  getBuildingCompartment = (ref, stateProxy) => {
    if (ref && ref.id) {
      const buildingCompartments =
        stateProxy.getter("buildingCompartments") || [];
      return (buildingCompartments.filter((x) => x.id == ref.id)[0] || {}).name;
    }
  };

  getDimension = (type, dimensions, stateProxy) => {
    const codeElements = stateProxy.getter("codeElements");

    const dimensionKindRoot = (codeElements || {})["Dimensions.Kind"][0];
    const dimensionKinds = dimensionKindRoot.children.filter((x) => x.code);

    let dimensionKind;
    switch (type) {
      case "height":
        dimensionKind = dimensionKinds.filter(
          (x) =>
            x.code === "Dimensions.Kind.Height" || x.code === "Lokaal - Hoogte"
        )[0];
        break;
      case "width":
        dimensionKind = dimensionKinds.filter(
          (x) =>
            x.code === "Dimensions.Kind.Width" || x.code === "Lokaal - Breedte"
        )[0];
        break;
      case "length":
        dimensionKind = dimensionKinds.filter(
          (x) =>
            x.code === "Dimensions.Kind.Length" || x.code === "Lokaal - Lengte"
        )[0];
        break;
      case "surface":
        dimensionKind = dimensionKinds.filter(
          (x) =>
            x.code === "Dimensions.Kind.Surface" ||
            x.code === "Lokaal - Oppervlakte"
        )[0];
        break;
      case "volume":
        dimensionKind = dimensionKinds.filter(
          (x) =>
            x.code === "Dimensions.Kind.Volume" || x.code === "Lokaal - Volume"
        )[0];
        break;
      default:
        break;
    }

    if (dimensionKind) {
      var dimension = dimensions.filter(
        (x) => x.dimensionKind.id === dimensionKind.id
      )[0];
      if (dimension) {
        return dimension.dimensions;
      }
    }

    return null;
  };

  getCardSearchTerms(card) {
    return [
      card.name,
      card.uniqueNumber,
      card.internalNumber,
      card.legalStructure,
      card.branch,
      card.building,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.floor,
      card.buildingCompartment,
      card.type,
      card.kind,
      card.description,
      ...(card.tags || []).map((x) => x.name),
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.locations) {
        lookups.pristine = lookups.locations
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.locations;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Buildings yet");
  };
}
export default new LocationBoard();
