import React from "react";

import "./UniqueNumber.scss";

interface UniqueNumberProps {
    uniqueNumber: any
}

function onlyAllowControlClick(e: React.MouseEvent<HTMLElement>): void {
    if (!e.ctrlKey) {
        e.preventDefault();
    }
}

export default function UniqueNumber(props: UniqueNumberProps) {
    return <a 
        className="uniquenumber-link"
        onClick={onlyAllowControlClick}
        href={`${window.origin}/search/${props.uniqueNumber}`}>
            {props.uniqueNumber}
        </a>;
}
