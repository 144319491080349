import React, { useEffect, useState } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { OriginModuleEnum } from 'infra/api/contracts';
import Toaster from 'components/util/Toaster';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import { Spinner } from 'components/animations/Spinner';
import SignatureDocumentNotAvailable from './SignatureDocumentNotAvailable';

import './SignaturePopup.scss';

export interface DocumentToSign {
    id: string;
    module: OriginModuleEnum;
    storedFileId: string;
    name: string;
    uniqueNumber: string;
}

interface DocumentUrlToSign {
    id: string;
    module: OriginModuleEnum;
    url: string;
    name: string;
}

export interface SignatureProp {
    module: OriginModuleEnum;
    documents: DocumentToSign[];
    onConfirm: () => void;
}

export const createSignaturePopup = (props: SignatureProp) => {
    const state: any = {
        title: <><i className="fas fa-signature"></i> {arxs.t("controls.signature.title")}</>,
        content: <SignaturePopup {...props} />,
        maximized: true
    };

    return state;
};

export function SignaturePopup(props: SignatureProp) {
    const [index, setIndex] = useState(0);
    const [document, setDocument] = useState<DocumentUrlToSign | false>();
    const [navigationActions, setNavigationActions] = useState<Array<any>>([]);
    const [signed, setSigned] = useState<any>({});

    useEffect(() => {
        setSigned(props.documents.toDictionary(x => x.id, x => true));
    }, [props.documents]);
    
    useEffect(() => {
        const x = props.documents[index];
        if (!x.storedFileId) {
            setDocument(false);
            return;
        }
        arxs.ApiClient.shared.attachment.retrieve({ module: x.module, objectId: x.id, storedFileId: x.storedFileId })
            .then((result: any) => {
                setDocument({ id: x.id, module: x.module, name: x.name, url: result.previewUrl });
            });
    }, [index, props.documents]);

    useEffect(() => {
        setNavigationActions([
            {
                key: "first",
                name: arxs.t("controls.signature.nav.first"),
                icon: "fas fa-angle-double-left",
                pre: true,
                isActive: index > 0,
                handle: () => setIndex(0),
            },
            {
                key: "previous",
                name: arxs.t("controls.signature.nav.previous"),
                icon: "fas fa-angle-left",
                pre: true,
                isActive: index > 0,
                handle: () => setIndex(Math.max(index - 1, 0)),
            },
            {
                key: "current",
                name: props.documents[index].uniqueNumber + " (" + (index + 1) + "/" + props.documents.length + ")",
                icon: signed[props.documents[index].id] ? "fas fa-check" : "fas fa-question"
            },
            {
                key: "next",
                name: arxs.t("controls.signature.nav.next"),
                icon: "fas fa-angle-right",
                isActive: index < props.documents.length - 1,
                handle: () => setIndex(Math.min(index + 1, props.documents.length - 1)),
            },
            {
                key: "last",
                name: arxs.t("controls.signature.nav.last"),
                icon: "fas fa-angle-double-right",
                isActive: index < props.documents.length - 1,
                handle: () => setIndex(props.documents.length - 1),
            },
        ]);
    }, [index, props.documents, signed]);

    const toggleSign = (document: DocumentUrlToSign) => {
        setSigned({ ...signed, [document.id]: !signed[document.id] });
    };

    const confirm = (context: any) => {
        const ids = Object.keys(signed).filter(id => signed[id]);
        const count = ids.length;
        const label = count > 0
            ? (props.documents.length === 1
                ? arxs.t("controls.signature.confirmation.one_document")
                : arxs.t("controls.signature.confirmation.n_documents", { count }))
            : arxs.t("controls.signature.confirmation.no_documents");
        const confirmation = createInputPopup(context, label, () => {
            if (count > 0) {
                const metadata = arxs.moduleMetadataRegistry.get(props.module);
                const resource = metadata.base.getResource() as any;
                resource.sign(ids)
                    .then((result: any) => {
                        Toaster.success(arxs.t("controls.signature.confirmation.success"));
                        props.onConfirm();
                    });
            } else {
                props.onConfirm();
            }
        });
        context.inputPopup.show(confirmation);
    };

    const isSigned = document && signed[document.id];

    return (
        <GlobalContext.Consumer>
            {(context) =>
                <div className="signature-popup">
                    {props.documents.length > 1 && 
                        <div className="signature-popup-nav">
                            {navigationActions.map(action =>
                                <div
                                    key={action.key}
                                    className={[
                                        action.handle ? "signature-popup-nav-button" : "signature-popup-nav-section",
                                        action.isActive ? "" : "inactive"
                                    ].join(" ")}
                                    onClick={action.isActive ? action.handle : undefined}
                                    >
                                    {action.pre && <i className={action.icon}></i>}
                                    {action.name}
                                    {!action.pre && <i className={action.icon}></i>}
                                </div>)}
                        </div>
                    }
                    <div className="signature-popup-body">
                        {!document
                            ? (document === false ? <SignatureDocumentNotAvailable/> : <Spinner />)
                            : <>
                                <iframe src={document.url} title={document.name} className={isSigned ? "signed" : "tosign"} />
                                <div className={isSigned ? "info-badge signed" : "info-badge tosign"}>{
                                    isSigned ? arxs.t("controls.signature.info_badge.signed") : arxs.t("controls.signature.info_badge.to_sign")
                                }</div>
                            </>
                        }
                    </div>
                    <div className="signature-popup-actions">
                        {document && !signed[document.id] && <div className="signature-popup-actions-button sign" onClick={() => toggleSign(document)}>
                            {arxs.t("controls.signature.actions.sign")}
                        </div>}
                        {document && signed[document.id] && <div className="signature-popup-actions-button unsign" onClick={() => toggleSign(document)}>
                            {arxs.t("controls.signature.actions.unsign")}
                        </div>}
                        {document && <div className="signature-popup-actions-button confirm" onClick={() => confirm(context)}>
                            {arxs.t("controls.signature.actions.confirm")}
                        </div>}
                    </div>
                </div>
            }
        </GlobalContext.Consumer>
    );
}