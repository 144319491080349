import React, { Component } from "react";
import { Editor, EditorTools, ProseMirror } from "@progress/kendo-react-editor";
import {
  defaultMarkdownParser,
  defaultMarkdownSerializer,
} from "prosemirror-markdown";

import "./RichTextEditor.scss";

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const {
  Bold,
  Italic,
  Link,
  Unlink,
  FormatBlock,
  Undo,
  Redo,
  OrderedList,
  UnorderedList,
} = EditorTools;

export class RichTextEditor extends Component {
  // Technical Debt: https://trello.com/c/ygfZL2gw/201-richtexteditor-isloaded

  state = { isLoaded: false };

  componentDidMount() {
    this.setLoaded(this.props.field.isLoaded);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.field.isLoaded !== this.props.field.isLoaded) {
      this.setLoaded(this.props.field.isLoaded);
    }
  }

  setLoaded = (isLoaded) => {
    this.setState({ isLoaded }, () => {
      this.setStyleOnEditorIframe();
    });
  };

  setStyleOnEditorIframe = () => {
    for (const editor of document.getElementsByClassName("k-editor") || []) {
      if (editor) {
        const style = getComputedStyle(document.documentElement);
        const iFrame = editor.querySelector("iframe");
        iFrame.contentDocument.documentElement.style.setProperty(
          "font-family",
          style.getPropertyValue("--arxs-font-family")
        );
        iFrame.contentDocument.documentElement.style.setProperty(
          "font-size",
          "13px"
        );
        iFrame.contentDocument.documentElement.style.setProperty(
          "color",
          style.getPropertyValue("--arxs-primary-color")
        );
        iFrame.contentDocument.documentElement.style.setProperty(
          "background-color",
          style.getPropertyValue("--arxs-input-background-color")
        );
      }
    }
  };

  createView = (event) => {
    const shortcuts = {
      ...event.shortcuts,
      "Mod-u": () => false,
    };

    const state = EditorState.create({
      doc: defaultMarkdownParser.parse(this.props.field.getter() || ""),
      plugins: [
        ...event.plugins,
        ProseMirror.keymap(shortcuts),
        ProseMirror.keymap(ProseMirror.baseKeymap),
        new Plugin({
          key: new PluginKey("readonly"),
          props: {
            editable: () => !this.props.field.readOnly,
          },
          filterTransaction: (tr, _st) => !this.props.field.readOnly || !tr.docChanged,
        }),
      ],
    });

    return new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state,
      }
    );
  };

  render() {
    let field = this.props.field;
    if (!this.state.isLoaded) {
      return <></>;
    }
    const tools = this.props.field.readOnly ? [] : [
      [Bold, Italic, Link, Unlink],
      [Undo, Redo],
      [OrderedList, UnorderedList],
      [FormatBlock],
    ];
    const classNames = [
      this.props.className,
      "read-only"
    ].filter(x => x).join(" ");
    return (
      <Editor
        className={classNames}
        tools={tools}
        onMount={this.createView}
        onExecute={(e) => {
          if (e.transaction.docChanged) {
            field.setter(
              defaultMarkdownSerializer.serialize(e.transaction.doc)
            );
          }
        }}
      />
    );
  }
}
