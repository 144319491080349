import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

class BranchBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const preferredAddress= (card.addresses || []).filter(x => x.isPreferred)[0] || (card.addresses || []).firstOrDefault((x) => x);
    return {
      ...card,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      educationDome: this.getCodeElementValue(
        card.educationDome,
        stateProxy.getter("codeElementsById")
      ),
      businessType: this.getCodeElementValue(
        card.businessType,
        stateProxy.getter("codeElementsById")
      ),
      educationPortal: this.getCodeElementValue(
        card.educationPortal,
        stateProxy.getter("codeElementsById")
      ),
      email: (card.emails || [])
        .filter((x) => x.isPreferred)
        .map((x) => x.email)[0],
      phone: (card.phones || [])
        .filter((x) => x.isPreferred)
        .map((x) => x.number)[0],
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      address: preferredAddress && `${preferredAddress.street} ${preferredAddress.number}, ${preferredAddress.zipCode} ${preferredAddress.city}, ${preferredAddress.country}`,
      schoolNumberNames: (card.schoolNumbers || []).map(x => x.number).join(", "),
      establishmentNumberNames: (card.establishmentNumbers ||[]).map(x => x.establishmentNumber).join(", "),
      createdAt: card.createdAt && new Date(card.createdAt),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks)
    };
  }

  getCardSearchTerms(card) {
    return [
      card.name,
      card.uniqueNumber,
      card.abbreviation,
      card.internalName,
      card.companyNumber,
      card.legalStructure,
      card.email,
      card.phone,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.establishmentNumbers || []).map((x) => x.establishmentNumber),
      ...(card.schoolNumbers || []).map((x) => x.number),
      ...(card.tags || []).map((x) => x.name),
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.branches) {
        lookups.pristine = lookups.branches
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.branches;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Legalstructure yet");
  };
}
export default new BranchBoard();
