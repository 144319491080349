import { DateHelper, DomHelper, DragHelper, Rectangle } from '@bryntum/core-thin';

export default class PlanningDrag extends DragHelper {
  static get defaultConfig() {
    return {
      // Don't drag the actual row element, clone it
      cloneTarget: true,
      mode: 'translateXY',
      // Only allow drops on the schedule area
      dropTargetSelector: '.b-timeline-subgrid',
      // Only allow drag of row elements inside on the unplanned grid
      targetSelector: '.list-card-wrapper'
    };
  }

  construct(config) {
    const me = this;

    super.construct(config);

    // Configure DragHelper with schedule's scrollManager to allow scrolling while dragging
    me.scrollManager = me.schedule.scrollManager;

    me.on({
      dragstart: me.onTaskDragStart,
      drag: me.onTaskDrag,
      drop: me.onTaskDrop,
      thisObj: me
    });
  }

  startDraggingPlanningMoment(planningMoment) {
    this.task = planningMoment;
  }

  onTaskDragStart(source) {
    const { context } = source;
    const
      me = this,
      { schedule, task } = me,
      mouseX = context.clientX,
      mouseY = context.clientY,
      proxy = context.element,
      newHeight = 36,
      newWidth = Math.max(100, me.schedule.timeAxisViewModel.getDistanceForDuration(task.durationMS));

    // save a reference to the task so we can access it later
    context.task = task;

    proxy.classList.remove('list-card-wrapper');
    proxy.classList.add('b-sch-event-wrap');
    proxy.classList.add(`b-sch-color-${task.eventColor}`);
    proxy.innerHTML =
      `<div class="b-sch-event">
  <i class="far fa-tasks"></i>
  <span>${task.name}</span>
</div>`;

    me.schedule.enableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    // If the new width is narrower than the grabbed element...
    const proxyRect = Rectangle.from(context.grabbed);

    proxy.style.width = `${newWidth}px`;
    if (context.grabbed.offsetWidth > newWidth) {
      // If the mouse is off (nearly or) the end, centre the element on the mouse
      if (mouseX > proxyRect.x + newWidth - 20) {
        context.newX = context.elementStartX = context.elementX = mouseX - newWidth / 2;
        DomHelper.setTranslateX(proxy, context.newX);
      }
    }
    if (context.grabbed.offsetHeight > newHeight) {
      if (mouseY > proxyRect.y + newHeight - 20) {
        context.newY = context.elementStartY = context.elementY = mouseY - newHeight / 2;
        DomHelper.setTranslateY(proxy, context.newY);
      }
    }

    // Prevent tooltips from showing while dragging
    schedule.element.classList.add('b-dragging-event');
  }

  onTaskDrag(source) {
    const { context, event } = source;
    const
      me = this,
      { schedule } = me,
      { task } = context,
      coordinate = DomHelper[`getTranslate${schedule.isHorizontal ? 'X' : 'Y'}`](context.element),
      startDate = schedule.getDateFromCoordinate(coordinate, 'round', false),
      endDate = startDate && DateHelper.add(startDate, task.duration, task.durationUnit),
      // Coordinates required when used in vertical mode, since it does not use actual columns
      resource = context.target && schedule.resolveResourceRecord(context.target, [event.offsetX, event.offsetY]);

    // Don't allow drops anywhere, only allow drops if the drop is on the timeaxis and on top of a Resource
    context.valid &= Boolean(startDate && resource) &&
      (schedule.allowOverlap || schedule.isDateRangeAvailable(startDate, endDate, null, resource));

    // `cls` field is not persistable in the demo
    if (context.resource) {
      context.resource.cls = '';
    }

    if (startDate && resource) {
      resource.cls = 'target-resource';
    }
    // Save reference to resource so we can use it in onTaskDrop
    context.resource = resource;
  }

  // Drop callback after a mouse up, take action and transfer the unplanned task to the real EventStore (if it's valid)
  onTaskDrop(source) {
    const { context } = source;

    const
      me = this,
      { task, target, resource } = context;

    me.schedule.disableScrollingCloseToEdges(me.schedule.timeAxisSubGrid);

    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    if (context.valid && target) {
      const
        coordinate = DomHelper[`getTranslate${me.schedule.isHorizontal ? 'X' : 'Y'}`](context.element),
        date = me.schedule.getDateFromCoordinate(coordinate, 'round', false),
        // Try resolving event record from target element, to determine if drop was on another event
        targetEventRecord = me.schedule.resolveEventRecord(context.target);

      if (date) {
        // Remove from grid first so that the data change
        // below does not fire events into the grid.
        //me.grid.store.remove(task);

        //task.setStartDate(date, true);

        me.handleDropCard({
          start: date.toISOString(),
          end: new Date(date.getTime() + task.durationMS).toISOString(),
          assignees: [{ id: context.resource.id }],
          title: task.name,
          subject: task.subject,
          isAllDay: false,
        });
        // task.startDate = date;
        // task.resource = context.resource;
        // task.resourceId = context.resource.id;
        //me.schedule.eventStore.add(task);
      }

      // Dropped on a scheduled event, display toast
      if (targetEventRecord) {
        console.log(`Dropped on ${targetEventRecord.name}`);
      }

      me.context.finalize();
    }
    else {
      me.abort();
    }

    if (resource) {
      resource.cls = '';
    }

    me.schedule.element.classList.remove('b-dragging-event');
  }
};