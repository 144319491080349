import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

const whitelistedModules = [
    OriginModuleEnum.Consultancy,
    OriginModuleEnum.SafetyInstructionCard,
    OriginModuleEnum.InstructionCard,
];

async function verifyPayload(encodedString) {
    const [base64Payload, receivedBase64Checksum] = encodedString.split('.');
    const payload = atob(base64Payload);
    const encoder = new TextEncoder();
    const data = encoder.encode(payload);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const computedBase64Checksum = btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
    return computedBase64Checksum === receivedBase64Checksum;
}

const service = {
    validate: async (str, expectedModule) => {
        if (!whitelistedModules.contains(expectedModule)) {
            return {
                valid: false,
                message: arxs.t("form_editor.import.module_mismatch")
            };
        }
        if (str) {
            try {
                const [base64Payload, base64Checksum] = str.split('.');
                const json = atob(base64Payload);
                const data = new TextEncoder().encode(json);
                const hashBuffer = await crypto.subtle.digest('SHA-256', data);
                const computedBase64Checksum = btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
                if (computedBase64Checksum !== base64Checksum) { 
                    return {
                        valid: false,
                        message: arxs.t("form_editor.import.invalid_checksum")
                    };
                }

                const value = JSON.parse(json);
                if (value.module !== expectedModule) {
                    return {
                        valid: false,
                        message: arxs.t("form_editor.import.module_mismatch")
                    };
                }

                return {
                    valid: true,
                    value
                };
            } catch(e) {
                arxs.logger.error("FormImportValidator parse failed! {error}", e.message);
            }
        }

        return {
            valid: false,
            message: arxs.t("form_editor.import.invalid_json")
        };
    }
};
export default service;