import React, { Component } from 'react';
import Button from 'components/controls/Button';
import arxs from 'infra/arxs';
import { TextArea } from 'components/controls/TextArea';
import Toaster from 'components/util/Toaster';

import './InputPopup.scss';

export const createInputPopup = (context, title, onSubmit, showInput, required, placeHolder, okTitle, nokTitle, multiline, value, description) => {
  context = context || [];
  const state = {
    title: title,
    description: description,
    onSubmit: onSubmit,
    showInput: showInput || false,
    required: required || false,
    placeHolder: placeHolder || '',
    okTitle: okTitle || arxs.t("common.yes"),
    nokTitle: nokTitle || arxs.t("common.no"),
    multiline: multiline || true,
    value: value || ''
  };
  return state;
};

export const createInputPopupWithOptions = (context, options) => {
  context = context || [];
  const state = {
    showInput: false,
    required: false,
    placeHolder: '',
    okTitle: arxs.t("common.yes"),
    nokTitle: arxs.t("common.no"),
    multiline: true,
    value: '',
    ...options
  };
  return state;
};

class InputPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirtyValue: ''
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.value !== '' && (this.state.dirtyValue === '' || this.state.dirtyValue === undefined))
      && (prevProps.value !== this.props.value)) {
      this.setState({ dirtyValue: this.props.value });
    }
  }

  close = () => {
    this.setState({ dirtyValue: '' });

    if (this.props.onCancel) {
      this.props.onCancel();
    }
    if (this.props.close) {
      this.props.close();
    }
  }

  isEmptyOrSpaces(str) {
    return str === undefined || str === null || str.match(/^ *$/) !== null;
  }

  confirm = (action) => {
    if (this.props.required) {
      if (this.isEmptyOrSpaces(this.state.dirtyValue)) {
        Toaster.error(arxs.t('common.input_empty'));
        return;
      }
    }

    this.close();

    if (action) {
      action(this.state.dirtyValue);
    }
  }

  render() {
    const { dirtyValue } = this.state;

    const renderInput = () => {
      if (this.props['showInput']) {
        const field = {
          name: 'input',
          getter: () => dirtyValue,
          setter: (value) => {
            this.setState({ dirtyValue: value });
          },
          multiLine: this.props.multiline
        };

        return <div className="input-popup-input">
          <TextArea
            autoFocus
            field={field}
            placeholder={this.props.placeHolder}
          />
        </div>
      }
    }

    return this.props.onSubmit ? (<div className="input-popup-backdrop">
      <div className={`input-popup ${this.props.className || ""}`}>
        <h1>{this.props.title}</h1>
        <div className="input-popup-body">
          <div className="input-popup-body-content">
            <div>
              {this.props.icon && <i className={this.props.icon}></i>}
            </div>
            <div className="input-popup-body-content-inputs">
              {this.props.description && <p>{this.props.description}</p>}
              {renderInput()}
            </div>
          </div>
          <div className="buttons">
            <Button key={`option-popup-option-cancel`} className="icon alternative" onClick={() => this.close()}>{this.props.nokTitle}</Button>
            <Button key={`option-popup-option-confirm`} className="icon" onClick={() => this.confirm(this.props.onSubmit)}>{this.props.okTitle}</Button>
          </div>
        </div>
        <div className="input-popup-close" onClick={this.close}>
          <i className="fa fa-times"></i>
        </div>
      </div>
    </div>)
      : null;
  }
}
export default InputPopup;