import React from "react";
import GlobalContext from "infra/GlobalContext";
import { Icon as IconImage } from "components/controls/images/Icon";
import FormItemController from "./FormItemController";

import "./Icons.scss";

export default class Icons extends FormItemController {
    getAdditionalLookups() {
        return {
            iconMap: {},
            iconByNameMap: {},
        };
    }

    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    renderIconRow = (data, i) => {
        const { iconMap, iconByNameMap } = this.state;
        const icons = (data && iconMap && data.map(i => iconMap[i] || iconByNameMap[i] || {})) || [];

        return <div className="icon-row" key={`icon-row-${i}`}>
            {icons.map((icon, i) => <IconImage
                key={`icon-${i}`}
                src={icon.image?.url || ""}
                name={icon?.name || ""}
                width={87}
                height={87}
            />)}
        </div>;
    }

    renderIcons = () => {
        const numberOfColumns = this.getSettings().numberOfColumns || 5;

        let pagedData = this.getter().partition(numberOfColumns);
       
        return <div className="icons">
            {pagedData.map((row, i) => this.renderIconRow(row, i))}
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control icons">
                    {this.renderHeader(this.props.title, true)}
                    <div className="form-control-field">
                        {this.renderIcons()}
                    </div>
                </div>}
        </GlobalContext.Consumer>)
    }
}