/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskPriorityEnum {
    Low = 'Low',
    Normal = 'Normal',
    High = 'High'
}

export function TaskPriorityEnumFromJSON(json: any): TaskPriorityEnum {
    return TaskPriorityEnumFromJSONTyped(json, false);
}

export function TaskPriorityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskPriorityEnum {
    return json as TaskPriorityEnum;
}

export function TaskPriorityEnumToJSON(value?: TaskPriorityEnum | null): any {
    return value as any;
}

