import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";

import "./CodeElementFormLookup.scss";

const DraggableFormListItem = (props) => {
    const { item, dragHandleProps, commonProps } = props;
    const { readOnly, placeholder, codeElements, onItemChange, onDeleteItem } = commonProps;
  
    const selectedValue = item && item.value;

    const onValueChange = (state) => {
        if (item.value !== state) {
            onItemChange({ ...item, value: state });
        }
    };
    const handleDelete = () => {
        onDeleteItem(item.id);
    };

    return (<div className="ce-form-lookup-container" key={(item && item.id) || 0}>
        <div className="ce-form-lookup-line">
            {!readOnly && <div className="drag-handle" {...dragHandleProps}>
                <i className="fa fas fa-grip-vertical"></i>
            </div>}
            <AutoCompleteTextBox
                id={`value-${item.id}`}
                key={`value-${item.id}`}
                className={`ce-form-lookup-value`}
                items={codeElements}
                value={selectedValue}
                onChange={onValueChange}
                readOnly={readOnly}
                placeholder={placeholder}
            />
            <div className="ce-form-lookup-actions">
                {!readOnly && item && <div className="ce-form-lookup-action" onClick={handleDelete}>
                    <i className="far fa-trash-alt"></i>
                </div>}
            </div>
        </div>
    </div>);
};

export default class CodeElementFormLookup extends FormItemEditorController {
    lookups = {
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    getDraggableItemComponent() { return DraggableFormListItem };

    renderInputAndItems = (context) => {
        const { field, readOnly, placeholder } = this.props;
        const code = this.props.code;
        const parentCodeElement = ((this.state.codeElements || {})[code] || [])[0] || {};
        const codeElements = (parentCodeElement.children || []).map(x => x.name);

        const value = field.getter();
        const data = value.data || [];
        
        const onItemChange = (state) => {
            field.setter({ ...value, data: data.replaceOrAppend(state, (l, r) => l.id === r.id) });
        };

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };

        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData });
        };

        return <div className="ce-form-lookup">
            {this.renderDraggableList(data, { readOnly, placeholder, codeElements, onItemChange, onDeleteItem })}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderInputAndItems(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}