import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

class BuildingsBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,
      sortId: card.sort && card.sort.id,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      title: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),

      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks)
    };
  }

  getCardSearchTerms(card) {
    return [
      card.name,
      card.uniqueNumber,
      card.internalName,
      card.legalStructure,
      card.branch,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.abbreviation,
      card.description,
      ...(card.tags || []).map((x) => x.name),
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.buildings) {
        lookups.pristine = lookups.buildings
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.buildings;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Buildings yet");
  };
}
export default new BuildingsBoard();
