import React from 'react';
import Badge from 'components/controls/Badge';

import './Image.scss';

export interface IImageProps {
  style?: object,
  id?: string,
  className: string,
  src: string,
  alt?: string,
  onClick?: () => void
  totalImages?: number
}

interface Item {
  type: string
  id: string
  originalIndex: string
}

export const Image: React.FC<IImageProps> = ({ style, id, className, src, alt, onClick, totalImages }) => {
  return (<div className={`image ${className}`}
    style={style || { backgroundImage: `url("${src || "images/image.png"}")` }}
    title={alt}
    onClick={onClick}>
    {totalImages && totalImages > 1 && <Badge className="blue">{`+${totalImages - 1}`}</Badge>}
  </div>
  );

}
