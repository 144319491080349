import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import ReportDefinitionWizard from "./ReportDefinitionWizard";
import ReportDefinitionActions from "./ReportDefinitionActions";
import ReportDefinitionBoard from "./ReportDefinitionBoard";
import ReportDefinitionCard from "./ReportDefinitionCard";
import {
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
  ReportMainGrouping,
} from "infra/api/contracts";

const getReportDefinitionMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Report;

  return {
    module,
    title: arxs.modules.titles[module],
    icon: "fas fa-tasks-alt",
    path: "/reportdefinition",
    base: {
      name: "ReportDefinition",
      route: "/reportdefinition",
      getResource: () => arxs.Api.getResource("reportDefinition"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "Report.Read",
      writeAction: "Report.Write",
    },
    settings: {
      name: "ReportDefinitionSettings",
      route: "/reportdefinition/settings",
      getResource: () => arxs.ApiClient.shared.reportDefinitionSettings,
      readAction: "Report.Settings.Read",
      writeAction: "Report.Settings.Write",
      stepsTitle: arxs.t("settings.styling"),
      steps: [
        {
          title: arxs.t("field.headerFooter"),
          fields: [
            [
              {
                name: "headerFooter",
                noHeaders: true,
              },
            ],
          ],
        },
      ]
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "title", fullWidth: true },
          { name: "description" },
          { name: "type" },
        ],
      },
      {
        title: arxs.t("details_pane.relationships"),
        initiallyOpen: true,
        fields: [{ name: "relationships" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [ObjectDocumentType.AdditionalDocument],
    statuses: [],
    actions: ReportDefinitionActions.getActions(OriginModuleEnum.Report),
    wizard: {
      wizardClass: ReportDefinitionWizard,
      steps: [
        {
          title: arxs.t("wizard.common.identification"),
          fields: [
            [
              {
                name: "title",
              },
            ],
            [{ name: "description" }],
            [{ name: "startDate" }, { name: "endDate" }],
            [
              {
                name: "subjects",
                type: "cardlist",
                title: arxs.t("field.scope"),
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                  ],
                  expandable: true,
                },
              },
            ],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "Report.Kind" },
                parent: "kind",
                title: arxs.t("field.category"),
              },
            ],
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  "modules": [OriginModuleEnum.Employee],
                  "condensed": true,
                  "overridePrefilter": true
                }
              },
              {
                name: "relationships",
                type: "relationshiplist",
                props: { types: [RelationshipType.CoResponsible] },
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
          getSecurityContext: (writeAction, getValue) => {
            return arxs.securityContext.buildForUserContext();
          },
        },
        {
          title: arxs.t("wizard.common.details"),
          fields: [
            [
              {
                name: "mainGrouping",
                values: [
                  ReportMainGrouping.Module,
                  ReportMainGrouping.ModuleThenScope,
                  ReportMainGrouping.ScopeThenModule,
                ],
                props: { required: true },
              },
            ],
            [{ name: "definitionDetails" }],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [[{ name: "documents", type: "document" }]],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
      },
    },
    board: {
      lookups: {
        reportDefinitions: [],
        branchMap: {},
        buildingMap: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        codeElementsById: {},
        reportDefinitionMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "responsible" },
        { name: "coResponsibles" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: ReportDefinitionBoard,
      additionalStatuses: [],
      views: [BoardViewType.Grid],
    },
    cardClass: ReportDefinitionCard,
  };
};

export default getReportDefinitionMetadata;