import { ArXsState } from "infra/arxs";
import { OriginModuleEnum, ObjectDocumentType } from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import SupplierWizard from "./SupplierWizard";
import SupplierActions from "./SupplierActions";
import SupplierBoard from "./SupplierBoard";
import SupplierCard from "./SupplierCard";

const getSupplierMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Supplier;

  return {
    module,
    title: arxs.t("modules.supplier_customer"),
    icon: "far fa-user",
    path: "/supplier",
    base: {
      name: "Supplier",
      route: "/supplier",
      getResource: () => arxs.Api.getResource("supplier"),
      subscribe: (id, module, handler) => arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Supplier.Write",
      readAction: "Supplier.Read",
    },
    settings: {
      name: "SupplierSettings",
      route: "/supplier/settings",
      getImportResource: () => arxs.ApiClient.masterdata.supplierSettings,
      readAction: "Supplier.Settings.Write",
      writeAction: "Supplier.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "description" },
          { name: "enterpriseNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
          { name: "emails", fullWidth: true },
          { name: "phones", fullWidth: true },
          { name: "addresses", fullWidth: true },
          { name: "contacts" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument
    ],
    singleFileDocumentTypes: [],
    statuses: [],
    actions: SupplierActions.getActions(OriginModuleEnum.Supplier),
    wizard: {
      wizardClass: SupplierWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "name",
              },
              {
                name: "enterpriseNumber",
              },
            ],
            [{ name: "description" }],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "Supplier.Kind" },
                parent: "kind",
                title: arxs.t("field.category"),
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.contact"),
          fields: [
            [
              {
                name: "geoLocation",
                onChange: (stateProxy) => {
                  SupplierWizard.addDefaultAddress(stateProxy);
                },
              },
            ],
            [
              {
                name: "addresses",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "country", width: "100px" },
                  { name: "zipCode", width: "60px" },
                  { name: "city", width: "150px" },
                  { name: "street" },
                  { name: "number", width: "60px" },
                  { name: "bus", width: "50px" },
                  {
                    name: "type",
                    code: "Supplier.Address.Type",
                    width: "150px",
                  },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  SupplierWizard.onChangePreferredInItemList(stateProxy, fieldName, row, childName)
                    .then(() => SupplierWizard.setFirstItemInItemListAsPreferred(stateProxy, fieldName));
                }
              },
            ],
            [
              {
                name: "emails",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "email" },
                  { name: "type", code: "Supplier.Email.Type" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  SupplierWizard.onChangePreferredInItemList(stateProxy, fieldName, row, childName)
                    .then(() => SupplierWizard.setFirstItemInItemListAsPreferred(stateProxy, fieldName));
                }
              },
            ],
            [
              {
                name: "extraData",
                type: "itemlist",
                children: [
                  { name: "name" },
                  { name: "type", code: "Supplier.ExtraData.Type" },
                ],
              },
            ],
            [
              {
                name: "phones",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "number" },
                  { name: "type", code: "Supplier.Phone.Type" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  SupplierWizard.onChangePreferredInItemList(stateProxy, fieldName, row, childName)
                    .then(() => SupplierWizard.setFirstItemInItemListAsPreferred(stateProxy, fieldName));
                }
              },
            ],
            [
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  condensed: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        suppliers: [],
        codeElements: [],
      },
    },
    board: {
      allowDuplicate: false,
      lookups: {
        codeElementsById: {},
        tagMap: {},
        suppliers: [],
        employeeMap: {}
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "name" },
        { name: "email" },
        { name: "address" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "phone" },
        { name: "enterpriseNumber" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: SupplierBoard,
      bucketConfig: [],
      views: [BoardViewType.Grid, BoardViewType.Tree, BoardViewType.Map],
    },
    cardClass: SupplierCard
  };
};
export default getSupplierMetaData;
