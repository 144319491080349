import React, { useEffect, useState, useCallback, Fragment } from "react";
import { AutoSizer } from "react-virtualized";

import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";

import OlMap from "ol/Map";
import OlView from "ol/View";
import OlFeature from "ol/Feature";
import OlVectorLayer from "ol/layer/Vector";
import { toLonLat, fromLonLat } from "ol/proj";
import { defaults as defaultInteractions } from "ol/interaction";
import OlSourceVector from "ol/source/Vector";
import OlGeomPoint from "ol/geom/Point";
import OlStyleStyle from "ol/style/Style";
import OlStyleIcon from "ol/style/Icon";
import Control from "ol/control/Control";

import {
  defaultMapMetadata,
  toLayerGroup,
} from "components/controls/geolocation/MapMetadata";

import MapComponent from "components/layouts/geo/MapComponent";
import LayerTree from "components/layouts/geo/LayerTree";
import GeoLocationButton from "components/layouts/geo/GeoLocationButton";
import NominatimSearch from "components/layouts/geo/NominatimSearch";

import "./GeoLookup.scss";

export const createGeoLookup = (context, value, setValue) => {
  context = context || [];
  const state = {
    title: setValue
      ? arxs.t("geo_lookup.title")
      : arxs.t("geo_lookup.preview_title"),
    content: <GeoLookup value={value} setValue={setValue} />,
    maximized: true,
  };
  return state;
};

const markerIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' width='32' height='32' xml:space='preserve'%3E%3Cpath fill='%234D79FD' class='path1' d='M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z'%3E%3C/path%3E%3Cpath fill='%23fff' class='path2' d='M8 15.8l-4.4-4.6c-1.2-1.2-1.9-2.9-1.9-4.7 0-1.7 0.6-3.2 1.8-4.5 1.3-1.2 2.8-1.8 4.5-1.8s3.2 0.7 4.4 1.9c1.2 1.2 1.8 2.8 1.8 4.5s-0.7 3.5-1.8 4.7l-4.4 4.5zM4 10.7l4 4.1 3.9-4.1c1-1.1 1.6-2.6 1.6-4.2 0-1.5-0.6-2.9-1.6-4s-2.4-1.7-3.9-1.7-2.9 0.6-4 1.7c-1 1.1-1.6 2.5-1.6 4 0 1.6 0.6 3.2 1.6 4.2v0z'%3E%3C/path%3E%3Cpath fill='%23fff' class='path3' d='M8 16l-4.5-4.7c-1.2-1.2-1.9-3-1.9-4.8 0-1.7 0.6-3.3 1.9-4.6 1.2-1.2 2.8-1.9 4.5-1.9s3.3 0.7 4.5 1.9c1.2 1.3 1.9 2.9 1.9 4.6 0 1.8-0.7 3.6-1.9 4.8l-4.5 4.7zM8 0.3c-1.6 0-3.2 0.7-4.3 1.9-1.2 1.2-1.8 2.7-1.8 4.3 0 1.7 0.7 3.4 1.8 4.5l4.3 4.5 4.3-4.5c1.1-1.2 1.8-2.9 1.8-4.5s-0.6-3.1-1.8-4.4c-1.2-1.1-2.7-1.8-4.3-1.8zM8 15.1l-4.1-4.2c-1-1.2-1.7-2.8-1.7-4.4s0.6-3 1.7-4.1c1.1-1.1 2.6-1.7 4.1-1.7s3 0.6 4.1 1.7c1.1 1.1 1.7 2.6 1.7 4.1 0 1.6-0.6 3.2-1.7 4.3l-4.1 4.3zM4.2 10.6l3.8 4 3.8-4c1-1 1.6-2.6 1.6-4.1s-0.6-2.8-1.6-3.9c-1-1-2.4-1.6-3.8-1.6s-2.8 0.6-3.8 1.6c-1 1.1-1.6 2.4-1.6 3.9 0 1.6 0.6 3.1 1.6 4.1v0z'%3E%3C/path%3E%3C/svg%3E";
const fallbackGeoCoordinate = [4.352435254798073, 50.84679449699351];
const fallbackXyCoordinate = fromLonLat(fallbackGeoCoordinate);

const layerGroup = toLayerGroup(defaultMapMetadata);

const markerFeature = new OlFeature({});

markerFeature.setStyle(
  new OlStyleStyle({
    image: new OlStyleIcon({
      anchor: [0.5, 0],
      anchorOrigin: "bottom-left",
      src: markerIcon,
    }),
  })
);

const markerLayer = new OlVectorLayer({
  name: "Marker",
  source: new OlSourceVector({
    features: [markerFeature],
  }),
  visible: true,
});

let onMarkerChange = null;
let isReadOnly = false;

const map = new OlMap({
  interactions: defaultInteractions(),
  layers: [layerGroup, markerLayer],
  controls: [],
});

map.on("singleclick", function (event) {
  if (!map.hasFeatureAtPixel(event.pixel)) {
    const coordinate = event.coordinate;
    markerFeature.setGeometry(new OlGeomPoint(coordinate));
    if (onMarkerChange) onMarkerChange(toLonLat(coordinate));
  }
});

var handleZoomIn = function (e) {
  map.getView().setZoom(map.getView().getZoom() + 1);
};

var handleZoomOut = function (e) {
  map.getView().setZoom(map.getView().getZoom() - 1);
};

var zoomInButton = document.createElement("button");
zoomInButton.innerHTML = "<i class='fas fa-search-plus'></i>";

var zoomOutButton = document.createElement("button");
zoomOutButton.innerHTML = "<i class='fas fa-search-minus'></i>";

zoomInButton.addEventListener("click", handleZoomIn, false);
zoomOutButton.addEventListener("click", handleZoomOut, false);

var zoomDiv = document.createElement("div");
zoomDiv.className = "ol-zoom ol-unselectable ol-control";
zoomDiv.appendChild(zoomInButton);
zoomDiv.appendChild(zoomOutButton);

map.addControl(new Control({ element: zoomDiv }));

function getViewForMarker(markerXyCoordinate) {
  if (markerXyCoordinate) {
    return new OlView({
      center: markerXyCoordinate,
      zoom: 16,
    });
  }

  return new OlView({
    center: fallbackXyCoordinate,
    zoom: 12,
  });
}

function GeoLookup(props) {
  useEffect(() => {
    const geoCoordinate = props.value && [
      props.value.longitude,
      props.value.latitude,
    ];
    const xyCoordinate = geoCoordinate && fromLonLat(geoCoordinate);

    markerFeature.setGeometry(
      xyCoordinate ? new OlGeomPoint(xyCoordinate) : null
    );

    map.setView(getViewForMarker(xyCoordinate));
    map.render();

    setAddress(props.value);
    setPosition(geoCoordinate);
  }, [props.value]);

  useEffect(() => {
    isReadOnly = !props.setValue;
  }, [props.setValue]);

  const confirm = () => {
    let value = null;
    if (address) {
      value = {
        street: address.street,
        number: address.number,
        zipCode: address.zipCode,
        city: address.city,
        country: address.country,
      };
    }
    if (position) {
      value = {
        ...value,
        latitude: position[1],
        longitude: position[0],
      };
    }

    markerFeature.setGeometry(null);

    props.setValue(value);
  };

  const setCurrentLocation = useCallback((event) => {
    const xyCoordinate = event.position;
    markerFeature.setGeometry(new OlGeomPoint(xyCoordinate));
    map.setView(getViewForMarker(xyCoordinate));
    map.render();
    const lngLat = toLonLat(xyCoordinate);
    onMarkerChange(lngLat);
  });

  const [address, setAddress] = useState(props.value || null);
  const [position, setPosition] = useState(
    props.value && props.value.coordinate
      ? [props.value.coordinate.longitude, props.value.coordinate.latitude]
      : null
  );

  onMarkerChange = (lngLat) => {
    if (isReadOnly) {
      return;
    }

    const coordinate = lngLat
      ? { latitude: lngLat[1], longitude: lngLat[0] }
      : null;
    setPosition(lngLat);

    if (coordinate) {
      const retrieveFromGeopunt = () => {
        return new Promise((resolve, reject) => {
          fetch(
            `https://geo.api.vlaanderen.be/geolocation/v4/Location?latlon=${coordinate.latitude},${coordinate.longitude}`
          )
            .then((response) => {
              if (!response.ok) {
                reject(`${response.status} ${response.statusText}`);
              }

              resolve(
                response.json().then((x) => {
                  const location = (x.LocationResult || [])[0];
                  if (location) {
                    setAddress({
                      street: location.Thoroughfarename,
                      number: location.Housenumber,
                      zipCode: location.Zipcode,
                      city: location.Municipality,
                      country: location.CountryCode,
                    });
                  }
                })
              );
            })
            .catch((err) => {
              reject(err);
            });
        });
      };

      const retrieveFromNomatim = () => {
        return new Promise((resolve, reject) => {
          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${coordinate.latitude}&lon=${coordinate.longitude}`
          ).then((response) => {
            if (!response.ok) {
              reject(`${response.status} ${response.statusText}`);
            }

            resolve(
              response.json().then((x) => {
                const location = x.address;
                if (location) {
                  // let streetParts = (location.Address || "").split(" ") || [];
                  // if (
                  //   streetParts.length > 0 &&
                  //   streetParts[streetParts.length - 1] == location.AddNum
                  // ) {
                  //   streetParts.pop();
                  // }

                  setAddress({
                    street: location.road,
                    number: location.AddNum,
                    zipCode: location.postcode,
                    city: location.village,
                    country: location.country,
                  });
                }
              })
            );
          })
          .catch((err) => {
            reject(err);
          });
        });
      };

      retrieveFromGeopunt().catch((err) => {
        arxs.logger.error(
          "GeoLookup GeoPunt failed to retrieve address {error}",
          err
        );
        retrieveFromNomatim().catch((err) => {
          arxs.logger.error(
            "GeoLookup Arcgis failed to retrieve address {error}",
            err
          );
        });
      });
    }
  };

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className={`geo-lookup ${props.className || ""}`}>
          <div className="geo-lookup-toolbar">
            <NominatimSearch key="search" map={map} />
          </div>
          <div className="geo-lookup-content">
            <div className="geo-lookup-mapcontainer">
              <AutoSizer>
                {({ width, height }) => (
                  <MapComponent style={{ width, height }} map={map} />
                )}
              </AutoSizer>
              <GeoLocationButton
                title={arxs.t("geo_lookup.current_location")}
                map={map}
                showMarker={false}
                onGeolocationChange={setCurrentLocation}
              />
              {position && (
                <div className="geo-lookup-closest-address">
                  <h3>{arxs.t("geo_lookup.info_marked_location")}</h3>
                  <h3>{arxs.t("geo_lookup.closest_address")}</h3>
                  {address ? (
                    <Fragment>
                      <div>
                        {address.street} {address.number}
                      </div>
                      <div>
                        {address.zipCode} {address.city}
                      </div>
                      <div>{address.country}</div>
                    </Fragment>
                  ) : (
                    <div>{arxs.t("geo_lookup.no_address_available")}</div>
                  )}

                  <h3>{arxs.t("geo_lookup.coordinates")}</h3>
                  <div>
                    {position[1].toFixed(6)}&deg; {position[0].toFixed(6)}&deg;
                  </div>
                </div>
              )}
            </div>
            <LayerTree map={map} layerGroup={layerGroup} />
          </div>
          {!isReadOnly && position && (
            <div className="geo-lookup-selected" onClick={confirm}>
              {arxs.t("geo_lookup.confirm")}
            </div>
          )}
        </div>
      )}
    </GlobalContext.Consumer>
  );
}
export default GeoLookup;
