import React from "react";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import ChecklistItem from "components/controls/checklists/ChecklistItem";

import "./Checkbox.scss";

/*
    Definition
    {
        "Id": "...",
        "Section": "...",
        "Ordinal": 1,
        "Title": "Vinkje",
        "Control": 1,
        "Required": false,
        "PossibleActions": [],
        "Data": null,
        "Settings": "{}"
    },
    Values
    {
        "Id": "18bc9100-49c8-403e-a83a-2f3b23db61bc",
        "Value": "true",
        "Attachments": []
    },
*/

export default class Checkbox extends FormItemController {
    deserialize(serializedValue) {
        // This is the format the checkbox expects
        return {
            id: this.props.id,
            title: this.props.title,
            done: JSON.parse(serializedValue || "false")
        };
    }

    serialize(deserializedValue) {
        return JSON.stringify(!!(deserializedValue || {}).done);
    }
    
    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className={`form-control question checklist ${this.props.required && "required"} ${this.props.requiredWithoutValue && "missing-value"}`}>
                    {this.renderHeader(<ChecklistItem
                        editable={false}
                        readOnly={this.props.readOnly}
                        value={this.getter()}
                        onChange={(value) => this.setter(value)}
                    />)}
                    <div className="form-control-field">
                    </div>
                    {this.renderFooter(context)}
                </div>
            }
        </GlobalContext.Consumer>)
    }
}