import WizardController from "modules/WizardController";

class MaintenanceWizard extends WizardController {
  getRequiredFields(getCurrentFieldValue) {
    // const status = getCurrentFieldValue("status");
    // if (["Active", "Completed", "OnHold"].contains(status)) {
    //   return ["targetDate"];
    // }
    return [];
  }

  validate(stateProxy, getCurrentFieldValue, preValidation) {
    // const status = getCurrentFieldValue("status");
    // Disabled due to : https://trello.com/c/iMqRzL34/28-tab-uitvoerder-is-niet-langer-verplicht-ongeacht-de-status
    // if (["Active", "Completed", "OnHold"].contains(status)) {
    //   const relationships = getCurrentFieldValue("relationships") || [];
    //   const hasAssignees = relationships.some(x => x.type === RelationshipType.Assignee);
    //   if (!hasAssignees) {
    //     preValidation["assignees"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.assignees`) }) };
    //   }
    // }
    return preValidation;
  }
}
export default new MaintenanceWizard();
