import React from "react";

import arxs from "infra/arxs";
import { ObjectDocumentType } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import { Image } from "components/controls/images/Image";

function getConfigurationValue(props: CardClassProps, key: "showImage" | "showIconImage" | "showSubjectImage") {
  const { cardProps, actionProps } = props;
  const cardPropsValue = cardProps ? cardProps[key] : undefined;
  const actionPropsValue = actionProps ? actionProps[key] : undefined;

  if (cardPropsValue === undefined && actionPropsValue === undefined) {
    const { metadata } = actionProps;
    const metadataProps = metadata.board?.cardProps as any;
    return metadataProps ? metadataProps[key] : false;
  } else {
    return cardPropsValue !== undefined
        ? !!cardPropsValue
        : !!actionPropsValue;
  }
}

interface CardImageProps extends CardClassProps {
  iconClass?: string;
}

export default function CardImage(props: CardImageProps) {
    const { card } = props;

    let image;
    let showImage = getConfigurationValue(props, "showImage");
    let showIconImage = getConfigurationValue(props, "showIconImage");
    let showSubjectImage = getConfigurationValue(props, "showSubjectImage");

    if (showIconImage) {
      return <i className={props.iconClass}></i>;
    }

    if (showImage) {
      const getSubject = () => {
          if (card.subject) {
              var subjectInfo = arxs.Api.lookups.resolveSubject(card.subject);
              return subjectInfo;
          }
      };
  
      const subject = getSubject();
      const attachmentInfo = showSubjectImage
          ? (subject || {}).attachmentInfo
          : card.attachmentInfo;

      const imageAttachment = (
          (
          (((attachmentInfo || {}).attachments || []).filter(
              (x: any) => x.type === ObjectDocumentType.Image
          ) || [])[0] || {}
          ).value || []
      ).filter((x: any) => !x.isDeleted)[0];
  
      const firstImageFile =
          ((attachmentInfo || {}).storedFiles || []).filter(
          (x: any) => x.id === (imageAttachment || {}).id
          )[0] || {};
  
      image = card.imagePath || (firstImageFile || {}).url;
      if (image && image.length > 0) {
          image = arxs.ImageProxy.resizeImage(image, 100, 150);
      }

      return <Image className="list-card-image" src={image} />;
    }

    return <></>;
}