import React, { Fragment,useState, useEffect } from "react";

import arxs from "infra/arxs";
import { RelationshipType, } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardLocation from "components/card/CardLocation";

function getValue(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref.name
    ? ref.name
    : Object.keys(ref).some((x) => x)
    ? ref
    : undefined;
}

export default function TaskRequestCard(props: CardClassProps) {
  const initialLookups = {
    employeeMap: {},
  };
  
  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
      }),
    };

    return () => {
        subscriptions.lookups.dispose();
    };
  }, []);
  
  const { card, cardProps, actionProps } = props;

  const ownerRelationship = (card.relationships || []).filter((x: { type: string }) => x.type === RelationshipType.Owner)[0] || {};
  const owner = ownerRelationship.employee && (ownerRelationship.employee.name || getValue(lookups.employeeMap, ownerRelationship.employee));
  const ago = arxs.dateTime.timeAgo(card.createdAt);

  return (
    <Fragment>
    <div className="list-card-right">
      {owner && (
        <div className="list-card-header">
          <div>{owner}</div>
          <div>{ago}</div>
        </div>
      )}
      <h2>
        <div className="list-card-title">
          {card.title || card.description}
        </div>
        <CardActions {...actionProps} />
      </h2>
      <CardSubtitle cardProps={cardProps} card={card} />
      <div className="list-card-details">
        <div>{[card.kind, card.type].filter((x) => x).join(" - ")}</div>
        <CardLocation card={card} wrapLocation={true} />
      </div>
    </div>
  </Fragment>
  );
}
