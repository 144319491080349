/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportMainGrouping {
    Module = 'Module',
    ScopeThenModule = 'ScopeThenModule',
    ModuleThenScope = 'ModuleThenScope'
}

export function ReportMainGroupingFromJSON(json: any): ReportMainGrouping {
    return ReportMainGroupingFromJSONTyped(json, false);
}

export function ReportMainGroupingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMainGrouping {
    return json as ReportMainGrouping;
}

export function ReportMainGroupingToJSON(value?: ReportMainGrouping | null): any {
    return value as any;
}

