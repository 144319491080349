import React, { useState, useEffect } from "react";
import arxs from "infra/arxs";
import DropDown from "../DropDown";

import './Kinney.scss';

export default function Kinney(props) {
    const [selectedChance, setSelectedChance] = useState();
    const [selectedExposure, setSelectedExposure] = useState();
    const [selectedSeverity, setSelectedSeverity] = useState();
    const [data, setData] = useState({});
    const [originalData, setOriginalData] = useState({});

    const chances = [
        { value: 0.1, description: `${arxs.t("controls.form.risk_analysis.chance.nearly_impossible")}` },
        { value: 0.2, description: `${arxs.t("controls.form.risk_analysis.chance.practically_impossible")}` },
        { value: 0.5, description: `${arxs.t("controls.form.risk_analysis.chance.plausible")}` },
        { value: 1, description: `${arxs.t("controls.form.risk_analysis.chance.possible_border_case")}` },
        { value: 3, description: `${arxs.t("controls.form.risk_analysis.chance.unusual")}` },
        { value: 5, description: `${arxs.t("controls.form.risk_analysis.chance.highly_likely")}` },
        { value: 10, description: `${arxs.t("controls.form.risk_analysis.chance.to_be_expected")}` }];


    const exposures = [
        { value: 0.1, description: `${arxs.t("controls.form.risk_analysis.exposure.very_rare")}` },
        { value: 1, description: `${arxs.t("controls.form.risk_analysis.exposure.rare")}` },
        { value: 2, description: `${arxs.t("controls.form.risk_analysis.exposure.sometimes")}` },
        { value: 3, description: `${arxs.t("controls.form.risk_analysis.exposure.occasionally")}` },
        { value: 6, description: `${arxs.t("controls.form.risk_analysis.exposure.regularly")}` },
        { value: 8, description: `${arxs.t("controls.form.risk_analysis.exposure.often")}` },
        { value: 10, description: `${arxs.t("controls.form.risk_analysis.exposure.continuously")}` }];


    const severities = [
        { value: 1, description: `${arxs.t("controls.form.risk_analysis.severity.very_rare")}` },
        { value: 2, description: `${arxs.t("controls.form.risk_analysis.severity.rare")}` },
        { value: 3, description: `${arxs.t("controls.form.risk_analysis.severity.sometimes")}` },
        { value: 7, description: `${arxs.t("controls.form.risk_analysis.severity.occasionally")}` },
        { value: 10, description: `${arxs.t("controls.form.risk_analysis.severity.regularly")}` },
        { value: 15, description: `${arxs.t("controls.form.risk_analysis.severity.often")}` },
        { value: 40, description: `${arxs.t("controls.form.risk_analysis.severity.continuously")}` }];

    const { readOnly } = props;

    useEffect(() => {
        setData({ ...originalData, ...data, chance: (selectedChance || {}).value });
    }, [selectedChance]);

    useEffect(() => {
        setData({ ...originalData, ...data, exposure: (selectedExposure || {}).value });
    }, [selectedExposure]);

    useEffect(() => {
        setData({ ...originalData, ...data, severity: (selectedSeverity || {}).value });
    }, [selectedSeverity]);

    useEffect(() => {
        props.onChange(data);
    }, [data])

    useEffect(() => {
        let value = props.data;
        if (props.data !== undefined
            && typeof props.data === "string") {
            value = JSON.parse(props.data) || {};
        }
        setOriginalData(value);
    }, [props.data])


    return <div className="risk-kinney">
        <DropDown
            className="risk-kinney-chance"
            selected={!selectedChance ? chances.filter(x => x.value === (originalData || {}).chance).map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))[0] : selectedChance}
            onChange={(item) => setSelectedChance(item)}
            items={chances.map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))}
            readOnly={readOnly}
            disableClearButton={true}
            placeholder={arxs.t("controls.form.risk_analysis.chance.title")}
        />
        <i className="far fa-times"></i>
        <DropDown
            className="risk-kinney-exposure"
            selected={!selectedExposure ? exposures.filter(x => x.value === (originalData || {}).exposure).map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))[0] : selectedExposure}
            onChange={(item) => setSelectedExposure(item)}
            items={exposures.map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))}
            readOnly={readOnly}
            disableClearButton={true}
            placeholder={arxs.t("controls.form.risk_analysis.exposure.title")}
        />
        <i className="far fa-times"></i>
        <DropDown
            className="risk-kinney-severity"
            selected={!selectedSeverity ? severities.filter(x => x.value === (originalData || {}).severity).map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))[0] : selectedSeverity}
            onChange={(item) => setSelectedSeverity(item)}
            items={severities.map(x => ({ id: x.value, name: `${x.value} - ${x.description}`, value: x.value }))}
            readOnly={readOnly}
            disableClearButton={true}
            placeholder={arxs.t("controls.form.risk_analysis.severity.title")}
        />
    </div>
};