import React, { useState, useEffect, useContext } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { List } from "react-virtualized";

import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";

import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import { Card } from "components/card/Card";
import { Spinner } from "components/animations/Spinner";

export default function ActivityOverviewObjects(props) {
    const [loading, setLoading] = useState(true);
    const [hasUnreadMessages, setHasUnreadMessages] = useState({});
    const [hasDateHeader, setHasDateHeader] = useState({});
    const [cardHeights, setCardHeights] = useState([]);
    const context = useContext(GlobalContext);

    useEffect(() => {
        const objects = props.objects;
        if (!objects) return;

        if (objects.length === 0 || (objects.length > 0 && props.cards.length > 0)) {
            setLoading(false);
        }

        const idMap = objects.toDictionary(
            (x) => x.object.id,
            (x) => x
        );

        setHasUnreadMessages(objects
            .filter((card) => card.hasUnreadMessages)
            .toDictionary(
                (card) => card.object.id,
                (_) => true
            ));

        const dateHeaderMap = props.cards
            .reduce((acc, card) => {
                const lastMessage = idMap[card.id].lastMessage;
                const date = new Date(lastMessage).toISOString().substring(0, 10);
                if (!acc.dates[date]) {
                    acc.dates[date] = card.id;
                    acc.cards[card.id] = date;
                }
                return acc;
            }, { dates: {}, cards: {} }).cards;

        setHasDateHeader(dateHeaderMap);

        setCardHeights(props.cards.map(
            (card) => 166 + (dateHeaderMap[card.id] ? 30 : 0)
        ));
    }, [props.cards, props.objects]);

    useEffect(() => {
        if (!context.platform.isPhone && props.cards.length > 0 && !props.selected) {
            props.onSelectCard(props.cards[0]);
        }
    }, [!loading]);
    
    const markAllAsRead = (context) => {
        const confirmation = createInputPopup(
            context,
            arxs.t("activity_overview.confirm_mark_all_as_read"),
            () => {
                arxs.ApiClient.shared.activities.markAllAsRead();
                setHasUnreadMessages({});
            }
        );

        context.inputPopup.show(confirmation);
    };
    
    const selectCard = (e, card) => {
      if (!card) {
        return;
      }
  
      if (hasUnreadMessages[card.id]) {
        delete hasUnreadMessages[card.id];
        arxs.ApiClient.shared.activities.markAsRead(card.id);
        setHasUnreadMessages(hasUnreadMessages);
      }
  
      props.onSelectCard(card);
    };

    const handleToggleSubscription = (e, card) => {
        if (card.isSubscribed) {
          arxs.ApiClient.shared.subscriptions.unsubscribe(card.module, card.id);
        } else {
          arxs.ApiClient.shared.subscriptions.subscribe(card.module, card.id);
        }
    };

    return (<GlobalContext.Consumer>
          {(context) => (<div className="activity-overview-object-pane">
            <div className="activity-overview-object-pane-header">
                <h1>{arxs.t("activity_overview.title")}</h1>
                <div className="input-wrapper">
                    <i className="far fa-search"></i>
                    <input
                        type="text"
                        value={props.searchTerm}
                        onChange={e => props.onChangeSearchTerm(e.target.value)}
                    />
                </div>
            </div>
            <div className={["activity-overview-objects-list", loading && "loading"].join(" ")}>
                {loading ? <Spinner /> : <AutoSizer>
                    {({ width, height }) => (
                        <List
                            width={width}
                            height={height}
                            rowCount={cardHeights.length}
                            rowHeight={({ index }) => {
                                return cardHeights[index] || 176;
                            }}
                            rowRenderer={({ index, isScrolling, key, style }) => {
                                const card = props.cards[index];
                                if (!card) {
                                    return <></>;
                                }
                                const header = hasDateHeader[card.id];
                                const cardDisplay = (
                                    <div key={key}
                                        style={style}
                                        className="activity-overview-activity-container-outer"
                                        >
                                        {header && (
                                            <div className="activity-overview-activity-date-notification">
                                                {header}
                                            </div>
                                        )}
                                        <div className="activity-overview-activity-container-inner">
                                            <Card
                                                style={{ height: 146 }}
                                                data={card}
                                                onClick={selectCard}
                                                onToggleSubscription={handleToggleSubscription}
                                                showUnsubscribe
                                                showOpenInTab
                                                selected={props.selected && props.selected.id === card.id}
                                            />
                                            {hasUnreadMessages[card.id] && (
                                                <div className="bullet-glowing"></div>
                                            )}
                                        </div>
                                    </div>
                                );
                                return cardDisplay;
                            }}
                        />
                    )}
                </AutoSizer>}
            </div>
            {!loading && <div
                className="activity-overview-mark-as-read"
                onClick={() => markAllAsRead(context)}
                >
                <i className="far fa-envelope-open"></i>
                {arxs.t("activity_overview.mark_all_as_read")}
            </div>}
        </div>)}
    </GlobalContext.Consumer>);
}