import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';

import './DocumentPreview.scss';

export interface IPreviewInfo {
    url: string,
    name: string,
    documentManagementUrl: string
}

export interface IDownloadInfo {
    url: string
}

export interface IEditInfo {
    getUrl: string,
    saveUrl: string,
    key: string,
    fileType: string,
    documentType: string
}

export interface IDocumentPreviewProps {
    previewInfo: IPreviewInfo,
    downloadInfo: IDownloadInfo,
    editInfo?: IEditInfo
}

export interface IDocumentPreviewState {
    isEditMode: boolean,
    editor: any
}

export const createDocumentPreview = (previewInfo: IPreviewInfo, downloadInfo: IDownloadInfo, editInfo?: IEditInfo) => {
    const state: any = {
        title: `${arxs.t("controls.document.preview.title")} - ${previewInfo.name}`,
        content: <DocumentPreview previewInfo={previewInfo} downloadInfo={downloadInfo} editInfo={editInfo} />,
        maximized: true
    };

    return state;
};

export class DocumentPreview extends Component<IDocumentPreviewProps, IDocumentPreviewState>
{
    state: IDocumentPreviewState = {
        editor: null,
        isEditMode: false
    }


    componentWillUnmount() {
        this.destroyEditor();
    }

    destroyEditor = () => {
        const { editor } = this.state;
        editor && editor.destroyEditor();
    }

    loadEditor = () => {
        this.destroyEditor();
        arxs.loadExternalScript(`${process.env.REACT_APP_DOCUMENTEDITOR_ENDPOINT}/web-apps/apps/api/documents/api.js`, "documentEditor", this.loadDocument);
    };

    loadDocument = () => {
        const { editInfo, previewInfo } = this.props;

        const unsignedPayload = {
            "document": {
                "fileType": editInfo?.fileType,
                "key": editInfo?.key, //arxs.generateUniqueId(),
                "permissions": {
                    "comment": false,
                    "copy": true,
                    "deleteCommentAuthorOnly": false,
                    "download": false,
                    "edit": true,
                    "editCommentAuthorOnly": false,
                    "fillForms": true,
                    "modifyContentControl": true,
                    "modifyFilter": true,
                    "print": true,
                    "review": false,
                    "rename": false
                },
                "title": previewInfo.name,
                "url": editInfo?.getUrl
            },
            "documentType": editInfo?.documentType,
            "editorConfig": {
                "callbackUrl": editInfo?.saveUrl,
                "mode": "edit",
                "lang": "nl",
                "region": "nl-BE",
                "customization": {
                    "forcesave": true,
                    "macros": false
                },
                "user": {
                    "id": arxs.Identity.profile?.id,
                    "name": arxs.Identity.profile?.fullName
                }
            }
        }

        arxs.ApiClient.shared.attachment.signEditorPayload(unsignedPayload).then(
            signedPayload => {

                this.setState({ editor: new (window as any).DocsAPI.DocEditor("editor_placeholder", signedPayload) });
            }
        )
    }

    isDocumentEditable = (context: any) => {
        const { editInfo } = this.props;

        return editInfo !== undefined && editInfo !== null ? true : false;
    }

    handleEdit = (context: any) => {
        this.setState({ isEditMode: true }, this.loadEditor);
    }

    render() {
        const { previewInfo, downloadInfo } = this.props;

        const { isEditMode } = this.state;

        const renderDocumentManagementLink = (context: any) => {
            return <a href={previewInfo.documentManagementUrl} target="_blank" rel="noopener noreferrer">
                <div className="document-preview-documentmanagementlink"><div>
                    {arxs.t("controls.document.preview.goto")}
                </div>
                </div>
            </a>;
        }

        return (
            <GlobalContext.Consumer>
                {(context) =>
                    <div className="document-preview">
                        {!isEditMode && previewInfo.documentManagementUrl && previewInfo.documentManagementUrl.length > 0
                            && renderDocumentManagementLink(context)}
                        <div className="document-preview-document">
                            {!isEditMode && <div className="preview_container">
                                <iframe src={previewInfo.url} title={previewInfo.name} />
                            </div>}
                            {isEditMode && <div className="editor_container">
                                <div id="editor_placeholder"></div>
                            </div>}
                        </div>
                        <HorizontalSeparator />
                        {!isEditMode &&
                            <div className="document-actions" >
                                <div className="document-download" >
                                    {downloadInfo.url && <a href={downloadInfo.url} target="_blank" rel="noopener noreferrer">
                                        <div className="document-download-action" >
                                            {arxs.t("controls.document.preview.download")}
                                        </div>
                                    </a>}
                                </div>
                                {this.isDocumentEditable(context) ?
                                    <div className="document-edit" onClick={() => this.handleEdit(context)}>
                                        <div className="document-edit-action">
                                            <i className="fad fa-pencil"></i>
                                        </div>
                                    </div> : <Fragment></Fragment>}
                            </div>
                        }
                    </div>}
            </GlobalContext.Consumer>
        );
    }
}