import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import './MaskedDateInput.scss';

class MaskedDateInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            pristine: this.props.value
        };
    }

    componentDidMount() {
        const value = arxs.dateTime.formatDate(this.props.value);
        this.setState({ value });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            const value = arxs.dateTime.formatDate(this.props.value);
            this.setState({ value });
        }
    }

    getDateFromInput = (value) => {
        if (value) {
            const dateParts = value.split("-");

            if (dateParts.length === 3 && dateParts[2].length === 4) {
                return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            }
        }

        return value;
    }

    onChange = (event) => {
        let value = this.getDateFromInput(event.target.value);

        const stringValue = value instanceof Date ? arxs.dateTime.formatDate(value) : value;

        this.setState({ value: stringValue }, () => {

            if (value instanceof Date || value === undefined || value === "") {
                value = value === "" || value === undefined ? null : value;

                this.props.onChange({
                    value,
                    event,
                    target: this
                });
            }
        })
    }

    onKeyDown = (event) => {
        const { value } = this.state;
        let newValue;
        switch (event.keyCode) {
            case 8:
                event.preventDefault();
                newValue = value.substring(0, value.length);
                this.setState({ value: newValue });
                break;
            case 110:
            case 111:
                event.preventDefault();
                if ([2, 5].includes(value.length)) {
                    newValue = this.getDateFromInput(value + "-");
                    this.setState({ value: newValue });
                }
                break;
            default:
                if ([2, 5].includes(value.length)) {
                    newValue = value + "-";
                } else {
                    newValue = value;
                }
                this.setState({ value: newValue });
                return;
        }
    }

    render() {
        const { value } = this.state;

        let displayValue = this.getDateFromInput(value);

        if (displayValue instanceof Date) {
            displayValue = arxs.dateTime.formatDate(displayValue);
        }

        return (<Fragment>
            <input
                className="masked-date-input"
                data-format="00-00-0000"
                type="text"
                placeholder="dd-mm-jjjj"
                onChange={this.onChange}
                value={displayValue}
                onKeyUp={this.onKeyDown}
                maxLength="10" />
        </Fragment>)
    }
}
export default MaskedDateInput;