import React, { Component, Fragment } from 'react';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import arxs from 'infra/arxs';
import MultiSelect from 'components/controls/MultiSelect';
import GlobalContext from 'infra/GlobalContext';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';

import { Icon as IconImage } from "components/controls/images/Icon";

import { IconTypeEnum, OriginModuleEnum } from 'infra/api/contracts';

import './IconLookup.scss';

export const createIconLookup = (props) => {
  const context = props.context || [];
  const state = {
    title: context.length === 0 ? arxs.t("icon_lookup.title") : arxs.t("icon_lookup.title_with_context", { context: context.map(x => x.name).join(", ") }),
    content: <IconLookup {...props} />
  };
  return state;
};

class IconLookup extends Component {
  lookups = {
    icons: [],
    ghsPictograms: []
  }

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      iconWidth: 87,
      typeSelectedMap: {},
      pristine: [],
      items: [],
      selected: {},
      criteria: {},
      module: {},
      ...this.lookups,
    };

    const { module } = this.props;
    if (module) {
      this.state.criteria = {
        "searchTerm": () => this.state.searchTerm,
        "type": () => this.state.typeSelectedMap,
      };

      this.state.module = module;
    }
  }

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups) => this.setState({ ...lookups }, this.refresh))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions && this.subscriptions.lookups.dispose) {
      this.subscriptions.lookups.dispose();
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  refresh = () => {
    const { icons, ghsPictograms, searchTerm } = this.state;
    const { iconType, module } = this.props;
    let items = [];
    switch (iconType) {
      case arxs.iconTypes.ghs: items = ghsPictograms.orderBy(x => x.name); break;
      case arxs.iconTypes.icon:
      default: items = icons.orderBy(x => x.type + x.name); break;
    }

    if (module === OriginModuleEnum.HazardousSubstance && iconType === arxs.iconTypes.icon) {
      items = items.filter(x => x.type === IconTypeEnum.CommandSign);
    }

    //filter items based on the criteria & typeselectedmap! -> not needed for GSP, later implementeren!

    items = items.filter(x => x.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);


    items = items.map((icon, j) => {
      return {
        key: `icon-${j}`,
        ...icon,
        checked: this.state.selected[icon.iconId]
      }
    });

    this.setState({ items });
  }

  handleChangeSearchTerm = (event) => {
    this.setState({ searchTerm: event.target.value }, this.refresh);
  }

  handleIconToggle = (e, icon) => {
    const { singleSelection } = this.props;
    let selected = this.state.selected;
    if (selected[icon.iconId]) {
      delete selected[icon.iconId];
    } else {
      if (singleSelection) {
        selected = [];
      }
      selected[icon.iconId] = true;
    }
    this.setSelectedIcons(selected);
  }

  setSelectedIcons = (selected) => {
    const items = this.state.items;
    for (let icon of items) {
      icon.checked = !!selected[icon.iconId];
    }
    this.setState({ items, selected }, this.refresh);
  }

  applyFilter = (context) => {
    const threshold = 10;
    if (this.props.onApplyFilter) {
      const matchingItems = this.state.items;
      let result = matchingItems.filter(x => this.state.selected[x.iconId]).toDictionary(x => x.iconId);
      const emptyFilter = Object.keys(result).length === 0;

      if (emptyFilter) {
        if (this.props.disallowEmptySelection) {
          return;
        }
        result = matchingItems.toDictionary(x => x.iconId);
      }

      const resultLength = Object.keys(result).length;

      if (resultLength <= threshold) {
        this.props.onApplyFilter(result);
      } else {
        const confirmation = createInputPopup(context, arxs.t("icon_lookup.confirmation", { length: `${resultLength}` }), () => this.props.onApplyFilter(result));
        context.inputPopup.show(confirmation);
      }
    }
  };

  handleChangeIconTypeSelected = (selected) => {
    this.setState({
      typeSelectedMap: selected.toDictionary(x => x.name, x => x.id),
    }, this.refresh);
  }

  render() {
    const { applyFilterText, disallowEmptySelection } = this.props;
    let results = this.state.items;

    const selectedKeys = results.filter(x => this.state.selected[x.iconId]).map(x => x.iconId);

    const singleSelection = () => {
      if (this.props['singleSelection'] && this.props.singleSelection === true) {
        return `(Max 1)`;
      }
    }

    const renderFilter = (context) => {
      const { iconType, module } = this.props;

      if (iconType === arxs.iconTypes.icon) {
        if (module !== OriginModuleEnum.HazardousSubstance) {
          return <Fragment>
            <MultiSelect className="section"
              selected={this.state.typeSelected}
              items={Object.values(IconTypeEnum).map(x => ({ id: x, name: x, title: x }))}
              placeholder={arxs.t("icon_lookup.icon_type")}
              onChange={this.handleChangeIconTypeSelected}
            />
          </Fragment>
        }
      }

      return <Fragment>
      </Fragment>;
    }

    const renderContent = (context) => {
      const { iconType } = this.props;
      return <AutoSizer>
        {({ width, height }) => {
          let slices = [];

          switch (iconType) {
            case arxs.iconTypes.icon:
              const groups = results.length > 0 ? results.groupBy(x => x.type) : results;
              Object.keys(groups).map(key => slices = slices.concat(groups[key].partition(Math.max(1, Math.floor(width / 88)))));
              break;
            default:
              slices = results.partition(Math.max(1, Math.floor(width / 88)));
              break;
          }

          return <List
            width={width}
            height={height}
            rowCount={slices.length}
            rowHeight={106}
            rowRenderer={({ index, isScrolling, key, style }) => {
              return <div key={key} style={style}>
                <div className="icon-row">
                  {slices[index].map((icon, j) => {
                    return <IconImage key={`icon-lookup-${index}-${j}`} data={icon}
                      onToggle={(event) => this.handleIconToggle(event, icon)} width={87} height={87} />
                  }
                  )}
                </div>
              </div>;
            }}
          />;
        }}
      </AutoSizer>
    }

    const applyFilterClassName = [
      "icon-lookup-selected",
      (disallowEmptySelection && selectedKeys.length === 0) && "disabled"
    ].filter(x => x).join(" ");

    return <GlobalContext.Consumer>
      {(context) => <Fragment><div className="icon-lookup">
        <div className="icon-lookup-toolbar">
          <div className="input-wrapper far fa-search">
            <input autoFocus type="text" value={this.state.searchTerm} onChange={this.handleChangeSearchTerm}
              placeholder={arxs.t("icon_lookup.search_placeholder")} />
          </div>
          {renderFilter(context)}
        </div>
        <div className="icon-lookup-content-wrapper">
          {renderContent(context)}
        </div>
        <div className={applyFilterClassName} onClick={() => this.applyFilter(context)}>
          {applyFilterText || (
            selectedKeys.length > 0
              ? <Fragment>{arxs.t("icon_lookup.apply_selection")} ({selectedKeys.length}) <i className="fa fa-filter"></i>{singleSelection()}</Fragment>
              : <Fragment>{arxs.t("icon_lookup.apply_filter")} ({results.length}) <i className="fa fa-filter"></i>{singleSelection()}</Fragment>
          )}
        </div>
      </div >
      </Fragment>
      }
    </GlobalContext.Consumer>;
  }
}
export default IconLookup;
