import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import GlobalContext from 'infra/GlobalContext';
import TagTreeview from 'components/controls/tags/TagTreeView'
import TagLabel from 'components/controls/tags/TagLabel'

import './TagLookup.scss';

export const createTagLookup = (module, selectedCards, onSubmit) => {
  const state = {
    title: arxs.t("controls.taglookup.title"),
    content: <TagLookup module={module} selectedCards={selectedCards} onSubmit={onSubmit} />
  };

  return state;
};

export class TagLookup extends Component {
  lookups = {
    tags: [],
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedCards: [],
      selectedTreeViewItem: {},
      selectedTreeViewItemChildren: [],
      ...this.lookups,
      pristine: {},
      selected: {}
    };

    if (props["module"]) {
      this.state.module = {
        name: props["module"],
        icon: arxs.modules.icons[props["module"]],
        title: arxs.modules.titles[props["module"]]
      };
    };

    if (props["selectedCards"]) {
      this.state.selectedCards = props["selectedCards"];
    };
  }

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  refresh = () => {
  }

  onSubmit = async () => {
    const tagIds = Object.keys(this.state.selected);

    if (this.state.selectedCards.length > 0) {
      const objects = this.state.selectedCards.map((card) => { return { objectId: card.id, module: card.module } });
      arxs.ApiClient.shared.tags.addTagsToObjects({ tagIds, objects })
        .then(x => {
          this.props.onSubmit();
        });
    } else {
      this.props.onSubmit(tagIds);
    }
  }

  onTreeViewItemClick = (item, children) => {
    this.setState({ selectedTreeViewItem: item, selectedTreeViewItemChildren: children });

  }

  handleTagSelected = (id) => {
    const selected = this.state.selected;
    if (selected[id]) {
      delete selected[id];
    } else {
      selected[id] = true;
    }

    this.setState({ selected });
  }

  render() {
    const { selectedTreeViewItem, selectedTreeViewItemChildren, selectedCards } = this.state;

    const uniqueNumbers = () => {
      return selectedCards.map((card, i) => <div className="taglookup-card-uniquenumber" key={`card-${i}`}>{card.uniqueNumber}</div>)
    };

    const getTags = (context) => {
      if (selectedTreeViewItem && selectedTreeViewItemChildren && selectedTreeViewItemChildren.length > 0 && selectedTreeViewItem.type === arxs.codeElementTypes.type) {
        return selectedTreeViewItemChildren
          .filter(x => !x.isDeleted)
          .map((tag, i) => <TagLabel
            name={tag.name}
            id={tag.id}
            checkBox
            checked={!!this.state.selected[tag.id]}
            onClick={this.handleTagSelected}
            key={tag.id}
            readOnly />);
      } else {
        return <div className="taglookup-empty">
          <div className="taglookup-empty-icon">
            <i className="far fa-folder-tree"></i>
          </div>
          <div className="taglookup-empty-text">
            {arxs.t("controls.taglookup.empty")}
          </div>
        </div>
      }
    }

    const submit = () => {
      if (Object.keys(this.state.selected).length > 0) {
        return <Fragment>
          <HorizontalSeparator />
          <div className="taglookup-submit" onClick={this.onSubmit}>
            {arxs.t("controls.taglookup.submit", { number: Object.keys(this.state.selected).length })}<i className="fas fa-plus"></i>
          </div>
        </Fragment>;
      };
    }

    return (
      <GlobalContext.Consumer>
        {(context) => <div className="taglookup">
          <div className="taglookup-cardlist-info">
            {uniqueNumbers()}
          </div>
          <HorizontalSeparator />
          <div className="taglookup-details">
            <div className="taglookup-treeview">
              {!context.platform.isMobile && <TagTreeview
                onItemClick={this.onTreeViewItemClick} />}
              {context.platform.isMobile && <TagTreeview
                onItemClick={this.onTreeViewItemClick}
                onSelect={this.handleTagSelected} />}
            </div>
            {!context.platform.isMobile && <div className="taglookup-tags">{getTags(context)}</div>}
          </div>
          {submit()}
        </div>}
      </GlobalContext.Consumer>
    );
  }
}