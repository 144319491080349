import React from "react";
import arxs from 'infra/arxs';
import "infra/ArrayExtensions";
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import { Image } from "components/controls/images/Image";
import { createImagePreview } from "components/controls/images/ImagePreview";

import "./Images.scss";

export default class Images extends FormItemController {
    lookups = { }

    constructor(props) {
        super(props);

        this.state = { ...this.lookups, };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]")
            .map(entry => ((this.props.attachmentInfo || {}).storedFiles ||[])
            .filter(x => x.id === entry)[0] );
    }

    refresh = () => { }

    showPreview = (context) => {
        const data = this.getter();
        if (data && data.length > 0) {
            context.popup.show(createImagePreview(data));
        }
    }

    renderImageRow = (data, context) => {
        return <div className="image-row">
            {data.map((image, i) => image && <Image
                key={`image-${i}`}
                src={arxs.ImageProxy.resizeImage(image.url, 87, 87)}
                alt={image.name}
                className="preview"
                onClick={() => this.showPreview(context)}
            />)}
        </div>;
    }

    renderImages = (context) => {
        const numberOfColumns = this.getSettings().numberOfColumns || 1;

        let pagedData = this.getter().partition(numberOfColumns);

        return <div className="images">
            {pagedData.map(row => this.renderImageRow(row, context))}
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control images">
                    {this.renderHeader(this.props.title, true)}
                    <div className="form-control-field">
                        {this.renderImages(context)}
                    </div>
                </div>}
        </GlobalContext.Consumer>)
    }
}