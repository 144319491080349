import React, { Component, Fragment } from "react";
import arxs from "infra/arxs";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import {
  TimePicker,
  DatePicker,
  DateTimePicker,
} from "@progress/kendo-react-dateinputs";
import { NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  OriginModuleEnum,
} from "infra/api/contracts";
import { SecurityContext } from "infra/SecurityContext";
import Checklists from "components/controls/checklists/Checklists";
import CodeElementDropDown from "components/controls/codeElements/CodeElementDropDown";
import CodeElementMultiSelect from "components/controls/codeElements/CodeElementMultiSelect";
import DropDown from "components/controls/DropDown";
import { Card } from "components/card/Card";
import ToggleButton from "components/controls/ToggleButton";
import MultiToggleButton from "components/controls/MultiToggleButton";
import GeoLocation from "components/controls/geolocation/GeoLocation";
import Scheduler from "components/controls/scheduler/Scheduler";
import Notification from "components/controls/notification/Notification";
import FileDropzone from "components/controls/upload/FileDropzone";
import ReportDefinitionDetailsControl from "components/controls/reportDefinitionDetails/ReportDefinitionDetailsControl";
import CardList from "components/controls/cardlist/CardList";
import MailRecipients from "components/mailing/MailRecipients";
import MailAttachments from "components/mailing/MailAttachments";
import FormEditor from "components/controls/form/FormEditor";
import MaskedDateInput from "./MaskedDateInput";
import MaskedDateTimeInput from "./MaskedDateTimeInput";
import FieldLabel from "./FieldLabel";
import { TextArea } from "./TextArea";
import { RichTextEditor } from "./RichTextEditor";
import NumericRiskAnalysisValue from "components/controls/riskAnalysis/NumericRiskAnalysisValue";
import CodeElementList from 'components/controls/codeElements/CodeElementList';
import RelationshipList from "components/controls/RelationshipList";
import ReportHeaderFooterEditor from "components/controls/reportHeaderFooterEditor/ReportHeaderFooterEditor";
import MultiSelect from 'components/controls/MultiSelect';

import "@progress/kendo-theme-bootstrap/dist/all.scss";
import "./Field.scss";

export interface Schema {
  type: string;
  properties?: {};
  enum?: Array<string>;
  id?: string;
  readOnly?: boolean;
  multiLine?: boolean;
  required?: Array<string>;
  $ref?: string;
  format?: string;
  maxLength?: number;
  maximum?: number;
  minimum?: number;
  nullable?: boolean;
  items?: any;
}

export interface Field {
  name: string;
  schema: Schema;
  title: string;
  values: Array<any>;
  required: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  multiLine: boolean;
  fullWidth: boolean;
  filter(): Array<any>;
  getter(): any;
  parentGetter?(): any;
  setter(value: any): void;
  props: any;
  unit: string;
  securityContext: SecurityContext;
  parent: string;
  id: string;
  code: string;
  width?: string;
  typeOverride?: string;
  isLoaded?: boolean;
  stateProxy?: any;
  module?: OriginModuleEnum
}

export interface FieldProps {
  field: Field;
  className: string;
  noHeader?: any;
}

export interface FieldState {
  name: string;
  schema: Schema;
  id: string;
  type: string;
  className: string;
  required: boolean;
  readOnly: boolean;
  multiLine: boolean;
  fullWidth: boolean;
  showHeader: boolean;
  title?: string;
  allValues: Array<any>;
  values: Array<any>;
  error: any;
  getTitle?(value: any): string;
  serialize?(value: any): any;
  deserialize?(value: any): any;
  ref?: string;
  disabled: boolean;
  typeOverride?: string;
}

export interface ISubscription {
  dispose?(): void;
}

export class Field extends Component<FieldProps, FieldState> {
  subscriptions?: { lookups: ISubscription };

  constructor(props: FieldProps) {
    super(props);

    const field = props.field;
    const name = field.name;
    const schema = field.schema || {};
    const showHeader = !props["noHeader"];
    const title = field.title || arxs.t(`field.${name}`);
    const values = field.values;

    this.state = {
      id: field.id,
      name: name,
      schema: schema,
      type: schema.type,
      typeOverride: field.typeOverride,
      className: props.className || "",
      required: field.required,
      readOnly: schema.readOnly || field.readOnly || false,
      multiLine:
        field.multiLine !== undefined
          ? !!field.multiLine
          : schema.multiLine || name === "description",
      fullWidth: field.fullWidth,
      showHeader,
      title,
      allValues: [],
      values: values || [],
      disabled: schema.readOnly || field.disabled || false,
      error: null,
    };
  }

  componentDidUpdate(prevProps: FieldProps, prevState: FieldState) {
    const prevField = prevProps.field || {};
    const field = this.props.field;
    const filter = field.filter;

    if (this.state.schema["$ref"]) {
      if (filter) {
        const rawValue = field.getter();
        const previousValues = this.state.values;
        const values = this.state.values.filter(filter);
        if (rawValue && values.length === 0 && previousValues.length > 0) {
          field.setter(null);
        }
      }
    }
    if (!this.state.schema.readOnly && prevField.disabled != field.disabled) {
      this.setState({ disabled: field.disabled });
    }
  }

  componentWillUnmount() {
    if (this.subscriptions && this.subscriptions.lookups.dispose) {
      this.subscriptions.lookups.dispose();
    }
  }

  componentDidMount() {
    const name = this.state.name;
    const schema = this.state.schema;
    const type = schema["type"];
    let serialize = (x: any) => x;
    let deserialize = (x: any) => x;

    if (this.state.typeOverride) {
      if (this.state.typeOverride === "time") {
        this.setState({
          schema: {
            type: "string",
            format: "time",
          },
        });
        return;
      }
      if (this.state.typeOverride === "riskValue") {
        this.setState({
          type: "riskValue",
        });
        return;
      }
    }

    if (type === "array") {
      if (!schema.items) {
        return;
      }

      const link = schema.items["$ref"];
      if (link) {
        const match = link.match(/.*\/([^/]+)/);
        if (match === null) {
          arxs.logger.error("Unexpected ref {fieldName}", name);
          this.setState({ error: `${name}, unexpected ref` });
          return;
        }

        const ref = match[1];

        if ((ref === "LinkRef" || ref === "SubjectRef")
          && (name === "subjects" || name === "origins")
        ) {
          this.setState({ type: "subjects" });
          return;
        }

        if (ref === "CodeElementRef") {
          this.setState({ type: "codeElements" });
          return;
        }

        if (ref === "Checklist") {
          this.setState({ type: "checklists" });
          return;
        }

        if (ref === "File") {
          this.setState({ type: "files" });
          return;
        }

        if (ref === "ReportModuleSetting") {
          this.setState({ type: "reportDefinitionDetails" });
          return;
        }

        if (ref === "MailRecipient") {
          this.setState({ type: "mailRecipients" });
        }

        if (ref === "MailAttachment") {
          this.setState({ type: "mailAttachments" });
        }

        if (ref === "Relationship") {
          this.setState({ type: "relationships" });
        }
      }
    }

    const link = schema["$ref"];
    if (link) {
      const match = link.match(/.*\/([^/]+)/);
      if (match === null) {
        arxs.logger.error("Unexpected ref {fieldName}", name);
        this.setState({ error: `${name}, unexpected ref` });
        return;
      }

      const ref = match[1];
      if (ref === "CodeElementRef") {
        this.setState({ type: "codeElement" });
        return;
      }

      if (ref === "EmployeeRef" && this.props.field.type === "card") {
        this.setState({ type: "card" });
        return;
      }

      if ((ref === "LinkRef" || ref === "SubjectRef")
        && (name === "subject" || name === "card" || name === "location" || name === "context")
      ) {
        this.setState({ type: "card" });
        return;
      }

      if (ref === "Fraction" && name === "fraction") {
        this.setState({ type: "fraction" });
        return;
      }

      const refSchema = arxs.Api.getSchema(ref);
      if (!refSchema) {
        arxs.logger.error("Schema not found {fieldName} {ref}", name, ref);
        this.setState({ error: `${name}, schema not found` });
        return;
      }

      if (ref === "GeoLocation") {
        this.setState({ type: "geoLocation" });
        return;
      }

      if (ref === "Files") {
        this.setState({ type: "files" });
        return;
      }

      if (ref === "GeographicCoordinate") {
        this.setState({ type: "coordinate" });
        return;
      }

      if (ref === "Schedule") {
        this.setState({ type: "schedule" });
        return;
      }

      if (ref === "Notification") {
        this.setState({ type: "notification" });
        return;
      }

      if (ref === "ReportDefinitionDetails") {
        this.setState({ type: "reportDefinitionDetails" });
        return;
      }

      if (ref === "FormDefinition") {
        this.setState({ type: "form" });
        return;
      }

      if (ref === "ReportHeaderFooterSettings") {
        this.setState({ type: "reportHeaderFooterEditor" });
        return;
      }

      if (refSchema.enum) {
        let type;
        let values;
        switch (ref) {
          case "RelationshipType":
          case "OriginModuleEnum":
          case "ReportMainGrouping":
          case "ObjectDocumentType":
          case "HazardousSubstanceWGK":
          case "HazardousSubstanceSignalWord":
            type = "comboBox";
            values = (
              this.state.values.length > 0 ? this.state.values : []
            ).map((x: any) => ({
              id: x,
              name: arxs.enums.getTitle(ref, x) || x,
              icon: arxs.enums.getClassName(ref, x) || null,
            }));
            deserialize = (x) => ({ id: x });
            serialize = (x) => x ? x.id : null;
            break;
          case "WeekOfMonth":
          case "DayOfWeek":
            type = "comboBox";
            values = refSchema.enum.map((x: any) => ({
              id: x,
              name: arxs.enums.getTitle(ref, x) || x,
            }));
            deserialize = (x) => ({ id: x });
            serialize = (x) => (x ? x.id : null);
            break;
          case "MonthOfYear":
              type = "multiselect";
              values = refSchema.enum.map((x: any) => ({
                id: x,
                name: arxs.enums.getTitle(ref, x) || x,
              }));
              deserialize = (x) => ({ id: x });
              serialize = (x) => (x ? x.id : null);
                break;
          default:
            if (refSchema.type === "integer") {
              type = "toggleMulti";
            } else {
              type = "toggle";
            }
            values = refSchema.enum;
        }
        this.setState({
          type,
          values,
          getTitle: (value: any) => arxs.enums.getTitle(ref, value) || value,
          serialize,
          deserialize,
          ref,
        });
        return;
      }

      this.subscriptions = {
        lookups: arxs.Api.lookups.subscribeToResource(name, (items: any) =>
          this.setState({ type: "comboBox", values: items })
        ),
      };
    }
  }

  formatForDisplay = (type: string, schema: any, field: Field) => {
    const rawValue = field.getter();

    if (!rawValue) {
      return "";
    }

    if (type === "string" && schema.format === "date-time") {
      if (field.props) {
        if (field.props.showTime) {
          return arxs.dateTime.formatDateTime(rawValue);
        }
        if (field.props.longDate) {
          return arxs.dateTime.formatLongDate(rawValue);
        }
      }
      return arxs.dateTime.formatDate(rawValue);
    }
    if (type === "string" && schema.format === "time") {
      return rawValue;
    }
    if (type === "string") {
      if (field.props && field.props.markDown === true) {
        return (
          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(marked.parse(rawValue || "")),
            }}
          />
        );
      } else {
        return (rawValue || "")
          .split("\n")
          .map((item: any, key: React.Key | null | undefined) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ));
      }
    }
    if (type === "codeElements") {
      return <CodeElementList readOnly={true} value={rawValue} />;
    }
    if (type === "relationships") {
      return <RelationshipList readOnly={true} value={rawValue} />;
    }
    if (schema["$ref"]) {
      if (type === "codeElement") {
        var codeElementLookup = arxs.Api.lookups.getLookup("codeElementsById");
        if (type === "codeElement") {
          return (codeElementLookup[rawValue.id] || {}).name;
        }
      }

      if (type === "card") {
        return "";
      }

      if (type === "toggle") {
        return this.state.getTitle && this.state.getTitle(rawValue);
      }

      const filter = field.filter;
      const values = filter
        ? this.state.values.filter(filter)
        : this.state.values;
      const value =
        rawValue && values.filter((x) => x.id === (rawValue.id || rawValue))[0];

      return value && value["name"];
    }

    if (typeof rawValue === "object") {
      return this.state.getTitle;
    }

    //TODO: need to take into account if unit should be in front or after the value.
    if (field.unit) {
      return rawValue && `${rawValue} ${field.unit}`;
    }

    return rawValue;
  };

  // TODO: Need a componentDidUpdate where whenever the user changes another input resulting in us reevaluating the allowed values
  // Why isn't the kendoComboBox triggering onChange since the value dissapears

  renderReadOnly = (
    field: Field,
    state: FieldState,
    id: string,
    className: string
  ) => {
    const { title, type, schema, showHeader } = state;

    if (field.getter) {
      const fieldClassPrefix = field.name
        .split(/(?=[A-Z])/)
        .map((x) => x.toLowerCase())
        .join("-");
      const rawValue = field.getter();

      if (type === "card" && rawValue) {
        return (
          <div className={`${className} full-width`} style={this.props.style}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            <Card
              module={rawValue.module}
              objectId={rawValue.id}
              showImage
              showOpenInTab
            />
          </div>
        );
      }

      if (type === "riskValue") {
        return (
          rawValue && <NumericRiskAnalysisValue value={rawValue} readOnly={true} />
        );
      }

      if (type === "reportHeaderFooterEditor") {
        return (
          rawValue && <ReportHeaderFooterEditor value={rawValue} onChange={() => {}} />
        );
      }

      if (type === "subjects" && rawValue) {
        return (
          <div className={`${className} full-width`} style={this.props.style}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            {rawValue.map((v: any) => (
              <Card
                key={v.id}
                module={v.module}
                objectId={v.id}
                showImage
                showOpenInTab
              />
            ))}
          </div>
        );
      }

      if (type === "boolean") {
        return (
          <div className={className} style={this.props.style}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            <div className="switch">
              <Switch id={id} checked={!!field.getter()} />{" "}
              {showHeader && title && (
                <label
                  htmlFor={id}
                  style={{
                    display: "block",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  {title}
                </label>
              )}
            </div>
          </div>
        );
      }

      if (type === "fraction") {
        const value = field.getter();
        return (
          <div className={className} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                style={{ display: "inline-block", marginRight: "10px" }}
              />
            }
            <div>
              {value && (
                <span>
                  {value.numerator ? value.numerator : 0} /{" "}
                  {value.denominator ? value.denominator : 0}
                </span>
              )}
            </div>
          </div>
        );
      }

      if (type === "geoLocation") {
        const value = field.getter();
        return (
          <GeoLocation
            className="field"
            title={title ? title : ""}
            value={value}
            readOnly={true}
          />
        );
      }

      if (type === "form") {
        const value = field.getter();
        return (
          <FormEditor
            className="field"
            value={value}
            readOnly={true}
            {...field.props}
            stateProxy={field.stateProxy}
            module={field.module}
          />
        );
      }

      if (type === "schedule") {
        const value = field.getter();
        return (
          <Scheduler
            title={title}
            value={value}
            readOnly={true}
            {...field.props}
          />
        );
      }

      if (type === "notification") {
        const value = field.getter();
        return <Notification title={title} value={value} readOnly={true} />;
      }

      if (type === "checklists") {
        const value = field.getter();

        return (
          <div className={className} style={this.props.style}>
            <Checklists
              value={value}
              onChange={(arg) => field.setter(arg)}
              securityContext={field.securityContext}
              readOnly={true}
              {...field.props}
            />
          </div>
        );
      }

      if (type === "reportDefinitionDetails") {
        const value = field.getter();
        return (
          <ReportDefinitionDetailsControl
            value={value}
            readOnly={true}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
          />
        );
      }

      if (type === "mailRecipients") {
        const value = field.getter();
        return (
          <div className={className} style={this.props.style}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            <MailRecipients
              value={value}
              readOnly={true}
              onChange={(arg) => field.setter(arg)}
              securityContext={field.securityContext}
            />
          </div>
        );
      }

      if (type === "mailAttachments") {
        const value = field.getter();
        return (
          <div className={className} style={this.props.style}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            <MailAttachments
              value={value}
              readOnly={true}
              onChange={(arg) => field.setter(arg)}
              securityContext={field.securityContext}
              {...field.props}
            />
          </div>
        );
      }

      if (type === "multiselect") {
        const deserialize = this.state.deserialize || ((x) => x);
        const serialize = this.state.serialize || ((x) => x);
  
        const values = field.values || this.state.values;
        const value = (field.getter() || []).map(deserialize);
  
        return (
          <div className={className} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
              />
            }
            <MultiSelect
              selected={value}
              onChange={(selected) => field.setter(selected.map(serialize))}
              items={values}
              readOnly={true}
            />
          </div>
        );
      }

      if (schema["$ref"] && type === "toggle") {
        const toggleClassName = [
          rawValue && "badge",
          rawValue && `${fieldClassPrefix}-${("" + rawValue).toLowerCase()}`,
        ]
          .filter((x) => x)
          .join(" ");
        return (
          <div className={`field ${field.fullWidth ? "full-width" : ""}`}>
            {<FieldLabel id={id} title={title} showHeader={showHeader} />}
            <div className={toggleClassName}>
              {this.formatForDisplay(type, schema, field)}
            </div>
          </div>
        );
      }
    }

    return (
      <div className={className} style={this.props.style}>
        {<FieldLabel id={id} title={title} showHeader={showHeader} />}
        <div className="field-ellipsis">
          {this.formatForDisplay(type, schema, field)}
        </div>
      </div>
    );
  };

  renderReadWrite = (
    field: Field,
    state: FieldState,
    id: string,
    className: string
  ) => {
    const {
      name,
      title,
      required,
      type,
      schema,
      multiLine,
      disabled,
      showHeader,
    } = state;

    if (type === "string") {
      if (schema.format === "date-time") {
        const rawValue = field.getter();
        const value = rawValue ? new Date(rawValue) : null;

        return (
          <div
            className={className + " date-time-picker"}
            style={this.props.style}
          >
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                required={required}
              />
            }
            <div className="date-time-picker">
              {field.props && field.props.showTime && !field.props.split && (
                <DateTimePicker
                  className="input"
                  dateInput={MaskedDateTimeInput}
                  value={value}
                  onChange={(event) => field.setter(event.target.value)}
                  weekNumber={true}
                  disabled={disabled}
                />
              )}
              {field.props && field.props.showTime && field.props.split && (
                <Fragment>
                  <DatePicker
                className="input"
                dateInput={MaskedDateInput}
                value={value}
                onChange={(event) => field.setter(event.value?.setHours(12,0,0))}
                weekNumber={true}
                disabled={disabled}
                />
                <TimePicker
                  className="input"
                  value={value}
                  onChange={(event) => field.setter(event.value)}
                  disabled={disabled}
                  placeholder={"uu:mm"}
                />
              </Fragment>
              )}
              {(!field.props || !field.props.showTime) && (
                <DatePicker
                  className="input"
                  dateInput={MaskedDateInput}
                  value={value}
                  onChange={(event) => field.setter(event.value)}
                  weekNumber={true}
                  disabled={disabled}
                />
              )}
              <div className="date-time-picker-now">
                <div className="fa-regular fa-reply-clock" onClick={() => field.setter(new Date()) }></div>
              </div>
            </div>
          </div>
        );
      } else if (schema.format === "time") {
        const rawValue = field.getter();

        let value = null;
        if (rawValue) {
          const timeTokens = rawValue
            .split(/[:.]+/)
            .map((x: string) => parseInt(x));
          const date = new Date();
          date.setHours(
            timeTokens[0],
            timeTokens[1],
            timeTokens[2],
            timeTokens[3]
          );
          value = date;
        }
        const mapToTimeOnly = (date?: Date | null) => {
          if (!date) return null;
          const serialized =
            [date.getHours(), date.getMinutes()]
              .map((x) => String(x).padStart(2, "0"))
              .join(":") + ":00.000";
          return serialized;
        };

        return (
          <div className={className + " time-picker"} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                required={required}
              />
            }
            <div className="time-picker">
              <TimePicker
                className="input"
                value={value}
                onChange={(event) => field.setter(mapToTimeOnly(event.value))}
                disabled={disabled}
              />
            </div>
          </div>
        );
      } else {
        const classNames = ["full-width", !multiLine && "input"]
          .filter((x) => x)
          .join(" ");

        return (
          <div className={className} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                required={required}
              />
            }
            {field.props && field.props.markDown === true ? (
              <RichTextEditor
                className={classNames}
                field={{ ...field, maxLength: schema.maxLength }}
                readOnly={disabled}
              />
            ) : (
                <TextArea
                  className={classNames}
                  field={{ ...field, maxLength: schema.maxLength }}
                  readOnly={disabled}
                />
              )}
          </div>
        );
      }
    }

    if (type === "number") {
      if (schema.format === "double") {
        const value = field.getter();
        // To customize this see
        // - https://www.telerik.com/kendo-react-ui-develop/components/inputs/numerictextbox/formats/
        // - https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        const currencyOptions = {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol",
          useGrouping: true,
        };
        let options: any = {
          style: "decimal",
        };
        let placeholder = "";
        let min = undefined;
        let max = undefined;
        if (field.unit) {
          options = `#,##0.## "${field.unit}"`;
          min = 0;
          placeholder = `${field.unit}`;
        } else if (name && (name.match(/price/i) || name.match(/cost/i))) {
          options = currencyOptions;
          placeholder = `EUR`;
          min = 0;
        } else if (name && name.match(/duration/i)) {
          options = `#,##0.## "${arxs.t("wizard.common.hours")}"`;
          placeholder = `${arxs.t("wizard.common.hours")}`;
          min = 0;
        }
        if (schema.maximum) {
          max = schema.maximum;
        }
        return (
          <div className={className} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                required={required}
              />
            }
            <NumericTextBox
              spinners={false}
              min={min}
              max={max}
              value={value}
              onChange={(event) => field.setter(event.target.value)}
              format={options}
              placeholder={placeholder}
              disabled={disabled}
              width={field.width}
            />
          </div>
        );
      }
    }

    if (type === "integer") {
      if (schema.format === "int32") {
        const value = field.getter();
        let options: any = {
          style: "decimal",
        };
        let placeholder = "";
        let min = undefined;
        let max = undefined;

        let format = "#,##0.##";
        if (field.props && field.props.wholeNumber) {
          format = "#,##0";
        }

        const currencyOptions = {
          style: "currency",
          currency: "EUR",
          currencyDisplay: "symbol",
          useGrouping: true,
        };
        if (field.unit) {
          options = `${format} ${field.unit}`;
          placeholder = `${field.unit}`;
          min = 0;
        } else if (name && (name.match(/price/i) || name.match(/cost/i))) {
          options = currencyOptions;
          placeholder = `EUR`;
          min = 0;
        } else if (name && name.match(/duration/i)) {
          options = `${format} "${arxs.t("wizard.common.hours")}"`;
          placeholder = `${arxs.t("wizard.common.hours")}`;
          min = 0;
        }
        if (schema.minimum) {
          min = schema.minimum;
        }
        if (schema.maximum) {
          max = schema.maximum;
        }

        return (
          <div className={className} style={this.props.style}>
            {
              <FieldLabel
                id={id}
                title={title}
                showHeader={showHeader}
                required={required}
              />
            }
            <NumericTextBox
              spinners={false}
              min={min}
              max={max}
              value={value}
              onChange={(event) => field.setter(event.target.value)}
              format={options}
              placeholder={placeholder}
              disabled={disabled}
              width={field.width}
            />
          </div>
        );
      }
    }

    if (type === "boolean") {
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              /* title={title} */ showHeader={showHeader}
              required={required}
            />
          }
          <div className="switch">
            <Switch
              id={id}
              checked={!!field.getter()}
              onChange={(event) => field.setter(event.target.value)}
              disabled={disabled}
            />
            {showHeader && title && (
              <label
                htmlFor={id}
                style={{
                  display: "inline-block",
                  marginRight: "10px",
                  marginBottom: "5px",
                }}
              >
                {title}
                {required && <span> *</span>}
              </label>
            )}
          </div>
        </div>
      );
    }

    if (type === "codeElement") {
      const value = field.getter();
      const selectedParents: any = field.parentGetter
        ? [field.parentGetter()].filter((x) => x)
        : null;

      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <CodeElementDropDown
            id={field.name}
            code={field.code}
            selected={value}
            selectedParents={selectedParents}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
            items={[]}
            readOnly={disabled}
            {...field.props}
            filter={field.filter}
          />
        </div>
      );
    }

    if (type === "geoLocation") {
      const value = field.getter();
      return (
        <GeoLocation
          className={`field ${field.fullWidth ? "full-width" : ""}`}
          title={title ? title : ""}
          value={value}
          onChange={field.setter}
          readOnly={disabled}
        />
      );
    }

    if (type === "card") {
      const value = field.getter();
      const isArray = Array.isArray(value);

      const onChange = (rawValue: any) => {
        const value = isArray ? rawValue : (rawValue && rawValue[0]) || null;
        field.setter(value);
      };

      return (
        <CardList
          className="field"
          title={showHeader ? title : null}
          readOnly={this.props.readOnly}
          value={isArray || !value ? value : [value]}
          onChange={onChange}
          singleSelection={(field.props || {}).singleSelection || !isArray}
          {...field.props}
          required={required}
          prefilter={field.filter}
          securityContext={field.securityContext}
        />
      );
    }

    if (type === "schedule") {
      const value = field.getter();
      return (
        <Scheduler
          title={title}
          value={value}
          onChange={field.setter}
          readOnly={disabled}
          isLoaded={field.isLoaded}
          {...field.props}
        />
      );
    }

    if (type === "form") {
      const value = field.getter();
      return (
        <FormEditor
          className="field"
          value={value}
          onChange={field.setter}
          readOnly={disabled}
          {...field.props}
          stateProxy={field.stateProxy}
          module={field.module}
        />
      );
    }

    if (type === "notification") {
      const value = field.getter();
      return (
        <Notification
          title={title}
          value={value}
          onChange={field.setter}
          readOnly={disabled}
        />
      );
    }

    if (type === "reportDefinitionDetails") {
      const value = field.getter();
      return (
        <ReportDefinitionDetailsControl
          value={value}
          readOnly={disabled}
          onChange={(arg) => field.setter(arg)}
          securityContext={field.securityContext}
        />
      );
    }

    if (type === "mailRecipients") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <MailRecipients
            value={value}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
          />
        </div>
      );
    }

    if (type === "mailAttachments") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <MailAttachments
            value={value}
            readOnly={disabled}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "codeElements") {
      const value = field.getter();
      const selectedParents = field.parentGetter
        ? [field.parentGetter()].filter((x) => x)
        : null;

      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <CodeElementMultiSelect
            id={field.name}
            code={field.code}
            selected={value}
            selectedParents={selectedParents ? selectedParents : []}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
            items={[]}
            readOnly={disabled}
          />
        </div>
      );
    }

    if (type === "relationships") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          <FieldLabel
            id={id}
            title={title}
            showHeader={showHeader}
            required={required}
          />
          <RelationshipList
            id={field.name}
            field={field}
            value={value}
            onChange={(arg: any) => field.setter(arg)}
            securityContext={field.securityContext}
            readOnly={disabled}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "riskValue") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          <FieldLabel
            id={id}
            title={title}
            showHeader={showHeader}
            required={required}
          />
          <NumericRiskAnalysisValue
            value={value}
            onChange={(arg: any) => field.setter(arg)}
            readOnly={disabled}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "reportHeaderFooterEditor") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          <FieldLabel
            id={id}
            title={title}
            showHeader={showHeader}
            required={required}
          />
          <ReportHeaderFooterEditor
            value={value}
            onChange={(arg: any) => field.setter(arg)}
            readOnly={disabled}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "checklists") {
      const value = field.getter();

      return (
        <div className={className} style={this.props.style}>
          <Checklists
            value={value}
            onChange={(arg) => field.setter(arg)}
            securityContext={field.securityContext}
            readOnly={disabled}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "files") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <FileDropzone
            value={value}
            onChange={(arg: any) => field.setter(arg)}
            securityContext={field.securityContext}
            readOnly={disabled}
            correlationKey={(field.props || {}).correlationKey}
            {...field.props}
          />
        </div>
      );
    }

    if (type === "toggle") {
      const value = field.getter();
      const ref = this.state.ref;
      const required = field.props && field.props.required;

      const unfilteredValues = field.values || this.state.values;
      const filter = field.filter;
      const values = filter
        ? unfilteredValues.filter(filter)
        : unfilteredValues;

      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <ToggleButton
            value={value}
            values={values}
            textSelector={this.state.getTitle}
            onChange={(_: any, value: any) =>
              required
                ? field.setter(value)
                : field.setter(value === field.getter() ? null : value)
            }
            getItemClassName={(x) => arxs.enums.getClassName(ref, x) || ""}
            readOnly={disabled}
          />
        </div>
      );
    }

    if (type === "toggleMulti") {
      const value = field.getter();
      const values = field.values || this.state.values;

      const ref = this.state.ref;
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <MultiToggleButton
            value={value}
            values={values}
            textSelector={this.state.getTitle}
            onChange={(_, value) => field.setter(value)}
            getItemClassName={(x) => arxs.enums.getClassName(ref, x) || ""}
            readOnly={disabled}
          />
        </div>
      );
    }
    
    if (type === "multiselect") {
      const deserialize = this.state.deserialize || ((x) => x);
      const serialize = this.state.serialize || ((x) => x);

      const values = field.values || this.state.values;
      const value = (field.getter() || []).map(deserialize);

      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <MultiSelect
            selected={value}
            onChange={(selected) => field.setter(selected.map(serialize))}
            items={values}
            readOnly={disabled}
          />
        </div>
      );
    }

    if (type === "fraction") {
      const value = field.getter();
      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <div className="fraction">
            <NumericTextBox
              value={value ? value.numerator : 0}
              onChange={(event) =>
                field.setter({ ...value, numerator: event.target.value })
              }
              disabled={disabled}
            />
            <div className="fraction-char">/</div>
            <NumericTextBox
              value={value ? value.denominator : 0}
              onChange={(event) =>
                field.setter({ ...value, denominator: event.target.value })
              }
              disabled={disabled}
            />
          </div>
        </div>
      );
    }

    if (schema["$ref"]) {
      const rawValue = field.getter();

      const parent = field.parentGetter && field.parentGetter();
      const filter = field.filter;
      const unfilteredValues = this.state.values || [];
      const filteredValues = (
        filter ? unfilteredValues.filter(filter) : unfilteredValues
      ).filter((x) => !x.isDeleted);

      // Childvalues are always looked up based on their parent, ignoring security context.
      // The security context was already checked for its parent.
      const securityFilter = field.securityContext
        ? field.securityContext.filter
        : () => true;
      const values = parent
        ? filteredValues.filter((x) => (x[field.parent] ||{}).id === parent.id)
        : filteredValues.filter(securityFilter);

      const deserialize = this.state.deserialize || ((x) => x);
      const serialize = this.state.serialize || ((x) => x);
      const value =
        unfilteredValues.filter(
          (x) => rawValue && x.id === deserialize(rawValue).id
        )[0] || null; // undefined is not a valid "empty" value

      return (
        <div className={className} style={this.props.style}>
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          <DropDown
            id={id}
            items={values}
            selected={value}
            onChange={(value) => field.setter(serialize(value))}
            readOnly={disabled}
            width={field.width || (field.props || {}).width}
          />
        </div>
      );
    }

    return (
      <div className={className} style={this.props.style}>
        {
          <FieldLabel
            id={id}
            title={title}
            showHeader={showHeader}
            required={required}
          />
        }
      </div>
    );
  };

  render() {
    const { field } = this.props;
    const {
      name,
      title,
      required,
      type,
      error,
      multiLine,
      fullWidth,
      showHeader,
    } = this.state;
    const id = this.state.id || `field-${name}`;
    const className = [
      "field",
      this.state.className,
      type === "string" && (multiLine || fullWidth) && "full-width",
      field.validation && "error",
    ]
      .filter((x) => x)
      .join(" ");

    field.multiLine = field.multiLine || multiLine;

    if (error) {
      return (
        <div
          className={`${className} field-not-found`}
          style={this.props.style}
        >
          {
            <FieldLabel
              id={id}
              title={title}
              showHeader={showHeader}
              required={required}
            />
          }
          {error || `${this.state.name} not found`}
        </div>
      );
    }

    if (field.readOnly || this.state.readOnly || this.props.readOnly) {
      return this.renderReadOnly(field, this.state, id, className);
    }

    return this.renderReadWrite(field, this.state, id, className);
  }
}
export default Field;
