import { Component } from 'react';
import arxs from 'infra/arxs';

import { DropDownItem } from '../DropDown';
import { CodeElement, CodeElementSchema } from 'infra/LookupContracts';
import { SecurityContext } from 'infra/SecurityContext';

export interface ISubscription {
  dispose?(): void,
}

export interface CodeElementGroup {
  name: string,
  items: Array<DropDownItem>,
}

export interface CodeElementComponentProps {
  className?: string,
  items: Array<DropDownItem>,
  selected: any,
  selectedParents: Array<DropDownItem>,
  onChange(value: Array<DropDownItem> | DropDownItem | null): void,
  placeholder?: string,
  idField?: string,
  textField?: string,
  code?: string,
  codeElementType?: string,
  modules?: { [key: string]: string, },
  securityContext: SecurityContext,
  id?: string,
  readOnly?: boolean,
  sortByCode?: boolean,
  filter?(value:any):boolean
}

export interface CodeElementComponentState {
  codeElements: { [key: string]: Array<CodeElement> },
  codeElementsSchema: { [key: string]: CodeElementSchema, },
  codeElementsById: { [key: string]: CodeElement, },
  pristine: {},
  items: Array<CodeElementGroup>,
}

class CodeElementComponent extends Component<CodeElementComponentProps, CodeElementComponentState> {
  lookups = {
    codeElements: {},
    codeElementsSchema: {},
    codeElementsById: {}
  }

  notFound: CodeElementGroup = {
    name: "Instelling niet gevonden",
    items: []
  }

  groupCodes = {
    EmptyGroup: "EmptyGroup",
    TypeGroup: "TypeGroup",
    NormGroup: "NormGroup"
  }

  subscriptions?: { lookups: ISubscription }

  constructor(props: CodeElementComponentProps) {
    super(props);

    this.state = {
      ...this.lookups,
      pristine: {},
      items: [],
    };
  }

  /*
    When designing components that deal with large amounts of in-memory items, we are quickly confronted with how react re-renders "dirty" components.
    Whenever a render loop does a lot of work user-experience suffers as rendering takes longer than it needs to.
    Ideally we design components to always render based on fixed state from this.state.??? or this.props.???.
  
    Some design principles:
    - if the raw input state is not in the right format, project it eagerly (componentDidMount or componentDidUpdate)
    - if the state needs to be enriched with viewstate, such as which items are selected or what has the user typed, it can be
      advantageous to refresh the state in componentDidUpdate
    - if the component needs external state aswell as project/enrich state, it can be useful to split up both operations, since
      projecting/enriching is often called more as a result of other triggers, and you don't want to refresh the state from the server.
  */

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups: any) => this.setState({ ...lookups }, this.refresh))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions && this.subscriptions.lookups.dispose) {
      this.subscriptions.lookups.dispose();
    }
  }

  componentDidUpdate(prevProps: CodeElementComponentProps) {
    const hasSelectedChanged = !Object.areKeysEqual(this.props.selected, prevProps.selected);
    const hasSelectedParentsChanged = !(this.props.selectedParents || [])
      .map(x => x.id)
      .valueEquals((prevProps.selectedParents || []).map(x => x.id));
    const hasModulesChanged = !Object.areKeysEqual(this.props.modules, prevProps.modules);
    const hasCodeChanged = this.props.code !== prevProps.code;

    if (hasSelectedChanged || hasSelectedParentsChanged || hasModulesChanged || hasCodeChanged) {
      this.refresh();
    }
  }

  refresh = () => {
    const { code, codeElementType, modules } = this.props;

    let schemas;
    if (code) {
      const schema = this.state.codeElementsSchema[code];
      if (!schema) {
        return;
      }
      schemas = [schema];
    } else {
      schemas = Object.values(this.state.codeElementsSchema).filter(x => modules && modules[x.module] && x.name === codeElementType);
    }

    const items = schemas.map(schema => this.mapToDropDownItem(schema));

    this.setState({ items });
  }

  clearValue = () => {
    arxs.logger.warn("CodeElementComponent does not extend clearValue()");
  }

  mapToDropDownItem = (schema: CodeElementSchema): CodeElementGroup => {
    const flattenGroup = (groupCode: string | undefined, codeElement: CodeElement) => {
      if (groupCode === undefined || groupCode === null) {
        return [codeElement];
      }
      // Due to some dumb reason codeElements that live under an emptyGroup, do not have code set to their group name
      if (groupCode === this.groupCodes.EmptyGroup) {
        return codeElement.children;
      }
      // Other codes do (e.g. TypeGroup, NormGroup)
      return codeElement.children.filter(x => x.code === groupCode);
    };

    let codeElement = this.notFound;

    const securityFilter = ((this.props.securityContext || {}).filter || (() => false));

    const groupCode = schema.group;
    const roots = (this.state.codeElements[schema.root || schema.parent || schema.code] || [])
      .filter(securityFilter);

    if (roots) {
      let parents = roots;
      const codeElementsById = this.state.codeElementsById;
      const selectedParents = this.props.selectedParents
        ? this.props.selectedParents
          .map(x => codeElementsById[x.id])
          .filter(x => x)
          .toDictionary(x => x.name)
        : null;

      if (schema.root && schema.root !== schema.parent) {
        // hmmm, this makes the norm selection in the wizard works, but not sure why...
        // this.clearValue();

        parents = roots
          .flatMap(x => x.children || [])
          .flatMap(x => x.children || [])
          .filter(x => !selectedParents || selectedParents[x.name])
          .flatMap(x => flattenGroup(groupCode, x))
          ;
      } else if (schema.parent) {
        // this.clearValue();

        parents = roots
          .flatMap(x => x.children || [])
          .filter(x => !selectedParents || selectedParents[x.name])
          .flatMap(x => flattenGroup(groupCode, x))
          ;
      }

      codeElement = {
        name: schema.title,
        items: parents.flatMap(x => x.children || []).filter(x => x),
      };
    }

    return codeElement;
  }
}
export default CodeElementComponent;