import WizardController from "modules/WizardController";
import arxs from "infra/arxs";

class TaskWizard extends WizardController {
  completePriorityAndTargetDateBasedOnContext = (
    stateProxy,
    triggeringFieldName
  ) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      const getValue = this.buildGetValue(stateProxy);
      const kind = getValue("kind");
      const type = getValue("type");
  
      const refs = getValue("subjects"); // Assuming now it's a list of subjects
      
      if (refs && refs.length > 0 && stateProxy.getter("taskPriorityRules").length > 0) {
        const kindId = (kind || {}).id;
        const typeId = (type || {}).id;
  
        let bestMatchingRule = null;
  
        // Loop through each subject in the list
        refs.forEach(ref => {
          const subject = arxs.Api.lookups.resolveSubject(ref);
          const legalStructureId =
            ref.module === "SchoolGroup"
              ? subject.id
              : (subject.legalStructure || {}).id;
          const branchId =
            ref.module === "School" ? subject.id : (subject.branch || {}).id;
  
          const matchingRules = stateProxy
            .getter("taskPriorityRules")
            .filter((rule) =>
              (!rule.legalStructure || rule.legalStructure.id === legalStructureId) &&
              (!rule.branch || rule.branch.id === branchId) &&
              (!rule.kind || rule.kind.id === kindId) &&
              (!rule.type || rule.type.id === typeId)
            )
            .sort((ruleA, ruleB) =>
              (
                ((ruleB.legalStructure || {}).id === legalStructureId ? 8 : 0) +
                ((ruleB.branch || {}).id === branchId ? 4 : 0) +
                ((ruleB.kind || {}).id === kindId ? 2 : 0) +
                ((ruleB.type || {}).id === typeId ? 1 : 0)
              ) - (
                ((ruleA.legalStructure || {}).id === legalStructureId ? 8 : 0) +
                ((ruleA.branch || {}).id === branchId ? 4 : 0) +
                ((ruleA.kind || {}).id === kindId ? 2 : 0) +
                ((ruleA.type || {}).id === typeId ? 1 : 0)
              )
            );
  
          if (matchingRules.length > 0 && (!bestMatchingRule || matchingRules[0])) {
            bestMatchingRule = matchingRules[0];
          }
  
          if (triggeringFieldName === "priority") {
            const priority = getValue("priority");
            const priorityMatchedRule = matchingRules.filter(
              (x) => x.priority === priority
            )[0];
            if (priorityMatchedRule) {
              bestMatchingRule = priorityMatchedRule;
            }
          }
        });
  
        if (bestMatchingRule) {
          let targetDate = new Date();
          targetDate.setDate(
            targetDate.getDate() + bestMatchingRule.resolutionTime
          );
          targetDate.setHours(23, 59, 59, 0);
  
          const newData = {
            ...data,
            priority: bestMatchingRule.priority,
            targetDate,
          };
  
          stateProxy.setter({ data: newData }, resolve);
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  };

  getRequiredFields(getCurrentFieldValue) {
    // const status = getCurrentFieldValue("status");
    // if (["Active", "Completed", "OnHold"].contains(status)) {
    //   return ["targetDate"];
    // }
    return [];
  }

  validate(stateProxy, getCurrentFieldValue, preValidation) {
    // const status = getCurrentFieldValue("status");
    // Disabled due to : https://trello.com/c/iMqRzL34/28-tab-uitvoerder-is-niet-langer-verplicht-ongeacht-de-status
    // if (["Active", "Completed", "OnHold"].contains(status)) {
    //   const relationships = getCurrentFieldValue("relationships") || [];
    //   const hasAssignees = relationships.some(x => x.type === RelationshipType.Assignee);
    //   if (!hasAssignees) {
    //     preValidation["assignees"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.assignees`) }) };
    //   }
    // }
    return preValidation;
  }
}
export default new TaskWizard();
