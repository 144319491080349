import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';

class ContactBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const preferredAddress= (card.addresses || []).filter(x => x.isPreferred)[0] || (card.addresses || []).firstOrDefault((x) => x);
    const preferredEmail= (card.emails || []).filter(x => x.isPreferred)[0] || (card.emails || []).firstOrDefault((x) => x);
    const preferredPhone= (card.phones || []).filter(x => x.isPreferred)[0] || (card.phones || []).firstOrDefault((x) => x);

    return {
      ...card,
      suppliers: (card.suppliers || []).map(x => this.getLookupValue(stateProxy.getter("supplierMap"), x)),
      department: this.getCodeElementValue(card.department, stateProxy.getter("codeElementsById")),
      function: this.getCodeElementValue(card.function, stateProxy.getter("codeElementsById")),
      createdAt: card.createdAt && new Date(card.createdAt),
      address: preferredAddress && `${preferredAddress.street} ${preferredAddress.number}, ${preferredAddress.zipCode} ${preferredAddress.city}, ${preferredAddress.country}`,
      email: preferredEmail && preferredEmail.email,
      phone: preferredPhone && preferredPhone.number
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.firstname,
      card.surname,
      card.nationalNumber,
      card.function,
      card.department,
      ...(card.emails || []).map(x => x.email),
      ...(card.addresses || []).map(x => x.bus),
      ...(card.addresses || []).map(x => x.city),
      ...(card.addresses || []).map(x => x.country),
      ...(card.addresses || []).map(x => x.number),
      ...(card.addresses || []).map(x => x.street),
      ...(card.addresses || []).map(x => x.zipCode),
      ...(card.phones || []).map(x => x.number),
      ...(card.tags || []).map(x => x.name),
      ...(card.suppliers || []).map(x => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.contacts) {
            lookups.pristine = lookups.contacts
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.contacts;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
        });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Activities yet");
  }
}
export default new ContactBoard();