import React from 'react';
import EnumMetadata from 'infra/EnumMetadata';

export default class Icons extends React.Component {
  constructor(props) {
    super(props);

    const meta = new EnumMetadata();
    const iconMap = meta.classNames.OriginModuleEnum;
    const icons = Object.keys(iconMap).map(key => ({ module: key, className: iconMap[key] }));

    this.state = {
      iconRef: React.createRef(),
      canvasRef: React.createRef(),
      icons,
    };
  }

  getFontIcon = (className, width) => {
    const iconElement = this.state.iconRef.current;

    const i = document.createElement("i");
    i.setAttribute("class", className);
    iconElement.appendChild(i);

    const iStyle = window.getComputedStyle(i);
    const iBeforeStyle = window.getComputedStyle(i, ":before");

    const fontFamily = iStyle.getPropertyValue("font-family");
    const fontWeight = iStyle.getPropertyValue("font-weight");
    const fontSize = `${width / 2}px`;

    const res = {
      font: `${fontWeight} ${fontSize} ${fontFamily}`,
      text: String.fromCodePoint(iBeforeStyle.getPropertyValue("content").codePointAt(1))
    };

    iconElement.removeChild(i);

    return res;
  }

  renderIcons = () => {
    const element = this.state.canvasRef.current;
    const context = element.getContext("2d");
    const width = 32;

    context.fillStyle = "white";
    context.fillRect(0, 0, element.width, element.height);

    let offset = width;
    for (const icon of this.state.icons) {
      const x = this.getFontIcon(icon.className, width);
      context.font = x.font;
      context.fillStyle = "#4D79FD";
      context.textAlign = "center";
      context.fillText(x.text, offset - width / 2, width - 10);
      context.beginPath();
      context.moveTo(offset, 0);
      context.lineTo(offset, width);
      context.lineWidth = 1;
      context.stroke();
      offset += width;
    }
  }

  downloadIcons = () => {
    const element = this.state.canvasRef.current;
    const context = element.getContext("2d");
    const width = 32;

    const targetCanvas = document.createElement("canvas");
    targetCanvas.width = width;
    targetCanvas.height = width;
    const targetContext = targetCanvas.getContext("2d");

    const downloadIconAt = (index) => {
      const fileName = this.state.icons[index].module.toLowerCase();
      const offset = index * width;
      const imageData = context.getImageData(offset + 1, 1, 30, 30);
      targetContext.putImageData(imageData, 1, 1);
      targetCanvas.toBlob(
        blob => {
          const anchor = document.createElement("a");
          anchor.download = `${fileName}.png`;
          anchor.href = URL.createObjectURL(blob);
          anchor.click();
          URL.revokeObjectURL(anchor.href);

          if (index + 1 < this.state.icons.length) {
            setTimeout(() => downloadIconAt(index + 1), 100);
          }
        },
        "image/png"
      );
    };
    downloadIconAt(0);
  }

  render() {
    const height = 32;
    const width = height * this.state.icons.length;
    return <div style={{ display: "flex", flexDirection: "column" }}>
      <button onClick={this.renderIcons}>Render</button>
      <button onClick={this.downloadIcons}>Download</button>
      <canvas
        ref={this.state.canvasRef}
        style={{ width, height, border: "1px solid black", background: "white" }}
        width={width}
        height={height}
      />
      <div ref={this.state.iconRef} style={{ display: "none" }}></div>
    </div>;
  }
}