import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import GlobalContext from "infra/GlobalContext";
import FormItemController from "./FormItemController";
import DropDown from "components/controls/DropDown";
import { OriginModuleEnum } from "infra/api/contracts";
import { TextArea } from "components/controls/TextArea";
import { NumericTextBox } from "@progress/kendo-react-inputs";

import "./Periodicals.scss";

export default class Periodicals extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }

    renderPeriodical = (context, settings, onFieldChange, onDeleteItem, data) => {
        const { codeElementsById } = this.state;
        const { modules, executors, frequencies } = settings;
        const { readOnly } = this.props;
        const selectedModule = data && data.module && { id: data.module, name: arxs.modules.titles[data.module] };
        const selectedExecutor = data && data.executor && codeElementsById ? { id: data.executor, name: (codeElementsById[data.executor] || {}).name } : undefined;
        const selectedFrequency = data && data.frequency && codeElementsById ? { id: data.frequency, name: (codeElementsById[data.frequency] || {}).name } : undefined;

        return <div className="periodical" key={(data && data.id) || 0}>
            <div className="periodical-identification">
                <DropDown
                    id={`module-${data.id}`}
                    key={`module-${data.id}`}
                    className="periodical-module"
                    items={modules}
                    selected={selectedModule}
                    onChange={state => onFieldChange(data.id, "module", state.id)}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.module")}
                />
                <TextArea
                    readOnly={this.props.readOnly}
                    field={{ getter: () => data.description, setter: state => onFieldChange(data.id, "description", state), multiLine: false }}
                    required={this.props.required}
                />
                <div className="periodical-actions">
                    {!readOnly && data && <div className="periodical-action" onClick={() => onDeleteItem(data.id)}>
                        <i className="far fa-trash-alt"></i>
                    </div>}
                </div>
            </div>
            <div className="periodical-details">
                <DropDown
                    id={`executor-${data.id}`}
                    key={`executor-${data.id}`}
                    className="periodical-executor"
                    items={executors}
                    selected={selectedExecutor}
                    onChange={state => onFieldChange(data.id, "executor", state.id)}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.executor")}
                />
                <NumericTextBox
                    className="periodical-number"
                    spinners={false}
                    min={0}
                    max={20}
                    value={data.number}
                    onChange={(event) => onFieldChange(data.id, "number", event.target.value)}
                    disabled={readOnly}
                    placeholder={arxs.t("forms.items.periodicals.each")}
                />
                <DropDown
                    id={`frequency-${data.id}`}
                    key={`frequency-${data.id}`}
                    className="periodical-frequency"
                    items={frequencies}
                    selected={selectedFrequency}
                    onChange={state => onFieldChange(data.id, "frequency", state.id)}
                    readOnly={readOnly}
                    disableClearButton={true}
                    placeholder={arxs.t("forms.items.periodicals.frequency")}
                />
            </div>

        </div>;
    }

    renderPeriodicals = (context) => {
        const { codeElements } = this.state;
        const { readOnly } = this.props;
        const value = this.getter();
        const modules = [OriginModuleEnum.PeriodicControl, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.RiskAnalysis].map(x => ({ id: x, name: arxs.modules.titles[x] }));
        const executorKind = ((codeElements || {})["Executor"] || [])[0] || {};
        const executors = (executorKind.children || []).map(x => ({ id: x.id, name: x.name }));
        const frequencyIterationKind = ((codeElements || {})["PeriodicMaintenance.IterationFrequency"] || [])[0] || {};
        const frequencies = (frequencyIterationKind.children || []).map(x => ({ id: x.id, name: x.name }));

        const onFieldChange = (rowId, field, state) => {
            if (readOnly) return;
            const rowData = value.filter(x => x.id === rowId)[0];
            let newRowData = { ...rowData };
            newRowData[field] = state;
            onItemChange(newRowData);
        };

        const onItemChange = (state) => {
            if (readOnly) return;
            let newValue = [ ...value ];
            const index = newValue.findIndex(x => x.id === state.id);
            if (index === -1) {
                newValue.push(state);
            } else {
                newValue[index] = state;
            }
            this.setter(newValue);
        };

        const renderNonEmptyLines = () => {
            return value
                .map(line => this.renderPeriodical(context
                    , { modules, executors, frequencies }
                    , onFieldChange
                    , onDeleteItem
                    , line)
                );
        };

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };

        const onDeleteItem = (id) => {
            let newData = (value || []).filter(x => x.id !== id);
            this.setter(newData);
        };

        return <div className="periodicals">
            {renderNonEmptyLines()}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control">
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {this.renderPeriodicals(context)}
                    </div>
                    {this.renderFooter(context)}
                </div>
            }
        </GlobalContext.Consumer>)
    }
}
