import React from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardImage from "components/card/CardImage";

export default function FormCard(props: CardClassProps) {
    const { card, actionProps, cardProps } = props;
    return (
        <>
            <div className="list-card-left">
                <CardImage {...props} />
            </div>
            <div className="list-card-right">
                <h2>
                    <div className="list-card-title" title={card.title}>
                        {card.title}
                    </div>
                    <CardActions {...actionProps} />
                </h2>
                <CardSubtitle cardProps={cardProps} card={card} />
                <div className="list-card-details">
                    <div>
                    {[
                        arxs.modules.titles[card.targetModule] || card.targetModule,
                        card.kind,
                        card.type,
                    ]
                        .filter((x) => x)
                        .join(" - ")}
                    </div>
                    <div>
                        {arxs.t("kanban.common.changed")}:{" "}
                        {arxs.dateTime.formatDate(card.modifiedAt)}
                    </div>
                    <div>
                        {arxs.t("kanban.common.expires")}:{" "}
                        {card.revisionDate && arxs.dateTime.formatDate(card.revisionDate)}
                    </div>
                    <div>
                        {`${arxs.t("kanban.common.estimatedDuration")}: ${
                            (card.estimatedDuration &&
                            arxs.number.formatDecimal(card.estimatedDuration)) ||
                            ""
                        } ${card.estimatedDuration ? arxs.t("unit.h") : ""}`}
                    </div>
                    <div>
                        {`${arxs.t("kanban.common.estimatedCost")}: ${
                            (card.estimatedCost &&
                            arxs.number.formatDecimal(card.estimatedCost)) ||
                            ""
                        } ${card.estimatedCost ? arxs.t("unit.euro") : ""}`}
                    </div>
                </div>
            </div>
        </>
    );
}