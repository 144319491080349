import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import FolderButtonStrip from 'components/controls/folderbuttons/FolderButtonStrip';
import CardList from "components/controls/cardlist/CardList";
import SortKindType from 'components/controls/codeElements/SortKindType';
import StructureFilter from 'components/controls/structure/StructureFilter';
import { OriginModuleEnum } from "infra/api/contracts";
import { useIsMounted } from "infra/tools/ReactExtensions";
import PlanningDataSource from './PlanningDataSource';

import './PlannableItemSelector.scss';

const generateTab = (label, className, getter, ds) => {
  return { label, className, getter, count: () => ds ? getter(ds).cards.length : 0 };
};

const tabDefinitions = [
  {
    getLabel: (arxs) => arxs.t("planning.filters.overdue_tasks"),
    className: "status-error",
    getter: (ds) => ds.getExpired()
  },
  {
    getLabel: (arxs) => arxs.t("planning.filters.plan_today"),
    className: "status-active",
    getter: (ds) => ds.getToPlan(0, 1)
  },
  {
    getLabel: (arxs) => arxs.t("planning.filters.plan_1d_7d"),
    className: "status-active",
    getter: (ds) => ds.getToPlan(1, 8)
  },
  {
    getLabel: (arxs) => arxs.t("planning.filters.plan_7d_14d"),
    className: "status-active",
    getter: (ds) => ds.getToPlan(8, 15)
  },
  {
    getLabel: (arxs) => arxs.t("planning.filters.plan_14d"),
    className: "status-active",
    getter: (ds) => ds.getToPlan(15)
  },
  {
    getLabel: (arxs) => arxs.t("planning.filters.no_target_date"),
    className: "status-active",
    getter: (ds) => ds.getNoTargetDate()
  },
];

const PlannableItemSelector = (props) => {
  const { className, activeTab } = props;

  const isMounted = useIsMounted();

  const modulesInScope = [OriginModuleEnum.Task, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.PeriodicControl, OriginModuleEnum.RiskAnalysis].toDictionary(x => x, x => x);

  const [planningDataSource, setPlanningDataSource] = useState();
  const [selected, setSelected] = useState();
  const [initialTab, setInitialTab] = useState();

  const [searchTerm, setSearchTerm] = useState();
  const [sortSelected, setSortSelected] = useState([]);
  const [kindSelected, setKindSelected] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);
  const [legalStructureSelected, setLegalStructureSelected] = useState([]);
  const [branchSelected, setBranchSelected] = useState([]);
  const [buildingSelected, setBuildingSelected] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [criteria, setCriteria] = useState({});

  const [tabs, setTabs] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    let newCriteria = {
      "searchTerm": () => searchTerm,
      "modules": () => modulesInScope,
      "sort": () => sortSelected.toDictionary(x => x.name, x => x.name),
      "kind": () => kindSelected.toDictionary(x => x.name, x => x.name),
      "type": () => typeSelected.toDictionary(x => x.name, x => x.name),
      "legalStructure": () => legalStructureSelected.toDictionary(x => x.id, x => x.id),
      "branch": () => branchSelected.toDictionary(x => x.id, x => x.id),
      "building": () => buildingSelected.toDictionary(x => x.id, x => x.id),
      "location": () => locationSelected.toDictionary(x => x.id, x => x.id),
    };
    for (const key of Object.keys(newCriteria)) {
      criteria[key] = newCriteria[key];
    }
    setCriteria(criteria);
    refresh(planningDataSource);
  }, [
    searchTerm
    , sortSelected
    , kindSelected
    , typeSelected
    , legalStructureSelected
    , branchSelected
    , buildingSelected
    , locationSelected
  ]);

  const refresh = (ds, tab) => {
    if (!isMounted.current) {
      return;
    }

    const tabs = tabDefinitions.map(x => generateTab(x.getLabel(arxs), x.className, x.getter, ds));

    let activeTabLabel = (tab && tab.label) || activeTab;

    if (!initialTab && !tab && ds) {
      const tabsWithCards = tabs.filter(x => x.getter(ds).cards.length > 0);
      const firstTabWithCards = tabsWithCards[0];
      const activeTabHasCards = tabsWithCards.filter(_ => activeTab).any(x => x.label === activeTab.label);

      if (!activeTabHasCards && firstTabWithCards) {
        const tab = firstTabWithCards.label;
        onChangeActiveTab(firstTabWithCards);
        setInitialTab(tab);
        activeTabLabel = tab;
      }
    }

    activeTabLabel = activeTabLabel || tabs[0].label;

    const cards = activeTabLabel && ds
      ? tabs
        .filter(x => x.label === activeTabLabel)
        .flatMap(x => x.getter(ds).cards)
      : [];

    setTabs(tabs);
    setCards(cards);
  };

  useEffect(() => {
    // We only want to run this once, otherwise we introduce race conditions due to criteria refreshes
    // We really want to move criteria into the .get()
    const dataSource = new PlanningDataSource(criteria, props.securityContext);
    dataSource.setRefresh(() => refresh(dataSource));
    setPlanningDataSource(dataSource);
    return dataSource.dispose;
  }, [props.securityContext]);

  useEffect(() => {
    refresh(planningDataSource);
  }, [planningDataSource, activeTab, initialTab]);

  const onSelectCard = (e, card) => {
    setSelected(card);
    props.onSelectCard(card);
  };

  const onChangeActiveTab = (tab) => {
    if (planningDataSource) {
      planningDataSource.setRefresh(() => refresh(planningDataSource, tab))
    }
    onSelectCard();
    props.onChangeActiveTab && props.onChangeActiveTab((tab || {}).label);
  };

  const unplannedFirst = (card) => {
    const planningMoment = (card.inboundLinks || []).filter(x => !x.isDeleted && x.module === OriginModuleEnum.Planning)[0];
    return planningMoment ? 1 : 0;
  };

  let activeTabLabel = activeTab || (tabs[0] && tabs[0].label);

  return <div ref={props.innerRef} className={`plannable-items${className ? ` ${className}` : ""}`}>
    <div className="plannable-items-filters">
      <div className="input-wrapper far fa-search">
        <input type="text" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}
          placeholder={arxs.t("card_lookup.search_placeholder")} />
      </div>
      <StructureFilter
        modules={modulesInScope}
        onLegalStructureChange={setLegalStructureSelected}
        onBranchChange={setBranchSelected}
        onBuildingChange={setBuildingSelected}
        onLocationChange={setLocationSelected}
        securityContext={props.securityContext} />
      <SortKindType
        modules={modulesInScope}
        onTypeChange={setTypeSelected}
        onKindChange={setKindSelected}
        onSortChange={setSortSelected}
        securityContext={props.securityContext}
      />
    </div>
    <FolderButtonStrip items={tabs} active={activeTabLabel} onChange={onChangeActiveTab} />
    <CardList
      className="card-list"
      value={cards}
      selected={selected}
      readOnly={true}
      hideTitle={true}
      showOpenInTab={false}
      showOpenInWizard={true}
      onClick={onSelectCard}
      onMouseDown={props.onMouseDown}
      undraggable={props.undraggable}
      orderBy={unplannedFirst}
    />
  </div>;
};
export default PlannableItemSelector;