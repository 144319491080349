import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import GlobalActions from "modules/GlobalActions";

class ReportDefinitionActions {
  getActions = (module) => {
    return GlobalActions.getActions(module).concat([
      {
        name: "generate",
        module: module,
        icon: arxs.actions.icons["generate"],
        singleSelectionOnly: true,
        getTitle: () => arxs.t("actions.report.generate"),
        onClick: (state) => this.generate(state),
      },
    ]);
  };

  generate = (state) => {
    const ids = state.ids || [state.item.objectId];
    const data = { reportDefinitionId: ids[0] };

    let filters = [];
    let baseFilter = {};
    const reportDefinitionInfo = state.cards.filter((x) => x.id === ids[0])[0];

    if (reportDefinitionInfo.startDate || reportDefinitionInfo.endDate) {
      if (reportDefinitionInfo.startDate) {
        baseFilter.from = reportDefinitionInfo.startDate;
      }
      if (reportDefinitionInfo.endDate) {
        baseFilter.to = reportDefinitionInfo.endDate;
      }
    }

    if (
      reportDefinitionInfo.subjects &&
      reportDefinitionInfo.subjects.length > 0
    ) {
      baseFilter.scope = reportDefinitionInfo.subjects.map((x) => x.id);
    }

    for (var moduleSetting of reportDefinitionInfo.definitionDetails
      .moduleSettings) {
      let filter = {};

      if (moduleSetting.codeElements && moduleSetting.codeElements.length > 0) {
        filter.codeElements = moduleSetting.codeElements;
      }

      const fullFilter = { ...filter, ...baseFilter };

      filters = filters.concat([fullFilter]);
    }

    return arxs.ApiClient.shared.reportDefinition
      .generate({ ...data, tenantId: arxs.Identity.profile.tenant })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            arxs.ApiClient.shared.report.getById(result).then((report) => {
              arxs.ReportClient.reporting().generatePDF({ template: report, filters: filters });
              Toaster.notify(arxs.t("report.requested"));
            });
          });
        }
      });
  };
}
export default new ReportDefinitionActions();
