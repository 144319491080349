/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormValueAttachmentType {
    Comment = 'Comment',
    Media = 'Media',
    Action = 'Action'
}

export function FormValueAttachmentTypeFromJSON(json: any): FormValueAttachmentType {
    return FormValueAttachmentTypeFromJSONTyped(json, false);
}

export function FormValueAttachmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormValueAttachmentType {
    return json as FormValueAttachmentType;
}

export function FormValueAttachmentTypeToJSON(value?: FormValueAttachmentType | null): any {
    return value as any;
}

