import React from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import CardLocation from "components/card/CardLocation";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardImage from "components/card/CardImage";

export default function PeriodicalCard(props: CardClassProps) {
    const { card, cardProps, actionProps } = props;

    const ref = JSON.parse(card.reference || "{}");

    return (
      <>
        <div className="list-card-left">
            <CardImage {...props} />
        </div>
        <div className="list-card-right">
          <h2>
            <div className="list-card-title" title={ref.title}>
              {ref.title}
            </div>
            <CardActions {...actionProps} />
          </h2>
          <CardSubtitle cardProps={cardProps} card={card} />
          <div className="list-card-details">
            <div>
              {[
                arxs.modules.titles[card.referenceModule] || card.referenceModule,
                card.kind,
                card.type,
              ]
                .filter((x) => x)
                .join(" - ")}
            </div>
            <CardLocation card={card} />
            <div>
              {arxs.t("kanban.common.changed")}:{" "}
              {arxs.dateTime.formatDate(card.modifiedAt)}
            </div>
          </div>
        </div>
      </>
    );
}