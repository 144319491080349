import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { RelationshipType } from "infra/api/contracts";

class ProjectBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const branchMap = stateProxy.getter("branchMap");
    const legalStructureMap = stateProxy.getter("legalStructureMap");
    const buildingMap = stateProxy.getter("buildingMap");
    const locationMap = stateProxy.getter("locationMap");
    const legalStructures = card.legalStructures.map(x => this.getLookupValue(legalStructureMap, x));
    const branches = card.branches.map(x => this.getLookupValue(branchMap, x));
    const buildings = card.branches.map(x => this.getLookupValue(buildingMap, x));
    const locations = card.branches.map(x => this.getLookupValue(locationMap, x));

    return {
      ...card,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      legalStructureNames: legalStructures.filter(x => x !== "").join(", "),
      branchNames: branches.filter(x => x !== "").join(", "),
      buildingNames: buildings.filter(x => x !== "").join(", "),
      locationNames: locations.filter(x => x !== "").join(", "),
      template: card.template && JSON.parse(card.template),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.kind,
      card.type,
      card.uniqueNumber,
      card.title,
      card.description,
      card.responsible,
      card.coResponsibles,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.projects) {
        lookups.pristine = lookups.projects
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.documents;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new ProjectBoard();
