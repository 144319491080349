import React, { Component, Fragment } from 'react';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import arxs from 'infra/arxs';
import CardDataSource from 'infra/CardDataSource';
import GlobalContext from 'infra/GlobalContext';
import { EmployeeStatus, ObjectDocumentType, OriginModuleEnum, RecommendationStatus } from 'infra/api/contracts';

import ModuleSelector from "components/controls/ModuleSelector";
import { Card } from 'components/card/Card';
import TreeView from 'components/controls/treeview/TreeView';
import DropDown from 'components/controls/DropDown';
import Button from 'components/controls/Button';
import SortKindType from 'components/controls/codeElements/SortKindType';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import StructureFilter from 'components/controls/structure/StructureFilter';

import './CardLookup.scss';

export const createCardLookup = (props) => {
  const context = props.context || [];
  const defaultModules = arxs.moduleMetadataRegistry.supportedModules.except(props.excludeModules || []);
  props.modules = (props.modules || defaultModules).filter(arxs.moduleMetadataRegistry.isModuleAllowed);
  const state = {
    title: props.title ? props.title : (context.length === 0 ? arxs.t("card_lookup.title") : arxs.t("card_lookup.title_with_context", { context: context.map(x => x.name).join(", ") })),
    content: <CardLookup {...props} />
  };
  return state;
};

const _viewModeTypes = { tree: "tree", list: "list" };

class CardLookup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: this.props.searchTerm || "",
      cardWidth: 310,
      moduleSelected: [],
      moduleSelectedMap: {},
      sortSelectedMap: {},
      kindSelectedMap: {},
      typeSelectedMap: {},
      legalStructureSelectedMap: {},
      branchSelectedMap: {},
      buildingSelectedMap: {},
      locationSelectedMap: {},
      pristine: [],
      items: [],
      selected: {},
      ...this.lookups,
      filteredCards: [],
      uniqueNodeIds: {},
      //selectedDocumentType: {},
      documentTypesForModule: [],
      moduleMetaDataMap: {}
    };

    const { modules } = this.props;
    if (modules) {
      let criteria = {
        "searchTerm": () => this.state.searchTerm,
        "modules": () => this.state.moduleSelectedMap,
        "sort": () => this.state.sortSelectedMap,
        "kind": () => this.state.kindSelectedMap,
        "type": () => this.state.typeSelectedMap,
        "legalStructure": () => this.state.legalStructureSelectedMap,
        "branch": () => this.state.branchSelectedMap,
        "building": () => this.state.buildingSelectedMap,
        "location": () => this.state.locationSelectedMap
      };

      if (this.props.prefilter) {
        for (const key of Object.keys(this.props.prefilter)) {
          criteria = {
            ...criteria,
            [key]: () => this.props.prefilter[key]
          }
        }
      }

      this.state.modules = modules;
      this.state.module = {
        selected: [],
        items: modules.map(x => ({ icon: arxs.modules.icons[x], name: arxs.modules.titles[x], key: arxs.modules.keys[x], id: arxs.modules.keys[x] }))
      };
      this.state.moduleSelectedMap = modules.toDictionary(x => x, x => x);
      this.state.dataSource = new CardDataSource(criteria);

      this.state.viewMode = (modules.length === 1 && this.props.viewMode) || _viewModeTypes.list;
    }
    this.timeout = 0;
  }

  componentDidMount() {
    this.state.dataSource.setRefresh(this.refresh);
  }

  componentWillUnmount() {
    if (this.state.dataSource) {
      this.state.dataSource.dispose();
    }
  }

  refresh = () => {
    const securityFilter = this.props.securityContext ? this.props.securityContext.filter : () => true;

    const baseFilter = (x) =>
      securityFilter(x)
      && !x.isDeleted
      && !(x.module === OriginModuleEnum.Employee && (x.status === EmployeeStatus.OutOfService || x.status === EmployeeStatus.Sick))
      && !(x.module === OriginModuleEnum.Consultancy && x.status !== RecommendationStatus.Active);

    const filter = this.props.filterPredicate ? (x => this.props.filterPredicate(x) && baseFilter(x)) : baseFilter;

    let items = (this.props.items ? this.props.items.orderByDescending(card => card.createdAt) : this.state.dataSource.get())
      .filter(filter);

    if (this.state.viewMode === _viewModeTypes.tree && (this.props.modules && this.props.modules.length === 1)) {
      const sortIds = items
        .map(card => card.sortId).filter(x => x !== undefined);
      const kindIds = items.map(card => card.kindId).filter(x => x !== undefined);
      const typeIds = items.map(card => card.typeId).filter(x => x !== undefined);

      let uniqueNodeIds = [];
      uniqueNodeIds.push(...sortIds);
      uniqueNodeIds.push(...kindIds);
      uniqueNodeIds.push(...typeIds);

      

      let documentTypesForModule;
      let selectedItem;

      if (this.props.sourceModule){
        const metadata = arxs.moduleMetadataRegistry.get(this.props.sourceModule);

        documentTypesForModule = metadata.allowedDocumentTypes.filter(x => x !== ObjectDocumentType.Image && x !== ObjectDocumentType.MainDocument).map((x) => ({ id: x, name: arxs.documentTypes.titles[x.toLowerCase()] }));
        selectedItem = this.state.selectedDocumentType 
          ? this.state.selectedDocumentType : documentTypesForModule.some(x => x.id === ObjectDocumentType.AdditionalDocument) 
            ? documentTypesForModule.filter(x => x.id === ObjectDocumentType.AdditionalDocument)[0] : documentTypesForModule[0];
      }
      
      let filteredCards = items;

      if (this.state.selectedTreeViewItem) {
        const item = this.state.selectedTreeViewItem

        switch (item.type) {
          case arxs.codeElementTypes.sort:
            filteredCards = items.filter(x => x.sortId === item.id);
            break;
          case arxs.codeElementTypes.kind:
            filteredCards = items.filter(x => x.kindId === item.id);
            break;
          case arxs.codeElementTypes.type:
            filteredCards = items.filter(x => x.typeId === item.id);
            break;
          default: return;
        }
      }

      this.setState({ items, filteredCards: filteredCards, uniqueNodeIds: uniqueNodeIds.toDictionary(x => x), documentTypesForModule: documentTypesForModule, selectedDocumentType: selectedItem });
    } else {
      this.setState({ items });
    }
  }

  treeFilter = (nodeId) => {
    return this.state.uniqueNodeIds && Object.keys(this.state.uniqueNodeIds).some(x => x === nodeId);
  }

  handleChangeSearchTerm = (event) => {
    this.setState({ searchTerm: event.target.value });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = (setTimeout(() => { this.refresh() }, 50))
  }

  handleCardToggle = (e, card) => {
    const { singleSelection } = this.props;
    let selected = this.state.selected;
    if (selected[card.id]) {
      delete selected[card.id];
    } else {
      if (singleSelection) {
        selected = [];
      }
      selected[card.id] = true;
    }
    this.setSelectedCards(selected);
  }

  handleCardSelect = (item) => {
    if (item && item.type === "Item") {
      const { singleSelection } = this.props;
      let selected = this.state.selected;
      if (selected[item.id]) {
        delete selected[item.id];
      } else {
        if (singleSelection) {
          selected = [];
        }
        selected[item.id] = true;
      }
      this.setSelectedCards(selected);
    }
  }

  setSelectedCards = (selected) => {
    const items = this.state.items;
    for (let card of items) {
      card.checked = !!selected[card.id];
    }
    this.setState({ items, selected }, this.refresh);
  }

  handleChangeSortSelected = (selected) => {
    this.setState({
      sortSelectedMap: selected.toDictionary(x => x.name, x => x.id),
      kindSelectedMap: {},
      typeSelectedMap: {}
    }, this.refresh);
  }

  handleChangeKindSelected = (selected) => {
    this.setState({
      kindSelectedMap: selected.toDictionary(x => x.name, x => x.id),
      typeSelectedMap: {}
    }, this.refresh);
  }
  handleChangeTypeSelected = (selected) => {
    this.setState({
      typeSelectedMap: selected.toDictionary(x => x.name, x => x.id),
    }, this.refresh);
  }

  handleChangeModuleSelected = (selected) => {
    const { modules } = this.state;
    this.setState({
      moduleSelected: selected,
      moduleSelectedMap: selected.length !== 0 ? selected.toDictionary(x => x.key, x => x.key) : modules.toDictionary(x => x, x => x),
      sortSelectedMap: {},
      kindSelectedMap: {},
      typeSelectedMap: {}
    }, this.refresh);
  }

  handleChangeLegalStructureSelected = (selected) => {
    this.setState({
      legalStructureSelectedMap: selected.toDictionary(x => x.id, x => x.name),
      branchSelectedMap: {},
      buildingSelectedMap: {},
      locationSelectedMap: {}
    }, this.refresh);
  }

  handleChangeBranchSelected = (selected) => {
    this.setState({
      branchSelectedMap: selected.toDictionary(x => x.id, x => x.name),
      buildingSelectedMap: {},
      locationSelectedMap: {}
    }, this.refresh);
  }

  handleChangeBuildingSelected = (selected) => {
    this.setState({
      buildingSelectedMap: selected.toDictionary(x => x.id, x => x.name),
      locationSelectedMap: {}
    }, this.refresh);
  }

  handleChangeLocationSelected = (selected) => {
    this.setState({
      locationSelectedMap: selected.toDictionary(x => x.id, x => x.name),
    }, this.refresh);
  }

  applyFilter = (context) => {
    const threshold = 100;
    if (this.props.onApplyFilter) {
      const matchingItems = this.state.items;
      let result = matchingItems.filter(x => this.state.selected[x.id]).toDictionary(x => x.id, x => x.module);
      const emptyFilter = Object.keys(result).length === 0;

      if (emptyFilter) {
        if (this.props.disallowEmptySelection) {
          return;
        }
        result = matchingItems.toDictionary(x => x.id, x => x.module);
      }

      const resultLength = Object.keys(result).length;

      if (resultLength <= threshold) {
        this.props.onApplyFilter(result, this.state.selectedDocumentType);
      } else {
        const confirmation = createInputPopup(context, arxs.t("card_lookup.confirmation", { length: `${resultLength}` }), () => this.props.onApplyFilter(result, this.state.selectedDocumentType));
        context.inputPopup.show(confirmation);
      }
    }
  };

  getAllowedModules = () => {
    const allowedModulesForAddNew = [];

    for (const key of Object.keys(arxs.Identity.profile.allowedActions)) {
      if (key.indexOf("Write") > -1 && key.indexOf("Settings.Write") === -1 && arxs.Identity.profile.allowedActions[key] === true) {
        const moduleInfoFromModules = this.state.module.items.filter(x => x.id === key.substr(0, key.indexOf(".")))[0]
        if (moduleInfoFromModules) {
          allowedModulesForAddNew.push(moduleInfoFromModules.id);
        }
      }
    }

    return allowedModulesForAddNew.distinct(x => x);
  }

  isAddAllowed = () => {
    const allowedModulesForAddNew = this.getAllowedModules();

    if (allowedModulesForAddNew.length > 0 && !this.props.disableAddition) {
      return true;
    }

    return false;
  }

  handleAddNewCard = (context) => {
    const options = [];
    const allowedModulesForAddNew = this.getAllowedModules();

    for (const module of allowedModulesForAddNew) {
      const metadata = arxs.moduleMetadataRegistry.get(module);
      if (metadata && metadata.base.route) {
        options.push({ title: arxs.modules.titles[module], handle: () => window.open(`${metadata.base.route}/create?ac=1`, '_blank') });
      }
    }

    context.optionPopup.show(arxs.t("kanban.common.new"), options);
  }

  onTreeItemClick = (item, children) => {
    if (item) {
      this.setState({ selectedTreeViewItem: item, selectedTreeViewItemChildren: children });

      const { items } = this.state;

      if (item.type && items) {
        let filteredCards = [];

        switch (item.type) {
          case arxs.codeElementTypes.sort:
            filteredCards = items.filter(x => x.sortId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          case arxs.codeElementTypes.kind:
            filteredCards = items.filter(x => x.kindId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          case arxs.codeElementTypes.type:
            filteredCards = items.filter(x => x.typeId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          default: return;
        }
      }
    }
  }


  _moduleOrderMap = [
    OriginModuleEnum.SchoolGroup,
    OriginModuleEnum.School,
    OriginModuleEnum.Building,
    OriginModuleEnum.Room,
    OriginModuleEnum.EquipmentInstallation,
    OriginModuleEnum.Labourmeans,
    OriginModuleEnum.Pbm,
    OriginModuleEnum.HazardousSubstance,
    OriginModuleEnum.IntangibleAsset,
    OriginModuleEnum.CombinedInstallation,
    OriginModuleEnum.Document,
    OriginModuleEnum.Commissioning,
    OriginModuleEnum.OutOfCommissioning,
    OriginModuleEnum.InstructionCard,
    OriginModuleEnum.SafetyInstructionCard,
    OriginModuleEnum.Consultancy,
    OriginModuleEnum.Task,
    OriginModuleEnum.NotificationDefect,
    OriginModuleEnum.ActivityEntry,
    OriginModuleEnum.Project,
    OriginModuleEnum.PeriodicMaintenance,
    OriginModuleEnum.PeriodicControl,
    OriginModuleEnum.RiskAnalysis,
    OriginModuleEnum.IncidentManagement,
    OriginModuleEnum.Form,
    OriginModuleEnum.Training,
    OriginModuleEnum.Employee,
    OriginModuleEnum.Supplier,
    OriginModuleEnum.Contact,
  ].toDictionary(x => x, (_, i) => (i || 0) + 1);

  orderByModule = (card) => {
    return this._moduleOrderMap[card.module] || 99;
  }

  render() {
    const { selectedCards, applyFilterText, disallowEmptySelection } = this.props;
    let results = this.props.viewMode === _viewModeTypes.tree ? this.state.filteredCards : this.state.items;
    results = results
      //.orderBy(card => card.uniqueNumber)
      .orderBy(this.orderByModule);

    const selectedKeys = results.filter(x => this.state.selected[x.id]).map(x => x.id);
    const modulesInScope = Object.keys(this.state.moduleSelectedMap).length > 0 ? this.state.moduleSelectedMap : this.state.module.items.toDictionary(x => x.key, x => x.key);

    const singleSelection = () => {
      if (this.props['singleSelection'] && this.props.singleSelection === true) {
        return `(Max 1)`;
      }
    }

    const securityContext = this.props.securityContext || arxs.securityContext.buildForUserContext();

    const renderSelectionActionBar = (context) => {
      return <div className="card-lookup-cardlist-info">
        {renderDocumentTypeSelection(context)}
        {selectedCards.map((card, i) => <div className="card-lookup-card-uniquenumber" key={`card-${i}`}>{card.uniqueNumber}</div>)}
      </div>;
    }

    const renderFilter = (context) => {
      const { prefilter } = this.props;
      let showLocationFilter = true;

      if (prefilter && ["legalStructure", "branch", "building", "location"].intersect(Object.keys(prefilter)).some(x => x)) {
        showLocationFilter = false;
      }

      return <Fragment>
        {this.state.viewMode === _viewModeTypes.list && showLocationFilter && <StructureFilter
          modules={modulesInScope}
          onLegalStructureChange={this.handleChangeLegalStructureSelected}
          onBranchChange={this.handleChangeBranchSelected}
          onBuildingChange={this.handleChangeBuildingSelected}
          onLocationChange={this.handleChangeLocationSelected}
          securityContext={securityContext} />}
        {this.state.module
          && Object.keys(this.state.module.items).length > 1
          && <ModuleSelector className="section"
            selected={this.state.moduleSelected.map(x => x.key)}
            modules={this.state.modules}
            onChange={this.handleChangeModuleSelected}
          />}
        {this.state.viewMode === _viewModeTypes.list && <SortKindType
          modules={modulesInScope}
          onTypeChange={this.handleChangeTypeSelected}
          onKindChange={this.handleChangeKindSelected}
          onSortChange={this.handleChangeSortSelected}
          securityContext={securityContext}
        />}
      </Fragment>
    }

    const renderDocumentTypeSelection = (context) => {
      const { documentTypesForModule, selectedDocumentType } = this.state;

      if (documentTypesForModule && documentTypesForModule.length > 0) {
        if (documentTypesForModule.length >= 1) {
          return <div className="card-lookup-documenttypes">
            <DropDown
              items={documentTypesForModule}
              selected={selectedDocumentType}
              onChange={(value) => this.setState({ selectedDocumentType: value })} />
            <div className="card-lookup-documenttypes-addlabel"> toevoegen aan </div>
          </div>
        }
      }
      return <Fragment></Fragment>;
    }

    const renderList = (context) => {
      return <AutoSizer>
        {({ width, height }) => {
          const slices = results.partition(Math.max(1, Math.floor(width / this.state.cardWidth)));
          return <List
            width={width}
            height={height}
            rowCount={slices.length}
            rowHeight={165}
            rowRenderer={({ index, isScrolling, key, style }) => {
              return <div key={key} style={style}>
                <div className="card-row">
                  {slices[index].map((card, j) =>
                    <Card key={`card-lookup-${index}-${j}`} data={card}
                      onToggle={this.handleCardToggle}
                      style={{ height: 146 }}
                      checked={this.state.selected[card.id]}
                    />)}
                </div>
              </div>;
            }}
          />;
        }}
      </AutoSizer>
    }

    const renderTree = (context) => {
      return <Fragment>
        <div className={`tree-nav-container ${context.platform.isMobile && "mobile"}`}>
          <div className="tree-nav">
            <TreeView
              module={this.state.modules[0]}
              itemIcon="fad fa-folder"
              securityContext={securityContext}
              onItemClick={context.platform.isMobile ? this.handleCardSelect : this.onTreeItemClick}
              hideSearch={true}
              filterTree={!context.platform.isMobile && this.treeFilter}
              includeItems={context.platform.isMobile ? true : this.props.includeItems}
              items={context.platform.isMobile && results.map((card, j) => ({
                ...card, render: () =>
                  <Card key={`card-lookup-${j}`} data={card}
                    //onToggle={!context.platform.isMobile && this.handleCardToggle}
                    style={{ height: 146 }}
                    selected={this.state.selected[card.id]}
                  />
              }))}
            />
          </div>
        </div>
        {!context.platform.isMobile && <div className="tree-card-content">
          {renderList(context)}
        </div>}
      </Fragment>
    }

    const renderContent = (context) => {
      switch (this.state.viewMode) {
        case _viewModeTypes.tree: return renderTree(context);
        case _viewModeTypes.list: return renderList(context);
        default: return renderList(context);
      }
    }

    const applyFilterClassName = [
      "card-lookup-selected",
      (disallowEmptySelection && selectedKeys.length === 0) && "disabled"
    ].filter(x => x).join(" ");

    return <GlobalContext.Consumer>
      {(context) => <Fragment><div className="card-lookup">
        {selectedCards && renderSelectionActionBar(context)}
        <div className={`card-lookup-toolbar ${context.platform.isMobile && "mobile"}`}>
          <div className="input-wrapper far fa-search">
            <input autoFocus type="text" value={this.state.searchTerm} onChange={this.handleChangeSearchTerm}
              placeholder={arxs.t("card_lookup.search_placeholder")} />
          </div>
          {!context.platform.isMobile && renderFilter(context)}
        </div>
        <div className="card-lookup-content-wrapper">
          {renderContent(context)}
        </div>
        <div className={applyFilterClassName} onClick={() => this.applyFilter(context)}>
          {applyFilterText || (
            selectedKeys.length > 0
              ? <Fragment>{arxs.t("card_lookup.apply_selection")} ({selectedKeys.length}) <i className="fa fa-filter"></i>{singleSelection()}</Fragment>
              : <Fragment>{arxs.t("card_lookup.apply_filter")} ({results.length}) <i className="fa fa-filter"></i>{singleSelection()}</Fragment>
          )}
        </div>

      </div >
        {this.isAddAllowed() && <div className="card-lookup-buttons">
          <Button className="card-lookup-button card-lookup-addnew icon" onClick={() => this.handleAddNewCard(context)} title={arxs.t("card_lookup.add_new")}>
            <i className="fas fa-plus"></i>
          </Button>
        </div>}
      </Fragment>
      }
    </GlobalContext.Consumer>;
  }
}
export default CardLookup;
