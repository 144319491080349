import React, { Component, Fragment } from 'react';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import CodeElementMultiSelect from 'components/controls/codeElements/CodeElementMultiSelect';

import './SortKindType.scss';

class SortKindType extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sortSelected: [],
            kindSelected: [],
            typeSelected: [],
            sortSelectedMap: {},
            kindSelectedMap: {},
            typeSelectedMap: {},
            hierarchyType: '',
            dropdownVisible: false,
            hasCodeElements: false,
        };
    }

    componentDidMount() {
        this.refresh(this.props.modules);
    }

    refresh = (modules) => {
        const modulesArray = Object.keys(modules || {});
        if (modulesArray.length > 0) {
            arxs.ApiClient.masterdata.codeElements.getMetaDataByModules(modulesArray)
                .then(codeElementMetaData => {
                    const hasSorts = Object.values(codeElementMetaData)
                        .any(x => modulesArray.contains(x.module) && x.hierarchyType === arxs.hierarchyTypes.sortAndKindAndType && x.name === arxs.codeElementTypes.sort);
                    const hasKinds = Object.values(codeElementMetaData)
                        .any(x => modulesArray.contains(x.module) && x.hierarchyType === arxs.hierarchyTypes.kindAndType && x.name === arxs.codeElementTypes.kind);

                    const hasCodeElements = hasSorts || hasKinds;

                    this.setState({ hierarchyType: hasSorts ? arxs.hierarchyTypes.sortAndKindAndType : hasKinds ? arxs.hierarchyTypes.kindAndType : '', hasCodeElements: hasCodeElements });
                });

            this.setState({ modules: modules });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const hasModulesChanged = !Object.areKeysEqual(this.props.modules, prevProps.modules);
        if (hasModulesChanged) {
            this.refresh(this.props.modules);
        }
    }

    handleChangeSortSelected = (selected) => {
        this.setState({
            sortSelected: selected,
            sortSelectedMap: selected.toDictionary(x => x.name, x => x.id),
            kindSelectedMap: {},
            typeSelectedMap: {}
        });
        this.props.onSortChange(selected);
    }

    handleChangeKindSelected = (selected) => {
        this.setState({
            kindSelected: selected,
            kindSelectedMap: selected.toDictionary(x => x.name, x => x.id),
            typeSelectedMap: {}
        });
        this.props.onKindChange(selected);
    }

    handleChangeTypeSelected = (selected) => {
        this.setState({
            typeSelected: selected,
            typeSelectedMap: selected.toDictionary(x => x.name, x => x.id),
        });
        this.props.onTypeChange(selected);
    }

    toggleDropdown = () => {
        const { dropdownVisible } = this.state;
        this.setState({ dropdownVisible: !dropdownVisible })
    }

    render() {
        const { sortSelected, kindSelected, typeSelected, hierarchyType, hasCodeElements } = this.state;
        const { dropdownVisible } = this.state;

        const hasSort = hierarchyType === arxs.hierarchyTypes.sortAndKindAndType;
        const hasKind = hasSort || hierarchyType === arxs.hierarchyTypes.kindAndType;

        const sortDropdown = (context) => {
            return <CodeElementMultiSelect
                id="sortLookup"
                key="sortLookup"
                className="section"
                modules={this.props.modules}
                codeElementType={arxs.codeElementTypes.sort}
                selected={sortSelected}
                onChange={this.handleChangeSortSelected}
                securityContext={this.props.securityContext}
            />
        }

        const kindDropdown = (context) => {
            return <CodeElementMultiSelect
                id="kindLookup"
                key="kindLookup"
                className="section"
                modules={this.props.modules}
                codeElementType={arxs.codeElementTypes.kind}
                selectedParents={sortSelected}
                selected={kindSelected}
                onChange={this.handleChangeKindSelected}
                securityContext={this.props.securityContext}
            />
        }

        const typeDropdown = (context) => {
            return <CodeElementMultiSelect
                id="typeLookup"
                key="typeLookup"
                className="section"
                modules={this.props.modules}
                codeElementType={arxs.codeElementTypes.type}
                selectedParents={kindSelected}
                selected={typeSelected}
                onChange={this.handleChangeTypeSelected}
                securityContext={this.props.securityContext}
            />
        }

        const hasFilter = (sortSelected || []).length > 0
            || (kindSelected || []).length > 0
            || (typeSelected || []).length > 0;

        return (
            <GlobalContext.Consumer>
                {(context) =>{if (hasCodeElements) {return <Fragment>
                        <div className="sortkindtype-filter">
                            <div className="sortkindtype-filter-header" onClick={this.toggleDropdown}>
                                {context.platform.isMobile ? <i className='fal fa-share-alt'></i> : arxs.t("card_lookup.category")} {hasFilter && <i className="far fa-filter"></i>}
                            </div>
                            {dropdownVisible && <div className="sortkindtype-filter-dropdown">
                                {hasSort && sortDropdown(context)}
                                {hasKind && kindDropdown(context)}
                                {hasKind && typeDropdown(context)}
                            </div>}
                        </div>
                    </Fragment>} else {
                        return <Fragment></Fragment>
                    }}
                }
            </GlobalContext.Consumer>
        );
    }
}
export default SortKindType;