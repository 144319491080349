import React, { Fragment } from 'react';
import { useDrop } from 'react-dnd';
import Collapsible from 'react-collapsible';

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { SecurityContext } from 'infra/SecurityContext';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';

import ChecklistItem from './ChecklistItem';
import Badge from 'components/controls/Badge';

import { ChecklistModel } from './Contracts';
import { ChecklistItemModel } from './Contracts';

import './Checklist.scss';

export interface ChecklistProps {
  readOnly?: boolean,
  editable?: boolean,
  value: ChecklistModel,
  onChange(value?: ChecklistModel): void,
  onDrop(target: ChecklistItemModel, dragged: ChecklistItemModel): void,
  className?: string,
  securityContext: SecurityContext,
}

interface ChecklistState {
}

export default function Checklist(props: ChecklistProps) {
  const [, drop] = useDrop({ accept: 'ChecklistItem' });

  const handleAdd = (context: any) => {
    const value = props.value;

    const newValue = {
      ...value,
      items: (value.items || []).concat({ id: arxs.uuid.generate(), title: "", autoFocus: true })
    };

    props.onChange(newValue);
  };

  const handleEdit = (originalItem: ChecklistItemModel, modifiedItem?: ChecklistItemModel) => {
    const value = props.value;

    const newValue = {
      ...value,
      items: modifiedItem
        ? (value.items || []).replace(originalItem, modifiedItem)
        : value.items.filter(x => x.id !== originalItem.id)
    };

    props.onChange && props.onChange(newValue);
  };

  const handleEditTitle = (event: any, context: any) => {
    event.stopPropagation();

    const editPrompt = createInputPopup(context
      , arxs.t("common.rename")
      , (newTitle: string) => {
        props.onChange({ ...props.value, title: newTitle });
      }
      , true
      , true
      , ""
      , arxs.t("common.save")
      , arxs.t("common.cancel")
      , false
      , props.value.title
    );

    context.inputPopup.show(editPrompt);
  };

  const handleDelete = (event: any, context: any) => {
    event.stopPropagation();

    const confirmation = createInputPopup(context
      , arxs.t("controls.checklist.delete_confirmation")
      , props.onChange
    );

    context.inputPopup.show(confirmation);
  };

  const { readOnly, editable, className, securityContext } = props;
  const value = props.value;

  const completeChecklistItems = value.items.filter(x => x.done).length;
  const totalChecklistItems = value.items.length;

  return <GlobalContext.Consumer>
    {
      context => <div ref={drop} className={`checklist ${className || ''}`}>
        {value && <Collapsible key={value.title} trigger={<Fragment>
          {value.title}<Badge>{`${completeChecklistItems}/${totalChecklistItems}`}</Badge>
          {!readOnly && <div className="checklist-header-actions">
            <div onClick={(e) => handleEditTitle(e, context)}>
              <i className="fas fa-pencil"></i>
            </div>
            <div onClick={(e) => handleDelete(e, context)}>
              <i className="far fa-trash-alt"></i>
            </div>
          </div>}
        </Fragment>} open={true}>
          <div className="checklist-content">
            {(value.items || []).map((item: ChecklistItemModel) => <ChecklistItem
              key={item.id}
              editable={editable}
              readOnly={readOnly}
              value={item}
              onChange={(modified) => handleEdit(item, modified)}
              onAdd={() => handleAdd(context)}
              onDrop={props.onDrop}
              securityContext={securityContext}
            />)}
            {!(readOnly || (editable === false)) && <div className="checklist-action-add" onClick={() => handleAdd(context)}>
              {arxs.t("checklist.add_checklist_item")} <i className="fas fa-plus"></i>
            </div>}
          </div>
        </Collapsible>}
      </div>
    }
  </GlobalContext.Consumer>;
}