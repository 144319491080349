import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import GlobalActions from "modules/GlobalActions";

class ProjectActions {
  getActions = (module) => {
    return GlobalActions.getActions(module).concat([
      {
        name: "complete",
        module: module,
        icon: arxs.actions.icons["complete"],
        getTitle: () => arxs.actions.titles["complete"],
        onClick: (state) => this.complete(state),
      },
      {
        name: "hold",
        module: module,
        icon: arxs.actions.icons["hold"],
        getTitle: () => arxs.actions.titles["hold"],
        onClick: (state) => this.hold(state),
      },
      {
        name: "unhold",
        module: module,
        icon: arxs.actions.icons["unhold"],
        getTitle: () => arxs.actions.titles["unhold"],
        onClick: (state) => this.unhold(state),
      },
      {
        name: "activate",
        module: module,
        icon: arxs.actions.icons["accept"],
        getTitle: () => arxs.actions.titles["activate"],
        onClick: (state) => this.activate(state),
      },
      {
        name: "gantt-edit",
        module: module,
        icon: arxs.actions.icons["gantt"],
        singleSelectionOnly: true,
        getTitle: () => arxs.actions.titles["gantt"],
        onClick: (state) => this.editGantt(state),
      },
    ]);
  };

  editGantt = (state) => {
    const card = state.cards[0];
    if (card) {
      const route = `/project/gantt/${card.id}`;
      state.history.push({ pathname: route });
    }
  };

  complete = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.project
        .complete(data)
        .then(() =>
          Toaster.success(arxs.t("actions.project.completed_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.project.complete_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };

  activate = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.project
        .activate(data)
        .then(() =>
          Toaster.success(arxs.t("actions.project.activate_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.project.activate_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };

  hold = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.project
        .hold(data)
        .then(() =>
          Toaster.success(arxs.t("actions.project.hold_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.project.hold_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };

  unhold = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.project
        .unhold(data)
        .then(() =>
          Toaster.success(arxs.t("actions.project.unhold_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.project.unhold_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };

  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        switch (state.item.status) {
          case "OnHold":
            this.setInProcess(state);
            break;
          default:
            return;
        }
      },
      onDropInOnHold: (state) => {
        switch (state.item.status) {
          case "InProcess":
          case "Active":
            this.hold(state);
            break;
          default:
            return;
        }
      },
      onDropInActive: (state) => {
        const context = state.context;
        const history = state.history;

        switch (state.item.status) {
          case "InProcess":
            this.activate(state);
            break;
          case "OnHold":
            const executeAction = (motivation) => {
              history.push({
                pathname: `/project/edit/${state.item.objectId}`,
                state: { status: "Active", motivation: motivation },
              });
            };
            const confirmation = createInputPopup(
              context,
              arxs.t("actions.project.activate_confirmation_question"),
              (motivation) => executeAction(motivation),
              true,
              true,
              arxs.t("common.motivation")
            );
            context.inputPopup.show(confirmation);
            break;
          default:
            return;
        }
      },
      onDropInCompleted: (state) => {
        const context = state.context;
        const history = state.history;

        switch (state.item.status) {
          case "OnHold":
            const executeAction = (motivation) => {
              history.push({
                pathname: `/project/edit/${state.item.objectId}`,
                state: { status: "Completed", motivation: motivation },
              });
            };
            const confirmation = createInputPopup(
              context,
              arxs.t("actions.project.complete_confirmation_question"),
              (motivation) => executeAction(motivation),
              true,
              true,
              arxs.t("common.motivation")
            );
            context.inputPopup.show(confirmation);
            break;
          case "Active":
            this.complete(state);
            break;
          default:
            return;
        }
      },
    };
  };
}
export default new ProjectActions();
