import React from 'react';

import arxs from 'infra/arxs';
import Badge from 'components/controls/Badge';

import './NotificationTile.scss';

export default function NotificationTile(props) {
  const navigateToFilter = (item) => {
    const data = props.data;
    if (data && data.module) {
      let module = data.module;
      if (module === "NotificationDefect") {
        module = "Task";
      }
      const metadata = arxs.moduleMetadataRegistry.get(module);
      if (metadata) {
        props.history.push(`${metadata.base.route}/${item.searchTerm}`);
      }
    }
  };

  const tile = props.data;
  return (<div className="notification-tile pointer" key={`{tile.key}-data`}>
    <h2>
      <div>{tile.moduleTitle}</div>
      <i className="far fa-external-link"></i>
    </h2>
    <div>
      <h1>{tile.count}</h1>
      <i className={`far ${tile.moduleIcon}`}></i>
    </div>
    <div className="tile-detail">
      {tile.items
        .map(item => {
          const { items, status } = item;
          const className = `tile-detail-item-bullet ${arxs.getStatusClassName(props.data.module, status)}`;
          const title = item.title || arxs.statuses.titles[status];
          return <div key={status} className="tile-detail-item" onClick={() => navigateToFilter(item)}>
            <div className={className}>•</div>
            <div className="tile-detail-title">{title}</div>
            <Badge>{items.length}</Badge>
          </div>;
        })}
    </div>
  </div>);
}
