import arxs from "infra/arxs";
import {
  OriginModuleEnum,
  Employee,
  DocumentManagementDocument,
  Project,
  Form,
  Relationship,
  Tag,
  AddressInfo,
  EmailInfo,
  PhoneInfo,
  Recommendation,
} from "./api/contracts";
import buildSearchTermFilter from "infra/SearchTermFilter.js";

interface MatchableCard {
  module: any;
  uniqueNumber: any;
  name: any;
  title: any;
  sort: any;
  kind: any;
  type: any;
  description: any;
  brand: any;
  model: any;
  internalNumber: any;
  supplier: any;
  supplierId: any;
  suppliers: Array<any>;
  supplierIds: Array<any>;
  legalStructure: any;
  legalStructureId: any;
  branch: any;
  branchId: any;
  building: any;
  buildingId: any;
  location: any;
  locationId: any;
  geoLocation: any;
  firstname: any;
  surname: any;
  tags: any;
  functions: any;
  phones: any;
  addresses: any;
  emails: any;
  targetModule: any;
  requester: any;
  addressSearchString?: string;
  phoneSearchString?: string;
  emailSearchString?: string;
  functionSearchString?: string;
  tagSearchString?: string;
  relationshipSearchString?: string;
  abbreviation?: string;
  internalName?: string;
  subjectString?: string;
}

export default class CardDataSource {
  criteria: { [index: string]: any } = {};
  lookups: { [index: string]: any } = {
    objectsByModule: {},
    codeElementsById: {},
    legalStructureMap: {},
    branchMap: {},
    buildingMap: {},
    locationMap: {},
    labourmeanMap: {},
    protectionEquipmentMap: {},
    hazardousSubstanceMap: {},
    equipmentMap: {},
    combinedInstallationMap: {},
    incidentMap: {},
    taskRequestMap: {},
    tasks: [],
    taskMap: {},
    maintenances: [],
    maintenanceMap: {},
    inspections: [],
    inspectionMap: {},
    riskAnalysises: [],
    riskAnalysisMap: {},
    multiYearPlans: [],
    multiYearPlanMap: {},
    periodicals: {},
    periodicalMap: {},
    supplierMap: {},
    employeeMap: {},
    userRoles: [],
    userRoleMap: {},
    contactMap: {},
    documentMap: {},
    projectMap: {},
    activityEntryMap: {},
    intangibleAssetMap: {},
    formMap: {},
    recommendationMap: {},
    tagMap: {},
    reportDefinitionMap: {},
  };

  id: number;
  subscriptions: { lookups: { dispose: () => void } };
  onRefresh: any;
  timeout: any;
  objectsByModuleResult: { [module: string]: Array<any> } = {};

  constructor(criteria: {
    searchTerm?: (() => any) | (() => any);
    modules?: (() => any) | (() => any);
    sort?: (() => any) | (() => any);
    kind?: (() => any) | (() => any);
    type?: (() => any) | (() => any);
    tags?: (() => any) | (() => any);
    ids?: (() => any) | (() => any);
  }) {
    this.id = arxs.getIncrementalIndex();

    this.criteria = criteria;
    this.timeout = null;

    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups: any) => {
        this.lookups = Object.assign({}, this.lookups, lookups);
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.refresh();
        }, 250);
      }),
    };
  }

  refresh = () => {
    for (const key of Object.keys(this.lookups.objectsByModule || {})) {
      this.objectsByModuleResult[key] = (
        this.lookups.objectsByModule[key] || []
      ).map(
        (card: {
          sort: { id: any };
          kind: { id: any };
          type: { id: any };
          legalStructure: { id: any };
          branch: { id: any };
          building: { id: any };
          location: { id: any };
          legalStructures?: Array<{ id: any }>;
          branches?: Array<{ id: any }>;
          supplier: { id: any };
          suppliers: Array<{ id: any }>;
          contacts?: Array<any>;
          assignments?: Array<{ function: { id: any } }>;
          addresses?: Array<AddressInfo>;
          functions?: Array<string>;
          emails?: Array<EmailInfo>;
          phones?: Array<PhoneInfo>;
          relationships?: Array<Relationship>;
          tags?: Array<Tag>;
          subject?: { id: any, module: any };
        }) => {
          const cardExtensions = {
            sortId: card.sort && card.sort.id,
            kindId: card.kind && card.kind.id,
            typeId: card.type && card.type.id,

            sort:
              this.lookups.codeElementsById &&
              this.getLookupValue("codeElementsById", card.sort),
            kind:
              this.lookups.codeElementsById &&
              this.getLookupValue("codeElementsById", card.kind),
            type:
              this.lookups.codeElementsById &&
              this.getLookupValue("codeElementsById", card.type),

            legalStructureId: card.legalStructure && card.legalStructure.id,
            branchId: card.branch && card.branch.id,
            buildingId: card.building && card.building.id,
            locationId: card.location && card.location.id,

            legalStructure:
              this.lookups.legalStructureMap &&
              this.getLookupValue("legalStructureMap", card.legalStructure),
            branch:
              this.lookups.branchMap &&
              this.getLookupValue("branchMap", card.branch),
            building:
              this.lookups.buildingMap &&
              this.getLookupValue("buildingMap", card.building),
            location:
              this.lookups.locationMap &&
              this.getLookupValue("locationMap", card.location),

            legalStructureNames:
              this.lookups.legalStructureMap &&
              card.legalStructures &&
              card.legalStructures
                .map((x: any) => this.getLookupValue("legalStructureMap", x))
                .join(", "),

            branchNames:
              this.lookups.branchMap &&
              card.branches &&
              card.branches
                .map((x: any) => this.getLookupValue("branchMap", x))
                .join(", "),

            supplierId: card.supplier && card.supplier.id,

            supplier:
              this.lookups.supplierMap &&
              this.getLookupValue("supplierMap", card.supplier),

            supplierIds: card.suppliers && card.suppliers.map(x => x.id),

            suppliers:
              card.suppliers &&
              this.lookups.supplierMap &&
              card.suppliers.map(x => this.getLookupValue("supplierMap", x)),

            functions: (card.assignments || []).map(
              (x) =>
                this.lookups.codeElementsById &&
                this.getLookupValue("codeElementsById", x.function)
            ),
            
            functionSearchString: (card.functions || []).join("|"),
            emailSearchString: (card.emails || []).map((x: any) => x.email).join("|"),
            phoneSearchString: (card.phones || []).map((x: any) => x.number).join("|"),

            addressSearchString: (card.addresses || []).map(
              (x) => [x.bus, x.city, x.country, x.number, x.street, x.zipCode].join(", ")
            ).distinct().join("|"),

            tagSearchString: (card.tags || []).map(
              (x) => this.lookups.tagMap && this.getLookupValue("tagMap", x)
            ).distinct().join("|"),

            relationshipSearchString: (card.relationships || []).map(
              (x) => 
                (this.lookups.employeeMap && this.getLookupValue("employeeMap", x.employee))
                || (this.lookups.userRoleMap && this.getLookupValue("userRoleMap", x.userRole))
            ).distinct().join("|"),

            subjectKey: card.subject ? `${card.subject.module}:${card.subject.id}` : null,
          };

          return Object.assign({}, card, cardExtensions);
        }
      );
    }

    if (this.onRefresh) {
      this.onRefresh();
    }
  };

  setRefresh(onRefresh: () => void) {
    this.onRefresh = onRefresh;
  }

  dispose() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  getCardSearchTerms = (card: any) => {
    return [
      card.uniqueNumber,
      card.legalStructure,
      card.legalStructureNames,
      card.branch,
      card.branchNames,
      card.building,
      card.location,
      card.sort,
      card.kind,
      card.type,
      card.tagSearchString,
      card.name,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.title,
      card.description,
      card.brand,
      card.model,
      card.internalNumber,
      card.supplier,
      (card.suppliers || []).join("|"),
      card.firstname,
      card.surname,
      card.functionSearchString,
      card.emailSearchString,
      card.addressSearchString,
      card.relationshipSearchString,
      card.phoneSearchString,
      arxs.modules.titles[card.targetModule],
      (card.requester || {}).name,
      card.abbreviation,
      card.internalName,
      card.taskType && arxs.enums.getTitle("TaskType", card.taskType),
      card.priority && arxs.enums.getTitle("TaskPriority", card.priority),
      card.serialNumber,
    ];
  };

  matchesSearchTerm = (card: MatchableCard) => {
    const searchTerm = this.getCriterion("searchTerm");
    if (!searchTerm) {
      return true;
    }

    const matchTerms = this.getCardSearchTerms(card)
      .filter((x) => x && x.toLowerCase)
      .map((x) => x.toLowerCase());
    const searchTermWords = searchTerm.toLowerCase().split(" ");
    return searchTermWords.all((word: string) =>
      matchTerms.some((match) => match.indexOf(word) > -1)
    );
  };

  getCriterion = (key: string) => {
    var valueOrGetter = this.criteria[key];
    if (typeof valueOrGetter === "function") {
      return valueOrGetter();
    }
    return valueOrGetter;
  };

  getModulesCriterion = (): any => {
    const modules = Object.entries(this.getCriterion("modules") || {})
      .map((x) => x[0]);
    const whitelisted = modules.filter(arxs.moduleMetadataRegistry.isModuleAllowed);
    return whitelisted.reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
  };

  matchesFilterCriteria = (card: MatchableCard) => {
    const createMapPredicate = (fieldName: string) => {
      return (map: any): boolean => {
        const value = (card as any)[fieldName];
        return Array.isArray(value) ? value.any(x => !!map[x]) : !!map[value];
      };
    };

    const kindAndType = [
      {
        key: "kind",
        filter: createMapPredicate("kind"),
      },
      {
        key: "type",
        filter: createMapPredicate("type"),
      },
    ];
    const sortKindAndType = [
      {
        key: "sort",
        filter: createMapPredicate("sort"),
      },
    ].concat(kindAndType);

    const evaluate = (
      definitions: Array<{ key: string; filter: (map: any) => any }>
    ) => {
      const success = definitions.reduce((result, x) => {
        const criterion = this.getCriterion(x.key) || {};
        const hasCriterionSelected = Object.keys(criterion).length > 0;
        return result && (!hasCriterionSelected || x.filter(criterion));
      }, true);
      return success;
    };

    const legalStructure = this.getCriterion("legalStructure") || {};
    const hasLegalStructuresSelected = Object.keys(legalStructure).length > 0;
    const branch = this.getCriterion("branch") || {};
    const hasBranchesSelected = Object.keys(branch).length > 0;
    const hasContextSelected =
      hasLegalStructuresSelected || hasBranchesSelected;

    for (const branchId of Object.keys(branch)) {
      const b = this.getLookupItem("branchMap", { id: branchId });
      if (
        b &&
        b.legalStructure &&
        b.legalStructure.id &&
        !Object.keys(legalStructure).any(
          (x: string) => x === b.legalStructure.id
        )
      ) {
        legalStructure[b.legalStructure.id] = true;
      }
    }

    const tags = this.getCriterion("tags");
    if (tags && Object.keys(tags).length > 0) {
      if (!(card.tags || []).any((x: Tag) => tags[x.id || ""])) {
        return false;
      }
    }

    switch (card.module) {
      case OriginModuleEnum.Employee:
        const employee = card as any as Employee;
        if (!hasContextSelected) {
          return true;
        }
        return (employee.assignments || []).any(
          (x) =>
            (!x.branch &&
              x.legalStructure &&
              x.legalStructure.id &&
              legalStructure[x.legalStructure.id]) ||
            (x.branch && x.branch.id && branch[x.branch.id])
        );

      case OriginModuleEnum.Document:
        const document = card as any as DocumentManagementDocument;
        return (
          evaluate(kindAndType) &&
          (!hasContextSelected ||
            (document.legalStructures || []).any(
              (x) => x.id && legalStructure[x.id]
            ) ||
            (document.branches || []).any((x) => x.id && branch[x.id]))
        );
        
      case OriginModuleEnum.Consultancy:
        const recommendation = card as any;
        return (
          evaluate(kindAndType) &&
          (!hasContextSelected ||
            (!recommendation.branch
              && recommendation.legalStructureId
              && legalStructure[recommendation.legalStructureId || ""]
            ) || (recommendation.branch
              && branch[recommendation.branchId || ""])
        ));

      case OriginModuleEnum.Project:
        const project = card as any as Project;
        return (
          evaluate(kindAndType) &&
          (!hasContextSelected ||
            (project.legalStructures || []).any(
              (x) => x.id && legalStructure[x.id]
            ) ||
            (project.branches || []).any((x) => x.id && branch[x.id]))
        );

      case OriginModuleEnum.Report:
        return evaluate(kindAndType);

      case OriginModuleEnum.Form:
        const form = card as any as Form;
        return (
          evaluate(kindAndType) &&
          (!hasContextSelected ||
            (form.legalStructures || []).any(
              (x) => x.id && legalStructure[x.id]
            ) ||
            (form.branches || []).any((x) => x.id && branch[x.id]))
        );

      case OriginModuleEnum.Contact:
        return evaluate([
          {
            key: "supplier",
            filter: createMapPredicate("supplierIds"),
          }
        ]);

      // case OriginModuleEnum.Training:
      // TODO

      case OriginModuleEnum.UserRole:
        return true;

      default:
        return evaluate(
          sortKindAndType.concat([
            {
              key: "legalStructure",
              filter: createMapPredicate("legalStructureId"),
            },
            {
              key: "branch",
              filter: createMapPredicate("branchId"),
            },
            {
              key: "building",
              filter: createMapPredicate("buildingId"),
            },
            {
              key: "location",
              filter: createMapPredicate("locationId"),
            },
            {
              key: "supplier",
              filter: createMapPredicate("supplierId"),
            },
            {
              key: "subject",
              filter: (subject: any): boolean => {
                const value = (card as any)["subjectKey"];
                return !subject || value === `${subject.module}:${subject.id}`;
              },
            },
          ])
        );
    }
  };

  getLookupValue = (key: string, value?: { id?: string | number | undefined }) => {
    if (value && value.id) {
      const lookup = this.lookups[key] || {};
      if (value) {
        const match = lookup[value.id];
        if (match) {
          return match.name;
        }
      }
    }
    return null;
  };

  getLookupItem = (key: string, value: { id: string | number }) => {
    const lookup = this.lookups[key] || {};
    if (value) {
      const match = lookup[value.id];
      if (match) {
        return match;
      }
    }
    return null;
  };

  _moduleOrderMap: { [key: string]: number } = [
    OriginModuleEnum.SchoolGroup,
    OriginModuleEnum.School,
    OriginModuleEnum.Building,
    OriginModuleEnum.Room,
    OriginModuleEnum.EquipmentInstallation,
    OriginModuleEnum.Labourmeans,
    OriginModuleEnum.Pbm,
    OriginModuleEnum.HazardousSubstance,
    OriginModuleEnum.IntangibleAsset,
    OriginModuleEnum.CombinedInstallation,
    OriginModuleEnum.Document,
    OriginModuleEnum.Commissioning,
    OriginModuleEnum.OutOfCommissioning,
    OriginModuleEnum.SafetyInstructionCard,
    OriginModuleEnum.InstructionCard,
    OriginModuleEnum.Consultancy,
    OriginModuleEnum.Periodical,
    OriginModuleEnum.Task,
    OriginModuleEnum.NotificationDefect,
    OriginModuleEnum.ActivityEntry,
    OriginModuleEnum.Project,
    OriginModuleEnum.PeriodicMaintenance,
    OriginModuleEnum.PeriodicControl,
    OriginModuleEnum.GlobalPreventionPlan,
    OriginModuleEnum.RiskAnalysis,
    OriginModuleEnum.IncidentManagement,
    OriginModuleEnum.Form,
    OriginModuleEnum.Training,
    OriginModuleEnum.Employee,
    OriginModuleEnum.Supplier,
    OriginModuleEnum.Contact,
  ].toDictionary(
    (x) => x,
    (_, i) => (i || 0) + 1
  );

  orderByModule = (card: { module: string }) => {
    return this._moduleOrderMap[card.module] || 99;
  };

  get(
    filter?: (x: any) => any | undefined,
    order?: () => any | undefined,
    descending?: boolean
  ) {
    let items: any[] = [];

    const modulesValue = this.getModulesCriterion();
    const modules = Object.keys(modulesValue);

    if (!filter) {
      filter = (_) => true;
    }


    const ids = this.getCriterion("ids") || {};
    const searchTerm = this.getCriterion("searchTerm");
    const searchTermPredicate = buildSearchTermFilter(searchTerm);

    for (const module of modules) {
      if (this.objectsByModuleResult[module]) {
        let filteredItems = this.objectsByModuleResult[module].filter(filter);
        if (Object.keys(ids).length > 0) {
          filteredItems = filteredItems.filter(x => ids[x.id]);
        }
        let matchingItems = filteredItems
          .filter(card => {
            const matchTerms = this.getCardSearchTerms(card);
            return searchTermPredicate(card, matchTerms);
          })
          .filter(this.matchesFilterCriteria);

        const newItems = matchingItems.orderByDescending(
          module === OriginModuleEnum.UserRole ? (card: any) => card.name : (card: { createdAt: any }) => card.createdAt
        );

        items.push(...newItems);
      } 
    }

    if (order) {
      if (descending) {
        return items.orderByDescending(order);
      } else {
        return items.orderBy(order);
      }
    }

    return items.orderBy(this.orderByModule);
  }
}
