/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HazardousSubstanceWGK {
    Wgk0 = 'WGK0',
    Wgk1 = 'WGK1',
    Wgk2 = 'WGK2',
    Wgk3 = 'WGK3'
}

export function HazardousSubstanceWGKFromJSON(json: any): HazardousSubstanceWGK {
    return HazardousSubstanceWGKFromJSONTyped(json, false);
}

export function HazardousSubstanceWGKFromJSONTyped(json: any, ignoreDiscriminator: boolean): HazardousSubstanceWGK {
    return json as HazardousSubstanceWGK;
}

export function HazardousSubstanceWGKToJSON(value?: HazardousSubstanceWGK | null): any {
    return value as any;
}

