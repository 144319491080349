import React from "react";
import FormBadgePresenter, { CardWithForm } from "components/controls/form/FormBadgePresenter";

interface CardFormBadgeProps {
    card: CardWithForm;
}

export default function CardFormBadge(props: CardFormBadgeProps) {
    return <FormBadgePresenter
        card={props.card}
        module={props.card.module}
        render={({filledIn, total}) => total > 0 && <div className="list-card-form-count">
            <i className="fas fa-tasks"></i>
            {filledIn}/{total}
        </div>}
    />;
}