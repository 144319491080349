import AssetWizard from '../AssetWizard';

class ProtectionEquipmentWizard extends AssetWizard {
    getFilteredNorms = (stateProxy, row, id) => {
        const filter = stateProxy.getter("normsFromKind") || {};
        if(filter){
            return Object.keys(filter).some(x => x === row.id)
        }
        return true;
    }

    filterNorms = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");
            const codeElementsById = stateProxy.getter("codeElementsById");

            const kind = data.kind || pristine.kind;

            if (kind) {
                const normsFromKind = (codeElementsById[kind.id].children.filter(x => x.code === "NormGroup")[0].children || []).map(x => x.id).toDictionary(x => x);

                stateProxy.setter({ normsFromKind }, resolve)
            } else {
                resolve();
            }
        });
    }

}
export default new ProtectionEquipmentWizard();
