import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';
import { RelationshipType } from "infra/api/contracts";

class FormBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      codeElements: (card.codeElements || []).map((x) =>
          this.getCodeElementValue(x, stateProxy.getter("codeElementsById"))
        ).join(", "),
      creationDate: card.creationDate && new Date(card.creationDate),
      createdAt: card.createdAt && new Date(card.createdAt),
      legalStructureNames: (card.legalStructures || []).map(ls => this.getLookupValue(stateProxy.getter("legalStructureMap"), ls)).filter(x => x !== "").join(", "),
      branchNAmes: (card.branches || []).map(b => this.getLookupValue(stateProxy.getter("branchMap"), b)).filter(x => x !== "").join(", "),
      responsible: this.getEmployeeValue(card.responsible, stateProxy.getter("employeeMap")),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      mostRecentVersion: card.versions.map(x => new Date(x.versionDate)).orderByDescending(x => x).filter(x => x)[0],
      revisionDate: this.getRevisionDate(card),
      targetModule: arxs.modules.titles[card.targetModule],
      subjectModule: arxs.modules.titles[card.subjectModule],
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.targetModule,
      card.kind,
      card.type,
      card.title,
      card.codeElements,
      ...((card.legalStructureNames || "").split(", ") || []).map(x => x),
      ...((card.branchNames || "").split(", ") || []).map(x => x),
      ...(card.tags || []).map(x => x.name),
      JSON.stringify(card.formDefinition)
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.forms) {
            lookups.pristine = lookups.forms
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.forms;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
        });
  }
}
export default new FormBoard();