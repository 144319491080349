const createInputPopupState = (setState, props) => {
  var state = {
    ...props,
    show: (props) => {
      setState(createInputPopupState(setState, props));
    },
    close: () => {
      setState(createInputPopupState(setState));
    }
  };
  return state;
};
export default createInputPopupState; 