import React from "react";
import arxs from 'infra/arxs'
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";

import "./PreRecommendation.scss";

export default class PreRecommendation extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }

    getPreRecommendationTypes = () => {
        const { codeElements } = this.state;

        const code = 'Recommendation.PreAdvice';

        const preRecommendationKind = ((codeElements || {})[code] || [])[0] || {};
        const preRecommendationTypes = (preRecommendationKind.children || [])
            .map(x => ({ id: x.id, name: x.name }));
        return preRecommendationTypes;
    }

    renderPreRecommendation = (item) => {
        const getTextValue = () => {
            return (item || {})["value"] || "";
        }

        return <li key={`risk-measurement-li-${item.id}`}>
            <label>{getTextValue()}</label>
        </li>;
    }

    renderPreRecommendations = () => {
        const value = this.getter();

        return <div className="prerecommendation">
            <div className="prerecommendation-values">
                <ul>
                    {value.map((line, index) => this.renderPreRecommendation(line))}
                </ul>
            </div>
        </div >
    }

    render() {
        const settings = this.getSettings();
        const { codeElementsById } = this.state;
        let selectedPreRecommendation;
        if (settings && codeElementsById) {
            if (arxs.uuid.isValid(settings.title)) {
                selectedPreRecommendation = settings.title
                    ? { id: settings.title, name: (codeElementsById[settings.title] || {}).name }
                    : undefined;
            } else if (settings.title) {
                selectedPreRecommendation = { name: settings.title };
            }
        }

        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control prerecommendation">
                    {this.renderHeader((selectedPreRecommendation || {}).name, true)}
                    <div className="form-control-field">
                        {this.renderPreRecommendations()}
                    </div>
                </div>}
        </GlobalContext.Consumer>)
    }
}