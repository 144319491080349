/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EditFormAction {
    Complete = 'Complete',
    Verify = 'Verify',
    Save = 'Save'
}

export function EditFormActionFromJSON(json: any): EditFormAction {
    return EditFormActionFromJSONTyped(json, false);
}

export function EditFormActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditFormAction {
    return json as EditFormAction;
}

export function EditFormActionToJSON(value?: EditFormAction | null): any {
    return value as any;
}

