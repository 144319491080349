import React from 'react';
import './Link.scss';

export default class Link extends React.Component {
  render() {
    const classNames = [
      "link",
      this.props.className
    ].filter(x => x);
    return (<span className={classNames.join(" ")} onClick={this.props.onClick} >
      {this.props.children}
    </span>)
  }
}