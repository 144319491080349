import React, { Fragment, useEffect, useState, useCallback } from "react";

import GlobalContext from "infra/GlobalContext";
import { SecurityContext } from "infra/SecurityContext";
import {
  ReportModuleSetting,
  OriginModuleEnum,
  ReportStyle,
  ReportPageOrientation,
} from "infra/api/contracts";
import arxs from "infra/arxs";
import MultiSelect from "components/controls/MultiSelect";
import DropDown, { DropDownItem } from "components/controls/DropDown";
import CodeElementList from "components/controls/codeElements/CodeElementList";
import StringExtensions from "infra/tools/StringExtensions";

import "./ReportModuleConfig.scss";

export interface ReportModuleConfigProps {
  readOnly?: boolean;
  editable?: boolean;
  value: ReportModuleSetting;
  base: ReportModuleSetting;
  onChange(value?: ReportModuleSetting): void;
  securityContext: SecurityContext;
}

interface CodeElementRef {
  id: string;
}

export default function ReportModuleConfig(props: ReportModuleConfigProps) {
  const { value } = props;
  const [selectedFields, setSelectedFields] = useState(
    value.fields?.map((f: string) => ({ id: f, name: arxs.t("field." + f) })) ||
      []
  );
  const [selectedCodeElements, setSelectedCodeElements] = useState(
    value.codeElements?.map((g: string) => ({ id: g })) || []
  );
  const [selectedReportStyle, setSelectedReportStyle] = useState(
    value.style || ReportStyle.ListView
  );
  const [selectedReportPageOrientation, setSelectedReportPageOrientation] =
    useState(value.orientation || ReportPageOrientation.Portrait);

  useEffect(() => {
    setSelectedFields(
      props.value.fields?.map((f: string) => ({
        id: f,
        name: f.split(".").map(x => arxs.t("field." + StringExtensions.lowerCaseFirstLetter(x))).join(" "),
      })) || []
    );
    setSelectedCodeElements(
      value.codeElements?.map((g: string) => ({ id: g })) || []
    );
    setSelectedReportStyle(value.style || ReportStyle.ListView);
    setSelectedReportPageOrientation(
      value.orientation || ReportPageOrientation.Landscape
    );
  }, [props.value]);

  const getBaseFields = () => {
    var dropdownItems = props.base.fields?.map((f) => ({
      id: f,
      name: f.split(".").map(x => arxs.t("field." + StringExtensions.lowerCaseFirstLetter(x))).join(" "),
    }));

    return dropdownItems as Array<DropDownItem>;
  };

  const getSelectedFields = () => {
    const baseFields = getBaseFields();
    var dropdownItems = selectedFields.filter((x: DropDownItem) =>
      baseFields.map((y: DropDownItem) => y.id).includes(x.id)
    );

    return dropdownItems as Array<DropDownItem>;
  };

  const getSelectedCodeElements = () => {
    return selectedCodeElements as Array<CodeElementRef>;
  };

  const getReportStyleItems = () => {
    return Object.values(ReportStyle).map((x) => ({
      id: x,
      name: arxs.t(`enums.ReportStyle.${x}`),
    }));
  };

  const getSelectedReportStyle = () => {
    return {
      id: selectedReportStyle,
      name: arxs.t(`enums.ReportStyle.${selectedReportStyle}`),
    };
  };

  const handleSelectedReportStyleSelection = useCallback((selected: any) => {
    setSelectedReportStyle(selected.id);

    var changed = {
      ...props.value,
      style: selected.id,
      orientation: selectedReportPageOrientation,
      codeElements: selectedCodeElements.map((x) => x.id),
      fields: selectedFields.map((x) => x.id),
    };

    props.onChange(changed);
  }, [props.value, props.onChange, setSelectedReportStyle]);

  const getReportPageOrientationItems = () => {
    return Object.values(ReportPageOrientation).map((x) => ({
      id: x,
      name: arxs.t(`enums.ReportPageOrientation.${x}`),
    }));
  };

  const getSelectedReportPageOrientation = () => {
    return {
      id: selectedReportPageOrientation,
      name: arxs.t(
        `enums.ReportPageOrientation.${selectedReportPageOrientation}`
      ),
    };
  };

  const handleSelectedReportPageOrientationSelection = (selected: any) => {
    setSelectedReportPageOrientation(selected.id);

    var changed = {
      ...props.value,
      orientation: selected.id,
      style: selectedReportStyle,
      codeElements: selectedCodeElements.map((x) => x.id),
      fields: selectedFields.map((x) => x.id),
    };

    props.onChange(changed);
  };

  const handleFieldSelection = useCallback((selected: any) => {
    setSelectedFields(selected);

    var changed = {
      ...props.value,
      fields: selected.map((x: DropDownItem) => x.id),
      codeElements: selectedCodeElements.map((x) => x.id),
      style: selectedReportStyle,
      orientation: selectedReportPageOrientation,
    };
    props.onChange(changed);
  }, [props.value, props.onChange, setSelectedFields]);

  const handleCodeElementSelection = useCallback((value: any[]) => {
    const newValues = value
      .map((x) => ({ id: x.id ?? x.parentId ?? x.grandParentId ?? "" }))
      .distinct((x) => x.id);

    setSelectedCodeElements(newValues);

    var changed = {
      ...props.value,
      codeElements: newValues.map((x) => x.id),
      fields: selectedFields.map((x) => x.id),
      style: selectedReportStyle,
      orientation: selectedReportPageOrientation,
    };

    props.onChange(changed);
  }, [props.value, props.onChange, setSelectedCodeElements]);

  const shouldRenderCodeElementList = () => {
    switch (props.base.module) {
      case OriginModuleEnum.Consultancy:
        return false;
      default:
        return true;
    }
  };

  const getRootCode = () => {
    switch (props.base.module){
      case OriginModuleEnum.IncidentManagement: return "IncidentManagement.Kind"; 
      default: return undefined;
    }
  }

  return (
    <GlobalContext.Consumer>
      {(context) => (
        <div className="report-module-config">
          <Fragment>
            <div className="field-selection">
              <label>Output type</label>
              <DropDown
                items={getReportStyleItems()}
                selected={getSelectedReportStyle()}
                onChange={handleSelectedReportStyleSelection}
              />
              {shouldRenderCodeElementList() && (
                <CodeElementList
                  className="field"
                  title={arxs.t(`field.category`)}
                  readOnly={props.readOnly}
                  value={getSelectedCodeElements()}
                  onChange={handleCodeElementSelection}
                  singleSelection={false}
                  module={props.base.module || OriginModuleEnum.None}
                  allowDelete={!props.readOnly}
                  required={false}
                  securityContext={props.securityContext}
                  addParent={true}
                  code={getRootCode()}
                />
              )}
            </div>
            <div className="field-selection">
              <label>Pagina layout</label>
              <DropDown
                items={getReportPageOrientationItems()}
                selected={getSelectedReportPageOrientation()}
                onChange={handleSelectedReportPageOrientationSelection}
              />
            </div>
            <div className="field-selection">
              <label>
                Geef aan welke velden getoond mogen worden op het rapport
              </label>
              <MultiSelect
                className="multiselect"
                selected={getSelectedFields()}
                onChange={handleFieldSelection}
                placeholder={arxs.t("field.fields")}
                items={getBaseFields()}
              />
            </div>

          </Fragment>
          {/* {!(readOnly || editable === false) && <Fragment></Fragment>} */}
        </div>
      )}
    </GlobalContext.Consumer>
  );
}
