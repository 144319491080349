import React, { Fragment, ReactNode } from "react";

import arxs from "infra/arxs";
import { ObjectDocumentType, } from "infra/api/contracts";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardPlanning from "components/card/CardPlanning"
import CardActions from "components/card/CardActions";
import CardFormBadge from "components/card/CardFormBadge";
import { Image } from "components/controls/images/Image";
import CardLocation from "components/card/CardLocation";


export default function TasklikeCard(props: CardClassProps) {
  const { card, cardProps, actionProps } = props;

  if (actionProps.condensed) {
    return (
      <div className={`user-card ${cardProps.className || ""} selectable`}>
        <i className={arxs.modules.icons[card.module]}></i>
        <div className="user-card-names">
          <div className="user-card-name">
            <div>{card.title}</div>
          </div>
        </div>
        <CardActions {...props.actionProps} />
      </div>
    );
  }

  if (actionProps.mini) {
    return (
      <>
        <i className={arxs.modules.icons[card.module]}></i>
        <div className="mini-card-names">
          <div className="mini-card-name">
            <div>{card.title}</div>
          </div>
        </div>
      </>
    );
  }

  let showImage = cardProps.showImage || false;
  let showSubjectImage = cardProps.showSubjectImage || false;

  const getSubject = () => {
    if (card.subject) {
      var subjectInfo = arxs.Api.lookups.resolveSubject(card.subject);
      return subjectInfo;
    }
  };

  const subject = getSubject();
  const attachmentInfo = showSubjectImage
    ? (subject || {}).attachmentInfo
    : card.attachmentInfo;

  const imageAttachment = (
    (
      (((attachmentInfo || {}).attachments || []).filter(
        (x: any) => x.type === ObjectDocumentType.Image
      ) || [])[0] || {}
    ).value || []
  ).filter((x: any) => !x.isDeleted)[0];

  const firstImageFile =
    ((attachmentInfo || {}).storedFiles || []).filter(
      (x: any) => x.id === (imageAttachment || {}).id
    )[0] || {};

  let image = card.imagePath || (firstImageFile || {}).url;
  if (image && image.length > 0) {
    image = arxs.ImageProxy.resizeImage(image, 100, 150);
  }

  const { priority, taskType } = card;

  const taskTypeClassName = taskType && `task-type-${taskType.toLowerCase()}`;
  const taskTypeValue = taskType && arxs.enums.getTitle("TaskType", taskType);

  const priorityClassName = priority && `priority-${priority.toLowerCase()}`;
  const priorityValue = priority && arxs.enums.getTitle("TaskPriorityEnum", priority);

  const title = card.title || card.description;

  return (
    <Fragment>
      {showImage && <div className="list-card-left">
        <Image className="list-card-image" src={image} />
      </div>}
      <div className="list-card-right">
        <div className="list-card-header">
          <CardPlanning {...props} />
        </div>
        <h2>
          <div className="list-card-title" title={title}>
            {title}
          </div>
          <CardActions {...props.actionProps} />
        </h2>
        <CardSubtitle
          cardProps={props.cardProps}
          card={card}
          render={(children: ReactNode) => (
            <>
              <div
                className={`list-card-priority badge ${priorityClassName}`}
                >
                {priorityValue}
              </div>
              <div
                className={`list-card-tasktype badge ${taskTypeClassName}`}
                >
                {taskTypeValue}
              </div>
              {children}
              <div className="list-card-document-count">
                <i className="fas fa-copy"></i>
                {card.numberOfDocuments}
              </div>
              <CardFormBadge card={card} />
            </>
          )}
        />
        <div className="list-card-details">
          {subject && subject.internalNumber && (
            <div>{subject.internalNumber}</div>
          )}
          <div>
            {[card.sort, card.kind, card.type].filter((x) => x).join(" - ")}
          </div>
          <CardLocation card={card} />
        </div>
      </div>
    </Fragment>
  );
}
