import React, { Fragment } from "react";
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";

export default class TextAreaInput extends FormItemEditorController {
    render() {
        const isExecutable = this.props.type && ["select","checkbox", "remark"].includes(this.props.type);

        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    {this.renderTitle(context)}
                    {this.renderEnd(isExecutable)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}