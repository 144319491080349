import Toaster from "components/util/Toaster";
import arxs from "infra/arxs";
import {
  Profile,
  GenerateApiKeyRequest,
  LegalStructure,
  Branch,
  RightsInfo,
  UserRoleInfo,
  Employee,
  AddCodeElement,
  LabourMeans,
  Equipment,
  Building,
  Location,
  Tag,
  AddTagsToObjects,
  AddObjectsToTag,
  AddTag,
  ObjectRef,
  TaskRequest,
  RefuseTaskRequest,
  UnrefuseTaskRequest,
  AcceptTaskRequest,
  TaskRequestSettings,
  EditTaskRequestSettingsRule,
  Task,
  CompleteTask,
  ResetTaskToDraft,
  UnHoldTask,
  ActivateTask,
  VerifyTask,
  HoldTask,
  EditTaskForm,
  TaskSettings,
  EditTaskSettingsRule,
  TaskPriorityRule,
  EditTaskPriorityRule,
  Maintenance,
  CompleteMaintenance,
  ResetMaintenanceToDraft,
  UnHoldMaintenance,
  ActivateMaintenance,
  VerifyMaintenance,
  HoldMaintenance,
  EditMaintenanceForm,
  Inspection,
  CompleteInspection,
  ResetInspectionToDraft,
  UnHoldInspection,
  ActivateInspection,
  VerifyInspection,
  HoldInspection,
  EditInspectionForm,
  Activity,
  ObjectWithActivitiesForUser,
  PlanningMoment,
  Trust,
  ActivityEntry,
  Contact,
  Incident,
  IncidentSettings,
  EditIncidentSettingsRule,
  DocumentManagementDocument,
  SetDocumentInProcess,
  SeriousIncidentRange,
  Project,
  CompleteProject,
  HoldProject,
  UnHoldProject,
  ProjectSettings,
  EditProjectSettingsRule,
  Template,
  ProtectionEquipment,
  HazardousSubstance,
  CombinedInstallation,
  Supplier,
  ReportTemplate,
  RevisePlanning,
  GanttDefinition,
  OriginModuleEnum,
  ReportDefinition,
  ActivateProject,
  GenerateReportTemplate,
  UnexpireLabourmean,
  UnexpireEquipment,
  UnexpireIntangibleAsset,
  UnexpireHazardousSubstance,
  AddToExistingCombinedInstallation,
  MailMessage,
  SetDocumentActive,
  Form,
  SetFormInProcess,
  SetFormActive,
  MultiYearPlan,
  InstructionCard,
  InstructionCardSettings,
  EditInstructionCardSettingsRule,
  PlanMultiYearPlan,
  UnPlanMultiYearPlan,
  RePlanMultiYearPlan,
  CompleteMultiYearPlan,
  CloseMultiYearPlan,
  Commissioning,
  CommissioningSettings,
  EditCommissioningSettingsRule,
  UnexpireProtectionEquipment,
  SafetyInstructionCard,
  SafetyInstructionCardSettings,
  EditSafetyInstructionCardSettingsRule,
  RiskAnalysis,
  CompleteRiskAnalysis,
  VerifyRiskAnalysis,
  HoldRiskAnalysis,
  ActivateRiskAnalysis,
  ResetRiskAnalysisToDraft,
  UnHoldRiskAnalysis,
  EditRiskAnalysisForm,
  RiskAnalysisSettings,
  EditRiskAnalysisSettings,
  InspectionSettings,
  EditInspectionSettings,
  MaintenanceSettings,
  EditMaintenanceSettings,
  EditCommissioningForm,
  RecommendationSettings,
  EditRecommendationSettingsRule,
  Recommendation,
  SetRecommendationInProcess,
  SetRecommendationInTreatment,
  RefuseRecommendation,
  SetCommissioningInProcess,
  SetInstructionCardInProcess,
  SetSafetyInstructionCardInProcess,
  DeactivateSafetyInstructionCard,
  DeactivateInstructionCard,
  Decommissioning,
  DecommissioningSettings,
  EditDecommissioningForm,
  SetDecommissioningInProcess,
  EditDecommissioningSettingsRule,
  Periodical,
  CompletePeriodical,
  HoldPeriodical,
  ActivatePeriodical,
  ResetPeriodicalToDraft,
  UnholdPeriodical,
  AddAttachmentsToObjects,
  ImportCodeElementsRequest,
  ImportRequest,
  BulkEdit,
  AmendActualDurationOfTask,
  AmendActualDurationOfInspection,
  AmendActualDurationOfMaintenance,
  AmendActualDurationOfRiskAnalysis,
  LibraryItem,
  GenerateCalendarPublishLink,
} from "./contracts";

class ApiClient {
  endpointUrl = process.env.REACT_APP_API_ENDPOINT;
  getToken: () => null;
  headers: any;

  constructor() {
    this.getToken = () => null;
    this.headers = { "Content-Type": "application/json" };
  }

  initialize = () => {};

  toastFetchError = (error?: any) => {
    if (typeof error === "string") {
      Toaster.error(error);
    } else {
      Toaster.error("Er ging iets mis...");
    }
  };

  handleFetchError = (url: RequestInfo, error: string) => {
    const errorMessage = "" + error;
    arxs.logger.warn("Fetch {url} failed: {error}", url, errorMessage);
    return new Promise((resolve, reject) => {});
  };

  fetchSubscriptions: Array<any> = [];
  subscribeToFetchEvents = (handler?: any) => {
    const index = this.fetchSubscriptions.length;

    if (!handler) {
      throw Error("subscribeToFetchEvents expects a non-null handler");
    }

    this.fetchSubscriptions.push(handler);

    return {
      dispose: () => {
        this.fetchSubscriptions.splice(index, 1);
      },
    };
  };

  tryFetch = (
    url: RequestInfo,
    body: RequestInit | undefined,
    returnRawResponse?: boolean | undefined,
    contentType?: string | undefined
  ) => {
    if (!body) {
      body = { headers: this.getHeaders() };
    }
    if (contentType) {
      body.headers = { ...body.headers, "Content-Type": contentType };
    }
    return fetch(url, body)
      .then((response) => {
        for (const handler of this.fetchSubscriptions) {
          handler(response);
        }
        return Promise.resolve(response);
      })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 400 || response.status === 422 || response.status === 304) {
            return Promise.resolve(response);
          }
          return response
            .json()
            .then((x) => {
              if (returnRawResponse) {
                return x;
              } else {
                if (response.status === 405) {
                  this.toastFetchError(arxs.t("error.action_unauthorized"));
                  arxs.logger.warn(
                    "Fetch {url} failed: {error} {payload}"
                    , url, x.error, this.fetchErrorToLogPayload(x)
                  );
                } else if (response.status === 403) {
                  this.toastFetchError(arxs.t("error.record_unauthorized"));
                  arxs.logger.warn(
                    "Fetch {url} failed: {error} {payload}"                    
                    , url, x.error, this.fetchErrorToLogPayload(x)
                  );
                } else if (response.status >= 400) {
                  arxs.logger.warn(
                    "Fetch {url} failed: {error} {payload}"
                    , url, x.error, this.fetchErrorToLogPayload(x)
                  );
                  this.toastFetchError(x.error);
                } else {
                  arxs.logger.warn(
                    "Fetch {url} returned unexpected payload: {payload}"
                    , url, x
                  );
                  this.toastFetchError(x.error);
                }
              }

              return new Promise((resolve, reject) => {
                reject({ response });
              });
            })
            .catch((error) => {
              return new Promise((resolve, reject) => {
                reject({ error, response });
              });
            });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        // catch gets called when the user is offline or one of our then handlers throws an exception of its own
        //   In those cases we get an error and no response
        //   In the case where a non-ok success status is returned we want to pass the error response to the caller so it can stop spinners
        const response = error && error.response;
        error = error ? error.error : error;
        for (const handler of this.fetchSubscriptions) {
          handler(response);
        }
        this.handleFetchError(url, error);
        return new Promise((resolve, reject) => reject(response));
      });
  };

  fetchErrorToLogPayload = (result: any) => {
    const payload = { ...result };
    if (payload.traceId) {
      payload.SpanId = payload.traceId;
      delete payload.traceId;
    }
    return payload;
  };

  tryFetchJson<T>(
    url: string,
    body?: any,
    contentType?: string | undefined
  ): Promise<T> {
    return this.tryFetch(url, body, undefined, contentType).then(
      (response: any) => {
        return response.json().catch((error: string) => {
          const errorMessage = "" + error;
          arxs.logger.warn(
            "Fetch {url} failed: {error}"
            , url, errorMessage
          );
          this.toastFetchError();
          return new Promise((resolve, reject) => {});
        });
      }
    );
  }

  tryFetchBinary<T>(url: string, body?: any): Promise<T> {
    return this.tryFetch(url, body).then((response: any) => {
      return response
        .clone()
        .blob()
        .catch((error: string) => {
          const errorMessage = "" + error;
          arxs.logger.warn(
            "Fetch {url} failed: {error}"
            , url, errorMessage
          );
          this.toastFetchError();
          return new Promise((resolve, reject) => {});
        });
    });
  }

  getHeaders = () => {
    const token = arxs.Api.getToken();
    const headers = { ...this.headers };
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }
    return headers;
  };

  masterdata = (() => {
    const baseUrl = `${this.endpointUrl}/api/masterdata`;

    return {
      legalStructure: {
        getById: (id: string) =>
          this.tryFetchJson<LegalStructure>(`${baseUrl}/legalstructure/${id}`),
        get: () =>
          this.tryFetchJson<LegalStructure[]>(`${baseUrl}/legalstructure`),
        post: (id: string, data: LegalStructure) =>
          this.tryFetch(`${baseUrl}/legalstructure/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        put: (data: LegalStructure) =>
          this.tryFetch(`${baseUrl}/legalstructure`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/legalstructure/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/legalstructure/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        settings: () => null,
      },
      branch: {
        getById: (id: string) =>
          this.tryFetchJson<Branch>(`${baseUrl}/branch/${id}`),
        get: () => this.tryFetchJson(`${baseUrl}/branch`),
        post: (id: string, data: Branch) =>
          this.tryFetch(`${baseUrl}/branch/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        put: (data: Branch) =>
          this.tryFetch(`${baseUrl}/branch`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/branch/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/branch/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      supplier: {
        getById: (id: string) =>
          this.tryFetchJson<Supplier>(`${baseUrl}/supplier/${id}`),
        get: () => this.tryFetchJson(`${baseUrl}/supplier`),
        post: (id: string, data: Supplier) =>
          this.tryFetch(`${baseUrl}/supplier/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        put: (data: Supplier) =>
          this.tryFetch(`${baseUrl}/supplier`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/supplier/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/supplier/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      supplierSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/supplier/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/supplier/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      codeElements: {
        getMetaData: () =>
          this.tryFetchJson<any>(`${baseUrl}/codeelements/getmetadata`),
        getMetaDataByModules: (modules: string[]) =>
          this.tryFetchJson(
            `${baseUrl}/codeelements/getmetadatabymodules/${modules.join(",")}`
          ),
        add: (data: AddCodeElement) =>
          this.tryFetch(`${baseUrl}/codeelements/`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        edit: (id: string, value: string) =>
          this.tryFetch(`${baseUrl}/codeelements/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ Id: id, Value: value }),
          }),
        delete: (id: string) =>
          this.tryFetch(`${baseUrl}/codeelements/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(),
          }),
        getImportTemplate: (code: string) => {
          return this.tryFetchBinary(
            `${baseUrl}/codeelements/import-template/${code}`
          );
        },
        importData: (data: ImportCodeElementsRequest) =>
          this.tryFetchJson(`${baseUrl}/codeelements/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      userRole: {
        getById: (id: string) =>
          this.tryFetchJson<UserRoleInfo>(`${baseUrl}/userrole/${id}`),
        get: () => this.tryFetchJson<UserRoleInfo[]>(`${baseUrl}/userrole`),
        addUsers: (id: string, data: string[]) =>
          this.tryFetch(`${baseUrl}/userrole/${id}/addusers`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        deleteUserFromRole: (roleId: string, userId: string) =>
          this.tryFetch(`${baseUrl}/userrole/${roleId}/user/${userId}`, {
            method: "DELETE",
            headers: this.getHeaders(),
          }),
        delete: (id: string) =>
          this.tryFetch(`${baseUrl}/userrole/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(),
          }),
        rename: (id: string, name: string) =>
          this.tryFetch(`${baseUrl}/userrole/${id}/rename`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(name),
          }),
        add: (name: string) =>
          this.tryFetch(`${baseUrl}/userrole`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(name),
          }),
        getRights: (id: string) =>
          this.tryFetchJson(`${baseUrl}/userrole/${id}/rights`),
        setRights: (id: string, rights: RightsInfo) =>
          this.tryFetch(`${baseUrl}/userrole/${id}/rights`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(rights),
          }),
      },
      employee: {
        getById: (id: string) =>
          this.tryFetchJson<Employee>(`${baseUrl}/employee/${id}`),
        get: () => this.tryFetchJson<Employee[]>(`${baseUrl}/employee`),
        post: (id: string, data: Employee) =>
          this.tryFetch(`${baseUrl}/employee/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        put: (data: Employee) =>
          this.tryFetch(`${baseUrl}/employee`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        setOutOfService: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/employee/setoutofservice`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setSick: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/employee/setsick`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInService: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/employee/setinservice`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      employeeSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/employee/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(
            `${baseUrl}/employee/import`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            }
          ),
      },
      profile: {
        get: () => this.tryFetchJson<Profile>(`${baseUrl}/profile`),
        post: (data: Profile) =>
          this.tryFetch(`${baseUrl}/profile`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        api: {
          generate: (data: GenerateApiKeyRequest) =>
            this.tryFetch(`${baseUrl}/profile/apikey`, {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            }),
          remove: (id: string) =>
            this.tryFetch(`${baseUrl}/profile/apikey/${id}`, {
              method: "DELETE",
              headers: this.getHeaders(),
            }),
        },
        calendar: {
          generate: (data: GenerateCalendarPublishLink) =>
            this.tryFetch(`${baseUrl}/profile/calendar`, {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            }),
          remove: (id: string) =>
            this.tryFetch(`${baseUrl}/profile/calendar/${id}`, {
              method: "DELETE",
              headers: this.getHeaders(),
            }),
          createTokenUrl: (token: string) => `${baseUrl}/profile/calendar/${token}.ics`,
        },
      },
      trust: {
        getById: (id: string) =>
          this.tryFetchJson<Trust>(`${baseUrl}/trust/${id}`),
        get: () => this.tryFetchJson<Trust>(`${baseUrl}/trust`),
        post: (data: Trust) =>
          this.tryFetch(`${baseUrl}/trust`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        put: (data: Trust) =>
          this.tryFetch(`${baseUrl}/trust`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      contact: {
        get: () => this.tryFetchJson<Contact[]>(`${baseUrl}/contact`),
        getById: (id: string) =>
          this.tryFetchJson<Contact>(`${baseUrl}/contact/${id}`),
        put: (data: Contact) =>
          this.tryFetch(`${baseUrl}/contact`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Contact) =>
          this.tryFetch(`${baseUrl}/contact/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/contact/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/contact/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      template: {
        get: () => this.tryFetchJson<Template[]>(`${baseUrl}/template`),
        getById: (id: string) =>
          this.tryFetchJson<Template>(`${baseUrl}/template/${id}`),
        put: (data: Template) =>
          this.tryFetch(`${baseUrl}/template`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Template) =>
          this.tryFetch(`${baseUrl}/template/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      library: {
        get: () =>
          this.tryFetchJson<LibraryItem>(`${baseUrl}/library`),
        getById: (id: string) =>
          this.tryFetchJson<LibraryItem>(`${baseUrl}/library/${id}`),
        publish: (data: Template) =>
          this.tryFetch(`${baseUrl}/library/publish`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
    };
  })();

  assets = (() => {
    const baseUrl = `${this.endpointUrl}/api/assetmanagement`;
    return {
      labourmeans: {
        getById: (id: string) =>
          this.tryFetchJson<LabourMeans>(`${baseUrl}/labourmeans/${id}`),
        get: () => this.tryFetchJson<LabourMeans[]>(`${baseUrl}/labourmeans`),
        put: (data: LabourMeans) =>
          this.tryFetch(`${baseUrl}/labourmeans`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: LabourMeans) =>
          this.tryFetch(`${baseUrl}/labourmeans/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/labourmeans/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        unExpire: (data: UnexpireLabourmean) =>
          this.tryFetch(`${baseUrl}/labourmeans/unexpire`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      labourmeansSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/labourmeans/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/labourmeans/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      equipment: {
        getById: (id: string) =>
          this.tryFetchJson<Equipment>(`${baseUrl}/equipment/${id}`),
        get: () => this.tryFetchJson<Equipment[]>(`${baseUrl}/equipment`),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        put: (data: Equipment) =>
          this.tryFetch(`${baseUrl}/equipment`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Equipment) =>
          this.tryFetch(`${baseUrl}/equipment/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/equipment/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        unExpire: (data: UnexpireEquipment) =>
          this.tryFetch(`${baseUrl}/equipment/unexpire`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      equipmentSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/equipment/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/equipment/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      intangibleAsset: {
        getById: (id: string) =>
          this.tryFetchJson<Equipment>(`${baseUrl}/intangibleasset/${id}`),
        get: () => this.tryFetchJson<Equipment[]>(`${baseUrl}/intangibleasset`),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        put: (data: Equipment) =>
          this.tryFetch(`${baseUrl}/intangibleAsset`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Equipment) =>
          this.tryFetch(`${baseUrl}/intangibleasset/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/intangibleasset/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        unExpire: (data: UnexpireIntangibleAsset) =>
          this.tryFetch(`${baseUrl}/intangibleasset/unexpire`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      intangibleAssetSettings: {
      },
      protectionEquipment: {
        getById: (id: string) =>
          this.tryFetchJson<ProtectionEquipment>(
            `${baseUrl}/protectionequipment/${id}`
          ),
        get: () =>
          this.tryFetchJson<ProtectionEquipment[]>(
            `${baseUrl}/protectionequipment`
          ),
        put: (data: ProtectionEquipment) =>
          this.tryFetch(`${baseUrl}/protectionequipment`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: ProtectionEquipment) =>
          this.tryFetch(`${baseUrl}/protectionequipment/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/protectionequipment/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/protectionequipment/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(
            `${baseUrl}/protectionequipment/request_commissioning`,
            {
              method: "PUT",
              headers: this.getHeaders(),
              body: JSON.stringify({ ids: ids }),
            }
          ),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/protectionequipment/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/protectionequipment/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/protectionequipment/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        unExpire: (data: UnexpireProtectionEquipment) =>
          this.tryFetch(`${baseUrl}/protectionequipment/unexpire`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      protectionEquipmentSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/protectionequipment/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/protectionequipment/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      hazardousSubstance: {
        getById: (id: string) =>
          this.tryFetchJson<HazardousSubstance>(
            `${baseUrl}/hazardoussubstance/${id}`
          ),
        get: () =>
          this.tryFetchJson<HazardousSubstance[]>(
            `${baseUrl}/hazardoussubstance`
          ),
        put: (data: HazardousSubstance) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: HazardousSubstance) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        unExpire: (data: UnexpireHazardousSubstance) =>
          this.tryFetch(`${baseUrl}/hazardoussubstance/unexpire`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        getInventory: () =>
          this.tryFetchBinary(
            `${baseUrl}/hazardoussubstance/report/inventory`
          ),
      },
      hazardousSubstanceSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/hazardoussubstance/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/hazardoussubstance/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      combinedInstallation: {
        getById: (id: string) =>
          this.tryFetchJson<CombinedInstallation>(
            `${baseUrl}/combinedinstallation/${id}`
          ),
        get: () =>
          this.tryFetchJson<CombinedInstallation[]>(
            `${baseUrl}/combinedinstallation`
          ),
        put: (data: CombinedInstallation) =>
          this.tryFetch(`${baseUrl}/combinedinstallation`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: CombinedInstallation) =>
          this.tryFetch(`${baseUrl}/combinedinstallation/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/combinedinstallation/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/combinedinstallation/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        addToExisting: (data: AddToExistingCombinedInstallation) =>
          this.tryFetch(`${baseUrl}/combinedinstallation/${data.id}/add`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      building: {
        getById: (id: string) =>
          this.tryFetchJson<Building>(`${baseUrl}/building/${id}`),
        get: () => this.tryFetchJson<Building[]>(`${baseUrl}/building`),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        put: (data: Building) =>
          this.tryFetch(`${baseUrl}/building`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Building) =>
          this.tryFetch(`${baseUrl}/building/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/building/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      buildingSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/building/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/building/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      location: {
        getById: (id: string) =>
          this.tryFetchJson<Location>(`${baseUrl}/location/${id}`),
        get: () => this.tryFetchJson<Location[]>(`${baseUrl}/location`),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        put: (data: Location) =>
          this.tryFetch(`${baseUrl}/location`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Location) =>
          this.tryFetch(`${baseUrl}/location/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        requestCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/request_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelCommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/cancel_commissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        requestDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/request_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        cancelDecommissioning: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/location/cancel_decommissioning`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      locationSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/location/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/location/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
    };
  })();

  shared = (() => {
    const baseUrl = `${this.endpointUrl}/api/shared`;
    return {
      subscriptions: {
        subscribe: (module: string, objectId: string) =>
          this.tryFetchJson(
            `${baseUrl}/subscription/${module}/${objectId}/subscribe`,
            {
              method: "POST",
              headers: this.getHeaders(),
            }
          ),
        unsubscribe: (module: string, objectId: string) =>
          this.tryFetchJson(
            `${baseUrl}/subscription/${module}/${objectId}/unsubscribe`,
            {
              method: "POST",
              headers: this.getHeaders(),
            }
          ),
      },
      blob: {
        getPutAuthorization: async (fileName: string, documentType: string) =>
          await this.tryFetchJson<string>(
            `${baseUrl}/blob/GetBlobPutAuthorization?fileName=${encodeURIComponent(
              fileName
            )}&type=${documentType}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      attachment: {
        addAttachmentsToObjects: (data: AddAttachmentsToObjects) =>
          this.tryFetch(`${baseUrl}/attachment/addattachmentstoobjects`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        retrieve: (data: any) =>
          this.tryFetchJson(
            `${baseUrl}/attachment/retrieveurls?module=${data.module}&objectid=${data.objectId}&storedfileid=${data.storedFileId}&documentid=${data.documentId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
        signEditorPayload: (data: any) =>
          this.tryFetchJson(`${baseUrl}/attachment/signeditorpayload`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        retrieveDocument: (data: any) =>
          this.tryFetchJson(
            `${baseUrl}/attachment/retrievefile?module=${data.module}&objectid=${data.objectId}&attachmentid=${data.attachmentId}&preview=${data.preview}`
          ),
        retrieveZippedDocuments: (data: any) => {
          return this.tryFetchBinary(`${baseUrl}/attachment/retrievezippeddocuments`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          })
        },
      },
      tags: {
        addObjectsToTag: (data: AddObjectsToTag) =>
          this.tryFetch(`${baseUrl}/tag/addObjectsToTag`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        addTagsToObjects: (data: AddTagsToObjects) =>
          this.tryFetch(`${baseUrl}/tag/AddTagsToObjects`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        add: (data: AddTag) =>
          this.tryFetch(`${baseUrl}/tag/`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        getById: (id: string) => this.tryFetchJson<Tag>(`${baseUrl}/tag/${id}`),
        get: () =>
          this.tryFetchJson<Tag[]>(`${baseUrl}/tag?pageNumber=0&pageSize=5000`),
        getObjectsByTagId: (id: string) =>
          this.tryFetchJson<ObjectRef[]>(`${baseUrl}/tag/${id}/objects`),
        getForObject: async (objectId: string) =>
          await this.tryFetchJson(`${baseUrl}/tag/objects/${objectId}`, {
            method: "GET",
            headers: this.getHeaders(),
          }),
        edit: (id: string, value: Tag) =>
          this.tryFetch(`${baseUrl}/tag/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ value: value, id: id }),
          }),
        delete: (id: string) =>
          this.tryFetch(`${baseUrl}/tag/${id}`, {
            method: "DELETE",
            headers: this.getHeaders(),
          }),
      },
      tagSettings: {
        getImportTemplate: () => {
          return this.tryFetchBinary(
            `${baseUrl}/tag/import-template`
          );
        },
        importData: (data: ImportRequest) =>
          this.tryFetchJson(`${baseUrl}/tag/import`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        },
      activities: {
        get: () => this.tryFetchJson<Activity[]>(`${baseUrl}/activity`),
        objectsWithActivities: () =>
          this.tryFetchJson<ObjectWithActivitiesForUser[]>(
            `${baseUrl}/activity/objects`
          ),
        comment: async (id: string, module: string, message: string) => {
          const response = await this.tryFetch(`${baseUrl}/activity/comment`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({
              objectId: id,
              objectModule: module,
              message: message,
            }),
          });
          return response;
        },
        removeComment: async (objectId: string, commentId: string) => {
          const response = await this.tryFetch(
            `${baseUrl}/activity/object/${objectId}/comment/${commentId}`,
            {
              method: "DELETE",
              headers: this.getHeaders(),
            }
          );
          return response;
        },
        markAsRead: async (objectId: string) => {
          const response = await this.tryFetch(
            `${baseUrl}/activity/object/${objectId}/markasread`,
            {
              method: "POST",
              headers: this.getHeaders(),
            }
          );
          return response;
        },
        markAllAsRead: async () => {
          const response = await this.tryFetch(
            `${baseUrl}/activity/markallasread`,
            {
              method: "POST",
              headers: this.getHeaders(),
            }
          );
          return response;
        },
      },
      documentManagement: {
        get: () =>
          this.tryFetchJson<DocumentManagementDocument[]>(
            `${baseUrl}/documentmanagement`
          ),
        getById: (id: string) =>
          this.tryFetchJson<DocumentManagementDocument>(
            `${baseUrl}/documentmanagement/${id}`
          ),
        put: (data: DocumentManagementDocument) =>
          this.tryFetch(`${baseUrl}/documentmanagement`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: DocumentManagementDocument) =>
          this.tryFetch(`${baseUrl}/documentmanagement/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/documentmanagement/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetchJson(`${baseUrl}/documentmanagement/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        setInProcess: (data: SetDocumentInProcess) =>
          this.tryFetchJson(`${baseUrl}/documentmanagement/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        setActive: (data: SetDocumentActive) =>
          this.tryFetchJson(`${baseUrl}/documentmanagement/setactive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      report: {
        get: () => this.tryFetchJson<ReportTemplate[]>(`${baseUrl}/report`),
        getById: (id: string) =>
          this.tryFetchJson<ReportTemplate>(`${baseUrl}/report/${id}`),
      },
      reportDefinition: {
        get: () =>
          this.tryFetchJson<ReportDefinition[]>(`${baseUrl}/reportdefinition`),
        getById: (id: string) =>
          this.tryFetchJson<ReportDefinition>(
            `${baseUrl}/reportdefinition/${id}`
          ),
        put: (data: ReportDefinition) =>
          this.tryFetch(`${baseUrl}/reportdefinition`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: ReportDefinition) =>
          this.tryFetch(`${baseUrl}/reportdefinition/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/reportdefinition/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/reportdefinition/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        generate: (data: GenerateReportTemplate) =>
          this.tryFetch(`${baseUrl}/reportdefinition/generatereporttemplate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      reportDefinitionSettings: {
        get: () => 
          this.tryFetchJson<any>(`${baseUrl}/reportdefinition/settings`),
        post: (data: any) =>
          this.tryFetch(`${baseUrl}/reportdefinition/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      relationships: {
        edit: (data: BulkEdit) => 
          this.tryFetchJson(`${baseUrl}/relationship`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      }
    };
  })();

  facilitymanagement = (() => {
    const baseUrl = `${this.endpointUrl}/api/facilitymanagement`;
    return {
      taskRequest: {
        get: () => this.tryFetchJson<TaskRequest[]>(`${baseUrl}/taskrequest`),
        getById: (id: string) =>
          this.tryFetchJson<TaskRequest>(`${baseUrl}/taskrequest/${id}`),
        put: (data: TaskRequest) =>
          this.tryFetch(`${baseUrl}/taskrequest`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: TaskRequest) =>
          this.tryFetch(`${baseUrl}/taskrequest/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/taskrequest/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        accept: (data: AcceptTaskRequest) =>
          this.tryFetchJson(`${baseUrl}/taskrequest/accept`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/taskrequest/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        refuse: (data: RefuseTaskRequest) =>
          this.tryFetch(`${baseUrl}/taskrequest/refuse`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unrefuse: (data: UnrefuseTaskRequest) =>
          this.tryFetch(`${baseUrl}/taskrequest/unrefuse`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      taskRequestSettings: {
        get: () =>
          this.tryFetchJson<TaskRequestSettings>(
            `${baseUrl}/taskrequest/settings`
          ),
        post: (data: EditTaskRequestSettingsRule) =>
          this.tryFetch(`${baseUrl}/taskrequest/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      task: {
        get: () => this.tryFetchJson<Task[]>(`${baseUrl}/task`),
        getById: (id: string) =>
          this.tryFetchJson<Task>(`${baseUrl}/task/${id}`),
        put: (data: Task) =>
          this.tryFetch(`${baseUrl}/task`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Task) =>
          this.tryFetch(`${baseUrl}/task/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/task/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompleteTask) =>
          this.tryFetch(`${baseUrl}/task/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/task/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        verify: (data: VerifyTask) =>
          this.tryFetch(`${baseUrl}/task/verify`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        hold: (data: HoldTask) =>
          this.tryFetch(`${baseUrl}/task/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivateTask) =>
          this.tryFetch(`${baseUrl}/task/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        resetToDraft: (data: ResetTaskToDraft) =>
          this.tryFetch(`${baseUrl}/task/resettodraft`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnHoldTask) =>
          this.tryFetch(`${baseUrl}/task/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        editForm: (data: EditTaskForm) =>
          this.tryFetch(`${baseUrl}/task/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        amendActualDuration: (data: AmendActualDurationOfTask) => 
          this.tryFetch(`${baseUrl}/task/amendactualduration`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data)
          }),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/task/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/task/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      taskSettings: {
        get: () => this.tryFetchJson<TaskSettings>(`${baseUrl}/task/settings`),
        post: (data: EditTaskSettingsRule) =>
          this.tryFetch(`${baseUrl}/task/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      taskPriorityRule: {
        get: () =>
          this.tryFetchJson<TaskPriorityRule[]>(`${baseUrl}/task/settings/taskpriorityrule`),
        post: (data: EditTaskPriorityRule) =>
          this.tryFetch(`${baseUrl}/task/settings/taskpriorityrule`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      inspection: {
        get: () => this.tryFetchJson<Inspection[]>(`${baseUrl}/inspection`),
        getById: (id: string) =>
          this.tryFetchJson<Inspection>(`${baseUrl}/inspection/${id}`),
        put: (data: Inspection) =>
          this.tryFetch(`${baseUrl}/inspection`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Inspection) =>
          this.tryFetch(`${baseUrl}/inspection/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/inspection/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompleteInspection) =>
          this.tryFetch(`${baseUrl}/inspection/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/inspection/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        verify: (data: VerifyInspection) =>
          this.tryFetch(`${baseUrl}/inspection/verify`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        hold: (data: HoldInspection) =>
          this.tryFetch(`${baseUrl}/inspection/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivateInspection) =>
          this.tryFetch(`${baseUrl}/inspection/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        resetToDraft: (data: ResetInspectionToDraft) =>
          this.tryFetch(`${baseUrl}/inspection/resettodraft`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnHoldInspection) =>
          this.tryFetch(`${baseUrl}/inspection/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        editForm: (data: EditInspectionForm) =>
          this.tryFetch(`${baseUrl}/inspection/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        amendActualDuration: (data: AmendActualDurationOfInspection) => 
          this.tryFetch(`${baseUrl}/inspection/amendactualduration`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data)
          }),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/inspection/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/inspection/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      inspectionSettings: {
        get: () =>
          this.tryFetchJson<InspectionSettings>(`${baseUrl}/inspection/settings`),
        post: (data: EditInspectionSettings) =>
          this.tryFetch(`${baseUrl}/inspection/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      maintenance: {
        get: () => this.tryFetchJson<Maintenance[]>(`${baseUrl}/maintenance`),
        getById: (id: string) =>
          this.tryFetchJson<Maintenance>(`${baseUrl}/maintenance/${id}`),
        put: (data: Maintenance) =>
          this.tryFetch(`${baseUrl}/maintenance`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Maintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/maintenance/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompleteMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/maintenance/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        verify: (data: VerifyMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/verify`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        hold: (data: HoldMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivateMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        resetToDraft: (data: ResetMaintenanceToDraft) =>
          this.tryFetch(`${baseUrl}/maintenance/resettodraft`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnHoldMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        editForm: (data: EditMaintenanceForm) =>
          this.tryFetch(`${baseUrl}/maintenance/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        amendActualDuration: (data: AmendActualDurationOfMaintenance) =>
          this.tryFetch(`${baseUrl}/maintenance/amendactualduration`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data)
          }),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/maintenance/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/maintenance/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      maintenanceSettings: {
        get: () =>
          this.tryFetchJson<MaintenanceSettings>(
            `${baseUrl}/maintenance/settings`
          ),
        post: (data: EditMaintenanceSettings) =>
          this.tryFetch(`${baseUrl}/maintenance/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      planning: {
        planning: async (start: string, end: string) => {
          const response = await this.tryFetchJson<PlanningMoment[]>(
            `${baseUrl}/planning?start=${start}&end=${end}`
          );
          return response;
        },
        post: (data: PlanningMoment) =>
          this.tryFetch(`${baseUrl}/planning`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        delete: (id: any, assigneeId: any) =>
          this.tryFetch(`${baseUrl}/planning/${id}/${assigneeId}`, {
            method: "DELETE",
            headers: this.getHeaders(),
          }),
        revise: (data: RevisePlanning) =>
          this.tryFetch(`${baseUrl}/planning/${data.id}/revise`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      activityEntry: {
        get: () =>
          this.tryFetchJson<ActivityEntry[]>(`${baseUrl}/activityentry`),
        getById: (id: string) =>
          this.tryFetchJson<ActivityEntry>(`${baseUrl}/activityentry/${id}`),
        put: (data: ActivityEntry) =>
          this.tryFetch(`${baseUrl}/activityentry`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: ActivityEntry) =>
          this.tryFetch(`${baseUrl}/activityentry/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/activityentry/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/activityentry/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
      },
      project: {
        get: () => this.tryFetchJson<Project[]>(`${baseUrl}/project`),
        getById: (id: string) =>
          this.tryFetchJson<Project>(`${baseUrl}/project/${id}`),
        put: (data: Project) =>
          this.tryFetch(`${baseUrl}/project`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Project) =>
          this.tryFetch(`${baseUrl}/project/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        saveGantt: (
          id: string,
          data: {
            id: string;
            module: OriginModuleEnum;
            gantt: GanttDefinition;
            tenantId: string;
          }
        ) =>
          this.tryFetch(`${baseUrl}/project/${id}/updategantt`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/project/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompleteProject) =>
          this.tryFetch(`${baseUrl}/project/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/project/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        hold: (data: HoldProject) =>
          this.tryFetch(`${baseUrl}/project/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivateProject) =>
          this.tryFetch(`${baseUrl}/project/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnHoldProject) =>
          this.tryFetch(`${baseUrl}/project/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      projectSettings: {
        get: () =>
          this.tryFetchJson<ProjectSettings>(`${baseUrl}/project/settings`),
        post: (data: EditProjectSettingsRule) =>
          this.tryFetch(`${baseUrl}/project/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      form: {
        get: () => this.tryFetchJson<Form[]>(`${baseUrl}/form`),
        getById: (id: string) =>
          this.tryFetchJson<Form>(`${baseUrl}/form/${id}`),
        put: (data: Form) =>
          this.tryFetch(`${baseUrl}/form`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Form) =>
          this.tryFetch(`${baseUrl}/form/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/form/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetchJson(`${baseUrl}/form/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        setInProcess: (data: SetFormInProcess) =>
          this.tryFetchJson(`${baseUrl}/form/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        setActive: (data: SetFormActive) =>
          this.tryFetchJson(`${baseUrl}/form/setactive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        exportForm: (id: string) => this.tryFetchJson<string>(`${baseUrl}/form/export-form/${id}`),
      },
      periodical: {
        get: () => this.tryFetchJson<Periodical[]>(`${baseUrl}/periodical`),
        getById: (id: string) =>
          this.tryFetchJson<Periodical>(`${baseUrl}/periodical/${id}`),
        put: (data: Periodical) =>
          this.tryFetch(`${baseUrl}/periodical`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Periodical) =>
          this.tryFetch(`${baseUrl}/periodical/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/periodical/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetchJson(`${baseUrl}/periodical/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompletePeriodical) =>
          this.tryFetch(`${baseUrl}/periodical/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        hold: (data: HoldPeriodical) =>
          this.tryFetch(`${baseUrl}/periodical/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivatePeriodical) =>
          this.tryFetch(`${baseUrl}/periodical/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        resetToDraft: (data: ResetPeriodicalToDraft) =>
          this.tryFetch(`${baseUrl}/periodical/resettodraft`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnholdPeriodical) =>
          this.tryFetch(`${baseUrl}/periodical/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      templates: {
        get: () =>
          this.tryFetchJson<Template>(`${baseUrl}/masterdata/template`),
        post: (data: Template) =>
          this.tryFetch(`${baseUrl}/masterdata/template`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
    };
  })();

  safety = (() => {
    const baseUrl = `${this.endpointUrl}/api/safety`;
    return {
      recommendation: {
        get: () =>
          this.tryFetchJson<Recommendation[]>(`${baseUrl}/recommendation`),
        getById: (id: string) =>
          this.tryFetchJson<Recommendation>(`${baseUrl}/recommendation/${id}`),
        put: (data: Recommendation) =>
          this.tryFetch(`${baseUrl}/recommendation`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Recommendation) =>
          this.tryFetch(`${baseUrl}/recommendation/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/recommendation/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/recommendation/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
        request: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/request`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        finalize: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/finalize`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInTreatment: (data: SetRecommendationInTreatment) =>
          this.tryFetch(`${baseUrl}/recommendation/setintreatment`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
          setInProcess: (data: SetRecommendationInProcess) =>
          this.tryFetch(`${baseUrl}/recommendation/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        accept: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/accept`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        refuse: (data: RefuseRecommendation) =>
          this.tryFetch(`${baseUrl}/recommendation/refuse`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        sign: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/recommendation/sign`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        exportForm: (id: string) => this.tryFetchJson<string>(`${baseUrl}/recommendation/export-form/${id}`),
      },
      recommendationSettings: {
        get: () =>
          this.tryFetchJson<RecommendationSettings>(
            `${baseUrl}/recommendation/settings`
          ),
        post: (data: EditRecommendationSettingsRule) =>
          this.tryFetch(`${baseUrl}/recommendation/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      incident: {
        get: () => this.tryFetchJson<Incident[]>(`${baseUrl}/incident`),
        getById: (id: string) =>
          this.tryFetchJson<Incident>(`${baseUrl}/incident/${id}`),
        put: (data: Incident) =>
          this.tryFetch(`${baseUrl}/incident`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Incident) =>
          this.tryFetch(`${baseUrl}/incident/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/incident/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/incident/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        complete: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/incident/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        toInvestigate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/incident/settoinvestigate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        getseriousinjuryranges: () =>
          this.tryFetchJson<SeriousIncidentRange[]>(
            `${baseUrl}/seriousinjuryranges`
          ),
        getInventory: () =>
          this.tryFetchBinary(
            `${baseUrl}/incident/report/inventory`
          ),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/incident/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/incident/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      multiYearPlan: {
        get: () =>
          this.tryFetchJson<MultiYearPlan[]>(`${baseUrl}/multiyearplan`),
        getById: (id: string) =>
          this.tryFetchJson<MultiYearPlan>(`${baseUrl}/multiyearplan/${id}`),
        put: (data: MultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: MultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/multiyearplan/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/multiyearplan/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        plan: (data: PlanMultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/plan`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unplan: (data: UnPlanMultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/unplan`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        replan: (data: RePlanMultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/replan`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        complete: (data: CompleteMultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        close: (data: CloseMultiYearPlan) =>
          this.tryFetch(`${baseUrl}/multiyearplan/close`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      multiYearPlanSettings: {},
      incidentSettings: {
        get: () =>
          this.tryFetchJson<IncidentSettings>(`${baseUrl}/incident/settings`),
        post: (data: EditIncidentSettingsRule) =>
          this.tryFetch(`${baseUrl}/incident/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      safetyInstructionCard: {
        get: () =>
          this.tryFetchJson<SafetyInstructionCard[]>(
            `${baseUrl}/safetyinstructioncard`
          ),
        getById: (id: string) =>
          this.tryFetchJson<SafetyInstructionCard>(
            `${baseUrl}/safetyinstructioncard/${id}`
          ),
        put: (data: SafetyInstructionCard) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: SafetyInstructionCard) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        finalize: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/finalize`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        sign: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/sign`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInProcess: (data: SetSafetyInstructionCardInProcess) => 
          this.tryFetch(`${baseUrl}/safetyinstructioncard/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        deactivate: (data: DeactivateSafetyInstructionCard) => 
          this.tryFetch(`${baseUrl}/safetyinstructioncard/deactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        exportForm: (id: string) => this.tryFetchJson<string>(`${baseUrl}/safetyinstructioncard/export-form/${id}`),
      },
      safetyInstructionCardSettings: {
        get: () =>
          this.tryFetchJson<SafetyInstructionCardSettings>(
            `${baseUrl}/safetyinstructioncard/settings`
          ),
        post: (data: EditSafetyInstructionCardSettingsRule) =>
          this.tryFetch(`${baseUrl}/safetyinstructioncard/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      instructionCard: {
        get: () =>
          this.tryFetchJson<InstructionCard[]>(`${baseUrl}/instructioncard`),
        getById: (id: string) =>
          this.tryFetchJson<InstructionCard>(
            `${baseUrl}/instructioncard/${id}`
          ),
        put: (data: InstructionCard) =>
          this.tryFetch(`${baseUrl}/instructioncard`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: InstructionCard) =>
          this.tryFetch(`${baseUrl}/instructioncard/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/instructioncard/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/instructioncard/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        finalize: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/instructioncard/finalize`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        sign: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/instructioncard/sign`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInProcess: (data: SetInstructionCardInProcess) => 
          this.tryFetch(`${baseUrl}/instructioncard/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        deactivate: (data: DeactivateInstructionCard) => 
          this.tryFetch(`${baseUrl}/instructioncard/deactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        exportForm: (id: string) => this.tryFetchJson<string>(`${baseUrl}/instructioncard/export-form/${id}`),
      },
      instructionCardSettings: {
        get: () =>
          this.tryFetchJson<InstructionCardSettings>(
            `${baseUrl}/instructioncard/settings`
          ),
        post: (data: EditInstructionCardSettingsRule) =>
          this.tryFetch(`${baseUrl}/instructioncard/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      commissioning: {
        get: () =>
          this.tryFetchJson<Commissioning[]>(`${baseUrl}/commissioning`),
        getById: (id: string) =>
          this.tryFetchJson<Commissioning>(`${baseUrl}/commissioning/${id}`),
        put: (data: Commissioning) =>
          this.tryFetch(`${baseUrl}/commissioning`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Commissioning) =>
          this.tryFetch(`${baseUrl}/commissioning/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/commissioning/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/commissioning/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        editForm: (data: EditCommissioningForm) =>
          this.tryFetch(`${baseUrl}/commissioning/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        finalize: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/commissioning/finalize`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        sign: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/commissioning/sign`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInProcess: (data: SetCommissioningInProcess) => 
          this.tryFetch(`${baseUrl}/commissioning/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      commissioningSettings: {
        get: () =>
          this.tryFetchJson<CommissioningSettings>(
            `${baseUrl}/commissioning/settings`
          ),
        post: (data: EditCommissioningSettingsRule) =>
          this.tryFetch(`${baseUrl}/commissioning/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      decommissioning: {
        get: () =>
          this.tryFetchJson<Decommissioning[]>(`${baseUrl}/decommissioning`),
        getById: (id: string) =>
          this.tryFetchJson<Decommissioning>(`${baseUrl}/decommissioning/${id}`),
        put: (data: Decommissioning) =>
          this.tryFetch(`${baseUrl}/decommissioning`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: Decommissioning) =>
          this.tryFetch(`${baseUrl}/decommissioning/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/decommissioning/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/decommissioning/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        editForm: (data: EditDecommissioningForm) =>
          this.tryFetch(`${baseUrl}/decommissioning/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        finalize: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/decommissioning/finalize`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        sign: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/decommissioning/sign`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids: ids }),
          }),
        setInProcess: (data: SetDecommissioningInProcess) => 
          this.tryFetch(`${baseUrl}/decommissioning/setinprocess`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      decommissioningSettings: {
        get: () =>
          this.tryFetchJson<DecommissioningSettings>(
            `${baseUrl}/decommissioning/settings`
          ),
        post: (data: EditDecommissioningSettingsRule) =>
          this.tryFetch(`${baseUrl}/decommissioning/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
      riskAnalysis: {
        get: () => this.tryFetchJson<RiskAnalysis[]>(`${baseUrl}/riskanalysis`),
        getById: (id: string) =>
          this.tryFetchJson<RiskAnalysis>(`${baseUrl}/riskanalysis/${id}`),
        put: (data: RiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        post: (id: string, data: RiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/${id}`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        archive: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/riskanalysis/archive`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        complete: (data: CompleteRiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/complete`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        reactivate: (ids: string[]) =>
          this.tryFetch(`${baseUrl}/riskanalysis/reactivate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify({ ids }),
          }),
        verify: (data: VerifyRiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/verify`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        hold: (data: HoldRiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/hold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        activate: (data: ActivateRiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/activate`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        resetToDraft: (data: ResetRiskAnalysisToDraft) =>
          this.tryFetch(`${baseUrl}/riskanalysis/resettodraft`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        unhold: (data: UnHoldRiskAnalysis) =>
          this.tryFetch(`${baseUrl}/riskanalysis/unhold`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        editForm: (data: EditRiskAnalysisForm) =>
          this.tryFetch(`${baseUrl}/riskanalysis/edit-form`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
        amendActualDuration: (data: AmendActualDurationOfRiskAnalysis) => 
          this.tryFetch(`${baseUrl}/riskanalysis/amendactualduration`, {
            method: "PUT",
            headers: this.getHeaders(),
            body: JSON.stringify(data)
          }),
        sendMail: (
          id: string,
          data: MailMessage,
          returnRawResponse?: boolean | undefined
        ) =>
          this.tryFetch(
            `${baseUrl}/riskanalysis/${id}/mail`,
            {
              method: "POST",
              headers: this.getHeaders(),
              body: JSON.stringify(data),
            },
            returnRawResponse
          ),
        getMail: (id: string, mailId: string) =>
          this.tryFetchJson<MailMessage>(
            `${baseUrl}/riskanalysis/${id}/mail/${mailId}`,
            {
              method: "GET",
              headers: this.getHeaders(),
            }
          ),
      },
      riskAnalysisSettings: {
        get: () =>
          this.tryFetchJson<RiskAnalysisSettings>(
            `${baseUrl}/riskanalysis/settings`
          ),
        post: (data: EditRiskAnalysisSettings) =>
          this.tryFetch(`${baseUrl}/riskanalysis/settings`, {
            method: "POST",
            headers: this.getHeaders(),
            body: JSON.stringify(data),
          }),
      },
    };
  })();
}
export default new ApiClient();
