import React from 'react';
import DarkTheme from 'themes/DarkTheme';
import LightTheme from 'themes/LightTheme';
import arxs from 'infra/arxs';

export const themes = [
  ["lightTheme", () => <LightTheme />],
  ["darkTheme", () => <DarkTheme />]
];

const _localStoreKey = 'Theme';

const createThemeState = (setState, index) => {
  if (index === null || index === undefined) {
    const persistedTheme = window.localStorage.getItem(_localStoreKey);
    const initialIndex = persistedTheme ? Math.max(themes.findIndex(x => x[0] === persistedTheme), 0) : 0;
    index = initialIndex;
  }

  let themeRow = themes[index];
  let nextTheme = themeRow[1]();
  window.localStorage.setItem(_localStoreKey, themeRow[0]);

  var state = {
    index: index,
    setIndex: (newIndex) => {
      setState(createThemeState(setState, newIndex));
    },
    getVariable: (name) => {
      let styleName = name;
      let value = themeRow[1][styleName];
      let i = 1;
      while (value && value.startsWith("--arxs-") && i < 10) {
        value = themeRow[1][styleName];
        i++;
      };
      if (i > 9) {
        arxs.logger.error(
          "Couldn't set {style} style variable since it blew the stack."
          , styleName
        );
      } else {
        return value;
      };
    },
    component: nextTheme
  };
  return state;
};
export default createThemeState; 