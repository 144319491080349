import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet'
import Toaster from 'components/util/Toaster';

import './UnexpirePopup.scss';
import { OriginModuleEnum } from 'infra/api/contracts';

export const createUnexpirePopup = (value, onSubmit, onCancel, module) => {
  const state = {
    title: arxs.t("actions.unexpire.title"),
    content: <UnexpirePopup value={value} onSubmit={onSubmit} onCancel={onCancel} module={module} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
    onSubmit: onSubmit,
  };

  return state;
};

function UnexpirePopup(props) {
  const [data, setData] = useState({});

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => {
    if (props.onSubmit) {
      if ((!data.expirationDate && !data.notificationDate)) {
        props.onSubmit({ ids: props.value });
      } else if ((data.expirationDate && data.expirationDate > new Date())
        && (data.notificationDate && data.notificationDate > new Date()
          && data.notificationDate < data.expirationDate)) {
        props.onSubmit({ ids: props.value, ...data });
      } else {
        Toaster.error(arxs.t('actions.unexpire.error'));
      }
    } else {
      Toaster.error(arxs.t('actions.unexpire.error'));
    }
  }

  const rows = [
    [
      {
        name: "expirationDate",
      },
      {
        name: "notificationDate",
      }
    ]
  ];

  const getschemaName = () => {
    switch (props.module) {
      case OriginModuleEnum.Labourmeans: return "UnexpireLabourmean";;
      case OriginModuleEnum.EquipmentInstallation: return "UnexpireEquipment";
      case OriginModuleEnum.HazardousSubstance: return "UnexpireHazardousSubstance";
      case OriginModuleEnum.IntangibleAsset: return "UnexpireIntangibleAsset";
      case OriginModuleEnum.Pbm: return "UnexpireProtectionEquipment"
      default: return;
    }
  }

  return <div className={`unexpire-asset-popup ${props.className || ""}`}>
    <div className="unexpire-asset-popup-body">
      <FieldSet schemaName={getschemaName()} rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`unexpire-asset-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`unexpire-asset-confirm`} className="icon" onClick={confirm}>{arxs.t("common.complete")}</Button>
      </div>
    </div>
  </div>;
}