import React, { useState } from 'react';
import { Spinner } from 'components/animations/Spinner';

import './Button.scss';

function Button(props) {
  const { disabled, className, title, children, onClick } = props;
  const disableOnClick = props["disable-on-click"];

  const [hasClicked, setHasClicked] = useState(false);

  let handleClick = onClick;

  if (disableOnClick === true) {
    handleClick = () => {
      setHasClicked(true);
      return onClick()
        .then(() => setHasClicked(false))
        .catch(() => setHasClicked(false));
    };
  }

  return (
    <button disabled={disabled || (disableOnClick && hasClicked)} className={`button ${className || ""}`} onClick={handleClick} title={title}>
      {disableOnClick && hasClicked ? <Spinner /> : children}
    </button>
  );
}
export default Button;