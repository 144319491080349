import React from "react";

import { CardClassProps } from "components/card/CardContracts";

export default function CardCategorization(props: CardClassProps) {
    const { card } = props;

    const categories = [card.sort, card.category, card.kind, card.type].filter(x => x);

    if (categories.length === 0) {
        return <></>;
    }

    return <div>{categories.join(" - ")}</div>;
}