import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';
import {RelationshipType } from "infra/api/contracts";

class ReportDefinitionBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(card.kind, stateProxy.getter("codeElementsById")),
      type: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      responsible: this.getEmployeeValue(card.responsible, stateProxy.getter("employeeMap")),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.kind,
      card.type,
      card.uniqueNumber,
      card.title,
      card.description,
      card.responsible,
      card.coResponsibles,
      ...(card.tags || []).map(x => x.name)
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.reportDefinitions) {
            lookups.pristine = lookups.reportDefinitions
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.reportDefinitions;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
        });
  }
}
export default new ReportDefinitionBoard();