import React, { Component } from 'react';
import FolderButton from './FolderButton';
import './FolderButtonStrip.scss';

export default class FolderButtonStrip extends Component {
  handleClick = (item) => {
    if (this.props["onChange"]) {
      this.props.onChange(item);
    }
  }

  render() {
    return <div className={`folder-button-strip`}>
      {this.props.items.slice(0).reverse()
        .map((item, i) => <FolderButton key={`folder-button-strip-${i}`} item={item} active={this.props.active === item.label} onClick={this.handleClick} />)}
    </div>;
  }
}