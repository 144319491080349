import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { RelationshipType } from "infra/api/contracts";

class CommissioningBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const subject = card.subject ? arxs.Api.lookups.resolveSubject(card.subject) : {};

    return {
      ...card,
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      createdAt: card.createdAt && new Date(card.createdAt),
      subject_uniqueNumber: subject.uniqueNumber,
      sortId: subject.sort && subject.sort.id,
      kindId: subject.kind && subject.kind.id,
      typeId: subject.type && subject.type.id,
      sort: subject.sort,
      kind: subject.kind,
      type: subject.type,
      subject_sort: this.getCodeElementValue(
        subject.sort,
        stateProxy.getter("codeElementsById")
      ),
      subject_kind: this.getCodeElementValue(
        subject.kind,
        stateProxy.getter("codeElementsById")
      ),
      subject_type: this.getCodeElementValue(
        subject.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructure: subject && this.getLookupValue(stateProxy.getter("legalStructureMap"), subject.legalStructure),
      branch: subject && this.getLookupValue(stateProxy.getter("branchMap"), subject.branch),
      building: subject && this.getLookupValue(stateProxy.getter("buildingMap"), subject.building),
      location: subject && this.getLookupValue(stateProxy.getter("locationMap"), subject.location),
      subject_internalNumber: subject.internalNumber 
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.title,
      card.description,
      card.subject_uniqueNumber,
      card.subject_sort,
      card.subject_kind,
      card.subject_type,
      card.responsible,
      card.subject_internalNumber,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    const lookups = arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.commissionings) {
        lookups.pristine = lookups.commissionings
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.commissionings;
      }
      stateProxy.setter(lookups);
    });

    return Promise.resolve({ lookups });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Commissioning yet");
  };
}
export default new CommissioningBoard();
