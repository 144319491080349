import posthog from "posthog-js";

import arxs, { ArXsState, Profile } from 'infra/arxs';
import { OriginModuleEnum } from "infra/api/contracts";

import ModuleMetadata from 'modules/ModuleMetadata';

import getLabourMeansMetadata from 'modules/assetmanagement/labourmeans/LabourMeansMetadata';
import getEquipmentMetadata from 'modules/assetmanagement/equipment/EquipmentMetadata';
import getProtectionEquipmentMetadata from 'modules/assetmanagement/protectionequipment/ProtectionEquipmentMetadata';
import getHazardousSubstanceMetadata from 'modules/assetmanagement/hazardoussubstance/HazardousSubstanceMetadata';
import getTaskRequestMetadata from 'modules/facilitymanagement/taskrequests/TaskRequestMetadata';
import getTaskMetadata from 'modules/facilitymanagement/tasks/TaskMetadata';
import getMaintenanceMetadata from 'modules/facilitymanagement/maintenances/MaintenanceMetadata';
import getInspectionMetadata from 'modules/facilitymanagement/inspections/InspectionMetadata';
import getLegalStructureMetaData from 'modules/organisation/legalstructure/LegalStructureMetadata';
import getBranchMetaData from 'modules/organisation/branch/BranchMetadata';
import getBuildingMetaData from 'modules/assetmanagement/building/BuildingMetaData';
import getLocationMetaData from "modules/assetmanagement/location/LocationMetaData";
import getEmployeeMetaData from "modules/organisation/employee/EmployeeMetaData";
import getTrustMetaData from "modules/organisation/trust/TrustMetaData";
import getActivityEntryMetaData from "modules/facilitymanagement/activityentries/ActivityEntryMetaData";
import getContactMetaData from "modules/organisation/contacts/ContactMetaData";
import getIncidentMetaData from "modules/safety/incidents/IncidentMetaData";
import getDocumentManagementMetaData from "modules/shared/DocumentManagement/DocumentManagementMetaData";
import getProjectMetadata from "modules/facilitymanagement/projects/ProjectMetadata";
import getCombinedInstallationMetadata from "modules/assetmanagement/combinedinstallation/CombinedInstallationMetadata";
import getSupplierMetadata from "modules/organisation/supplier/SupplierMetaData";
import getReportDefinitionMetadata from "modules/shared/ReportDefinition/ReportDefinitionMetadata";
import getIntangibleAssetMetadata from "modules/assetmanagement/intangibleAsset/IntangibleAssetMetadata";
import getFormMetadata from "modules/shared/Form/FormMetadata";
import getPeriodicalMetadata from 'modules/facilitymanagement/periodicals/PeriodicalMetaData';
import getRecommendationMetadata from 'modules/safety/recommendation/RecommendationMetaData';
import getMultiYearPlanMetadata from 'modules/safety/multiyearplan/MultiYearPlanMetaData';
import getInstructionCardMetadata from './safety/instructioncard/InstructionCardMetadata';
import getCommissioningMetadata from './safety/commissioning/CommissioningMetadata';
import getSafetyInstructionCardMetadata from './safety/safetyinstructioncard/SafetyInstructionCardMetadata';
import getRiskAnalysisMetadata from './safety/riskanalysis/RiskAnalysisMetadata';
import getDecommissioningMetadata from './safety/decommissioning/DecommissioningMetadata';
import getPlanningMetadata from "./planning/PlanningMetadata";
import getUserRoleMetadata from "./configuration/RoleManagement/UserRoleMetadata";
import getGeoLocationMetadata from "./shared/GeoLocation/GeoLocationMetadata";
import getTagMetadata from "./configuration/TagManagement/TagMetadata"

export default class ModuleMetadataRegistry {
  metadatas: ModuleMetadata[] = []
  metadataMap: { [key: string]: ModuleMetadata } = {}
  moduleToActionMap: { [key: string]: string } = {}
  moduleToPathMap: { [key: string]: string } = {}
  supportedModules: OriginModuleEnum[] = []

  initialize = (arxs: ArXsState) => {
    this.metadatas = [
      getLabourMeansMetadata(arxs),
      getEquipmentMetadata(arxs),
      getProtectionEquipmentMetadata(arxs),
      getHazardousSubstanceMetadata(arxs),
      getTaskRequestMetadata(arxs),
      getTaskMetadata(arxs),
      getMaintenanceMetadata(arxs),
      getInspectionMetadata(arxs),
      getLegalStructureMetaData(arxs),
      getBranchMetaData(arxs),
      getBuildingMetaData(arxs),
      getLocationMetaData(arxs),
      getEmployeeMetaData(arxs),
      getTrustMetaData(arxs),
      getActivityEntryMetaData(arxs),
      getContactMetaData(arxs),
      getIncidentMetaData(arxs),
      getDocumentManagementMetaData(arxs),
      getProjectMetadata(arxs),
      getCombinedInstallationMetadata(arxs),
      getSupplierMetadata(arxs),
      getReportDefinitionMetadata(arxs),
      getIntangibleAssetMetadata(arxs),
      getFormMetadata(arxs),
      getPeriodicalMetadata(arxs),
      getRecommendationMetadata(arxs),
      getMultiYearPlanMetadata(arxs),
      getInstructionCardMetadata(arxs),
      getSafetyInstructionCardMetadata(arxs),
      getCommissioningMetadata(arxs),
      getRiskAnalysisMetadata(arxs),
      getDecommissioningMetadata(arxs),
      getPlanningMetadata(arxs),
      getUserRoleMetadata(arxs),
      getGeoLocationMetadata(arxs),
      getTagMetadata(arxs)
    ].map(meta => ({
      ...meta,
      actions: (meta.actions || []).map((x) => ({
        ...x,
        onClick: (state) => {
          posthog.capture(`action:${x.name}`, { module: x.module });
          x.onClick(state);
        }
      }))
    }));
    this.metadataMap = this.metadatas.toDictionary(x => x.module)

    this.moduleToActionMap = [
      [OriginModuleEnum.Planning, "Planning.Read"],
      [OriginModuleEnum.Training, "Training.Read"],
      [OriginModuleEnum.Student, "Student.Read"],
      [OriginModuleEnum.Tag, "Tag.Read"],

      ...this.metadatas
        // We always allow to query employees, you just get the minimal required info to reference them in a lookup
        .filter(x => x.module !== OriginModuleEnum.Employee)
        .map(x => [x.module, x.base.readAction])
    ]
    .reduce((acc: any, [module, path]) => { acc[module] = path; return acc; }, {});

    this.moduleToPathMap = [
      [OriginModuleEnum.Planning, "/planning"],
      [OriginModuleEnum.Training, "/training"],
      [OriginModuleEnum.Student, "/student"],
      [OriginModuleEnum.Tag, "/tag"],

      ...this.metadatas.map(x => [x.module, x.path])
    ]
    .reduce((acc: any, [module, path]) => { acc[module] = path; return acc; }, {});

    this.supportedModules = this.metadatas.map(x => x.module as OriginModuleEnum);
  }

  getResourceByModule = (module: string | OriginModuleEnum) => {
    const meta = arxs.moduleMetadataRegistry.get(module);
    return meta.base.getResource();
  }
  
  isModuleAllowed = (module: string | OriginModuleEnum): boolean => {
    const profile = arxs.Identity.profile as Profile;
    if (!profile) {
      return false;
    }
    const actionName = this.moduleToActionMap[module];
    return !actionName || profile.allowedActions[actionName];
  }

  getModulePath = (module: string | OriginModuleEnum) => {
    return this.moduleToPathMap[module];
  }

  getAll = (): ModuleMetadata[] => this.metadatas;

  get = (module: string): ModuleMetadata => {
    const metadata = this.metadataMap[module];

    if (!metadata) {
      console.log("Module {module} not supported.", JSON.stringify(module));

      return {
        module,
        title: `Unsupported module ${module}: remember to add module to ModuleMetadataRegistry`,
        icon: "",
        path: "/",
        base: {
          name: "",
          route: "",
          getResource: () => ({
            getById: () => new Promise(() => { }),
            get: () => new Promise(() => { }),
            //patch: () => new Promise(() => { }),
            post: () => new Promise(() => { })
          }),
          subscribe: (id, module, handler) => { },
          readAction: "",
          writeAction: ""
        },
        detailspane: [],
        allowedDocumentTypes: [],
        statuses: [],
        actions: [],
        wizard: {
          steps: [],
          lookups: {},
          addNewState: "",
          wizardClass: {}
        },
        board: {
          lookups: {},
          gridColums: [],
          cardProps: {},
          boardClass: {
            loadData: () => new Promise((resolve, reject) => { }),
            getFullExportActions: () => [],
          },
          bucketConfig: [],
          additionalStatuses: []
        },
        canSubscribe: false
      };
    }

    return metadata;
  }
}