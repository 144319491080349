// LEAVE THIS! I know it seems obsolete, but this prevents a compiler error:
// ts(1228) all files must be modules when the isolatedmodules flag is true
export { }

// This is necessary so typescript realizes we want to extend the Array prototype
declare global {
    interface SelectorFunction<T, R> {
        (item: T, index?: number | undefined): R
    }

    interface BinaryFunction<T1, T2, R> {
        (item1: T1, item2: T2, index?: number | undefined): R
    }

    interface NumberSelectorFunction<T> extends SelectorFunction<T, number> { }
    interface StringSelectorFunction<T> extends SelectorFunction<T, string> { }
    interface BooleanSelectorFunction<T> extends SelectorFunction<T, boolean> { }
    interface MapFunction<T> extends SelectorFunction<T, T> { }

    interface Array<T> {
        any(predicate?: BooleanSelectorFunction<T>): boolean,
        all(predicate?: BooleanSelectorFunction<T>): boolean,
        sum(selector?: NumberSelectorFunction<T>): number,
        min(selector?: NumberSelectorFunction<T>): number,
        max(selector?: NumberSelectorFunction<T>): number,
        average(selector?: NumberSelectorFunction<T>): number,
        contains(item: any): boolean,
        take(count: number): T[],
        replace(original: T, modified: T, comparator?: BinaryFunction<T, T, boolean>): T[],
        replaceOrAppend(needle: T, comparator: BinaryFunction<T, T, boolean>): T[],
        toggle(needle: T, comparator: BinaryFunction<T, T, boolean>): T[],
        remove(original: T): T[],
        zip(other: T[], mapper: BinaryFunction<T, any, any>): any[],
        traverse(selector: SelectorFunction<T, any>): T[],
        groupBy(keySelector: StringSelectorFunction<T>, valueSelector?: SelectorFunction<T, any>): { [key: string]: Array<any> },
        orderBy(valueSelector?: SelectorFunction<T, any>): T[],
        orderByDescending(valueSelector?: NumberSelectorFunction<T>): T[],
        toDictionary<R>(keySelector: StringSelectorFunction<T>, valueSelector?: SelectorFunction<T, R>): { [key: string]: R },
        partition(itemsPerPartition: number): T[][],
        except(items: T[]): T[],
        distinct(keySelector?: StringSelectorFunction<T>): T[],
        moveItem(from: number, to: number): T[],
        intersect(items: T[]): T[],
        coalesce(items: T[]): T[],
        sequenceEquals(items: number[] | string[]): boolean,
        valueEquals(items: T[]): boolean,
        coalesceIfEmpty(others: T[]): T[],
        firstOrDefault(predicate?: BooleanSelectorFunction<T>): T | null,
        interleave(item: T | MapFunction<T>): T[],
        joinNonEmpty(delimiter: string): string,
        overlay(other: T[], mapper: SelectorFunction<T, string>): T[],
        indexOfFirstMatch(predicate: BooleanSelectorFunction<T>): number;
    }

    interface ObjectConstructor {
        areKeysEqual(left: any, right: any): boolean
    }
}

window.Array.prototype.sum = function (selector) {
    let result = 0;
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        const value = selector ? selector(item) : item;
        result += value;
    }
    return result;
};

window.Array.prototype.min = function (selector) {
    let result = Infinity;
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        const value = selector ? selector(item) : item;
        result = Math.min(result, value);
    }
    return result;
};

window.Array.prototype.max = function (selector) {
    let result = -Infinity;
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        const value = selector ? selector(item) : item;
        result = Math.max(result, value);
    }
    return result;
};

window.Array.prototype.average = function (selector) {
    let result = 0;
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        const value = selector ? selector(item) : item;
        result += value;
    }
    return result / this.length;
};

window.Array.prototype.contains = function (item) {
    return this.indexOf(item) > -1;
};

window.Array.prototype.take = function (count) {
    return this.flatMap((x, i) => i < count ? [x] : []);
};

window.Array.prototype.replaceOrAppend = function (needle, comparator) {
    const index = this.findIndex(x => comparator(x, needle));
    if (index === -1) {
        return this.concat([needle]);
    }
    const result = this.slice(0, index)
        .concat([needle])
        .concat(this.slice(index + 1));
    return result;
};


window.Array.prototype.toggle = function (needle, comparator) {
    const index = this.findIndex(x => comparator(x, needle));
    if (index === -1) {
        return this.concat([needle]);
    }
    const result = this.slice(0, index)
        .concat(this.slice(index + 1));
    return result;
};

window.Array.prototype.replace = function (original, modified, comparator) {
    const index = comparator ? this.findIndex(x => comparator(x, modified)) : this.indexOf(original);
    const result = this.slice(0, index)
        .concat([modified])
        .concat(this.slice(index + 1));
    return result;
};

window.Array.prototype.remove = function (original) {
    const index = this.indexOf(original);
    const result = this.slice(0, index)
        .concat(this.slice(index + 1));
    return result;
};

window.Array.prototype.zip = function (other, mapper) {
    const result = [];
    const max = Math.max(this.length, other.length);
    for (let i = 0; i < max; i++) {
        const item1 = this[i];
        const item2 = other[i];
        result.push(mapper(item1, item2));
    }
    return result;
};

window.Array.prototype.traverse = function (selector) {
    const result = [];
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        result.push(item);
        const children = selector(item);
        if (children) {
            for (var x of children.traverse(selector)) {
                result.push(x);
            }
        }
    }
    return result;
};

window.Array.prototype.any = function (predicate) {
    if (!predicate) {
        return this.length > 0;
    }
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        if (predicate(item)) return true;
    }
    return false;
};

window.Array.prototype.all = function (predicate) {
    if (!predicate) {
        return false;
    }
    for (var i = 0; i < this.length; ++i) {
        const item = this[i];
        if (!predicate(item)) return false;
    }
    return true;
};

window.Array.prototype.groupBy = function (keySelector, valueSelector) {
    var result: any = {};
    for (var i = 0; i < this.length; ++i) {
        var item = this[i];
        var key = keySelector(item);
        if (!(key in result)) result[key] = [];
        var value = valueSelector ? valueSelector(item) : item;
        result[key].push(value);
    }
    return result;
};

window.Array.prototype.orderBy = function (valueSelector) {
    var result = this.slice(0).sort(function (a, b) {
        var leftValue = valueSelector ? valueSelector(a) : a;
        var rightValue = valueSelector ? valueSelector(b) : b;
        return (leftValue < rightValue) ? -1 : (leftValue === rightValue ? 0 : 1);
    });
    return result;
};

window.Array.prototype.orderByDescending = function (valueSelector) {
    var result = this.slice(0).sort(function (a, b) {
        var leftValue = valueSelector ? valueSelector(a) : a;
        var rightValue = valueSelector ? valueSelector(b) : b;
        return (leftValue > rightValue) ? -1 : (leftValue === rightValue ? 0 : 1);
    });
    return result;
};

window.Array.prototype.toDictionary = function (keySelector, valueSelector) {
    var result: any = {};
    for (var i = 0; i < this.length; ++i) {
        var item = this[i];
        var key = keySelector(item, i);
        result[key] = valueSelector ? valueSelector(item, i) : item;
    }
    return result;
};

window.Array.prototype.partition = function (itemsPerPartition) {
    const partitions = [];
    for (let i = 0; i < this.length;) {
        const partition = [];
        for (let j = 0; j < itemsPerPartition && i < this.length; j++, i++) {
            partition.push(this[i]);
        }
        partitions.push(partition);
    }
    return partitions;
};

window.Array.prototype.except = function (items) {
    const result = [];
    for (let i = 0; i < this.length; i++) {
        let item = this[i];
        let found = false;
        for (let j = 0; j < items.length; j++) {
            if (item === items[j]) {
                found = true;
            }
        }
        if (!found) {
            result.push(item);
        }
    }
    return result;
};

window.Array.prototype.distinct = function (selector) {
    const result = [];
    const keys: any = {};
    for (let i = 0; i < this.length; i++) {
        const item = this[i];
        const key = selector ? selector(item) : item;
        if (!keys[key] && item) {
            keys[key] = key;
            result.push(item);
        }
    }
    return result;
}


window.Array.prototype.moveItem = function (from, to) {
    const arr = this.slice(0);
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
}

window.Array.prototype.intersect = function (items) {
    return this.filter(x => items.indexOf(x) > -1);
};

window.Array.prototype.coalesce = function (items) {
    if (this.length === 0) {
        return items;
    }
    return this;
};

window.Array.prototype.interleave = function (item) {
    if (this.length === 0) {
        return [];
    }
    const result = [];
    for (let i = 0; i < this.length - 1; i++) {
        const value = this[i];
        result.push(value);
        if (typeof item === "function") {
            result.push(item(value));
        } else {
            result.push(item)
        }
    }
    result.push(this[this.length - 1]);
    return result;;
};

window.Array.prototype.valueEquals = function (items) {
    return this.length === items.length && this.except(items).length === 0;
};

window.Array.prototype.coalesceIfEmpty = function (others) {
    return this.length > 0 ? this : others;
}

window.Array.prototype.sequenceEquals = function (items) {
    return this.length === items.length && !this.map((x, i) => items[i] === x).some(x => !x);
};

window.Array.prototype.firstOrDefault = function (predicate) {
    for (let i = 0; i < this.length; i++) {
        const item = this[i];
        if (!predicate || predicate(item, i)) {
            return item;
        }
    }
    return null;
};

window.Array.prototype.joinNonEmpty = function (delimiter: string) {
    return this.filter(x => x).join(delimiter);
}

window.Array.prototype.overlay = function (other, mapper) {
    let result = [...this];
    for (let i = 0; i < other.length; i++) {
        const item = other[i];
        const otherId = mapper(item);
        const index = result.findIndex(x => mapper(x) === otherId);
        if (index > -1) {
            result[index] = item;
        } else {
            result.push(item);
        }
    }
    return result;
};

window.Array.prototype.indexOfFirstMatch = function (predicate) {
    for (let i = 0; i < this.length; i++) {
        if (predicate(this[i])) {
            return i;
        }
    }
    return -1;
};

Object.areKeysEqual = function (left, right) {
    const leftIsNull = !left;
    const rightIsNull = !right;
    if (leftIsNull) {
        if (rightIsNull) return true;
        return false;
    }
    if (rightIsNull) {
        return false;
    }

    const leftKeys = Object.keys(left);
    const rightKeys = Object.keys(right);
    if (leftKeys.length !== rightKeys.length) return false;
    const union: any = {};
    for (let i = 0; i < leftKeys.length; i++) {
        union[leftKeys[i]] = true;
        union[rightKeys[i]] = true;
    }
    const areEqual = Object.keys(union).length === leftKeys.length;
    return areEqual;
}