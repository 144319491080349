import React from "react";
import arxs from "infra/arxs";

import "./Icon.scss";

export interface IIconProps {
  className?: string;
  src: string;
  name: string;
  onToggle?: () => void;
  data?: any;
  width: number;
  height: number;
}

export const Icon: React.FC<IIconProps> = ({
  className,
  src,
  name,
  onToggle,
  data,
  width,
  height,
}) => {
  let imageUrl = (data && data.image?.url) || src;

  imageUrl = arxs.ImageProxy.resizeImage(imageUrl, width, height);

  let titleParts = ((data || {}).name || name).split('.')[0].split(' ');
  titleParts[0] = titleParts[0].charAt(0).toUpperCase() + titleParts[0].slice(1);
  const title = titleParts.join(' ');

  return (
    <div
      className={`icon-image ${className || ""} ${
        (data || {}).checked ? "selected" : ""
      }`}
      style={{
        background: `url("${imageUrl}")`,
        width: `${width}px`,
        height: `${height}px`
      }}
      title={title}
      onClick={onToggle}
    ></div>
  );
};
