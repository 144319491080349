import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";

import GeoLocationLabel from "components/layouts/geo/GeoLocationLabel";
import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import CardImage from "components/card/CardImage";

function getLookupRef(map: any, cardValue: any) {
  const id = (cardValue || {}).id;
  const ref = (map && map[id]) || {};
  return ref;
};

export default function SupplierCard(props: CardClassProps) {
  const initialLookups = {
      codeElementsById: {},
    };
  
  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
        lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
        }),
    };

    return () => {
        subscriptions.lookups.dispose();
    };
  }, []);

  const { card, cardProps, actionProps } = props;

  const email = card.emails && (card.emails.filter((x: any) => x.isPreferred)[0] || card.emails[0] || {}).email;
  const phone = card.phones && (card.phones.filter((x: any) => x.isPreferred)[0] || card.phones[0] || {}).number;
  const possibleEmergencyPhones = (card.phones || [])
    .filter((phone: any) => getLookupRef(lookups.codeElementsById, phone.type).code === "Supplier.Phone.Type.Emergency");
  let emergencyPhone;
  if (possibleEmergencyPhones && possibleEmergencyPhones.length > 0) {
    emergencyPhone = possibleEmergencyPhones[0].number;
  }

  const addressItem =
    card.addresses &&
    (card.addresses.filter((x: any) => x.isPreferred)[0] ||
      card.addresses[0]);
  const address = addressItem &&
    addressItem.zipCode + " " +
      addressItem.city + ", " +
      addressItem.street + " " +
      addressItem.number + " " +
      addressItem.bus;

  const renderLocation = () => {
    if (card.geoLocation && !card.geoLocation.isInherited) {
      return <GeoLocationLabel value={card.geoLocation} />;
    }
    return (
      <>
        <div>{address || ""}</div>
      </>
    );
  };

  if (actionProps.condensed) {
    return (
      <div className={`user-card ${cardProps.className || ""}`}>
        <i className="far fa-truck"></i>
        <div className="user-card-names">
          <div className="user-card-name">{card.name}</div>
          <div className="user-card-username">{email}</div>
          <div className="user-card-phones">
            {emergencyPhone && (
              <div className="user-card-phone emergency">
                {emergencyPhone}
              </div>
            )}
            <div className="user-card-phone">{phone}</div>
          </div>
        </div>
        <CardActions {...props.actionProps} />
      </div>
    );
  }

  return (
    <>
      <div className="list-card-left">
        <CardImage {...props} />
      </div>
      <div className="list-card-right">
          <h2>
            <div className="list-card-title">
                {card.name}
            </div>
            <CardActions {...props.actionProps} />
          </h2>
          <CardSubtitle cardProps={cardProps} card={card} />
          <div className="list-card-details">
            <div>{email}</div>
            <div>{phone}</div>
            <div className="list-card-details-detail emergencyPhone">
              {emergencyPhone}
            </div>
            {renderLocation()}
          </div>
      </div>
    </>
  );
}
