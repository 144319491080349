import i18n, { InitOptions } from "i18next";
import { load } from '@progress/kendo-intl';
import { loadMessages } from "@progress/kendo-react-intl";
import { getTranslations } from "locale/Translations";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import nlNumbers from "cldr-numbers-full/main/nl/numbers.json";
import nlLocalCurrency from "cldr-numbers-full/main/nl/currencies.json";
import nlCaGregorian from "cldr-dates-full/main/nl/ca-gregorian.json";
import nlDateFields from "cldr-dates-full/main/nl/dateFields.json";
import nlTimeZoneNames from "cldr-dates-full/main/nl/timeZoneNames.json";
import enNumbers from "cldr-numbers-full/main/en/numbers.json";
import enLocalCurrency from "cldr-numbers-full/main/en/currencies.json";
import enCaGregorian from "cldr-dates-full/main/en/ca-gregorian.json";
import enDateFields from "cldr-dates-full/main/en/dateFields.json";
import enTimeZoneNames from "cldr-dates-full/main/en/timeZoneNames.json";
import frNumbers from "cldr-numbers-full/main/fr/numbers.json";
import frLocalCurrency from "cldr-numbers-full/main/fr/currencies.json";
import frCaGregorian from "cldr-dates-full/main/fr/ca-gregorian.json";
import frDateFields from "cldr-dates-full/main/fr/dateFields.json";
import frTimeZoneNames from "cldr-dates-full/main/fr/timeZoneNames.json";

import Identity from "./Identity";
import Api from "./Api";

const epochs = [
    { name: "epochs.years", seconds: 31536000 },
    { name: "epochs.months", seconds: 2592000 },
    { name: "epochs.days", seconds: 86400 },
    { name: "epochs.hours", seconds: 3600 },
    { name: "epochs.minutes", seconds: 60 },
    { name: "epochs.seconds", seconds: 1 },
];

let lastLanguage: string | null = null;

const loadTranslations = (service: any) => {
    const languageHasChanged = lastLanguage === null || lastLanguage !== Identity.language;
    if (!languageHasChanged) {
      return Promise.resolve();
    }

    lastLanguage = Identity.language;
    
    return Api.translations.get(Identity.language, Identity.productType)
      .then((backendTranslations) => {
        const productType = Identity.productType;
        const language = Identity.language;
        const productName = service.getProductName(productType);

        service.productType = productType;
        service.productName = productName;
        service.language = language;

        const frontendTranslations: any = getTranslations(productType)[language];

        const translation = {
          ...frontendTranslations["translation"],
          ...backendTranslations,
        };

        const initOptions: InitOptions = {
          resources: {
            [language]: { translation },
          },
          lng: language,
          fallbackLng: "en",
          debug: process.env.NODE_ENV !== "production",
          react: {
            wait: true,
          },
        };

        const instance = languageHasChanged ? i18n.createInstance() : i18n;
        return instance
          .init(initOptions)
          .then((t) => {
            service.t = (key: string, arg?: {} | undefined) => t(key, arg);

            service.dateTime.timeAgo = (date?: string | Date) => {
              if (!date) {
                return "";
              }

              const dateInstance =
                typeof date === "string" ? new Date(date) : date;
              const secondsAgo = Math.floor(
                (new Date().getTime() - dateInstance.getTime()) / 1000
              );

              for (let { name, seconds } of epochs) {
                const interval = Math.floor(secondsAgo / seconds);

                if (interval >= 1) {
                  return service.t("epochs.epoch_ago", {
                    amount: interval,
                    epoch: service.t(name),
                  });
                }
              }

              return service.t("epochs.just_now");
            };

            service.dateTime.formatDuration = (
              dateFrom?: string | Date,
              dateTo?: string | Date
            ) => {
              if (!dateFrom || !dateTo) return "";

              const dateFromInstance =
                typeof dateFrom === "string" ? new Date(dateFrom) : dateFrom;
              const dateToInstance =
                typeof dateTo === "string" ? new Date(dateTo) : dateTo;
              const milliseconds = Math.floor(
                (dateToInstance.getTime() - dateFromInstance.getTime()) / 1000
              );

              for (let { name, seconds } of epochs) {
                const interval = Math.floor(milliseconds / seconds);

                if (interval >= 1) {
                  return service.t("epochs.epoch_duration", {
                    amount: interval,
                    epoch: service.t(name),
                  });
                }
              }

              return "";
            };

            const enums = service.enums;
            enums.setTitles(t);

            service.modules.titles = enums.titles["OriginModuleEnum"];
            service.statuses.titles = enums.titles["Status"];

            service.documentTypes.titles = {
              image: t("document_types.image"),
              manual: t("document_types.manual"),
              certificate: t("document_types.certificate"),
              additionaldocument: t("document_types.additional_document"),
              floorplan: t("document_types.floorplan"),
              landregister: t("document_types.landregister"),
              workrules: t("document_types.workrules"),
              evacuationplan: t("document_types.evacuationplan"),
              partitioningplan: t("document_types.partitioningplan"),
              contract: t("document_types.contract"),
              maindocument: t("document_types.maindocument"),
              sds: t("document_types.sds"),
              chemistrycard: t("document_types.chemistry_card"),
              formimage: t("document_types.formimage"),
              formdocument: t("document_types.formdocument"),
              formaladvice: t("document_types.formaladvice"),
            };
            service.actions.titles = {
              reactivate: t("actions.reactivate"),
              edit: t("actions.edit"),
              archive: t("actions.archive"),
              accept: t("actions.accept"),
              accept_and_activate: t("actions.task_request.accept_and_activate"),
              accept_and_complete: t("actions.task_request.accept_and_complete"),
              unrefuse: t("actions.unrefuse"),
              refuse: t("actions.refuse"),
              hold: t("actions.hold"),
              unhold: t("actions.unhold"),
              activate: t("actions.activate"),
              complete: t("actions.complete"),
              complete_form: t("actions.complete_form"),
              verify_form: t("actions.verify_form"),
              amend_actual_duration: t("actions.amend_actual_duration"),
              verify: t("actions.verify"),
              finalize: t("actions.finalize"),
              sign: t("actions.sign"),
              reschedule: t("actions.reschedule"),
              sick: t("actions.employee.sick"),
              out_of_service: t("actions.employee.out_of_service"),
              to_investigate: t("actions.incident.to_investigate"),
              set_inprocess: t("actions.set_inprocess"),
              deactivate: t("actions.deactivate"),
              gantt: t("actions.gantt"),
              plan: t("actions.plan"),
              unplan: t("actions.unplan"),
              replan: t("actions.replan"),
              close: t("actions.close"),
              transfer_to_myp: t("actions.task.transfer_to_myp"),
              create_follow_up_task: t("actions.task.create_follow_up"),
              create_follow_up_periodiccontrol: t(
                "actions.inspection.create_follow_up"
              ),
              create_follow_up_periodicmaintenance: t(
                "actions.maintenance.create_follow_up"
              ),
              create_follow_up_riskanalysis: t(
                "actions.riskanalysis.create_follow_up"
              ),
              create_task: t("actions.task.create_task"),
              create_maintenance: t("actions.maintenance.create"),
              create_inspection: t("actions.inspection.create"),
              create_activity: t("actions.activity.create_activity"),
              create_incident: t("actions.incident.create"),
              create_riskanalysis: t("actions.riskanalysis.create"),
              create_safetyinstructioncard: t("actions.safety_instruction_card.create"),
              create_instructioncard: t("actions.instruction_card.create"),
              request: t("actions.recommendation.request"),
              to_treatment: t("actions.recommendation.to_treatment"),
              to_sign: t("actions.recommendation.to_sign"),
            };

            service.translations = { translation };

            // Loads kendo messages
            loadMessages(translation.kendo, language);
            load(
              likelySubtags,
              currencyData,
              weekData,
              nlNumbers,
              nlLocalCurrency,
              nlCaGregorian,
              nlDateFields,
              nlTimeZoneNames,
              enNumbers,
              enLocalCurrency,
              enCaGregorian,
              enDateFields,
              enTimeZoneNames,
              frNumbers,
              frLocalCurrency,
              frCaGregorian,
              frDateFields,
              frTimeZoneNames,
            );

            return service;
          });
      });
  };

export default loadTranslations;