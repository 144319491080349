import { TaskStatus } from 'infra/api/contracts/TaskStatus';
import { SecurityContext } from 'infra/SecurityContext';
import { RelationshipType, OriginModuleEnum } from "infra/api/contracts";

class PlanningFilters {
    endOfBusinessDayHours: number = 19;
    startOfBusinessDayHours: number = 7;

    amountOfWorkHours: number = 8;

    securityContext?: SecurityContext;
    profile?: any;
    relationshipTypes: RelationshipType[];

    planningMomentMap: { [id: string]: any };

    constructor(planningMoments: Array<any>
        , securityContext?: SecurityContext
        , profile?: any
        , relationshipTypes?: RelationshipType[]
    ) {
        this.planningMomentMap = planningMoments.toDictionary(x => x.id);
        this.securityContext = securityContext;
        this.profile = profile;
        this.relationshipTypes = relationshipTypes || [];
    }

    isPlannableModule = (card: { module: OriginModuleEnum }) => {
        switch (card.module) {
            case OriginModuleEnum.Task:
            case OriginModuleEnum.PeriodicMaintenance:
            case OriginModuleEnum.PeriodicControl:
            case OriginModuleEnum.RiskAnalysis:
                return true;
        }
        return false;
    }

    expiredTaskFilter = () => {
        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }
            if (this.isPlannableModule(card)) {
                const ignoreStatuses = [TaskStatus.InProcess, TaskStatus.OnHold, TaskStatus.Completed, TaskStatus.MultiYearPlan, TaskStatus.ToVerify];
                return (card.targetDate && this.dateReached(card.targetDate) && ignoreStatuses.indexOf(card.status) === -1)
                    || card.status === TaskStatus.ExecutionOverdue;
            }
            return false;
        }
        return filter;
    };

    expiredPlanningFilter = () => {
        const toPlanStatuses = [TaskStatus.Active];

        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }
            if (this.isPlannableModule(card)) {
                const hasActiveState = toPlanStatuses.indexOf(card.status) > -1;
                if (hasActiveState) {
                    const planningMoment = card.inboundLinks
                        .filter((x: any) => x.module === OriginModuleEnum.Planning && x.isDeleted !== false)
                        .map((x: any) => this.planningMomentMap[x.id])
                        .filter((x: any) => x)[0];
                    const date = (planningMoment && planningMoment.end);
                    return (!card.targetDate || !this.dateReached(card.targetDate)) && this.dateReached(date);
                }
            }
            return false;
        }
        return filter;
    };

    plannedfilter = (start: number, endExclusive: number) => {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const startDate = new Date(new Date().setHours(today.getHours() + (start * 24), 0, 0, 0));
        const endDate = new Date(new Date().setHours(today.getHours() + ((endExclusive || 9999) * 24), 0, 0, 0));
        const toPlanStatuses = [TaskStatus.Active];

        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }

            if (this.isPlannableModule(card)) {
                const hasActiveState = toPlanStatuses.indexOf(card.status) > -1;
                if (hasActiveState) {
                    const planningMoment = card.inboundLinks
                        .filter((x: any) => x.module === OriginModuleEnum.Planning && x.isDeleted !== false)
                        .map((x: any) => this.planningMomentMap[x.id])
                        .filter((x: any) => x)[0];
                    const date = (planningMoment && planningMoment.start) || card.targetDate;
                    return !this.dateReached(card.targetDate) && this.dateInRange(date, startDate, endDate);
                }
            }

            return false;
        }

        return filter;
    };

    toPlanfilter = (start: number, endExclusive: number) => {
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const startDate = new Date(new Date().setHours(today.getHours() + (start * 24), 0, 0, 0));
        const endDate = new Date(new Date().setHours(today.getHours() + ((endExclusive || 9999) * 24), 0, 0, 0));
        const toPlanStatuses = [TaskStatus.Active];

        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }

            let targetDate;
            if (card.module === OriginModuleEnum.Planning) {
                targetDate = card.start;
            } else if (this.isPlannableModule(card)) {
                if (toPlanStatuses.indexOf(card.status) > -1) {
                    targetDate = card.targetDate;
                }
            }

            return targetDate && this.dateInRange(targetDate, startDate, endDate);
        }

        return filter;
    };

    noTargetDatefilter = () => {
        const toPlanStatuses = [TaskStatus.Active];

        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }
            if (this.isPlannableModule(card)) {
                return !card.targetDate && toPlanStatuses.indexOf(card.status) > -1;
            }
            return false;
        }

        return filter;
    };

    noTargetDateAndPlanningfilter = () => {
        const toPlanStatuses = [TaskStatus.Active];

        const filter = (card: any) => {
            if (!this.matchesSecurityFilter(card)) {
                return false;
            }
            if (card.isDeleted) {
                return false;
            }
            if (this.isPlannableModule(card)) {
                const noPlanning = !card.inboundLinks.some((x: any) => x.module === OriginModuleEnum.Planning && x.isDeleted !== false);
                const noTargetDate = !card.targetDate && toPlanStatuses.indexOf(card.status) > -1;
                return noPlanning && noTargetDate;
            }
            return false;
        }

        return filter;
    };

    private matchesSecurityFilter = (card: any) => {
        if (!card) {
            return false;
        }

        if (this.securityContext && !this.securityContext.filter(card)) {
            return false;
        }

        if (this.profile) {
            const userId = this.profile.id;
            const userRoleIds = this.profile.getUserRoleIds();
            if (card.module === OriginModuleEnum.Planning) {
                if (!card.assignee || card.assignee.id !== userId || (this.relationshipTypes.indexOf(RelationshipType.Assignee) > -1)) {
                    return false;
                }
            } else if (this.isPlannableModule(card)) {
                if (!card.relationships || !card.relationships.some(
                    (x: any) => (this.relationshipTypes.indexOf(x.type) > -1)
                        && ((x.employee && x.employee.id === userId)
                            || (x.userRole && userRoleIds.indexOf(x.userRole.id) > -1)))
                ) {
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    }

    private getPlanningMomentDuration = (planningMoment: any) => {
        if (planningMoment.isAllDay) {
            return this.amountOfWorkHours;
        }

        if (planningMoment.end) {
            return this.getDurationBetweenTimes(planningMoment.start, planningMoment.end);
        }
    }

    private getDurationBetweenTimes = (start: Date, end: Date) => {
        let diffInMilliSeconds = Math.abs(end.getTime() - start.getTime()) / 1000;
        return Math.floor(diffInMilliSeconds / 3600) % 24;
    }

    private getDurationInDays = (hours: number) => {
        if (hours <= this.amountOfWorkHours) {
            return 0;
        }

        return Math.ceil(hours / this.amountOfWorkHours);
    }

    private dateReached = (dateToCheck?: Date | string, dateToCheckAgainst?: Date | undefined) => {
        if (!dateToCheck) {
            return false;
        }

        if (dateToCheckAgainst === undefined) {
            dateToCheckAgainst = new Date();
        }

        const date = dateToCheck instanceof Date ? dateToCheck : new Date(dateToCheck);

        const differenceInDays = (date.getTime() - dateToCheckAgainst.getTime()) / (1000 * 3600 * 24);
        return differenceInDays <= 0;
    }

    private dateInRange = (dateToCheck: Date | string, start: Date, endExclusive: Date) => {
        const date = dateToCheck instanceof Date ? dateToCheck : new Date(dateToCheck);

        const startDiff = (date.getTime() - start.getTime()) / (1000 * 3600 * 24);
        const endDiff = (date.getTime() - endExclusive.getTime()) / (1000 * 3600 * 24);

        return (startDiff >= 0) && (endDiff < 0);
    }
}
export default PlanningFilters;