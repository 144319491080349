import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import Button from 'components/controls/Button';

import './Toaster.scss';

const UpdateMessage = (message, buttonText, onClick) => {
  return () => (
    <Fragment>
      {message}
      {onClick && <Button onClick={onClick} className="create">{buttonText}</Button>}
    </Fragment>
  )
};

const HandleStatusUpdate = (messages) => {
  const getFormattedMessages = () => {
    return <ul>
      {messages.map((message, index) => (<li key={index} >• {message}</li>))}
    </ul>;
  }

  if (toast.isActive("arxs_alert")) {
    toast.update("arxs_alert", {
      render: () => getFormattedMessages(),
      autoClose: false,
      closeButton: false
    });
  }
  else {
    toast.error(getFormattedMessages(), { toastId: "arxs_alert", autoClose: false, closeButton: false });
  }
}

class Toaster {
  alertMessages = [];

  success(message) {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
    
  }

  warning(message, autoClose) {
    toast.warning(message, {
      autoClose: autoClose || false,
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }

  error(message) {
    toast.error(message, { autoClose: false, position: toast.POSITION.BOTTOM_RIGHT });
  }

  notify(message, autoClose) {
    toast.info(message, {
      autoClose: autoClose || true,
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }

  custom(message, buttonText, onClick) {
    toast(UpdateMessage(message, buttonText, onClick), { autoClose: false, className: "custom-toast" });
  }

  status(message) {
    if (!this.alertMessages.contains(message)) {
      this.alertMessages.push(message);
    }

    HandleStatusUpdate(this.alertMessages);
  }

  clear_status(message) {
    if (!message) {
      toast.dismiss("arxs_alert");
      this.alertMessages = [];
      return;
    };

    this.alertMessages = this.alertMessages.filter(x => x !== message);

    if (this.alertMessages.length === 0) {
      toast.dismiss("arxs_alert");
    }
    else {
      HandleStatusUpdate(this.alertMessages);
    }
  }

  clear() {
    toast.dismiss();
  }
}
export default new Toaster();