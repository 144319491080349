import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";

import "./CodeElementFormLookup.scss";

/*
    Definition
    {
        "Id": "...",
        "Section": "...",
        "Ordinal": 4,
        "Title": "Bijkomende veiligheidseisen",
        "Control": 4,
        "Required": false,
        "PossibleActions": [],
        "Data": "[{\"id\":\"...\",\"value\":\"\"}]",
        "Settings": "{}"
    }
    values
    {
        ???
    }
*/

// data -> { id: string, value: string, attachments:[] }
export default class CodeElementFormLookup extends FormItemController {
    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    serialize(deserializedValue) {
        return JSON.stringify(deserializedValue);
    }

    renderItem = (codeElements, onItemChange, onDeleteItem, data) => {
        const { readOnly } = this.props;

        return <div className="ce-form-lookup-container" key={(data && data.id) || 0}>
            <div className="ce-form-lookup-line">
                <AutoCompleteTextBox
                    id={`value-${data.id}`}
                    key={`value-${data.id}`}
                    className={`ce-form-lookup-value`}
                    items={codeElements}
                    value={data.value}
                    onChange={(value) => onItemChange(data, value)}
                    readOnly={readOnly}
                    placeholder={this.props.placeholder}
                />
                <div className="ce-form-lookup-actions">
                    {!readOnly && data && <div className="ce-form-lookup-action" onClick={() => onDeleteItem(data.id)}>
                        <i className="far fa-trash-alt"></i>
                    </div>}
                </div>
            </div>
        </div>;
    }

    renderInputAndItems = (context) => {
        const { readOnly } = this.props;
        const code = this.props.code;
        const parentCodeElement = ((this.state.codeElements || {})[code] || [])[0] || {};
        const codeElements = (parentCodeElement.children || [])
            .filter(x => x.isActive)
            .map(x => x.name);

        const value = (this.getter() || [])
            .map(x => ({
                ...x,
                value: this.state.codeElementsById[x.id] 
                    ? this.state.codeElementsById[x.id].name
                    : x.value
            }));
        
        const onItemChange = (line, itemValue) => {
            let newValue = value.replaceOrAppend({ ...line, value: itemValue }, (l, r) => l.id === r.id);
            this.setter(newValue);
        };

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };

        const onDeleteItem = (id) => {
            let newValue = (value || []).filter(x => x.id !== id);
            this.setter(newValue);
        };

        const renderItems = () => {
            return value.map(line => this.renderItem(codeElements, onItemChange, onDeleteItem, line));
        };

        return <div className="ce-form-lookup">
            {renderItems()}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control">
                    {this.renderHeader(this.props.title)}
                    <div className="form-control-field">
                        {this.renderInputAndItems(context)}
                    </div>
                    {this.renderFooter(context)}
                </div>
            }
        </GlobalContext.Consumer>)
    }
}