import React, { useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import Badge from 'components/controls/Badge'

import './DragableImage.scss';

export interface IImageProps {
  style: object,
  id: string,
  onDrop: (dragIndex: number, hoverindex: number) => void,
  className: string,
  src: string,
  alt: string,
  onClick: () => void
  label: number,
  index: number,
  readOnly: boolean
}

interface IItem {
  type: string,
  id: string,
  index: number
}

export const DragableImage: React.FC<IImageProps> = ({ style, id, onDrop, className, src, alt, onClick, label, index, readOnly }) => {

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'Image',
    item: { id, index },
    canDrag: !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'Image',
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      };

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      };

      onDrop(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  })

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} className={`image ${className}`}
      style={(style && { ...style, opacity }) || { backgroundImage: `url("${src || "images/image.png"}")` }}
      title={alt}
      onClick={onClick}>
      <Badge className="blue">{label}</Badge>
    </div>
  );

}
