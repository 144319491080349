import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import Toaster from 'components/util/Toaster';
import { _providers } from 'infra/Identity'
import { Spinner } from 'components/animations/Spinner';
import CheckBox from 'components/controls/CheckBox';
import Link from 'components/controls/Link';
import { Loading } from 'components/shell/Loading';

import './Login.scss';

const _viewStates = {
  loading: "loading",
  login: "login",
  reset_request: "reset_request",
  reset_token_validation: "reset_token_validation",
  reset_completion: "reset_completion",
};



export class Login extends Component {
  constructor(props) {
    super(props);

    const parsedUrl = arxs.parseURL(window.location.href);
    const rawToken = parsedUrl.searchObject["token"];
    const unverifiedToken = rawToken ? decodeURIComponent(rawToken) : null;
    const code = parsedUrl.searchObject["code"];

    this.state = {
      data: {
        userName: "",
        password: "",
        rememberMe: false,
        password1: "",
        password2: "",
        email: ""
      },
      splashVisible: true,
      loggingIn: false,
      unverifiedToken,
      verifiedToken: null,
      viewState: code ? _viewStates.loading : (unverifiedToken ? _viewStates.reset_token_validation : _viewStates.login),
      splashImage: undefined
    };

    this.checkFeedback();

    window.history.pushState({}, "ArXs", window.location.href.split("?")[0]);
  }

  checkFeedback = () => {
    const parsedUrl = arxs.parseURL(window.location.href);
    if (parsedUrl.searchObject["e"]) {
      parsedUrl.searchObject["e"] === "notfound" ? Toaster.error(arxs.t("login.error.nomatch")) : Toaster.error(parsedUrl.searchObject["e"]);
    }
  }

  componentDidMount() {
    if (!this.state.splashImage) {
      const splashImage = this.getSplashImage();
      this.setState({ splashImage });
    }

    if (this.state.unverifiedToken) {
      const token = this.state.unverifiedToken;
      arxs.Identity.verifyResetToken(token)
        .then((data) => {
          if (data && data.isOk) {
            this.setState({
              splashVisible: false,
              verifiedToken: token,
              unverifiedToken: null,
              viewState: _viewStates.reset_completion
            });
          }
          else {
            Toaster.error(arxs.t("login.passwordreset.error"));
            this.setState({ viewState: _viewStates.login });
          };
        })
        .catch(error => {
          Toaster.error(arxs.t("login.passwordreset.error"));
          this.setState({ viewState: _viewStates.login });
        });

    }
  }

  handleChange(event, field) {
    const data = { ...this.state.data };
    const target = event.target;
    if (field === "rememberMe") {
      data[field] = !data[field];
    } else {
      data[field] = target.value;
    }
    this.setState({ data });
  }

  hideSplash = () => {
    this.setState({ splashVisible: false });
  }

  login = async (event, context) => {
    event.preventDefault();

    if (this.state.loggingIn) {
      return;
    }

    this.setState({ loggingIn: true });

    const onError = () => {
      Toaster.error(arxs.t("login.error.general"));
      this.setState({ loggingIn: false });
    };

    let data = await arxs.Identity.arxsLogin(this.state.data.userName, this.state.data.password, this.state.data.rememberMe, onError);

    if (data && data.isOk) {
      window.location = data.redirectUrl;
    } else {
      this.setState({ loggingIn: false });
    }
  }

  socialLogin = async (event, context, provider) => {
    event.preventDefault();

    await arxs.Identity.socialLogin(provider);
  }

  forgotPasswordInit = (event, context) => {
    event.preventDefault();

    Toaster.clear();

    this.setState({ viewState: _viewStates.reset_request });
  }

  forgotPassword = async (event, context) => {
    event.preventDefault();
    
    if (this.state.requestingReset) { return; }

    this.setState({ requestingReset: true });

    try {
      let data = await arxs.Identity.forgotPassword(this.state.data.userName);

      if (data && data.isOk) {
        //show that we'll send an email if we have a match found.
        Toaster.notify(arxs.t("login.passwordreset.requested"))
      } else {
        Toaster.error(arxs.t("login.passwordreset.error"));
      }
    } catch (error) {

    }

    this.setState({ viewState: _viewStates.login, requestingReset: false });
  }

  resetPassword = async (event, context) => {
    event.preventDefault();

    if (this.state.data.password1 !== this.state.data.password2) {
      Toaster.error(arxs.t("login.passwordreset.passwords_not_matching"))
    } else {
      let data = await arxs.Identity.resetPassword(this.state.data.userName, this.state.verifiedToken, this.state.data.password1);

      if (data && data.isOk) {
        Toaster.success(arxs.t("login.passwordreset.success"));
      } else {
        Toaster.error(arxs.t("login.passwordreset.error"));
      }

      this.setState({ viewState: _viewStates.login });
    }
  }

  getSplashImage = () => {
    const images = {
      default: ["images/splash/facility1.jpg",
        "images/splash/industrieel1.jpg",
        "images/splash/industrieel2.jpg",
        "images/splash/labo1.jpg",
        "images/splash/logistiek1.jpg",
        "images/splash/onderwijs1.jpg",
        "images/splash/onderwijs2.jpg",
        "images/splash/onderwijs3.jpg",
        "images/splash/onderwijs4.jpg",
        "images/splash/zorg1.jpg"],
      sint: ["images/splash/sint/sint1.jpg",
        "images/splash/sint/sint2.jpg"],
      kerst: ["images/splash/kerst/kerst1.jpg",
        "images/splash/kerst/kerst2.jpg",
        "images/splash/kerst/kerst3.jpg"],
      ny: ["images/splash/ny/NY1.jpg",
        "images/splash/ny/NY2.jpg",
        "images/splash/ny/NY2.jpg"]

    };
    const getImagesInScope = () => {
      const currentDate = new Date();

      if (currentDate.getMonth() + 1 === 12 && currentDate.getDate() === 6) {
        return images.sint;
      }

      if (currentDate.getMonth() + 1 === 12 && (currentDate.getDate() >= 20 && currentDate.getDate() < 27)) {
        return images.kerst;
      }

      if ((currentDate.getMonth() + 1 === 12 && currentDate.getDate() >= 27) || (currentDate.getMonth() + 1 === 1 && currentDate.getDate() <= 15)) {
        return images.ny;
      }

      return images.default;
    }

    const imagesInScope = getImagesInScope();

    return imagesInScope[Math.floor((Math.random() * imagesInScope.length))];
  }

  getSplashSentence = () => {

    const currentDate = new Date();

    const getYear = () => {
      if (currentDate.getMonth() + 1 === 12) {
        return currentDate.getFullYear() + 1;
      }

      return currentDate.getFullYear();
    }

    if (currentDate.getMonth() + 1 === 12 && currentDate.getDate() === 6) {
      return <Fragment>{arxs.t("login.splash.sint")}</Fragment>;
    }

    if ((currentDate.getMonth() + 1 === 12 && (currentDate.getDate() >= 20)) || (currentDate.getMonth() + 1 === 1 && currentDate.getDate() <= 15)) {
      return <Fragment>{arxs.t("login.splash.title")}<br />{arxs.t("login.splash.wishes", { year: getYear() })}</Fragment>
    }

    return <Fragment>{arxs.t("login.splash.title")}</Fragment>;
  }

  render() {
    const providers = arxs.Identity.configuredProviders;
    const containsSocialProviders = providers.includes(_providers.google) || providers.includes(_providers.smartschool) || providers.includes(_providers.microsoft);

    const style = (context) => {
      const { splashImage } = this.state;
      return context.platform.isMobile
        ? { backgroundImage: `url(${splashImage})`, backgroundSize: "cover", backgroundPositionX: "center" }
        : { backgroundImage: `url(${splashImage})`, backgroundSize: "cover" }
    }

    const openArxs = () => {
      window.open("https://www.arxs.be");
    }

    const buildSplash = context => (<div className="splash"
      style={style(context)}>

      <div className="splash-content">
        <div className="splash-logo" onClick={openArxs}>
          <img src="images/ArXs_Logo_White_trs.png" alt="ArXs" />
        </div>
        <div className="motto">
          <h2>{this.getSplashSentence()}</h2>
        </div>
      </div>
      {context.platform.isMobile && (<div className="splash-button">
        <button onClick={() => this.hideSplash()}>
          {this.state.loggingIn ? <Spinner /> : arxs.t("login.log_on")}
        </button>
      </div>)}
    </div>);

    const loginForm = (context, providers) => (
      <Fragment>
        <h1>{arxs.t("login.log_on")}</h1>
        {providers.includes(_providers.forms) && <div>
          <div className="field">
            <label htmlFor="userName">{arxs.t("login.username")}</label>
            <div className="input-wrapper far fa-user">
              <input autoFocus id="userName" name="userName" type="text" value={this.state.data.userName} onChange={(event) => this.handleChange(event, "userName")} autoComplete="username" />
            </div>
          </div>
          <div className="field">
            <label htmlFor="password">{arxs.t("login.password")}</label>
            <div className="input-wrapper far fa-lock-alt">
              <input id="password" name="password" type="password" value={this.state.data.password} onChange={(event) => this.handleChange(event, "password")} autoComplete="current-password" />
            </div>
          </div>
          <div className="grid-2">
            <CheckBox className="field" id="rememberMe"
              checked={this.state.data.rememberMe}
              onChange={(event) => this.handleChange(event, "rememberMe")}
              label={arxs.t("login.remember_me")}
            />
            <Link className="underline" onClick={event => this.forgotPasswordInit(event, context)}>{arxs.t("login.forgot_password")}</Link>
          </div>
          <button onClick={event => this.login(event, context)}>
            {this.state.loggingIn ? <Spinner /> : arxs.t("login.log_on")}
          </button>
        </div>}
        {containsSocialProviders &&
          <div className="social">
            {providers.includes(_providers.forms) && <h2>{arxs.t("login.social_logon")}</h2>}
            {providers.includes(_providers.google) && (
              <button onClick={event => this.socialLogin(event, context, _providers.google)}>
                <img src="images/identityProviders/Google.png" alt={_providers.google} />
                Google
              </button>)}
            {providers.includes(_providers.microsoft) && (
              <button onClick={event => this.socialLogin(event, context, _providers.microsoft)} >
                <img src="images/identityProviders/Office365.png" alt={_providers.microsoft} />
                Office 365
              </button>)}
            {providers.includes(_providers.smartschool) && (
              <button onClick={event => this.socialLogin(event, context, _providers.smartschool)} >
                <img src="images/identityProviders/Smartschool.png" alt={_providers.smartschool} />
                Smartschool
              </button>)}
          </div>}
      </Fragment>
    );

    const forgotForm = (context) => (
      <Fragment>
        <h1>{arxs.t("login.passwordreset.title")}</h1>
        <div className="field">
          <label htmlFor="userName">{arxs.t("login.passwordreset.userName")}</label>
          <div className="input-wrapper far fa-user">
            <input autoFocus id="userName" name="userName" type="text" value={this.state.data.userName} onChange={(event) => this.handleChange(event, "userName")} />
          </div>
        </div>
        <button disabled={this.state.requestingReset} onClick={event => this.forgotPassword(event, context)}>{arxs.t("login.passwordreset.reset")}</button>
        <Link className="underline" onClick={event => this.setState({ viewState: _viewStates.login })}>{arxs.t("login.passwordreset.back")}</Link>
      </Fragment>
    );

    const resetForm = (context) => (
      <Fragment>
        <h1>{arxs.t("login.passwordreset.title")}</h1>
        <div className="field">
          <label htmlFor="userName">{arxs.t("login.passwordreset.userName")}</label>
          <div className="input-wrapper far fa-user">
            <input autoFocus id="userName" name="userName" type="text" value={this.state.data.userName} onChange={(event) => this.handleChange(event, "userName")} autoComplete="username" />
          </div>
        </div>
        <div className="field">
          <label htmlFor="password1">{arxs.t("login.passwordreset.password")}</label>
          <div className="input-wrapper far fa-lock-alt">
            <input id="password1" name="password1" type="password" value={this.state.data.password1} onChange={(event) => this.handleChange(event, "password1")} autoComplete="new-password" />
          </div>
        </div>
        <div className="field">
          <label htmlFor="password2">{arxs.t("login.passwordreset.password2")}</label>
          <div className="input-wrapper far fa-lock-alt">
            <input id="password2" name="password2" type="password" value={this.state.data.password2} onChange={(event) => this.handleChange(event, "password2")} autoComplete="new-password" />
          </div>
        </div>
        <button onClick={event => this.resetPassword(event, context)}>{arxs.t("login.passwordreset.reset")}</button>
      </Fragment>
    );

    const verificationForm = (context) => (<div>
      {arxs.t("login.passwordreset.token_verification_title")}
    </div>);

    const buildForm = (context) => {
      const renderForm = (state) => {
        switch (state) {
          case _viewStates.reset_request: return forgotForm(context);
          case _viewStates.reset_token_validation: return verificationForm(context);
          case _viewStates.reset_completion: return resetForm(context);
          default: return loginForm(context, providers);
        }
      }

      return (<div className="form">
        {context.platform.isMobile && (<div className="logo">
          <img src="images/logo-transparent-small.png" alt="ArXs" />
          <h1>{arxs.productName}</h1>
        </div>)}
        <div className="form-content">
          <form>
            {renderForm(this.state.viewState)}
          </form>
        </div>
      </div>
      )
    };

    if (this.state.viewState === _viewStates.loading) {
      return <Loading />;
    }

    // Declaring Route components inline makes them not remount when Layout rerenders
    return (<GlobalContext.Consumer>
      {(context) => (
        <div className="login-content">
          {context.platform.isMobile
            ? (<Fragment>
              {!this.state.splashVisible && buildForm(context)}
              {this.state.splashVisible && buildSplash(context)}
            </Fragment>)
            : (<Fragment>
              {buildForm(context)}
              {buildSplash(context)}
            </Fragment>)}
        </div>
      )}
    </GlobalContext.Consumer>
    );
  }
}
