import React, { useEffect, useState } from 'react';

import arxs from 'infra/arxs';
import { ReportHeaderFooterSettings } from "infra/api/contracts/ReportHeaderFooterSettings";
import DropDown, { DropDownItem } from 'components/controls/DropDown';
import ReportHeaderImageUploader from "./ReportHeaderImageUploader";
import ColorPicker from "components/controls/ColorPicker";

import './ReportHeaderFooterEditor.scss';

export interface ReportHeaderFooterEditorProps {
    value: ReportHeaderFooterSettings;
    onChange(state: ReportHeaderFooterSettings): void;
}
const renderDropDown = (onChange: (state: ReportHeaderFooterSettings) => void
    , fields: DropDownItem[]
    , value: any
    , key: "header" | "footer"
    , index: number
    ) => {
        const selected = fields.filter(x => value[key][index] === x.id)[0];
        return <DropDown
            items={fields}
            selected={selected}
            onChange={(x: any) => {
                onChange({
                    ...value,
                    [key]: value[key].slice(0, index).concat(x ? [x.id] : [""]).concat(value[key].slice(index + 1))
                })
            }}
            width={"250px"}
        />;
    };

const serialize = (customLogoUrl: string | null, state: ReportHeaderFooterSettings): ReportHeaderFooterSettings => {
    return {
        ...state,
        header: (state.header || []).map(x => x === "custom_logo" ? customLogoUrl || "custom_logo" : x),
        footer: (state.footer || []).map(x => x === "custom_logo" ? customLogoUrl || "custom_logo" : x),
    };
};

const deserialize = (state: ReportHeaderFooterSettings, defaults: ReportHeaderFooterSettings): [ReportHeaderFooterSettings, string] => {
    if (!state) {
        return [defaults, ""];
    }

    const dataUrl = (state.header || [])
        .concat((state.footer || []))
        .filter(x => x.startsWith("data:image/"))[0];
    return [
        {
            ...defaults,
            ...state,
            header: (state.header || [])
                .coalesceIfEmpty(defaults.header || [])
                .map(x => x.startsWith("data:image/") ? "custom_logo" : x),
            footer: (state.footer || [])
                .coalesceIfEmpty(defaults.footer || [])
                .map(x => x.startsWith("data:image/") ? "custom_logo" : x)
                .slice(0, 2).concat(["arxs_logo"]),
        },
        dataUrl
    ];
};

const _defaultValue = {
    header: ["", "", "legalStructure"],
    footer: ["page_number","document_title","arxs_logo"],
    headerColor: "#3182bd",
    headerBackgroundColor: "#ffffff",
    headerBorder: "1px solid #3182bd",
    subHeaderColor: "#3182bd",
    subHeaderBackgroundColor: "#ffffff",
    subHeaderBorder: "1px solid #3182bd",
}

export default function ReportHeaderFooterEditor(props: ReportHeaderFooterEditorProps) {
    const [value, dataUrl] = deserialize(props.value, _defaultValue);
    const [customLogo, setCustomLogo] = useState<string | null>(dataUrl);
    const [fields] = useState(["custom_logo", "legalStructure", "branch", "document_title", "document_date", "page_number"]
        .map(x => ({ id: x, name: arxs.t(`controls.report_header_footer_editor.fields.${x}`) })));

    const renderField = (headerFooter: string[], index: number) => {
        const item = headerFooter[index];
        switch (item) {
            case "custom_logo":
                return <img src={customLogo || ""} />;
            case "legalStructure":
                return <img src="images/image.png" />;
            case "branch":
                return <img src="images/image.png" />;
            case "arxs_logo": 
                return <img src="images/arxs.png" />;
            case "document_title":
                return <div className="report-preview-title">{arxs.t("controls.report_header_footer_editor.fields.document_title")}</div>;
            case "document_date":
                return <div className="report-preview-date">{arxs.dateTime.formatDate(new Date())}</div>;
            case "page_number":
                return <div className="report-preview-page"><label>{arxs.t("controls.report_header_footer_editor.page_number")}</label><span>|</span><b>1/3</b></div>;
        }
        return <div>

        </div>
    };

    const handleChangeImage = (dataUrl: string | null) => {
        setCustomLogo(dataUrl);
        const newValue = serialize(dataUrl || "", value);
        props.onChange(newValue);
    };

    const handleFieldChange = (newValue: ReportHeaderFooterSettings) => {
        props.onChange(serialize(customLogo, newValue));
    };

    const handleColorChange = (key: string) => {
        return (newValue: string) => {
            props.onChange(serialize(customLogo, {
                ...value,
                [key]: newValue
            }));
        };
    };

    const getStyleOrDefault = (key: string) => (value as any)[key] || (_defaultValue as any)[key];

    const getStyle = (index: number) => {
        switch (index) {
            case 0:
                return {
                    "marginTop": "5px",
                    "color": getStyleOrDefault("headerColor"),
                    "fontWeight": "bold" as "bold", // Otherwise type incompatible: TS2322
                    "backgroundColor": getStyleOrDefault("headerBackgroundColor"),
                    "borderBottom": getStyleOrDefault("headerBorder"),
                };
            case 1:
                return {
                    "color": getStyleOrDefault("subHeaderColor"),
                    "backgroundColor": getStyleOrDefault("subHeaderBackgroundColor"),
                    "borderBottom": getStyleOrDefault("subHeaderBorder"),
                };
        }
    };

    const header = value.header || [];
    const footer = value.footer || [];

    return <div className="report-header-footer-editor">
        <div className="report-header">
            <h3>{arxs.t("controls.report_header_footer_editor.header")}</h3>
            <label>{arxs.t("controls.report_header_footer_editor.left")}</label>
            {renderDropDown(handleFieldChange, fields, value, "header", 0)}
            <label>{arxs.t("controls.report_header_footer_editor.center")}</label>
            {renderDropDown(handleFieldChange, fields, value, "header", 1)}
            <label>{arxs.t("controls.report_header_footer_editor.right")}</label>
            {renderDropDown(handleFieldChange, fields, value, "header", 2)}
            <label>{arxs.t("controls.report_header_footer_editor.header_color")}</label>
            <div className="header-colors">
                {["headerColor", "headerBackgroundColor", "headerBorder"]
                    .map(x => <ColorPicker key={x} value={getStyleOrDefault(x)} onChange={handleColorChange(x)} />)}
            </div>
            <label>{arxs.t("controls.report_header_footer_editor.subheader_color")}</label>
            <div className="header-colors">
                {["subHeaderColor", "subHeaderBackgroundColor", "subHeaderBorder"]
                    .map(x => <ColorPicker key={x} value={getStyleOrDefault(x)} onChange={handleColorChange(x)} />)}
            </div>
        </div>
        <div className="report-preview">
            <h3>{arxs.t("controls.report_header_footer_editor.preview")}</h3>
            <div className="report-preview-document">
                <div className="report-preview-header">
                    {renderField(header, 0)}
                    {renderField(header, 1)}
                    {renderField(header, 2)}
                </div>
                <div className="report-preview-body">
                    <div style={getStyle(0)}>{arxs.t("controls.report_header_footer_editor.heading")} 1</div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet nibh sed sapien commodo consectetur vulputate iaculis ligula. Fusce pellentesque elit lobortis turpis interdum convallis nec a urna. Duis quis est lacus. Pellentesque vulputate id ante nec gravida. 
                    <div style={getStyle(0)}>{arxs.t("controls.report_header_footer_editor.heading")} 2</div>
                    Nullam vehicula, neque vitae aliquet auctor, mi lectus sollicitudin magna, vitae suscipit massa justo a nisi.
                    <div style={getStyle(1)}>{arxs.t("controls.report_header_footer_editor.subheading")} 1</div>
                    Mauris semper sollicitudin quam at suscipit. Ut laoreet nisi mi, ut fermentum eros tincidunt ac. Aenean ut dui non nulla ornare dignissim sit amet vel dui. Quisque lacinia non eros id pharetra. Fusce mollis vel risus et efficitur. Etiam ullamcorper mi a odio pulvinar, vitae maximus magna convallis.
                    <div style={getStyle(1)}>{arxs.t("controls.report_header_footer_editor.subheading")} 1</div>
                    Mauris lacus sem, rutrum non porta ut, sagittis non nunc. Cras neque ante, dictum sit amet gravida non, tempor quis velit.
                </div>
                <div className="spacer"></div>
                <div className="report-preview-footer">
                    {renderField(footer, 0)}
                    {renderField(footer, 1)}
                    {renderField(footer, 2)}
                </div>
            </div>
        </div>
        <div className="report-footer">
            <h3>{arxs.t("controls.report_header_footer_editor.footer")}</h3>
            <label>{arxs.t("controls.report_header_footer_editor.left")}</label>
            {renderDropDown(handleFieldChange, fields, value, "footer", 0)}
            <label>{arxs.t("controls.report_header_footer_editor.center")}</label>
            {renderDropDown(handleFieldChange, fields, value, "footer", 1)}
            <label>{arxs.t("controls.report_header_footer_editor.fields.custom_logo")}</label>
            <ReportHeaderImageUploader
                value={dataUrl}
                onChange={handleChangeImage}
            />
        </div>
    </div>;
}