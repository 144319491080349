import "infra/ArrayExtensions";
import { UserManager, WebStorageStateStore } from "oidc-client";
import Api from "infra/Api";
import Toaster from "components/util/Toaster";
import { ProductType } from "infra/Types";
import createClassicRouter from "./classic/ClassicRouter";
import CacheStore from './CacheStore';
import posthog from "posthog-js";

export const _providers = {
  forms: "Forms",
  google: "Google",
  microsoft: "Microsoft",
  smartschool: "Smartschool",
};

const _httpMethod = {
  get: "GET",
  post: "POST",
};

export const _socialProviders = [
  _providers.google,
  _providers.microsoft,
  _providers.smartschool,
];

const _localStorageKeys = {
  isAuthenticated: "isAuthenticated",
  oidcTokenPrefix: "oidc.user",
  version: "version",
};

class Identity {
  constructor() {
    const {
      REACT_APP_IDENTITY_ENDPOINT,
      REACT_APP_TENANT_MAP,
      REACT_APP_TENANT_OVERRIDE,
    } = process.env;

    this.endpointUrl = REACT_APP_IDENTITY_ENDPOINT;
    this.configuredProviders = [];

    if (REACT_APP_TENANT_OVERRIDE) {
      this.tenant = REACT_APP_TENANT_OVERRIDE;
    } else if (REACT_APP_TENANT_MAP) {
      var map = JSON.parse(REACT_APP_TENANT_MAP);
      this.tenant = map[window.location.hostname];
    }
    if (!this.tenant) {
      this.tenant = `${window.location.hostname.replace(/[.]/g, "")}`;
    }

    this.userManager = new UserManager({
      authority: this.endpointUrl,
      client_id: "vnext.arxs.be",
      // This url should be formed as such: https://hostname:3000 <- no ending slash or path of any kind
      // These redirect_uris are whitelisted explicitely in client definitions
      redirect_uri: window.location.origin,
      silent_redirect_uri: `${window.location.origin}/signin-redirect-callback.html`,
      post_logout_redirect_uri: `${window.location.origin}/`,
      response_type: "code",
      scope: "openid profile ArXs.Api",
      filterProtocolClaims: true,
      loadUserInfo: true,
      extraQueryParams: {
        tenantId: this.tenant,
        returnUrl: window.location.href,
      },
      automaticSilentRenew: true,
      revokeAccessTokenOnSignout: true,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.userManager.clearStaleState();

    this.profile = null;
    this.productType = ProductType.Hyperion;
    this.language = this.getNavigatorLanguageCode();
    this.vNextModules = [];

    this.headers = {};
    this.authenticationUpdateHandlers = [];
  }

  getNavigatorLanguageCode = () => {
    switch (navigator.language) {
      case "nl":
      case "nl-NL":
      case "nl-BE":
        return "nl";
      case "fr":
      case "fr-FR":
      case "fr-BE":
        return "fr";
    }
    return "en";
  }

  initialize = () => {
    window.addEventListener("storage", (event) => {
      if (event.key === _localStorageKeys.isAuthenticated) {
        this.authenticate();
      }
    });

    this.userManager.events.addUserLoaded((args) => {
      this.userManager.getUser()
        .then(this.loadProfile)
        .then(x => {
          this.language = x.language;
        });
    });

    this.headers = {
      "Content-Type": "application/json",
      TenantId: this.tenant,
    };

    return this.getTenantMetadata()
      .then((meta) => {
        this.productType =
          meta.productType === "Helios"
            ? ProductType.Helios
            : ProductType.Hyperion;
        this.language = this.language || meta.language;
        this.configuredProviders = meta.providers;

        const host = window.location.hostname.toLowerCase();
        this.vNextModules = meta.vNextModules || [];

        let redirectTo;
        
        const location = window.location;
        if (meta.classicPath) {
          this.classicRouter = createClassicRouter(
            meta.classicPath,
            meta.vNextModules || []
          );
          const path = location.pathname;
          const query = location.search;
          redirectTo = this.classicRouter.redirectTo(host, path, query);
        }
        if ((location.pathname || "").startsWith("//")) {
          redirectTo = new URL("/" + location.pathname.replace(/^\/+/g, '') + location.search, location.origin);
        }

        if (redirectTo) {
          window.location = redirectTo;
          return new Promise(() => { });
        } else {
          return this.authenticate();
        }
      })
      .catch((reason) => {
        switch (this.getNavigatorLanguageCode()) {
          case "nl":
            Toaster.error(
              "Er ging iets mis! Probeer binnen enkele ogenblikken opnieuw..."
            );
            break;
          case "fr":
            Toaster.error(
              "Connexion a échoué! Veuillez réessayer dans quelques instants..."
            );
            break;
          default:
            Toaster.error(
              "Something went wrong! Please retry in a few moments..."
            );
        }
      });
  };

  loadProfile = (user) => {
    const capturedAccessToken = user["access_token"];
    Api.trySetToken(() => {
      return (user && user["access_token"]) || capturedAccessToken;
    });
    if (!this.isTokenExpired()) {
      return Api.session.get().then((session) => {
        this.profile = this.mapUserToProfile(user, session);
        posthog.identify(this.profile.id, {
          email: (this.profile.email || "").toLowerCase(),
          name: (this.profile.fullName || "").toLowerCase(),
          tenantId: this.profile.tenant
        });
        return this.profile;
      });
    }
    return new Promise(() => { });
  };

  getTenantMetadata = async () => {
    const endpoint = `${this.endpointUrl}/api/meta/${this.tenant}`;
    let response = await fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    let data = await response.json();

    return data;
  };

  authenticationUpdateHandlers = [];
  subscribeToAuthenticationUpdate = (handler) => {
    this.authenticationUpdateHandlers.push(handler);

    if (this.isAuthenticated()) {
      handler();
    }

    return () => {
      const index = this.authenticationUpdateHandlers.indexOf(handler);
      this.authenticationUpdateHandlers.splice(index, 1);
    };
  };

  authenticate = () => {
    const triggerAuthenticationUpdate = () => {
      if (this.authenticationUpdateHandlers.length > 0) {
        for (const handler of this.authenticationUpdateHandlers) {
          handler();
        }
      }
    };

    const completeAuthentication = (user) => {
      if (user) {
        this.loadProfile(user)
          .then(profile => {
            const versionMap = JSON.parse(localStorage.getItem(_localStorageKeys.version)) || {};
            const previousVersion = versionMap[profile.id];
            const currentVersion = profile.version;
            this.language = profile.language;

            versionMap[profile.id] = currentVersion;
            localStorage.setItem(_localStorageKeys.version, JSON.stringify(versionMap));

            const continuation = () => {
              localStorage.setItem(_localStorageKeys.isAuthenticated, "true");
              triggerAuthenticationUpdate();
            };

            const hasResetFlag = window.location.search.indexOf("?hard_reset") === 0;
            const isUserOutdated = (previousVersion > 0 && currentVersion > previousVersion);

            if (hasResetFlag || isUserOutdated) {
              // arxs.logger.warn(`Triggering reset f=${hasResetFlag} ov=${previousVersion} cv=${currentVersion}`);

              CacheStore.purgeDatabase()
                .then(() => {
                  window.history.pushState({}, null, window.location.href.split('?')[0]);
                  continuation();
                });
            } else {
              continuation();
            }
          });
      } else {
        Api.logOff();
        this.profile = null;
        localStorage.removeItem(_localStorageKeys.isAuthenticated);
        triggerAuthenticationUpdate();
      }
      return user;
    };

    if (window.location.search.indexOf("?errorid=") > -1) {
      return this.retrieveError().then((error) =>
        Toaster.error(error || "Something went wrong")
      );
    } else if (window.location.search.indexOf("?code=") > -1) {
      return this.userManager
        .signinRedirectCallback()
        .then(completeAuthentication)
        .then((user) => {
          const {
            state: { returnUrl },
          } = user;
          if (returnUrl) {
            window.history.pushState({}, "ArXs", returnUrl);
          }
          return user;
        })
        .catch(() => {
          Toaster.error("Invalid authentication code");
          setTimeout(() => (window.location.href = "/"), 2000);
        });
    } else {
      return this.userManager.getUser().then(completeAuthentication);
    }
  };

  logOff = () => {
    Api.logOff();
    this.profile = null;
    this.userManager.signoutRedirect();
    this.authenticationUpdateHandlers = [];
  };

  isAuthenticated = () => {
    if (this.profile) {
      return !this.isTokenExpired();
    }
    return false;
  };

  isTokenExpired = () => {
    const token = Api.getToken();
    if (!token) {
      return true;
    }

    var claims = this.parseJwt(token);
    return claims.exp < new Date().getTime() / 1e3;
  }

  parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  };

  mapUserToProfile = (user, session) => {
    var claims = this.parseJwt(user["access_token"]);
    var id = claims["#id"];

    return {
      id,
      id_token: user["id_token"],
      username: claims["#un"],
      tenant: claims["#tn"],
      version: session.version,

      fullName: session.fullName,
      firstName: session.fullName.split(" ")[0],
      email: session.email,
      image: session.avatar,
      assignments: session.assignments,
      trusts: session.trusts,
      allowedActions: session.allowedActions.toDictionary(
        (x) => x,
        (_) => true
      ),
      allowedFeatures: session.allowedFeatures.toDictionary(
        (x) => x,
        (_) => true
      ),
      allowedModules: this.vNextModules.toDictionary(
        (x) => x,
        (_) => true
      ),
      isLocalAdmin: session.isLocalAdmin,
      language: session.language,
      lastModifiedMap: session.lastModifieds.toDictionary(x => x.resource, x => x.at),

      getUserRoleIds: () => Api.lookups.getLookup("userRoleByUserIdMap")[id] || [],
    };
  };

  tryFetchJson = async (url, body, _httpMethod, onError) => {
    return fetch(`${this.endpointUrl}${url}`, {
      method: _httpMethod,
      headers: this.headers,
      credentials: "include",
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response.statusText);
        }
        return response.json().catch((_) => {
          if (onError) {
            onError();
          } else {
            Toaster.error("Er ging iets mis...");
          }
          return {};
        });
      })
      .catch((error) => {
        if (onError) {
          onError();
        } else {
          Toaster.error(error || "Er ging iets mis...");
        }
        return {};
      });
  };

  retrieveError = async () => {
    var query = window.location.search;
    var errorIdQuery =
      query && query.toLowerCase().indexOf("?errorid=") === 0 && query;

    const response = await fetch(
      `${this.endpointUrl}/api/authenticate/error` + errorIdQuery,
      {
        credentials: "include",
        "content-type": "application/json",
      }
    );
    const data = await response.text();
    return data;
  };

  socialLogin = async (provider) => {
    var signinrequest = await this.userManager.createSigninRequest({
      state: { returnUrl: window.location.href },
    });

    await fetch(signinrequest.url, {
      method: "GET",
    }).then((response) => {
      if (response.redirected) {
        window.location.href =
          `${this.endpointUrl}/api/External/Challenge` +
          response.url.substr(response.url.indexOf("?")) +
          `&provider=${provider}&tenantId=${this.tenant}`;
      }
    });
  };

  arxsLogin = async (userName, password, rememberme, onError) => {
    const signinRequest = await this.userManager.createSigninRequest({
      state: { returnUrl: window.location.href },
    });

    const body = JSON.stringify({
      username: userName,
      password: password,
      rememberMe: rememberme,
      returnUrl: `${this.endpointUrl}/connect/authorize?${signinRequest.url.split("?")[1]
        }`,
    });

    return this.tryFetchJson(
      "/api/authenticate",
      body,
      _httpMethod.post,
      onError
    );
  };

  forgotPassword = async (userName) => {
    const body = JSON.stringify({
      userName: userName,
      tokenUrl: `${window.location.href}?token=`,
    });
    return this.tryFetchJson(
      "/api/credential/forgotpasswordrequest",
      body,
      _httpMethod.post
    );
  };

  resetPassword = async (userName, token, password) => {
    const body = JSON.stringify({
      userName: userName,
      token: token,
      password: password,
    });

    return this.tryFetchJson(
      "/api/credential/resetpassword",
      body,
      _httpMethod.post
    );
  };

  verifyResetToken = async (token) => {
    const body = JSON.stringify({
      token: token,
    });

    return this.tryFetchJson(
      "/api/credential/verifytoken",
      body,
      _httpMethod.post
    );
  };
}

export default new Identity();
