import React, { Component } from "react";
import arxs from "infra/arxs";
import GlobalContext, { GlobalContextProvider } from "infra/GlobalContext";
import { Loading } from "components/shell/Loading";
import { Login } from "components/authentication/Login";
import Layout from "components/shell/Layout";

import "./site.scss";

export default class App extends Component {
  static displayName = App.name;

  state = { isLoaded: false, isAuthenticated: false };

  constructor(props) {
    super(props);

    arxs.onReadyForLogin.then((t) => {
      const splashTimeout = 1000; // ms
      setTimeout(() => this.setState({ isLoaded: true }), splashTimeout);
    });
    
    arxs.onLoggedIn.then((t) => {
      this.setState({ isAuthenticated: true });
    });
  }

  render() {
    return (
      <GlobalContextProvider>
        <GlobalContext.Consumer>
          {(context) =>
            !this.state.isLoaded ? (
              <Loading />
            ) : this.state.isAuthenticated ? (
              <Layout />
            ) : (
              <Login />
            )
          }
        </GlobalContext.Consumer>
      </GlobalContextProvider>
    );
  }
}
