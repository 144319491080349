import React, { useContext} from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';

import QRScanner from 'components/shell/Search/QRScanner';

import "./Search.scss";

export default function Search(props) {
    const context = useContext(GlobalContext);

    return (<div className="search">
        <i className="far fa-search flip-horizontally"></i>
        <div className="search-input">
            <input type="text" value={props.value} onChange={props.onChange}
                placeholder={props.placeholder || arxs.t("controls.common.search_placeholder")} />
        </div>
        {context.platform.isMobile 
            ? <QRScanner history={props.history} onSearchResult={props.onSearchResult}/>
            : <div className="search-filter">
                <i className="far fa-filter"></i>
            </div>}            
    </div>);
}