import React from 'react';
import GlobalContext from 'infra/GlobalContext';

import './WizardNavigator.scss';

export default function WizardNavigator(props) {
  const onClickAction = () => {
    if (props.action) {
      if (props.action.onClick) {
        props.action.onClick();
      }
    }
  }

  return <GlobalContext.Consumer>
    {(context) => context.platform.isMobile
      ? <></>
      : <div className="wizard-navigator">
        <ul>
          {props.steps.map((step, i) =>
            <li key={`navigator-step-${i + 1}`}
              className={(props.stepIndex === i) ? "active" : ""}
              onClick={() => props.onNavigate(i)}
            >
              <div className="circle">{i + 1}</div>
              <div className="title">{step.title}</div>
            </li>)}
        </ul>
        {props.action && <div className="action" onClick={onClickAction}>
          {props.action.title}
        </div>}
      </div>}
  </GlobalContext.Consumer>;
}
