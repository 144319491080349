import React, { useRef, useState, useEffect } from "react";
import { BryntumScheduler } from '@bryntum/scheduler-react-thin';

// import '@bryntum/scheduler-thin/scheduler.classic-light.css';

export default function Diagnostics(props) {
  const schedulerRef = useRef();

  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [assignments, setAssignments] = useState([]);

  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    setResources([
      { id: 1, name: "Resource 1" }
    ]);

    setEvents([
      { id: 1, name: "Test 1", startDate: new Date(), duration: 10 }
    ]);

    setAssignments([
      { id: 1, resourceId: "1", resource: "1", eventId: 1, event: 1 },
    ]);
  }, []);

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);

    schedulerRef.current.instance.eventStore.filter({
      filters: event => event.name.match(new RegExp(e.target.value, 'i')),
      replace: true
    });
  }

  const filtered = events;
  //.filter(x => !searchTerm || (x.name || "").search(new RegExp(searchTerm, "i")) > -1);

  return <div>
    <input type="string" onChange={handleSearchTerm} />
    <BryntumScheduler
      ref={schedulerRef}
      resources={resources}
      events={filtered}
      assignments={assignments}
    />
  </div>;
}