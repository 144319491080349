import React, { Component } from 'react';
import { List } from 'react-virtualized';
import AutoSizer from 'react-virtualized-auto-sizer';

import TreeView from 'components/controls/treeview/TreeView'
import TagTreeview from 'components/controls/tags/TagTreeView'
import { Card } from 'components/card/Card';

import arxs from 'infra/arxs';

import './Tree.scss';
import { OriginModuleEnum } from 'infra/api/contracts';

export default class Tree extends Component {
  lookups = {
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.lookups,
      selected: {},
      filteredCards: [],
      cardWidth: 280,
      uniqueNodeIds: {},
      itemsPerType: {}
    };
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
    };

    this.refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    let dataChanged = false;

    if (!dataChanged && prevProps.data.length !== this.props.data.length) {
      dataChanged = true;
    }

    if (!dataChanged) {
      for (const listItem of this.props.data) {
        const prevListItem = prevProps.data.filter(x => x.key === listItem.key)[0];
        if (!dataChanged && !prevListItem) {
          dataChanged = true;
          break;
        }

        if (!dataChanged && listItem.cards.length !== prevListItem.cards.length) {
          dataChanged = true;
          break;
        }
      }
    }

    if (dataChanged) {
      this.refresh();
    }
  }

  refresh = () => {
    const items = (this.props.data || [])
      .flatMap(x => x.cards)
      .filter(x => !x.isDeleted);

    const uniqueNodeIds = items.map(card => card.sortId)
      .concat(items.map(card => card.kindId))
      .concat(items.map(card => card.typeId))
      .filter(x => x !== undefined)
      .toDictionary(x => x);

    const itemsPerType = items
      .groupBy(card => card.typeId)
      ;

    this.setState({ filteredCards: items.filter(x => !x.isDeleted), uniqueNodeIds: uniqueNodeIds, itemsPerType: itemsPerType });
  }

  onItemClick = (item, children) => {
    if (item) {
      this.setState({ selectedTreeViewItem: item, selectedTreeViewItemChildren: children });

      const items = (this.props.data || [])
        .flatMap(x => x.cards)
        .filter(x => !x.isDeleted);

      if (item.type && items) {
        let filteredCards = [];

        switch (item.type) {
          case arxs.codeElementTypes.sort:
            filteredCards = items.filter(x => x.sortId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          case arxs.codeElementTypes.kind:
            filteredCards = items.filter(x => x.kindId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          case arxs.codeElementTypes.type:
            filteredCards = items.filter(x => x.typeId === item.id);
            this.setState({ filteredCards: filteredCards });
            break;
          default: return;
        }
      }
    }
  }

  treeFilter = (nodeId) => {
    return this.state.uniqueNodeIds && Object.keys(this.state.uniqueNodeIds).some(x => x === nodeId);
  }

  render() {
    const { filteredCards } = this.state;
    const { module } = this.props;

    const additionalModuleMap = {
      "Form": [OriginModuleEnum.Task, OriginModuleEnum.NotificationDefect, OriginModuleEnum.PeriodicControl, OriginModuleEnum.PeriodicMaintenance],
      "Periodical": [OriginModuleEnum.Task, OriginModuleEnum.NotificationDefect, OriginModuleEnum.PeriodicControl, OriginModuleEnum.PeriodicMaintenance],
      "InstructionCard": [OriginModuleEnum.Building, OriginModuleEnum.Room, OriginModuleEnum.Labourmeans, OriginModuleEnum.EquipmentInstallation, OriginModuleEnum.CombinedInstallation, OriginModuleEnum.HazardousSubstance, OriginModuleEnum.IntangibleAsset, OriginModuleEnum.Pbm],
      "SafetyInstructionCard": [OriginModuleEnum.Building, OriginModuleEnum.Room, OriginModuleEnum.Labourmeans, OriginModuleEnum.EquipmentInstallation, OriginModuleEnum.CombinedInstallation, OriginModuleEnum.HazardousSubstance, OriginModuleEnum.IntangibleAsset, OriginModuleEnum.Pbm],
      "Commissioning": [OriginModuleEnum.Building, OriginModuleEnum.Room, OriginModuleEnum.Labourmeans, OriginModuleEnum.EquipmentInstallation, OriginModuleEnum.CombinedInstallation, OriginModuleEnum.HazardousSubstance, OriginModuleEnum.IntangibleAsset, OriginModuleEnum.Pbm],
    }

    const getTreeView = () => {
      switch (module.toLowerCase()) {
        case "tags": return <TagTreeview
          onItemClick={this.onItemClick} />
        default: return <TreeView
          module={this.props.module}
          itemIcon="fad fa-folder"
          securityContext={arxs.securityContext.buildForUserContext()}
          onItemClick={this.onItemClick}
          filterTree={this.treeFilter}
          additionalModules={additionalModuleMap[module] || []}
          rootExpanded={[OriginModuleEnum.Form, OriginModuleEnum.Periodical].includes(module)}
        />;
      }
    }

    return <div className="tree-wrapper">
      <div className="tree-nav-container">
        <div className="tree-nav">
          {getTreeView()}
        </div>
      </div>
      <div className="tree-card-content">
        <AutoSizer>
          {({ width, height }) => {
            const horizontalPadding = 30;
            const cellWidth = this.state.cardWidth + 20; //Gap
            const slices = filteredCards.orderBy(x => x.uniqueNumber)
              .partition(Math.max(1, Math.floor((width - horizontalPadding) / cellWidth)));
            return <List
              width={width}
              height={height}
              rowCount={slices.length}
              rowHeight={165}
              rowRenderer={({ index, isScrolling, key, style }) => {
                return <div key={key} style={style}>
                  <div className="card-row">
                    {slices[index].map((card, j) =>
                      <Card key={`card-lookup-${index}-${j}`}
                        data={card}
                        showImage
                        onToggle={this.props.onToggle}
                        onCheckChange={this.props.onCheckChange}
                        selected={card.selected}
                        style={{ height: 146 }}
                        actions={card.actions}
                        getCardProps={this.props.getCardProps}
                        checked={card.checked}
                      />)}
                  </div>
                </div>;
              }}
            />;
          }}
        </AutoSizer>
      </div>
    </div>
  }
}