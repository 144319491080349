/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportPageOrientation {
    Portrait = 'Portrait',
    Landscape = 'Landscape'
}

export function ReportPageOrientationFromJSON(json: any): ReportPageOrientation {
    return ReportPageOrientationFromJSONTyped(json, false);
}

export function ReportPageOrientationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPageOrientation {
    return json as ReportPageOrientation;
}

export function ReportPageOrientationToJSON(value?: ReportPageOrientation | null): any {
    return value as any;
}

