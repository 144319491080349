import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { unregister } from './registerServiceWorker';
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import 'react-toastify/dist/ReactToastify.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

posthog.init('phc_cbJv0cmgN20K67CfdkwCuii6iJb9SvAOcIBLxOv8wnY',{
  api_host:'https://eu.i.posthog.com',
  capture_pageview: false,
  autocapture: false,
});

root.render(
  <BrowserRouter basename={baseUrl}>
    <PostHogProvider client={posthog}>
      <App />
      <ToastContainer />
    </PostHogProvider>
  </BrowserRouter>);

unregister();