import arxs from "infra/arxs";
import { FormStatus } from "infra/api/contracts";

import WizardController from "modules/WizardController";

class FormWizard extends WizardController {
  setDefaultVersionDate = (stateProxy, field, row) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");

      const values = data[field] || pristine[field] || [];

      if (
        row === undefined &&
        values.some((x) => Object.keys(x).length === 0)
      ) {
        const newRow = {
          versionDate: new Date(),
          version: "",
          description: "",
        };
        const newValues = values
          .filter((x) => x.versionDate !== undefined)
          .concat(newRow);

        const newData = {
          ...data,
          [field]: newValues,
        };

        stateProxy.setter({ data: newData }, resolve);
      } else {
        resolve();
      }
    });
  };

  clearCategory = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      const newData = {
        ...data,
        category: {},
        type: {},
        kind: {},
      };

      stateProxy.setter({ data: newData }, resolve);
    });
  };

  toggleGlobalAuthorization = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      if (data.isGlobalAuthorization) {
        const newData = {
          ...data,
          legalStructureAuthorizations: [],
          branchAuthorizations: [],
          employeeAuthorizations: [],
          userRoleAuthorizations: [],
        };

        stateProxy.setter({ data: newData }, resolve);
      } else {
        const pristine = stateProxy.getter("pristine");
        const newData = {
          ...data,
          legalStructureAuthorizations: pristine.legalStructureAuthorizations,
          branchAuthorizations: pristine.branchAuthorizations,
          employeeAuthorizations: pristine.employeeAuthorizations,
          userRoleAuthorizations: pristine.userRoleAuthorizations,
        };

        stateProxy.setter({ data: newData }, resolve);
      }
    });
  };

  toggleGlobalScope = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      if (data.isGlobalScope) {
        const newData = {
          ...data,
          legalStructures: [],
          branches: [],
        };

        stateProxy.setter({ data: newData }, resolve);
      } else {
        const pristine = stateProxy.getter("pristine");
        const newData = {
          ...data,
          legalStructures: pristine.legalStructures,
          branches: pristine.branches,
        };

        stateProxy.setter({ data: newData }, resolve);
      }
    });
  };

  hasOneOrMoreValues = (getCurrentFieldValue, fieldName) => {
    const rawValue = getCurrentFieldValue(fieldName);
    return rawValue && rawValue.length > 0;
  };

  validate(stateProxy, getCurrentFieldValue, preValidation) {
    const isGlobalAuthorization = getCurrentFieldValue("isGlobalAuthorization");
    const isGlobalScope = getCurrentFieldValue("isGlobalScope");

    // If the form is not in process, then it must have at least one authorization
    if (!isGlobalAuthorization) {
      const noLegalStructureAuthorizations = !this.hasOneOrMoreValues(
        getCurrentFieldValue,
        "legalStructureAuthorizations"
      );
      const noBranchAuthorizations = !this.hasOneOrMoreValues(
        getCurrentFieldValue,
        "branchAuthorizations"
      );
      const noUserRoleAuthorizations = !this.hasOneOrMoreValues(
        getCurrentFieldValue,
        "userRoleAuthorizations"
      );
      const noEmployeeAuthorizations = !this.hasOneOrMoreValues(
        getCurrentFieldValue,
        "employeeAuthorizations"
      );
      if (
        noLegalStructureAuthorizations &&
        noBranchAuthorizations &&
        noUserRoleAuthorizations &&
        noEmployeeAuthorizations
      ) {
        preValidation["authorizations"] = {
          error: arxs.t("wizard.validation.field_is_required", {
            field: arxs.t(`field.authorization`),
          }),
        };
      }
    }

    const noLegalStructures = !this.hasOneOrMoreValues(
      getCurrentFieldValue,
      "legalStructures"
    );
    const noBranches = !this.hasOneOrMoreValues(
      getCurrentFieldValue,
      "branches"
    );

    if (!isGlobalScope && noLegalStructures && noBranches) {
      preValidation["scope"] = {
        error: arxs.t("wizard.validation.field_is_required", {
          field: arxs.t(`field.authorization`),
        }),
      };
    }

    // Local admins can create forms without scope
    if (
      !arxs.Identity.profile.isLocalAdmin &&
      (isGlobalScope || (
      noLegalStructures &&
      noBranches))
    ) {
      preValidation["scope"] = {
        error: arxs.t("wizard.validation.one_of_required", {
          field1: arxs.t(`field.legalStructures`),
          field2: arxs.t("field.branches"),
        }),
      };
    }

    var branchMap = stateProxy.getter("branchMap");
    var legalStructureIdsFromBranches = (getCurrentFieldValue("branches") || [])
      .map((x) => branchMap[x.id])
      .map((y) => y.legalStructure.id);
    var legalStructureIds = (getCurrentFieldValue("legalStructures") || []).map(
      (x) => x.id
    );
    var authorizationLegalStructureIdsFromBranches = (
      getCurrentFieldValue("branchAuthorizations") || []
    )
      .map((x) => branchMap[x.id])
      .map((y) => y.legalStructure.id);
    var authorizationLegalStructureIds = (
      getCurrentFieldValue("legalStructureAuthorizations") || []
    ).map((x) => x.id);

    if (
      this.getCommonEntries(legalStructureIds, legalStructureIdsFromBranches)
    ) {
      preValidation["scope"] = {
        error: arxs.t("wizard.validation.overlap_scope", {
          field1: arxs.t(`field.legalStructures`),
          field2: arxs.t("field.branches"),
        }),
      };
    }

    if (
      this.getCommonEntries(
        authorizationLegalStructureIds,
        authorizationLegalStructureIdsFromBranches
      )
    ) {
      preValidation["authorization"] = {
        error: arxs.t("wizard.validation.overlap_authorizations", {
          field1: arxs.t("field.legalStructures"),
          field2: arxs.t("field.branches"),
        }),
      };
    }

    return preValidation;
  }

  showSimpleExpiration = (stateProxy) => {
    const value = stateProxy.getField("schedule");

    if (
      value === undefined ||
      value === null ||
      (value.recurrenceRule &&
        (value.recurrenceRule.indexOf("FREQ=NONE") !== -1 ||
          value.recurrenceRule.indexOf("FREQ=undefined") !== -1))
    ) {
      return true;
    }

    return false;
  };
}
export default new FormWizard();
