import React, { Component } from "react";
import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import Button from "components/controls/Button";
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import { OriginModuleEnum } from 'infra/api/contracts';

import "./Search.scss";

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: null,
    };
  }

  handleChangeSearchTerm = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  onKeyDown = (event, context) => {
    event.stopPropagation();
    switch (event.keyCode) {
      case 9:
      case 13:
        this.onClickSearch(context);
        break;
      default: break;
    }
  }

  onClickSearch = (context) => {
    const { searchTerm } = this.state;


    const onApplyFilter = (arg) => {
      const card = Object.entries(arg).reduce((acc, cur) => ({ module: cur[1], id: cur[0] }), {});
      this.props.history.push({ pathname: `${arxs.moduleMetadataRegistry.getModulePath(card.module)}/${card.id}` });
      context.popup.close();
    }

    context.menu.close();

    const securityContext = arxs.securityContext.buildForUserContext();
    const cardLookup = createCardLookup({
      onApplyFilter,
      singleSelection: true,
      securityContext,
      disallowEmptySelection: true,
      applyFilterText: arxs.t("search.goto_record"),
      disableAddition: true,
      excludeModules: [OriginModuleEnum.UserRole],
      searchTerm: searchTerm
    });
    context.popup.show(cardLookup);

  };

  render() {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="menu-search">
            <input
              type="text"
              placeholder={arxs.t("menu.search")}
              value={this.state.searchTerm}
              onChange={this.handleChangeSearchTerm}
              onKeyDown={(event) => this.onKeyDown(event, context)}
            />
            <i className="far fa-search flip-horizontally"></i>
            <Button
              className="icon"
              onClick={() => this.onClickSearch(context)}
            >
              <i className="far fa-filter"></i>
            </Button>
          </div>

        )}
      </GlobalContext.Consumer>
    );
  }
}
export default Search;
