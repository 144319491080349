import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import GlobalActions from "modules/GlobalActions";
import { createDocumentReactivationPopup } from "./DocumentReactivationPopup";
import {RRule} from 'rrule';
import { DocumentStatus } from "infra/api/contracts";


class DocumentManagementActions {
  getActions = (module) => {
    return GlobalActions.getActions(module).concat([
      {
        name: "activate",
        module: module,
        icon: arxs.actions.icons["reactivate"],
        getTitle: () => arxs.actions.titles["activate"],
        onClick: (state) => this.activate(state),
      },
      {
        name: "set_inprocess",
        module: module,
        icon: arxs.actions.icons["set_inprocess"],
        getTitle: () => arxs.actions.titles["set_inprocess"],
        onClick: (state) => this.setInProcess(state),
      },
    ]);
  };

  activate = (state) => {
    const context = state.context;
    const id = (state.ids && state.ids[0]) || state.item.objectId;
    const card = (state.item && state.item.card) || state.cards[0];
    const schedule = card.schedule;
    const status = card.status;

    const activate = (data) => {
      arxs.ApiClient.shared.documentManagement
        .setActive(data)
        .then(() => Toaster.success(arxs.t("actions.documentmanagement.setactive.confirmation")));
    }

    if (schedule && schedule.recurrenceRule
      && schedule.recurrenceRule.indexOf("FREQ=NONE") === -1
      && schedule.recurrenceRule.indexOf("FREQ=undefined") === -1) {
      const options = RRule.parseString(schedule.recurrenceRule);
      const dtstart = new Date();
      const rule = new RRule({ ...options, dtstart, count: 1 });
      const dates = rule.all();
      const revisionDate = dates[0]; //returns the first occurence of the recurrenceRule based on the start date.
      const data = { id, revisionDate };

      activate(data);
    } else {
      const executeAction = (data) => {
        activate(data);
        context.popup.close();
      };

      const data = { id: id, revisionDate: card.revisionDate };

      if (status !== DocumentStatus.InProcess) {
        const reactivateDocumentPopup = createDocumentReactivationPopup(
          data,
          executeAction,
          context.popup.close
        );
        context.popup.show(reactivateDocumentPopup);
      } else {
        activate(data);
      }
    }
  };

  setInProcess = (state) => {
    const ids = state.ids || [state.item.objectId];
    const data = { ids: ids };
    return arxs.ApiClient.shared.documentManagement
      .setInProcess(data)
      .then(() => Toaster.success(arxs.t("actions.set_inprocess_confirmation")));
  };

  dropActions = () => {
    return {
      onDropInProcess: (state) => {
        this.setInProcess(state);
      },
      onDropInActive: (state) => {
        this.activate(state);
      },
    };
  };
}
export default new DocumentManagementActions();
