import { ArXsState } from "infra/arxs";
import {
  FormStatus,
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
} from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import FormWizard from "./FormWizard";
import FormActions from "./FormActions";
import FormBoard from "./FormBoard";
import FormCard from "./FormCard";

const getFormMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Form;

  return {
    module,
    title: arxs.t("modules.form"),
    icon: "fab fa-wpforms",
    path: "/form",
    base: {
      name: "Form",
      route: "/form",
      getResource: () => arxs.Api.getResource("form"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Form.Write",
      readAction: "Form.Read",
    },
    // settings: {
    //   name: "FormSettings",
    //   route: "/form/settings",
    //   getResource: () => arxs.Api.getResource("form").settings(),
    //   readAction: "Form.Settings.Read",
    //   writeAction: "Form.Settings.Write",
    // },
    defaultDetailsTabIndex: 0,
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructures" },
          { name: "branches" },
          { name: "description" },
          { name: "creationDate" },
          { name: "revisionDate" },
          { name: "targetModule" },
          { name: "codeElements" },
        ],
      },
      {
        title: arxs.t("details_pane.authorization"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructureAuthorizations", fullWidth: true },
          { name: "branchAuthorizations", fullWidth: true },
          { name: "employeeAuthorizations", fullWidth: true },
          { name: "userRoleAuthorizations", fullWidth: true },
        ],
      },
      {
        title: arxs.t("details_pane.relationships"),
        initiallyOpen: true,
        fields: [{ name: "relationships" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [ObjectDocumentType.AdditionalDocument],
    statuses: [
      FormStatus.InProcess,
      FormStatus.Active,
      FormStatus.ToExpire,
      FormStatus.Expired,
    ],
    actions: FormActions.getActions(OriginModuleEnum.Form),
    formCodesOverride: [
      { module: OriginModuleEnum.Task, code: "Task.Kind" },
      { module: OriginModuleEnum.PeriodicControl, code: "Control.ControlType" },
      { module: OriginModuleEnum.PeriodicMaintenance, code: "PeriodicMaintenance.MaintenanceType" },
      // { module: OriginModuleEnum.InstructionCard, code: "HazardousSubstance.Sort" },
      // { module: OriginModuleEnum.SafetyInstructionCard, code: "IntangibleAsset.Kind" },
      // { module: OriginModuleEnum.Commissioning, code: "IntangibleAsset.Kind" },
      { module: OriginModuleEnum.RiskAnalysis, code: "RiskAnalysis.Kind" },
      { module: OriginModuleEnum.Consultancy, code: "Recommendation.Kind" },
    ],
    wizard: {
      wizardClass: FormWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              { name: "title" },
              {
                name: "creationDate",
              },
            ],
            [{ name: "description" }],
            [
              {
                name: "targetModule",
                props: { width: "310px" },
                values: [
                  OriginModuleEnum.Task,
                  OriginModuleEnum.PeriodicControl,
                  OriginModuleEnum.PeriodicMaintenance,
                  OriginModuleEnum.InstructionCard,
                  OriginModuleEnum.SafetyInstructionCard,
                  OriginModuleEnum.Commissioning,
                  OriginModuleEnum.OutOfCommissioning,
                  OriginModuleEnum.RiskAnalysis,
                  OriginModuleEnum.Consultancy
                ],
                onChange: (stateProxy) =>
                  FormWizard.clearCategory(stateProxy)
              },
              {
                name: "subjectModule",
                props: { width: "310px" },
                values: [
                  OriginModuleEnum.Building,
                  OriginModuleEnum.Room,
                  OriginModuleEnum.Labourmeans,
                  OriginModuleEnum.Pbm,
                  OriginModuleEnum.EquipmentInstallation,
                  OriginModuleEnum.HazardousSubstance,
                  OriginModuleEnum.IntangibleAsset,
                ]
              },
            ],
            [
              {
                name: "codeElements",
                type: "codeelementlist",
                title: arxs.t("field.codeElements")
              },
              {
                name: "status",
                values: [
                  FormStatus.InProcess,
                  FormStatus.Active,
                ],
                props: { required: true, disabledOnEdit: true },
              },
            ],
            [{ name: "tags", type: "tag" }],
            [
              {
                title: arxs.t("field.versions"),
                name: "versions",
                type: "itemlist",
                children: [
                  { name: "version", width: "100px" },
                  { name: "versionDate", width: "150px" },
                  { name: "description", multiLine: false },
                ],
                onChange: (
                  stateProxy: any,
                  fieldName: any,
                  row: any,
                  childName: any
                ) =>
                  FormWizard.setDefaultVersionDate(stateProxy, fieldName, row),
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructures", "branches"].map(getValue);
            if (args.some((x : any) => x && x.length > 0)) {
              return arxs.securityContext.buildForMultipleContext(
                writeAction,
                ...(args as any)
              );
            }
            return arxs.securityContext.buildForUserContext();
          },
        },
        {
          title: arxs.t("details_pane.scope"),
          fields: [
            [{ name: "isGlobalScope", onChange:(stateProxy, fieldName, row, childName) => FormWizard.toggleGlobalScope(stateProxy) }],
            [
              {
                name: "legalStructures",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
              },
            ],
            [
              {
                name: "branches",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.School],
                  overridePrefilter: true,
                  condensed: true,
                  expandable: true,
                },
              },
            ],
          ],
          toggleFieldVisibility : () => {
            return [{isGlobalScope: {
              default: "false",
              false: [],
              true: ["legalStructures",
              "branches"]
            }}]
          }
        },
        {
          title: arxs.t("details_pane.read_rights"),
          fields: [
            [{ name: "isGlobalAuthorization", onChange:(stateProxy, fieldName, row, childName) => FormWizard.toggleGlobalAuthorization(stateProxy) }],
            [
              {
                name: "legalStructureAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.SchoolGroup],
                  condensed: true,
                  expandable: true,
                  overridePrefilter: true,
                },
                title: arxs.t("field.legalStructures"),
              },
            ],
            [
              {
                name: "branchAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.School],
                  condensed: true,
                  expandable: true,
                  overridePrefilter: true,
                },
                title: arxs.t("field.branches"),
              },
            ],
            [
              {
                name: "employeeAuthorizations",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "userRoleAuthorizations",
                type: "cardlist",
                props: {
                  modules: ["UserRole"],
                  overridePrefilter: true,
                  condensed: true,
                },
              },
            ],
          ],
          toggleFieldVisibility : () => {
            return [{isGlobalAuthorization: {
              default: "false",
              false: [],
              true: ["legalStructureAuthorizations",
              "branchAuthorizations","employeeAuthorizations","userRoleAuthorizations"]
            }}]
          }
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                  overridePrefilter: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc], overridePrefilter: true
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.expiration"),
          fields: [
            [
              {
                name: "schedule",
                props: {
                  allowedRecurrencePatternValues: ["None", "Monthly", "Yearly"],
                },
              },
            ],
            [
              {
                name: "revisionDate",
                isVisible: (stateProxy) =>
                  FormWizard.showSimpleExpiration(stateProxy),
              },
              {
                name: "notification",
                isVisible: (stateProxy) =>
                  FormWizard.showSimpleExpiration(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.form"),
          fields: [
            [
              {
                name: "estimatedDuration",
                unit: arxs.t("unit.hour").toLowerCase(),
                props: { wholeNumber: true },
              },
              { name: "estimatedCost" },
              {
                name: "formDefinition",
                // filter: (stateProxy, module) => {
                //   FormWizard.getModuleFilter(stateProxy, module);
                // },
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              {
                name: "documents",
                type: "document",
              },
            ],
          ],
        },
      ],
      fieldsWithIdsForDuplication: ["versions"],
      lookups: {
        employeeMap: {},
        branchMap: {}
      },
      addNewState: "Active",
    },
    board: {
      lookups: {
        forms: [],
        legalStructureMap: {},
        branchMap: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        codeElementsById: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructureNames" },
        { name: "branchNames" },
        { name: "targetModule" },
        { name: "subjectModule" },
        { name: "codeElements" },
        { name: "title" },
        { name: "description" },
        { name: "creationDate" },
        { name: "revisionDate" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "mostRecentVersion" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: FormBoard,
      bucketConfig: [
        {
          module: module,
          status: "InProcess",
          onDrop: (state) => FormActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
        {
          module: module,
          status: "Active",
          onDrop: (state) => FormActions.dropActions().onDropInActive(state),
          requiredActions: ["activate"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
    },
    cardClass: FormCard,
  };
};
export default getFormMetaData;
