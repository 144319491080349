import React, { Component, Fragment } from "react";
import ItemSelector from "components/controls/ItemSelector";

import "./TeamSelector.scss";

export default class TeamSelector extends Component {
  renderItem(item) {
    return <Fragment>
      <i className="far fa-user-tag"></i>
      <span>{item.name}</span>
    </Fragment>;
  }

  render() {
    return <ItemSelector
      className="team-selector-item"
      data={this.props.data}
      selected={this.props.selected}
      onChange={this.props.onChange}
      placeholder={this.props.placeholder}
      renderItem={this.renderItem}
    />;
  }
}