/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BulkAction {
    Replace = 'Replace',
    Append = 'Append'
}

export function BulkActionFromJSON(json: any): BulkAction {
    return BulkActionFromJSONTyped(json, false);
}

export function BulkActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkAction {
    return json as BulkAction;
}

export function BulkActionToJSON(value?: BulkAction | null): any {
    return value as any;
}

