import React from "react";
import { Link } from "react-router-dom";
import GlobalContext from "infra/GlobalContext";

export default function MenuTile(props) {
  let isMobile = !!props.isMobile;
  let name = props.name || (isMobile ? props.title : "");
  let title = props.title || name;
  let nodeRoute = props.route;
  let currentRoute = props.currentRoute;
  let isActive =
    !!currentRoute &&
    (nodeRoute === currentRoute ||
      (props.items && props.items.some((x) => x.route === currentRoute)));
  let items = props.items && (
    <ul>
      {props.items.map((item, i) => (
        <li key={props.key + "_" + i}>
          <MenuTile {...item} isMobile={isMobile} currentRoute={currentRoute} />
        </li>
      ))}
    </ul>
  );

  const className = ["tile", isActive && "active", props.tileClassName]
    .filter((x) => x)
    .join(" ");

  const onClick = (context) => {
    if (!props.items || props.items.length === 0) {
      if (props.onClick) {
        props.onClick(context);
      }
    }
  };

  const getContent = (context) => {
    return props.getContent ? (
      props.getContent()
    ) : (
      <div className={className} title={title} onClick={() => onClick(context)}>
        <div className="tile-inner">
          <i className={props.className}></i>
          {name && <div>{name}</div>}
        </div>
        {items}
      </div>
    );
  };

  return (
    <GlobalContext.Consumer>
      {(context) =>
        props.route ? (
          <Link to={props.route} onClick={context.menu.close}>
            {getContent(context)}
          </Link>
        ) : (
          getContent(context)
        )
      }
    </GlobalContext.Consumer>
  );
}
