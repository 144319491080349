import React from 'react';
import arxs from 'infra/arxs';

import "./RouteRestricted.scss";

export default function RouteRestricted(props) {
  const title = arxs.t("right.access_is_restricted_title");
  const { module, action } = props;
  const moduleTitle = module && arxs.modules.titles[module];
  const details = moduleTitle ? ` ( ${moduleTitle}:${action} )` : "";
  return <div className="route-restricted">
    <div className="route-restricted-title">{title}</div>
    <div className="route-restricted-splash">
      <img src="/images/stop.jpg" alt="Stop" />
      <span>{arxs.t("right.access_is_restricted", { details })}</span>
    </div>
  </div>;
}