import { ArXsState } from "infra/arxs";
import { OriginModuleEnum, ObjectDocumentType } from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";

import ContactActions from "./ContactActions";
import ContactBoard from "./ContactBoard";
import ContactWizard from "./ContactWizard";
import ContactCard from "./ContactCard";

const getContactMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Contact;

  return {
    module,
    title: arxs.t("modules.contact"),
    icon: "fa fa-address-card",
    path: "/contact",
    base: {
      name: "Contact",
      route: "/contact",
      getResource: () => arxs.Api.getResource("contact"),
      subscribe: (id, module, handler) => arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Contact.Write",
      readAction: "Contact.Read",
    },
    settings: {
      name: "ContactSettings",
      route: "/contact/settings",
      getResource: () => arxs.Api.getResource("contact").settings(),
      readAction: "Contact.Settings.Read",
      writeAction: "Contact.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "firstname" },
          { name: "surname" },
          { name: "emails", fullWidth: true },
          { name: "phones", fullWidth: true },
          { name: "nationalNumber" },
          { name: "department" },
          { name: "function" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument
    ],
    statuses: [],
    actions: ContactActions.getActions(module),
    wizard: {
      wizardClass: ContactWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "firstname" }, { name: "surname" }],
            [
              {
                name: "function",
                code: "Contact.Function",
              },
              {
                name: "department",
                code: "Contact.Department",
              },
            ],
            [{ name: "nationalNumber" }],
            [
              {
                name: "suppliers",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  overridePrefilter: true,
                },
                title: arxs.t("field.suppliers"),
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "nationalities",
                type: "itemlist",
                noHeaders: true,
                children: [
                  {
                    name: "name",
                    code: "Nationality",
                  },
                ],
              },
            ],
            [
              {
                title: arxs.t("field.address"),
                name: "addresses",
                type: "itemlist",
                preferredOnly: true,
                children: [
                  { name: "country", width: "100px" },
                  { name: "zipCode", width: "60px" },
                  { name: "city", width: "150px" },
                  { name: "street" },
                  { name: "number", width: "60px" },
                  { name: "bus", width: "50px" },
                ],
              },
            ],
            [
              {
                title: arxs.t("field.email"),
                name: "emails",
                type: "itemlist",
                noHeaders: true,
                preferredOnly: true,
                children: [{ name: "email" }],
              },
              {
                title: arxs.t("field.phone"),
                name: "phones",
                type: "itemlist",
                noHeaders: true,
                preferredOnly: true,
                children: [{ name: "number" }],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
    },
    board: {
      lookups: {
        tagMap: {},
        contacts: [],
        supplierMap: {},
        codeElementsById: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "firstname" },
        { name: "surname" },
        { name: "email" },
        { name: "address" },
        { name: "phone" },
        { name: "function" },
        { name: "department" },
        { name: "suppliers" },
        { name: "nationalNumber" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: ContactBoard,
      views: [BoardViewType.Grid],
    },
    cardClass: ContactCard
  };
};
export default getContactMetaData;
