import React, { Component } from 'react';

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { TagRef } from 'infra/api/contracts';

import TagLabel from 'components/controls/tags/TagLabel'
import { createTagLookup } from 'components/controls/tags/TagLookup';
import SelectionLabel from '../selectionLabel/SelectionLabel';

import './TagOverview.scss';

export interface ITag {
    name: string,
    id: string
}

export interface ITagOverviewProps {
    module: string,
    tags: Array<TagRef>,
    readOnly: boolean,
    onDelete(value: TagRef): void,
    onAdd(value: Array<TagRef>): void,
    title?: string,
    className?: string,
}

export interface ISubscription {
    dispose?(): void,
}

export interface ITagOverviewState {
    tagLookup?: any,
    tagMap?: { [key: string]: ITag, }
}

export default class TagOverview extends Component<ITagOverviewProps, ITagOverviewState> {
    state: ITagOverviewState = {}
    lookups = { tagMap: {} }
    subscriptions?: { lookups: ISubscription }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups: any) => this.setState({ ...lookups }))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions && this.subscriptions.lookups.dispose) {
            this.subscriptions.lookups.dispose();
        }
    }

    handleAddTags = (context: { popup: { close: () => void; show: (arg0: any) => void; }; }) => {
        const { module } = this.props;
        const onSubmit = (selectedIds: Array<string>) => {
            context.popup.close();
            if (this.props.onAdd) {
                this.props.onAdd(selectedIds.map(id => ({ id })));
            }
        };
        let tagLookup = createTagLookup(module, null, onSubmit);
        this.setState({ tagLookup: tagLookup }, () => context.popup.show(this.state.tagLookup));
    }

    onDelete = (tag: TagRef) => {
        if (this.props.onDelete) {
            this.props.onDelete(tag);
        }
    }

    render() {
        const tagMap = this.state.tagMap || {};

        return (
            <GlobalContext.Consumer>
                {(context) =>
                    <div className={`tag-overview ${this.props.className || ''}`}>
                        {this.props.title && <label>{this.props.title}</label>}
                        {this.props.tags && this.props.tags.length > 0 && <div className="tag-overview-content">
                            {this.props.tags
                                .flatMap(tag => tag.id ? [tagMap[tag.id]] : [])
                                .filter(x => x)
                                .distinct(x => x.id)
                                .map((tag) => <TagLabel
                                    name={tag.name}
                                    id={tag.id}
                                    key={tag.id}
                                    onDelete={() => this.onDelete(tag)}
                                    readOnly={this.props.readOnly} />)}
                        </div>}
                        {!this.props.readOnly && <div className="tag-overview-actions">
                            <SelectionLabel onClick={() => this.handleAddTags(context)} title={arxs.t("item_list.add_item", { name: "Tags" })} />
                        </div>}
                    </div>
                }
            </GlobalContext.Consumer >
        );
    }
}