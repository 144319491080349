import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { BeamerHelmet } from "react-beamer";

import { OriginModuleEnum } from "infra/api/contracts";
import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";

import Wizard from "components/wizard/Wizard";
import Board from "components/board/Board";
import Settings from "components/settings/Settings";
import Toaster from "components/util/Toaster";
import Icons from "components/diagnostics/Icons";
import FormAction from "components/controls/form/FormAction";
import FreshdeskWidget from "components/support/FreshdeskWidget";

import Home from "modules/dashboard/Home";
import Planning from "modules/planning/Planning";
import UserProfile from "modules/profile/UserProfile";
import Diagnostics from "modules/configuration/Diagnostics/Diagnostics";
import GanttEditor from "modules/facilitymanagement/projects/GanttEditor";
import TagManagement from "modules/configuration/TagManagement/TagManagement";
import RoleManagement from "modules/configuration/RoleManagement/RoleManagement";
import ActivityOverview from "modules/shared/ActivityOverview/ActivityOverview";

import Menu from "./Menu/Menu";
import Popup from "./Popup/Popup";
import Header from "./Header/Header";
import RouteRestricted from "./RouteRestricted";
import DetailsPane from "./DetailsPane/DetailsPane";
import OptionPopup from "./OptionPopup/OptionPopup";
import InputPopup from "./InputPopup/InputPopup";
import PromptPopup from "./PromptPopup/PromptPopup";
import PostHogPageviewTracker from "./PostHogPageviewTracker";

import "./Layout.scss";

class Layout extends Component {
  constructor(props) {
    super(props);

    // Hack to make react router navigate to returnUrl without needing to navigate using the browser (cause page reload)
    // This is necessary because Identity does not have access to the react router
    if (props.history.location.pathname !== window.location.pathname) {
      props.history.push(window.location.pathname);
    }

    this.state = {
      selectedCard: {},
      checkedCards: [],
      routes: [
        {
          path: ["/", "/index.html"],
          render: (props) => (
            <Home
              onCheckedCards={this.onCheckedCards}
              {...props}
            />
          ),
        },
        {
          path: "/planning",
          module: OriginModuleEnum.Planning,
          render: (props) => (
            <Planning
              onCheckedCards={this.onCheckedCards}
              {...props}
            />
          ),
          disableOnPhone: true
        },
        {
          path: "/labourmeans/create",
          action: "LabourMeans.Write",
          module: OriginModuleEnum.Labourmeans,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Labourmeans} {...props} />
          ),
        },
        {
          path: "/labourmeans/edit/:id",
          action: "LabourMeans.Write",
          module: OriginModuleEnum.Labourmeans,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Labourmeans} {...props} />
          ),
        },
        {
          path: "/labourmeans/duplicate/:id",
          action: "LabourMeans.Write",
          module: OriginModuleEnum.Labourmeans,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Labourmeans} {...props} duplicate />
          ),
        },
        {
          path: "/labourmeans/settings",
          action: "LabourMeans.Settings.Write",
          module: OriginModuleEnum.Labourmeans,
          render: (props) => (
            <Settings module={OriginModuleEnum.Labourmeans} {...props} />
          ),
        },
        {
          path: "/labourmeans/:searchTerm?",
          action: "LabourMeans.Read",
          module: OriginModuleEnum.Labourmeans,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Labourmeans}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/equipment/create",
          action: "Equipment.Write",
          module: OriginModuleEnum.EquipmentInstallation,
          render: (props) => (
            <Wizard
              module={OriginModuleEnum.EquipmentInstallation}
              {...props}
            />
          ),
        },
        {
          path: "/equipment/edit/:id",
          action: "Equipment.Write",
          module: OriginModuleEnum.EquipmentInstallation,
          render: (props) => (
            <Wizard
              module={OriginModuleEnum.EquipmentInstallation}
              {...props}
            />
          ),
        },
        {
          path: "/equipment/duplicate/:id",
          action: "Equipment.Write",
          module: OriginModuleEnum.EquipmentInstallation,
          render: (props) => (
            <Wizard
              module={OriginModuleEnum.EquipmentInstallation}
              {...props}
              duplicate
            />
          ),
        },
        {
          path: "/equipment/settings",
          action: "Equipment.Settings.Write",
          module: OriginModuleEnum.EquipmentInstallation,
          render: (props) => (
            <Settings
              module={OriginModuleEnum.EquipmentInstallation}
              {...props}
            />
          ),
        },
        {
          path: "/equipment/:searchTerm?",
          action: "Equipment.Read",
          module: OriginModuleEnum.EquipmentInstallation,
          render: (props) => (
            <Board
              module={OriginModuleEnum.EquipmentInstallation}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/intangibleAsset/create",
          action: "IntangibleAsset.Write",
          module: OriginModuleEnum.IntangibleAsset,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IntangibleAsset} {...props} />
          ),
        },
        {
          path: "/intangibleAsset/edit/:id",
          action: "IntangibleAsset.Write",
          module: OriginModuleEnum.IntangibleAsset,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IntangibleAsset} {...props} />
          ),
        },
        {
          path: "/intangibleAsset/duplicate/:id",
          action: "IntangibleAsset.Write",
          module: OriginModuleEnum.IntangibleAsset,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IntangibleAsset} {...props} duplicate />
          ),
        },
        {
          path: "/intangibleAsset/settings",
          action: "IntangibleAsset.Settings.Write",
          module: OriginModuleEnum.IntangibleAsset,
          render: (props) => (
            <Settings module={OriginModuleEnum.IntangibleAsset} {...props} />
          ),
        },
        {
          path: "/intangibleAsset/:searchTerm?",
          action: "IntangibleAsset.Read",
          module: OriginModuleEnum.IntangibleAsset,
          render: (props) => (
            <Board
              module={OriginModuleEnum.IntangibleAsset}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/protectionequipment/create",
          action: "ProtectionEquipment.Write",
          module: OriginModuleEnum.Pbm,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Pbm} {...props} />
          ),
        },
        {
          path: "/protectionequipment/edit/:id",
          action: "ProtectionEquipment.Write",
          module: OriginModuleEnum.Pbm,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Pbm} {...props} />
          ),
        },
        {
          path: "/protectionequipment/duplicate/:id",
          action: "ProtectionEquipment.Write",
          module: OriginModuleEnum.Pbm,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Pbm} {...props} duplicate />
          ),
        },
        {
          path: "/protectionequipment/settings",
          action: "ProtectionEquipment.Settings.Write",
          module: OriginModuleEnum.Pbm,
          render: (props) => (
            <Settings module={OriginModuleEnum.Pbm} {...props} />
          ),
        },
        {
          path: "/protectionequipment/:searchTerm?",
          action: "ProtectionEquipment.Read",
          module: OriginModuleEnum.Pbm,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Pbm}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/hazardoussubstance/create",
          action: "HazardousSubstance.Write",
          module: OriginModuleEnum.HazardousSubstance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.HazardousSubstance} {...props} />
          ),
        },
        {
          path: "/hazardoussubstance/edit/:id",
          action: "HazardousSubstance.Write",
          module: OriginModuleEnum.HazardousSubstance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.HazardousSubstance} {...props} />
          ),
        },
        {
          path: "/hazardoussubstance/duplicate/:id",
          action: "HazardousSubstance.Write",
          module: OriginModuleEnum.HazardousSubstance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.HazardousSubstance} {...props} duplicate />
          ),
        },
        {
          path: "/hazardoussubstance/settings",
          action: "HazardousSubstance.Settings.Write",
          module: OriginModuleEnum.HazardousSubstance,
          render: (props) => (
            <Settings module={OriginModuleEnum.HazardousSubstance} {...props} />
          ),
        },
        {
          path: "/hazardoussubstance/:searchTerm?",
          action: "HazardousSubstance.Read",
          module: OriginModuleEnum.HazardousSubstance,
          render: (props) => (
            <Board
              module={OriginModuleEnum.HazardousSubstance}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/combinedinstallation/create",
          action: "CombinedInstallation.Write",
          module: OriginModuleEnum.CombinedInstallation,
          render: (props) => (
            <Wizard module={OriginModuleEnum.CombinedInstallation} {...props} />
          ),
        },
        {
          path: "/combinedinstallation/edit/:id",
          action: "CombinedInstallation.Write",
          module: OriginModuleEnum.CombinedInstallation,
          render: (props) => (
            <Wizard module={OriginModuleEnum.CombinedInstallation} {...props} />
          ),
        },
        {
          path: "/combinedinstallation/settings",
          action: "CombinedInstallation.Settings.Write",
          module: OriginModuleEnum.CombinedInstallation,
          render: (props) => (
            <Settings
              module={OriginModuleEnum.CombinedInstallation}
              {...props}
            />
          ),
        },
        {
          path: "/combinedinstallation/:searchTerm?",
          action: "CombinedInstallation.Read",
          module: OriginModuleEnum.CombinedInstallation,
          render: (props) => (
            <Board
              module={OriginModuleEnum.CombinedInstallation}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/taskrequest/create",
          action: "NotificationDefect.Write",
          module: OriginModuleEnum.NotificationDefect,
          render: (props) => (
            <Wizard module={OriginModuleEnum.NotificationDefect} {...props} />
          ),
        },
        {
          path: "/taskrequest/edit/:id",
          action: "NotificationDefect.Write",
          module: OriginModuleEnum.NotificationDefect,
          render: (props) => (
            <Wizard module={OriginModuleEnum.NotificationDefect} {...props} />
          ),
        },
        {
          path: "/taskrequest/settings",
          action: "NotificationDefect.Settings.Write",
          module: OriginModuleEnum.NotificationDefect,
          render: (props) => (
            <Settings module={OriginModuleEnum.NotificationDefect} {...props} />
          ),
        },
        {
          path: "/taskrequest/:searchTerm?",
          action: "NotificationDefect.Read",
          module: OriginModuleEnum.NotificationDefect,
          render: (props) => (
            <Board
              module={OriginModuleEnum.NotificationDefect}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/task/create",
          action: "Task.Write",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Task} {...props} />
          ),
        },
        {
          path: "/task/edit/:id",
          action: "Task.Write",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Task} {...props} />
          ),
        },
        {
          path: "/task/duplicate/:id",
          action: "Task.Write",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Task} {...props} duplicate />
          ),
        },
        {
          path: "/task/settings",
          action: "Task.Settings.Write",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <Settings module={OriginModuleEnum.Task} {...props} />
          ),
        },
        {
          path: "/task/:searchTerm?",
          action: "Task.Read",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Task}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/task/form/:id",
          module: OriginModuleEnum.Task,
          render: (props) => (
            <FormAction module={OriginModuleEnum.Task} {...props} />
          ),
        },
        {
          path: "/maintenance/create",
          action: "Maintenance.Write",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicMaintenance} {...props} />
          ),
        },
        {
          path: "/maintenance/edit/:id",
          action: "Maintenance.Write",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicMaintenance} {...props} />
          ),
        },
        {
          path: "/maintenance/duplicate/:id",
          action: "Maintenance.Write",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicMaintenance} {...props} duplicate />
          ),
        },
        {
          path: "/maintenance/settings",
          action: "Maintenance.Settings.Write",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <Settings module={OriginModuleEnum.PeriodicMaintenance} {...props} />
          ),
        },
        {
          path: "/maintenance/:searchTerm?",
          action: "Maintenance.Read",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <Board
              module={OriginModuleEnum.PeriodicMaintenance}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/maintenance/form/:id",
          module: OriginModuleEnum.PeriodicMaintenance,
          render: (props) => (
            <FormAction module={OriginModuleEnum.PeriodicMaintenance} {...props} />
          ),
        },
        {
          path: "/inspection/create",
          action: "Control.Write",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicControl} {...props} />
          ),
        },
        {
          path: "/inspection/edit/:id",
          action: "Control.Write",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicControl} {...props} />
          ),
        },
        {
          path: "/inspection/duplicate/:id",
          action: "Control.Write",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <Wizard module={OriginModuleEnum.PeriodicControl} {...props} duplicate />
          ),
        },
        {
          path: "/inspection/settings",
          action: "Control.Settings.Write",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <Settings module={OriginModuleEnum.PeriodicControl} {...props} />
          ),
        },
        {
          path: "/inspection/:searchTerm?",
          action: "Control.Read",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <Board
              module={OriginModuleEnum.PeriodicControl}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/inspection/form/:id",
          module: OriginModuleEnum.PeriodicControl,
          render: (props) => (
            <FormAction module={OriginModuleEnum.PeriodicControl} {...props} />
          ),
        },
        {
          path: "/ActivityEntry/create",
          action: "ActivityEntry.Write",
          module: OriginModuleEnum.ActivityEntry,
          render: (props) => (
            <Wizard module={OriginModuleEnum.ActivityEntry} {...props} />
          ),
        },
        {
          path: "/ActivityEntry/edit/:id",
          action: "ActivityEntry.Write",
          module: OriginModuleEnum.ActivityEntry,
          render: (props) => (
            <Wizard module={OriginModuleEnum.ActivityEntry} {...props} />
          ),
        },
        {
          path: "/ActivityEntry/duplicate/:id",
          action: "ActivityEntry.Write",
          module: OriginModuleEnum.ActivityEntry,
          render: (props) => (
            <Wizard module={OriginModuleEnum.ActivityEntry} {...props} duplicate />
          ),
        },
        {
          path: "/ActivityEntry/settings",
          action: "ActivityEntry.Settings.Write",
          module: OriginModuleEnum.ActivityEntry,
          render: (props) => (
            <Settings module={OriginModuleEnum.ActivityEntry} {...props} />
          ),
        },
        {
          path: "/ActivityEntry/:searchTerm?",
          action: "ActivityEntry.Read",
          module: OriginModuleEnum.ActivityEntry,
          render: (props) => (
            <Board
              module={OriginModuleEnum.ActivityEntry}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/legalstructure/create",
          action: "SchoolGroup.Write",
          module: OriginModuleEnum.SchoolGroup,
          render: (props) => (
            <Wizard module={OriginModuleEnum.SchoolGroup} {...props} />
          ),
        },
        {
          path: "/legalstructure/edit/:id",
          action: "SchoolGroup.Write",
          module: OriginModuleEnum.SchoolGroup,
          render: (props) => (
            <Wizard module={OriginModuleEnum.SchoolGroup} {...props} />
          ),
        },
        {
          path: "/legalstructure/settings",
          action: "SchoolGroup.Settings.Write",
          module: OriginModuleEnum.SchoolGroup,
          render: (props) => (
            <Settings module={OriginModuleEnum.SchoolGroup} {...props} />
          ),
        },
        {
          path: "/legalstructure/:searchTerm?",
          action: "SchoolGroup.Read",
          module: OriginModuleEnum.SchoolGroup,
          render: (props) => (
            <Board
              module={OriginModuleEnum.SchoolGroup}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/branch/create",
          action: "School.Write",
          module: OriginModuleEnum.School,
          render: (props) => (
            <Wizard module={OriginModuleEnum.School} {...props} />
          ),
        },
        {
          path: "/branch/edit/:id",
          action: "School.Write",
          module: OriginModuleEnum.School,
          render: (props) => (
            <Wizard module={OriginModuleEnum.School} {...props} />
          ),
        },
        {
          path: "/branch/settings",
          action: "School.Settings.Write",
          module: OriginModuleEnum.School,
          render: (props) => (
            <Settings module={OriginModuleEnum.School} {...props} />
          ),
        },
        {
          path: "/branch/:searchTerm?",
          action: "School.Read",
          module: OriginModuleEnum.School,
          render: (props) => (
            <Board
              module={OriginModuleEnum.School}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/shared/ActivityOverview",
          render: (props) => (
            <ActivityOverview
              onCheckedCards={this.onCheckedCards}
              {...props}
            />
          ),
        },
        {
          path: "/configuration/TagManagement",
          action: "Tag.Read",
          module: OriginModuleEnum.Tag,
          render: (props) => (
            <TagManagement
              onCheckedCards={this.onCheckedCards}
              {...props}
            />
          ),
        },
        {
          path: "/building/create",
          action: "Building.Write",
          module: OriginModuleEnum.Building,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Building} {...props} />
          ),
        },
        {
          path: "/building/edit/:id",
          action: "Building.Write",
          module: OriginModuleEnum.Building,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Building} {...props} />
          ),
        },
        {
          path: "/building/duplicate/:id",
          action: "Building.Write",
          module: OriginModuleEnum.Building,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Building} {...props} duplicate />
          ),
        },
        {
          path: "/building/settings",
          action: "Building.Settings.Write",
          module: OriginModuleEnum.Building,
          render: (props) => (
            <Settings module={OriginModuleEnum.Building} {...props} />
          ),
        },
        {
          path: "/building/:searchTerm?",
          action: "Building.Read",
          module: OriginModuleEnum.Building,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Building}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/location/create",
          action: "Room.Write",
          module: OriginModuleEnum.Room,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Room} {...props} />
          ),
        },
        {
          path: "/location/edit/:id",
          action: "Room.Write",
          module: OriginModuleEnum.Room,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Room} {...props} />
          ),
        },
        {
          path: "/location/duplicate/:id",
          action: "Room.Write",
          module: OriginModuleEnum.Room,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Room} {...props} duplicate />
          ),
        },
        {
          path: "/location/settings",
          action: "Room.Settings.Write",
          module: OriginModuleEnum.Room,
          render: (props) => (
            <Settings module={OriginModuleEnum.Room} {...props} />
          ),
        },
        {
          path: "/location/:searchTerm?",
          action: "Room.Read",
          module: OriginModuleEnum.Room,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Room}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/debug/icons",
          render: (props) => <Icons />,
        },
        {
          path: "/configuration/rolemanagement",
          action: "InstanceManagement.Write",
          module: OriginModuleEnum.None,
          render: (props) => <RoleManagement {...props} />,
        },
        {
          path: "/profile",
          //action: "InstanceManagement.Write", ??
          render: (props) => <UserProfile {...props} />,
        },
        {
          path: "/employee/create",
          action: "Employee.Write",
          module: OriginModuleEnum.Employee,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Employee} {...props} />
          ),
        },
        {
          path: "/employee/edit/:id",
          action: "Employee.Write",
          module: OriginModuleEnum.Employee,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Employee} {...props} />
          ),
        },
        {
          path: "/employee/duplicate/:id",
          action: "Employee.Write",
          module: OriginModuleEnum.Employee,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Employee} {...props} duplicate />
          ),
        },
        {
          path: "/employee/settings",
          action: "Employee.Settings.Write",
          module: OriginModuleEnum.Employee,
          render: (props) => (
            <Settings module={OriginModuleEnum.Employee} {...props} />
          ),
        },
        {
          path: "/employee/:searchTerm?",
          action: "Employee.Read",
          module: OriginModuleEnum.Employee,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Employee}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/trust/create",
          module: OriginModuleEnum.Trust,
          render: (props) => <Wizard module="Trust" {...props} />,
        },
        {
          path: "/trust/edit/:id",
          module: OriginModuleEnum.Trust,
          render: (props) => <Wizard module="Trust" {...props} />,
        },
        {
          path: "/trust/:searchTerm?",
          module: OriginModuleEnum.Trust,
          render: (props) => (
            <Board
              module="Trust"
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/contact/create",
          action: "Contact.Write",
          module: OriginModuleEnum.Contact,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Contact} {...props} />
          ),
        },
        {
          path: "/contact/edit/:id",
          action: "Contact.Write",
          module: OriginModuleEnum.Contact,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Contact} {...props} />
          ),
        },
        {
          path: "/contact/duplicate/:id",
          action: "Contact.Write",
          module: OriginModuleEnum.Contact,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Contact} {...props} duplicate />
          ),
        },
        {
          path: "/contact/settings",
          action: "Contact.Settings.Write",
          module: OriginModuleEnum.Contact,
          render: (props) => (
            <Settings module={OriginModuleEnum.Contact} {...props} />
          ),
        },
        {
          path: "/contact/:searchTerm?",
          action: "Contact.Read",
          module: OriginModuleEnum.Contact,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Contact}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/incident/create",
          action: "IncidentManagement.Write",
          module: OriginModuleEnum.IncidentManagement,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IncidentManagement} {...props} />
          ),
        },
        {
          path: "/incident/edit/:id",
          action: "IncidentManagement.Write",
          module: OriginModuleEnum.IncidentManagement,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IncidentManagement} {...props} />
          ),
        },
        {
          path: "/incident/duplicate/:id",
          action: "IncidentManagement.Write",
          module: OriginModuleEnum.IncidentManagement,
          render: (props) => (
            <Wizard module={OriginModuleEnum.IncidentManagement} {...props} duplicate />
          ),
        },
        {
          path: "/incident/settings",
          action: "IncidentManagement.Settings.Write",
          module: OriginModuleEnum.IncidentManagement,
          render: (props) => (
            <Settings module={OriginModuleEnum.IncidentManagement} {...props} />
          ),
        },
        {
          path: "/incident/:searchTerm?",
          action: "IncidentManagement.Read",
          module: OriginModuleEnum.IncidentManagement,
          render: (props) => (
            <Board
              module={OriginModuleEnum.IncidentManagement}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/multiyearplan/create",
          action: "GlobalPreventionPlan.Write",
          module: OriginModuleEnum.GlobalPreventionPlan,
          render: (props) => (
            <Wizard module={OriginModuleEnum.GlobalPreventionPlan} {...props} />
          ),
        },
        {
          path: "/multiyearplan/edit/:id",
          action: "GlobalPreventionPlan.Write",
          module: OriginModuleEnum.GlobalPreventionPlan,
          render: (props) => (
            <Wizard module={OriginModuleEnum.GlobalPreventionPlan} {...props} />
          ),
        },
        {
          path: "/multiyearplan/duplicate/:id",
          action: "GlobalPreventionPlan.Write",
          module: OriginModuleEnum.GlobalPreventionPlan,
          render: (props) => (
            <Wizard module={OriginModuleEnum.GlobalPreventionPlan} {...props} duplicate />
          ),
        },
        {
          path: "/multiyearplan/settings",
          action: "GlobalPreventionPlan.Settings.Write",
          module: OriginModuleEnum.GlobalPreventionPlan,
          render: (props) => (
            <Settings module={OriginModuleEnum.GlobalPreventionPlan} {...props} />
          ),
        },
        {
          path: "/multiyearplan/:searchTerm?",
          action: "GlobalPreventionPlan.Read",
          module: OriginModuleEnum.GlobalPreventionPlan,
          render: (props) => (
            <Board
              module={OriginModuleEnum.GlobalPreventionPlan}

              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/safetyinstructioncard/create",
          action: "SafetyInstructionCard.Write",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.SafetyInstructionCard} {...props} />
          ),
        },
        {
          path: "/safetyinstructioncard/edit/:id",
          action: "SafetyInstructionCard.Write",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.SafetyInstructionCard} {...props} />
          ),
        },
        {
          path: "/safetyinstructioncard/duplicate/:id",
          action: "SafetyInstructionCard.Write",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.SafetyInstructionCard} {...props} duplicate />
          ),
        },
        {
          path: "/safetyinstructioncard/settings",
          action: "SafetyInstructionCard.Settings.Write",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <Settings module={OriginModuleEnum.SafetyInstructionCard} {...props} />
          ),
        },
        {
          path: "/safetyinstructioncard/:searchTerm?",
          action: "SafetyInstructionCard.Read",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <Board
              module={OriginModuleEnum.SafetyInstructionCard}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/safetyinstructioncard/form/:id",
          module: OriginModuleEnum.SafetyInstructionCard,
          render: (props) => (
            <FormAction module={OriginModuleEnum.SafetyInstructionCard} {...props} />
          ),
        },
        {
          path: "/instructioncard/create",
          action: "InstructionCard.Write",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.InstructionCard} {...props} />
          ),
        },
        {
          path: "/instructioncard/edit/:id",
          action: "InstructionCard.Write",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.InstructionCard} {...props} />
          ),
        },
        {
          path: "/instructioncard/duplicate/:id",
          action: "InstructionCard.Write",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <Wizard module={OriginModuleEnum.InstructionCard} {...props} duplicate />
          ),
        },
        {
          path: "/instructioncard/settings",
          action: "InstructionCard.Settings.Write",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <Settings module={OriginModuleEnum.InstructionCard} {...props} />
          ),
        },
        {
          path: "/instructioncard/:searchTerm?",
          action: "InstructionCard.Read",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <Board
              module={OriginModuleEnum.InstructionCard}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/instructioncard/form/:id",
          module: OriginModuleEnum.InstructionCard,
          render: (props) => (
            <FormAction module={OriginModuleEnum.InstructionCard} {...props} />
          ),
        },
        {
          path: "/commissioning/create",
          action: "Commissioning.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Commissioning} {...props} />
          ),
        },
        {
          path: "/commissioning/edit/:id",
          action: "Commissioning.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Commissioning} {...props} />
          ),
        },
        {
          path: "/commissioning/duplicate/:id",
          action: "Commissioning.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Commissioning} {...props} duplicate />
          ),
        },
        {
          path: "/commissioning/settings",
          action: "Commissioning.Settings.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Settings module={OriginModuleEnum.Commissioning} {...props} />
          ),
        },
        {
          path: "/commissioning/:searchTerm?",
          action: "Commissioning.Read",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Commissioning}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/commissioning/form/:id",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <FormAction module={OriginModuleEnum.Commissioning} {...props} />
          ),
        },
        {
          path: "/decommissioning/create",
          action: "OutOfCommissioning.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.OutOfCommissioning} {...props} />
          ),
        },
        {
          path: "/decommissioning/edit/:id",
          action: "OutOfCommissioning.Write",
          module: OriginModuleEnum.OutOfCommissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.OutOfCommissioning} {...props} />
          ),
        },
        {
          path: "/decommissioning/duplicate/:id",
          action: "OutOfCommissioning.Write",
          module: OriginModuleEnum.OutOfCommissioning,
          render: (props) => (
            <Wizard module={OriginModuleEnum.OutOfCommissioning} {...props} duplicate />
          ),
        },
        {
          path: "/decommissioning/settings",
          action: "OutOfCommissioning.Settings.Write",
          module: OriginModuleEnum.Commissioning,
          render: (props) => (
            <Settings module={OriginModuleEnum.OutOfCommissioning} {...props} />
          ),
        },
        {
          path: "/decommissioning/:searchTerm?",
          action: "OutOfCommissioning.Read",
          module: OriginModuleEnum.OutOfCommissioning,
          render: (props) => (
            <Board
              module={OriginModuleEnum.OutOfCommissioning}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/decommissioning/form/:id",
          module: OriginModuleEnum.OutOfCommissioning,
          render: (props) => (
            <FormAction module={OriginModuleEnum.OutOfCommissioning} {...props} />
          ),
        },
        {
          path: "/documentmanagement/create",
          action: "Document.Write",
          module: OriginModuleEnum.Document,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Document} {...props} />
          ),
        },
        {
          path: "/documentmanagement/edit/:id",
          action: "Document.Write",
          module: OriginModuleEnum.Document,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Document} {...props} />
          ),
        },
        {
          path: "/documentmanagement/duplicate/:id",
          action: "Document.Write",
          module: OriginModuleEnum.Document,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Document} {...props} duplicate />
          ),
        },
        {
          path: "/documentmanagement/settings",
          action: "Document.Settings.Write",
          module: OriginModuleEnum.Document,
          render: (props) => (
            <Settings module={OriginModuleEnum.Document} {...props} />
          ),
        },
        {
          path: "/documentmanagement/:searchTerm?",
          action: "Document.Read",
          module: OriginModuleEnum.Document,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Document}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/project/create",
          action: "Project.Write",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Project} {...props} />
          ),
        },
        {
          path: "/project/edit/:id",
          action: "Project.Write",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Project} {...props} />
          ),
        },
        {
          path: "/project/duplicate/:id",
          action: "Project.Write",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Project} {...props} duplicate />
          ),
        },
        {
          path: "/project/settings",
          action: "Project.Settings.Write",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <Settings module={OriginModuleEnum.Project} {...props} />
          ),
        },
        {
          path: "/project/:searchTerm?",
          action: "Project.Read",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Project}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/project/gantt/:id?",
          action: "Project.Write",
          module: OriginModuleEnum.Project,
          render: (props) => (
            <GanttEditor
              onCheckedCards={this.onCheckedCards}
              {...props}
            />
          ),
        },
        {
          path: "/reportdefinition/create",
          action: "Report.Write",
          module: OriginModuleEnum.Report,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Report} {...props} />
          ),
        },
        {
          path: "/reportdefinition/edit/:id",
          action: "Report.Write",
          module: OriginModuleEnum.Report,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Report} {...props} />
          ),
        },
        {
          path: "/reportdefinition/settings",
          action: "Report.Settings.Write",
          module: OriginModuleEnum.Report,
          render: (props) => (
            <Settings module={OriginModuleEnum.Report} {...props} />
          ),
        },
        {
          path: "/reportdefinition/duplicate/:id",
          action: "Report.Write",
          module: OriginModuleEnum.Report,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Report} {...props} duplicate />
          ),
        },
        {
          path: "/reportdefinition/:searchTerm?",
          action: "Report.Read",
          module: OriginModuleEnum.Report,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Report}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/supplier/create",
          action: "Supplier.Write",
          module: OriginModuleEnum.Supplier,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Supplier} {...props} />
          ),
        },
        {
          path: "/supplier/edit/:id",
          action: "Supplier.Write",
          module: OriginModuleEnum.Supplier,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Supplier} {...props} />
          ),
        },
        {
          path: "/supplier/settings",
          action: "Supplier.Settings.Write",
          module: OriginModuleEnum.Supplier,
          render: (props) => (
            <Settings module={OriginModuleEnum.Supplier} {...props} />
          ),
        },
        {
          path: "/supplier/:searchTerm?",
          action: "Supplier.Read",
          module: OriginModuleEnum.Supplier,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Supplier}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/form/create",
          action: "Form.Write",
          module: OriginModuleEnum.Form,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Form} {...props} />
          ),
        },
        {
          path: "/form/edit/:id",
          action: "Form.Write",
          module: OriginModuleEnum.Form,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Form} {...props} />
          ),
        },
        {
          path: "/form/duplicate/:id",
          action: "Form.Write",
          module: OriginModuleEnum.Form,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Form} {...props} duplicate />
          ),
        },
        {
          path: "/form/settings",
          action: "Form.Settings.Write",
          module: OriginModuleEnum.Form,
          render: (props) => (
            <Settings module={OriginModuleEnum.Form} {...props} />
          ),
        },
        {
          path: "/form/:searchTerm?",
          action: "Form.Read",
          module: OriginModuleEnum.Form,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Form}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/periodical/create",
          action: "Periodical.Write",
          module: OriginModuleEnum.Periodical,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Periodical} {...props} />
          ),
        },
        {
          path: "/periodical/edit/:id",
          action: "Periodical.Write",
          module: OriginModuleEnum.Periodical,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Periodical} {...props} />
          ),
        },
        {
          path: "/periodical/duplicate/:id",
          action: "Periodical.Write",
          module: OriginModuleEnum.Periodical,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Periodical} {...props} duplicate />
          ),
        },
        {
          path: "/periodical/settings",
          action: "Periodical.Settings.Write",
          module: OriginModuleEnum.Periodical,
          render: (props) => (
            <Settings module={OriginModuleEnum.Periodical} {...props} />
          ),
        },
        {
          path: "/periodical/:searchTerm?",
          action: "Periodical.Read",
          module: OriginModuleEnum.Periodical,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Periodical}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/riskanalysis/create",
          action: "RiskAnalysis.Write",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <Wizard module={OriginModuleEnum.RiskAnalysis} {...props} />
          ),
        },
        {
          path: "/riskanalysis/edit/:id",
          action: "RiskAnalysis.Write",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <Wizard module={OriginModuleEnum.RiskAnalysis} {...props} />
          ),
        },
        {
          path: "/riskanalysis/duplicate/:id",
          action: "RiskAnalysis.Write",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <Wizard module={OriginModuleEnum.RiskAnalysis} {...props} duplicate />
          ),
        },
        {
          path: "/riskanalysis/settings",
          action: "RiskAnalysis.Settings.Write",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <Settings module={OriginModuleEnum.RiskAnalysis} {...props} />
          ),
        },
        {
          path: "/riskanalysis/:searchTerm?",
          action: "RiskAnalysis.Read",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <Board
              module={OriginModuleEnum.RiskAnalysis}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/riskanalysis/form/:id",
          module: OriginModuleEnum.RiskAnalysis,
          render: (props) => (
            <FormAction module={OriginModuleEnum.RiskAnalysis} {...props} />
          ),
        },
        {
          path: "/recommendation/create",
          action: "Consultancy.Request",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Consultancy} {...props} />
          ),
        },
        {
          path: "/recommendation/edit/:id",
          action: "Consultancy.Request",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Consultancy} {...props} />
          ),
        },
        {
          path: "/recommendation/duplicate/:id",
          action: "Consultancy.Request",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
            <Wizard module={OriginModuleEnum.Consultancy} {...props} duplicate />
          ),
        },
        {
          path: "/recommendation/settings",
          action: "Consultancy.Settings.Write",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
            <Settings module={OriginModuleEnum.Consultancy} {...props} />
          ),
        },
        {
          path: "/recommendation/form/:id",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
              <FormAction module={OriginModuleEnum.Consultancy} {...props} />
          ),
        },
        {
          path: "/recommendation/:searchTerm?",
          action: "Consultancy.Read",
          module: OriginModuleEnum.Consultancy,
          render: (props) => (
            <Board
              module={OriginModuleEnum.Consultancy}
              selected={this.state.selectedCard}
              onSelectCard={this.onSelectCard}
              onCheckedCards={this.onCheckedCards}
              {...props}
              onDetailsPaneNavigate={this.onDetailsPaneNavigate}
            />
          ),
        },
        {
          path: "/search/:searchTerm",
          render: (props) => {
            const searchTerm = props.match.params.searchTerm;
            const module = arxs.modules.getModuleForUniqueNumber(searchTerm);
            if (module) {
              props.history.push({
                pathname: `${arxs.moduleMetadataRegistry.getModulePath(module)}/${searchTerm}`,
              });
            } else {
              Toaster.error(
                arxs.t("search.not_found", { uniqueNumber: searchTerm })
              );
            }
          },
        },
        {
          path: "/diagnostics",
          render: (props) => <Diagnostics />,
        },
      ],
    };
  }

  componentDidUpdate(prevProps) {
    const previousLocation = (prevProps.location || {}).pathname || "/";
    const currentLocation = (this.props.location || {}).pathname || "/";
    if (previousLocation !== currentLocation) {
      this.onRouteChanged(previousLocation, currentLocation);
    }
  }

  onRouteChanged = (oldRoute, newRoute) => {
    const oldArea = (oldRoute.split("/")[1] || "").toLowerCase();
    const newArea = (newRoute.split("/")[1] || "").toLowerCase();
    if (oldArea !== newArea) {
      this.context.detailsPane.open();
    }
  };

  onSearchResult = (cards) => {
    if (cards && cards.length === 1) {
      this.setState({ selectedCard: {} }, () => this.context.detailsPane.open(cards[0]));
    } else {
      this.setState({ checkedCards: [] }, () => this.onCheckedCards(cards));
    }
  };

  onCheckedCards = (cards) => {
    this.setState({
      checkedCards: cards.map((card) => ({
        id: card.id,
        module: card.module,
        title: card.title,
        uniqueNumber: card.uniqueNumber,
        description: card.description,
        internalNumber: card.internalNumber,
        consultancy: card.consultancy,
        status: card.status,
        image: card.image,
        estimatedDuration: card.estimatedDuration,
        actions: card.actions,
      })),
    });
  };

  onDetailsPaneNavigate = (tab) => {
    this.setState({ selectedTab: tab });
  };

  registerSupportClickHandler = (handler) => {
    this.setState({ supportClickHandler: handler });
  };

  onSupportClick = () => {
    if (this.state.supportClickHandler) {
      this.state.supportClickHandler();
    }
  };

  render() {
    const isActionAllowed = (context, action) => 
      context.profile.allowedActions[action];

    // Declaring Route components inline makes them not remount when Layout rerenders
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="content">
            <PostHogPageviewTracker />
            <Header onSearchResult={this.onSearchResult} />
            <div className="page-main">
              <DndProvider
                backend={context.platform.isMobile ? TouchBackend : HTML5Backend}
                options={{ enableMouseEvents: true }}
                key="DragAndDrop"
              >
                <Menu
                  onSearchResult={this.onSearchResult}
                  onSupportClick={this.onSupportClick}
                />
                <div className="page-content">
                  <Switch>
                    {this.state.routes
                      .flatMap((route) => typeof (route.path) === "string" ? [route] : route.path.map(path => ({ ...route, path })))
                      .filter(route => !context.platform.isMobile || !route.disableOnMobile)
                      .filter(route => !context.platform.isPhone || !route.disableOnPhone)
                      .map((route) => (
                        <Route
                          exact
                          path={route.path}
                          key={`route${route.path.replace("/", "-")}`}
                          render={
                            (props) =>
                              arxs.isActionAllowed(route.action) ? (
                                route.render({ ...props, isMobile: context.platform.isMobile, isPhone: context.platform.isPhone })
                              ) : (
                                <RouteRestricted
                                  module={route.module}
                                  action={route.action}
                                />
                              )
                          }
                        />))
                    }
                  </Switch>
                </div>
                <DetailsPane
                  selectedTab={this.state.selectedTab}
                  card={context.detailsPane.card}
                />
                {context.popup.popups.map((x, i) => <Popup key={`popup-${i}`} {...x} />)}
                <OptionPopup {...context.optionPopup} />
                <InputPopup {...context.inputPopup} />
                <PromptPopup {...context.promptPopup} />
              </DndProvider>
            </div>
            <BeamerHelmet
              id={process.env.REACT_APP_BEAMER_ID}
              args={{ selector: ".beamerButton" }}
            />
            {isActionAllowed(context, "SupportTicket.Create") && (
              <FreshdeskWidget
                email={arxs.Identity.profile.email}
                name={arxs.Identity.profile.fullName}
                registerClickHandler={this.registerSupportClickHandler}
              />
            )}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
export default withRouter(Layout);
// This allows to use the context inside of the component via this.context
Layout.contextType = GlobalContext;
