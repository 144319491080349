import React, { Component } from "react";
import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import GlobalContext from "infra/GlobalContext";

import "./Popup.scss";

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialTitle: null,
      title: this.props.title,
      isMaximized: this.props.maximized || false,
      canMaximize:
        this.props.canMaximize === undefined ? true : this.props.canMaximize,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.title !== prevProps.title) {
      this.setState({
        initialTitle: this.props.title,
        isMaximized: this.props.maximized || false,
        canMaximize:
          this.props.canMaximize === undefined ? true : this.props.canMaximize,
      });
    }
  }

  onTitleChanged = (title) => {
    this.setState({ title });
  };

  close = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }

    if (this.props.close) {
      this.props.close();
      this.setState({
        initialTitle: null,
        title: null,
      });
    }
  };

  changeSize = () => {
    this.setState({ isMaximized: !this.state.isMaximized });
  };

  render() {
    const { canMaximize, isMaximized } = this.state;
    const content = this.props.getContent
      ? this.props.getContent({
          onTitleChanged: this.onTitleChanged,
          refresh: this.props.refresh,
        })
      : this.props.content;
    const classNames = [
      "popup",
      isMaximized && "maximized",
      !isMaximized && this.props.fitContentWidth && "fit-content-width",
      !isMaximized && this.props.fitContentHeight && "fit-content-height",
    ]
      .filter((x) => x)
      .join(" ");

    return (
      <GlobalContext.Consumer>
        {(context) =>
          content ? (
            <div className="popup-backdrop">
              <div className={classNames}>
                <div className="popup-header">
                  <h1>{this.state.title || this.state.initialTitle}</h1>
                  {canMaximize && !context.platform.isMobile && (
                    <div
                      className="popup-maximize-minimize"
                      onClick={this.changeSize}
                    >
                      {isMaximized && (
                        <i className="fas fa-window-minimize"></i>
                      )}
                      {!isMaximized && (
                        <i className="fas fa-window-maximize"></i>
                      )}
                    </div>
                  )}
                  <div className="popup-close" onClick={this.close}>
                    <i className="fa fa-times"></i>
                  </div>
                </div>
                <HorizontalSeparator />
                <div className="popup-body">{content}</div>
              </div>
            </div>
          ) : null
        }
      </GlobalContext.Consumer>
    );
  }
}
export default Popup;
