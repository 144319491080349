import React, { Component } from 'react';

import './Signature.scss';

class Signature extends Component {
  render() {
    const url = this.props.src;
    return (
      <div className={`signature ${this.props.className || ""}`}
        style={{ backgroundImage: `url("${url}")` }}
        title={this.props.fullName}></div>
    );
  }
}
export default Signature;