import React, { useRef, useState, useEffect, useContext } from 'react';
import { BryntumGantt } from '@bryntum/gantt-react-thin';
import { ProjectModel } from '@bryntum/gantt-thin';
import { LocaleManager, Menu, LocaleHelper } from '@bryntum/core-thin';
import { NlLocale } from '@bryntum/gantt-thin/lib/localization/Nl';
import { LinkType, OriginModuleEnum } from 'infra/api/contracts';
import { createGanttLineEditor } from './GanttLineEditor';
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import { Card } from 'components/card/Card';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import Toaster from 'components/util/Toaster';
import Button from 'components/controls/Button';

import '@bryntum/core-thin/core.classic-light.css';
import '@bryntum/grid-thin/grid.classic-light.css';
import '@bryntum/gantt-thin/gantt.classic-light.css';
import './GanttEditor.scss';

export default function GanttEditor(props) {
  const ref = useRef();
  const context = useContext(GlobalContext);

  const metadata = arxs.moduleMetadataRegistry.get(OriginModuleEnum.Project);

  LocaleManager.applyLocale('Nl');

  const params = props.match.params || {};

  const lookups = {
    employees: [],
    taskMap: {},
    projectMap: {},
    projects: []
  };

  const [data, setData] = useState({});
  const [ganttData, setGanttData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [taskMap, setTaskMap] = useState({});
  const [projectMap, setProjectMap] = useState({});
  const [projects, setProjects] = useState([]);

  let objectLinks = [];

  useEffect(() => {
    const refresh = () => {
      if (params.id && projectMap && Object.keys(projectMap).some(x => x)) {
        const project = projectMap[params.id];
        setData(project);
      }
    };

    refresh();
  }, [params.id, projectMap])

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          switch (key) {
            case "employees": setEmployees(values[key]); break;
            case "taskMap": setTaskMap(values[key]); break;
            case "projectMap": setProjectMap(values[key]); break;
            case "projects": setProjects(values[key]); break;
            default: break;
          }
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  useEffect(() => {
    const refresh = () => {
      if (data && Object.keys(data).some(x => x)) {
        const project = getGanttProject();
        setGanttData(project);
      }
    };

    refresh();
  }, [data, taskMap, employees]);

  const mapSection = (section, isRoot) => {
    let ganttNode = {
      name: section.title,
      id: generateId(data.id, section.id || arxs.uuid.generate()),
      sectionId: section.id,
      rootId: data.id,
      expanded: true,
      module: section.module,
      objectId: section.objectId,
      completionPercentage: section.completionPercentage,
      percentDone: (section.completionPercentage || 0) * 100,
      effort: section.effort,
      duration: section.duration
    };

    if (!section.sections || section.sections.length === 0) {
      ganttNode.startDate = section.startDate && (new Date(section.startDate)).toISOString();
      ganttNode.constraintType = "startnoearlierthan";
      ganttNode.constraintDate = ganttNode.startDate;

      if (section.duration) {
        ganttNode.duration = section.duration;
        ganttNode.endDate = ganttNode.startDate && (arxs.dateTime.addDays(ganttNode.startDate, section.duration)).toISOString();
      }
    } else {
      ganttNode.children = (section.sections || []).map(section => mapSection(section, false));
    }

    const actions = getActions({ ...ganttNode, parentId: isRoot ? null : 1 });

    ganttNode = { ...ganttNode, ...actions, projects };

    return ganttNode;
  };

  const getGanttProject = () => {
    const getTasks = () => {
      const gantt = { title: data.title, id: data.id, sections: [{ id: arxs.uuid.generate(), rootId: data.id, title: "placeholder" }] };

      const tasks = ((data.gantt || gantt).sections).map(section => mapSection(section, true));
      return tasks;
    }

    const getResources = () => {
      const resources = employees.map(employee => {
        const hasAvatar = employee.images && employee.images.length > 0;
        return {
          id: arxs.getStableId(OriginModuleEnum.Employee, employee.id),
          name: employee.name,
          image: hasAvatar,
          imageUrl: hasAvatar === true ? employee.images[0].url : null
        }
      });

      return resources;
    }

    const tasks = getTasks();
    const resources = getResources();

    const project = new ProjectModel({
      tasksData: tasks,
      resourcesData: resources,
      listeners: {
        change({ action, records }) {
          redrawActions();
        }
      },
      calendar: 'general',
      calendarsData: [{
        id: 'general',
        intervals: [
          {
            recurrentStartDate: 'on Sat at 0:00',
            recurrentEndDate: 'on Mon at 0:00',
            isWorking: false
          }
        ]
      }]
    });

    return project;
  }

  const generateId = (projectId, childId) => arxs.getStableId(OriginModuleEnum.Project, projectId, childId);


  const redrawActions = () => {
    const gantt = getGanttInstance();

    for (let record of gantt.taskStore.allRecords) {
      record = setActionsOnRecord(record);
    }

    gantt.taskStore.commit();
    gantt.refresh();
  }

  const getActions = (record) => {

    let actions = {
      canAdd: true,
      canDelete: true,
      canEdit: true,
      canIndent: false,
      canOutdent: true,
      canClone: true,
      canLinkObject: false,
      canUnlinkObject: false
    };

    if (getGanttInstance().taskStore.allRecords.length === 1) {
      actions.canDelete = false;
    }

    if (record.previousSibling && !record.previousSibling.data.module) {
      actions.canIndent = true;
    }

    if (!record.children || record.children.length === 0) {
      actions.canLinkObject = true;
    }

    if (record.module) {
      if (record.objectId) {
        actions.canUnlinkObject = true
      }

      actions.canAdd = false;
      // actions.canEdit = false;
    }

    if (record.parentId === null) {
      actions.canOutdent = false;
    }

    return actions;
  }

  const setActionsOnRecord = (record) => {
    let actions = getActions({ module: record.data.module, objectId: record.data.objectId, parentId: record.data.parentId, previousSibling: record.previousSibling, children: record.children });

    record.set({
      canAdd: actions.canAdd,
      canDelete: actions.canDelete,
      canEdit: actions.canEdit,
      canIndent: actions.canIndent,
      canOutdent: actions.canOutdent,
      canClone: actions.canClone,
      canLinkObject: actions.canLinkObject,
      canUnlinkObject: actions.canUnlinkObject
    })
    return record;
  }

  const mapChildToSection = (child) => {
    if (child) {
      const section = {
        id: child.data.sectionId,
        rootId: data.id,
        startDate: child.data.startDate,

        module: child.data.module,
        objectId: child.data.objectId,
        ganttId: child.data.ganttId,
        title: child.data.name,

        duration: child.data.duration === null ? undefined : child.data.duration,
        effort: child.data.effort === null ? undefined : child.data.effort,
        completionPercentage: child.data.completionPercentage === null ? undefined : child.data.completionPercentage,
        sections: (child.children || []).map(child => mapChildToSection(child))
      }

      if (!child.children || child.children.length === 0) {
        section.duration = child.data.duration
      }

      return section;
    }
  }

  const getGanttInstance = () => ref.current.ganttInstance;

  const getGanttRecord = (id) => {
    const gantt = getGanttInstance();
    const ganttRecord = gantt.taskStore.getById(id);

    return ganttRecord;
  }

  const indentTask = (context, record) => {
    const ganttRecord = getGanttRecord(record.id);

    getGanttInstance().taskStore.indent(ganttRecord);
  }

  const outdentTask = (context, record) => {
    const ganttRecord = getGanttRecord(record.id);

    getGanttInstance().taskStore.outdent(ganttRecord);
  }

  const openMenu = (context, record, mousePos) => {
    let menuItems = [];

    if (record.canEdit) {
      menuItems.push({
        icon: 'b-fa b-fa-pen',
        text: arxs.t("common.edit"),
        onClick: () => editTask(context, record)
      })
    }

    if (record.canDelete) {
      menuItems.push({
        icon: 'b-fa far fa-trash-alt',
        text: arxs.t("common.delete"),
        onClick: () => deleteTask(context, record)
      })
    }

    if (record.canAdd) {
      menuItems.push({
        icon: 'b-fa b-fa-plus',
        text: arxs.t("common.add"),
        onClick: () => addTask(context, record)
      })
    }

    if (record.canIndent) {
      menuItems.push({
        icon: 'b-fa b-fa-arrow-right',
        text: arxs.t("actions.project.indent"),
        onClick: () => indentTask(context, record)
      })
    }

    if (record.canOutdent) {
      menuItems.push({
        icon: 'b-fa b-fa-arrow-left',
        text: arxs.t("actions.project.outdent"),
        onClick: () => outdentTask(context, record)
      })
    }

    if (record.canUnlinkObject) {
      menuItems.push({
        icon: 'b-fa b-fa-times',
        text: arxs.t("actions.project.unlink_object"),
        onClick: () => unlinkObject(context, record)
      })
    }

    if (record.canLinkObject) {
      menuItems.push({
        icon: 'b-fa b-fa-search',
        text: arxs.t("actions.project.link_object"),
        menu: [
          {
            icon: 'b-fa far fa-tasks',
            text: arxs.t("menu.actions.task"),
            onClick: () => linkObject(context, record, OriginModuleEnum.Task)
          }
        ]
      });
      menuItems.push({
        icon: 'b-fa b-fa-search',
        text: arxs.t("actions.project.select_template"),
        menu: [
          {
            icon: 'b-fa far fa-tasks',
            text: arxs.t("menu.actions.task")
          }
        ]
      });
    }

    if (record.canClone) {
      menuItems.push({
        icon: 'b-fa b-fa-clone',
        text: arxs.t("common.clone"),
        onClick: () => cloneTask(context, record)
      })
    }

    const estimatedMenuHeight = menuItems.length * 35;

    let posY = mousePos.y;
    if (window.window.innerHeight < (mousePos.y + estimatedMenuHeight)) {
      posY = window.window.innerHeight - estimatedMenuHeight;
    }


    let menu = new Menu({
      items: menuItems,
      onItem({ item }) {
        if (item.onClick) {
          item.onClick();
        }
      },
      x: mousePos.x + 10,
      y: posY
    });

    menu.show();
  }



  const unlinkObject = (context, record) => {
    record.set("objectId", undefined);
    record.set("module", undefined);
    objectLinks = (objectLinks || []).filter(x => x.module !== record.data.module && x.id !== record.data.objectId);
  }

  const linkObject = (context, record, module) => {
    if (!record.data.ganttId) {
      const onApplyFilter = (state) => {
        context.popup.close();

        if (state) {
          if (Object.entries(state).length !== 1) {
            arxs.logger.error('only 1 selection allowed for lookup when linking an object!')
            Toaster.error(arxs.t('actions.error.selection_exceeded'));
            context.popup.close();
          } else {
            const objectId = Object.keys(state)[0];

            record.set("objectId", objectId);
            record.set("module", Object.values(state)[0]);

            objectLinks = (objectLinks || []).concat([{ module: Object.values(state)[0], id: objectId, type: LinkType.PartOf }]);
          }
        }
      }

      const filterPredicate = (card) => {
        if (card && card.module && card.id && record.projects) {
          const usedLinks = ((record.projects.flatMap(x => x.inboundLinks) || []).concat(objectLinks || [])).distinct(x => x.id);
          return !usedLinks.some(x => x.module === card.module && x.id === card.id && x.type === LinkType.PartOf);
        }
      }

      const cardLookup = createCardLookup({
        modules: [module],
        onApplyFilter,
        singleSelection: true,
        filterPredicate,
      });

      context.popup.show(cardLookup);
    }
  }

  const deleteTask = (context, record) => {
    const ganttRecord = getGanttRecord(record.id);
    getGanttInstance().taskStore.remove(ganttRecord);
    objectLinks = objectLinks.where(x => x.module !== record.data.module && x.id !== record.data.objectId);
  }

  const cloneTask = (context, record) => {
    const ganttRecord = getGanttRecord(record.id);
    const parentRecord = getGanttRecord(record.data.parentId);

    const rootClone = ganttRecord.copy();
    const newSectionId = arxs.uuid.generate();
    rootClone.id = generateId(data.id, arxs.uuid.generate());
    rootClone.set({ sectionId: newSectionId, expanded: true });

    const addChild = (parent, origin) => {
      for (const child of origin.children) {
        const cloneChild = child.copy();
        const newSectionId = arxs.uuid.generate();
        cloneChild.id = generateId(data.id, arxs.uuid.generate());
        cloneChild.set({ sectionId: newSectionId, expanded: true });

        if (child.children && child.children.length > 0) {
          addChild(cloneChild, child)
        }

        parent.appendChild(cloneChild);
      }
    }

    if (ganttRecord.children && ganttRecord.children.length > 0) {
      addChild(rootClone, ganttRecord);
    }

    if (parentRecord) {
      parentRecord.appendChild(rootClone);
    } else {
      getGanttInstance().taskStore.add(rootClone);
    }

  }

  const addTask = (context, record) => {
    const popup = createGanttLineEditor({}
      , (result) => {
        context.popup.close();

        const newSectionId = arxs.uuid.generate();

        let newGanttRecord = {
          sectionId: newSectionId,
          rootId: data.id,
          id: generateId(data.id, newSectionId),
        };

        newGanttRecord.name = result.title;
        newGanttRecord.expanded = true;
        newGanttRecord.startDate = result.startDate;
        newGanttRecord.duration = result.duration;
        newGanttRecord.completionPercentage = result.completionPercentage;
        newGanttRecord.effort = result.effort;
        record.appendChild(newGanttRecord);
      }
      , () => {
        context.popup.close();
      });

    context.popup.show(popup);
  }

  const editTask = (context, record) => {
    const ganttRecord = getGanttRecord(record.id);

    const item = {
      title: ganttRecord.name,
      module: ganttRecord.module,
      objectId: ganttRecord.objectId,
      startDate: ganttRecord.startDate,
      completionPercentage: ganttRecord.completionPercentage,
      effort: ganttRecord.effort,
      duration: ganttRecord.duration
    }

    const popup = createGanttLineEditor(item
      , (result) => {
        context.popup.close();

        record.set({
          startDate: result.startDate,
          duration: result.duration,
          name: result.title,
          completionPercentage: result.completionPercentage,
          percentDone: (result.completionPercentage || 0) * 100,
          effort: result.effort
        })
      }
      , () => {
        context.popup.close();
      });

    context.popup.show(popup);
  }

  const openDetailsPane = (card) => {
    context.detailsPane.open(card);
  }

  const renderTaskName = (context, record) => {
    if (record.data.module !== undefined && record.data.objectId !== undefined) {
      return <div className="gantt-task-card-container" onClick={() => openDetailsPane({ id: record.data.objectId, module: record.data.module })}>
        <Card
          objectId={record.data.objectId}
          module={record.data.module}
          mini
          readOnly />
      </div>
    }

    return <div className="gantt-task-record-name" title={record.name}>{record.name}</div>;
  }

  const navigateToHome = () => props.history.push(metadata.base.route)

  const handleCancel = () => {
    navigateToHome();
  }

  const handleValidationErrors = (validationErrors) => {
    Toaster.error(`Er ging iets mis. ${JSON.stringify(validationErrors)}`)
    arxs.logger.error("GanttEditor validation errors {validationErrors}", validationErrors)
  }

  const handleSave = (context, onConfirm) => {
    let gantt = data.gantt || { title: data.title, id: data.id, sections: [] };

    gantt.sections = getGanttInstance().taskStore.allRecords.filter(x => x.data.parentId === null).map(record => mapChildToSection(record));

    return arxs.ApiClient.facilitymanagement.project.saveGantt(params.id, { objectId: params.id, module: data.module, gantt: gantt, tenantId: arxs.Identity.tenant })
      .then(response => {
        switch (response.status) {
          case 202:
            response.json()
              .then(() => {
                Toaster.success(arxs.t("wizard.save_successful"));
              });
            break;
          case 422:
            response.json().then(handleValidationErrors);
            break;
          default:
            Toaster.error(`Unhandled response, ${JSON.stringify(response)}`);
            arxs.logger.info("Unhandled response from Wizard.handleSave() {response}", response);
            break;
        }
      });
  }

  return (<GlobalContext.Consumer>
    {context => <div className="gantt">
      <div className="gantt-body">
        <div className="gantt-header">
          <h1>
            <i className={arxs.modules.icons[data.module]}></i> {`${data.uniqueNumber} - ${data.title}`}
          </h1>
        </div>
        <div className="gantt-container" >
          <BryntumGantt
            key={1}
            project={ganttData}
            columns={[
              {
                type: 'name',
                width: '350px',
                renderer: ({ record }) => renderTaskName(context, record)
              },
              {
                type: 'action',
                actions: [
                  {
                    cls: 'b-fa fas fa-bars',
                    renderer: ({ action }) => `<i class="b-action-item ${action.cls} "></i>`,
                    visible: ({ record }) => true,
                    tooltip: () => `<p class="b-nicer-than-default">Menu</p>`,
                    //onClick: ({ record }) => openMenu(context, record)
                  },
                ]
              },
              { type: 'startdate', format: 'YYYY-MM-DD', width: '100px' },
              { type: 'enddate', format: 'YYYY-MM-DD', width: '100px' },
              { type: 'duration', width: '100px' }
            ]}
            ref={ref}
            contextMenuFeature={false}
            headerMenuFeature={false}
            headerContextMenuFeature={false}
            timeAxisHeaderMenuFeature={false}
            sortFeature={false}
            columnResizeFeature={false}
            taskMenuFeature={false}
            cellMenuFeature={false}
            cellEditFeature={true}
            taskDragFeature={{
              validatorFn(taskRecord, startDate) {
                return taskRecord.sectionId;
              }
            }}
            dependenciesFeature={false}
            bufferCoef={2}
            // autoHeight={true}
            infiniteScroll={false}

            viewPreset={{
              base: "weekAndDayLetter",
            }}

            timeRangesFeature={{
              showCurrentTimeLine: true
            }}

            scrollTaskIntoViewOnCellClick={true}

            listeners={{
              taskDblClick: (e) => {
                editTask(context, e.taskRecord);
              },
              beforeTaskEdit: (e) => {
                return false;
              },
              cellClick: (e) => {
                if (e.column.data.type === "action") {
                  openMenu(context, e.record, { x: e.event.clientX, y: e.event.clientY })
                }
              }
            }}
          />
        </div>

      </div>
      <div className="gantt-buttons">
        <Button className="gantt-button gantt-cancel icon" onClick={handleCancel} title={arxs.t("wizard.cancel")}>
          <i className="fas fa-times"></i>
        </Button>
        <Button className="gantt-button gantt-save icon" onClick={() => handleSave(context)} title={arxs.t("wizard.save")}>
          <i className="fad fa-save"></i>
        </Button>
      </div>
    </div>}
  </GlobalContext.Consumer>);
}