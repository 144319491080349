import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";
import { IconOverview } from "components/controls/images/IconOverview";


export default class RiskAndPreventionMeasures extends FormItemEditorController {
    lookups = {
        codeElements: [],
        iconMap: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    renderRPM = (context, risks, preventionMeasures, onItemChange, onDeleteItem, data) => {
        const { readOnly } = this.props;
        const selectedRisk = data && data.risk;
        const selectedPreventionMeasure = data && data.prevention;

        const risksValues = (risks.children || []).map(x => x.name);
        const preventionValues = (preventionMeasures.children || []).map(x => x.name);

        const getIcon = (value) => {
            return (this.state.iconMap && Object.keys(this.state.iconMap).some(x => x) && [this.state.iconMap[value]]) || [];
        }

        const riskIcon = (data && data.riskIcon && getIcon(data.riskIcon)) || [];
        const preventionIcon = (data && data.preventionIcon && getIcon(data.preventionIcon)) || [];

        const onTextChange = (state, field) => {
            if (state) {
                if (state !== '') {
                    onItemChange({ ...data, [field]: state });
                } else {
                    let newData = { ...data };
                    delete newData[field];
                    onItemChange({ ...newData });
                }
            } else {
                let newData = { ...data };
                delete newData[field];
                onItemChange({ ...newData });
            }
        }

        const handleDelete = () => {
            onDeleteItem(data.id);
        }

        const onAddIcon = (state, field) => {
            if (state) {
                var icon = (Object.values(state) || []).map(x => x.iconId)[0];
                onItemChange({ ...data, [field]: icon });
            }
        }

        const onDeleteIcon = (field) => {
            var newData = { ...data };
            delete newData[field];
            onItemChange(newData);
        }


        return <div className="rpm" key={(data && data.id) || 0}>
            <IconOverview
                className="field full-width"
                key="risk-icons"
                readOnly={readOnly}
                data={riskIcon}
                onAdd={(state) => onAddIcon(state, "riskIcon")}
                onDelete={() => onDeleteIcon("riskIcon")}
                title=""
                singleSelection={true}
                size={36}
            />
            <AutoCompleteTextBox
                id={`risk-${data.id}`}
                key={`risk-${data.id}`}
                className={`rpm-value`}
                items={risksValues}
                value={selectedRisk}
                onChange={(state) => onTextChange(state, "risk")}
                readOnly={readOnly}
                placeholder={arxs.t("forms.items.risk_prevention_measures.risk")}
            />
            <AutoCompleteTextBox
                id={`prevention-${data.id}`}
                key={`prevention-${data.id}`}
                className={`rpm-value`}
                items={preventionValues}
                value={selectedPreventionMeasure}
                onChange={(state) => onTextChange(state, "prevention")}
                readOnly={readOnly}
                placeholder={arxs.t("forms.items.risk_prevention_measures.prevention")}
            />
            <IconOverview
                className="field full-width"
                key="prevention-icons"
                readOnly={readOnly}
                data={preventionIcon}
                onAdd={(state) => onAddIcon(state, "preventionIcon")}
                onDelete={() => onDeleteIcon("preventionIcon")}
                title=""
                singleSelection={true}
                size={36}
            />
            <div className="rpm-actions">
                {!readOnly && data && <div className="rpm-action" onClick={handleDelete}>
                    <i className="far fa-trash-alt"></i>
                </div>}
            </div>
        </div>;
    }

    renderRPMS = (context) => {
        const { field, readOnly } = this.props;
        const value = field.getter();
        const data = value.data || [];
        const risks = ((this.state.codeElements || {})["InstructionCard.Risks"] || [])[0] || {};
        const preventionMeasures = ((this.state.codeElements || {})["InstructionCard.PreventionRule"] || [])[0] || {};

        const onItemChange = (state) => {
            let newData = (data || []);
            const index = newData.findIndex(x => x.id === state.id);
            if (index === -1) {
                newData.push(state);
            } else {
                newData[index] = state;
            }
            field.setter({ ...value, data: newData });
        };

        const renderLines = () => {
            return data.map(line => this.renderRPM(context, risks, preventionMeasures, onItemChange, onDeleteItem, line));
        }

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        }

        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData});
        }

        return <div className="rpms-container">
            {renderLines()}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderRPMS(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}