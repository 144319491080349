import React, { ReactNode } from "react";

import { CardClassProps } from "components/card/CardContracts";
import CardActions from "components/card/CardActions";
import CardLocation from "components/card/CardLocation";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardImage from "components/card/CardImage";
import CardInternalNumber from "components/card/CardInternalNumber";
import CardCategorization from "components/card/CardCategorization";

export default function CardBase(props: CardClassProps, iconClass: string, renderName?: (card: any) => ReactNode) {
    const { card, cardProps, actionProps } = props;

    const { showNavigateTo, showUnsubscribe, showCheckbox } = props.actionProps;

    if (actionProps.condensed) {
        return (
          <div className={`user-card ${cardProps.className || ""}`}>
            <i className={iconClass}></i>
            <div className="user-card-names">
                <div className="user-card-name">{renderName ? renderName(card) : card.name}</div>
                {typeof card.legalStructure === "string" && <div className="user-card-username">
                    {card.legalStructure}
                </div>}
            </div>
            <CardActions
              {...actionProps}
              showCheckbox={showCheckbox}
              showUnsubscribe={showUnsubscribe}
              showNavigateTo={showNavigateTo}
            />
          </div>
        );
    }

    if (actionProps.mini) {
      return (
        <>
          <i className={iconClass}></i>
          <div className="mini-card-names">
            <div className="mini-card-name">
              {renderName ? renderName(card) : card.name}
            </div>
          </div>
        </>
      );
    }

    return (
        <>
          <div className="list-card-left">
            <CardImage {...props} iconClass={iconClass} />
          </div>
          <div className="list-card-right">
            <h2>
              <div className="list-card-title">
                {card.title || card.name}
              </div>
              <CardActions
                {...actionProps}
                showCheckbox={showCheckbox}
                showUnsubscribe={showUnsubscribe}
                showNavigateTo={showNavigateTo}
              />
            </h2>
            <CardSubtitle cardProps={cardProps} card={card} />
            <div className="list-card-details">
              <CardCategorization {...props} />
              <CardInternalNumber {...props} />
              <CardLocation card={card} />
            </div>
          </div>
        </>
      );
}