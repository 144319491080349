import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';

class TrustBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,
      ownershipMode: card.ownersipMode,
      legalStructureScopes: card.legalStructureScopes.map(x => this.getValue(stateProxy.getter("legalStructureMap"), x)),
      branchScopes: card.branchScopes.map(x => this.getValue(stateProxy.getter("branchMap"), x)),
      roleScopes: card.roleScopes.map(x => this.getValue(stateProxy.getter("userRoleMap"), x)),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.trusteeTenant,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          let pristine = lookups.trusts || stateProxy.getter("pristine") || [];
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
          stateProxy.setter(pristine);
        });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Employees yet");
  }
}
export default new TrustBoard();