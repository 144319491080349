import React, { useState, useEffect } from "react";
import arxs from "infra/arxs";
import Button from "components/controls/Button";
import FieldSet from "components/controls/FieldSet";
import _ from "lodash";

import "./AmendActualDurationTaskLikePopup.scss";
import { OriginModuleEnum } from "infra/api/contracts";

export const createAmendActualDurationTaskLikePopup = (
  value,
  onSubmit,
  onCancel,
  taskLikeModule
) => {
  const state = {
    title: arxs.t("actions.amend_actual_duration_popup.title", {
      module: arxs.modules.titles[taskLikeModule],
    }),
    content: (
      <AmendActualDurationTaskLikePopup
        value={value}
        onSubmit={onSubmit}
        onCancel={onCancel}
        correlationKey={new Date().format_yyyyMMddhhmmsshhh()}
        module={taskLikeModule}
      />
    ),
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function AmendActualDurationTaskLikePopup(props) {
  const [data, setData] = useState({});
  const [lookups] = useState({
    taskMap: {},
    inspectionMap: {},
    maintenanceMap: {},
    riskAnalysisMap: {}
  });

  const [map, setMap] = useState();

  const taskLikeMap = (() => {
    switch (props.module) {
      case OriginModuleEnum.Task: return "taskMap";
      case OriginModuleEnum.PeriodicMaintenance: return "maintenanceMap";
      case OriginModuleEnum.PeriodicControl: return "inspectionMap";
      case OriginModuleEnum.RiskAnalysis: return "riskAnalysisMap";
      default: return "";
    }
  });

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          const lookup = values[key] || {};
          switch (key) {
            case taskLikeMap():
              setMap(lookup);
              break;
            default:
              break;
          }
        }
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);


  useEffect(() => {
    const { id } = props.value;

    setData({
      id,
    });
  }, [props.value]);

  useEffect(() => {
    const { id } = props.value;

    let actualDuration;
    if (!actualDuration && map) {
      const taskLike = map[id];
      if (taskLike) {
        const estimatedDuration = taskLike.estimatedDuration || 1;
        actualDuration = taskLike.actualDuration || estimatedDuration;
      }
    }

    setData({
      id,
      actualDuration: actualDuration || null
    });
  }, [map]);

  const close = () => props.onCancel && props.onCancel();
  const confirm = () =>
    props.onSubmit && data && data.actualDuration && props.onSubmit(data);

  const rows = [
    [
      {
        name: "actualDuration",
        unit: arxs.t("unit.hour").toLowerCase(),
        props: { wholeNumber: true },
      },
    ],
  ];

  return (
    <div
      className={`amend-actualduration-tasklike-popup ${
        props.className || ""
      }`}
    >
      <div className="amend-actualduration-tasklike-popup-body">
        <FieldSet
          schemaName="AmendActualDurationOfTask"
          rows={rows}
          data={data}
          setData={setData}
        />
        <div className="buttons">
          <Button
            key={`amend-actualduration-tasklike-cancel`}
            className="icon alternative"
            onClick={close}
          >
            {arxs.t("common.cancel")}
          </Button>
          <Button
            key={`amend-actualduration-tasklike-confirm`}
            className="icon"
            onClick={confirm}
            disabled={
              !data.id ||
              !data.actualDuration ||
              !_.isNumber((data || {}).actualDuration)
            }
          >
            {arxs.t("common.amend")}
          </Button>
        </div>
      </div>
    </div>
  );
}
