import React from 'react';

// Function that extracts color part of a border style string (the last part of the string)
function extractColor(borderStyle) {
  return borderStyle.split(' ').pop();
}

function ColorPicker(props) {
  const value = (props.value || '#000000').trim();
  const tokens = value.split(' ');

  const onChange = (e) => {
    const color = e.target.value;
    const newValue = tokens.slice(0, tokens.length - 1).concat([color]).join(' ');
    props.onChange(newValue);
  };

  return (
    <div>
      <input 
        type="color" 
        value={tokens[tokens.length - 1]} 
        onChange={onChange}
      />
    </div>
  );
}

export default ColorPicker;