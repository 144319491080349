import React, { useState, useEffect } from "react";
import {
    TreeView as KendoTreeView,
    processTreeViewItems,
    handleTreeViewCheckChange,
    TreeViewExpandChangeEvent,
    TreeViewCheckChangeSettings
} from '@progress/kendo-react-treeview';

import arxs from "infra/arxs";
import { Spinner } from "components/animations/Spinner";
import Search from "components/controls/Search";

import "./TreeList.scss";

export interface TreeListItem {
    text: string;
    icon?: string;
    items: TreeListItem[];
}

export interface TreeListProps {
    data: TreeListItem[];
    onClick(item: TreeListItem): void;
    onCheckToggle(item: TreeListItem): void;
    idField: string;
}

export default function TreeList(props: TreeListProps) {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [filteredTreeItems, setFilteredTreeItems] = useState<TreeListItem[]>([]);
    const [check, setCheck] = useState<string[]>([]);
    const [select, setSelect] = useState([""]);
    const [expand, setExpand] = useState<{ ids: Array<string>, idField: string }>({
        ids: [],
        idField: props.idField,
      });

    useEffect(() => {
        if (!searchTerm) {
            setFilteredTreeItems(props.data);
            setExpand({
                ids: [],
                idField: props.idField
            })
        } else {
            const st = searchTerm.toLowerCase();
            const matches = (item: TreeListItem) => item && item.text && item.text.toLowerCase().indexOf(st) > -1;
            
            const filter = (items: TreeListItem[]): TreeListItem[] => {
                const filtered = [];
                for (const item of items) {
                    if (matches(item)) {
                        filtered.push(item);
                    } else if (item.items && item.items.length > 0) {
                        const childItems = filter(item.items);
                        if (childItems.length > 0) {
                            filtered.push({
                                ...item,
                                items: childItems
                            });
                        }
                    }
                }
                return filtered;
            };

            const matchingTreeItems = filter(props.data);

            if (st) {
                setExpand({
                    ids: matchingTreeItems.flatMap(x => [(x as any)[props.idField], ...x.items.map(y => (y as any)[props.idField])]),
                    idField: props.idField
                })
            }

            setFilteredTreeItems(matchingTreeItems);
        }
    }, [props.data, searchTerm])

    const handleChangeSearchTerm = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const onItemClick = (event: any) => {
        if (event.item.url) {
            props.onClick(event.item);
        }
        setSelect([event.itemHierarchicalIndex]);
        if  (event.item.items){
            onExpandChange(event);
        }
      };
    const onExpandChange = (event: any) => {
        const ids = expand.ids ? expand.ids.slice() : [];
        const index = ids.indexOf(event.item[props.idField]);
        index === -1 ? ids.push(event.item[props.idField]) : ids.splice(index, 1);
        setExpand({
            ids,
            idField: props.idField,
        });
    };
    const onCheckChange = (event: TreeViewExpandChangeEvent) => {
        const settings: TreeViewCheckChangeSettings = {
            singleMode: false,
            checkChildren: false,
            checkParents: false,
        };
        setCheck(handleTreeViewCheckChange(event, check, filteredTreeItems, settings) as any);
        if (!event.item.items || event.item.items.length === 0) {
            props.onCheckToggle(event.item);
        }
    };

    const countLeafChildren = (item: TreeListItem): number => {
        if (!Array.isArray(item.items) || item.items.length === 0) {
            return 1;
        }
    
        let count = 0;
        for (let child of item.items) {
            count += countLeafChildren(child);
        }
    
        return count;
    };

    const showTree = props.data && props.data.length > 0;

    return (
        <div className="tree-list">
            <Search
                value={searchTerm}
                onChange={handleChangeSearchTerm}
                />
            {(!showTree || filteredTreeItems.length === 0) && <div className="center">
                {props.data ? <label>{arxs.t("controls.treelist.empty")}</label> : <Spinner />}
            </div>}
            {(showTree && filteredTreeItems.length > 0) && <KendoTreeView
                    data={processTreeViewItems(filteredTreeItems, {
                        select: select,
                        check: check,
                        expand: expand,
                      })}
                    item={props => {
                        return <div>
                            {props.item.icon && <i className={props.item.icon} ></i>}
                            {props.item.text} {props.item.items && `(${countLeafChildren(props.item)})`}
                        </div>;
                    }}
                    expandIcons={true}
                    onExpandChange={onExpandChange}
                    aria-multiselectable={true}
                    onItemClick={onItemClick}
                    checkboxes={true}
                    onCheckChange={onCheckChange}
                />}
        </div>
    );
}