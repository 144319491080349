import React, { Component, Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import QRCode from "react-qr-code";

class QR extends Component {
  state = {
    imgSrc: null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (!this.props.data) {
        this.setState({ imgSrc: null });
      } else {
        this.renderImage(this.props.data, this.props.size);
      }
    }
  }

  componentDidMount() {
    this.renderImage(this.props.data, this.props.size);
  }

  renderImage = ({ value }, size) => {
    const padding = 20;
    const svg = ReactDOMServer.renderToStaticMarkup(<QRCode value={value} size={size - (padding * 2)}  level="L"/>);
    const svgImg = new window.Image();
    svgImg.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));

    var canvas = document.createElement("canvas");
    canvas.width = this.props.size;
    canvas.height = this.props.size;

    svgImg.onload = () => {
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(svgImg, padding, padding);
      this.setState({ imgSrc: canvas.toDataURL("image/png") });
    };
  }

  render() {
    if (this.state.imgSrc) {
      const fileName = this.props.data.name + ".png";
      return <a href={this.state.imgSrc} download={fileName}>
        <img
          src={this.state.imgSrc}
          alt={fileName} /></a>;
    }

    return <Fragment />;
  }
}
export default QR;
