import React, { useState, useEffect } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';
import Toaster from "components/util/Toaster";

import './FormReactivationPopup.scss';

export const createFormReactivationPopup = (value, onSubmit, onCancel) => {
  const state = {
    title: arxs.t("actions.form.setactive.title"),
    content: <FormReactivationPopup value={value} onSubmit={onSubmit} onCancel={onCancel} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,

    onSubmit: onSubmit,
  };

  return state;
};

function FormReactivationPopup(props) {
  const [data, setData] = useState({});
  const [originalRevisionDate, setOriginalRevisionDate] = useState({});

  useEffect(() => {
    const { id, revisionDate } = props.value;

    setData({ ids: [id], revisionDate });
    setOriginalRevisionDate(revisionDate);

  }, [props.value]);

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => {
    if (props.onSubmit) {
      if (data.revisionDate === null ||
        (data.revisionDate !== null && data.revisionDate !== undefined
          && data.revisionDate > originalRevisionDate
          && data.revisionDate > new Date())) {
        props.onSubmit(data);
      } else {
        if (data.revisionDate <= originalRevisionDate
          || data.revisionDate <= new Date()) {
          Toaster.error(arxs.t("actions.form.error.revisionDate"));
        } else {
          props.onSubmit(data);
        }
      }
    }
  }

  const rows = [
    [
      {
        name: "revisionDate",
        props: { showTime: false }
      }
    ],
  ];

  return <div className={`reactivate-form-popup ${props.className || ""}`}>
    <div className="reactivate-form-popup-body">
      <FieldSet schemaName="SetFormActive" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`reactivate-form-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`reactivate-form-confirm`} className="icon" onClick={confirm}>{arxs.t("common.save")}</Button>
      </div>
    </div>
  </div>;
}