import MobileDetect from 'mobile-detect';

const createPlatformState = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isIPhone = navigator.userAgent.match(/iPhone/);
  const hasTouch = navigator.maxTouchPoints >  1;
  const isIPad =  md.versionStr("iOS") && hasTouch && !isIPhone;
  const isMobile = !!md.mobile() || isIPad || isIPhone;
  const platform = {
    isPhone: !!md.phone() || isIPhone,
    isTablet: !!md.tablet() || isIPad,
    isMobile: isMobile,
    isDesktop: !isMobile,
    isIOS: isIPhone || isIPad,
  };

  return platform;
};
export default createPlatformState; 