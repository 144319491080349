import React, { useState, useEffect } from "react";
import arxs from "infra/arxs";
import TextAreaInput from "components/controls/form/FormItemEditorControls/TextAreaInput";
import PointsOfAttention from "components/controls/form/FormItemEditorControls/PointsOfAttention";
import Icons from "components/controls/form/FormItemEditorControls/Icons";
import Images from "components/controls/form/FormItemEditorControls/Images";
import RiskAndPreventionMeasures from "components/controls/form/FormItemEditorControls/RiskAndPreventionMeasures";
import Periodicals from "components/controls/form/FormItemEditorControls/Periodicals";
import Recommendation from "components/controls/form/FormItemEditorControls/Recommendation";
import RiskAnalysis from "components/controls/form/FormItemEditorControls/RiskAnalysis";
import RiskMeasurement from "./FormItemEditorControls/RiskMeasurement";
import PreRecommendation from "./FormItemEditorControls/PreRecommendation";
import CodeElementFormLookup from "./FormItemEditorControls/CodeElementFormLookup";
import Link from "./FormItemEditorControls/Link";
import Instruction from "./FormItemEditorControls/Instruction";

import "./FormItemEditor.scss";

export default function FormItemEditor(props) {
    const value = props.value || {};
    const [attachmentInfo, setAttachmentInfo] = useState({});

    useEffect(() => {
        setAttachmentInfo(props.attachmentInfo);
    }, [props.attachmentInfo]);

    const { id, onChange, knownControls, onAdd, autoFocus, readOnly, module, targetModule } = props;

    const handleChange = (state, fieldName) => {
        if (fieldName) {
            onChange({
                ...value,
                [fieldName]: state
            });
        } else {
            onChange({
                ...value,
                ...state
            });
        }
    };

    const handleDelete = () => {
        onChange();
    };

    const handleRequiredChange = (e) => {
        onChange({
            ...value,
            required: !value.required
        });
    };

    const handleControlChange = (selected) => {
        onChange({
            ...value,
            id: arxs.uuid.generate(),
            control: selected ? selected.id : null,
            data: undefined
        });
    };

    const selectedControl = knownControls.filter(x => x.id === value.control)[0];

    const defaultProps = {
        readOnly,
        knownControls,
        selectedControl,
        onAdd,
        autoFocus,
        module,
        targetModule,
        required: value.required,
        onControlChange: handleControlChange,
        onRequiredChange: handleRequiredChange,
        handleDelete: handleDelete,
    }

    const renderSelectedControl = () => {
        const field = {
            id,
            getter: () => value,
            setter: (state) => {
                handleChange(state);
            }
        };

        switch ((selectedControl || {}).type) {
            case "pointsOfAttention":
                return <PointsOfAttention
                    field={field}
                    {...defaultProps}
                ></PointsOfAttention>;
            case "icons":
                return <Icons
                    field={field}
                    {...defaultProps}
                />;
            case "images": {
                return <Images
                    field={field}
                    {...defaultProps}
                    attachmentInfo={attachmentInfo}
                />;
            }
            case "riskAndPreventionMeasures": {
                return <RiskAndPreventionMeasures
                    field={field}
                    {...defaultProps}
                />
            }
            case "periodicals": {
                return <Periodicals
                    field={field}
                    {...defaultProps}
                />
            }
            case "recommendation": {
                return <Recommendation
                    field={field}
                    {...defaultProps}
                />
            }
            case "riskAnalysis": {
                return <RiskAnalysis
                    field={field}
                    {...defaultProps}
                    includeRiskValue={true}
                />
            }
            case "riskMeasurement": {
                return <RiskMeasurement
                    field={field}
                    {...defaultProps}
                />
            }
            case "preRecommendation": {
                return <PreRecommendation
                    field={field}
                    {...defaultProps}
                />
            }
            case "link": {
                return <Link
                    field={field}
                    {...defaultProps}
                />
            }
            case "requirements": {
                return <CodeElementFormLookup
                    field={field}
                    code="InstructionCard.Requirements"
                    placeholder={arxs.t("controls.common.select")}
                    {...defaultProps}
                />;
            }
            case "additionalSafetyRequirements": {
                return <CodeElementFormLookup
                    field={field}
                    code="Commissioning.AdditionalSafetyRequirements"
                    placeholder={arxs.t("controls.common.select")}
                    {...defaultProps}
                />;
            }
            case "instruction": {
                return <Instruction
                    field={field}
                    {...defaultProps}
                    titleRequired={true}
                    type={(selectedControl || {}).type}
                />;
            }
            default:
                return <TextAreaInput
                    field={field}
                    {...defaultProps}
                    titleRequired={true}
                    type={(selectedControl || {}).type}
                />;
        }
    }

    return <div className="form-item-editor-row">
        {!readOnly && <div className="form-item-editor-drag-handle" {...props.dragHandleProps}>
            <i className="fa fas fa-grip-vertical"></i>
        </div>}
        <div className="form-item-editor-control">
            {renderSelectedControl()}
        </div>
        {!readOnly && <div className="form-item-editor-actions" onClick={handleDelete}>
            <i className="far fa-trash-alt"></i>
        </div>}
    </div>;
}