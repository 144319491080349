import React from 'react';
import arxs from 'infra/arxs';
import GlobalContext from "infra/GlobalContext";
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import { SecurityContext } from 'infra/SecurityContext';
import { MailRecipient } from 'infra/api/contracts';
import Toaster from 'components/util/Toaster';

import "./MailRecipients.scss";

export interface MailRecipientsProps {
  value: MailRecipient[],
  onChange(value: MailRecipient[]): void,
  readOnly?: boolean,
  securityContext?: SecurityContext,
}
export default function MailRecipients(props: MailRecipientsProps) {
  const handleAdd = (context: any) => {
    const cardLookup = createCardLookup({
        modules: ["Employee", "Contact"],
        onApplyFilter: (value: any) => {
          const recipients = 
            (props.value || []).map((x: MailRecipient) => ({ name: x.name, email: x.email }))
            .concat(
              Object.entries(value)
                .flatMap(pair => {
                  const ref = { id: pair[0], module: pair[1] };
                  const card = arxs.Api.lookups.resolveSubject(ref);
                  if (card) {
                    const name = [card.firstname, card.surname].filter(x => x).join(" ");
                    let email = (card.emails || []).filter((x: any) => x.isPreferred).map((x: any) => x.email)[0];
                    
                    if (!email) {
                      email = (card.emails ||[]).map((x: any) => x.email)[0]
                    }

                    if (email) { 
                      return [{ name, email }];
                    } else {
                      Toaster.error(arxs.t("actions.mailing.does_not_have_preferred_email", { name }))
                    }
                  }
                  return [];
                }))
            .distinct(x => x.email);
          props.onChange(recipients);
          context.popup.close();
        },
        securityContext: props.securityContext,
      });
    context.popup.show(cardLookup)
  };

  const handleDelete = (recipient: MailRecipient) => {
    const newValue = props.value.remove(recipient);
    props.onChange(newValue);
  };

  const renderActions = (recipient: MailRecipient) => {
    return !props.readOnly && <i className="far fa-times" onClick={() => handleDelete(recipient)}></i>;
  };

  const recipients = (props.value || [])
    .map(x => x.name
      ? <div key={x.email} className="recipient">{x.name} {`<${x.email}>`} {renderActions(x)}</div>
      : <div key={x.email} className="recipient">{x.email} {renderActions(x)}</div>
    );

  return <GlobalContext.Consumer>
    {
      context => <div className="recipients">
        {recipients}
        {!props.readOnly && <button className="button icon" onClick={() => handleAdd(context)}>
          <i className="far fa-plus"></i>
        </button>}
      </div>
    }
  </GlobalContext.Consumer>;
}