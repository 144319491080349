import React from 'react';

import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs'
import { OriginModuleEnum } from 'infra/api/contracts';

export default function Search(props) {
  const onClick = (context) => {
    const onApplyFilter = (arg) => {
      const card = Object.entries(arg).reduce((acc, cur) => ({ module: cur[1], id: cur[0] }), {});
      props.history.push({ pathname: `${arxs.moduleMetadataRegistry.getModulePath(card.module)}/${card.id}` });
      context.popup.close();
    }

    const securityContext = arxs.securityContext.buildForUserContext();
    const cardLookup = createCardLookup({
      onApplyFilter,
      singleSelection: true,
      securityContext,
      disallowEmptySelection: true,
      applyFilterText: arxs.t("search.goto_record"),
      disableAddition: true,
      excludeModules: [OriginModuleEnum.UserRole]
    });
    context.popup.show(cardLookup);
  };

  return (<GlobalContext.Consumer>
    {context => <div className="tile" onClick={() => onClick(context)}>
      <i className="fa fa-search flip-horizontally"></i>
    </div>}
  </GlobalContext.Consumer>);
}