import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import LegalStructureWizard from "./LegalStructureWizard";
import LegalStructureActions from "./LegalStructureActions";
import LegalStructureBoard from "./LegalStructureBoard";
import { ObjectDocumentType, OriginModuleEnum } from "infra/api/contracts";
import { ProductType } from "infra/Types";
import LegalStructureCard from "./LegalStructureCard";

const getLegalStructureMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.SchoolGroup;

  return {
    module,
    title: arxs.t("modules.legalstructure"),
    icon: "far fa-sitemap",
    path: "/legalstructure",
    base: {
      name: "LegalStructure",
      route: "/legalstructure",
      getResource: () => arxs.Api.getResource("legalstructure"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "SchoolGroup.Read",
      writeAction: "SchoolGroup.Write",
    },
    settings: {
      name: "LegalStructureSettings",
      route: "/legalstructure/settings",
      getResource: () => arxs.Api.getResource("legalstructure").settings(),
      readAction: "SchoolGroup.Settings.Write",
      writeAction: "SchoolGroup.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "name" },
          { name: "internalName" },
          { name: "abbreviation" },
          { name: "companyNumber" },
          { name: "educationDome", productType: ProductType.Helios },
          { name: "educationPortal", productType: ProductType.Helios },
          { name: "dateOfCreation" },
          { name: "businessType" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.contact"),
        initiallyOpen: true,
        fields: [{ name: "addresses" }, { name: "emails" }, { name: "phones" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.FloorPlan,
      ObjectDocumentType.AdditionalDocument
    ],
    singleFileDocumentTypes: [ObjectDocumentType.Image],
    statuses: [],
    actions: LegalStructureActions.getActions(OriginModuleEnum.SchoolGroup),
    wizard: {
      wizardClass: LegalStructureWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "name" }],
            [{ name: "abbreviation" }, { name: "internalName" }],
            [{ name: "companyNumber" }, { name: "rszNumber" }],
            [
              {
                name: "educationPortal",
                code: "EducationPortal",
                productType: ProductType.Helios,
              },
              {
                name: "educationDome",
                code: "EducationDome",
                parent: "educationPortal",
                productType: ProductType.Helios,
              },
            ],
            [
              { name: "dateOfCreation" },
              { name: "businessType", code: "CompanyType" },
            ],
            [
              {
                name: "geoLocation",
                onChange: (stateProxy) => {
                  LegalStructureWizard.addDefaultAddress(stateProxy);
                },
              },
            ],
            [
              {
                name: "addresses",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "country", width: "100px" },
                  { name: "zipCode", width: "60px" },
                  { name: "city", width: "150px" },
                  { name: "street" },
                  { name: "number", width: "60px" },
                  { name: "bus", width: "50px" },
                  {
                    name: "type",
                    code: "SchoolGroup.Address.Type",
                    width: "150px",
                  },
                ],
                onChange: (
                  stateProxy,
                  fieldName: any,
                  row: any,
                  childName: any
                ) => {
                  LegalStructureWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    LegalStructureWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [
              {
                name: "emails",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "email" },
                  { name: "type", code: "SchoolGroup.Email.Type" },
                ],
                onChange: (
                  stateProxy,
                  fieldName: any,
                  row: any,
                  childName: any
                ) => {
                  LegalStructureWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    LegalStructureWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [
              {
                name: "extraData",
                type: "itemlist",
                children: [
                  { name: "name" },
                  { name: "type", code: "SchoolGroup.ExtraData.Type" },
                ],
              },
            ],
            [
              {
                name: "phones",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "number" },
                  { name: "type", code: "SchoolGroup.Phone.Type" },
                ],
                onChange: (
                  stateProxy,
                  fieldName: any,
                  row: any,
                  childName: any
                ) => {
                  LegalStructureWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    LegalStructureWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "technicalBusinessUnits",
                type: "itemlist",
                children: [
                  { name: "number", width: "150px" },
                  { name: "name" },
                ],
                onDelete: (stateProxy, items, index) =>
                  LegalStructureWizard.isDeleteAllowed(
                    stateProxy,
                    "technicalBusinessUnits",
                    items,
                    index
                  ),
              },
            ],
            [
              {
                name: "naceBells",
                type: "itemlist",
                onDelete: (stateProxy, items, index) =>
                  LegalStructureWizard.isDeleteAllowed(
                    stateProxy,
                    "naceBells",
                    items,
                    index
                  ),
              },
            ],
            [
              {
                name: "paritairComites",
                type: "itemlist",
                onDelete: (stateProxy, items, index) =>
                  LegalStructureWizard.isDeleteAllowed(
                    stateProxy,
                    "paritairComites",
                    items,
                    index
                  ),
              },
            ],
            [
              {
                name: "landRegistries",
                type: "itemlist",
                children: [
                  { name: "section" },
                  { name: "parcelNumber" },
                  {
                    name: "area",
                    unit: arxs.t("unit.square_meter").toLowerCase(),
                  },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        branches: [],
        codeElements: {},
      },
    },
    board: {
      allowDuplicate: false,
      lookups: {
        legalStructures: {},
        tagMap: {},
        codeElementsById: {},
        employeeMap: {},
        periodicalMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "name" },
        { name: "abbreviation" },
        { name: "internalName" },
        { name: "address" },
        { name: "geoLocation" },
        { name: "phone" },
        { name: "email" },
        { name: "companyNumber", width: "175px" },
        { name: "rszNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: LegalStructureBoard,
      views: [BoardViewType.Grid, BoardViewType.Map],
    },
    cardClass: LegalStructureCard
  };
};
export default getLegalStructureMetadata;
