/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IncidentManagementSeriousInjuryRangeType {
    DivergentIncident = 'DivergentIncident',
    MaterialCause = 'MaterialCause',
    InjuryType = 'InjuryType'
}

export function IncidentManagementSeriousInjuryRangeTypeFromJSON(json: any): IncidentManagementSeriousInjuryRangeType {
    return IncidentManagementSeriousInjuryRangeTypeFromJSONTyped(json, false);
}

export function IncidentManagementSeriousInjuryRangeTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentManagementSeriousInjuryRangeType {
    return json as IncidentManagementSeriousInjuryRangeType;
}

export function IncidentManagementSeriousInjuryRangeTypeToJSON(value?: IncidentManagementSeriousInjuryRangeType | null): any {
    return value as any;
}

