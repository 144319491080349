import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import { ImageOverview } from "components/controls/images/ImageOverview";
import { ObjectDocumentType } from "infra/api/contracts";


export default class Images extends FormItemEditorController {
    lookups = {
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups,
            uploadCorrelationKey: new Date().format_yyyyMMddhhmmsshhh()
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    renderImageControl = (context) => {
        const { field, readOnly, module } = this.props;
        const value = field.getter();
        const data = value.data || [];
        let settings = value.settings;

        const onAddImage = (state) => {
            if (state && state.documents) {
                if (state.documents.some(x => x.type === ObjectDocumentType.FormImage)) {
                    let newData = data || [];
                    newData = newData.concat(state.documents.filter(x => x.type === ObjectDocumentType.FormImage).map(x => ({ ...x, refId: arxs.uuid.generate() })));
                    field.setter({ ...value, data: newData, settings });
                }
            }
        }

        const onDeleteImage = (state) => {
            let newData = data.filter(x => x !== state)
            field.setter({ ...value, data: newData, settings });
        }

        const onSortImages = (state) => {
            field.setter({ ...value, data: state, settings })
        };

        const renderImages = () => {
            return <ImageOverview
                className="field full-width"
                key="images"
                module={module}
                readOnly={readOnly}
                data={data}
                onAdd={onAddImage}
                onDelete={onDeleteImage}
                onSort={onSortImages}
                correlationKey={this.state.uploadCorrelationKey}
                imageType={ObjectDocumentType.FormImage}
                renderInOneLine={true}
            />
        }

        const onNumberOfColumnsChange = (state) => {
            if (state && state.id) {
                let newSettings = settings || {};
                newSettings.numberOfColumns = state.id;
                field.setter({ ...value, settings: newSettings });
            }
        }

        const renderSettings = () => {
            const values = [1, 2, 3, 4].map(x => ({ id: x, name: x }));
            const selected = settings && settings.numberOfColumns ? { id: settings.numberOfColumns, name: settings.numberOfColumns } : { id: 1, name: 1 };
            return <div className="field image-settings">
                 <label>{arxs.t("forms.items.images.number_of_columns")}</label>
                <DropDown
                    id={`image-settings-number-of-columns`}
                    key={`image-settings-number-of-columns`}
                    className="number-of-columns"
                    items={values}
                    selected={selected}
                    onChange={onNumberOfColumnsChange}
                    readOnly={readOnly}
                    disableClearButton={true}
                />
            </div>
        }

        return <div className="images-container">
            {renderSettings()}
            {renderImages()}
        </div>;
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderImageControl(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}