/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IncidentManagementVictimType {
    None = 'None',
    Employee = 'Employee',
    Student = 'Student',
    ThirdParty = 'ThirdParty'
}

export function IncidentManagementVictimTypeFromJSON(json: any): IncidentManagementVictimType {
    return IncidentManagementVictimTypeFromJSONTyped(json, false);
}

export function IncidentManagementVictimTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IncidentManagementVictimType {
    return json as IncidentManagementVictimType;
}

export function IncidentManagementVictimTypeToJSON(value?: IncidentManagementVictimType | null): any {
    return value as any;
}

