import React, { Component } from 'react';
import Wizard from "components/wizard/Wizard";

import './WizardPopup.scss';

export const createWizardPopup = (module, data, readOnly, close, onSave) => {
  const state = {
    title: "Editeren",
    content: <WizardPopup 
      module={module}
      data={data}
      readOnly={readOnly}
      close={close}
      onSave={onSave} />,
    // maximized: true

  };
  return state;
};

class WizardPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: undefined
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // if ((this.props.value !== '' && (this.state.dirtyValue === '' || this.state.dirtyValue === undefined))
    //   && (prevProps.value !== this.props.value)) {
    //   this.setState({ dirtyValue: this.props.value });
    // }
  }

  onClose = () => {
    if (this.props.close) {
      this.props.close();
    }
  }

  onSave = (data) => {
    if (this.props.onSave) {
      this.props.onSave(data);
    }
    this.onClose();
  }

  render() {
    const { module, data, readOnly } = this.props;

    return module && data ?
      <div className={`wizard-popup`}>
        <div className="wizard-popup-body">
          <div className="wizard-wrapper">
            <Wizard
              module={module}
              data={data}
              readOnly={readOnly}
              callback={this.onClose}
              onSave={this.onSave}
              isPopup={true}
            />
          </div>
        </div>
      </div> : null;
  }
}
export default WizardPopup;
