import React from "react";
import arxs from 'infra/arxs';
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";

import "./PointsOfAttention.scss";

export default class PointsOfAttention extends FormItemController {
    getAdditionalLookups() {
        return {
            codeElementsById: {},
        };
    }

    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    refresh = () => {
    }

    renderItem = (data, index) => {
        const value = this.sanitizeValue((data && data.value) || "");

        return <li key={`points-of-attention-li-${index}`}>
            <label>{value}</label>
        </li >
    }

    renderItems = () => {
        let values = this.getter();
        if (!Array.isArray(values)) {
            values = [];
        }

        return <div className="points-of-attention">
            <div className="points-of-attention-values">
                <ul>
                    {values.map((line, index) => this.renderItem(line, index))}
                </ul>
            </div>
        </div>
    }

    render() {
        const { codeElementsById } = this.state;
        const settings = this.getSettings();;
        let selectedType;
        if (settings && codeElementsById) {
            if (arxs.uuid.isValid(settings.title)) {
                selectedType = settings.title
                    ? { id: settings.title, name: (codeElementsById[settings.title] || {}).name }
                    : undefined;
            } else if (settings.title) {
                selectedType = { name: settings.title };
            }
        }

        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control points-of-attention">
                    {this.renderHeader((selectedType || {}).name, true)}
                    <div className="form-control-field">
                        {this.renderItems()}
                    </div>
                </div>}
        </GlobalContext.Consumer>)
    }
}