import React, { Component, Fragment } from 'react';
import { TreeView as KendoTreeView } from '@progress/kendo-react-treeview';
import arxs from 'infra/arxs';
import TreeViewItem from './TreeViewItem'
import { processTreeViewItems } from '@progress/kendo-react-treeview';
import Toaster from 'components/util/Toaster';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import Search from 'components/controls/Search';

import './TreeView.scss';

class TreeView extends Component {
    lookups = {
        codeElementsStructure: {},
        codeElements: {},
    }

    actions = {
        addAction: 'addAction'
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups,
            data: [],
            expand: {
                ids: [],
                idField: 'id'
            },
            searchTerm: '',
            // startTime: new Date().getTime(),
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const oldSearchTerm = prevProps.searchTerm;
        const newSearchTerm = this.props.searchTerm;
        if (oldSearchTerm !== newSearchTerm) {
            this.setState({ searchTerm: newSearchTerm }, this.refresh);
        } else {
            const oldItems = prevProps.items || [];
            const newItems = this.props.items || [];
            if (oldItems !== newItems && oldItems.length !== newItems.length) {
                this.refresh();
            }
        }
    }

    applySearchFilter = (items, term) => {
        return items.reduce((acc, item) => {
            let expand = true;
            if (item.type === arxs.codeElementTypes.kind && this.props.excludeTypes) {
                expand = false;
            }

            if (item.searchTerms) {
                if (item.searchTerms.some(x => x.toLowerCase().indexOf(term.toLowerCase()) > -1)) {
                    item.expanded = expand;
                    acc.push(item);
                }
                else if (item.items && item.items.length > 0) {
                    let newItems = this.applySearchFilter(item.items, term);

                    if (newItems && newItems.length > 0) {
                        acc.push({ ...item, items: newItems, searchTerms: item.searchTerms, expanded: expand });
                    }
                }
            }
            return acc;
        }, []);
    }

    handleChangeSearchTerm = (event) => {
        const searchTerm = event.target.value;
        this.setState({ searchTerm }, this.refresh)
    }

    refresh = () => {
        const sourceItems = (this.props.items || []).filter(x => x.isDeleted === false);
        const searchTerm = this.state.searchTerm;

        /*
        "Tag.Kind": {
            "key": "Tag.Kind",
            "hierarchyType": "KindAndType",
            "groups": ["EmptyGroup"],
            "name": "Soort",
            "module": "Tag",
            "children": [{
                    "key": "Tag.Type",
                    "hierarchyType": "Nested",
                    "groups": [],
                    "name": "Type",
                    "module": "Tag"
                }
            ]
        }
        */
        const { allCodes, additionalCodes, module, rootCode, addItemText, excludeCodes, additionalModules } = this.props;

        let moduleRoots = Object.values(this.state.codeElementsStructure)
            .filter(x => x.module === module || (additionalModules || []).includes(x.module));

        if (rootCode) {
            moduleRoots = moduleRoots.filter(x => x.key === rootCode);
        }

        if (additionalCodes) {
            let additionalRoots = Object.values(this.state.codeElementsStructure)
                .filter(x => additionalCodes.map(ac => ac.module).includes(x.module));

            additionalRoots = additionalRoots.filter(x => additionalCodes.map(ac => ac.code).includes(x.key));

            moduleRoots = moduleRoots.concat(additionalRoots);
        }

        const nextTypePerHierarchyMap = {
            [arxs.hierarchyTypes.flat]: {
                [arxs.codeElementTypes.root]: arxs.codeElementTypes.type,
            },
            [arxs.hierarchyTypes.kindAndType]: {
                [arxs.codeElementTypes.root]: arxs.codeElementTypes.kind,
                [arxs.codeElementTypes.kind]: arxs.codeElementTypes.type,
                [arxs.codeElementTypes.type]: arxs.codeElementTypes.item,
            },
            [arxs.hierarchyTypes.sortAndKindAndType]: {
                [arxs.codeElementTypes.root]: arxs.codeElementTypes.sort,
                [arxs.codeElementTypes.sort]: arxs.codeElementTypes.kind,
                [arxs.codeElementTypes.kind]: arxs.codeElementTypes.type,
                [arxs.codeElementTypes.type]: arxs.codeElementTypes.item,
            },
            [arxs.hierarchyTypes.nested]: {
                [arxs.codeElementTypes.root]: arxs.codeElementTypes.kind,
                [arxs.codeElementTypes.kind]: arxs.codeElementTypes.type,
                [arxs.codeElementTypes.type]: arxs.codeElementTypes.item,
            }
        };

        const placeHolderMap = {
            [arxs.codeElementTypes.sort]: arxs.t("controls.treeview.add_sort"),
            [arxs.codeElementTypes.kind]: arxs.t("controls.treeview.add_kind"),
            [arxs.codeElementTypes.type]: arxs.t("controls.treeview.add_type"),
            [arxs.codeElementTypes.norm]: arxs.t("controls.treeview.add_norm"),
            [arxs.codeElementTypes.item]: addItemText || arxs.t("controls.treeview.add_setting"),
        };

        const mapToTreeViewItem = (root, codeElement, parent) => {

            const { hierarchyType, module } = root;
            const { id, render } = codeElement;
            const nextTypeMap = nextTypePerHierarchyMap[hierarchyType];

            const groups = root.groups;
            let type = parent ? nextTypeMap[parent.type] : arxs.codeElementTypes.root;

            if (parent && parent.type === arxs.codeElementTypes.kind && groups.length > 1) {
                if (this.props.unforkTypes) {
                    switch (codeElement.code) {
                        case "NormGroup": type = arxs.codeElementTypes.norm; break;
                        case "TypeGroup": type = arxs.codeElementTypes.type; break;
                        default: break;
                    }

                } else {
                    type = arxs.codeElementTypes.group;
                }
            }

            if (parent && parent.type === arxs.codeElementTypes.group) {
                type = arxs.codeElementTypes.type;
            }

            let name = !parent ? `${root.name} - ${arxs.modules.titles[module]}` : codeElement.name;

            if (type === arxs.codeElementTypes.group) {
                name = arxs.t(`controls.treeview.codeelement.${codeElement.code}`);
            }

            const mapped = { id, parent, name, type, render, searchTerms: (name ? [name] : []), module, readOnly: codeElement.isReadOnly, code: codeElement.code };

            const hasAddAction =
                !mapped.readOnly && (
                    (this.props.allowEditBranches && (!parent || !parent.readOnly) && (type === arxs.codeElementTypes.root
                        || type === arxs.codeElementTypes.sort
                        || (type === arxs.codeElementTypes.kind && groups.length <= 1)
                        || type === arxs.codeElementTypes.group))
                    || (this.props.allowEditLeafs && (!parent || !parent.readOnly) && this.props.onAddNewItem && (type === arxs.codeElementTypes.type || type === arxs.codeElementTypes.norm))
                    || false);

            let actionItem;

            if (hasAddAction) {
                const actionType = type === arxs.codeElementTypes.group ? codeElement.code === "NormGroup" ? arxs.codeElementTypes.norm : arxs.codeElementTypes.type : nextTypeMap[type];
                actionItem = {
                    type: this.actions.addAction,
                    name: placeHolderMap[actionType],
                    actionType,
                    groups,
                    parentId: codeElement.id
                };
            }

            if (this.props.includeItems && type === arxs.codeElementTypes.type) {
                mapped.items = [actionItem].filter(x => x)
                    .concat(sourceItems
                        .filter(x => x.kindId === parent.id && x.typeId === codeElement.id)
                        .map(x => mapToTreeViewItem(root, x, mapped)));
            } else {
                if (type === arxs.codeElementTypes.kind) {
                    if (root.groups.length === 1) {
                        codeElement = codeElement.children[0];
                        if (actionItem) {
                            actionItem.parentId = codeElement.id;
                        }
                    }
                    else if (root.groups.length > 1) {
                        if (this.props.unforkTypes) {
                            codeElement = codeElement.children.filter(x => x.code === "TypeGroup")[0];
                        } else {
                            mapped.items = codeElement.children.map(x => mapToTreeViewItem(root, x, mapped));
                        }
                    }
                }

                if (type !== arxs.codeElementTypes.type) {
                    mapped.items = [actionItem].filter(x => x)
                        .concat(((codeElement || {}).children || [])
                            .filter(x => x.isActive === undefined || x.isActive === true)
                            .map(x => mapToTreeViewItem(root, x, mapped)));
                }
            }

            return mapped;
        };

        let data = moduleRoots
            .filter(root => allCodes || [arxs.hierarchyTypes.sortAndKindAndType, arxs.hierarchyTypes.kindAndType].contains(root.hierarchyType))
            .filter(root => (!excludeCodes || excludeCodes.length === 0) || !excludeCodes.contains(root.key))
            .flatMap(root => {
                const codeElements = this.state.codeElements[root.key];
                if (!codeElements || codeElements.length === 0 || codeElements.isReadOnly) return [];
                return codeElements
                    .filter(x => x.isActive === undefined || x.isActive === true)
                    .filter(this.props.securityContext.filter)
                    .map(codeElement => mapToTreeViewItem(root, codeElement));
            });

        if (!allCodes) {
            data = data.flatMap(x => x.items);
        }

        if (searchTerm && searchTerm.length > 0) {
            data = this.applySearchFilter(data, searchTerm);
        }

        this.setState({ data }, () => {
            this.props.onFilter && this.props.onFilter(data);
            this.props.onItemClick(undefined, undefined);
        });

        if (this.props["onItemsLoad"]) {
            this.props.onItemsLoad(data);
        }

        if (this.state.selectedId) {
            const traverse = (item) => item ? [...(item.items || []).flatMap(traverse), item] : [];
            const items = data.flatMap(traverse);
            const item = items.filter(x => x.id === this.state.selectedId)[0];
            if (item) {
                this.onItemClick({ item: item }, true);
            }
        }
    }

    getNewActionHandler = (item) => {
        switch (item.actionType) {
            case arxs.codeElementTypes.sort:
                return (value, parentId) => this.addNewCodeElement(value, parentId, arxs.codeElementTypes.sort);
            case arxs.codeElementTypes.norm:
                return (value, parentId) => this.addNewCodeElement(value, parentId, arxs.codeElementTypes.norm);
            case arxs.codeElementTypes.type:
                return (value, parentId) => this.addNewCodeElement(value, parentId, arxs.codeElementTypes.type);
            case arxs.codeElementTypes.kind:
                return (value, parentId) => this.addNewCodeElement(value, parentId, arxs.codeElementTypes.kind, item.groups);
            case arxs.codeElementTypes.item:
                return (value) => this.props.onAddNewItem(value, item.parentId, arxs.codeElementTypes.item);
            default:
                throw new Error("Error! Missing case for actionType=" + item.actionType);
        }
    }

    addNewCodeElement = (value, parentId, elementType, groups) => {
        if (!!value) {
            arxs.ApiClient.masterdata.codeElements.add({ parentId, value, groups })
                .then(() => Toaster.success(arxs.t("controls.treeview.messages.saved", { type: elementType })))
                .then(this.refresh);
        } else {
            Toaster.error(arxs.t("controls.treeview.messages.error.empty"));
        }
    }

    onItemClick = (event, preventExpand) => {
        // TagManagement
        // CodeConstants.Tags.kind
        //      - 01. ???
        //          - 01.01 ????
        //              - Tag 1
        //              - Tag 2
        //              - Action: Add new tag
        //          - Action: Add new type
        //     - Action: Add new kind

        // TagLookup
        // CodeConstants.Tags.kind
        //      - 01. ???
        //          - 01.01 ????

        const item = event.item;

        if (item.action) return;

        const children = (item.items || []).length > 0 ? item.items : (this.props.items || []).filter(x => x.typeId === item.id);
        this.props.onItemClick(item, children);
        if (!preventExpand) {
            this.handleExpandAndCollapse(event.item);
        }
    }

    handleExpandAndCollapse = (item) => {
        this.setState({ selectedId: item && item.id });

        if (this.props.excludeTypes && item.type === arxs.codeElementTypes.kind) {
            return;
        }

        if (item.items) {
            let ids = this.state.expand.ids.slice();
            const index = ids.indexOf(item.id);
            index === -1 ? ids.push(item.id) : ids.splice(index, 1);
            this.setState({ expand: { ids, idField: 'id' } });
        }
    }

    onBranchEdit = (value, id, type) => {
        if (!!value) {
            arxs.ApiClient.masterdata.codeElements.edit(id, value)
                .then(() => Toaster.success(arxs.t("controls.treeview.messages.saved", { type: type })));
        } else {
            Toaster.error(arxs.t("controls.treeview.messages.error.empty"));
        }
    }

    onBranchDelete = (context, id, type) => {
        const confirmation = createInputPopup(context, arxs.t("controls.treeview.messages.delete_confirmation", { type: type }), () => arxs.ApiClient.masterdata.codeElements.delete(id)
            .then(() => Toaster.success(arxs.t("controls.treeview.messages.deleted", { type: type }))));

        context.inputPopup.show(confirmation);
    }

    onItemEdit = (value, id) => {
        this.props.onEditItem(value, id);
    }

    onItemDelete = (context, id, type) => {
        this.props.onDeleteItem(context, id);
    }

    renderTreeItem = (item) => {
        const { allowEditLeafs, allowEditBranches, itemIcon, onImport } = this.props;
        const isRoot = item.type === arxs.codeElementTypes.root;
        const hasChildItems = item.items && item.items.length > 1 && item.items.some(x => !Object.keys(this.actions).contains(x.type));
        const isEditable = !isRoot && !item.readOnly && this.state.selectedId === item.id;
        const isDeletable = isEditable && !hasChildItems;
        const isBranchEditable = allowEditBranches && isEditable;
        const isLeafEditable = allowEditLeafs && isEditable;
        const isLeafDeletable = allowEditLeafs && isDeletable;
        const isBranchDeletable = allowEditBranches && isDeletable;
        const isRootImportable = isRoot && !item.readOnly && onImport ? true : false;

        switch (item.type) {
            case arxs.codeElementTypes.root:
            case arxs.codeElementTypes.sort:
            case arxs.codeElementTypes.kind:
            case arxs.codeElementTypes.group:
            case arxs.codeElementTypes.type:
            case arxs.codeElementTypes.norm:
                if (item.render) {
                    return item.render();
                }

                return <TreeViewItem
                    id={item.id}
                    value={item.name}
                    itemType={item.type}
                    placeHolder={arxs.t("controls.treeview.empty")}
                    isBranch
                    isExpanded={item.expanded}
                    allowEdit={isBranchEditable}
                    allowDelete={isBranchDeletable}
                    onDelete={this.onBranchDelete}
                    onEdit={this.onBranchEdit}
                    importExport={isRootImportable ? this.renderImportExport : undefined}
                    code={item.code}
                />;
            case arxs.codeElementTypes.item:
                if (item.render) {
                    return item.render();
                }

                return <TreeViewItem
                    id={item.id}
                    value={item.name}
                    placeHolder={arxs.t("controls.treeview.empty")}
                    isLeaf
                    allowEdit={isLeafEditable}
                    allowDelete={isLeafDeletable}
                    itemIcon={itemIcon}
                    onDelete={this.onItemDelete}
                    onEdit={this.onItemEdit} />;
            case this.actions.addAction:
                return <TreeViewItem
                    onEdit={this.getNewActionHandler(item)}
                    placeHolder={item.name}
                    parentId={item.parentId}
                    isAction />;

            default:
                arxs.logger.error("Tree item not supported {item}", item);
                return;
        }
    }

    onExport = (e, context, code, name) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.onExport && this.props.onExport(context, code, name);
    }

    onImport = (e, context, code) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.onImport && this.props.onImport(context, code);
    }

    renderImportExport = (code, name, context) => {
        return <Fragment><div className='export' onClick={e => this.onExport(e, context, code, name)}>
            <span title={arxs.t('settings.import.export')}><i className="fas fa-cloud-download-alt"></i></span>
        </div>
            <div className='import' onClick={e => this.onImport(e, context, code)}>
                <span title={arxs.t('settings.import.title')}><i className="fas fa-cloud-upload-alt"></i></span>
            </div>
        </Fragment>
    }

    render() {
        const { textField, rootExpanded, additionalModules } = this.props;
        const { data, expand } = this.state;

        //TODO: make this recursie
        const getFilteredTreeData = () => {
            let filteredData = data.filter(entry => this.props.filterTree(entry.id));

            const filterItems = (root) => {
                if (root.items) {
                    root.items = root.items.filter(subitem => this.props.filterTree(subitem.id));

                    for (const subitem of root.items) {
                        filterItems(subitem);
                    }
                }
            }

            for (const root of filteredData) {
                filterItems(root);
            }
            return filteredData;
        }

        let treeItems = this.props.filterTree ? getFilteredTreeData() : data;

        if (additionalModules) {
            var upScaledTreeItems = [];

            for (const item of treeItems) {
                if (item.parent && upScaledTreeItems.any(x => x.id === item.parent.id)) {
                    var parent = upScaledTreeItems.filter(x => x.id === item.parent.id)[0];
                    if (!parent.items.any(x => x.id === item.id)) {
                        parent.items.push(item);
                    }
                } else {
                    item.parent.items = [item];
                    upScaledTreeItems.push(item.parent);

                }
            }
            treeItems = upScaledTreeItems;
        }

        const recursiveFilter = (arr, predicate, childrenProp) => arr.reduce((a, o) => {
            if (!predicate(o)) {
                return a;
            }

            const obj = Array.isArray(o[childrenProp]) ?
                Object.assign({}, o, {
                    [childrenProp]: recursiveFilter(o[childrenProp], predicate, childrenProp)
                })
                :
                o;

            a.push(obj);

            return a;
        }, []);

        treeItems = recursiveFilter(treeItems, ({ readOnly }) => !readOnly, 'items');

        if (rootExpanded) {
            const rootId = treeItems.filter(x => x.type === arxs.codeElementTypes.root).map(x => x.id);
            if (!expand.ids.some(x => x === rootId)) {
                expand.ids.push([rootId]);
            }
        }

        return (<div className="treeview">
            {!this.props.hideSearch
                && <Search
                    value={this.state.searchTerm}
                    onChange={this.handleChangeSearchTerm}
                    />}
            <div className="kendo-treeview">
                <KendoTreeView
                    data={processTreeViewItems(treeItems, { expand: expand })}
                    textField={textField}
                    item={props => {
                        return this.renderTreeItem(props.item);
                    }}
                    onItemClick={this.onItemClick}
                />
            </div>
        </div>
        );
    }
}
export default TreeView;