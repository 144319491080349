import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";

class TaskRequestBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      responsible: this.getEmployeeValue(
        card.relationships.filter(
          (x) => x.type === RelationshipType.Responsible
        ),
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      owner: this.getEmployeeValue(
        card.relationships.filter((x) => x.type === RelationshipType.Creator),
        stateProxy.getter("employeeMap")
      ),
      notifier: this.getNotifierValue(card.notifier, stateProxy),
      createdAt: card.createdAt && new Date(card.createdAt),

      tags: card.tags,
    };
  }

  getNotifierValue = (ref, stateProxy) => {
    if (ref && ref.module) {
      switch (ref.module) {
        case OriginModuleEnum.Employee: return this.getEmployeeValue(ref, stateProxy.getter("employeeMap"));
        case OriginModuleEnum.Contact: return this.getContactValue([ref], stateProxy.getter("contactMap"));
        default: break;
      }
    }
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.kind,
      card.type,
      card.title,
      card.description,
      card.responsible,
      card.owner,
      card.notifier,
      card.coResponsibles,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.taskRequests) {
        lookups.pristine = lookups.taskRequests
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.taskRequests;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new TaskRequestBoard();
