import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';
import _ from 'lodash';

class EmployeeWizard extends WizardController {

  onLookupsChange(stateProxy) {
    this.filterParitairComitePerAssignment(stateProxy);
  }

  setUserNameIfEmpty = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const pristine = stateProxy.getter("pristine");
      const data = stateProxy.getter("data");

      let userName = pristine["userName"];
      if (!userName) {
        const firstname = data["firstname"] || '';
        const surname = data["surname"] || '';

        const newUserName = firstname.toLowerCase().replace(/\s/g, '') + surname.toLowerCase().replace(/\s/g, '');

        const newData = { ...data, userName: newUserName };

        stateProxy.setter({ data: newData }, resolve);
      } else {
        resolve();
      }

    });
  }

  filterParitairComitePerAssignment = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");
      const filter = stateProxy.getter("filter") || {};
      const legalStructureMap = stateProxy.getter("legalStructureMap");
      const branchMap = stateProxy.getter("branchMap");

      const assignments = data["assignments"] || pristine["assignments"] || [];

      if (legalStructureMap && branchMap) {
        for (const assignment of assignments) {
          const legalStructure = assignment.legalStructure && assignment.legalStructure.id && legalStructureMap[assignment.legalStructure.id];
          const branch = assignment.branch && assignment.branch.id && branchMap[assignment.branch.id];

          if (branch) {
            _.set(filter, `assignments.${assignment.id}.paritairComite`, branch["paritairComites"].map(x => x.id).toDictionary(x => x));
          } else if (legalStructure) {
            _.set(filter, `assignments.${assignment.id}.paritairComite`, legalStructure["paritairComites"].map(x => x.id).toDictionary(x => x));
          } else {
            _.set(filter, `assignments.${assignment.id}.paritairComite`, {});
          }
        }

        stateProxy.setter({ filter }, resolve);
      } else {
        resolve();
      }
    });
  }

  getFilteredParitairComites = (stateProxy, row, assignment) => {
    const filter = stateProxy.getter("filter");

    if (filter && assignment.id) {
      return Object.keys(filter['assignments'][assignment.id]['paritairComite']).some(x => x === row.id);
    } else {
      const legalStructureMap = stateProxy.getter("legalStructureMap");
      const branchMap = stateProxy.getter("branchMap");

      if (legalStructureMap && branchMap) {
        const legalStructure = assignment.legalStructure && assignment.legalStructure.id && legalStructureMap[assignment.legalStructure.id];
        const branch = assignment.branch && assignment.branch.id && branchMap[assignment.branch.id];

        if (branch) {
          return branch.paritairComites.map(x => x.id).some(x => row.id);
        } else if (legalStructure) {
          return legalStructure.paritairComites.map(x => x.id).some(x => x === row.id);
        } else {
          return false;
        }
      }
    }
  }

  validate(stateProxy, getCurrentFieldValue, preValidation) {
    const userName = getCurrentFieldValue("userName");
    const id = getCurrentFieldValue("id");

    const employees = stateProxy.getter("employees");

    if (employees && employees.some(x => x.id !== id && x.userName.toLowerCase() === userName.toLowerCase())) {
      preValidation["userName"] = { error: arxs.t("wizard.validation.non_unique_value", { field: arxs.t(`field.userName`) }) };
    }

    return preValidation;
  }
}
export default new EmployeeWizard();