import arxs from 'infra/arxs';
import 'infra/ArrayExtensions';
import CardDataSource from 'infra/CardDataSource';
import { SecurityContext } from 'infra/SecurityContext';
import PlanningFilters from './PlanningFilters'
import { RelationshipType } from 'infra/api/contracts';

export default class PlanningDataSource {

    lookups: { [index: string]: any } = {
        planningMoments: [],
    };

    id: number;
    subscriptions: { lookups: { dispose: () => void; }; };
    refresh: any;
    cardDataSource: CardDataSource;
    securityContext: SecurityContext | undefined;
    planningFilters: any = {};

    constructor(criteria: any, securityContext?: SecurityContext) {
        this.id = arxs.getIncrementalIndex();

        this.securityContext = securityContext;

        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, (lookups: any) => {
                    if (lookups.planningMoments) {
                        const planningMoments = lookups.planningMoments
                            .map((x: any) => ({
                                ...x,
                                start: new Date(x.start),
                                end: new Date(x.end),
                            }));
                        this.planningFilters = new PlanningFilters(planningMoments, securityContext, arxs.Identity.profile, [RelationshipType.Responsible, RelationshipType.CoResponsible]);
                        if (this.refresh) {
                            this.refresh();
                        }
                    }
                })
        };

        this.planningFilters = new PlanningFilters([], securityContext, arxs.Identity.profile, [RelationshipType.Responsible, RelationshipType.CoResponsible]);
        this.cardDataSource = new CardDataSource(criteria);
    }

    getResult = (items: Array<any>, securityContext?: any | undefined) => {
        const securityFilter = securityContext ? securityContext.filter : () => true;
        const filteredItems = items.filter(securityFilter);

        const result = {
            cards: filteredItems || [],
            modules: (filteredItems || []).map((x: { module: string; }) => x.module).distinct((x: string) => x)
        }

        return result;
    }

    setRefresh = (refresh: () => void) => {
        this.refresh = refresh;
        this.cardDataSource && this.cardDataSource.setRefresh(refresh);
    }

    dispose = () => {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
        if (this.cardDataSource) {
            this.cardDataSource.dispose();
        }
    }

    getPlannableItems = () => {
        const anyFilter = (card: any) =>
            [
                this.planningFilters.expiredTaskFilter(),
                this.planningFilters.toPlanfilter(0, 1),
                this.planningFilters.toPlanfilter(1, 8),
                this.planningFilters.toPlanfilter(8, 15),
                this.planningFilters.toPlanfilter(15),
                this.planningFilters.noTargetDateAndPlanningfilter()
            ]
                .any(f => f(card));
        const order = () => { };
        const items = this.cardDataSource.get(anyFilter, order);

        return this.getResult(items, this.securityContext);
    }

    getExpired = () => {
        const filter = this.planningFilters.expiredTaskFilter();
        const order = () => { };

        const items = this.cardDataSource.get(filter, order);

        return this.getResult(items, this.securityContext);
    }

    getToPlan = (start: number, endExclusive: number) => {
        const filter = this.planningFilters.toPlanfilter(start, endExclusive);
        const order = () => { };

        const items = this.cardDataSource.get(filter, order);

        return this.getResult(items, this.securityContext);
    }

    getNoTargetDate = () => {

        const filter = this.planningFilters.noTargetDatefilter();
        const order = () => { };

        const items = this.cardDataSource.get(filter, order);

        return this.getResult(items, this.securityContext);
    }
}