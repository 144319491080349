import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import LocationBoard from "./LocationBoard";
import LocationWizard from "./LocationWizard";
import {
  ObjectDocumentType,
  OriginModuleEnum,
  StatusEnum,
} from "infra/api/contracts";
import LocationActions from "./LocationActions";
import LocationCard from "./LocationCard";

const getLocationMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Room;

  return {
    module,
    title: arxs.t("modules.location"),
    icon: "far fa-door-open",
    path: "/location",
    base: {
      name: "Location",
      route: "/location",
      getResource: () => arxs.Api.getResource("location"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Room.Write",
      readAction: "Room.Read",
    },
    settings: {
      name: "LocationSettings",
      route: "/location/settings",
      getImportResource: () => arxs.ApiClient.assets.locationSettings,
      readAction: "Room.Settings.Write",
      writeAction: "Room.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "name" },
          { name: "internalNumber" },
          { name: "kind" },
          { name: "type", parent: "kind" },
          { name: "description" },
          { name: "floor" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.FloorPlan,
      ObjectDocumentType.EvacuationPlan,
      ObjectDocumentType.PartitioningPlan,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      StatusEnum.Active,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.OutOfService,
    ],
    statusColorOverride: [
      { status: StatusEnum.Active, color: "status-completed" },
    ],
    actions: LocationActions.getActions(OriginModuleEnum.Room),
    wizard: {
      wizardClass: LocationWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Building],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  LocationWizard.setScopeFields(stateProxy, "context").then(
                    () => LocationWizard.applyProcessFlow(stateProxy, "context")
                  );
                },
                onLoad: (stateProxy) => {
                  LocationWizard.getContext(stateProxy);
                },
                title: arxs.t("field.contextLocation"),
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              { name: "name" },
              { name: "internalNumber" },
              {
                name: "floor",
                code: "Building.Floors",
              },
            ],
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "Room.Kind" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  LocationWizard.applyProcessFlow(stateProxy, "context"),
              },
            ],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "buildingCompartment",
                parent: "building"
              },
            ],
            [
              {
                name: "constructionHistoryRules",
                type: "itemlist",
                children: [
                  {
                    name: "buildingHistoryType",
                    code: "ConstructionHistory.Type",
                    width: "200px",
                  },
                  { name: "constructionDate", width: "150px" },
                  { name: "description", multiLine: false },
                ],
              },
            ],
            [
              {
                name: "dimensionRules",
                type: "itemlist",
                children: [
                  {
                    name: "dimensionKind",
                    code: "Dimensions.Kind",
                    width: "200px",
                  },
                  { name: "description", multiLine: false },
                  { name: "dimensions", width: "150px" },
                  { name: "unit", code: "Dimensions.Units", width: "100px" },
                ],
              },
            ],
            [
              {
                name: "finishingRules",
                type: "itemlist",
                children: [
                  {
                    name: "finishingType",
                    code: "Finishing.Type",
                    width: "200px",
                  },
                  { name: "description", multiLine: false },
                ],
              },
            ],
            [
              {
                name: "fireProtectionMaterialRules",
                type: "itemlist",
                children: [
                  {
                    name: "material",
                    code: "Fireprotection.Materials",
                    width: "200px",
                  },
                  {
                    name: "fireProtectionClass",
                    code: "Fireprotection.Class",
                    width: "200px",
                  },
                  { name: "description", multiLine: false },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        buildingMap: {},
        locations: [],
        buildingCompartmentMap: {}
      },
      fieldsWithIdsForDuplication: [
        "constructionHistoryRules",
        "dimensionRules",
        "finishingRules",
        "fireProtectionMaterialRules",
      ],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        codeElements: [],
        buildings: {},
        locations: {},
        tagMap: {},
        buildingCompartments:[],
        hazardousSubstances:[],
        periodicalMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "name" },
        { name: "internalNumber" },
        { name: "description" },
        { name: "kind" },
        { name: "type" },
        { name: "floor" },
        { name: "buildingCompartment"},
        { name: "dimensionHeight" },
        { name: "dimensionWidth" },
        { name: "dimensionLength" },
        { name: "dimensionSurface" },
        { name: "dimensionVolume" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: LocationBoard,
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: LocationCard
  };
};
export default getLocationMetadata;
