import { ResourceLanguage } from 'i18next';
import { ProductType } from 'infra/Types';

function squash(src: any, dst: any) {
    for (const key of Object.keys(src)) {
        const srcValue = src[key];
        const dstValue = dst[key];
        if (dstValue && typeof (dstValue) === "object") {
            dst[key] = squash(srcValue, dstValue);
        } else {
            dst[key] = srcValue;
        }
    }
    return dst;
}

export default function load(productType: ProductType, base: any, helios: any, hyperion: any): ResourceLanguage {
    const resources = productType === ProductType.Helios ? [base, helios] : [base, hyperion];
    return resources.reduce(squash, {});
}