import React, { useState, useEffect } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';

import './PlanMultiYearPlanPopup.scss';

export const createPlanMultiYearPlanPopup = (value, onSubmit, onCancel, isReplan) => {
  const state = {
    title: isReplan ? arxs.t("actions.multi_year_plan.replan_popup.title") : arxs.t("actions.multi_year_plan.plan_popup.title"),
    content: <PlanMultiYearPlanPopup value={value} onSubmit={onSubmit} onCancel={onCancel} isReplan={isReplan} />,
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function PlanMultiYearPlanPopup(props) {
  const [data, setData] = useState({});
  const [lookups] = useState({ multiYearPlanMap: {} });
  const [multiYearPlanMap, setMultiYearPlanMap] = useState();

  useEffect(() => {

    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          const lookup = values[key] || {};
          switch (key) {
            case "multiYearPlanMap":
              setMultiYearPlanMap(lookup);
              break;
            default: break;
          }
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };

  }, [lookups]);

  useEffect(() => {
    if (multiYearPlanMap) {
      let start, end;

      const count = props.value.ids ? props.value.ids.length : 0;
      if (count > 0) {
        if (count === 1) {
          var id = props.value.ids[0];
          const card = multiYearPlanMap[id];
          if (!card) {
            arxs.logger.error("Card not found with id {id}", id);
            return;
          }
          start = new Date(new Date(card.start).setHours(0, 0, 0)).toISOString();
          end = new Date(new Date(card.end).setHours(23, 59, 59)).toISOString();
        } else {
          const year = new Date().getFullYear() + (new Date().getMonth() < 6 ? 0 : 1);
          start = new Date(new Date(new Date().setFullYear(year, 0, 1)).setHours(0, 0, 0)).toISOString();
          end = new Date(new Date(new Date().setFullYear(year, 11, 31)).setHours(23, 59, 59)).toISOString();
        }

        setData({ start, end });
      }
    }
  }, [multiYearPlanMap]);

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => {
    if (props.onSubmit) {
      if (props.isReplan) {
        props.onSubmit({
          ids: props.value.ids,
          start: data.start,
          end: data.end,
          motivation: data.motivation
        });
      }
      else {
        props.onSubmit({
          ids: props.value.ids,
          start: data.start,
          end: data.end,
        });
      }
    }
  };

  const schema = props.isReplan ? "RePlanMultiYearPlan" : "PlanMultiYearPlan";

  let rows = [
    [
      {
        name: "start",
      },
      {
        name: "end",
      },
    ],
  ];

  if (props.isReplan) {
    rows = rows.concat([[{ name: "motivation", multiLine: true }]]);
  }

  return <div className={`plan-multi-year-plan-popup ${props.className || ""}`}>
    <div className="plan-multi-year-plan-popup-body">
      <FieldSet schemaName={schema} rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button className="icon alternative" onClick={close}>{arxs.t("common.no")}</Button>
        <Button className="icon" onClick={confirm}>{arxs.t("common.yes")}</Button>
      </div>
    </div>
  </div>;
}