import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import { themes } from "infra/GlobalState/ThemeState";
import MenuTile from "./MenuTile";
import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import Search from "../Search/Search";
import { OriginModuleEnum } from "infra/api/contracts";

import "./Menu.scss";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        {
          className: "far fa-tachometer-alt",
          title: arxs.t("menu.dashboard"),
          route: "/",
        },
        {
          className: "far fa-calendar",
          title: arxs.t("menu.planning"),
          requiredAction: "Planning.Read",
          //module: OriginModuleEnum.Planning,
          route: "/planning",
          disableOnPhone: true
        },
        {},
        {
          className: "far fa-tools",
          title: arxs.t("menu.assetmanagement.title"),
          items: [
            {
              className: "far fa-wrench",
              name: arxs.t("menu.assetmanagement.labourmeans"),
              requiredAction: "LabourMeans.Read",
              module: OriginModuleEnum.Labourmeans,
              route: "/labourmeans",
            },
            {
              className: "far fa-charging-station",
              name: arxs.t("menu.assetmanagement.equipment"),
              requiredAction: "Equipment.Read",
              module: OriginModuleEnum.EquipmentInstallation,
              route: "/equipment",
            },
            {
              className: "fas fa-hand-holding-box",
              name: arxs.t("menu.assetmanagement.intangible_asset"),
              requiredAction: "IntangibleAsset.Read",
              module: OriginModuleEnum.IntangibleAsset,
              route: "/intangibleasset",
            },
            {
              className: "far fa-flask",
              name: arxs.t("menu.assetmanagement.hazardous_substance"),
              requiredAction: "HazardousSubstance.Read",
              module: OriginModuleEnum.HazardousSubstance,
              route: "/hazardoussubstance",
            },
            {
              className: "far fa-headphones",
              name: arxs.t("menu.assetmanagement.protection_equipment"),
              requiredAction: "ProtectionEquipment.Read",
              module: OriginModuleEnum.Pbm,
              route: "/protectionequipment",
            },
            {
              className: "far fa-link",
              name: arxs.t("menu.assetmanagement.combined_installation"),
              requiredAction: "CombinedInstallation.Read",
              module: OriginModuleEnum.CombinedInstallation,
              route: "/combinedinstallation",
            },
            {
              className: "far fa-building",
              name: arxs.t("menu.assetmanagement.building"),
              requiredAction: "Building.Read",
              module: OriginModuleEnum.Building,
              route: "/building",
            },
            {
              className: "far fa-door-open",
              name: arxs.t("menu.assetmanagement.location"),
              requiredAction: "Room.Read",
              module: OriginModuleEnum.Room,
              route: "/location",
            },
          ],
        },
        {
          className: "far fa-calendar-alt",
          title: arxs.t("menu.actions.title"),
          items: [
            {
              className: "far fa-tools",
              name: arxs.t("menu.actions.maintenance"),
              requiredAction: "Maintenance.Read",
              module: OriginModuleEnum.PeriodicMaintenance,
              route: "/maintenance",
            },
            {
              className: "far fa-check-square",
              name: arxs.t("menu.actions.inspection"),
              requiredAction: "Control.Read",
              module: OriginModuleEnum.PeriodicControl,
              route: "/inspection",
            },
            {
              className: "fas fa-tasks-alt",
              name: arxs.t("menu.actions.project"),
              requiredAction: "Project.Read",
              module: OriginModuleEnum.Project,
              route: "/project",
            },
            {
              className: "far fa-tasks",
              name: arxs.t("menu.actions.task"),
              requiredAction: "Task.Read",
              module: OriginModuleEnum.Task,
              route: "/task",
            },
            {
              className: "far fa-bell-exclamation",
              name: arxs.t("menu.actions.notification"),
              requiredAction: "NotificationDefect.Read",
              module: OriginModuleEnum.NotificationDefect,
              route: "/taskrequest",
            },
            {
              className: "fal fa-file-signature",
              name: arxs.t("menu.actions.activityentry"),
              requiredAction: "ActivityEntry.Read",
              module: OriginModuleEnum.ActivityEntry,
              route: "/activityentry",
            },
          ],
        },
        {
          className: "fa fa-shield-alt",
          title: arxs.t("menu.safety.title"),
          items: [
            {
              className: "far fa-shield-alt",
              name: arxs.t("menu.safety.safetyinstructioncard"),
              requiredAction: "SafetyInstructionCard.Read",
              module: OriginModuleEnum.SafetyInstructionCard,
              route: "/safetyinstructioncard",
            },
            {
              className: "far fa-file-alt",
              name: arxs.t("menu.safety.instructioncard"),
              requiredAction: "InstructionCard.Read",
              module: OriginModuleEnum.InstructionCard,
              route: "/instructioncard",
            },
            {
              className: "far fa-sign-in",
              name: arxs.t("menu.safety.commissioning"),
              requiredAction: "Commissioning.Read",
              module: OriginModuleEnum.Commissioning,
              route: "/commissioning",
            },
            {
              className: "far fa-sign-out",
              name: arxs.t("menu.safety.outofcommissioning"),
              requiredAction: "OutOfCommissioning.Read",
              module: OriginModuleEnum.OutOfCommissioning,
              route: "/decommissioning",
            },
            {
              className: "far fa-comments-alt",
              name: arxs.t("menu.safety.recommendation"),
              requiredAction: "Consultancy.Read",
              module: OriginModuleEnum.Consultancy,
              route: "/recommendation",
            },
            {
              className: "far fa-retweet",
              name: arxs.t("menu.safety.riskanalysis"),
              requiredAction: "RiskAnalysis.Read",
              module: OriginModuleEnum.RiskAnalysis,
              route: "/riskanalysis",
            },
            {
              className: "far fa-exclamation-triangle",
              name: arxs.t("menu.safety.incidentmanagement"),
              requiredAction: "IncidentManagement.Read",
              module: OriginModuleEnum.IncidentManagement,
              route: "/incident",
            },
            {
              className: "far fa-globe",
              name: arxs.t("menu.safety.multiyearplan"),
              requiredAction: "GlobalPreventionPlan.Read",
              module: OriginModuleEnum.GlobalPreventionPlan,
              route: "/multiyearplan",
            },
            {
              className: "far fa-book-reader",
              name: arxs.t("menu.general.training"),
              requiredAction: "Training.Read",
              module: OriginModuleEnum.Training,
              route: "/training",
            },
          ],
        },
        {
          className: "far fa-school",
          title: arxs.t("menu.organisation.title"),
          items: [
            {
              className: "far fa-sitemap",
              name: arxs.t("menu.organisation.legalstructure"),
              requiredAction: "SchoolGroup.Read",
              module: OriginModuleEnum.SchoolGroup,
              route: "/legalstructure",
            },
            {
              className: "far fa-school",
              name: arxs.t("menu.organisation.branch"),
              requiredAction: "School.Read",
              module: OriginModuleEnum.School,
              route: "/branch",
            },
            {
              className: "far fa-users",
              name: arxs.t("menu.organisation.employee"),
              requiredAction: "Employee.Read",
              module: OriginModuleEnum.Employee,
              route: "/employee",
            },
            {
              className: "far fa-user",
              name: arxs.t("menu.organisation.student"),
              requiredAction: "Student.Read",
              module: OriginModuleEnum.Student,
              route: "/student",
            },
            {
              className: "far fa-truck",
              name: arxs.t("menu.organisation.supplier_customer"),
              requiredAction: "Supplier.Read",
              module: OriginModuleEnum.Supplier,
              route: "/supplier",
            },
            {
              className: "fa fa-address-card",
              name: arxs.t("menu.organisation.contact"),
              requiredAction: "Contact.Read",
              module: OriginModuleEnum.Contact,
              route: "/contact",
            },
            {
              className: "far fa-handshake",
              name: arxs.t("menu.organisation.trust"),
              requiredAction: "Trust.Read",
              route: "/trust",
            },
            {
              className: "fas fa-books",
              name: arxs.t("menu.general.documentmanagement"),
              requiredAction: "Document.Read",
              module: OriginModuleEnum.Document,
              route: "/documentmanagement",
            },
            {
              className: "far fa-clipboard-list",
              name: arxs.t("menu.configuration.report"),
              requiredAction: "Report.Read",
              route: "/reportdefinition",
            },
            {
              className: "fab fa-wpforms",
              name: arxs.t("menu.actions.form"),
              requiredAction: "Form.Read",
              module: OriginModuleEnum.Form,
              route: "/form",
            },
            {
              className: "far fa-repeat",
              name: arxs.t("menu.actions.periodical"),
              requiredAction: "Periodical.Read",
              module: OriginModuleEnum.Periodical,
              route: "/periodical",
            },
          ],
        },
        {},
        {
          className: "far fa-cog",
          title: arxs.t("menu.configuration.title"),
          items: [
            {
              className: "far fa-user-tag",
              name: arxs.t("menu.configuration.roles"),
              requiredAction: "InstanceManagement.Write",
              route: "/configuration/rolemanagement",
            },
            {
              className: "fas fa-tags",
              name: arxs.t("menu.configuration.tagmanagement"),
              requiredAction: "Tag.Read",
              route: "/configuration/tagmanagement",
            },
          ],
        },
        {
          className: "far fa-question-circle",
          title: arxs.t("menu.help.title"),
          items: [
            {
              className: "far fa-book",
              tileClassName: "beamerButton",
              name: arxs.t("menu.help.knowledgebase"),
            },
            {
              className: "far fa-headset",
              name: arxs.t("menu.help.support"),
              onClick: this.props.onSupportClick,
              requiredAction: "SupportTicket.Create",
            },
          ].concat(process.env.REACT_APP_RELEASE_NOTES_URL ? [{
            className: "far fa-book",
            onClick: this.onReleaseNotesClick,
            name: arxs.t("menu.help.release_notes"),
          }] : []),
        },
        {},
      ],
    };
  }

  onReleaseNotesClick = () => {
    window.open(process.env.REACT_APP_RELEASE_NOTES_URL, "_blank");
  }

  mapToTile(previousItem, item, i, isPhone, currentRoute) {
    const previousWasSeparator = !previousItem || !previousItem.className;

    const element =
      !item || !item.className ? (
        !previousWasSeparator ? (
          <HorizontalSeparator />
        ) : null
      ) : (
        <MenuTile {...item} isMobile={isPhone} currentRoute={currentRoute} />
      );

    return <li key={"menu_item_" + i}>{element}</li>;
  }

  filterItems(items, allowedProfileActions, allowedProfileModules, isPhone) {
    const filteredItems = items
      .map((item) => ({
        ...item,
        items: !item.items
          ? item.items
          : item.items.filter((child) =>
            this.isItemAllowed(
              child,
              allowedProfileActions,
              allowedProfileModules
            )
          ),
      }))
      .filter(
        (item) =>
          this.isItemAllowed(
            item,
            allowedProfileActions,
            allowedProfileModules,
            isPhone
          ) &&
          (!item.items || item.items.length > 0)
      );
    return filteredItems;
  }

  isItemAllowed(item, allowedProfileActions, allowedProfileModules, isPhone) {
    return (
      (!item.module ||
        Object.keys(allowedProfileModules).length === 0 ||
        allowedProfileModules[item.module]) &&
      (!item.requiredAction || allowedProfileActions[item.requiredAction]) &&
      (!isPhone || !item.disableOnPhone)
    );
  }

  render() {
    const toggleTheme = (context) => {
      let nextIndex = context.theme.index + 1;
      nextIndex %= themes.length;
      context.theme.setIndex(nextIndex);
    };

    return (
      <GlobalContext.Consumer>
        {(context) => {
          if (context.platform.isPhone && !context.menu.isOpen) return;

          let items = this.filterItems(
            this.state.items,
            context.profile.allowedActions,
            context.profile.allowedModules,
            context.platform.isPhone
          );

          if (context.platform.isPhone) {
            items = items.concat([
              {},
              {
                className: "far fa-user",
                name: arxs.t("header.profile.my_profile"),
                route: "/profile"
              },
              {
                className: "far fa-power-off red",
                title: arxs.t("header.profile.logoff"),
                onClick: arxs.Identity.logOff,
              },
              {},
              {
                className:
                  context.theme.index === 0
                    ? "far fa-moon-stars"
                    : "far fa-sun",
                title: arxs.t("header.profile.mode"),
                onClick: toggleTheme,
              },
            ]);
          }

          return (
            <nav role="navigation">
              {context.platform.isPhone && context.menu.isOpen && (
                <Search
                  history={this.props.history}
                  onSearchResult={this.props.onSearchResult}
                />
              )}
              <ul>
                {items.map((x, i) =>
                  this.mapToTile(
                    items[i - 1],
                    x,
                    i,
                    context.platform.isPhone,
                    this.props.location.pathname
                  )
                )}
              </ul>
            </nav>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}
export default withRouter(Menu);
