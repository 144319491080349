import arxs from "infra/arxs";
import { Feature } from "infra/Features";
import Toaster from "components/util/Toaster";
import { createMailPopup } from "components/mailing/MailPopup";
import { createFormExportPopup } from "components/controls/form/ImportExport/FormExportPopup";
import { createPublishToLibraryPopup } from "components/controls/library/PublishToLibraryPopup";
import TaskRequestActions from "modules/facilitymanagement/taskrequests/TaskRequestActions";
import TaskActions from "modules/facilitymanagement/tasks/TaskActions";
import MaintenanceActions from "modules/facilitymanagement/maintenances/MaintenanceActions";
import InspectionActions from "modules/facilitymanagement/inspections/InspectionActions";
import MultiYearPlanActions from "modules/safety/multiyearplan/MultiYearPlanActions";
import CombinedInstallationActions from "modules/assetmanagement/combinedinstallation/CombinedInstallationActions";
import ActivityEntryActions from "modules/facilitymanagement/activityentries/ActivityEntryActions";
import IncidentActions from "./safety/incidents/IncidentActions";
import RiskAnalysisActions from "./safety/riskanalysis/RiskAnalysisActions";

class GlobalActions {
  getActions = (module) => {
    let globalActions =  [
      {
        name: "archive",
        module: module,
        icon: arxs.actions.icons["archive"],
        getTitle: () => arxs.actions.titles["archive"],
        onClick: (state) => this.archive(state, module),
      },
      {
        name: "reactivate",
        module: module,
        icon: arxs.actions.icons["reactivate"],
        getTitle: () => arxs.actions.titles["reactivate"],
        onClick: (state) => this.reactivate(state, module),
      },
      {
        name: "send_mail",
        module: module,
        icon: "far fa-envelope",
        getTitle: () => arxs.t("actions.mailing.send_mail"),
        onClick: (state) => this.showSendMailPopup(state),
        singleSelectionOnly: true
      },
    ]
      .concat([
        {
          name: "export_form",
          module: module,
          icon: "far fa-file-export",
          getTitle: () => arxs.t("actions.form.export"),
          onClick: (state) => this.exportFormDefinition(state),
          singleSelectionOnly: true
        },
      ].filter(_ => arxs.Identity.profile.allowedFeatures[Feature.Form_Import_Export]))
      .concat([
        {
          name: "publish_to_library",
          module: module,
          icon: "far fa-cloud-arrow-up",
          getTitle: () => arxs.t("actions.library.publish"),
          onClick: (state) => this.publishToLibrary(state),
        },
      ].filter(_ => arxs.Identity.profile.allowedFeatures[Feature.Library_Publishing]))
      .concat(TaskRequestActions.getGlobalActions(module))
      .concat(TaskActions.getGlobalActions(module))
      .concat(MaintenanceActions.getGlobalActions(module))
      .concat(InspectionActions.getGlobalActions(module))
      .concat(MultiYearPlanActions.getGlobalActions(module))
      .concat(CombinedInstallationActions.getGlobalActions(module))
      .concat(ActivityEntryActions.getGlobalActions(module))
      .concat(IncidentActions.getGlobalActions(module))
      .concat(RiskAnalysisActions.getGlobalActions(module))
      ;

    return globalActions;
  }

  archive = (state, module) => {
    const ids = state["ids"] || [state.item.objectId];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .archive(ids)
      .then(() => Toaster.success(arxs.t("actions.archive_confirmation")));
  }

  reactivate = (state, module) => {
    const ids = state["ids"] || [state.item.objectId];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .reactivate(ids)
      .then(() => Toaster.success(arxs.t("actions.reactivate_confirmation")));
  }

  showSendMailPopup = (state) => {
    const cards = state["cards"] || [];
    const card = cards[0];
    if (cards.length === 1 && card) {
      state.context.popup.show(
        createMailPopup({
          id: card.id,
          module: card.module,
          onClose: () => {
            state.context.popup.close();
          },
          securityContext: arxs.securityContext.buildForUserContext(),
        })
      );
    }
  }

  exportFormDefinition = (state) => {
    const { context, cards } = state;
    const card = (cards || [])[0];
    if (cards.length === 1 && card) {
      context.popup.show(createFormExportPopup(card, context.popup.close));
    }
  }

  publishToLibrary = (state) => {
    const { context, cards } = state;
    if (cards.length > 0) {
      context.popup.show(createPublishToLibraryPopup(cards, context.popup.close));
    }
  }
}
export default new GlobalActions();
