import React from 'react';

export default class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, onClick } = this.props;
    const color = this.props.color || "white";
    const strokeDashoffset = this.circumference - (progress || 0) / 100 * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        onClick={onClick}
      >
        <circle
          stroke={color}
          fill="transparent"
          opacity="0.3"
          strokeDasharray={this.circumference + ' ' + this.circumference}
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeDasharray={this.circumference + ' ' + this.circumference}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
          transform={`rotate(-90 ${radius} ${radius})`}
        />
        <line x1={radius - 3} y1={radius - 3} x2={radius + 3} y2={radius + 3} style={{ "stroke": color, "strokeWidth": 2 }} />
        <line x1={radius - 3} y1={radius + 3} x2={radius + 3} y2={radius - 3} style={{ "stroke": color, "strokeWidth": 2 }} />
      </svg>
    );
  }
}