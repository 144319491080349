/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LinkType {
    Subject = 'Subject',
    Origin = 'Origin',
    SucceededBy = 'SucceededBy',
    Witness = 'Witness',
    Participant = 'Participant',
    Assignee = 'Assignee',
    Victim = 'Victim',
    CrisisCommitteeMember = 'CrisisCommitteeMember',
    Pbm = 'PBM',
    Supplier = 'Supplier',
    Caregiver = 'Caregiver',
    Reference = 'Reference',
    Recommendation = 'Recommendation',
    PartOf = 'PartOf',
    Scope = 'Scope',
    Authorization = 'Authorization',
    Contact = 'Contact',
    TransientReference = 'TransientReference'
}

export function LinkTypeFromJSON(json: any): LinkType {
    return LinkTypeFromJSONTyped(json, false);
}

export function LinkTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkType {
    return json as LinkType;
}

export function LinkTypeToJSON(value?: LinkType | null): any {
    return value as any;
}

