export default class ModuleSettings {
  loaded = false;
  items = [];
  timer = null;

  constructor(resource) {
    this.resource = resource;
  }

  findMatches(items, modulesAndKeys) {
    return items
      .filter(x => modulesAndKeys.any(y => y.module === x.module && y.key.toLowerCase() === x.key.toLowerCase()))
      .map(x => ({ ...x }));
  }

  getSettings(modulesAndKeys) {
    const findResult = (items) => this.findMatches(items, modulesAndKeys);

    let getItems;
    if (!this.loaded) {
      getItems = this.resource.get()
        .then(items => {
          this.items = items;
          this.loaded = true;
          return items;
        });
    } else {
      getItems = new Promise((resolve) => {
        resolve(this.items);
      });
    }

    return getItems.then(findResult);
  }

  getValues(modulesAndKeys) {
    return this.getSettings(modulesAndKeys).then(settings => settings.map(setting => setting ? JSON.parse(setting.value) : null));
  }

  getValue(module, key) {
    return this.getValues([{ module, key }])
      .then(x => x[0]);
  }

  setValue(module, key, setting, delayed) {
    return this.save([{ module, key, value: JSON.stringify(setting) }], delayed);
  }

  save(modifiedItems, delayed) {
    if (!this.loaded) {
      console.log("Settings not yet loaded");
      return new Promise((resolve, reject) => { });
    }

    const persist = () => {
      const storeItems = this.findMatches(this.items, modifiedItems);
      const payload = [];
      for (const item of modifiedItems) {
        const match = storeItems.filter(x => x.key === item.key && x.module === item.module)[0];
        const id = match ? match.id : null;
        payload.push({ ...item, id });
      }
      this.items = this.items
        .filter(x => !payload.any(m => m.key === x.key && m.module === x.module))
        .concat(payload);
      return this.resource.post(payload);
    };

    if (delayed) {
      if (this.timer) {
        window.clearTimeout(this.timer);
      }

      const delay = 5000;
      this.timer = window.setTimeout(() => {
        this.timer = null;
        persist();
      }, delay);

      return;
    }

    return persist();
  }
}