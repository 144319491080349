import React, { Component, Fragment } from 'react';
import { TextArea } from 'components/controls/TextArea';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';

import './TreeViewItem.scss';

export default class TreeViewItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props['id'],
            type: this.props['itemType'],
            parentId: this.props['parentId'],
            editing: false,
            value: this.props['value'] || '',
            dirtyValue: this.props['value'] || '',
            code: this.props['code'],
        };
    }

    onKeyDown = (event) => {
        // This prevents upstream onKeyDown handlers from stealing keys (e.g. space selects treeview element)
        event.stopPropagation();

        switch (event.keyCode) {
            case 9:
            case 13:
                this.submitChange(event);
                break;
            case 27:
                this.setState({ editing: false, dirtyValue: null });
                break;
            default: break;
        }
    }

    preventDefaultClick = (event) => {
        event.stopPropagation();
    }

    onDelete = (event, context) => {
        event.stopPropagation();

        const { id, parentId, type } = this.state;
        this.props.onDelete(context, id || parentId, type);

    }

    onEdit = (event) => {
        event.stopPropagation();

        this.setState({ editing: true });
    }

    onAddNew = (event) => {
        event.stopPropagation();
        this.setState({ editing: true });
    }

    submitChange = (event) => {
        const { dirtyValue, id, parentId, type } = this.state;
        this.setState({ editing: false, value: dirtyValue }, () => this.props.onEdit(dirtyValue, id || parentId, type));
    }

    onBlur = (event) => {
        this.submitChange(event);
    }

    render() {
        const { allowEdit, allowDelete, isExpanded, isLeaf, isBranch, isAction, itemIcon, placeHolder, itemType, importExport } = this.props
        const { editing, value, dirtyValue } = this.state;

        const treeviewItemAddInput = (context) => {
            const field = {
                name: 'treeview-item',
                getter: () => dirtyValue,
                setter: (value) => {
                    this.setState({ dirtyValue: value });
                }
            };
            return <div className='treeview-item-add-input'>
                <TextArea
                    autoFocus
                    className='input'
                    field={field}
                    onClick={this.preventDefaultClick}
                    onKeyDown={this.onKeyDown}
                    onBlur={this.onBlur}
                    placeholder={placeHolder}
                />

            </div>
        }

        const renderAction = (context) => {
            if (editing) {
                return treeviewItemAddInput(context);
            } else {
                return <div className='treeview-item-add' onClick={this.onAddNew}>{placeHolder} <i className='fas fa-plus'></i></div>
            }
        }

        const renderBranch = (context) => {
            const icon = itemIcon || (itemType && itemType === arxs.codeElementTypes.type && "fal fa-share-alt") || 'fad fa-folder';

            if (editing) {
                return <div className='treeview-item-text' title={value}><i className={`${icon}${isExpanded && icon === "fad-fa-folder" ? '-open' : ''}`}></i>
                    {treeviewItemAddInput(context)}
                </div>;
            } else {
                return <Fragment>
                    <div className='treeview-item-text' title={value}>
                        <i className={`${icon}${isExpanded && icon === "fad-fa-folder" ? '-open' : ''}`}></i>
                        <div className='text'>{value}</div>
                    </div>
                    {getEditActions(context)}
                </Fragment>;
            }
        }

        const renderLeaf = (context) => {
            if (editing) {
                return <div className='treeview-item-text' title={value}><i className={itemIcon}></i>
                    {treeviewItemAddInput(context)}
                </div>;
            } else {
                return <Fragment>
                    <div className='treeview-item-text' title={value}>
                        <i className={itemIcon}></i>
                        <div className='text'>{value}</div>
                    </div>
                    {getEditActions(context)}
                </Fragment>;
            }
        }

        const editAction = (context) => {
            return <div className='edit' onClick={this.onEdit}>
                <span title={arxs.t('controls.treeview.item.edit')}><i className='fa fa-pencil'></i></span>
            </div>
        }

        const deleteAction = (context) => {
            return <div className='remove' onClick={e => this.onDelete(e, context)}>
                <span title={arxs.t('controls.treeview.item.delete')}><i className='far fa-trash-alt'></i></span>
            </div>
        }

        

        const getEditActions = (context) => {
            const {code, value} = this.state;
            //TODO we also need to check for rights here, itemtype (leaf => module? branch = codeelements action = on level?)
            if (allowEdit || importExport) {
                return <div className='treeview-item-editactions'>
                    {importExport && importExport(code, value, context)}
                    {allowEdit && allowDelete && deleteAction(context)}
                    {allowEdit && editAction(context)}
                </div>
            }
        }

        return (
            <GlobalContext.Consumer>
                {(context) => {
                    return <div className='treeview-item'> {
                        isAction ? renderAction(context) :
                            isBranch ? renderBranch(context) :
                                isLeaf ? renderLeaf(context) : null}
                    </div>
                }
                }
            </GlobalContext.Consumer >
        );
    }
}          