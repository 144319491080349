import React, { Component, Fragment } from "react";
import {
  MultiSelect as KendoMultiSelect,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import CheckBox from "./CheckBox";
import { DropDownItem } from "./DropDown";
import arxs from "infra/arxs";
import { filterBy } from "@progress/kendo-data-query";

import "./MultiSelect.scss";

export interface MultiSelectProps {
  className?: string;
  items: Array<DropDownItem>;
  selected: Array<DropDownItem>;
  onChange(value: Array<DropDownItem>): void;
  placeholder?: string;
  idField?: string;
  textField?: string;
  singleSelection?: boolean;
  readOnly?: boolean;
}

export interface MultiSelectState {
  items: Array<DropDownItem>;
}

class MultiSelect extends Component<MultiSelectProps, MultiSelectState> {
  componentDidMount() {
    const items = this.props.items || [];
    this.setState({ items });
  }

  componentDidUpdate(prevProps: MultiSelectProps, prevState: MultiSelectState) {
    const items = this.props.items || [];
    const nonNullIds = items.filter((x) => x.id);
    if (items.length > nonNullIds.length) {
      throw Error(
        "MultiSelect component requires items with a non-null id property"
      );
    }

    if (
      JSON.stringify((prevState || {}).items || []) !== JSON.stringify(items)
    ) {
      this.setState({ items });
    }
  }

  handleChange = (event: any) => {
    const value = event.target.value;
    if (this.props.onChange) {
      if (this.props.singleSelection) {
        this.props.onChange(value.filter((x: any) => x.key));
      } else {
        this.props.onChange(value);
      }
    }
  };

  renderItem = (li: any, itemProps: ListItemProps) => {
    const textField = this.props.textField || "name";
    const item = itemProps.dataItem;
    const icon = item.getIcon ? item.getIcon() : item.icon;
    const title = item.getTitle ? item.getTitle() : item[textField];

    const itemChildren = item.content ? (
      item.content
    ) : (
      <Fragment>
        {icon && <i className={`${icon} dropdown-item-icon`}></i>}
        {
          <CheckBox
            className="dropdown-item-checkbox"
            checked={itemProps.selected}
            onClick={(e: any) => itemProps.onClick(itemProps.index, e)}
          />
        }
        <div className="dropdown-item-title" title={title}>
          {title}
        </div>
      </Fragment>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  filterChange = (event: any) => {
    let newItems = filterBy(this.props.items || [], event.filter);
    this.setState({ items: newItems });
  };

  render() {
    let items = (this.state && this.state.items) || [];
    const className = this.props.className;
    const placeholder = this.props.placeholder || arxs.t("controls.common.select");
    const idField = this.props.idField || "id";
    const textField = this.props.textField || "name";
    let values = this.props.selected || [];

    items = items
      .filter((x: any) =>
        values.map((y: any) => y[idField]).includes(x[idField])
      )
      .concat(
        items.filter(
          (x: any) => !values.map((y: any) => y[idField]).includes(x[idField])
        )
      );

    values = values
      .flatMap((x: any) =>
        items.filter((y: any) => y[idField] === x[idField])
      );

    return (
      <KendoMultiSelect
        className={`multiselect ${className || ""}`}
        data={items}
        itemRender={this.renderItem}
        value={values}
        onChange={this.handleChange}
        filterable={true}
        onFilterChange={this.filterChange}
        placeholder={placeholder}
        dataItemKey={idField}
        textField={textField}
        autoClose={false}
        popupSettings={{ className: "popup-auto-width" }}
        disabled={this.props.readOnly}
      />
    );
  }
}
export default MultiSelect;
