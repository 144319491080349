import { AttachmentType, ObjectDocumentType } from 'infra/api/contracts';
import arxs from 'infra/arxs';
import WizardController from 'modules/WizardController';

class DocumentManagementWizard extends WizardController {
    setDefaultVersionDate = (stateProxy, field, row) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");

            const values = data[field] || pristine[field] || [];


            if (row === undefined && values.some(x => Object.keys(x).length === 0)) {
                const newRow = { versionDate: new Date(), version: "", description: "" }
                const newValues = values.filter(x => x.versionDate !== undefined).concat(newRow);

                const newData = {
                    ...data,
                    [field]: newValues
                };

                stateProxy.setter({ data: newData }, resolve);
            } else {
                resolve();
            }
        });
    }

    hasOneOrMoreValues = (getCurrentFieldValue, fieldName) => {
        const rawValue = getCurrentFieldValue(fieldName);
        return rawValue && rawValue.length > 0;
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        if (!this.hasOneOrMoreValues(getCurrentFieldValue, "legalStructureAuthorizations")) {
            if (!this.hasOneOrMoreValues(getCurrentFieldValue, "branchAuthorizations")) {
                if (!this.hasOneOrMoreValues(getCurrentFieldValue, "userRoleAuthorizations")) {
                    if (!this.hasOneOrMoreValues(getCurrentFieldValue, "employeeAuthorizations")) {
                        preValidation["authorizations"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.authorization`) }) };
                    }
                }
            }
        }

        if (!this.hasOneOrMoreValues(getCurrentFieldValue, "legalStructures")) {
            if (!this.hasOneOrMoreValues(getCurrentFieldValue, "branches")) {
                preValidation["scope"] = { error: arxs.t("wizard.validation.one_of_required", { field1: arxs.t(`field.legalStructures`), field2: arxs.t('field.branches') }) };
            }
        }

        if (getCurrentFieldValue("status") !== "set_inprocess") {
            const attachmentInfo = getCurrentFieldValue("attachmentInfo");

            const hasMainDocument = (((((attachmentInfo || {}).attachments || [])
                .filter(x => x.type === ObjectDocumentType.MainDocument) || [])[0] || {}).value || []).filter(x => !x.isDeleted && (x.type === AttachmentType.StoredFile || x.type === AttachmentType.Weblink)).length === 1;

            if (!hasMainDocument) {
                preValidation["attachmentInfo"] = { error: arxs.t("actions.documents.error.main_document_missing") };
            }
        }

        var branchMap = stateProxy.getter("branchMap");
        var legalStructureIdsFromBranches = (getCurrentFieldValue("branches") || []).map((x) => branchMap[x.id])
        .map((y) => y.legalStructure.id);
        var legalStructureIds = (getCurrentFieldValue("legalStructures") ||[]).map(x => x.id);
        var authorizationLegalStructureIdsFromBranches = (getCurrentFieldValue("branchAuthorizations") ||[]).map((x) => branchMap[x.id])
        .map((y) => y.legalStructure.id);
        var authorizationLegalStructureIds = (getCurrentFieldValue("legalStructureAuthorizations") ||[]).map(x => x.id);

        
        console.log(authorizationLegalStructureIdsFromBranches, authorizationLegalStructureIds);


        if (this.getCommonEntries(legalStructureIds, legalStructureIdsFromBranches)) {
            preValidation["scope"] = { error: arxs.t("wizard.validation.overlap_scope", { field1: arxs.t(`field.legalStructures`), field2: arxs.t('field.branches') }) };
        }

        if (this.getCommonEntries(authorizationLegalStructureIds, authorizationLegalStructureIdsFromBranches )) {
            preValidation["authorization"] = { error: arxs.t("wizard.validation.overlap_authorizations", {field1: arxs.t('field.legalStructures'), field2: arxs.t('field.branches') }) };
        }

        return preValidation;
    }

    showSimpleExpiration = (stateProxy) => {
        const value = stateProxy.getField("schedule");

        if (value === undefined
            || value === null
            || (value.recurrenceRule && (value.recurrenceRule.indexOf("FREQ=NONE") !== -1 || value.recurrenceRule.indexOf("FREQ=undefined") !== -1))) {
            return true;
        }

        return false;
    }
}
export default new DocumentManagementWizard();
