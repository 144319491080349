import React, { useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import DraggableList from 'external/react-draggable-list';

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import Badge from "components/controls/Badge";
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import FormItemEditor from "./FormItemEditor";
import { TextArea } from "../TextArea";
import "./FormSectionEditor.scss";

const cx = (...args) => {
  const classNames = args.reduce((prev, cur) => {
    if (typeof cur === "string") return [...prev, cur];
    if (typeof cur === "object") return prev.concat(Object.keys(cur).filter(k => cur[k]));
    return prev;
  }, []);
  return classNames.join(" ");
};

const DraggableFormItem = (props) => {
  const { item, itemSelected, dragHandleProps, commonProps } = props;
  const { value, onChange, readOnly, knownControls, addItem, focusedItemId, module, targetModule, attachmentInfo } = commonProps;
  const scale = itemSelected * 0.02 + 1;
  const shadow = itemSelected * 15 + 1;
  const dragged = itemSelected !== 0;

  return (
    <div
      className={cx({ dragged })}
      style={{
        transform: `scale(${scale})`,
        boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
      }}
    >
      <FormItemEditor
        key={`item-${item.id}`}
        id={item.id}
        value={item}
        onChange={(modifiedItem) => {
          let items;
          if (modifiedItem) {
            items = value.items
              .replace(item, modifiedItem, (l, r) => l.id === item.id);
          } else {
            items = value.items
              .filter(x => x.id !== item.id);
          }
          onChange({ ...value, items });
        }}
        onAdd={addItem}
        readOnly={readOnly}
        dragHandleProps={!readOnly && dragHandleProps}
        knownControls={knownControls}
        autoFocus={item.id === focusedItemId}
        module={module}
        targetModule={targetModule}
        attachmentInfo={attachmentInfo}
      />
    </div>
  );
};

export default function FormSectionEditor(props) {
  const { value, ordinal, onChange, readOnly, knownControls, defaultControlIndex, module, targetModule } = props;

  const [focusedItemId, setFocusedItemId] = useState();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [dirtyTitleValue, setDirtyTitleValue] = useState("");
  const [attachmentInfo, setAttachmentInfo] = useState({});

  useEffect(() => {
    setAttachmentInfo(props.attachmentInfo);
  }, [props.attachmentInfo]);

  const onSectionsChange = ([...itemsInNewOrder]) => {
    onChange({
      ...props.value,
      items: itemsInNewOrder
    });
  };

  const addItem = () => {
    const id = arxs.uuid.generate();
    setFocusedItemId(id);
    onChange({
      ...value,
      items: value.items
        .concat([{
          id,
          title: "",
          required: false,
          control: defaultControlIndex,
          possible_actions: ["create_task_onCompletion", "create_myp_onCompletion"],
        }])
    });
  };

  const handleEditTitle = (e, context) => {
    e.stopPropagation();

    setIsEditingTitle(true);
    setDirtyTitleValue(value.title);
  };

  const handleDelete = (e, context) => {
    e.stopPropagation();

    const confirmation = createInputPopup(context
      , arxs.t("form_editor.section.delete_confirmation")
      , onChange
    );

    context.inputPopup.show(confirmation);
  };

  const onKeyDown = (event) => {
    event.stopPropagation();

    switch (event.keyCode) {
      case 9:
      case 13:
        onChange({ ...value, title: dirtyTitleValue });
        setIsEditingTitle(false);
        setDirtyTitleValue(null);
        break;
      case 27:
        setIsEditingTitle(false);
        setDirtyTitleValue(null);
        break;
      default: break;
    }
  }

  const onBlur = (event) => {
    onChange({ ...value, title: dirtyTitleValue });
    setIsEditingTitle(false);
    setDirtyTitleValue(null);
  }

  const renderTitle = (context) => {
    if (!isEditingTitle) {
      return value.title;
    } else {
      const field = {
        name: 'title',
        getter: () => dirtyTitleValue,
        setter: (value) => {
          setDirtyTitleValue(value);
        }
      };
      return <TextArea
        autoFocus
        className='input'
        field={field}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    }
  };

  const renderLocationControl = () => {
    const canUp = props.ordinal > 0;
    const canDown = props.ordinal < props.sectionCount;
    
    return (
      <>
        {canUp && (
          <div className="header-action"
            onClick={(event) => props.onMove("up")}
          >
            <i className="fas fa-arrow-up"></i>
          </div>
        )}
        {canDown && (
          <div className="header-action"
            onClick={(event) => props.onMove("down")}
          >
            <i className="fas fa-arrow-down"></i>
          </div>
        )}
      </>
    );
  };

  return <GlobalContext.Consumer>
    {(context) => (<Collapsible
      trigger={
        <div className="header">
          <div className="circle">{ordinal}</div>
          <div className="title">
            {renderTitle(context)}
            {!readOnly && !isEditingTitle && <div className="header-action" onClick={(e) => handleEditTitle(e, context)}>
              <i className="fas fa-pencil"></i>
            </div>}
            <Badge>{value.items.length}</Badge>
          </div>
          <div className="spacer"></div>
          {!readOnly && <div className="header-actions">
            {renderLocationControl()}
            <div className="header-action" onClick={(e) => handleDelete(e, context)}>
              <i className="far fa-trash-alt"></i>
            </div>
          </div>}
        </div>
      }
      className="section"
      openedClassName="section"
      open={true}
      triggerDisabled={isEditingTitle}
    >
      <div className="section-body">
        <DraggableList
          itemKey="id"
          template={DraggableFormItem}
          list={value.items}
          onMoveEnd={onSectionsChange}
          container={() => document.body}
          commonProps={{
            value, onChange, readOnly, knownControls, addItem, focusedItemId, module, targetModule, attachmentInfo
          }}
        />
        {!readOnly && <div className="section-body-add" onClick={addItem}>
          {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
        </div>}
      </div>
    </Collapsible>)}
  </GlobalContext.Consumer>;
}