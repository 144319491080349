import React from "react";
import arxs from 'infra/arxs';
import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";
import { Icon as IconImage } from "components/controls/images/Icon";

import "./RiskAndPreventionMeasures.scss";

export default class RiskAndPreventionMeasures extends FormItemController {
    getAdditionalLookups() {
        return {
            iconMap: {},
            iconByNameMap: {},
        };
    }

    deserialize(serializedValue) {
        return JSON.parse(serializedValue || "[]");
    }

    renderRPM = (data, i) => {
        const { iconMap, iconByNameMap } = this.state;
        const riskIcon = ((data && data.riskIcon && iconMap && iconByNameMap && (iconMap[data.riskIcon] || iconByNameMap[data.riskIcon])) || {});
        const preventionIcon = ((data && data.preventionIcon && iconMap && iconByNameMap && (iconMap[data.preventionIcon]  || iconByNameMap[data.preventionIcon])) || {});

        return <div className="rpm-row" key={`rpm-row-${i}`}>
            <IconImage
                key={`icon-${data.riskIcon || arxs.uuid.generate()}`}
                src={riskIcon.image?.url || ""}
                name={riskIcon?.name || ""}
                width={40}
                height={40}
            />
            <div className="rpm-values">
                <div className="rpm risk" title={data.risk || ""}>{data.risk || ""}</div>
                <div className="rpm prevention" title={data.prevention || ""}>{data.prevention || ""}</div>
            </div>
            <IconImage
                key={`icon-${data.preventionIcon || arxs.uuid.generate()}`}
                src={preventionIcon.image?.url || ""}
                name={preventionIcon?.name || ""}
                width={40}
                height={40}
            />
        </div>;
    }

    renderRPMs = () => {
        return <div className="rpms">
            {this.getter().map((row, i) => this.renderRPM(row, i))}
        </div>
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control rpms">
                    {this.renderHeader(this.props.title, true)}
                    <div className="form-control-field">
                        {this.renderRPMs()}
                    </div>
                </div>}
        </GlobalContext.Consumer>)
    }
}