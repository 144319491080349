import React from "react";

import arxs from "infra/arxs";
import Button from "components/controls/Button";
import NotificationTiles from "./Tiles/NotificationTiles";
import PlanningDashboard from "modules/planning/PlanningDashboard";

import "./Home.scss";

export default function Home(props) {
  const handleCreateTaskRequest = () => {
    props.history.push({ pathname: "/taskrequest/create" });
  };

  return (
    <div className="home">
      <div className="home-left">
        <PlanningDashboard />
      </div>
      <div className="home-right">
        <div className="buttons">
          <Button
            className="create"
            onClick={handleCreateTaskRequest}
            title={arxs.t("dashboard.create_taskrequest")}
          >
            <i className="far fa-bell-exclamation"></i>{" "}
            {arxs.t("dashboard.create_taskrequest")}
          </Button>
        </div>
        <NotificationTiles history={props.history} />
      </div>
    </div>
  );
}