/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OriginModuleEnum {
    SchoolGroup = 'SchoolGroup',
    School = 'School',
    Building = 'Building',
    Room = 'Room',
    EquipmentInstallation = 'EquipmentInstallation',
    Pbm = 'PBM',
    Labourmeans = 'Labourmeans',
    Task = 'Task',
    NotificationDefect = 'NotificationDefect',
    Commissioning = 'Commissioning',
    SafetyInstructionCard = 'SafetyInstructionCard',
    PeriodicControl = 'PeriodicControl',
    PeriodicMaintenance = 'PeriodicMaintenance',
    RiskAnalysis = 'RiskAnalysis',
    Consultancy = 'Consultancy',
    HazardousSubstance = 'HazardousSubstance',
    GlobalPreventionPlan = 'GlobalPreventionPlan',
    YearActionPlan = 'YearActionPlan',
    None = 'None',
    OutOfCommissioning = 'OutOfCommissioning',
    CombinedInstallation = 'CombinedInstallation',
    Document = 'Document',
    IncidentManagement = 'IncidentManagement',
    Employee = 'Employee',
    Management = 'Management',
    Student = 'Student',
    Supplier = 'Supplier',
    Search = 'Search',
    Training = 'Training',
    Event = 'Event',
    Report = 'Report',
    Tag = 'Tag',
    ActivityEntry = 'ActivityEntry',
    Planning = 'Planning',
    Project = 'Project',
    Template = 'Template',
    Trust = 'Trust',
    UserRole = 'UserRole',
    SupportTicket = 'SupportTicket',
    Contact = 'Contact',
    IntangibleAsset = 'IntangibleAsset',
    Mailing = 'Mailing',
    Form = 'Form',
    Periodical = 'Periodical',
    InstructionCard = 'InstructionCard',
    StoredFileStaging = 'StoredFileStaging',
    CodeElement = 'CodeElement',
    Icon = 'Icon'
}

export function OriginModuleEnumFromJSON(json: any): OriginModuleEnum {
    return OriginModuleEnumFromJSONTyped(json, false);
}

export function OriginModuleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OriginModuleEnum {
    return json as OriginModuleEnum;
}

export function OriginModuleEnumToJSON(value?: OriginModuleEnum | null): any {
    return value as any;
}

