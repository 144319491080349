import arxs from "infra/arxs";
import TaskLikeBoard from "modules/facilitymanagement/tasks/TaskLikeBoard";

class MaintenanceBoard extends TaskLikeBoard {
  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.maintenances) {
        lookups.pristine = lookups.maintenances
          .orderByDescending((card) => card.createdAt)
          .map((card) => ({
            ...card,
            activities: card.numberOfMessages,
            selected: () => !!stateProxy.getter("selected")[card.id],
          }));
        delete lookups.maintenances;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new MaintenanceBoard();
