import { ArXsState } from "infra/arxs";

import ModuleMetadata from "modules/ModuleMetadata";
import GeoLocationCard from "./GeoLocationCard";

const getGeoLocationMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = "GeoLocation";
  
  return {
    module,
    base: {
      name: "",
      route: "",
      getResource: (module?: string) => ({ get: () => new Promise(() => {}), set: (data: any) => new Promise(() => {}) }),
      subscribe: (id: string, module: string, handler: (x: any) => any) => {},
      readAction: "",
      writeAction: "",
      createAction: "",
    },
    allowedDocumentTypes: [],
    actions: [],
    statuses: [],
    title: "",
    icon: "far fa-map-marked-alt",
    path: "/",
    cardClass: GeoLocationCard,
    canSubscribe: false,
    canNavigateTo: false,
  };
};

export default getGeoLocationMetadata;
