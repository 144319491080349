import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import Button from "components/controls/Button";
import { Spinner } from "components/animations/Spinner";

import "./FormExportPopup.scss";

export const createFormExportPopup = (
  card,
  onCancel,
  title,
) => {
  const state = {
    title: title || arxs.t("actions.form.export"),
    content: (
      <FormExportPopup
        card={card}
        onCancel={onCancel}
      />
    ),
    onCancel: onCancel,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function FormExportPopup(props) {
  const { card, onCancel } = props;

  const [token, setToken] = useState(null);

  useEffect(() => {
    let mounted = true;
    arxs
      .moduleMetadataRegistry
      .getResourceByModule(card.module)
      .exportForm(card.id)
      .then(payload => {
        if (mounted) {
          setToken(payload);
        }
      });
      return () => mounted = false;
  }, []);

  const copyToClipboard = () => {
    if (token) {
      navigator.clipboard.writeText(token);
      onCancel();
      Toaster.success(arxs.t("actions.form.export_confirmation"));
    }
  };

  return (
    <div className={`form-export-popup ${props.className || ""}`}>
      <div className="form-export-popup-body">
        <div className="content">
          {!token && <>
            <h3>{arxs.t("actions.form.form_is_being_exported")}</h3>
            <Spinner />
          </>}
          {token && <Button
                className="icon"
                onClick={copyToClipboard}
              >
                <i className="far fa-copy"></i>
                {arxs.t("actions.form.copy_token_to_clipboard")}
          </Button>}
        </div>
        <div className="buttons">
          <Button
            className="icon alternative"
            onClick={onCancel}
          >
            {arxs.t("common.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
