import React, { Fragment } from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import CardActions from "components/card/CardActions";
import { getFutureOccurrences } from "components/controls/scheduler/Scheduler";


export default function DocumentManagementCard(props: CardClassProps) {
  const { card, cardProps, actionProps } = props;

  let revisionDate = card.revisionDate;
  if (card.schedule && card.schedule.recurrenceRule !== 'FREQ=NONE') {
    const plannedOccurrences = getFutureOccurrences(card.schedule, 1);
    revisionDate = plannedOccurrences[0] || revisionDate;
  }
  
  return (
    <Fragment>
        <div className="list-card-left">
          {card.numberOfDocuments && card.numberOfDocuments > 1 ? (
            <i className="fad fa-copy" />
          ) : (
            <i className="fad fa-file" />
          )}
        </div>
        <div className="list-card-right">
          <h2>
            <div className="list-card-title" title={card.title}>
              {card.title}
            </div>
            <CardActions {...actionProps} />
          </h2>
          <CardSubtitle cardProps={cardProps} card={card} />
          <div className="list-card-details">
            <div>{[card.kind, card.type].filter((x) => x).join(" - ")}</div>
            <div>{card.legalStructureNames}</div>
            <div>{card.branchNames}</div>
            <div>
              {arxs.t("kanban.common.changed")}:{" "}
              {arxs.dateTime.formatDate(card.modifiedAt)}
            </div>
            <div>
              {arxs.t("kanban.common.expires")}:{" "}
              {revisionDate && arxs.dateTime.formatDate(revisionDate)}
            </div>
          </div>
        </div>
    </Fragment>
  );
}
