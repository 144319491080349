import React, { Component } from "react";
import DraggableList from 'external/react-draggable-list';

import arxs from 'infra/arxs';
import CheckBox from 'components/controls/CheckBox';
import DropDown from "components/controls/DropDown";
import { TextArea } from "components/controls/TextArea";

const cx = (...args) => {
    const classNames = args.reduce((prev, cur) => {
      if (typeof cur === "string") return [...prev, cur];
      if (typeof cur === "object") return prev.concat(Object.keys(cur).filter(k => cur[k]));
      return prev;
    }, []);
    return classNames.join(" ");
  };

const buildDraggableFormListItem = (ItemComponent) => {
    return (props) => {
        const { item, itemSelected, dragHandleProps, commonProps } = props;
        const scale = itemSelected * 0.02 + 1;
        const shadow = itemSelected * 15 + 1;
        const dragged = itemSelected !== 0;
    
        return (
          <div
            className={cx({ dragged })}
            style={{
              transform: `scale(${scale})`,
              boxShadow: `rgba(0, 0, 0, 0.3) 0px ${shadow}px ${2 * shadow}px 0px`,
            }}
          >
            <ItemComponent {...props} commonProps={commonProps} />
        </div>);
    };
};

export default class FormItemEditorController extends Component {
    componentDidMount() {
        const draggableItemComponent = this.getDraggableItemComponent();
        this.setState({ DraggableFormListItem: draggableItemComponent ? buildDraggableFormListItem(draggableItemComponent) : null });
    }

    renderBegin = () => {
        const { knownControls, selectedControl, onControlChange, readOnly } = this.props;
        return <DropDown
            className="form-item-control-type"
            items={knownControls}
            selected={selectedControl}
            onChange={onControlChange}
            readOnly={readOnly}
            disableClearButton={true}
        />
    }

    renderEnd = (isExecutable) => {
        const { required, onRequiredChange, readOnly, titleRequired } = this.props;
        return <div className="form-item-editor-required">
            {titleRequired && <span className="red">*</span>}
            {isExecutable && <CheckBox
                checked={required}
                onChange={onRequiredChange}
                disabled={readOnly}
            />}
        </div>;
    }

    getDraggableItemComponent() { return null; }

    renderDraggableList = (items, props) => {
        // The reason this is moved into a local variable is because we don't want to recreate the DraggableFormListItem every render
        // or we lose focus to inputs inside
        if (!this.DraggableFormListItem) {
            this.DraggableFormListItem = this.getDraggableItemComponent();
            if (!this.DraggableFormListItem) {
                throw "Expecting getDraggableItemComponent to be defined";
            }
        }

        const { field } = this.props;
        const value = field.getter();
        const onMoveItem = ([...itemsInNewOrder]) => {
            field.setter({ ...value, data: itemsInNewOrder });
        };

        return <DraggableList
            itemKey="id"
            template={this.DraggableFormListItem}
            list={items}
            onMoveEnd={onMoveItem}
            container={() => document.body}
            commonProps={{
                ...props
            }}
        />;
    }

    onKeyDown = (e) => {
        const { onAdd, handleDelete } = this.props;
        if (e.keyCode === 13) {
            e.preventDefault();
            if (onAdd) {
                onAdd();
            }
        } else if (e.keyCode === 27) {
            e.preventDefault();
            handleDelete();
        }
    };

    titleField = {
        id: this.props.field.id,
        getter: () => this.props.field.getter().title,
        setter: (state) => {
            const { field } = this.props;
            const value = field.getter();
            field.setter({ ...value, title: state });
        }
    }

    renderTitle = (context) => {
        const { autoFocus, readOnly } = this.props;

        return <TextArea
            className={""}
            field={this.titleField}
            onKeyDown={this.onKeyDown}
            autoFocus={autoFocus}
            readOnly={readOnly}
            placeholder={arxs.t("forms.items.title")}
        />;
    }
}