import { ArXsState } from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts/OriginModuleEnum";
import { SubjectRef } from "./api/contracts";

export interface SecurityContext {
  isUnambiguous: boolean;
  filter(item: Ref): boolean;
  getAuthoritativeTenant(): string | null;
}

interface Ref {
  id: string;
  module?: string;
  tenantId: string;
}

interface BranchRef extends Ref {
  legalStructure: Ref;
}

export default class SecurityContextFactory {
  arxs: ArXsState;

  constructor(arxs: ArXsState) {
    this.arxs = arxs;
  }

  buildForUserContext = (): SecurityContext => {
    const { profile } = this.arxs.Identity;
    const tenant = profile?.tenant;
    const isLocalAdmin = profile?.isLocalAdmin;
    const assignments = profile?.assignments || [];
    const legalStructures = assignments.map((x: any) => x.legalStructure);
    const legalStructureMap = legalStructures.toDictionary((x: Ref) => x.id);

    const isUnambiguous = true;
    const filter = (x: Ref) => {
      if (!x.module && !x.tenantId) {
        return true;
      }
      if (isLocalAdmin) {
        return x.tenantId === tenant;
      }
      if (x.module === "SchoolGroup") {
        return legalStructureMap[x.id];
      }
      return x.tenantId === tenant;
    };
    const getAuthoritativeTenant = () => tenant;
    return {
      isUnambiguous,
      filter,
      getAuthoritativeTenant,
    };
  };

  buildForObjectOrUserContext = (
    writeAction?: string,
    legalStructure?: Ref,
    branch?: Ref,
    building?: Ref,
    location?: Ref
  ): SecurityContext => {
    if (!legalStructure && !branch && !building && !location) {
      return this.buildForUserContext();
    }

    return this.buildForMultipleContext(
      writeAction,
      legalStructure ? [legalStructure] : [],
      branch ? [branch] : [],
      building ? [building] : [],
      location ? [location] : []
    );
  };

  buildForContext = (
    writeAction?: string,
    legalStructure?: Ref,
    branch?: Ref,
    building?: Ref,
    location?: Ref,
    subject?: Ref,
  ): SecurityContext => {
    return this.buildForMultipleContext(
      writeAction,
      legalStructure ? [legalStructure] : [],
      branch ? [branch] : [],
      building ? [building] : [],
      location ? [location] : [],
      subject ? [subject] : [],
    );
  };

  buildForSubjects = (
    writeAction: string,
    subjects: Array<SubjectRef>
  ): SecurityContext => {
    return this.buildForMultipleContext(
      writeAction,
      subjects
        .map(x => x.legalStructure)
        .concat(subjects.filter(x => x.module === OriginModuleEnum.SchoolGroup))
        .filter(x => x)
        .map(x => x as Ref),
      subjects
        .map(x => x.branch)
        .concat(subjects.filter(x => x.module === OriginModuleEnum.School))
        .filter(x => x)
        .map(x => x as Ref),
      subjects
        .filter(x => x.module === OriginModuleEnum.Building)
        .map(x => x as Ref),
      subjects
        .filter(x => x.module === OriginModuleEnum.Room)
        .map(x => x as Ref),
      subjects.map(x => x as Ref),
    );
  }

  buildForMultipleContext = (
    writeAction?: string,
    legalStructures?: Array<Ref>,
    branches?: Array<Ref>,
    buildings?: Array<Ref>,
    locations?: Array<Ref>,
    subjects?: Array<Ref>,
  ): SecurityContext => {
    const { profile } = this.arxs.Identity;
    const { lookups } = this.arxs.Api;
    const isLocalAdmin = profile?.isLocalAdmin;

    const subjectLegalStructures = (subjects || [])
      .map(lookups.resolveSubject)
      .filter((x: any) => x && x.legalStructure)
      .map((x: any) => x.legalStructure)
      .concat((subjects || [])
        .filter((x: any) => x.module === OriginModuleEnum.SchoolGroup));

    const legalStructureItems = (legalStructures || [])
      .concat(subjectLegalStructures)
      .filter(x => x)
      ;

    const subjectBranches = (subjects || [])
      .map(lookups.resolveSubject)
      .filter((x: any) => x && x.branch)
      .map((x: any) => x.branch)
      .concat((subjects || [])
      .filter((x: any) => x.module === OriginModuleEnum.School));

    const branchItems = (branches || [])
      .concat(subjectBranches)
      .map(
        (branch) =>
          lookups.resolveSubject({
            id: branch.id,
            module: "School",
          }) as BranchRef
      )
      .filter((x) => x && Object.keys(x).length > 0);

    const assignments = (profile?.assignments || []).concat(profile?.trusts || []);
    const trusts = profile?.trusts || [];

    const isImplicitelyUnambiguous = trusts.length === 0;

    let authoritativeTenant: string | null = null;
    if (isImplicitelyUnambiguous) {
      authoritativeTenant = profile?.tenant;
    } else {
      if (branchItems.length > 0) {
        if (
          assignments.any(
            (a: any) =>
              branchItems.some(
                (branchItem) =>
                  branchItem.legalStructure.id === a.legalStructure.id
              ) ||
              a.branches.any((b: BranchRef) =>
                branchItems.some((branch) => b.id === branch.id)
              )
          )
        ) {
          authoritativeTenant = profile?.tenant;
        } else {
          const matchingTrust = trusts.filter(
            (a: any) =>
              branchItems.some(
                (branchItem) =>
                  branchItem.legalStructure.id === a.legalStructure.id
              ) ||
              a.branches.any((r: Ref) =>
                branchItems.some((branch) => r.id === branch.id)
              )
          )[0];
          if (matchingTrust) {
            authoritativeTenant = matchingTrust.tenantId;
          }
        }
      } else if (legalStructureItems && legalStructureItems.some((x) => x)) {
        if (
          assignments.any((a: any) =>
          legalStructureItems.some((x) => x.id === a.legalStructure.id)
          )
        ) {
          authoritativeTenant = profile?.tenant;
        } else {
          const matchingTrust = trusts.filter((a: any) =>
          legalStructureItems.some((x) => x.id === a.legalStructure.id)
          )[0];
          if (matchingTrust) {
            authoritativeTenant = matchingTrust.tenantId;
          }
        }
      }
    }

    const authoritativeTenantIsKnown = !!authoritativeTenant;
    const isUnambiguous =
      isImplicitelyUnambiguous || authoritativeTenantIsKnown;

    const legalStructuresFromAssignments = assignments
      .map((x: any) => x.legalStructure)
      .concat(
        trusts
          .filter((x: any) => x.allowedActions.contains(writeAction))
          .map((x: any) => x.legalStructure)
      );
    const legalStructureMap = legalStructuresFromAssignments.toDictionary(
      (x: Ref) => x.id
    );

    const branchesFromAssignments = assignments
      .flatMap((x: any) => x.branches)
      .concat(
        trusts
          .filter((x: any) => x.allowedActions.contains(writeAction))
          .flatMap((x: any) => x.branches)
      );
    const branchMap = branchesFromAssignments.toDictionary((x: Ref) => x.id);

    return {
      isUnambiguous,
      filter: (x: Ref) => {
        if (!x.module && !x.tenantId) {
          return true;
        }

        const isAllowedFallback = isLocalAdmin || x.tenantId === authoritativeTenant;

        if (x.module === OriginModuleEnum.SchoolGroup) {
          if (legalStructures && legalStructures.some((x) => x)) {
            return (
              legalStructures.some((ls) => ls.id === x.id) &&
              legalStructureMap[x.id]
            );
          }
          return legalStructureMap[x.id] || isLocalAdmin;
        }

        if (x.module === OriginModuleEnum.School) {
          const branchItem = x as any;
          const legalStructureId = branchItem.legalStructure.id || branchItem.legalStructureId;
          if (legalStructureItems.length > 0) {
            return (
              legalStructureItems.some((ls) => ls.id === legalStructureId) && legalStructureMap[legalStructureId]
            );
          }
          return (branchMap[branchItem.id]
            || legalStructureMap[branchItem.legalStructure.id]
            || isLocalAdmin
          );
        }

        if (!isUnambiguous) {
          return false;
        }

        if (x.module === OriginModuleEnum.Supplier) {
          return isAllowedFallback;
        }

        if (x.module === OriginModuleEnum.Employee) {
          const employee = x as any;

          const allAssignments = (employee.assignments || []).concat(
            employee.trustAssignments || []
          );
          
          if (branchItems.length > 0) {
            if (
              allAssignments.some((a: any) =>
                a.branch
                  ? branchItems.some((b) => b.id === a.branch.id)
                  : branchItems.some(
                      (b) => b.legalStructure.id === a.legalStructure.id
                    )
              )
            ) {
              return true;
            } else {
              if (legalStructureItems.length > 0) {
                if (
                  allAssignments
                    .filter((a: any) => a.legalStructure)
                    .filter((b: any) => !b.branch)
                    .some((a: any) =>
                    legalStructureItems.some(
                        (ls) => ls.id === a.legalStructure.id
                      )
                    )
                ) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          }

          if (legalStructureItems.length > 0) {
            if (
              allAssignments
                .filter((a: any) => a.legalStructure)
                .some((a: any) =>
                legalStructureItems.some((ls) => ls.id === a.legalStructure.id)
                )
            ) {
              return isAllowedFallback;
            } else {
              return false;
            }
          }
          return false;
        }

        if (
          x.module === OriginModuleEnum.Document ||
          x.module === OriginModuleEnum.Form
        ) {
          const item = x as any;

          if (branchItems.length > 0) {
            if (item.branches.some((a: any) => branchItems.some((b) => b.id === a.id))
              || item.legalStructures.some((a: any) => branchItems.some((b) => b.legalStructure.id === a.id))) {
              return isAllowedFallback;
            } else {
              return false;
            }
          }

          if (legalStructureItems.length > 0) {
            if (item.legalStructures.some((a: any) => legalStructureItems.some((ls) => ls.id === a.id))) {
              return isAllowedFallback;
            } else {
              return false;
            }
          }
        }

        if (x.module === OriginModuleEnum.CombinedInstallation) {
          const ci = x as any;
          if (branchItems.length > 0) {
            return branchItems.some((b) => b.id === ci.branchId);
          }
          if (legalStructureItems.length > 0) {
            return legalStructureItems.some((ls) => ls.id === ci.legalStructureId);
          }
        }

        return isAllowedFallback;
      },
      getAuthoritativeTenant: () => authoritativeTenant,
    };
  };
}
