import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';
import { OriginModuleEnum } from 'infra/api/contracts';

class ProjectWizard extends WizardController {
  hasAuthorization = (getCurrentFieldValue, fieldName) => {
    const authorizations = getCurrentFieldValue(fieldName);
    return authorizations && authorizations.length > 0;
  }
  validate(stateProxy, getCurrentFieldValue, preValidation) {
    if (!this.hasAuthorization(getCurrentFieldValue, "legalStructureAuthorizations")) {
      if (!this.hasAuthorization(getCurrentFieldValue, "branchAuthorizations")) {
        if (!this.hasAuthorization(getCurrentFieldValue, "userRoleAuthorizations")) {
          if (!this.hasAuthorization(getCurrentFieldValue, "employeeAuthorizations")) {
            preValidation["authorizations"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.authorization`) }) };
          }
        }
      }
    }

    if ((getCurrentFieldValue("subjects") || []).length === 0) {
      preValidation["subjects"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.subjects`) }) };
    }

    var branchMap = stateProxy.getter("branchMap");
    var legalStructureIdsFromBranches = (getCurrentFieldValue("branches") || []).map((x) => branchMap[x.id])
    .map((y) => y.legalStructure.id);
    var legalStructureIds = (getCurrentFieldValue("legalStructures") ||[]).map(x => x.id);
    var authorizationLegalStructureIdsFromBranches = (getCurrentFieldValue("branchAuthorizations") ||[]).map((x) => branchMap[x.id])
    .map((y) => y.legalStructure.id);
    var authorizationLegalStructureIds = (getCurrentFieldValue("legalStructureAuthorizations") ||[]).map(x => x.id);

    if (this.getCommonEntries(legalStructureIds, legalStructureIdsFromBranches)) {
        preValidation["scope"] = { error: arxs.t("wizard.validation.overlap_scope", { field1: arxs.t(`field.legalStructures`), field2: arxs.t('field.branches') }) };
    }

    if (this.getCommonEntries(authorizationLegalStructureIds, authorizationLegalStructureIdsFromBranches )) {
        preValidation["authorization"] = { error: arxs.t("wizard.validation.overlap_authorizations", {field1: arxs.t('field.legalStructures'), field2: arxs.t('field.branches') }) };
    }

    return preValidation;
  }

  syncScopeTree = (stateProxy, fieldName) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      const getValue = this.buildGetValue(stateProxy);

      const locationMap = stateProxy.getter("locationMap");
      const buildingMap = stateProxy.getter("buildingMap");
      const branchMap = stateProxy.getter("branchMap");

      let refs = {
        [OriginModuleEnum.Room]: getValue("locations") || [],
        [OriginModuleEnum.Building]: getValue("buildings") || [],
        [OriginModuleEnum.School]: getValue("branches") || [],
        [OriginModuleEnum.SchoolGroup]: getValue("legalStructures") || []
      }

      let subjects = getValue("subjects") || [];

      const getModuleName = (module) => {
        switch (module) {
          case OriginModuleEnum.Room: return "location";
          case OriginModuleEnum.Building: return "building";
          case OriginModuleEnum.School: return "branch";
          case OriginModuleEnum.SchoolGroup: return "legalStructure";
          default: return;
        }
      }

      const getChildModule = (module) => {
        switch (module) {
          case OriginModuleEnum.Building: return OriginModuleEnum.Room;
          case OriginModuleEnum.School: return OriginModuleEnum.Building;
          case OriginModuleEnum.SchoolGroup: return OriginModuleEnum.School;
          default: return undefined;
        }
      }

      const getParentModule = (module) => {
        switch (module) {
          case OriginModuleEnum.Room: return OriginModuleEnum.Building;
          case OriginModuleEnum.Building: return OriginModuleEnum.School;
          case OriginModuleEnum.School: return OriginModuleEnum.SchoolGroup;
          default: return;
        }
      }

      const getModuleMapLookup = (module) => {
        switch (module) {
          case OriginModuleEnum.Room: return locationMap;
          case OriginModuleEnum.Building: return buildingMap;
          case OriginModuleEnum.School: return branchMap;
          default: return;
        }
      }

      const getFieldNameByModule = (module) => {
        switch (module) {
          case OriginModuleEnum.Room: return "locations";
          case OriginModuleEnum.Building: return "buildings";
          case OriginModuleEnum.School: return "branches";
          case OriginModuleEnum.SchoolGroup: return "legalStructures";
          default: return;
        }
      }

      const getModuleByFieldName = (fieldName) => {
        switch (fieldName) {
          case "legalStructures": return OriginModuleEnum.SchoolGroup;
          case "branches": return OriginModuleEnum.School;
          case "buildings": return OriginModuleEnum.Building;
          case "locations": return OriginModuleEnum.Room;
          default: return undefined;
        }
      }

      const removeOrphans = (module) => {
        if ([OriginModuleEnum.SchoolGroup, OriginModuleEnum.School, OriginModuleEnum.Building].includes(module)) {
          // we are in the scope fields, except location as there we need to clean subject!
          const childModule = getChildModule(module);
          if (childModule) {
            const currentChildren = refs[childModule];
            const currentValues = refs[module];//getValue(getFieldNameByModule(module)) || [];
            let newChildren = { [childModule]: [] };

            for (const child of currentChildren) {
              const extendedChild = arxs.Api.lookups.resolveSubject({ ...child, module: childModule });
              if (extendedChild) {
                if (currentValues.map(ref => (ref || {}).id).includes(extendedChild[getModuleName(module)].id)) {
                  newChildren[childModule] = newChildren[childModule].concat(child);
                }
              }
            }

            refs = { ...refs, ...newChildren };

            removeOrphans(childModule);
          }
        }
      }

      const sanitizeSubjects = () => {
        if (subjects.length > 0) {
          let newSubjects = [];
          for (const subRef of subjects) {
            const sub = arxs.Api.lookups.resolveSubject(subRef);

            if (sub.location) {
              if (refs[OriginModuleEnum.Room].some(x => x.id === sub.location.id)) {
                newSubjects = newSubjects.concat(subRef);
              }
            } else
              if (sub.building) {
                if (refs[OriginModuleEnum.Building].some(x => x.id === sub.building.id)) {
                  newSubjects = newSubjects.concat(subRef);
                }
              } else
                if (sub.branch) {
                  if (refs[OriginModuleEnum.School].some(x => x.id === sub.branch.id)) {
                    newSubjects = newSubjects.concat(subRef);
                  }
                } else
                  if (sub.legalStructure) {
                    if (refs[OriginModuleEnum.SchoolGroup].some(x => x.id === sub.legalStructure.id)) {
                      newSubjects = newSubjects.concat(subRef);
                    }
                  }
          }
          subjects = newSubjects;
        }
      }

      const addNewParents = (module) => {
        if (module) {
          if ([OriginModuleEnum.Room, OriginModuleEnum.Building, OriginModuleEnum.School].includes(module)) {
            const parentModule = getParentModule(module);
            if (parentModule) {
              let newParentRefs = refs[parentModule];

              for (const ref of (getValue(getFieldNameByModule(module)) || []).concat(refs[module] || [])) {

                const extendedRef = getModuleMapLookup(ref.module)[ref.id];
                if (extendedRef) {
                  if (!newParentRefs.some(x => x.id === extendedRef[getModuleName(parentModule)].id)) {
                    newParentRefs = newParentRefs.concat({ id: extendedRef[getModuleName(parentModule)].id, module: parentModule })
                  }
                }
              }

              refs[parentModule] = newParentRefs;

              addNewParents(parentModule);
            }
          }

        } else {
          for (const subRef of subjects) {
            const sub = arxs.Api.lookups.resolveSubject(subRef);
            if (sub.location && !refs[OriginModuleEnum.Room].some(x => x.id === sub.location.id)) {
              refs[OriginModuleEnum.Room] = refs[OriginModuleEnum.Room].concat({ ...sub.location, module: OriginModuleEnum.Room });
              addNewParents(OriginModuleEnum.Room);
            } else if (sub.building && !refs[OriginModuleEnum.Building].some(x => x.id === sub.building.id)) {
              refs[OriginModuleEnum.Building] = refs[OriginModuleEnum.Building].concat({ ...sub.building, module: OriginModuleEnum.Building });
              addNewParents(OriginModuleEnum.Building);
            } else if (sub.branch && !refs[OriginModuleEnum.School].some(x => x.id === sub.branch.id)) {
              refs[OriginModuleEnum.School] = refs[OriginModuleEnum.School].concat({ ...sub.branch, module: OriginModuleEnum.School });
              addNewParents(OriginModuleEnum.School);
            } else if (sub.legalStructure && !refs[OriginModuleEnum.SchoolGroup].some(x => x.id === sub.legalStructure.id)) {
              refs[OriginModuleEnum.SchoolGroup] = refs[OriginModuleEnum.SchoolGroup].concat({ ...sub.legalStructure, module: OriginModuleEnum.SchoolGroup });
              addNewParents(OriginModuleEnum.SchoolGroup);
            }
          }
        }
      }

      const moduleForField = getModuleByFieldName(fieldName)

      removeOrphans(moduleForField);
      addNewParents(moduleForField);
      sanitizeSubjects();

      stateProxy.setter({
        data: {
          ...data
          , locations: refs[OriginModuleEnum.Room].distinct(x => x.id)
          , buildings: refs[OriginModuleEnum.Building].distinct(x => x.id)
          , branches: refs[OriginModuleEnum.School].distinct(x => x.id)
          , legalStructures: refs[OriginModuleEnum.SchoolGroup].distinct(x => x.id)
          , subjects: subjects
        }
      }, resolve);
    })
  }
}
export default new ProjectWizard();
