import React, { useState, useEffect } from "react";
import { OriginModuleEnum, PlanningMoment } from "infra/api/contracts";
import arxs from "infra/arxs";

import './PlanningProgress.scss';

export interface PlanningProgressProps {
  subject: {
    id: string,
    module: OriginModuleEnum,
    estimatedDuration?: number,
  }
}

const getPlanningMomentDuration = (planningMoment: PlanningMoment) => {
  if (planningMoment.start && planningMoment.end) {
    const endDateTime = new Date(planningMoment.end);
    const startDateTime = new Date(planningMoment.start);
    const duration = (endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60 * 60);
    return duration * (planningMoment.assignees || []).length;
  }
  return 0;
}

export default function PlanningProgress(props: PlanningProgressProps) {
  const lookups = {
    planningMomentsBySubjectMap: {},
  };

  const [planningMomentsBySubjectMap, setPlanningMomentsBySubjectMap] = useState<{ [key: string]: Array<PlanningMoment> }>({});

  useEffect(() => {

    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values: any) => {
        for (var key of Object.keys(values)) {
          switch (key) {
            case "planningMomentsBySubjectMap": setPlanningMomentsBySubjectMap(values[key]); break;
          }
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  const planningMoments = (planningMomentsBySubjectMap && planningMomentsBySubjectMap[`${props.subject.module}:${props.subject.id}`]) || [];
  const value = planningMoments.filter(x => x.start && x.end && !x.isDeleted).map(getPlanningMomentDuration).sum();

  const total = props.subject.estimatedDuration;

  if (total && total > 0) {
    const current = (value || 0);
    const ratio = current / total;
    const progress = Math.min(100, Math.ceil(100 * ratio)) + "%";
    const progressColor = ratio < 0.05 ? "var(--arxs-primary-color)" : "white";

    return <div className="planning-progress-container">
      <div className="planning-progress" style={{ width: progress }}></div>
      <span style={{ color: progressColor }}>{Math.round((current + Number.EPSILON) * 10) / 10} / {total} uren</span>
    </div>;
  }

  return <></>;
}