const createDetailsPaneState = (getState, setState, props) => {
  const getCurrentCard = (state) => {
    const { cardStack, stackIndex } = state;
    if (stackIndex < 0 || cardStack.length <= stackIndex) {
      return null;
    }
    return cardStack[stackIndex];
  };

  const cardStackMax = 20;

  const setStateAndRecalculate = (overrideState) => {
    const state = {
      ...getState(),
      ...overrideState
    };
    const length = state.cardStack.length;
    const canGoBack = state.stackIndex > 0;
    const canGoForward = state.stackIndex < length - 1;
    setState({ ...state, canGoBack, canGoForward });
  };

  const open = (card, collapse) => {
    if (!card || !card.id) {
      return;
    }

    const { cardStack, stackIndex } = getState();

    if (cardStack.length > 0) {
      const lastCard = cardStack[cardStack.length - 1];
      if (card.id === lastCard.id && card.module === lastCard.module) {
        setStateAndRecalculate({
          cardStack: cardStack,
          card: lastCard,
          collapsed: !!collapse,
        });
        return;
      }
    }

    const clonedCard = { ...card };
    if (cardStack.length > cardStackMax) {
      setStateAndRecalculate({
        cardStack: cardStack.slice(1).concat([clonedCard]),
        card: clonedCard,
        collapsed: !!collapse,
      });
    } else {
      const newStackIndex = stackIndex + 1;
      setStateAndRecalculate({
        cardStack: cardStack.slice(0, newStackIndex).concat([clonedCard]),
        stackIndex: newStackIndex,
        card: clonedCard,
        collapsed: !!collapse,
      });
    }
  };

  const back = () => {
    const state = getState();
    const stackIndex = Math.max(0, state.stackIndex - 1);
    const card = getCurrentCard({ ...state, stackIndex });
    setStateAndRecalculate({ stackIndex, card });
  };

  const forward = () => {
    const state = getState();
    const stackIndex = Math.min(state.cardStack.length - 1, state.stackIndex + 1);
    const card = getCurrentCard({ ...state, stackIndex });
    setStateAndRecalculate({ stackIndex, card });
  };
  
  const clear = () => {
    setStateAndRecalculate({ stackIndex: -1, cardStack: [], card: null });
  };

  const toggleCollapse = () => {
    const { collapsed } = getState();
    setStateAndRecalculate({ collapsed: !collapsed });
  };

  const collapse = () => {
    setStateAndRecalculate({ collapsed: true });
  };
  
  const state = {
    state: {
      cardStack: [],
      stackIndex: -1,
      collapsed: false,
    },
    ...props,
    open,
    forward,
    back,
    clear,
    collapse,
    toggleCollapse,
  };

  return state;
};
export default createDetailsPaneState;