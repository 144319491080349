import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import CodeElementManagement from 'components/codeelements/CodeElementManagement';
import MiniGridSettings from './MiniGridSettings';
import Import from './Import';

import './Settings.scss';


export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.getTabs = this.getTabs.bind(this);

    const module = this.props.module;
    const metadata = arxs.moduleMetadataRegistry.get(module);

    this.state = {
      metadata
    };
  }

  scrollTo = (ref) => {
    ref.current.innerRef.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
  }

  getTabs() {
    const { metadata } = this.state;
    const stepsTitle = metadata.settings.stepsTitle || arxs.t("settings.process_flow");
    const steps = metadata.settings.steps || [];
    const importEnabled = !!metadata.settings.getImportResource || false;
    return [{
      title: arxs.t("settings.code_elements"),
      render: () => <CodeElementManagement module={this.props.module} />
    }].concat([{
      title: stepsTitle,
      render: () => <MiniGridSettings module={this.props.module} steps={steps} />
    }]
      .filter(_ => steps.length > 0))
      .concat(importEnabled ? [{
        title: arxs.t("settings.import.title"),
        render: () => <Import module={this.props.module} />
      }] : []);
  }

  render() {
    const { metadata } = this.state;
    const tabs = this.getTabs();
    return <GlobalContext.Consumer>
      {(context) => <div className="settings">
        {<div className="settings-header">
          <h1>
            {arxs.t("settings.new_header", { module: arxs.modules.titles[this.props.module] })}
          </h1>
          <i
            className={`${metadata && metadata.icon} module`}
            onClick={() =>
              this.props.history.push(metadata.base.route)
            }
          ></i>
        </div>}
        <Tabs className="settings-tabs">
          <TabList>
            {tabs.map(tab => <Tab key={tab.title}>{tab.title}</Tab>)}
          </TabList>
          {tabs.map(tab => <TabPanel key={tab.title}>{tab.render()}</TabPanel>)}
        </Tabs>
      </div>}
    </GlobalContext.Consumer>;
  }
}
