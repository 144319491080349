import React from "react";
import { ProgressBar } from "@progress/kendo-react-progressbars";

import "./LibraryImportProgress.scss";

export default function LibraryImportProgress(props) {
    return <ProgressBar
        className="library-import-progress"
        min={0}
        max={props.max}
        value={props.value}
        labelVisible={false}
    />;
}