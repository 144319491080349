import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';

import './WeblinkPopup.scss';
import { ObjectDocumentType } from 'infra/api/contracts';

export const createWeblinkPopup = (module, onSubmit, onCancel) => {
  const state = {
    title: arxs.t("weblink.popup.title"),
    content: <WeblinkPopup module={module} onSubmit={onSubmit} onCancel={onCancel} />,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
    onSubmit: onSubmit,
    onClose: () => {
      if (onCancel) onCancel();
    }
  };

  return state;
};

function WeblinkPopup(props) {
  const [data, setData] = useState({ documentType: "", weblink: "" });

  const close = () => props.onCancel && props.onCancel();
  const save = () => props.onSubmit && props.onSubmit(data);

  const metadata = arxs.moduleMetadataRegistry.get(props.module);

  const rows = [
    [
      {
        name: "documentType",
        values: (metadata.allowedDocumentTypes || []).filter(x => x !== ObjectDocumentType.Image)
      }
    ],
    [
      {
        name: "weblink",
      }
    ],
  ];

  return <div className={`weblink-popup ${props.className || ""}`}>
    <div className="weblink-popup-body">
      <FieldSet schemaName="weblinkAdd" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`weblink-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`weblink-save`} className="icon" onClick={save}>{arxs.t("common.save")}</Button>
      </div>
    </div>
  </div>;
}