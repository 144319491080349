import React from "react";
import GlobalContext from 'infra/GlobalContext';
import { createGeoLookup } from 'components/controls/geolocation/GeoLookup';

import "./GeoLocationLabel.scss";

export default function GeoLocationLabel(props) {
  const geoLocation = props.value;
  const addressLine = [
    [geoLocation.street, geoLocation.number, geoLocation.bus && `bus ${geoLocation.bus}`].joinNonEmpty(" "),
    [geoLocation.zipcode, geoLocation.city].joinNonEmpty(" "),
    geoLocation.country,
  ]
    .filter(_ => geoLocation.street || geoLocation.city)
    .joinNonEmpty(", ");

  const coordinateLine = [
    `${geoLocation.latitude.toFixed(6)}°`,
    `${geoLocation.longitude.toFixed(6)}°`
  ]
    .filter(_ => geoLocation.latitude && geoLocation.longitude)
    .joinNonEmpty(" ");

  const handleClick = (context) => {
    const addressLookup = createGeoLookup(context, props.value);
    context.popup.show(addressLookup);
  };

  return <GlobalContext.Consumer>
    {
      context => <div className="geolocation-label">
        <i className="fas fa-map-marker-alt"></i>
        <div onClick={() => handleClick(context)}>
          {addressLine && <div>{addressLine}</div>}
          {!addressLine && coordinateLine && <div>{coordinateLine}</div>}
        </div>
      </div>
    }
  </GlobalContext.Consumer>;
}