import { ArXsState } from "infra/arxs";
import {
  OriginModuleEnum,
  RelationshipType,
  ObjectDocumentType,
  IncidentV2Status,
} from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import IncidentWizard from "./IncidentWizard";
import IncidentActions from "./IncidentActions";
import IncidentBoard from "./IncidentBoard";
import IncidentCard from "./IncidentCard";

const getIncidentMetaData = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.IncidentManagement;

  return {
    module,
    title: arxs.t("modules.incidentmanagement"),
    icon: "far fa-exclamation-triangle",
    path: "/incident",
    base: {
      name: "Incident",
      route: "/incident",
      getResource: () => arxs.Api.getResource("incident"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "IncidentManagement.Write",
      readAction: "IncidentManagement.Read",
    },
    settings: {
      name: "IncidentSettings",
      route: "/incident/settings",
      getResource: () => arxs.ApiClient.safety.incidentSettings,
      readAction: "IncidentManagement.Settings.Read",
      writeAction: "IncidentManagement.Settings.Write",
      steps: [
        {
          title: arxs.t("field.processFlows"),
          fields: [
            [
              {
                name: "processFlows",
                type: "itemlist",
                children: [
                  { name: "legalStructure" },
                  { name: "branch", parent: "legalStructure" },
                  { name: "kind", code: "IncidentManagement.Kind" },
                  {
                    name: "type",
                    parent: "kind",
                    code: "IncidentManagement.Type",
                  },
                  { name: "employee" },
                  { name: "userRole" },
                  {
                    name: "relationshipType",
                    values: [
                      RelationshipType.Responsible,
                      RelationshipType.CoResponsible,
                      RelationshipType.Cc,
                      RelationshipType.PreventionAdvisor,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      ],
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "victimType" },
          { name: "locationType" },
          { name: "type" },
          { name: "incidentTime", props: { dateAndTime: true } },
          { name: "isAccident" },
          {
            name: "inactivityDuration",
            props: {
              fromDate: "activityStoppedTime",
              toDate: "activityRestartedTime",
            },
          },
          {
            name: "relationships",
            props: {
              types: [
                RelationshipType.Responsible,
                RelationshipType.PreventionAdvisor,
                RelationshipType.Owner
              ],
            },
          },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [IncidentV2Status.Pending, IncidentV2Status.ToInvestigate, IncidentV2Status.Completed],
    actions: IncidentActions.getActions(OriginModuleEnum.IncidentManagement),
    wizard: {
      wizardClass: IncidentWizard,
      lookups: {
        codeElementsById: {},
        seriousInjuryRanges: [],
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        employeeMap: {},
        contactMap: {},
        supplierMap: {},
      },
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "title"}],
            [{ name: "internalReference"}],
            [{ name: "locationType" }],
            [
              {
                name: "incidentTime",
                props: { showTime: true},//, split: true },
              },
              {
                name: "accidentInvestigationDate",
              },
            ],
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  IncidentWizard.setScopeFields(stateProxy, "context").then(
                    () => IncidentWizard.applyProcessFlow(stateProxy, "context")
                  );
                },
                onLoad: (stateProxy) => {
                  IncidentWizard.getContext(stateProxy);
                },
                title: arxs.t("field.contextLocation"),
              },
              {
                name: "geoLocation",
                onChange: (stateProxy) =>
                  IncidentWizard.toggleLocationType(stateProxy),
              },
            ],
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "IncidentManagement.Kind" },
                type: "codeelementlist",
                onChange: (stateProxy) =>
                  IncidentWizard.applyProcessFlow(stateProxy, "context"),
              },
              {
                name: "victim",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                    OriginModuleEnum.Student,
                  ],
                  overridePrefilter: true,
                },
                title: arxs.t("field.victim"),
                onChange: (stateProxy) =>
                  IncidentWizard.setVictimFields(stateProxy),
              },
            ],
            [
              {
                name: "caregivers",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                },
                title: arxs.t("field.caregivers")
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructure"].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [{ name: "incidentDescription", props: { markDown: true } }],
            [{ name: "injuryAndCareDescription", props: { markDown: true } }],
            [
              {
                name: "subjects",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                  ],
                },
                title: arxs.t("field.subject"),
              },
            ],
            [
              {
                name: "injuryDescriptions",
                code: "IncidentManagement.InjuryDescription",
              },
              {
                name: "injuryPlaces",
                code: "IncidentManagement.InjuryPlace",
              },
            ],
            [
              {
                name: "injuryCauses",
                code: "IncidentManagement.InjuryCause",
              },
              {
                name: "injuryCares",
                code: "IncidentManagement.InjuryCare",
              },
            ],
            [{ name: "isAccident" }],
            [
              { name: "activityStoppedTime" },
              { name: "activityRestartedTime" },
              { name: "flatRateLeave", unit: arxs.t("unit.day").toLowerCase() },
            ],
            [
              {
                name: "divergentIncident",
                code: "IncidentManagement.DivergentIncident",
                props: { sortByCode: true },
                onChange: (stateProxy) =>
                  IncidentWizard.determineIsSerious(stateProxy),
              },
              {
                name: "materialCause",
                code: "IncidentManagement.MaterialCauseId",
                props: { sortByCode: true },
                onChange: (stateProxy) =>
                  IncidentWizard.determineIsSerious(stateProxy),
              },
            ],
            [
              {
                name: "accidentInjuryType",
                code: "IncidentManagement.InjuryType",
                props: { sortByCode: true },
                onChange: (stateProxy) =>
                  IncidentWizard.determineIsSerious(stateProxy),
              },
              {
                name: "accidentInjuryPlace",
                code: "IncidentManagement.InjuryLocation",
                props: { sortByCode: true },
                onChange: (stateProxy) =>
                  IncidentWizard.determineIsSerious(stateProxy),
              },
            ],

            [
              {
                name: "isDeadly",
                onChange: (stateProxy) =>
                  IncidentWizard.determineIsSerious(stateProxy),
              },
              { name: "isPermanentInjury" },
            ],

            [
              {
                name: "isSerious",
              },
              { name: "insuranceAck" },
            ],
          ],
          toggleFieldVisibility: () => {
            return [
              {
                isAccident: {
                  default: "false",
                  false: [
                    "isDeadly",
                    "isPermanentInjury",
                    "activityStoppedTime",
                    "activityRestartedTime",
                    "divergentIncident",
                    "materialCause",
                    "accidentInjuryPlace",
                    "accidentInjuryType",
                    "isSerious",
                    "flatRateLeave",
                    "insuranceAck",
                  ],
                  true: [],
                },
              },
            ];
          },
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [
                    RelationshipType.CoResponsible,
                    RelationshipType.Cc,
                    RelationshipType.PreventionAdvisor,
                  ],
                  vertical: true,
                },
              },
            ],
            [
              {
                name: "witnesses",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                  condensed: true,
                  vertical: true,
                },
                title: arxs.t("field.witnesses"),
              },
              {
                name: "crisisCommitteeMembers",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                  condensed: true,
                  vertical: true,
                },
                title: arxs.t("field.crisisCommittee"),
              },
            ],
            [
              {
                name: "suppliers",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  condensed: true,
                  vertical: true,
                  overridePrefilter: true,
                },
                title: arxs.t("field.suppliers"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  condensed: true,
                  vertical: true,
                },
                title: arxs.t("field.contacts"),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.incident_analysis"),
          fields: [
            [{ name: "analysis", props: { markDown: true } }],
            [{ name: "victimPbms", code: "IncidentManagement.VictimPBM" }],
            [
              {
                name: "relatedPBMs",
                type: "cardlist",
                props: { modules: [OriginModuleEnum.Pbm] },
                title: arxs.t("field.relatedPBMs"),
              },
            ],
            [{ name: "proposedPreventionMeasures", props: { markDown: true } }],
            [{ name: "appliedPreventionMeasures", props: { markDown: true } }],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              {
                name: "images",
                type: "image",
              },
              {
                name: "documents",
                type: "document",
              },
            ],
          ],
        },
      ],
    },
    board: {
      lookups: {
        incidents: [],
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        contactMap: {},
        supplierMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "locationType" },
        { name: "victimType" },
        { name: "victimDepartment" },
        { name: "victimFunction" },
        { name: "victim" },
        { name: "seniority"},
        { name: "kind" },
        { name: "type" },
        { name: "incidentGridDate" },
        { name: "incidentTime" },
        { name: "injuryDescription" },
        { name: "isDeadly" },
        { name: "isSerious" },
        { name: "isPermanentInjury" },
        { name: "inactivityDuration" },
        { name: "insuranceAck" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "preventionAdvisor" },
        { name: "suppliers" },
        { name: "owner" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: IncidentBoard,
      bucketConfig: [
        {
          module: module,
          status: "ToInvestigate",
          onDrop: (state) =>
            IncidentActions.dropActions().onDropInToInvestigate(state),
          requiredActions: ["to_investigate"],
        },
        {
          module: module,
          status: "Completed",
          onDrop: (state) =>
            IncidentActions.dropActions().onDropInCompleted(state),
          requiredActions: ["complete"],
        },
      ],
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
      reports: [
        {
          name: "IncidentRecordCard",
        }
      ],
      excelExports: [{ name: "IncidentInventory", alwaysOn: true }],
    },
    cardClass: IncidentCard,
  };
};
export default getIncidentMetaData;
