import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";
import { CardSubtitle } from "components/card/CardSubtitle";
import Avatar from "components/controls/images/Avatar";
import CardActions from "components/card/CardActions";
import CardImage from "components/card/CardImage";

function getValue(map: any, cardValue: any) {
    const id = (cardValue || {}).id;
    const ref = (map && map[id]) || {};
    return ref.name
        ? ref.name
        : Object.keys(ref).some((x) => x)
        ? ref
        : undefined;
}

export default function ContactCard(props: CardClassProps) {
  const initialLookups = {
      codeElementsById: {},
    };
  
  const [lookups, setLookups] = useState(initialLookups);

  useEffect(() => {
    const subscriptions = {
        lookups: arxs.Api.lookups.subscribe(initialLookups, (values: any) => {
        setLookups((lookups) => ({ ...lookups, ...values }));
        }),
    };

    return () => {
        subscriptions.lookups.dispose();
    };
  }, []);

  const { card, cardProps, actionProps } = props;

  if (actionProps.condensed) {
    const avatar = card.images && card.images.length > 0 && card.images[0].url;
    return (
        <div className={`user-card ${cardProps.className || ""}`}>
        {avatar && (
            <Avatar
                src={avatar}
                fullName={card.firstname + " " + card.surname}
                className="contact"
            />
        )}
        {!avatar && <i className="fa fa-address-card"></i>}
        <div className="user-card-names">
            <div className="user-card-name">
            {card.firstname + " " + card.surname}
            </div>
        </div>
        <CardActions {...props.actionProps} />
        </div>
    );
  }

  const email = card.emails && (card.emails.filter((x: any) => x.isPreferred)[0] || card.emails[0] || {}).email;
  const phone = card.phones && (card.phones.filter((x: any) => x.isPreferred)[0] || card.phones[0] || {}).number;
  const _function = card.function && getValue(lookups.codeElementsById, card.function);
  const department = card.department && getValue(lookups.codeElementsById, card.department);

  return (
    <>
        <div className="list-card-left">
          <CardImage {...props} />
        </div>
        <div className="list-card-right">
            <h2>
                <div className="list-card-title">
                    {card.firstname + " " + card.surname}
                </div>
                <CardActions {...props.actionProps} />
            </h2>
            <CardSubtitle cardProps={cardProps} card={card} />
            <div className="list-card-details">
                <div>{_function}</div>
                <div>{department}</div>
                <div>{phone}</div>
                <div>{email}</div>
            </div>
        </div>
    </>
  );
}
