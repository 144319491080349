import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';

class BranchWizard extends WizardController {
  validate(stateProxy, getCurrentFieldValue, preValidation) {
    const id = getCurrentFieldValue("id");

    const legalStructure = getCurrentFieldValue("legalStructure");
    const name = getCurrentFieldValue("name");

    const branches = stateProxy.getter("branches");

    if (name) {
      if (branches.some(x => x.name.toLowerCase().replace(/\s/g, '') === name.toLowerCase().replace(/\s/g, '')
        && x.legalStructure.id === legalStructure.id
        && x.id !== id)) {
        preValidation["name"] = { error: arxs.t("wizard.validation.duplicate_value", { field: arxs.t(`field.name`) }) };
      }
    }

    return preValidation;
  }

  onLookupsChange(stateProxy) {
    this.setAllowedNaceBellsAndParitairComitesAndTechnicalBusinessUnitsForLegalStructure(stateProxy);
  }

  setAllowedNaceBellsAndParitairComitesAndTechnicalBusinessUnitsForLegalStructure = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const filter = stateProxy.getter("filter") || {};
      const data = stateProxy.getter("data");
      const pristine = stateProxy.getter("pristine");
      const legalStructureMap = stateProxy.getter("legalStructureMap");

      const ref = data["legalStructure"] || pristine["legalStructure"] || {};

      const legalStructure = legalStructureMap && legalStructureMap[ref.id];
      const fields = ["naceBells", "paritairComites", "technicalBusinessUnits"];

      for (const field of fields) {
        filter[field] = legalStructure ? (legalStructure[field] || []).map(x => x.id).toDictionary(x => x) : {};
      }

      stateProxy.setter({ filter }, resolve);
    });
  }

  getFilteredIds = (stateProxy, field, row) => {
    const filter = stateProxy.getter("filter");
    return filter && filter[field][row.id];
  }

  addDefaultAddress = (stateProxy) => {
    const codeElements = stateProxy.getter("codeElements");
    const root = codeElements["School.Address.Type"]
      .filter(x => x.isActive && x.tenantId === arxs.Identity.tenant)[0];
    if (root && root.children && root.children.length > 0) {
      const defaultAddressType = arxs.t("code_elements.built-in.defaultAddressType").toLowerCase();
      const activeChildren = root.children.filter(x => x.isActive);
      const addressType = activeChildren
        .filter(x => x.name.toLowerCase() === defaultAddressType)
        .coalesceIfEmpty(activeChildren)[0];

      const geoLocation = stateProxy.getField("geoLocation");
      const addresses = stateProxy.getField("addresses");
      if (geoLocation && (!addresses || addresses.length === 0)) {
        stateProxy.setField("addresses", [{
          isPreferred: true,
          city: geoLocation.city,
          country: geoLocation.country || "België",
          number: geoLocation.number,
          street: geoLocation.street,
          zipCode: geoLocation.zipCode,
          type: addressType ? { id: addressType.id } : null,
        }]);
      }
    }
  }
}
export default new BranchWizard();
