import arxs from "infra/arxs";
import InstructionCardlikeBoard from "./InstructionCardlikeBoard";

class InstructionCardBoard extends InstructionCardlikeBoard {
  async loadData(requestedLookups, stateProxy) {
    const lookups = arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.instructionCards) {
        lookups.pristine = lookups.instructionCards
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.instructionCards;
      }
      stateProxy.setter(lookups);
    });

    return Promise.resolve({ lookups });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for InstructionCard yet");
  };
}
export default new InstructionCardBoard();
