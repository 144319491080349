import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import AutoSizer from 'react-virtualized-auto-sizer';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';

// Note: By using import Pannellum from "pannellum-react/es/elements/Pannellum" the video.js component will be skipped, which decreases the production build by more than 500kb.
// See: https://github.com/farminf/pannellum-react
import Pannellum from "external/pannellum-react/elements/Pannellum";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './ImagePreview.scss';

export const createImagePreview = (images) => {
    const state = {
        title: arxs.t("controls.image.preview.title"),
        getContent: (props) => <ImagePreview images={images} {...props} />,
        maximized: true
    };
    return state;
};

export class ImagePreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            imageSize: {},
        };

        this.downloadAnchorRef = React.createRef();
    }

    componentDidMount() {
        this.setTitle();
        this.setImageSize();

    }

    setImageSize = () => {
        const { imageSize } = this.state;

        for (const image of this.props.images) {
            const url = image.previewUrl || image.url;
            this.getImageSize(url, ((width, height, ratio) => {
                imageSize[url] = { width, height, ratio };
                this.setState({ imageSize });
            }));
        }
    }

    getImageSize = (url, callback) => {
        const img = new Image();

        img.onload = () => {
            callback(img.width, img.height, img.width / img.height);
        }
        img.src = url;
    }

    setTitle = () => {
        const { selected } = this.state;
        const { images } = this.props;

        const name = images[selected].name;

        if (this.props.onTitleChanged) {
            this.props.onTitleChanged(`${arxs.t("controls.image.preview.title")} - ${name}`);
        }
    }

    onImageSelect = (selectedIndex) => {
        this.setState({ selected: selectedIndex });
        this.setTitle();
    }

    renderItem = (item, options) => {
        const { imageSize } = this.state;
        const url = item.props.children.props.src;

        if (imageSize[url] && imageSize[url].ratio === 2) {
            return <div className="image-item" key={url}>
                <AutoSizer>
                    {({ width, height }) =>
                        <Pannellum
                            width={`${width}px`}
                            height={`${height}px`}
                            image={url}
                            pitch={10}
                            yaw={180}
                            hfov={110}
                            autoLoad
                            orientationOnByDefault={false}
                            draggable
                            keyboardZoom
                            mouseZoom
                            showControls={false}
                            showFullscreenCtrl={false}
                            showZoomCtrl
                            onError={(err) => { 
                                arxs.logger.error("Pannellum error {error}", err);
                            }} />
                    }
                </AutoSizer>
            </div>
        }

        return <div className="image-item" key={url}>
            <TransformWrapper
                defaultScale={1}
                options={{ limitToWrapper: true, limitToBounds: false, minScale: 0.2, maxScale: 5 }}
                doubleClick={{ mode: 'reset' }}>
                <TransformComponent>
                    {item.props.children}
                </TransformComponent>
            </TransformWrapper>
        </div>;
    }

    onDownload = (event, selectedImage) => {
        event.preventDefault();
        window.open(selectedImage.url, "_blank");
    }

    render() {
        const { selected } = this.state;
        const { images } = this.props;

        const imageItems = images.map((image) => {
            const url = image.previewUrl || image.url;

            return <div className="image-item" key={image.name} >
                <img src={url} alt={image.name} id={url} />
            </div >
        });

        return (
            <GlobalContext.Consumer>
                {(context) => <div className="image-preview">
                    <div className="image-preview-images">
                        <Carousel
                            showArrows={true}
                            showStatus={false}
                            showIndicators={true}
                            infiniteLoop={false}
                            showThumbs={true}
                            useKeyboardArrows={true}
                            dynamicHeight={true}
                            selectedItem={selected}
                            thumbWidth={100}
                            onChange={this.onImageSelect}
                            renderItem={(item, options) => this.renderItem(item, options)}
                            swipeable={false}>
                            {imageItems}
                        </Carousel>
                    </div>
                    <HorizontalSeparator />
                    <div className="image-preview-download" onClick={(event) => this.onDownload(event, images[selected])}>
                        {arxs.t("controls.image.preview.download")}
                    </div>
                </div>}
            </GlobalContext.Consumer >
        );
    }
}