import React, { useState, useEffect } from "react";
import arxs from "infra/arxs";

import './Matrix.scss';

export default function Matrix(props) {
    const [data, setData] = useState();

    const values = [1, 2, 3, 4, 5];
    const chances = [
        { name: `${arxs.t("controls.form.risk_analysis.chance.unlikely")}`, range: "0-1%" },
        { name: `${arxs.t("controls.form.risk_analysis.chance.small")}`, range: "1-10%", description: `${arxs.t("controls.form.risk_analysis.chance.exists_not_large")}` },
        { name: `${arxs.t("controls.form.risk_analysis.chance.possible")}`, range: "10-25%", description: `${arxs.t("controls.form.risk_analysis.chance.real")}` },
        { name: `${arxs.t("controls.form.risk_analysis.chance.probable")}`, range: "25-50%", description: `${arxs.t("controls.form.risk_analysis.chance.big")}` },
        { name: `${arxs.t("controls.form.risk_analysis.chance.certainty")}`, range: "50-100%" }];
    const consequences = [
        `${arxs.t("controls.form.risk_analysis.consequence.very_small")}`,
        `${arxs.t("controls.form.risk_analysis.consequence.small")}`,
        `${arxs.t("controls.form.risk_analysis.consequence.serious")}`,
        `${arxs.t("controls.form.risk_analysis.consequence.large")}`,
        `${arxs.t("controls.form.risk_analysis.consequence.very_large")}`];

    const { readOnly } = props;

    useEffect(() => {
        props.onChange(data);
    }, [data]);


    const getColor = (value) => {
        switch (true) {
            case (value < 5): return "status-completed";
            case (value < 12): return "status-warning";
            case (value < 20): return "status-severe";
            case (value >= 20): return "status-error";
        }
    }

    const renderCell = (r, c, index) => {
        const calculatedValue = r * c;

        let value = data || props.data || {};

        if (value) {
            if (typeof (value) === "string") {
                value = JSON.parse(value) || {};
            }
        }

        const selected = r === value.row
            && c === value.col;

        if (readOnly) {
            return <div key={`cell-${index}`}
                        className={`risk-matrix-cell ${getColor(calculatedValue)} ${selected && "selected"}`}>
                <div className="risk-matrix-cell-value">{calculatedValue}</div>
            </div>;
        }

        return <div key={`cell-${index}`}
                    className={`risk-matrix-cell ${getColor(calculatedValue)} ${selected && "selected"}`}
                    onClick={() => setData({ col: c, row: r })}>
            <div className="risk-matrix-cell-value">{calculatedValue}</div>
        </div>;
    }

    const renderHeaderCell = (value, index) => {
        return <div key={`header-${index}`}
                    className="risk-matrix-cell header"
                    title={`${value.range} ${value.description || ''}`}>
                        {value.name}
                </div>
    }

    const renderRow = (r, index) => {
        return <div className="risk-matrix-row" key={`row-${index}`}>
            <div className="risk-matrix-cell header" title={arxs.t("controls.form.risk_analysis.money_time_quality_safety")}>{consequences[index]}</div>
            {values.map((c, i) => renderCell(r, c, i))}
        </div>
    }

    const renderHeaderCells = () => {
        return <div className="risk-matrix-row">
            <div className="risk-matrix-cell no-border"></div>
            {chances.map((x, i) => renderHeaderCell(x, i))}
        </div>
    }

    return <div className="risk-matrix">
        <div className="risk-matrix-left"><div className="text">{arxs.t("controls.form.risk_analysis.consequence.title")}</div></div>
        <div className="risk-matrix-inner">
            <div className="risk-matrix-top">{arxs.t("controls.form.risk_analysis.chance.title")}</div>
            {renderHeaderCells()}
            {values.map((r, i) => renderRow(r, i))}
        </div>
    </div>
};