import BoardController from 'modules/BoardController';
import arxs from 'infra/arxs';
import { RelationshipType } from "infra/api/contracts";

class DocumentManagementBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const branchMap = stateProxy.getter("branchMap");
    const legalStructureMap = stateProxy.getter("legalStructureMap");
    const legalStructuresInScope = card.branches.map(x => this.getLegalStructureInScope(legalStructureMap, branchMap, x)).distinct();
    const legalStructures = card.legalStructures.map(x => this.getLookupValue(legalStructureMap, x));
    const branches = card.branches.map(x => this.getLookupValue(branchMap, x));

    return {
      ...card,

      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(card.kind, stateProxy.getter("codeElementsById")),
      type: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      creationDate: card.creationDate && new Date(card.creationDate),
      createdAt: card.createdAt && new Date(card.createdAt),
      scope: legalStructures.concat(branches).filter(x => x !== "").join(", "),
      legalStructureNames: legalStructures.concat(legalStructuresInScope).distinct().filter(x => x !== "").join(", "),
      branchNames: branches.filter(x => x !== "").join(", "),
      responsible: this.getEmployeeValue(card.responsible, stateProxy.getter("employeeMap")),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      owner: this.getEmployeeValue(card.relationships.filter(x => x.type === RelationshipType.Owner), stateProxy.getter("employeeMap")) || this.getEmployeeValue(card.owner, stateProxy.getter("employeeMap")),
      mostRecentVersion: card.versions.map(x => new Date(x.versionDate)).orderByDescending(x => x).filter(x => x)[0],
      revisionDate: this.getRevisionDate(card)
    };
  }

  getLegalStructureInScope = (legalStructureMap, branchMap, ref) => {
    const branch = ref && branchMap && branchMap[ref.id];
    if (branch && branch.legalStructure) {
      const legalStructure = this.getLookupValue(legalStructureMap, branch.legalStructure);
      return legalStructure;
    }
    return null;
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.kind,
      card.type,
      card.title,
      card.description,
      ...((card.legalStructureNames || "").split(", ") || []).map(x => x),
      ...((card.branchNames || "").split(", ") || []).map(x => x),
      ...(card.tags || []).map(x => x.name)
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.documents) {
            lookups.pristine = lookups.documents
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.documents;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
        });
  }
}
export default new DocumentManagementBoard();