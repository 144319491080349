import React, { Component } from 'react';
import arxs from 'infra/arxs';
import { Card } from 'components/card/Card';
import { TextArea } from 'components/controls/TextArea';

import './MentionPopup.scss';

class MentionPopup extends Component {
    lookups = {
        employees: []
    }

    constructor(props) {
        super(props);

        this.state = {
            pristine: [],
            filtered: [],
            employees: [],
            currentSelection: 0,
            word: null,
            top: null,
            left: null,
            height: null,
            ...this.lookups
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.height !== this.container.offsetHeight) {
            const height = this.container.offsetHeight;
            this.setState({ height });

            if (this.props.textAreaRef) {
                const element = this.props.textAreaRef.current;
                if (element) {
                    const bounds = element.getBoundingClientRect();
                    const canRenderOnBottom = (window.innerHeight - height - bounds.bottom) > 0;
                    const top = canRenderOnBottom ? bounds.bottom : bounds.top - height;
    
                    // The bounds of the textArea are relative to the window, and the container is relative to the document.
                    // So we ignore the left position, and assume the container stretches to the full height of the document.
                    this.container.style.top = Math.floor(top - 64) + "px";
                }
            }
        }

        if (prevProps.text !== this.props.text
            || prevProps.selectionStart !== this.props.selectionStart) {
            const word = this.findMentionAtPos(this.props.text, this.props.selectionStart);
            this.setState({ word });
        }

        if (prevState.word !== this.state.word) {
            this.refresh();
        }

        if (prevProps.securityContext !== this.props.securityContext) {
            this.refresh();
        }
    }

    findMentionAtPos = (str, pos) => {
        let left = str.substr(0, pos).replace(/[\r\n]/g, "");
        let right = str.substr(pos);

        left = left.replace(/.*@/g, "@").replace(/^.* /g, "");
        right = right.replace(/ .+$/g, "");

        const candidate = left + right;
        if (candidate.length > 1 && candidate[0] === '@') {
            return candidate.substr(1);
        }
        return null;
    };

    findSubstringUpstreamOf = (haystack, needle, index) => {
        for (let i = index; i > -1; i--) {
            if (haystack.substr(i, needle.length) === needle) {
                return i;
            }
        }
        return -1;
    };

    replaceTextAt = (haystack, needle, replaceBy, index) => {
        if (index === -1 || index === undefined) {
            return haystack.replace(needle, replaceBy);
        }
        const left = haystack.substr(0, Math.max(0, index));
        const right = haystack.substr(index + needle.length);
        return left + replaceBy + right;
    };

    refresh = () => {
        const securityFilter = this.props.securityContext ? this.props.securityContext.filter : () => false;

        const pristine = this.state.employees
            .filter(securityFilter)
            .orderBy(x => x.name);

        const filtered = pristine
            .filter(item => this.state.word && [item.name, item.userName].map(x => x.toLowerCase()).some(x => x.indexOf(this.state.word.toLowerCase()) > -1))
            .slice(0, 4);

        this.setState({ pristine, filtered });
    }

    onKeyDown = (event) => {
        const { currentSelection, filtered } = this.state;

        if (filtered.length === 0) return;

        switch (event.keyCode) {
            case 40:
                event.preventDefault();
                this.setState({ currentSelection: (currentSelection + 1) % filtered.length });
                break;
            case 38:
                event.preventDefault();
                this.setState({ currentSelection: (currentSelection - 1) % filtered.length });
                break;
            case 27:
                event.preventDefault();
                this.close();
                break;
            case 9:
            case 13:
                if (filtered.length > currentSelection) {
                    event.preventDefault();
                    const name = filtered[currentSelection].userName;
                    this.setMention(name);
                }
                break;
            default:
                this.refresh()
                return;
        }
    }

    setMention = (name) => {
        const { selectionStart, text } = this.props;
        const { word } = this.state;
        let newText = '';
        if (this.props.show) {
            newText = this.replaceTextAt(text, '', '@' + name, selectionStart);
        } else {
            const foundAt = this.findSubstringUpstreamOf(text, '@', selectionStart);
            newText = this.replaceTextAt(text, '@' + word, '@' + name + ' ', foundAt);
        }

        const setAndClose = () => {
            this.props.onSelect(newText)
            this.props.onClose();
        }

        this.setState({
            currentSelection: 0,
            word: null,
            filtered: []
        }, setAndClose);
    }

    close = () => {
        this.setState({
            currentSelection: 0,
            word: null,
            filtered: []
        }, this.props.onClose);
    }

    onItemMouseHover = (args, item) => {
        const { filtered } = this.state;

        const getIndex = (value, arr) => {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].id === value) {
                    return i;
                }
            }
            return -1;
        }

        this.setState({
            currentSelection: getIndex(item.id, filtered)
        });
    }

    onItemSelect = (event, item) => {
        event.preventDefault();
        const name = item.userName;
        this.setMention(name);
    }

    render() {
        const { filtered, word, currentSelection } = this.state;

        const field = {
            name: 'mentionSearch',
            getter: () => word || '',
            setter: (value) => {
                this.setState({ word: value });
            }
        };

        const show = this.props.show || filtered.length > 0;

        return (

            <div
                className="mention-popup"
                style={{
                    display: show ? "block" : "none",
                }}
                onKeyDown={this.onKeyDown}
                ref={el => (this.container = el)}>
                <h1>{arxs.t('activities.mention_title')}</h1>
                <div className="mention-close" onClick={this.close}>
                    <i className="fa fa-times"></i>
                </div>
                {this.props.show &&
                    <div className="mention-input">
                        <TextArea field={field} className='input' />
                    </div>}
                <div
                    className="mention-choice">
                    {(filtered || [])
                        .map((item, index) =>
                            (
                                <div
                                    key={index}
                                    className={index === currentSelection ? "selected" : ""}
                                    onClick={(args) => this.onItemSelect(args, item)}
                                    onMouseEnter={(args) => this.onItemMouseHover(args, item)}
                                    onMouseLeave={(args) => this.onItemMouseHover(args, item)}>
                                    <Card
                                        data={{ ...item, getImage: () => item.imagePath }}
                                        readOnly
                                        condensed={true}
                                    />
                                </div>
                            ))}
                </div>
            </div>
        );
    }
}
export default MentionPopup;