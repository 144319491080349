import BoardController from "modules/BoardController";
import arxs from "infra/arxs";

class SupplierBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const preferredAddress= (card.addresses || []).filter(x => x.isPreferred)[0] || (card.addresses || []).firstOrDefault((x) => x);
    const preferredEmail= (card.emails || []).filter(x => x.isPreferred)[0] || (card.emails || []).firstOrDefault((x) => x);
    const preferredPhone= (card.phones || []).filter(x => x.isPreferred)[0] || (card.phones || []).firstOrDefault((x) => x);
 
    return {
      ...card,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),
      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      createdAt: card.createdAt && new Date(card.createdAt),
      title: card.name,
      address: preferredAddress && `${preferredAddress.street} ${preferredAddress.number}, ${preferredAddress.zipCode} ${preferredAddress.city}, ${preferredAddress.country}`,
      email: preferredEmail && preferredEmail.email,
      phone: preferredPhone && preferredPhone.number
    };
  }

  getCardSearchTerms(card) {
    return [
      card.name,
      card.kind,
      card.type,
      card.enterpriseNumber,
      card.uniqueNumber,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.emails || []).map((x) => x.email),
      ...(card.addresses || []).map((x) => x.bus),
      ...(card.addresses || []).map((x) => x.city),
      ...(card.addresses || []).map((x) => x.country),
      ...(card.addresses || []).map((x) => x.number),
      ...(card.addresses || []).map((x) => x.street),
      ...(card.addresses || []).map((x) => x.zipCode),
      ...(card.phones || []).map((x) => x.number),
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.suppliers) {
        lookups.pristine = lookups.suppliers
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.suppliers;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new SupplierBoard();
