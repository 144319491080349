import React, { Component } from 'react';
import arxs from 'infra/arxs';

import './CheckBox.scss';

class CheckBox extends Component {
  doNothing() { }

  onClick = (e) => {
    e.preventDefault();
    if (this.props.onClick) {
      this.props.onClick(e);
    } else if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  render() {
    let checkboxProps = {
      checked: !!this.props.checked,
      onChange: this.props.onChange || this.doNothing
    };

    checkboxProps.id = this.props.id || `checkbox-${arxs.getIncrementalIndex()}`;
    if (this.props.disabled !== undefined) checkboxProps.disabled = this.props.disabled;

    return (<div className={`checkbox ${this.props.className || ""}`}>
      <input type="checkbox" {...checkboxProps} />
      <label htmlFor={checkboxProps.id} onClick={this.onClick}> {this.props.label}</label>
    </div>);
  }
}
export default CheckBox;
