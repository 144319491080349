import arxs from 'infra/arxs';
import BoardController from 'modules/BoardController';

class IntangibleAssetBoard extends BoardController {
  enrichCard(card, stateProxy) {
    return {
      ...card,

      sortId: card.sort && card.sort.id,
      kindId: card.kind && card.kind.id,
      typeId: card.type && card.type.id,
      title: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      sort: this.getCodeElementValue(card.sort, stateProxy.getter("codeElementsById")),
      kind: this.getCodeElementValue(card.kind, stateProxy.getter("codeElementsById")),
      type: this.getCodeElementValue(card.type, stateProxy.getter("codeElementsById")),
      legalStructureId: card.legalStructure && card.legalStructure.id,
      branchId: card.branch && card.branch.id,
      legalStructure: this.getLookupValue(stateProxy.getter("legalStructureMap"), card.legalStructure),
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(stateProxy.getter("buildingMap"), card.building),
      location: this.getLookupValue(stateProxy.getter("locationMap"), card.location),
      supplier: this.getLookupValue(stateProxy.getter("supplierMap"), card.supplier),
      contacts: (card.contacts ||[]).map(x => this.getLookupValue(stateProxy.getter("contactMap"), x)),
      extraSpecifications: (card.extraSpecifications || []).map(x => x.description),

      owner: this.getLookupValue(stateProxy.getter("employeeMap"), card.owner),
      recommendation: this.getLookupValueWithFieldNames(
        stateProxy.getter("recommendationMap"),
        card.recommendation, ["uniqueNumber", "title"]
      ),
      purchaseDate: card.purchaseDate && new Date(card.purchaseDate),
      expirationDate: card.expirationDate && new Date(card.expirationDate),
      depreciationPeriodEnd: card.depreciationPeriodEnd && new Date(card.depreciationPeriodEnd),
      createdAt: card.createdAt && new Date(card.createdAt),
      combinedInstallationUniqueNumber: this.getUniqueNumbersOfCombinedInstallations(stateProxy, card.inboundLinks),
      periodical_uniqueNumber: this.getUniqueNumbersOfPeriodicals(stateProxy, card.inboundLinks),
    };
  }

  getCardSearchTerms(card) {
    return [
      card.description,
      card.uniqueNumber,
      card.internalNumber,
      card.sort,
      card.kind,
      card.type,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      card.brand,
      card.model,
      ...(card.tags || []).map((x) => x.name),
      ...(card.extraSpecifications || []),
      card.combinedInstallationUniqueNumber,
      card.periodical_uniqueNumber,
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups
      .subscribe(requestedLookups
        , lookups => {
          if (lookups.intangibleAssets) {
            lookups.pristine = lookups.intangibleAssets
              .orderByDescending(card => card.createdAt)
              .map((card) => {
                return {
                  ...card,
                  activities: card.numberOfMessages,
                  selected: () => !!stateProxy.getter("selected")[card.id]
                };
              });
            delete lookups.intangibleAssets;
          }
          stateProxy.setter(lookups, () => Promise.resolve({ lookups }));

        });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for IntangibleAsset goods yet");
  }
}
export default new IntangibleAssetBoard();