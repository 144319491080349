import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import BuildingBoard from "./BuildingBoard";
import BuildingWizard from "./BuildingWizard";
import { ObjectDocumentType, OriginModuleEnum, StatusEnum } from "infra/api/contracts";
import BuildingActions from "./BuildingActions";
import BuildingCard from "./BuildingCard";


const getBuildingMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.Building;

  return {
    module,
    title: arxs.t("modules.building"),
    icon: "far fa-building",
    path: "/building",
    base: {
      name: "Building",
      route: "/building",
      getResource: () => arxs.Api.getResource("building"),
      subscribe: (id, module, handler) => arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "Building.Write",
      readAction: "Building.Read",
    },
    settings: {
      name: "BuildingSettings",
      route: "/building/settings",
      getImportResource: () => arxs.ApiClient.assets.buildingSettings,
      readAction: "Building.Settings.Write",
      writeAction: "Building.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "name" },
          { name: "description" },
          { name: "internalName" },
          { name: "abbreviation" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.FloorPlan,
      ObjectDocumentType.EvacuationPlan,
      ObjectDocumentType.PartitioningPlan,
      ObjectDocumentType.Certificate,
      ObjectDocumentType.AdditionalDocument
    ],
    statuses: [
      StatusEnum.Active,
      StatusEnum.ToCommission1,
      StatusEnum.ToCommission2,
      StatusEnum.InService,
      StatusEnum.OutOfService,
    ],
    statusColorOverride: [
      { status: StatusEnum.Active, color: "status-completed" },
    ],
    actions: BuildingActions.getActions(OriginModuleEnum.Building),
    wizard: {
      wizardClass: BuildingWizard,
      steps: [
        {
          title: arxs.t("details_pane.location"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.School,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  BuildingWizard.setScopeFields(stateProxy, "context").then(
                    () => BuildingWizard.applyProcessFlow(stateProxy, "context")
                  );
                },
                onLoad: (stateProxy) => {
                  BuildingWizard.getContext(stateProxy);
                },
                title: arxs.t("field.contextLocation"),
              },
              { name: "geoLocation" },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructure", "branch"].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "name" }],
            [{ name: "internalName" }, { name: "abbreviation" }],
            [{ name: "description" }],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "constructionHistoryRules",
                type: "itemlist",
                children: [
                  {
                    name: "buildingHistoryType",
                    code: "ConstructionHistory.Type",
                    width: "200px",
                  },
                  { name: "constructionDate", width: "150px" },
                  { name: "description", multiLine: false },
                ],
              },
            ],
            [
              {
                name: "netDimensionRules",
                type: "itemlist",
                children: [
                  { name: "floor", code: "Building.Floors", width: "200px" },
                  { name: "description", multiLine: false },
                  {
                    name: "dimension",
                    unit: arxs.t("unit.square_meter").toLowerCase(),
                    width: "150px",
                  },
                ],
              },
            ],
            [
              {
                name: "grossDimensionRules",
                type: "itemlist",
                children: [
                  { name: "floor", code: "Building.Floors", width: "200px" },
                  { name: "description", multiLine: false },
                  {
                    name: "dimension",
                    unit: arxs.t("unit.square_meter").toLowerCase(),
                    width: "150px",
                  },
                ],
              },
            ],
            [
              {
                name: "volumes",
                type: "itemlist",
                children: [
                  { name: "floor", code: "Building.Floors", width: "200px" },
                  { name: "description", multiLine: false },
                  {
                    name: "dimension",
                    unit: arxs.t("unit.cubic_meter").toLowerCase(),
                    width: "150px",
                  },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.fire_technical_data"),
          fields: [
            [
              {
                name: "buildingCompartments",
                type: "itemlist",
                children: [
                  { name: "floor", code: "Building.Floors", width: "200px" },
                  { name: "name", width: "200px" },
                  { name: "description", multiLine: false },
                  {
                    name: "surface",
                    unit: arxs.t("unit.square_meter").toLowerCase(),
                    width: "150px",
                  },
                ],
              },
            ],
            [
              {
                name: "legislationRules",
                type: "itemlist",
                children: [
                  {
                    name: "legislationKind",
                    code: "Legislation.Kind",
                    width: "400px",
                  },
                  {
                    name: "legislationType",
                    code: "Legislation.Type",
                    parent: "legislationKind",
                    width: "400px",
                  },
                  { name: "description", multiLine: false },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      fieldsWithIdsForDuplication: [
        "constructionHistoryRules",
        "netDimensionRules",
        "grossDimensionRules",
        "volumes",
        "buildingCompartments",
        "legislationRules"
      ],
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        buildings: {},
        tagMap: {},
        hazardousSubstances:[],
        periodicalMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "name" },
        { name: "abbreviation" },
        { name: "internalName", width: "250px" },
        { name: "description" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "link" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: BuildingBoard,
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Map],
    },
    cardClass: BuildingCard
  };
};
export default getBuildingMetadata;
