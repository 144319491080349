import React, { useEffect, useState } from 'react';
import arxs from 'infra/arxs';
import { MailAttachmentType } from 'infra/api/contracts';
import { SecurityContext } from 'infra/SecurityContext';
import Toaster from 'components/util/Toaster';
import { Spinner } from 'components/animations/Spinner';
import FieldSet from 'components/controls/FieldSet';
import Button from 'components/controls/Button';

import "./MailPopup.scss";

export interface MailPopupProps {
  id: string,
  module: string,
  mailId?: string,
  securityContext: SecurityContext,
  onClose(): void,
}

export interface CreateMailPopupProps extends MailPopupProps {
  onSubmit(): void,
}

export const createMailPopup = (props: CreateMailPopupProps) => {
  const title = props.mailId ? arxs.t("actions.mailing.read_title") : arxs.t("actions.mailing.send_title");
  const state = {
    title,
    getContent: () =>
      <MailPopup id={props.id}
        module={props.module}
        mailId={props.mailId}
        securityContext={props.securityContext}
        onClose={props.onClose}
      />,
    onSubmit: props.onSubmit,
    onClose: props.onClose,
  };
  return state;
};

export default function MailPopup(props: MailPopupProps) {
  const [isSending, setIsSending] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<any>();

  useEffect(() => {
    const resource = arxs.Api.getResource(props.module);
    if (props.mailId) {
      resource.getMail(props.id, props.mailId)
        .then((x: any) => {
          setData(x);
          setIsLoaded(true);
        });
    } else {
      const meta = arxs.moduleMetadataRegistry.get(props.module);
      resource.getById(props.id)
      .then((x:any) => {
        const reportAttachments = ((meta.board || {}).reports || [])
        .filter(report => !!report.alwaysOn === false)
        .map(report => ({ id: report.name, name: arxs.t(`report.${report.name}`) + " " + x.uniqueNumber, type: MailAttachmentType.Report }));
      setData({ ...data, attachments: reportAttachments });
      setIsLoaded(true);
      })
      
    }
  }, []);

  const rows = [
    [
      {
        name: "to",
      },
      {
        name: "cc",
      },
      {
        name: "attachments",
        props: {
          module: props.module,
          id: props.id,
          mailId: props.mailId,
        }
      },
      {
        name: "subject",
      },
      {
        name: "body",
        multiLine: true,
        isLoaded: true,
        noHeader: true,
        props: {
          markDown: true,
        }
      }
    ]
  ];

  const handleSend = () => {
    const resource = arxs.Api.getResource(props.module);
    if (resource.sendMail) {
      setIsSending(true);
      resource.sendMail(props.id, data, true)
        .then((response: any) => {
          setIsSending(false);

          if (response.ok) {
            Toaster.success(arxs.t("mail_popup.sent_successfully"));
            props.onClose();
          } else {
            Toaster.error("Er ging iets mis...");
          }
        });
    }
  };

  const readOnly = !!props.mailId;

  return <div className="mail-popup">
    {isLoaded
      ? <>
        <FieldSet schemaName={"MailMessage"} rows={rows} data={data} setData={setData} readOnly={readOnly} />
        {!props.mailId && <div className="mail-popup-buttons">
          {!isSending && <Button className="mail-popup-button mail-popup-send icon" onClick={handleSend} title={arxs.t("mail_popup.send")}>
            <i className="fas fa-paper-plane"></i>
          </Button>}
          {isSending && <Button className="mail-popup-button mail-popup-send icon" >
            <Spinner />
          </Button>}
        </div>}
      </>
      : <Spinner />}
  </div>;
}