import DocumentSigningWizardController from 'modules/DocumentSigningWizardController';

class InstructionCardWizard extends DocumentSigningWizardController {
    getRequiredFields(getCurrentFieldValue) {
        return [];
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        this.validateSignature(getCurrentFieldValue, preValidation);

        return preValidation;
    }
}
export default new InstructionCardWizard();
