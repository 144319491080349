import React, { Fragment } from "react";
import GlobalContext from "infra/GlobalContext";
import { RichTextEditor } from "components/controls/RichTextEditor";
import FormItemEditorController from "./FormItemEditorController";

import "./Instruction.scss";

export default class Instruction extends FormItemEditorController {
    render() {
        const field = {
            isLoaded: true,
            getter: () => this.props.field.getter().title,
            setter: (value) => this.props.field.setter({ ...this.props.field.getter(), title: value }),
            readOnly: this.props.readOnly,
        };
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <RichTextEditor
                        className="form-item-editor-rich-text"
                        field={field}
                    />
                    {this.renderEnd(false)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}