import React, { Component } from 'react';

import './Badge.scss';

class Badge extends Component {
  render() {
    return (
      <div className={`badge ${this.props.className || ""}`}>{this.props.children}</div>
    );
  }
}
export default Badge;