import arxs from 'infra/arxs';
import { OriginModuleEnum } from 'infra/api/contracts';

function trim(str: string, ch: string) {
  var start = 0,
    end = str.length;

  while (start < end && str[start] === ch)
    ++start;

  while (end > start && str[end - 1] === ch)
    --end;

  return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}

export default function createClassicRouter(classicPrefix: string, vNextModules: Array<string>) {
  classicPrefix = trim(classicPrefix, '/').toLowerCase();

  const vNextModuleMap = vNextModules.toDictionary(x => x, _ => true);

  // REMARK: When adding new routes, make sure to sort by more specific route definition first
  //         Route definitions are interpreted as a prefix match so any url part that comes after te definition (starting with / or ?) is considered a match
  //         For instance, given definitions [ "task", "task/settings" ]
  //          - /task/TAB-123456 is a match for definition "task"
  //          - /task/settings is also a match for definition "task" due to it being the first matching definition
  //         To get it to match /task/settings, we need to have that definition first int he definition list.
  //         Given definitions [ "task/settings", "task" ]
  //          - /task/TAB-123456 is a match for definition "task"
  //          - /task/settings is a match for definition "task/settings"
  const routes = [
    // vNext to classic routes
    ['legalstructure/settings', OriginModuleEnum.SchoolGroup, 'SchoolGroupSettings'],
    ['legalstructure', OriginModuleEnum.SchoolGroup, 'SchoolGroup'],
    ['branch/settings', OriginModuleEnum.School, 'SchoolSettings'],
    ['branch', OriginModuleEnum.School, 'School'],
    ['building/settings', OriginModuleEnum.Building, 'BuildingSettings'],
    ['building', OriginModuleEnum.Building, 'Building'],
    ['location/settings', OriginModuleEnum.Room, 'RoomSettings'],
    ['location', OriginModuleEnum.Room, 'Room'],
    ['labourmeans/settings', OriginModuleEnum.Labourmeans, 'LabourmeansSettings'],
    ['labourmeans', OriginModuleEnum.Labourmeans, 'Labourmeans'],
    ['equipment/settings', OriginModuleEnum.EquipmentInstallation, 'EquipmentSettings'],
    ['equipment', OriginModuleEnum.EquipmentInstallation, 'Equipment'],
    ['hazardoussubstance/settings', OriginModuleEnum.HazardousSubstance, 'HazardousSubstanceSettings'],
    ['hazardoussubstance', OriginModuleEnum.HazardousSubstance, 'HazardousSubstance'],
    ['combinedinstallation', OriginModuleEnum.CombinedInstallation, 'CombinedInstallation'],
    ['protectionequipment', OriginModuleEnum.Pbm, 'ProtectionEquipment'],
    ['combinedinstallation/settings', OriginModuleEnum.CombinedInstallation, 'CombinedInstallationSettings'],
    ['combinedinstallation', OriginModuleEnum.CombinedInstallation, 'CombinedInstallation'],
    ['taskrequest/settings', OriginModuleEnum.NotificationDefect, 'NotificationDefectSettings'],
    ['taskrequest', OriginModuleEnum.NotificationDefect, 'NotificationDefect'],
    ['task/settings', OriginModuleEnum.Task, 'TaskSettings'],
    ['task', OriginModuleEnum.Task, 'Task'],
    ['maintenance/settings', OriginModuleEnum.PeriodicMaintenance, 'PeriodicMaintenanceSettings'],
    ['maintenance', OriginModuleEnum.PeriodicMaintenance, 'PeriodicMaintenance'],
    ['inspection/settings', OriginModuleEnum.PeriodicControl, 'PeriodicControlSettings'],
    ['inspection', OriginModuleEnum.PeriodicControl, 'PeriodicControl'],
    ['safetyinstructioncard/settings', OriginModuleEnum.SafetyInstructionCard, 'SafetyInstructionCard/ettings'],
    ['safetyinstructioncard', OriginModuleEnum.SafetyInstructionCard, 'SafetyInstructionCard'],
    ['commissioning/settings', OriginModuleEnum.Commissioning, 'CommissioningSettings'],
    ['commissioning', OriginModuleEnum.Commissioning, 'Commissioning'],
    ['outofcommissioning/settings', OriginModuleEnum.OutOfCommissioning, 'OutOfCommissioningSettings'],
    ['outofcommissioning', OriginModuleEnum.OutOfCommissioning, 'OutOfCommissioning'],
    ['consultancy/settings', OriginModuleEnum.Consultancy, 'ConsultancySettings'],
    ['consultancy', OriginModuleEnum.Consultancy, 'Consultancy'],
    ['riskanalysis/settings', OriginModuleEnum.RiskAnalysis, 'RiskAnalysisSettings'],
    ['riskanalysis', OriginModuleEnum.RiskAnalysis, 'RiskAnalysis'],
    ['incident/settings', OriginModuleEnum.IncidentManagement, 'IncidentManagementSettings'],
    ['incident', OriginModuleEnum.IncidentManagement, 'IncidentManagement'],
    ['multiyearplan/settings', OriginModuleEnum.GlobalPreventionPlan, 'GlobalPreventionPlanSettings'],
    ['multiyearplan', OriginModuleEnum.GlobalPreventionPlan, 'GlobalPreventionPlan'],
    ['yearactionplan', OriginModuleEnum.YearActionPlan, 'YearActionPlan'],
    ['documentmanagement/settings', OriginModuleEnum.Document, 'DocumentSettings'],
    ['documentmanagement', OriginModuleEnum.Document, 'Document'],
    ['training/settings', OriginModuleEnum.Training, 'TrainingSettings'],
    ['training', OriginModuleEnum.Training, 'Training'],
    ['student', OriginModuleEnum.Student, 'Student'],
    ['supplier/settings', OriginModuleEnum.Supplier, 'SupplierSettings'],
    ['supplier', OriginModuleEnum.Supplier, 'Supplier'],

    // classic to vNext routes
    ['schoolgroup', OriginModuleEnum.SchoolGroup, 'SchoolGroup', 'legalstructure'],
    ['schoolgroupsettings', OriginModuleEnum.SchoolGroup, 'SchoolGroupSettings', 'legalstructure/settings'],
    ['school', OriginModuleEnum.School, 'School', 'branch'],
    ['schoolsettings', OriginModuleEnum.School, 'SchoolSettings', 'branch/settings'],
    ['buildingsettings', OriginModuleEnum.Building, 'BuildingSettings', 'building/settings'],
    ['room', OriginModuleEnum.Room, 'Room', 'Room', 'location'],
    ['roomsettings', OriginModuleEnum.Room, 'RoomSettings', 'location/settings'],
    ['labourmeanssettings', OriginModuleEnum.Labourmeans, 'LabourmeansSettings', 'labourmeans/settings'],
    ['equipmentsettings', OriginModuleEnum.EquipmentInstallation, 'EquipmentSettings', 'equipement/settings'],
    ['hazardoussubstancesettings', OriginModuleEnum.HazardousSubstance, 'HazardousSubstanceSettings', 'hazardoussubstance/settings'],
    ['protectionequipmentsettings', OriginModuleEnum.Pbm, 'ProtectionEquipmentSettings', 'protectionequipment/settings'],
    ['combinedinstallationsettings', OriginModuleEnum.CombinedInstallation, 'CombinedInstallationSettings', 'combinedinstallation/settings'],
    ['notificationdefect/createstart', OriginModuleEnum.NotificationDefect, 'NotificationDefect/CreateStart', 'taskrequest/create'],
    ['notificationdefect', OriginModuleEnum.NotificationDefect, 'NotificationDefect', 'taskrequest'],
    ['notificationdefectsettings', OriginModuleEnum.NotificationDefect, 'NotificationDefectSettings', 'taskrequest/settings'],
    ['tasksettings', OriginModuleEnum.Task, 'TaskSettings', 'task/settings'],
    ['periodicmaintenance', OriginModuleEnum.PeriodicMaintenance, 'PeriodicMaintenance', 'maintenance'],
    ['periodicmaintenancesettings', OriginModuleEnum.PeriodicMaintenance, 'PeriodicMaintenanceSettings', 'maintenance/settings'],
    ['periodiccontrol', OriginModuleEnum.PeriodicControl, 'PeriodicControl', 'inspection'],
    ['periodiccontrolsettings', OriginModuleEnum.PeriodicControl, 'PeriodicControlSettings', 'inspection/settings'],
    ['safetyinstructioncardsettings', OriginModuleEnum.SafetyInstructionCard, 'SafetyInstructionCardSettings', 'safetyinstructioncard/settings'],
    ['commissioningsettings', OriginModuleEnum.Commissioning, 'CommissioningSettings', 'commissioning/settings'],
    ['outofcommissioningsettings', OriginModuleEnum.OutOfCommissioning, 'OutOfCommissioningSettings', 'outofcommissioning/settings'],
    ['consultancy/creategeneralorpurchaseconsultancy', OriginModuleEnum.Consultancy, 'Consultancy/CreateGeneralOrPurchaseConsultancy', 'consultancy/create'],
    ['consultancy', OriginModuleEnum.Consultancy, 'Consultancy', 'advice'],
    ['consultancysettings', OriginModuleEnum.Consultancy, 'ConsultancySettings', 'advice/settings'],
    ['riskanalysissettings', OriginModuleEnum.RiskAnalysis, 'RiskAnalysisSettings', 'riskanalysis/settings'],
    ['incidentmanagement', OriginModuleEnum.IncidentManagement, 'IncidentManagement', 'incident'],
    ['incidentmanagementsettings', OriginModuleEnum.IncidentManagement, 'IncidentManagementSettings', 'incident/settings'],
    ['multiyearsettings', OriginModuleEnum.GlobalPreventionPlan, 'GlobalPreventionPlanSettings', 'multiyearplan/settings'],
    ['document', OriginModuleEnum.Document, 'Document', 'documentmanagement'],
    ['documentsettings', OriginModuleEnum.Document, 'DocumentSettings', 'documentmanagement/settings'],
    ['training/report', OriginModuleEnum.Training, 'Training/Report', 'training'],
    ['trainingsettings', OriginModuleEnum.Training, 'TrainingSettings', 'training/settings'],
    ['suppliersettings', OriginModuleEnum.Supplier, 'SupplierSettings', 'supplier/settings'],
  ];

  const routeMatches = (definition: string[], url: string) => {
    const definitionPath = (definition[0] || "").toLowerCase();
    const urlLowerCase = url.toLowerCase();
    const isMatch = definitionPath === urlLowerCase
      || urlLowerCase.startsWith(definitionPath + "/")
      || urlLowerCase.startsWith(definitionPath + "?");
    return isMatch;
  };

  const service = {
    redirectTo: (host: string, path: string, query: string) => {
      // Ignore redirection for vnext host so we can test vnext features without vnext feature flags.
      if (host === "vnext.arxs.be" || host === "localhost") {
        return;
      }

      path = trim(path || '', '/').toLowerCase();
      query = trim(query || '', '?').toLowerCase();

      // Classic paths should get picked up by nginx, so we should never arrive in this case
      if (path.startsWith(classicPrefix)) {
        arxs.logger.error(`Classic paths should have been redirected by reverse proxy: ${path}`);
        return;
      }

      const parts = path.split("/");
      const lastPart = parts[parts.length - 1];
      const querySuffix = query ? "?" + query : "";
      const queryMap = arxs.parseQuery(query);

      if (!vNextModules || vNextModules.length === 0) {
        return `/${classicPrefix}/${path}${querySuffix}`;
      } else {
        const match = routes.filter((definition) => routeMatches(definition, path))[0];
        if (match) {
          const [, module, classicPath, vNextPath] = match;
          const isModuleActiveInVNext = !!vNextModuleMap[module];
          if (!isModuleActiveInVNext) {
            if (arxs.uuid.isValid(lastPart)) {
              return `/${classicPrefix}/${classicPath}?IsFiltered=True&ObjectId=${lastPart}`;
            }
            return `/${classicPrefix}/${classicPath}${querySuffix}`;
          }
          if (vNextPath) {
            const objectId = queryMap["objectid"];
            if (objectId) {
              return `/${vNextPath}/${objectId}`;
            }
            const isClassicPath = queryMap["searchterm"] || queryMap["isfiltered"] || queryMap["objectid"];
            if (isClassicPath) {
              return `/${vNextPath}`;
            }
            return `/${vNextPath}${querySuffix}`;
          }
        } else {
          const queryMap = arxs.parseQuery(query);
          const uniqueNumber = queryMap['searchterm'];
          if (path === "search" && uniqueNumber) {
            const searchModule = arxs.modules.getModuleForUniqueNumber(uniqueNumber);
            if (vNextModuleMap[searchModule]) {
              return `/search/${uniqueNumber.toUpperCase()}`;
            } else {
              return `/${classicPrefix}/${path}${querySuffix}`;
            }
          }
        }
      }
    }
  };
  return service;
}