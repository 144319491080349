import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import arxs from "infra/arxs";
import GlobalContext from "infra/GlobalContext";
import { OriginModuleEnum, ObjectDocumentType, LinkType } from "infra/api/contracts";
import ErrorBoundary from "infra/errors/ErrorBoundary";

import { Image } from "components/controls/images/Image";
import { HorizontalSeparator } from "components/shell/HorizontalSeparator";
import PlanningProgress from "modules/planning/PlanningProgress";
import { createImagePreview } from "components/controls/images/ImagePreview";
import QR from "components/controls/QR";
import DropDownMenu from "components/controls/DropDownMenu";
import { Spinner } from "components/animations/Spinner";
import Wizard from "components/wizard/Wizard";
import Badge from "components/controls/Badge";
import Toaster from "components/util/Toaster";
import FormBadgePresenter from "components/controls/form/FormBadgePresenter";

import { Activities } from "./Activities";
import InformationTab from "./InformationTab";
import LinksTab from "./LinksTab";
import DocumentsTab from "./DocumentsTab";
import FormTab from "./FormTab";

import "./DetailsPane.scss";

export const DetailsPaneTabs = {
  info: "info",
  form: "form",
  activities: "activities",
  documents: "documents",
  links: "links",
};

const _linkWhitelistMap = [
  OriginModuleEnum.Labourmeans,
  OriginModuleEnum.EquipmentInstallation,
  OriginModuleEnum.IntangibleAsset,
  OriginModuleEnum.Pbm,
  OriginModuleEnum.HazardousSubstance,
  OriginModuleEnum.Consultancy,
  OriginModuleEnum.CombinedInstallation,
  OriginModuleEnum.RiskAnalysis,
  OriginModuleEnum.InstructionCard,
  OriginModuleEnum.SafetyInstructionCard,
  OriginModuleEnum.Commissioning,
  OriginModuleEnum.PeriodicControl,
  OriginModuleEnum.PeriodicMaintenance,
  OriginModuleEnum.RiskAnalysis,
  OriginModuleEnum.NotificationDefect,
  OriginModuleEnum.Task,
  OriginModuleEnum.Periodical,
  OriginModuleEnum.GlobalPreventionPlan,
  OriginModuleEnum.YearActionPlan,
  OriginModuleEnum.IncidentManagement,
  OriginModuleEnum.OutOfCommissioning,
  OriginModuleEnum.Project,
  OriginModuleEnum.Room
]
  .toDictionary(x => x);

class DetailsPane extends Component {
  lookups = {
    codeElementsById: {},
    reportsByAliasMap: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      module: null,
      metadata: {},

      fullScreen:
        arxs.parseURL(window.location.href).searchObject["fs"] === "1"
          ? true
          : false,

      pristine: {},
      data: {},
      selectedTabIndex: null,

      alternativeTitle: null,
      alternativeImage: null,

      // cardStack: [],
      // stackIndex: -1,
    };
  }

  getCodeElementValue = (ref, codeElementsById) => {
    if (!ref || !codeElementsById) return "";
    return this.getLookupValue(codeElementsById, ref);
  };

  getLookupValue = (lookup, ref) => {
    const match = lookup && ref && lookup[ref.id];
    return (match && match.name) || (ref && ref.name) || "";
  };

  loadAlternativeState = (module, card) => {
    this.setState({ alternativeImage: undefined, alternativeTitle: undefined });
    switch (module) {
      case OriginModuleEnum.IncidentManagement:
        const ref = card.victim;
        if (ref) {
          arxs
            .moduleMetadataRegistry
            .getResourceByModule(ref.module)
            .getById(ref.id)
            .then((victim) =>
              this.setState({
                alternativeTitle:
                  victim.name || `${victim.firstname} ${victim.surname}`,
                alternativeImage: (victim.images || [])[0],
              })
            );
        }
        break;
      case OriginModuleEnum.Contact:
        this.setState({
          alternativeTitle: card.firstname + " " + card.surname,
        });
        break;
      case OriginModuleEnum.Supplier:
      case OriginModuleEnum.HazardousSubstance:
      case OriginModuleEnum.Building:
      case OriginModuleEnum.Room:
        this.setState({ alternativeTitle: card.name });
        break;
      case OriginModuleEnum.IntangibleAsset:
      case OriginModuleEnum.Labourmeans:
      case OriginModuleEnum.EquipmentInstallation:
      case OriginModuleEnum.Pbm:
      case OriginModuleEnum.CombinedInstallation:
        const title = this.getCodeElementValue(
          card.type,
          this.state.codeElementsById
        );
        this.setState({ alternativeTitle: title });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups.subscribe(this.lookups, (lookups) =>
        this.setState({ ...lookups })
      ),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const prevCard = (prevProps.card || {});
    if (this.props.card !== prevCard) {
      const card = this.props.card;
      if (card && card.id !== prevCard.id) {
        this.loadCard(card);
      }
    } else {
      if (prevProps.selectedTab !== this.props.selectedTab) {
        const selectedTabIndex = this.getSelectedTabIndex(this.props.selectedTab, this.state.showDocumentsTab, this.state.showFormTab);
        this.setState({ selectedTabIndex });
      }
    }

    if (prevProps.fullScreen !== this.props.fullScreen) {
      this.setState({ fullScreen: this.props.fullScreen });
    }

    if (prevState.pristine !== this.state.pristine) {
      this.loadAlternativeState(module, this.state.pristine);
    }
  }

  getSelectedTabIndex = (selectedTab, showDocumentsTab, showFormTab) => {
    const metadata = arxs.moduleMetadataRegistry.get(this.state.module);
    if (selectedTab === DetailsPaneTabs.info) {
      return 0;
    } else if (selectedTab === DetailsPaneTabs.form && showFormTab) {
      return 1;
    } else if (selectedTab === DetailsPaneTabs.activities) {
      return showFormTab ? 2 : 1;
    } else if (selectedTab === DetailsPaneTabs.documents && showDocumentsTab) {
      return showFormTab ? 3 : 2;
    } else if (selectedTab === DetailsPaneTabs.links) {
      return showFormTab ? (showDocumentsTab ? 4 : 3) : 2;
    }
    return metadata.defaultDetailsTabIndex || 0;
  }

  loadCard = (card) => {
    const module = card.module;
    const metadata = arxs.moduleMetadataRegistry.get(module);

    if (this.subscriptions && this.subscriptions.lookups) {
      this.subscriptions.lookups.dispose();
    }

    const showDocumentsTab = metadata && metadata.allowedDocumentTypes.any((x) => x !== ObjectDocumentType.Image);

    this.setState(
      {
        card,
        module,
        metadata,
        showDocumentsTab,
        fullScreen: false,
        pristine: {},
        data: {},
      },
      () => {
        this.subscriptions = {
          lookups: metadata.base.subscribe(card.id, card.module, (x) => {
            const showFormTab = (Object.keys(x.formDefinition || {}).length > 0 || (this.state.module === OriginModuleEnum.Form && x.formDefinition));

            const selectedTabIndex = this.getSelectedTabIndex(this.props.selectedTab, showDocumentsTab, showFormTab);
        
            this.setState({ pristine: x, showFormTab, selectedTabIndex });
            this.loadAlternativeState(module, x);
          }),
        };
      }
    );
  }

  componentWillUnmount() {
    if (this.subscriptions && this.subscriptions.lookups) {
      this.subscriptions.lookups.dispose();
    }
  }

  edit = (context) => {
    if (context.platform.isMobile) {
      context.detailsPane.collapse();
    } else {
      if (this.state.fullScreen) {
        this.setState({ fullScreen: !this.state.fullScreen });
      }
    }
  };

  toggleFullscreen = () => {
    this.setState({ fullScreen: !this.state.fullScreen });
  };

  getFilteredActions = (actions) => {
    let requestedModuleActions = [];

    for (let action of actions || []) {
      if (action.indexOf(":") !== -1) {
        action = action.substring(0, action.indexOf(":"));
      }
      requestedModuleActions.push(action);
    }

    return requestedModuleActions;
  };

  handleSubscriptionChange = (value) => {
    this.setState({ data: { ...this.state.data, isSubscribed: value } });
  };

  handleAction = (action, context, cardId) => {
    const actionState = {
      context: context,
      ids: [cardId],
      history: this.props.history,
    };

    action.onClick(actionState);
    context.detailsPane.collapse();
  };

  render() {
    const card = this.state.card;
    const { metadata, reportsByAliasMap, showDocumentsTab, showFormTab } = this.state;
    const pristine = this.state.pristine || {};

    if (!card || !metadata || !metadata.actions) {
      return false;
    }

    const getValue = (key) =>
      this.state.data && this.state.data[key] !== undefined
        ? this.state.data[key]
        : pristine[key];

    const id = card.id;
    const module = card.module;
    const title = card.title || getValue("title");

    const { alternativeTitle, alternativeImage } = this.state;

    const description = card.description;
    const fullScreen = this.state.fullScreen;

    const attachmentInfo = pristine.attachmentInfo || {};

    const imageAttachments = ((
      ((attachmentInfo.attachments || []).filter(
        x => x.type === ObjectDocumentType.Image
      ) || [])[0] || {}
    ).value || []).filter(x => !x.isDeleted);

    const images = imageAttachments.map(x => (attachmentInfo.storedFiles || []).filter(y => y.id === x.id)[0]);

    const uniqueNumber = pristine.uniqueNumber;
    const moduleTitle = arxs.modules.titles[module];
    const moduleClassName = arxs.modules.icons[module];
    const statusTitle = arxs.statuses.titles[pristine.status];
    const statusClassName = arxs.getStatusClassName(module, pristine.status);
    const coverImage = alternativeImage || (attachmentInfo.storedFiles || []).filter(
      x => x.id === ((imageAttachments || [])[0] || {}).id
    )[0] || {};

    const isLoading = Object.keys(pristine).length === 0;

    const formValues = pristine.formValues || [];
    const formDefinition = pristine.formDefinition || {};

    const filteredActions = this.getFilteredActions(getValue("actions"));

    const canWrite = arxs.isActionAllowed(metadata.base.writeAction);
    const canCreate = canWrite || (metadata.base.createAction && arxs.isActionAllowed(metadata.base.createAction));
    const supportsDuplication = !(metadata.board.allowDuplicate === false);
    const canDuplicate = (canWrite || canCreate) && supportsDuplication;

    const canEdit = filteredActions.contains("edit");

    const isSubscribed = getValue("isSubscribed");

    const reportRefs = [{ module: module, objectId: id }];
    const filter = { refs: reportRefs };

    const reportActions = (metadata.board.reports || [])
      .filter((x) => !!x.alwaysOn === false)
      .filter(x => !x.filter || x.filter([pristine]))
      .map((report) => ({
        name: arxs.t(`report.${report.name}`),
        module: module,
        icon: "far fa-file-alt",
        getTitle: () => { return arxs.t(`report.${report.name}`) },
        onClick: (e) => {
          Toaster.notify(arxs.t("report.requested"));
          const template = reportsByAliasMap[report.name][0];
          arxs.ReportClient.reporting().generatePDF(
            {
              template: template,
              filters: [filter]
            },
            template.alias,
            card.uniqueNumber
          );
        },
      }));

    const actionItems = metadata.actions
      .filter(
        (x) =>
          x.name !== "reactivate" && x.name !== "edit" && x.name !== "archive"
      )
      .filter((x) => (x.applicableViews || []).length === 0)
      .filter((x) => filteredActions.contains(x.name))
      .filter((x) => x.module === module)
      .concat(reportActions);

    if (canDuplicate) {
      const duplicateAction = {
        name: "duplicate",
        module: module,
        icon: 'far fa-clone',
        getTitle: () => { return arxs.t("actions.duplicate") },
        onClick: () => this.props.history.push(`${metadata.base.route}/duplicate/${id}`)
      }

      actionItems.push(duplicateAction);
    }

    const imagePreview = (context) => {
      if (coverImage && coverImage.url) {
        const imageUrl = arxs.ImageProxy.resizeImage(coverImage.url, 140, 140);

        return (
          <Image
            src={imageUrl}
            alt={coverImage.name}
            className="preview"
            totalImages={images && images.length}
            onClick={() =>
              images &&
              images.length > 0 &&
              context.popup.show(createImagePreview(images))
            }
          />
        );
      }

      return <Image className="preview"></Image>;
    };

    const actionMenu = (context) => {
      if (actionItems && actionItems.length > 0) {
        return (
          <div className="details-pane-actions">
            <DropDownMenu
              id="details-pane-actions"
              className="section action icon"
              items={actionItems}
              state={{
                context: context,
                ids: [id],
                cards: [{ ...pristine, module }],
                history: this.props.history,
              }}
              onItemClick={context.detailsPane.collapse}
            >
              <span>{arxs.t("kanban.actions.title")}</span>
            </DropDownMenu>
          </div>
        );
      }
    };

    const statusBadge = (context) => {
      if (statusClassName) {
        return (
          <div className={`details-pane-status badge ${statusClassName}`}>
            {statusTitle}
          </div>
        );
      }
    };

    const renderDocumentLengthBadge = () => {
      const documentLength =
        ((attachmentInfo && ((attachmentInfo || {}).attachments || []).filter(
          (x) => ![ObjectDocumentType.Image, ObjectDocumentType.FormImage, ObjectDocumentType.FormDocument].includes(x.type)
        ))).flatMap(x => x.value).filter(x => !x.isDeleted).length;

      return documentLength > 0 ? (
        <Badge>{documentLength}</Badge>
      ) : (
        <Fragment></Fragment>
      );
    };

    const renderActivitiesBadge = () => {
      const { numberOfMessages } = this.state.pristine;
      return numberOfMessages > 0 ? (
        <Badge>{numberOfMessages}</Badge>
      ) : (
        <Fragment></Fragment>
      );
    };

    const renderLinkCountBadge = () => {
      const links = (pristine.inboundLinks || []).concat(pristine.outboundLinks || []);
      const count = links
        .filter(x => module === OriginModuleEnum.Room || x.type !== LinkType.TransientReference)
        .map(x => x.module)
        .filter(x => _linkWhitelistMap[x])
        .distinct()
        .length;

      return count > 0 ? (
        <Badge>{count}</Badge>
      ) : (
        <Fragment></Fragment>
      );
    };

    const renderFormBadge = () => {
      return <FormBadgePresenter
        card={pristine}
        module={module}
        render={({filledIn, total}) => total > 0 && <Badge>{`${filledIn}/${total}`}</Badge>}
        />;
    };

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div
            className={`details-pane-wrapper${fullScreen ? " fullscreen" : " "
              }${context.detailsPane.collapsed ? " collapsed" : ""}`}
          >
            {!fullScreen && (!context.platform.isMobile || context.detailsPane.collapsed) && (
              <div className="details-pane-collapser" onClick={context.detailsPane.toggleCollapse}>
                {context.detailsPane.collapsed ? (
                  <i className="fas fa-chevron-left"></i>
                ) : (
                  <i className="fas fa-chevron-right"></i>
                )}
              </div>
            )}

            <div className={`details-pane${fullScreen ? " fullscreen" : " "}`}>
              <div className="details-pane-toolbar">
                {!fullScreen && (
                  <div className="details-pane-toolbar-header">
                    <div className="details-pane-toolbar-header-module">
                      <h2>
                        <i className={moduleClassName}></i> {!context.platform.isPhone && moduleTitle}
                      </h2>
                    </div>
                    <div className="details-pane-toolbar-header-uniquenumber">
                      <h3>{uniqueNumber}</h3>
                    </div>
                  </div>
                )}
                <div className="spacer" />
                {<i className={`fas fa-chevron-left${context.detailsPane.canGoBack ? "" : " inactive"}`} onClick={context.detailsPane.back}></i>}
                {<i className={`fas fa-chevron-right${context.detailsPane.canGoForward ? "" : " inactive"}`} onClick={context.detailsPane.forward}></i>}
                {canEdit && (
                  <Link to={`${this.state.metadata.base.route}/edit/${id}`}>
                    <i
                      className="far fa-pencil"
                      onClick={() => this.edit(context)}
                      title={arxs.actions.titles["edit"]}
                    ></i>
                  </Link>
                )}
                {!context.platform.isMobile && (
                  <i
                    className={`${fullScreen
                      ? "fas fa-compress-arrows-alt"
                      : "fas fa-expand-arrows"
                      }`}
                    onClick={this.toggleFullscreen}
                  ></i>
                )}
                {context.platform.isMobile && (
                  <i className="far fa-times" onClick={context.detailsPane.collapse}></i>
                )}
              </div>
              <HorizontalSeparator />
              {this.state.module === OriginModuleEnum.Task && <PlanningProgress
                subject={{
                  module: this.state.module,
                  id: pristine.id,
                  estimatedDuration: pristine.estimatedDuration,
                }}
              />}
              {fullScreen ? (
                <div className="details-pane-header fullscreen">
                  <div className="details-pane-header-left">
                    <div className="details-pane-header-left-image">
                      {imagePreview(context)}
                    </div>
                    <div className="details-pane-header-left-identification">
                      <div className="details-pane-header-left-identification-title">
                        <h1> {alternativeTitle || title || description}</h1>
                      </div>
                      <div className="details-pane-header-left-identification-uniqueNumber">
                        <h3>
                          <i className={moduleClassName}></i>
                          {uniqueNumber}
                        </h3>
                      </div>
                    </div>
                    <div className="details-pane-header-left-status">
                      {statusBadge(context)}
                    </div>
                  </div>
                  <div className="details-pane-header-right">
                    <div className="details-pane-header-QR">
                      <QR
                        data={{
                          value: `${window.location.origin.toLowerCase()}/search/${uniqueNumber}`,
                          name: uniqueNumber,
                        }}
                        size={450}
                      />
                    </div>
                    {actionMenu(context)}
                  </div>
                </div>
              ) : (
                <div className="details-pane-header">
                  <div className="details-pane-header-left">
                    {statusBadge(context)}
                    <h2
                      className="details-pane-header-title"
                      title={alternativeTitle || title || description}
                    >
                      {alternativeTitle || title || description}
                    </h2>
                    {actionMenu(context)}
                  </div>
                  <div className="details-pane-header-right">
                    {imagePreview(context)}
                  </div>
                </div>
              )}
              <Tabs
                className="details-pane-tabs"
                selectedIndex={this.state.selectedTabIndex || 0}
                onSelect={(index) => this.setState({ selectedTabIndex: index })}
              >
                <TabList>
                  <Tab>
                    <i
                      className="fa fa-info"
                      title={arxs.t("details_pane.info")}
                    ></i>
                  </Tab>
                  {showFormTab && (
                    <Tab>
                      <i
                        className="far fa-clipboard-list-check"
                        title={arxs.t("details_pane.checklists")}
                      ></i>
                      {renderFormBadge()}
                    </Tab>
                  )}
                  <Tab>
                    <i
                      className="fa fa-comment-alt"
                      title={arxs.t("details_pane.activities")}
                    ></i>
                    {renderActivitiesBadge()}
                  </Tab>
                  {showDocumentsTab && (
                    <Tab>
                      <i
                        className="fa fa-copy"
                        title={arxs.t("details_pane.attachments")}
                      ></i>
                      {renderDocumentLengthBadge()}
                    </Tab>
                  )}
                  <Tab>
                    <i
                      className="fa fa-chart-network"
                      title={arxs.t("details_pane.links")}
                    ></i>
                    {renderLinkCountBadge()}
                  </Tab>
                </TabList>
                <TabPanel>
                  {isLoading && (
                    <div className="spinner-wrapper">
                      <Spinner />
                    </div>
                  )}
                  {!isLoading && (fullScreen ? (
                      <ErrorBoundary>
                        <div className="wizard-wrapper">
                          <Wizard
                            module={module}
                            data={pristine}
                            readOnly={true}
                          />
                        </div>
                      </ErrorBoundary>
                    ) : (
                      <ErrorBoundary>
                        <InformationTab
                          module={this.state.module}
                          definitions={this.state.metadata.detailspane}
                          pristine={pristine}
                        />
                      </ErrorBoundary>
                    ))}
                </TabPanel>
                {showFormTab && (
                  <TabPanel>
                    <ErrorBoundary>
                      <FormTab card={pristine} module={this.state.module} />
                    </ErrorBoundary>
                  </TabPanel>
                )}
                <TabPanel>
                  <ErrorBoundary>
                    <Activities
                      objectId={id}
                      module={module}
                      isSubscribed={isSubscribed}
                      onChange={this.handleSubscriptionChange}
                      showSubscriptionToggle={true}
                    />
                  </ErrorBoundary>
                </TabPanel>
                {showDocumentsTab && (
                  <TabPanel>
                    <ErrorBoundary>
                      <DocumentsTab
                        objectId={id}
                        module={module}
                        attachmentInfo={attachmentInfo}
                      />
                    </ErrorBoundary>
                  </TabPanel>
                )}
                <TabPanel>
                  <ErrorBoundary>
                    <LinksTab
                      uniqueNumber={pristine.uniqueNumber}
                      inboundLinks={pristine.inboundLinks}
                      outboundLinks={pristine.outboundLinks}
                      module={module}
                      navigateToCard={this.navigateToCard}
                    />
                  </ErrorBoundary>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
export default withRouter(DetailsPane);
