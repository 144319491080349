/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TaskStatus {
    InProcess = 'InProcess',
    OnHold = 'OnHold',
    Active = 'Active',
    Completed = 'Completed',
    ToVerify = 'ToVerify',
    ExecutionOverdue = 'ExecutionOverdue',
    MultiYearPlan = 'MultiYearPlan'
}

export function TaskStatusFromJSON(json: any): TaskStatus {
    return TaskStatusFromJSONTyped(json, false);
}

export function TaskStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskStatus {
    return json as TaskStatus;
}

export function TaskStatusToJSON(value?: TaskStatus | null): any {
    return value as any;
}

