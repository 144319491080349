import React from 'react';
import arxs from 'infra/arxs';

import "./SignatureDocumentNotAvailable.scss";

export default function SignatureDocumentNotAvailable(props) {
  return (
    <div className="signature-popup-document-not-available">
        <i className="far fa-hourglass fa-spin"></i>
        <div>
            {arxs.t("controls.signature.document_not_available")}
        </div>
    </div>
  );
}