import arxs from "infra/arxs";
import { LinkType, OriginModuleEnum } from "infra/api/contracts";
import BoardController from "modules/BoardController";

class PeriodicalBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const ref = card.reference && JSON.parse(card.reference) || {};
    const codeElementsById = stateProxy.getter("codeElementsById");
    const subjectRefs = (card.outboundLinks || []).filter(x => x.type === LinkType.Subject);
    const subjects = subjectRefs.map(arxs.Api.lookups.resolveSubject);
    const getLookupValueFor = (key) => (x) => this.getLookupValue(stateProxy.getter(key), x);
    const legalStructures = subjects.filter(x => x.module === OriginModuleEnum.SchoolGroup)
      .coalesceIfEmpty(subjects.map(x => x.legalStructure).filter(x => x))
      .map(getLookupValueFor("legalStructureMap"))      ;
    const branches = subjects.filter(x => x.module === OriginModuleEnum.School)
      .coalesceIfEmpty(subjects.map(x => x.branch).filter(x => x))
      .map(getLookupValueFor("branchMap"));
    const buildings = subjects.filter(x => x.module === OriginModuleEnum.Building)
      .coalesceIfEmpty(subjects.map(x => x.building).filter(x => x))
      .map(getLookupValueFor("buildingMap"));
    const locations = subjects.filter(x => x.module === OriginModuleEnum.Room)
      .coalesceIfEmpty(subjects.map(x => x.location).filter(x => x))
      .map(getLookupValueFor("locationMap"));
    const subjectUniqueNumber = subjects
      .filter(x => x.uniqueNumber)
      .map(x => x.uniqueNumber);

    return {
      ...card,
      responsible: this.getEmployeeValue(
        card.responsible,
        stateProxy.getter("employeeMap")
      ),
      title: ref.title,
      description: ref.description,
      sortId: ref.sort && ref.sort.id,
      kindId: ref.kind && ref.kind.id,
      typeId: ref.type && ref.type.id,
      kind: this.getCodeElementValue(ref.kind, codeElementsById),
      type: this.getCodeElementValue(ref.type, codeElementsById),
      subjectUniqueNumber,
      legalStructures,
      branches,
      buildings,
      locations,
      geoLocation: ref.geoLocation,
      referenceModule: arxs.modules.titles[card.referenceModule],
      createdAt: card.createdAt && new Date(card.createdAt)
    };
  }

  getCardSearchTerms(card) {
    return [
      card.legalStructures,
      card.branches,
      card.buildings,
      card.locations,
      card.title,
      card.uniqueNumber,
      card.responsible,
      card.sort,
      card.kind,
      card.type,
      card.description,
      card.subjectUniqueNumber,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.periodicals) {
        lookups.pristine = lookups.periodicals
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.periodicals;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }

  onDrop = (droppedStatus, item) => {
    arxs.logger("No drag & drop supported for Periodicals yet");
  };
}
export default new PeriodicalBoard();
