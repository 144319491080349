import React, { Component, Fragment } from 'react';
import DocumentOverview from 'components/controls/documents/DocumentOverview';

import './DocumentsTab.scss';

export default class DocumentsTab extends Component {
    render() {
        return <Fragment>
            <DocumentOverview
                key="Documents"
                module={this.props.module}
                attachmentInfo={this.props.attachmentInfo}
                objectId={this.props.objectId}
                readOnly
            />
        </Fragment>;
    }
}
