import WizardController from "modules/WizardController";
import arxs from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

class MultiYearPlanWizard extends WizardController {
  setDefaultOwnerValueIfNull = (stateProxy) => {
    return new Promise((resolve, reject) => {
      const data = stateProxy.getter("data");

      let { owner } = data;
      if (!owner) {
        const pristine = stateProxy.getter("pristine");
        const pristineOwner = (pristine || {}).owner;
        const { profile } = arxs.Identity;
        let newData = { ...data };
        if (pristineOwner) {
          delete newData["owner"];
          stateProxy.setter({ data: newData }, resolve);
        } else if (profile) {
          const newOwner = {
            id: profile.id,
            module: OriginModuleEnum.Employee,
          };
          stateProxy.setter({ data: { ...data, owner: newOwner } }, resolve);
        }
      } else {
        resolve();
      }
    });
  };
}
export default new MultiYearPlanWizard();
