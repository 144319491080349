import React from "react";
import CodeElementComponent from "./CodeElementComponent";
import DropDown from "../DropDown";

import "./CodeElementDropDown.scss";

class CodeElementDropDown extends CodeElementComponent {
  clearValue = () => {
    const { selected, selectedParents } = this.props;

    if (!selectedParents || Object.keys(selectedParents).length === 0) {
      if (selected && Object.keys(selected).length > 0) {
        this.props.onChange(null);
      }
    }
  };

  render() {
    const className = `code-element-dropdown ${this.props.className || ""}`;
    let items = this.state.items
      .flatMap((x) => x.items)
      .filter((x: any) => x.isActive);
    if (this.props.filter) {
      items = items.filter((x) =>
        this.props.filter ? this.props.filter(x) : true
      );
    }

    if (this.props.sortByCode) {
      items = items.orderBy((x) => x.code);
    } else {
      items = items.orderBy((x) => x.name);
    }
    const selected = this.props.selected;
    const value = selected && this.state.codeElementsById[selected.id];

    return (
      <DropDown
        className={className}
        selected={value}
        onChange={this.props.onChange}
        items={items}
        readOnly={this.props.readOnly}
      />
    );
  }
}
export default CodeElementDropDown;
