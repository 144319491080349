import AssetWizard from "../AssetWizard";

class LocationWizard extends AssetWizard {
  getFilteredBuildingCompartments = (stateProxy, value) => {
    const data = stateProxy.getter("data");
    const pristine = stateProxy.getter("pristine");
    const ref = data["building"] || pristine["building"] || {};
    return value && value.buildingId === ref.id;
  };
}
export default new LocationWizard();
