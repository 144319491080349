import React from "react";

import { CardClassProps } from "components/card/CardContracts";
import UniqueNumber from "components/card/UniqueNumber";
import CardBase from "components/card/CardBase";

export default function ActivityEntryCard(props: CardClassProps) {
    return CardBase(props, "far fa-file-signature", (card => <div>
        <UniqueNumber uniqueNumber={card.uniqueNumber} /> - {card.title}
      </div>
    ));
}