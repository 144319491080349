import { ArXsState } from "infra/arxs";
import {
  OriginModuleEnum,
  RelationshipType,
  ObjectDocumentType,
} from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import ActivityEntryWizard from "./ActivityEntryWizard";
import ActivityEntryActions from "./ActivityEntryActions";
import ActivityEntryBoard from "./ActivityEntryBoard";
import ActivityEntryCard from "./ActivityEntryCard";

const getActivityEntryMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.ActivityEntry;

  return {
    module,
    title: arxs.t("modules.activityentry"),
    icon: "fal fa-file-signature",
    path: "/activityentry",
    base: {
      name: "ActivityEntry",
      route: "/activityentry",
      getResource: () => arxs.Api.getResource("activityentry"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "ActivityEntry.Write",
      readAction: "ActivityEntry.Read",
    },
    settings: {
      name: "ActivityEntrySettings",
      route: "/activityentry/settings",
      getResource: () => arxs.Api.getResource("activityentry").settings(),
      readAction: "ActivityEntry.Settings.Read",
      writeAction: "ActivityEntry.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "title" },
          { name: "description", props: { markDown: true } },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [],
    actions: ActivityEntryActions.getActions(OriginModuleEnum.ActivityEntry),
    wizard: {
      wizardClass: ActivityEntryWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "context",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                  ],
                  overridePrefilter: true,
                  required: true,
                },
                onChange: (stateProxy) => {
                  ActivityEntryWizard.setScopeFields(stateProxy, "context");
                },
                onLoad: (stateProxy) => {
                  ActivityEntryWizard.getContext(stateProxy);
                },
                title: arxs.t("field.location"),
              },
              { name: "geoLocation" },
            ],
            [
              {
                name: "type",
                type: "codeelementlist",
                props: { code: "ActivityEntry.Kind" },
                parent: "kind",
                title: arxs.t("field.category"),
              },
            ],
            [{ name: "title" }],
            [{ name: "description", props: { markDown: true } }],
            [{ name: "tags", type: "tag" }],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = ["legalStructure", "branch"].map(getValue);
            return arxs.securityContext.buildForContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "startAt",
                type: "dateRange",
                title: `${arxs.t("field.startAt")} & ${arxs.t("field.endAt")}`,
                props: {
                  end: "endAt",
                  showTime: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.Assignee],
                },
              },
              {
                name: "participants",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                  condensed: true,
                },
                title: arxs.t("field.participants"),
              },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.supplier"),
              },
              {
                name: "contacts",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Contact],
                  setFilterOnField: "supplier",
                  singleSelection: true,
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                title: arxs.t("field.contact"),
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        contacts: [],
        contactMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
      },
    },
    board: {
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        tagMap: {},
        activityEntries: [],
        employeeMap: {},
        contactMap: {},
        supplierMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "title", width: "500px" },
        { name: "startAt" },
        { name: "endAt" },
        { name: "assignees" },
        { name: "participants" },
        { name: "supplier" },
        { name: "owner" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: false,
      },
      boardClass: ActivityEntryBoard,
      views: [BoardViewType.Grid, BoardViewType.Tree, BoardViewType.Map],
    },
    cardClass: ActivityEntryCard,
  };
};
export default getActivityEntryMetadata;
