import React from 'react';
import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';
import { ObjectDocumentType, SignatoryType } from 'infra/api/contracts';

class DocumentSigningWizardController extends WizardController {
    validateSignature(getCurrentFieldValue, preValidation) {
        const needsSignature = getCurrentFieldValue("needsSignature");
        if (needsSignature) {
            const signatories = getCurrentFieldValue("signatories");
            const hasSigningPreventionAdvisor = (signatories || []).filter(x => x.type === SignatoryType.PreventionAdvisor).length === 1;
            if (!hasSigningPreventionAdvisor) {
                preValidation["signingPreventionAdvisor"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.signingPreventionAdvisor`) }) };
            }
        }
    }

    onDocumentsChange = (stateProxy) => {
        const attachmentInfo = stateProxy.getField("attachmentInfo") || {};
        const attachments = attachmentInfo.attachments || [];
        const hasMainDocument = attachments.some(x => x.type === ObjectDocumentType.MainDocument && (x.value || []).some(y => !y.isDeleted));
        stateProxy.setFieldProps("formDefinition", {
            readOnly: hasMainDocument,
            readOnlyMessage: hasMainDocument ? <>
                <i className="far fa-exclamation-triangle"></i>
                {arxs.t("wizard.validation.when_a_main_document_is_present_no_form_can_be_provided")}
            </> : null
        });
    }

    onFormChange = (stateProxy) => {
        const formDefinition = stateProxy.getField("formDefinition") || {};
        const sections = formDefinition.sections || [];
        const hasFormSections = sections.length > 0;
        stateProxy.setFieldProps("documents", {
            readOnlyTypes: hasFormSections
                ? [{
                    type: ObjectDocumentType.MainDocument,
                    message: <>
                        <i className="far fa-exclamation-triangle"></i>
                        {arxs.t("wizard.validation.when_a_form_is_present_no_main_document_can_be_provided")}
                    </>
                }]
                : []
        });
    }
}
export default DocumentSigningWizardController;