import React, { Fragment, useState, useEffect } from "react";
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { OriginModuleEnum, RelationshipType } from "infra/api/contracts";
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import 'infra/DateExtensions';
import { Card } from "components/card/Card";

import "./PlanningList.scss";
import Avatar from "components/controls/images/Avatar";
import CardList from "components/controls/cardlist/CardList";

export default function PlanningList(props) {
  const lookups = {
    employeeMap: {},
  };

  const [employeeMap, setEmployeeMap] = useState({});

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        if (values.employeeMap) {
          setEmployeeMap(values.employeeMap);
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  const handleClick = (x) => {
    const card = (x.module === OriginModuleEnum.Task)
      || (x.module === OriginModuleEnum.PeriodicMaintenance)
      || (x.module === OriginModuleEnum.PeriodicControl)
      || (x.module === OriginModuleEnum.RiskAnalysis)
      ? x
      : arxs.Api.lookups.resolveSubject(x.subject);
    props.onSelectCard(null);
    props.onSelectCard(card);
  };

  const renderTaskInfo = (context, task) => {
    const { priority } = task;

    const priorityClassName = priority && `priority-${priority.toLowerCase()}`;
    const priorityValue = priority && arxs.enums.getTitle("TaskPriorityEnum", priority);

    const assignees = (task.relationships || [])
      .filter(x => x.type === RelationshipType.Assignee)
      .filter(x => x.employee)
      .map(x => x.employee);

    const renderTargetDateAndPlanning = () => {
      if (task.start && task.end) {
        return <>
          <div className="list-card-timestamp">{renderDateTimeRange(task.start, task.end)}</div>
          <div className="list-card-bullet">•</div>
        </>;
      }
      if (task.targetDate) {
        return <>
          <div className="list-card-timestamp">{renderDate(task.targetDate)}</div>
          <div className="list-card-bullet">•</div>
        </>;
      }
      return <></>;
    };

    const renderDate = (date) => {
      if (date.withoutTime() < (new Date()).withoutTime()) {
        return <div className="list-card-timestamp">
          <div className="planning-list-timestamp-overtime">
            <i className="fas fa-exclamation-triangle"></i>{arxs.dateTime.formatDate(date)}
          </div>
        </div>
      }
      return <div className="list-card-timestamp"><i class="far fa-clock"></i> {arxs.dateTime.formatDate(date)}</div>;
    };

    const renderDateTimeRange = (start, end) => {
      const duration = (end.getTime() - start.getTime()) / 3.6e6;
      const durationStr = duration.toFixed(1);
      if (end < new Date()) {
        return <div className="planning-list-timestamp-overtime"><i className="fas fa-exclamation-triangle"></i>{arxs.dateTime.formatDateTime(start)} - <i class="far fa-stopwatch"></i>{durationStr}{arxs.t("unit.h")}</div>
      }
      return <>
        <i class="far fa-clock"></i> {arxs.dateTime.formatDateTime(start)} - <i class="far fa-stopwatch"></i> {durationStr}{arxs.t("unit.h")}
      </>;
    };

    const renderTargetDate = (task) => {
      const date = task.targetDate;
      if (!date) {
        return <></>;
      }
      return <div className="list-card-target-date">{arxs.dateTime.formatDate(date)}</div>;
    };

    return <div className="planning-list-column-body">
      <div className="planning-list-column-counters">
        {renderTargetDateAndPlanning()}
        <div className="list-card-uniquenumber">{task.uniqueNumber}</div>
        {!context.platform.isPhone && task.numberOfMessages > 0 && <>
          <div className="list-card-bullet">•</div>
          <i className="fas fa-comment-alt"></i>
          <div className="list-card-activity-count">{task.numberOfMessages}</div>
        </>}
        {!context.platform.isPhone && task.numberOfImages > 0 && <>
          <div className="list-card-bullet">•</div>
          <i className="fas fa-images"></i>
          <div className="list-card-image-count">{task.numberOfImages}</div>
        </>}
        <div className="list-card-bullet">•</div>
        <div className={`list-card-priority badge ${priorityClassName}`}>{priorityValue}</div>
        {!context.platform.isPhone && task.targetDate && <div className="list-card-bullet">•</div>}
        {!context.platform.isPhone && renderTargetDate(task)}
      </div>

      <div className="planning-list-column-details">
        <div className="planning-list-column-title">{task.title}</div>
        <CardList
            readOnly={true}
            value={task.subjects}
            undraggable
            showOpenInTab
          />
      </div>
      <div className="planning-list-column-assignees">
        {assignees.map((x, i) => (<div key={`x.id-${i}`}><Avatar key={x.id} userId={x.id} /> {(employeeMap && (employeeMap[x.id] || {})).name}</div>))}
      </div>
    </div>;
  };

  const renderItem = (context, x) => {
    switch (x.module) {
      case OriginModuleEnum.Task:
      case OriginModuleEnum.PeriodicMaintenance:
      case OriginModuleEnum.PeriodicControl:
      case OriginModuleEnum.RiskAnalysis:
        return renderTaskInfo(context, x);
      default: return <Fragment />;
    }
  };

  return <GlobalContext.Consumer>
    {
      (context) =>
        <div className="planning-list" id="planning-list">
          {props.data.map((x, i) => <Fragment key={`x.id-${i}`}><div key={x.id}
            className="planning-list-columns"
            onClick={() => context.detailsPane.open(x)}
          >
            {renderItem(context, x)}
          </div>
            <HorizontalSeparator />
          </Fragment>)}
        </div>
    }
  </GlobalContext.Consumer>;
}
