import React, { Component } from 'react';
import seedrandom from 'seedrandom';

import './StarGrid.scss';

export class StarGrid extends Component {
  constructor(props) {
    super(props);

    this.updateDimensions = this.updateDimensions.bind(this);

    const seed = "" + Math.random();
    const width = window.innerWidth;
    const height = window.innerHeight;

    this.state = {
      width,
      height,
      seed,
      timer: null,
      starGrid: this.generateStarGrid(seed, width, height),
    };
  }

  updateDimensions() {
    // Updates the dimensions only when the user has stopped resizing the window for 100ms
    // Otherwise it becomes pretty laggy...
    let timer = this.state.timer;
    const seed = this.state.seed;
    const width = window.innerWidth;
    const height = window.innerHeight;

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      this.setState({ width, height, starGrid: this.generateStarGrid(seed, width, height), timer: null });
    }, 100);

    this.setState({ timer });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  generateStarGrid(seed, width, height) {
    // REMARK: This animation can hog a CPU thread, so anyone leaving this open for an extended amount of time on the login screen will draw more power than desirable
    // We inject a random seed to get deterministic random numbers per row.
    // This allows us to resize the window and recalculate the grid maintaining the highlighted stars we currently have
    const random = seedrandom(seed);
    const generatePoints = () => {
      const points = [];
      const step = 44;
      let index = 0;
      for (let y = 0; y < height / step; y++) {
        const rowRandom = seedrandom("" + random());
        for (let x = 0; x < width / step; x++) {
          const xx = 14 + x * step + ((y % 2) * 22);
          const yy = 23 + y * step;
          const delay = Math.floor((rowRandom() - 1) * 200000);
          const duration = Math.floor(100000 + rowRandom() * 100000);

          points.push(<g key={`s-${index}`}>
            <rect className="star" x={xx - 2} y={yy} width={4} height={4} style={{ opacity: 0, fill: "white", "--star-animation-delay": `${delay}ms`, "--star-animation-duration": `${duration}ms` }}></rect>
          </g >);

          index++;
        }
      }
      return points;
    };

    const starGridPoints = generatePoints();
    const starGrid = (<svg className="star-grid" preserveAspectRatio="xMinYMin slice" width="100%" height="100%">
      <defs>
        <pattern id="star" y="23" width="44" height="88" patternUnits="userSpaceOnUse">
          <rect x="11" y="0" width="4" height="4" style={{ opacity: 0.2, fill: "white" }}></rect>
          <rect x="33" y="44" width="4" height="4" style={{ opacity: 0.2, fill: "white" }}></rect>
        </pattern>
      </defs>
      <g>
        <rect width="100%" height="100%" fill="url(#star)" />
        {starGridPoints}
      </g>
    </svg>);
    return starGrid;
  }

  render() {
    return this.state.starGrid;
  }
}