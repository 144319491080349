import React, { Component, Fragment } from "react";
import Avatar from "components/controls/images/Avatar";
import ItemSelector from "components/controls/ItemSelector";

import "./EmployeeSelector.scss";

export default class EmployeeSelector extends Component {
  renderItem(item) {
    return <Fragment>
      <Avatar key={item.id} userId={item.id} fullName={item.name}/>
      <span>{item.name}</span>
    </Fragment>;
  }

  render() {
    return <ItemSelector
      className="employee-selector-item"
      data={this.props.data}
      selected={this.props.selected}
      onChange={this.props.onChange}
      placeholder={this.props.placeholder}
      renderItem={this.renderItem}
    />;
  }
}