/**
 * Helper Class for the URL handling.
 *
 * @class
 */
export class UrlUtil {
  /**
   * Transforms an object into a string containing requestParams (without
   * leading questionmark).
   *
   * @param {Object} object An object containing kvp for the request.
   *                        e.g. {height:400, width:200}
   * @return {string} The kvps as a requestString. e.g. 'height=400&width=200'
   */
  static objectToRequestString(object: any) {
    const requestString = Object.keys(object).map((key: string) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(object[key]);
    }).join('&');

    return requestString;
  }
}

export default UrlUtil;
