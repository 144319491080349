import React from "react";
import arxs from "infra/arxs";
import { CardClassProps } from "components/card/CardContracts";

import "./CardSignature.scss";

export default function CardSignature(props: CardClassProps) {
    const card = props.card;
    const signatures = (card.signatures || []).map((x: any) => x.userId).distinct();
    const signatories = (card.signatories || []).filter((x: any) => x.employee).map((x: any) => x.employee.id).distinct();
    const count = signatories.length - signatories.except(signatures).length;
    const userId = arxs!.Identity!.profile!.id;
    const isInToSignState = card.status === "ToSign";
    const userHasSigned = signatures.contains(userId);
    const userIsSignatory = signatories.contains(userId);

    return <>
        {isInToSignState && userIsSignatory && (userHasSigned
            ? <div className="has-signed"><i className="fas fa-check"></i>{" "}{arxs.t("module.common.card.signature.has_been_signed")}</div>
            : <div className="must-sign"><i className="fas fa-signature"></i>{" "}{arxs.t("module.common.card.signature.must_sign")}</div>)}
        {signatories.length > 0 && <div>
            <i className="fas fa-signature"></i>{" "}
            {count} / {signatories.length}
        </div>}
    </>;
}