/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttachmentType {
    StoredFile = 'StoredFile',
    Document = 'Document',
    Weblink = 'Weblink',
    Comment = 'Comment'
}

export function AttachmentTypeFromJSON(json: any): AttachmentType {
    return AttachmentTypeFromJSONTyped(json, false);
}

export function AttachmentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachmentType {
    return json as AttachmentType;
}

export function AttachmentTypeToJSON(value?: AttachmentType | null): any {
    return value as any;
}

