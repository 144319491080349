const _getControlByType = (items, controlMap, type) => items.filter(x => controlMap[x.control].type === type)

export default function buildFormValuesHelper(metadata) {
  if (!metadata || Object.keys(metadata).length === 0) {
    return null;
  }

  const controlMap = (metadata.controls || []).toDictionary(x => x.id);
  const checkboxItems = _getControlByType(metadata.items, controlMap, "checkbox");
  const selectItems = _getControlByType(metadata.items, controlMap, "select");

  const markItemOk = (newValues, item, defaultValue) => {
    let dataItem = {};
    if (newValues.some(x => x.id === item.id)) {
      dataItem = newValues.filter(x => x.id === item.id)[0];
    } else {
      dataItem = { id: item.id };
      newValues.push(dataItem);
    }

    if (dataItem.value === undefined) {
      dataItem.value = defaultValue;
    }
  };

  return {
    areItemsAllFilledIn: (values) => {
      return !checkboxItems
        .concat(selectItems)
        .map(item => values.filter(x => x.id === item.id)[0])
        .any(dataItem => !dataItem || dataItem.value === undefined);
    },
    canMarkAllOk: () => {
      return !!checkboxItems || !!selectItems;
    },
    markAllOk: (values) => {
      let newValues = values.slice(0);
      
      for (var item of checkboxItems) {
        markItemOk(newValues, item, true);
      }
      
      for (var item of selectItems) {
        markItemOk(newValues, item, "ok");
      }

      return newValues;
    }
  };
}