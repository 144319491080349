/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmployeeStatus {
    Active = 'Active',
    OutOfService = 'OutOfService',
    Sick = 'Sick'
}

export function EmployeeStatusFromJSON(json: any): EmployeeStatus {
    return EmployeeStatusFromJSONTyped(json, false);
}

export function EmployeeStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeStatus {
    return json as EmployeeStatus;
}

export function EmployeeStatusToJSON(value?: EmployeeStatus | null): any {
    return value as any;
}

