import AssetWizard from 'modules/assetmanagement/AssetWizard'

class HazardousSubstanceWizard extends AssetWizard {

    setSentenceInfo = (stateProxy, fieldName) => {
        return new Promise((resolve, reject) => {
          switch (fieldName) {
            case "preventionSentences":
              return this.setPSentenceInfo(stateProxy).then(resolve).catch(reject);
            case "hazardSentences":
              return this.setHSentenceInfo(stateProxy).then(resolve).catch(reject);
            default:
              return resolve(); // Explicitly resolving the promise
          }
        });
      };
      
      setHSentenceInfo = (stateProxy) => {
        return new Promise((resolve, reject) => {
          const data = stateProxy.getter("data");
          const hazardSentenceMap = stateProxy.getter("hazardSentenceMap");
      
          const getValue = this.buildGetValue(stateProxy);
      
          const sentences = getValue("hazardSentences");
      
          if (sentences && Array.isArray(sentences) && hazardSentenceMap) {
            let newSentences = sentences;
      
            for (let sentence of newSentences) {
              if (sentence.id) {
                const info = hazardSentenceMap[sentence.id] || {};
                sentence.description = info.description;
                sentence.dangerClass = info.dangerClass;
                sentence.dangerCategory = info.dangerCategory;
              }
            }
      
            // Wait for the state update to complete before resolving
            stateProxy.setter({ data: { ...data, hazardSentences: newSentences } }, () => resolve());
          } else {
            // Resolve even if no changes are needed
            resolve();
          }
        });
      };
      
      setPSentenceInfo = (stateProxy) => {
        return new Promise((resolve, reject) => {
          const data = stateProxy.getter("data");
          const preventionSentenceMap = stateProxy.getter("preventionSentenceMap");
      
          const getValue = this.buildGetValue(stateProxy);
      
          const sentences = getValue("preventionSentences");
      
          if (sentences && Array.isArray(sentences) && preventionSentenceMap) {
            let newSentences = sentences;
      
            for (let sentence of newSentences) {
              if (sentence.id) {
                const info = preventionSentenceMap[sentence.id] || {};
                sentence.description = info.description;
              }
            }
      
            // Wait for the state update to complete before resolving
            stateProxy.setter({ data: { ...data, preventionSentences: newSentences } }, () => resolve());
          } else {
            // Resolve even if no changes are needed
            resolve();
          }
        });
      };
      

}
export default new HazardousSubstanceWizard();
