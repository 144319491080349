import arxs from "infra/arxs";
import StringExtensions from "infra/tools/StringExtensions";

export default function buildSearchTermFilter(searchTerm) {
    if (!searchTerm) {
      return () => true;
    }

    const rawTokens = StringExtensions
      .parseQuotes(searchTerm.toLowerCase())
      .flatMap(token => token.type === "text" ? token.value.match(/\S+/g) : [token.value])
      .filter(x => x)
      ;

    const tokens = [];
    let filters = [];
    for (let i = 0; i < rawTokens.length; i++) {
      const token = rawTokens[i];
      if (token.startsWith(`${arxs.searchTokens.link}:`)) {
        let value = token.substring(5);
        if (!value && i + 1 < rawTokens.length) {
          i++;
          value = rawTokens[i];
        }
        if (value) {
          const module = arxs.modules.getModuleForUniqueNumber(value);
          if (!module) continue;

        //   this.linkSubscriptions = this.linkSubscriptions || {};
        //   const linkSubscription = this.linkSubscriptions[module.toLowerCase()];
        //   if (!linkSubscription) {
        //     this.linkSubscriptions[module.toLowerCase()] = arxs.Api.lookups.subscribeToResourceByModule(module, () => {
        //       const searchCriteria = this.parseSearchCriteria(searchTerm);
        //       this.setState({ searchCriteria }, this.refresh);
        //     });
        //   }

          const match = arxs.Api.lookups.resolveSubjectByUniqueNumber(value);
          if (match) {
            filters.push(card => {
              const links = (card.inboundLinks || []).concat(card.outboundLinks || []);
              return links.any(x => x.id === match.id && x.module === module);
            });
          }
        }
      } else if (token.startsWith(`${arxs.searchTokens.status}:`)) {
        let value = token.substring(7);
        if (!value && i + 1 < rawTokens.length) {
          i++;
          value = rawTokens[i];
        }
        if (value) {
          value = value.toLowerCase();
          filters.push(card => (card.status || "").toLowerCase() === value);
        }
      } else if (token.startsWith(`${arxs.searchTokens.action}:`)) {
        let value = token.substring(7);
        if (!value && i + 1 < rawTokens.length) {
          i++;
          value = rawTokens[i];
        }
        if (value) {
          value = value.toLowerCase();
          filters.push(card => (card.actions || []).map(a => a.split(":")[0]).includes(value));
        }
      } else if (token === arxs.searchTokens.subscribed) {
        filters.push(card => !!card.isSubscribed);
      } else {
        tokens.push(token);
      }
    }

    return (card, cardSearchTerms) => {
        const matchTerms = cardSearchTerms.filter(x => x && x.toLowerCase).map(x => x.toLowerCase());
        const hasMatchingWildcard = tokens.all(word => matchTerms.some(match => match.indexOf(word) > -1));
        return hasMatchingWildcard && (filters.length === 0 || filters.all(filter => filter(card)));
    };
};