import React from "react";

export default function ValidationSummary(props) {
  const { data } = props;

  const renderKey = ([key, result]) => {
    // const label = arxs.t(`field.${key}`);

    if (!Array.isArray(result)) {
      return <li key={`validation-${key}`}>{result.error}</li>;
    }

    return result.flatMap((x, i) => Object.entries(x)
      .map(([childKey, childResult]) => <li key={`validation-${key}-${i}-${childKey}`}>{childResult.error}</li>))
  };

  return Object.keys(data).length > 0 && <ul className="validation-summary">
    {Object.entries(data).map(renderKey)}
  </ul>;
}