/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum FormStatus {
    InProcess = 'InProcess',
    Active = 'Active',
    Expired = 'Expired',
    ToExpire = 'ToExpire'
}

export function FormStatusFromJSON(json: any): FormStatus {
    return FormStatusFromJSONTyped(json, false);
}

export function FormStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormStatus {
    return json as FormStatus;
}

export function FormStatusToJSON(value?: FormStatus | null): any {
    return value as any;
}

