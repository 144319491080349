import arxs from 'infra/arxs';
import { OriginModuleEnum } from './api/contracts';

export default class Lookups {
  state = {
    data: {},
    subscriptions: []
  }

  constructor(subscribeToEndpoint) {
    this.subscribeToEndpoint = subscribeToEndpoint;
    this.subscribeForKeys = this.getSubscribers();
    this.loadedEndpoints = {};
    this.moduleEndpoints = this.getModuleEndpoints();
  }

  getLookup = (key) => {
    const data = this.state.data || {};
    return data[key] || {};
  }

  updatePartionedLookups = (key, value, subKey) => {
    const { data } = this.state;
    const prevValue = data[key] || {};
    prevValue[subKey] = value;
    this.updateLookups(key, prevValue);
  }

  updateLookups = (key, value) => {
    this.state.data = this.state.data || {};
    this.state.data[key] = value;

    for (const subscription of this.state.subscriptions) {
      if (subscription.keyMap[key]) {
        subscription.handler({ [key]: value });
      }
    }
  }

  resolveModuleMap = (module) => {
    const data = this.state.data;

    switch (module) {
      case OriginModuleEnum.SchoolGroup: return data.legalStructureMap;
      case OriginModuleEnum.School: return data.branchMap;
      case OriginModuleEnum.Building: return data.buildingMap;
      case OriginModuleEnum.Room: return data.locationMap;
      case OriginModuleEnum.Labourmeans: return data.labourmeanMap;
      case OriginModuleEnum.EquipmentInstallation: return data.equipmentMap;
      case OriginModuleEnum.IntangibleAsset: return data.intangibleAssetMap;
      case OriginModuleEnum.Pbm: return data.protectionEquipmentMap;
      case OriginModuleEnum.HazardousSubstance: return data.hazardousSubstanceMap;
      case OriginModuleEnum.CombinedInstallation: return data.combinedInstallationMap;
      case OriginModuleEnum.Task: return data.taskMap;
      case OriginModuleEnum.PeriodicControl: return data.inspectionMap;
      case OriginModuleEnum.PeriodicMaintenance: return data.maintenanceMap;
      case OriginModuleEnum.NotificationDefect: return data.taskRequestMap;
      case OriginModuleEnum.Employee: return data.employeeMap;
      case OriginModuleEnum.UserRole: return data.userRoleMap;
      case OriginModuleEnum.ActivityEntry: return data.activityEntryMap;
      case OriginModuleEnum.Contact: return data.contactMap;
      case OriginModuleEnum.Student: return data.studentMap;
      case OriginModuleEnum.IncidentManagement: return data.incidentMap;
      case OriginModuleEnum.Document: return data.documentMap;
      case OriginModuleEnum.Planning: return data.planningMomentsMap;
      case OriginModuleEnum.Project: return data.projectMap;
      case OriginModuleEnum.Supplier: return data.supplierMap;
      case OriginModuleEnum.Report: return data.reportDefinitionMap;
      case OriginModuleEnum.Form: return data.formMap;
      case OriginModuleEnum.Periodical: return data.periodicalMap;
      case OriginModuleEnum.Consultancy: return data.recommendationMap;
      case OriginModuleEnum.GlobalPreventionPlan: return data.multiYearPlanMap;
      case OriginModuleEnum.InstructionCard: return data.instructionCardMap;
      case OriginModuleEnum.SafetyInstructionCard: return data.safetyInstructionCardMap;
      case OriginModuleEnum.Commissioning: return data.commissioningMap;
      case OriginModuleEnum.OutOfCommissioning: return data.decommissioningMap;
      case OriginModuleEnum.RiskAnalysis: return data.riskAnalysisMap;
      case OriginModuleEnum.CodeElement: return data.codeElementMap;
      case OriginModuleEnum.Tag: return data.tagMap;
      default:
        console.log("Lookups.resolveSubject is missing switchcase for " + module);
    }
  }

  resolveSubject = (subject) => {
    const map = this.resolveModuleMap(subject.module);
    return (map && map[subject.id]) || {};
  }

  resolveSubjectOrDefault = (subject) => {
    const map = this.resolveModuleMap(subject.module);
    return map ? map[subject.id] : null;
  }

  resolveSubjectByUniqueNumber = (uniqueNumber) => {
    const module = arxs.modules.getModuleForUniqueNumber(uniqueNumber);
    const items = this.resolveModuleItems(module);
    return items.firstOrDefault(x => x.uniqueNumber && x.uniqueNumber.toLowerCase() === uniqueNumber.toLowerCase());
  }

  flattenCodeElementHierarchy = (items) => {
    /*
      "Tag.Kind": {
          "key": "Tag.Kind",
          "hierarchyType": "KindAndType",
          "groups": ["EmptyGroup"],
          "name": "Soort",
          "module": "Tag",
          "children": [{
                  "key": "Tag.Type",
                  "hierarchyType": "Nested",
                  "groups": [],
                  "name": "Type",
                  "module": "Tag"
              }
          ]
      }
  */
    const flatten = (item) => {
      if (item.hierarchyType === arxs.hierarchyTypes.sortAndKindAndType) {
        const sort = {
          code: item.key,
          module: item.module,
          hierarchyType: item.hierarchyType,
          name: arxs.codeElementTypes.sort,
          title: arxs.t("code_elements.sort")
        };

        arxs.assert(() => item.children && item.children.length > 0, `Expected ${item.key} to have children.`);

        const kindItem = item.children[0];
        const kind = {
          code: kindItem.key,
          module: item.module,
          hierarchyType: kindItem.hierarchyType,
          name: arxs.codeElementTypes.kind,
          title: arxs.t("code_elements.kind"),
          root: sort.code,
          parent: sort.code,
        };

        const groups = (item.groups || []);
        let types;
        if (!kindItem.children || kindItem.children.length === 0) {
          types = groups
            .reduce((acc, group) => {
              return [...acc, {
                code: group,
                module: item.module,
                hierarchyType: kindItem.hierarchyType,
                name: arxs.codeElementTypes.type,
                title: arxs.t("code_elements.type"),
                root: sort.code,
                parent: kind.code,
                group: group
              }];
            }, []);
        } else {
          const typeItem = kindItem.children[0];
          types = [{
            code: typeItem.key,
            module: item.module,
            hierarchyType: typeItem.hierarchyType,
            name: arxs.codeElementTypes.type,
            title: arxs.t("code_elements.type"),
            root: sort.code,
            parent: kind.code,
            group: groups.length > 0 ? groups[0] : null
          }];
        }

        return [sort, kind, ...types];
      }

      if (item.hierarchyType === arxs.hierarchyTypes.kindAndType) {
        const kind = {
          code: item.key,
          module: item.module,
          hierarchyType: item.hierarchyType,
          name: arxs.codeElementTypes.kind,
          title: arxs.t("code_elements.kind"),
        };

        arxs.assert(() => item.children && item.children.length > 0, `Expected ${item.key} to have children.`);

        const groups = (item.groups || []);
        const typeItem = item.children[0];
        const type = {
          code: typeItem.key,
          module: item.module,
          hierarchyType: typeItem.hierarchyType,
          name: arxs.codeElementTypes.type,
          title: arxs.t("code_elements.type"),
          root: kind.code,
          parent: kind.code,
          group: groups.length > 0 ? groups[0] : null
        };

        return [kind, type];
      }

      const flat = {
        code: item.key,
        module: item.module,
        hierarchyType: item.hierarchyType,
        name: item.name,
        title: item.name
      };
      return [flat];
    };

    const schemas = Object.values(items)
      .filter(item => item.key !== "TypeGroup" && item.key !== "NormGroup")
      .flatMap(item => flatten(item))
      .toDictionary(x => x.code);

    return schemas;
  }

  mapCodeElementsToHierarchy = (codeElements) => {
    const roots = codeElements.filter(x => !x.parentId && x.code);
    const byParentId = codeElements.filter(x => x.parentId).groupBy(x => x.parentId);

    const getChildren = (parentId, byParentId) => {
      const children = byParentId[parentId] || [];

      for (const child of children) {
        child.children = getChildren(child.id, byParentId);
      }

      return children.orderBy(x => x.name);
    };

    for (const root of roots) {
      root.children = getChildren(root.id, byParentId);
    }

    return codeElements
  }

  resolveModuleItemsKey = (module) => {
    switch (module) {
      case OriginModuleEnum.SchoolGroup: return "legalStructures";
      case OriginModuleEnum.School: return "branches";
      case OriginModuleEnum.Building: return "buildings";
      case OriginModuleEnum.Room: return "locations";
      case OriginModuleEnum.Labourmeans: return "labourmeans";
      case OriginModuleEnum.EquipmentInstallation: return "equipments";
      case OriginModuleEnum.IntangibleAsset: return "intangibleAssets";
      case OriginModuleEnum.Pbm: return "protectionEquipments";
      case OriginModuleEnum.HazardousSubstance: return "hazardousSubstances";
      case OriginModuleEnum.CombinedInstallation: return "combinedInstallations";
      case OriginModuleEnum.Task: return "tasks";
      case OriginModuleEnum.NotificationDefect: return "taskRequests";
      case OriginModuleEnum.PeriodicControl: return "inspections";
      case OriginModuleEnum.PeriodicMaintenance: return "maintenances";
      case OriginModuleEnum.Employee: return "employees";
      case OriginModuleEnum.UserRole: return "userRoles";
      case OriginModuleEnum.ActivityEntry: return "activityEntries";
      case OriginModuleEnum.Contact: return "contacts";
      case OriginModuleEnum.Student: return "students";
      case OriginModuleEnum.IncidentManagement: return "incidents";
      case OriginModuleEnum.Document: return "documents";
      case OriginModuleEnum.Planning: return "planningMoments";
      case OriginModuleEnum.Project: return "projects";
      case OriginModuleEnum.Supplier: return "suppliers";
      case OriginModuleEnum.Report: return "reportDefinitions";
      case OriginModuleEnum.Form: return "forms";
      case OriginModuleEnum.Periodical: return "periodicals";
      case OriginModuleEnum.Consultancy: return "recommendations";
      case OriginModuleEnum.InstructionCard: return "instructionCards";
      case OriginModuleEnum.SafetyInstructionCard: return "safetyInstructionCards";
      case OriginModuleEnum.GlobalPreventionPlan: return "multiYearPlans";
      case OriginModuleEnum.RiskAnalysis: return "riskAnalysises";
      case OriginModuleEnum.CodeElement: return "codeElements";
      case OriginModuleEnum.Tag: return "tags";
      default:
        console.log("Lookups.resolveModuleItemsKey is missing switchcase for " + module);
    }
  }

  resolveModuleItems = (module) => {
    let key = this.resolveModuleItemsKey(module);
    return (key && this.state.data && this.state.data[key]) || [];
  }
  
  subscribeToResourceByModule = (module, handler) => {
    let key = this.resolveModuleItemsKey(module);
    return this.subscribe({ [key]: {} }, lookups => handler(lookups[key]));
  }
  
  resolveFieldNameItemsKey = (fieldName) => {
    switch (fieldName.toLowerCase()) {
      case "legalstructure": return "legalStructures";
      case "branch": return "branches";
      case "building": return "buildings";
      case "location": return "locations";
      case "equipment": return "equipments";
      case "intangibleAsset": return "intangibleAssets";
      case "labourmeans": return "labourmeans";
      case "protectionequipment": return "protectionEquipments";
      case "hazardoussubstance": return "hazardousSubstances";
      case "taskrequest": return "taskRequests";
      case "task": return "tasks";
      case "inspection": return "inspections";
      case "maintenance": return "maintenances";
      case "supplier": return "suppliers";
      case "employee": return "employees";
      case "userrole": return "userRoles";
      case "nacebells": return "naceBells";
      case "paritaircomites":
      case "paritaircomite": return "paritairComites";
      case "technicalbusinessunits": return "technicalBusinessUnits";
      case "userroles": return "userRoles";
      case "activityentry": return "activityEntries";
      case "contact": return "contacts";
      case "incident": return "incidents";
      case "document": return "documents";
      case "project": return "projects";
      case "template": return "templates";
      case "report": return "reports";
      case "reportdefinition": return "reportDefinitions";
      case "preventionsentences": return "preventionSentences";
      case "hazardsentences": return "hazardSentences";
      case "ghspictograms": return "ghsPictograms";
      case "icons": return "icons";
      case "forms": return "forms";
      case "periodicals": return "periodicals";
      case "recommendation": return "recommendations";
      case "globalpreventionplan":
      case "multiyearplan": return "multiYearPlans";
      case "instructioncard": return "instructionCards";
      case "safetyinstructioncard": return "safetyInstructionCards";
      case "riskanalysis": return "riskAnalysises";
      case "buildingcompartment": return "buildingCompartments"
      default: throw new Error(`Unknown resource ${module}`);
    }
  }

  subscribeToResource = (fieldName, handler) => {
    let key = this.resolveFieldNameItemsKey(fieldName);
    return this.subscribe({ [key]: {} }, lookups => handler(lookups[key]));
  }

  getModuleEndpoints = () => {
    const endpoints = {
      [OriginModuleEnum.SchoolGroup]: {
        get: "/api/masterdata/legalstructure",
        getById: "/api/masterdata/legalstructure/:id",
      },
      [OriginModuleEnum.School]: {
        get: "/api/masterdata/branch",
        getById: "/api/masterdata/branch/:id",
      },
      [OriginModuleEnum.Document]: {
        get: "/api/shared/documentmanagement",
        getById: "/api/shared/documentmanagement/:id",
      },
      [OriginModuleEnum.Building]: {
        get: "/api/assetmanagement/building",
        getById: "/api/assetmanagement/building/:id",
      },
      [OriginModuleEnum.Room]: {
        get: "/api/assetmanagement/location",
        getById: "/api/assetmanagement/location/:id",
      },
      [OriginModuleEnum.Labourmeans]: {
        get: "/api/assetmanagement/labourmeans",
        getById: "/api/assetmanagement/labourmeans/:id",
      },
      [OriginModuleEnum.EquipmentInstallation]: {
        get: "/api/assetmanagement/equipment",
        getById: "/api/assetmanagement/equipment/:id",
      },
      [OriginModuleEnum.IntangibleAsset]: {
        get: "/api/assetmanagement/intangibleAsset",
        getById: "/api/assetmanagement/intangibleAsset/:id",
      },
      [OriginModuleEnum.Pbm]: {
        get: "/api/assetmanagement/protectionequipment",
        getById: "/api/assetmanagement/protectionequipment/:id",
      },
      [OriginModuleEnum.HazardousSubstance]: {
        get: "/api/assetmanagement/hazardoussubstance",
        getById: "/api/assetmanagement/hazardoussubstance/:id",
      },
      [OriginModuleEnum.CombinedInstallation]: {
        get: "/api/assetmanagement/combinedinstallation",
        getById: "/api/assetmanagement/combinedinstallation/:id",
      },
      [OriginModuleEnum.NotificationDefect]: {
        get: "/api/facilitymanagement/taskrequest",
        getById: "/api/facilitymanagement/taskrequest/:id",
      },
      [OriginModuleEnum.Task]: {
        get: "/api/facilitymanagement/task",
        getById: "/api/facilitymanagement/task/:id",
      },
      [OriginModuleEnum.PeriodicControl]: {
        get: "/api/facilitymanagement/inspection",
        getById: "/api/facilitymanagement/inspection/:id",
      },
      [OriginModuleEnum.PeriodicMaintenance]: {
        get: "/api/facilitymanagement/maintenance",
        getById: "/api/facilitymanagement/maintenance/:id",
      },
      [OriginModuleEnum.Planning]: {
        get: "/api/facilitymanagement/planning",
        getById: "/api/facilitymanagement/planning/:id",
      },
      [OriginModuleEnum.Project]: {
        get: "/api/facilitymanagement/project",
        getById: "/api/facilitymanagement/project/:id",
      },
      [OriginModuleEnum.ActivityEntry]: {
        get: "/api/facilitymanagement/activityentry",
        getById: "/api/facilitymanagement/activityentry/:id",
      },
      [OriginModuleEnum.IncidentManagement]: {
        get: "/api/safety/incident",
        getById: "/api/safety/incident/:id",
      },
      [OriginModuleEnum.Employee]: {
        get: "/api/masterdata/employee",
        getById: "/api/masterdata/employee/:id",
      },
      "UserRole": {
        get: "/api/masterdata/userrole",
        getById: "/api/masterdata/userrole/:id",
      },
      [OriginModuleEnum.Contact]: {
        get: "/api/masterdata/contact",
        getById: "/api/masterdata/contact/:id",
      },
      [OriginModuleEnum.Supplier]: {
        get: "/api/masterdata/supplier",
        getById: "/api/masterdata/supplier/:id",
      },
      [OriginModuleEnum.Trust]: {
        get: "/api/masterdata/trust",
        getById: "/api/masterdata/trust/:id",
      },
      [OriginModuleEnum.Template]: {
        get: "/api/masterdata/template",
        getById: "/api/masterdata/template/:id",
      },
      [OriginModuleEnum.Report]: {
        get: "/api/shared/reportdefinition",
        getById: "/api/shared/reportdefinition/:id",
      },
      [OriginModuleEnum.Form]: {
        get: "/api/facilitymanagement/form",
        getById: "/api/facilitymanagement/form/:id",
      },
      [OriginModuleEnum.Periodical]: {
        get: "/api/facilitymanagement/periodical",
        getById: "/api/facilitymanagement/periodical/:id"
      },
      [OriginModuleEnum.Consultancy]: {
        get: "/api/safety/recommendation",
        getById: "/api/safety/recommendation/:id",
      },
      [OriginModuleEnum.GlobalPreventionPlan]: {
        get: "/api/safety/multiyearplan",
        getById: "/api/safety/multiyearplan/:id",
      },
      [OriginModuleEnum.InstructionCard]: {
        get: "/api/safety/instructioncard",
        getById: "/api/safety/instructioncard/:id",
      },
      [OriginModuleEnum.SafetyInstructionCard]: {
        get: "/api/safety/safetyinstructioncard",
        getById: "/api/safety/safetyinstructioncard/:id",
      },
      [OriginModuleEnum.Commissioning]: {
        get: "/api/safety/commissioning",
        getById: "/api/safety/commissioning/:id",
      },
      [OriginModuleEnum.OutOfCommissioning]: {
        get: "/api/safety/decommissioning",
        getById: "/api/safety/decommissioning/:id",
      },
      [OriginModuleEnum.RiskAnalysis]: {
        get: "/api/safety/riskanalysis",
        getById: "/api/safety/riskanalysis/:id"
      }

    };
    return endpoints;
  }

  getSubscribers = () => {
    const lookups = {
      "/api/masterdata/legalStructure": {
        module: OriginModuleEnum.SchoolGroup,
        includeInObjectsByModule: true,
        keys: {
          "legalStructures": x => x,
          "legalStructureMap": x => x.toDictionary(x => x.id),
        },
        actionName: "SchoolGroup.Read"
      },
      "/api/masterdata/branch": {
        module: OriginModuleEnum.School,
        includeInObjectsByModule: true,
        keys: {
          "branches": x => x,
          "branchMap": x => x.toDictionary(x => x.id),
        },
        actionName: "School.Read"
      },
      "/api/shared/documentmanagement": {
        module: OriginModuleEnum.Document,
        includeInObjectsByModule: true,
        keys: {
          "documents": x => x,
          "documentMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Document.Read"
      },
      "/api/assetmanagement/building": {
        module: OriginModuleEnum.Building,
        includeInObjectsByModule: true,
        keys: {
          "buildings": x => x,
          "buildingMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Building.Read"
      },
      "/api/assetmanagement/location": {
        module: OriginModuleEnum.Room,
        includeInObjectsByModule: true,
        keys: {
          "locations": x => x,
          "locationMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Room.Read"
      },
      "/api/masterdata/reference/nacebell": {
        keys: {
          "naceBells": x => x,
          "naceBellMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/reference/paritaircomite": {
        keys: {
          "paritairComites": x => x,
          "paritairComiteMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/reference/technicalbusinessunit": {
        keys: {
          "technicalBusinessUnits": x => x,
          "technicalBusinessUnitMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/assetmanagement/labourmeans": {
        module: OriginModuleEnum.Labourmeans,
        includeInObjectsByModule: true,
        keys: {
          "labourmeans": x => x,
          "labourmeanMap": x => x.toDictionary(x => x.id),
        },
        actionName: "LabourMeans.Read"
      },
      "/api/assetmanagement/equipment": {
        module: OriginModuleEnum.EquipmentInstallation,
        includeInObjectsByModule: true,
        keys: {
          "equipments": x => x,
          "equipmentMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Equipment.Read"
      },
      "/api/assetmanagement/intangibleAsset": {
        module: OriginModuleEnum.IntangibleAsset,
        includeInObjectsByModule: true,
        keys: {
          "intangibleAssets": x => x,
          "intangibleAssetMap": x => x.toDictionary(x => x.id),
        },
        actionName: "IntangibleAsset.Read"
      },
      "/api/assetmanagement/protectionequipment": {
        module: OriginModuleEnum.Pbm,
        includeInObjectsByModule: true,
        keys: {
          "protectionEquipments": x => x,
          "protectionEquipmentMap": x => x.toDictionary(x => x.id),
        },
        actionName: "ProtectionEquipment.Read"
      },
      "/api/assetmanagement/hazardoussubstance": {
        module: OriginModuleEnum.HazardousSubstance,
        includeInObjectsByModule: true,
        keys: {
          "hazardousSubstances": x => x,
          "hazardousSubstanceMap": x => x.toDictionary(x => x.id),
        },
        actionName: "HazardousSubstance.Read"
      },
      "/api/masterdata/reference/hazardsentence": {
        keys: {
          "hazardSentences": x => x,
          "hazardSentenceMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/reference/preventionsentence": {
        keys: {
          "preventionSentences": x => x,
          "preventionSentenceMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/reference/ghspictogram": {
        keys: {
          "ghsPictograms": x => x,
          "ghsPictogramMap": x => x.toDictionary(x => x.iconId),
        }
      },
      "/api/masterdata/reference/icon": {
        keys: {
          "icons": x => x,
          "iconMap": x => x.toDictionary(x => x.iconId),
          "iconByNameMap": x => x.toDictionary(x => x.name),
        }
      },
      "/api/masterdata/reference/buildingcompartment": {
        keys: {
          "buildingCompartments": x => x,
          "buildingCompartmentMap": x => x.toDictionary(x => x.buildingId),
        }
      },
      "/api/assetmanagement/combinedinstallation": {
        module: OriginModuleEnum.CombinedInstallation,
        includeInObjectsByModule: true,
        keys: {
          "combinedInstallations": x => x,
          "combinedInstallationMap": x => x.toDictionary(x => x.id),
        },
        actionName: "CombinedInstallation.Read"
      },
      "/api/facilitymanagement/taskrequest": {
        module: OriginModuleEnum.NotificationDefect,
        includeInObjectsByModule: true,
        keys: {
          "taskRequests": x => x,
          "taskRequestMap": x => x.toDictionary(x => x.id),
        },
        actionName: "NotificationDefect.Read"
      },
      "/api/facilitymanagement/task": {
        module: OriginModuleEnum.Task,
        includeInObjectsByModule: true,
        keys: {
          "tasks": x => x,
          "taskMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Task.Read"
      },
      "/api/facilitymanagement/task/settings/taskpriorityrule": {
        keys: {
          "taskPriorityRules": x => x,
        }
      },
      "/api/facilitymanagement/inspection": {
        module: OriginModuleEnum.PeriodicControl,
        includeInObjectsByModule: true,
        keys: {
          "inspections": x => x,
          "inspectionMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Control.Read"
      },
      "/api/facilitymanagement/maintenance": {
        module: OriginModuleEnum.PeriodicMaintenance,
        includeInObjectsByModule: true,
        keys: {
          "maintenances": x => x,
          "maintenanceMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Maintenance.Read"
      },
      "/api/safety/multiyearplan": {
        module: OriginModuleEnum.GlobalPreventionPlan,
        includeInObjectsByModule: true,
        keys: {
          "multiYearPlans": x => x,
          "multiYearPlanMap": x => x.toDictionary(x => x.id),
        },
        actionName: "GlobalPreventionPlan.Read"
      },
      "/api/facilitymanagement/project": {
        module: OriginModuleEnum.Project,
        includeInObjectsByModule: true,
        keys: {
          "projects": x => x,
          "projectMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Project.Read"
      },
      "/api/facilitymanagement/activityentry": {
        module: OriginModuleEnum.ActivityEntry,
        includeInObjectsByModule: true,
        keys: {
          "activityEntries": x => x,
          "activityEntryMap": x => x.toDictionary(x => x.id),
        },
        actionName: "ActivityEntry.Read"
      },
      "/api/masterdata/employee": {
        module: OriginModuleEnum.Employee,
        includeInObjectsByModule: true,
        keys: {
          "employees": x => x,
          "employeeMap": x => x.toDictionary(x => x.id),
        },
      },
      "/api/masterdata/userrole": {
        module: OriginModuleEnum.UserRole,
        includeInObjectsByModule: true,
        keys: {
          "userRoles": x => x,
          "userRoleMap": x => x.toDictionary(x => x.id),
          "userRoleByUserIdMap": x => x
            .flatMap(ur => ur.users.map(u => ({ userId: u.id, userRoleId: ur.id })))
            .groupBy(x => x.userId, x => x.userRoleId),
        }
      },
      "/api/masterdata/codeelements": {
        preProcess: this.mapCodeElementsToHierarchy,
        keys: {
          "codeElements": x => x.filter(x => x.code && !x.parentId).groupBy(x => x.code),
          "codeElementsById": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/codeelements/getmetadata": {
        keys: {
          "codeElementsStructure": x => x,
          "codeElementsSchema": x => this.flattenCodeElementHierarchy(x),
        }
      },
      "/api/masterdata/contact": {
        module: OriginModuleEnum.Contact,
        includeInObjectsByModule: true,
        keys: {
          "contacts": x => x,
          "contactMap": x => x.toDictionary(x => x.id),
        },
      },
      "/api/masterdata/profile/apikey": {
        keys: {
          "apiKeys": x => x,
          "apiKeyMap": x => x.toDictionary(x => x.id),
        },
        actionName: "GlobalManagement.Write",
      },
      "/api/masterdata/profile/calendar": {
        keys: {
          "calendars": x => x,
        },
      },
      "/api/shared/tag": {
        keys: {
          "tags": x => x,
          "tagMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/shared/report": {
        keys: {
          "reports": x => x,
          "reportMap": x => x.toDictionary(x => x.id),
          "reportsByAliasMap": x => x.filter(x => x.alias).groupBy(x => x.alias)
        },
      },
      "/api/shared/reportdefinition": {
        module: OriginModuleEnum.Report,
        includeInObjectsByModule: true,
        keys: {
          "reportDefinitions": x => x,
          "reportDefinitionMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Report.Read"
      },
      "/api/masterdata/supplier": {
        module: OriginModuleEnum.Supplier,
        includeInObjectsByModule: true,
        keys: {
          "suppliers": x => x,
          "supplierMap": x => x.toDictionary(x => x.id),
        },
      },
      "/api/masterdata/template": {
        keys: {
          "templates": x => x,
          "templateMap": x => x.toDictionary(x => x.id),
        }
      },
      "/api/masterdata/trust": {
        module: "Trust",
        keys: {
          "trusts": x => x,
          "trustMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Trust.Read"
      },
      "/api/safety/incident": {
        module: OriginModuleEnum.IncidentManagement,
        includeInObjectsByModule: true,
        keys: {
          "incidents": x => x,
          "incidentMap": x => x.toDictionary(x => x.id),
        },
        actionName: "IncidentManagement.Read"
      },
      "/api/safety/incident/seriousinjuryranges": {
        module: OriginModuleEnum.IncidentManagement,
        keys: {
          "seriousInjuryRanges": x => x
        }
      },
      "/api/facilitymanagement/planning": {
        module: OriginModuleEnum.Planning,
        keys: {
          "planningMoments": x => x,
          "planningMomentsMap": x => x.toDictionary(x => x.id),
          "planningMomentsBySubjectMap": x => x.filter(x => x.subject).groupBy(x => `${x.subject.module}:${x.subject.id}`)
        }
      },
      "/api/facilitymanagement/form": {
        module: OriginModuleEnum.Form,
        includeInObjectsByModule: true,
        keys: {
          "forms": x => x,
          "formMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Form.Read"
      },
      "/api/facilitymanagement/periodical": {
        module: OriginModuleEnum.Periodical,
        includeInObjectsByModule: true,
        keys: {
          "periodicals": x => x,
          "periodicalMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Periodical.Read"
      },
      "/api/safety/recommendation": {
        module: OriginModuleEnum.Consultancy,
        includeInObjectsByModule: true,
        keys: {
          "recommendations": x => x,
          "recommendationMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Consultancy.Read"
      },
      "/api/safety/instructioncard": {
        module: OriginModuleEnum.InstructionCard,
        includeInObjectsByModule: true,
        keys: {
          "instructionCards": x => x,
          "instructionCardMap": x => x.toDictionary(x => x.id),
        },
        actionName: "InstructionCard.Read"
      },
      "/api/safety/safetyinstructioncard": {
        module: OriginModuleEnum.SafetyInstructionCard,
        includeInObjectsByModule: true,
        keys: {
          "safetyInstructionCards": x => x,
          "safetyInstructionCardMap": x => x.toDictionary(x => x.id),
        },
        actionName: "SafetyInstructionCard.Read"
      },
      "/api/safety/commissioning": {
        module: OriginModuleEnum.Commissioning,
        includeInObjectsByModule: true,
        keys: {
          "commissionings": x => x,
          "commissioningMap": x => x.toDictionary(x => x.id),
        },
        actionName: "Commissioning.Read"
      },
      "/api/safety/decommissioning": {
        module: OriginModuleEnum.OutOfCommissioning,
        includeInObjectsByModule: true,
        keys: {
          "decommissionings": x => x,
          "decommissioningMap": x => x.toDictionary(x => x.id),
        },
        actionName: "OutOfCommissioning.Read"
      },
      "/api/safety/riskanalysis": {
        module: OriginModuleEnum.RiskAnalysis,
        includeInObjectsByModule: true,
        keys: {
          "riskAnalysises": x => x,
          "riskAnalysisMap": x => x.toDictionary(x => x.id),
        },
        actionName: "RiskAnalysis.Read"
      },
    };

    return Object.keys(lookups)
      .flatMap(endpoint => {
        const lookup = lookups[endpoint];
        const subscribe = () => {
          if (this.loadedEndpoints[endpoint]) {
            return;
          } else {
            this.loadedEndpoints[endpoint] = true;
          }

          this.subscribeToEndpoint(endpoint,
            items => {
              if (lookup.module) {
                items = items.map(x => ({ ...x, module: lookup.module }));
              }

              items = lookup.preProcess ? lookup.preProcess(items) : items;

              for (const key of Object.keys(lookup.keys)) {
                const mapper = lookup.keys[key];
                this.updateLookups(key, mapper(items));
              }

              if (lookup.includeInObjectsByModule) {
                this.updatePartionedLookups("objectsByModule", items, lookup.module);
              }
            },
            lookup.actionName);
        };
        return Object.keys(lookup.keys)
          .map(key => ({ key, subscribe }));
      })
      .toDictionary(x => x.key, x => x.subscribe);
  }

  subscribe = (keyMap, handler) => {
    const data = {};

    for (const key of Object.keys(keyMap)) {
      const value = this.state.data[key];
      if (value) {
        data[key] = value;
      } else {
        if (key !== "objectsByModule") {
          const subscribe = this.subscribeForKeys[key];
          if (!subscribe) {
            arxs.logger.error("Subscribe not found for lookup {key}", key);
          } else {
            subscribe();
          }
        }
      }
    }

    // Don't update them synchronously
    setTimeout(() => {
      if (Object.keys(data).length > 0) {
        handler(data);
      }
    }, 1);

    const subscription = { keyMap, handler };
    const subscriptions = this.state.subscriptions;
    subscriptions.push(subscription);

    return {
      dispose: () => {
        const index = subscriptions.indexOf(subscription);
        if (index > -1) {
          subscriptions.splice(index, 1);
        } else {
          arxs.logger.error("Couldn't find subscriptions while disposing...");
        }
      }
    };
  }

  subscribeToId = (module, id, handler) => {
    const endpoints = this.moduleEndpoints[module];
    if (!endpoints) {
      arxs.logger.error("Endpoints not found for {module}", module);
      return;
    }

    if (!endpoints.getById) {
      arxs.logger.error("getById endpoint not found for {module}", module);
      return;
    }

    const url = endpoints.getById.replace(":id", id);
    return this.subscribeToEndpoint(url, handler);
  }
}
