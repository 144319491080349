import { ArXsState } from "infra/arxs";
import {
  ObjectDocumentType,
  OriginModuleEnum,
  RelationshipType,
} from "infra/api/contracts";
import { ProductType } from "infra/Types";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import TaskRequestWizard from "./TaskRequestWizard";
import TaskRequestActions from "./TaskRequestActions";
import TaskRequestBoard from "./TaskRequestBoard";
import TaskRequestCard from "./TaskRequestCard";

const getTaskRequestMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.NotificationDefect;

  return {
    module,
    title: arxs.t("modules.taskrequest"),
    icon: "far fa-bell-exclamation",
    path: "/taskrequest",
    base: {
      name: "TaskRequest",
      route: "/taskrequest",
      getResource: () => arxs.Api.getResource("taskrequest"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "NotificationDefect.Read",
      writeAction: "NotificationDefect.Write",
    },
    settings: {
      name: "TaskRequestSettings",
      route: "/taskrequest/settings",
      getResource: () => arxs.ApiClient.facilitymanagement.taskRequestSettings,
      readAction: "NotificationDefect.Settings.Write",
      writeAction: "NotificationDefect.Settings.Write",
      steps: [
        {
          title: arxs.t("field.processFlows"),
          fields: [
            [
              {
                name: "processFlows",
                type: "itemlist",
                children: [
                  { name: "legalStructure" },
                  { name: "branch", parent: "legalStructure" },
                  { name: "kind", code: "NotificationDefect.Kind" },
                  {
                    name: "type",
                    parent: "kind",
                    code: "NotificationDefect.Type",
                  },
                  { name: "employee" },
                  { name: "userRole" },
                  {
                    name: "relationshipType",
                    values: [
                      RelationshipType.Responsible,
                      RelationshipType.CoResponsible,
                      RelationshipType.Cc,
                      RelationshipType.Assignee,
                    ],
                  },
                ],
              },
            ],
          ],
        },
      ],
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "title", fullWidth: true },
          { name: "description", fullWidth: true },
          { name: "subjects" },
          {
            name: "createdAt",
            props: { longDate: true },
            title: arxs.t("field.notificationDate"),
            fullWidth: true,
          },
          { name: "relationships" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "branch", parent: "legalStructure" },
          { name: "building", parent: "branch" },
          { name: "location", parent: "building" },
          { name: "geoLocation" },
        ],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      "Pending",
      "Refused",
      "Accepted",
      "OnHold",
      "ExecutionStarted",
      "ExecutionOverdue",
      "Completed",
    ],
    actions: TaskRequestActions.getActions(
      OriginModuleEnum.NotificationDefect,
      false
    ),
    wizard: {
      wizardClass: TaskRequestWizard,
      steps: [
        {
          title: arxs.t("wizard.common.identification"),
          fields: [
            [
              {
                name: "notifier",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Contact,
                    OriginModuleEnum.Employee,
                  ],
                  condensed: true,
                  overridePrefilter: true,
                  required: true,
                },
                title: arxs.t("wizard.task_request.notifier"),
              },
            ],
            [
              {
                name: "title",
                title: arxs.t("wizard.field.title"),
              },
            ],
            [
              {
                name: "description",
                title: arxs.t("wizard.field.description"),
              },
            ],
            [
              {
                name: "subjects",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.SchoolGroup,
                    OriginModuleEnum.School,
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  disableInitialValue: true,
                },
                onChange: (stateProxy, fieldName: string) => {
                  TaskRequestWizard.setScopeFields(stateProxy, fieldName).then(
                    () =>
                      TaskRequestWizard.loadRelatedRequests(stateProxy).then(
                        () =>
                          TaskRequestWizard.applyProcessFlowForSubjects(stateProxy)
                      )
                  );
                },
                title: arxs.t("field.subjects"),
              },
            ],
            [
              {
                name: "type",
                parent: "kind",
                props: { code: "NotificationDefect.Kind" },
                type: "codeelementlist",
                title: arxs.t("field.category"),
                onChange: (stateProxy) =>
                  TaskRequestWizard.applyProcessFlowForSubjects(stateProxy),
              },
              { name: "geoLocation" },
            ],
            [
              {
                name: "supplier",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Supplier],
                  condensed: true,
                  // Prefilter would filter these since they are not contextual
                  overridePrefilter: true,
                },
                productType: ProductType.Hyperion,
              },
              {
                name: "witness",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Employee,
                    OriginModuleEnum.Contact,
                  ],
                  overridePrefilter: true,
                  condensed: true,
                },
                title: arxs.t("field.witness"),
                productType: ProductType.Hyperion,
              },
            ],
            [
              {
                name: "",
                onLoad: (stateProxy) =>
                  TaskRequestWizard.loadRelatedRequests(stateProxy),
                render: (stateProxy) =>
                  TaskRequestWizard.renderRelatedRequests(stateProxy),
              },
            ],
          ],
          getSecurityContext: (writeAction, getValue) => {
            const args = [
              "legalStructure",
              "branch",
              "building",
              "location",
            ].map(getValue);
            return arxs.securityContext.buildForObjectOrUserContext(
              writeAction,
              ...(args as any)
            );
          },
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        taskRequests: [],
        labourmeanMap: {},
        protectionEquipmentMap: {},
        hazardousSubstanceMap: {},
        equipmentMap: {},
        intangibleAssetMap: {},
        combinedInstallationMap: {},
        objectsByModule: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        employeeMap: {},
      },
    },
    board: {
      allowDuplicate: false,
      lookups: {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        taskRequests: [],
        tagMap: {},
        employeeMap: {},
        userRoleMap: {},
        contactMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "geoLocation" },
        { name: "kind" },
        { name: "type" },
        { name: "title" },
        { name: "description" },
        { name: "notifier" },
        { name: "owner" },
        { name: "createdAt" },
        { name: "responsible" },
        { name: "coResponsibles" },
      ],
      boardClass: TaskRequestBoard,
      views: [
        BoardViewType.KanBan,
        BoardViewType.Grid,
        BoardViewType.Tree,
        BoardViewType.Map,
      ],
    },
    cardClass: TaskRequestCard
  };
};

export default getTaskRequestMetadata;
