import arxs from "infra/arxs";
import { LinkType, OriginModuleEnum } from "infra/api/contracts";
import GlobalActions from "modules/GlobalActions";

class ActivityEntryActions {
  getActions = (module) => {
    return GlobalActions.getActions(module);
  };

  getGlobalActions = (module) => {
    return [{
      name: "create_activityentry",
      module: module,
      icon: "far fa-file-signature",
      getTitle: () => arxs.t("kanban.actions.add_activityentry"),
      onClick: (state) => this.addActivityEntry(state, module),
      singleSelectionOnly: true
    }];
  };

  addActivityEntry = (state, module) => {
    let { cards, history, item } = state;

    if (!cards && item) {
      cards = [item.card];
    }

    if (cards.length === 1) {
      const {
        id,
        module,
        legalStructure,
        branch,
        building,
        location,
        geoLocation,
      } = cards[0];

      const getContext = () => {
        if (location) {
          return { ...location, module: OriginModuleEnum.Room };
        }
        if (building) {
          return { ...building, module: OriginModuleEnum.Building };
        }
        if (branch) {
          return { module: OriginModuleEnum.School, ...branch };
        }
        if (legalStructure) {
          return { module: OriginModuleEnum.SchoolGroup, ...legalStructure };
        }
      }

      const state = {
        legalStructure: legalStructure,
        branch: branch,
        building: building,
        location: location,
        geoLocation,
        context: getContext(),
        origins: [{ id, module, type: LinkType.Origin }],
      };

      history.push({ pathname: `/activityentry/create`, state });
    }
    return new Promise(() => { });
  }
}
export default new ActivityEntryActions();
