import React from 'react';
import RadioOption from './RadioOption';

import './RadioGroup.scss';

export default class RadioGroup extends React.Component {
  state = {
    index: 0
  }

  componentDidMount() {
    if (this.props.index) {
      this.setState({ index: this.props.index });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.index !== this.props.index) {
      this.setState({ index: this.props.index });
    }
  }

  renderRow = (row, i) => {
    const readOnly = i !== this.state.index;
    return row({ readOnly });
  }

  render() {
    const onChange = (i) => {
      return (e) => {
        if (e.target.value === "on") {
          this.setState({ index: i });
          this.props.onChange && this.props.onChange(i);
        }
      };
    }
    return (<div className="radio-group">
      {this.props.children
        .map((row, i) => <RadioOption key={`radio-option-${i}`} value={i === this.state.index} onChange={onChange(i)} readOnly={this.props.readOnly}>
          {this.renderRow(row, i)}
        </RadioOption>)}
    </div>)
  }
}