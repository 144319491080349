import React, { Fragment, useRef, useEffect } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

import GlobalContext from 'infra/GlobalContext';
import { SecurityContext } from 'infra/SecurityContext';

import { TextArea } from 'components/controls/TextArea';
import CheckBox from 'components/controls/CheckBox';
import Scrolling from 'components/card/Scrolling';
import { ChecklistItemModel } from './Contracts';

export interface ChecklistItemProps {
  readOnly?: boolean,
  editable?: boolean,
  value: ChecklistItemModel,
  onChange(value?: ChecklistItemModel): void,
  onAdd?(): void,
  onDrop?(target: ChecklistItemModel, dragged: ChecklistItemModel): void,
  securityContext: SecurityContext,
}

export default function ChecklistItem(props: ChecklistItemProps) {
  const { value, onChange, readOnly, editable } = props;

  useEffect(() => {

  }, []);

  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: 'ChecklistItem',
    item: () => {
      Scrolling.addEventListenerBoardScroll();
      return {
        id: value.id,
        title: value.title,
        done: value.done,
      };
    },
    end: Scrolling.removeEventListenerForBoardScroll,
    canDrag: true
  });

  // Allows to drop checklistitems on top of another item in orde to take its place
  const [, drop] = useDrop({
    accept: 'ChecklistItem',
    hover: (item: any, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      };

      if (props.onDrop) {
        props.onDrop(props.value, item);
      }
    },
  });

  drag(drop(ref));

  const handleDelete = () => {
    props.onChange();
  };

  // const handleEdit = () => {

  // };

  const toggle = (e: any, value: ChecklistItemModel) => {
    !readOnly && props.onChange && props.onChange({ ...value, done: !value.done });
  };

  const getField = () => {
    return {
      id: value.id,
      getter: () => value.title,
      setter: (title: string) => onChange({ ...value, title }),
      readOnly
    };
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (props.onAdd) {
        props.onAdd();
      }
    } else if (e.keyCode === 27) {
      e.preventDefault();
      handleDelete();
    }
  };

  const onBlur = (e: any) => {
    if (!value.title) {
      handleDelete();
    }
  }

  return <GlobalContext.Consumer>
    {
      context => <div ref={ref} className="checklist-item">
        <CheckBox
          checked={!!value.done}
          onChange={(e: any) => toggle(e, value)}
          disabled={readOnly}
        />
        <TextArea
          key={value.id}
          className={`full-width input${value.done ? " strikethrough" : ""}`}
          readOnly={(readOnly || (editable === false))}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          field={getField()}
          value={value.title}
          autoFocus={value.autoFocus}
        />
        {!(readOnly || (editable === false)) && <Fragment>
          <div className="checklist-item-action" onClick={handleDelete}>
            <i className="far fa-trash-alt"></i>
          </div>
        </Fragment>}
      </div>
    }
  </GlobalContext.Consumer>;
}