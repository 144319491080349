import React, { Component, Fragment } from 'react';
import Field from 'components/controls/Field';
import Link from 'components/controls/Link';
import arxs from 'infra/arxs';

import './ItemList.scss';

class ItemList extends Component {
  constructor(props) {
    super(props);

    this.state = this.initializeState(props.field);
  }

  initializeState(field) {
    let refSchema, error;

    const name = field.name;
    const schema = field.schema;
    if (!schema) {
      error = `Schema not provided for ItemList ${field.name}`;
    } else {
      if (schema.type !== "array") {
        error = "Unexpected type";
      }

      if (schema.type === "array" && schema.items) {
        const link = schema.items["$ref"]
        if (link) {
          const match = link.match(/.*\/([^/]+)/);
          if (match === null) {
            arxs.logger.error("Unexpected ref {fieldName}", name);
            error = `${name}, unexpected ref`;
          }

          const ref = match[1];
          refSchema = arxs.Api.getSchema(ref);
          if (!refSchema) {
            arxs.logger.error("Schema not found {fieldName} {ref}", name, ref);
            error = `${name}, schema not found`;
          } else if (!this.props.names || this.props.names.length === 0) {
            if (!refSchema.properties || !refSchema.properties.id) {
              error = "No fieldnames provided";
            }
          }
        }
      }
    }

    return {
      name: name,
      schema: schema,
      refSchema,
      error,
      pristine: [],
      data: []
    };
  }

  handleDelete = (row) => {
    const { field } = this.props;
    const items = [...(field.getter() || [])];
    const i = items.indexOf(row);

    if (i > -1) {
      if (this.props.onDelete) {
        if (this.props.onDelete(this.props.stateProxy, items, i)) {
          items.splice(i, 1);
          field.setter(items);
        }
      } else {
        items.splice(i, 1);
        field.setter(items);
      }
    };
  }

  handleAdd = () => {
    const { field } = this.props;
    const items = [...(field.getter() || [])];
    const newItem = this.props.preferredOnly ? { isPreferred: true } : {};
    items.push(newItem);
    field.setter(items);
  }

  render() {
    let items = this.props.field.getter() || [];
    const name = this.state.name;
    const title = this.props.title || arxs.t(`field.${name}`);
    const schema = this.state.refSchema;
    const properties = schema.properties || {};
    const required = this.props.field.required;

    if (items) {
      if (this.props.preferredOnly) {
        if (items.some(x => x.isPreferred)) {
          items = [items.filter(x => x.isPreferred)[0]];
        } else {
          const firstItem = items.filter(x => x)[0];
          if (firstItem) {
            items = [firstItem];
          }
        }
      }
    }

    const childFields = (this.props.names || [])
      .map((fieldOrFieldName) => {
        return typeof fieldOrFieldName === "string" ? { name: fieldOrFieldName } : fieldOrFieldName;
      });

    const getField = (index, def) => {
      const item = items[index] || {};
      const data = this.state.data[index] || {};

      const getCurrentFieldValue = fieldName => data[fieldName] === undefined ? item[fieldName] : data[fieldName];
      const fieldName = def.name;
      const field = properties[fieldName];
      const title = def.title;
      const unit = def.unit;
      const values = def.values;

      const getter = () => getCurrentFieldValue(fieldName);
      const setter = value => {
        if (this.props.setField) {
          this.props.setField(item, fieldName, value);
        }
      };

      const filter =
        (item && def.filter && ((args) => def.filter(this.props.stateProxy, args, item)))
        || (this.props.field.filter && ((args) => this.props.field.filter(this.props.stateProxy, args)));

      let securityContext = this.props.securityContext;

      const securityContextFields = { "legalStructure": "legalStructure", "branch": "branch" };
      if (childFields.some(x => securityContextFields[x.name])) {
        if (!securityContextFields[fieldName]) {
          const args = ["legalStructure", "branch"].map(getCurrentFieldValue);
          if (args.any(x => x)) {
            // console.log(`Overriding security context for ${fieldName} with ${args.filter(x => x).map(x => x.name).join(", ")}`);
            securityContext = arxs.securityContext.buildForContext(null, ...args);
          }
        }
      }

      const validation = ((this.props.validation || {})[index] || {})[fieldName];

      if (def === schema) {
        return {
          name: name,
          title: "",
          required: true,
          code: "",
          schema: this.props.field.schema.items,
          readOnly: this.props.readOnly,
          filter,
          getter: () => this.state.data[index] || items[index] || {},
          setter: value => {
            if (this.props.setField) {
              this.props.setField(item, "id", value ? value.id : null);
            }
          },
          securityContext,
          values: values,
          validation
        };
      }

      if (!field) {
        arxs.logger.error(`ItemList - Field '${fieldName}' not found in '${this.state.name}'`);
        return { name: fieldName };
      }

      const readOnly = this.props.readOnly || field.readOnly;
      const stateProxy = { getCurrentFieldValue };
      const code = typeof(def.code) === "function" ? def.code(stateProxy) : def.code;

      const parentName = typeof(def.parent) === "function" ? def.parent(stateProxy) : def.parent;
      const parentGetter = parentName ? () => getCurrentFieldValue(parentName) : null;

      return {
        name: fieldName,
        title,
        required: schema.required && schema.required.indexOf(fieldName) > -1,
        code,
        schema: field,
        readOnly: readOnly,
        multiLine: def.multiLine,
        unit,
        getter,
        setter,
        parentGetter,
        parent: parentName,
        filter,
        securityContext,
        values: values,
        validation,
        props: def.props
      };
    };

    const getFieldStyle = (field) => {
      if (field.width) {
        return {
          flexGrow: 0,
          flexBasis: field.width,
          "whiteSpace": "nowrap",
        };
      } else if (field.width === undefined) {
        switch (field.name) {
          case "isPreferred": return { flexGrow: 0, flexBasis: "50px", "whiteSpace": "nowrap", };
          default: return { "whiteSpace": "nowrap", };
        }
      }
    };

    const renderRequired = (name) => {
      const field = childFields.map((field) => getField(-1, field));

      const isRequired = field.filter(x => x.name === name).map(x => x.required)[0];
      return isRequired && !this.props.readOnly && (<span> *</span>);
    }

    const canEdit = (row) => {
      if (this.props.moduleSettings && row.actions) {
        return !this.props.readOnly && row.actions.includes("edit");
      }

      return !this.props.readOnly;
    }

    return (
      <div className={`item-list ${this.props.className || ''}`}>
        {this.props.title && <label>{this.props.title}{required && (<span> *</span>)}</label>}
        {this.state.error
          ? <span>{this.state.error}</span>
          : <div className="item-list-table">
            {!this.props.noHeaders && items && items.length > 0 && <div className="header">
              {childFields
                .map((childField, col) => <h4 key={`item-list-${name}-header-${col}`} style={getFieldStyle(childField)}>
                  {childField.title || arxs.t(`field.${childField.name}`)}{renderRequired(childField.name)}
                </h4>)}
              {!this.props.readOnly && <div className="item-list-table-action"></div>}
            </div>}
            {items.map((row, rowIndex) => <div key={`item-list-${name}-row-${rowIndex}`} className="row">
              {childFields
                .map((field, col) => {
                  if (field.props && field.props.followSchema) {
                    return <Field
                      key={`item-list-${name}-col-${rowIndex}-${col}`}
                      noHeader
                      field={{...getField(rowIndex, schema), readOnly: !canEdit(row)}}
                      />
                  }
                  return <Fragment key={`fragment-${col}`}><Field
                    key={`item-list-${name}-col-${rowIndex}-${col}`}
                    style={getFieldStyle(field)}
                    noHeader
                    field={getField(rowIndex, field)}
                    {...field.props}
                    readOnly= {!canEdit(row)} 
                    />
                    {this.props.noHeaders && <div className="item-list-required">{renderRequired(field.name)}</div>}
                  </Fragment>
                }
                )}
              {childFields.length === 0 && <Field
                noHeader
                field={getField(rowIndex, schema)} />}
              {canEdit(row)
                && <Link className="item-list-table-action" onClick={() => this.handleDelete(row)}>
                  <i className="far fa-trash-alt"></i>
                </Link>}
            </div>)}
            {((!this.props.readOnly && !this.props.preferredOnly) || (!this.props.readOnly && this.props.preferredOnly && items.length === 0)) && <div className="item-list-actions">
              <Link onClick={this.handleAdd}>
                {arxs.t("item_list.add_item", { name: title })} <i className="fas fa-plus"></i>
              </Link>
            </div>}
          </div>}
      </div>
    );
  }
}
export default ItemList;