import React from "react";

import { CardClassProps } from "components/card/CardContracts";
import CardBase from "components/card/CardBase";
import UniqueNumber from "components/card/UniqueNumber";

export default function ProjectCard(props: CardClassProps) {
    return CardBase(props, "far fa-tasks-alt", (card => <div>
        <UniqueNumber uniqueNumber={card.uniqueNumber} /> - {card.title}
      </div>
));
}