/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReportStyle {
    ListView = 'ListView',
    TableView = 'TableView'
}

export function ReportStyleFromJSON(json: any): ReportStyle {
    return ReportStyleFromJSONTyped(json, false);
}

export function ReportStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStyle {
    return json as ReportStyle;
}

export function ReportStyleToJSON(value?: ReportStyle | null): any {
    return value as any;
}

