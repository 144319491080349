import React, { Component } from 'react';
import { createGeoLookup } from './GeoLookup';
import { Card } from 'components/card/Card';
import GlobalContext from 'infra/GlobalContext';
import SelectionLabel from 'components/controls/selectionLabel/SelectionLabel';

import './GeoLocation.scss';

export interface GeoLocationProps {
  title: string,
  readOnly?: boolean,
  value: any,
  onChange?(value: any): void,
  className?: string,
  required?: boolean,
}

interface GeoLocationState {
  cardLookup?: any,
}

export default class GeoLocation extends Component<GeoLocationProps, GeoLocationState> {
  state: GeoLocationState = {}

  handleDelete = () => {
    this.props.onChange && this.props.onChange(null);
  }

  handleAdd = (context: any) => {
    const onChange = (state: any) => {
      context.popup.close();
      this.props.onChange && this.props.onChange(state);
    }

    const { value } = this.props;
    const addressLookup = createGeoLookup(context, value, onChange);
    context.popup.show(addressLookup);
  }

  render() {
    const { title, readOnly, className } = this.props;
    const value = this.props.value;
    const required = this.props.required;

    return <GlobalContext.Consumer>
      {
        context => <div className={`geolocation ${className || ''}`}>
          {title && <label>{title}{required && (<span> *</span>)}</label>}
          {value && <div className="geolocation-content">
            <Card data={value} module="GeoLocation" condensed={true} onDelete={() => this.handleDelete()} readOnly={this.props.readOnly} />
          </div>}
          {!readOnly && !value && <div className="geolocation-actions">
            <SelectionLabel onClick={() => this.handleAdd(context)} title={title} />
          </div>}
        </div>
      }
    </GlobalContext.Consumer>;
  }
}