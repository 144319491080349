import { ArXsState } from "infra/arxs";
import { OriginModuleEnum, RelationshipType, ObjectDocumentType, InstructionCardStatus, SignatoryType, Attachment, AttachmentValue } from "infra/api/contracts";

import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import createAssetProcessFlowSettings from "modules/safety/AssetProcessFlowSettings";

import SafetyInstructionCardWizard from "./SafetyInstructionCardWizard";
import SafetyInstructionCardActions from "./SafetyInstructionCardActions";
import SafetyInstructionCardBoard from "./SafetyInstructionCardBoard";
import InstructionCardCard from "modules/safety/instructioncard/InstructionCardCard";

const getSafetyInstructionCardMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.SafetyInstructionCard;

  return {
    module,
    title: arxs.t("modules.safetyinstructioncard"),
    icon: "far fa-shield-alt",
    path: "/safetyinstructioncard",
    base: {
      name: "SafetyInstructionCard",
      route: "/safetyinstructioncard",
      getResource: () => arxs.Api.getResource("safetyinstructioncard"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      writeAction: "SafetyInstructionCard.Write",
      readAction: "SafetyInstructionCard.Read",
    },
    settings: {
      name: "SafetyInstructionCardSettings",
      route: "/safetyinstructioncard/settings",
      getResource: () => arxs.ApiClient.safety.safetyInstructionCardSettings,
      readAction: "SafetyInstructionCard.Settings.Write",
      writeAction: "SafetyInstructionCard.Settings.Write",
      steps: createAssetProcessFlowSettings(),
    },
    detailspane: [
      {
        initiallyOpen: true,
        fields: [
          { name: "description", fullWidth: true },
          { name: "subject" },
          { name: "createdAt" },
          { name: "activationDate" },
          { name: "relationships" },
          { name: "signatories" },
        ],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.MainDocument,
      ObjectDocumentType.AdditionalDocument,
    ],
    statuses: [
      InstructionCardStatus.InProcess,
      InstructionCardStatus.ToSign,
      InstructionCardStatus.Active,
      InstructionCardStatus.Inactive,
    ],
    statusColorOverride: [
      { status: InstructionCardStatus.Active, color: "status-completed" },
    ],
    actions: SafetyInstructionCardActions.getActions(
      OriginModuleEnum.SafetyInstructionCard
    ),
    actionClass: SafetyInstructionCardActions,
    formCodesOverride: [
      { module: OriginModuleEnum.Labourmeans, code: "LabourMeans.Kind" },
      { module: OriginModuleEnum.Pbm, code: "ProtectionEquipment.Sort" },
      {
        module: OriginModuleEnum.EquipmentInstallation,
        code: "EquipmentInstallation.Sort",
      },
      {
        module: OriginModuleEnum.HazardousSubstance,
        code: "HazardousSubstance.Sort",
      },
      {
        module: OriginModuleEnum.IntangibleAsset,
        code: "IntangibleAsset.Kind",
      },
    ],
    wizard: {
      wizardClass: SafetyInstructionCardWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [{ name: "title" }, { name: "activationDate" }],
            [{ name: "description" }],
            [
              {
                name: "subject",
                type: "cardlist",
                props: {
                  modules: [
                    OriginModuleEnum.Building,
                    OriginModuleEnum.Room,
                    OriginModuleEnum.Labourmeans,
                    OriginModuleEnum.Pbm,
                    OriginModuleEnum.EquipmentInstallation,
                    OriginModuleEnum.HazardousSubstance,
                    OriginModuleEnum.CombinedInstallation,
                    OriginModuleEnum.IntangibleAsset,
                  ],
                  overridePrefilter: true,
                  disableInitialValue: true,
                },
                onChange: (
                  stateProxy,
                  fieldName: string,
                  row: any,
                  childName: string
                ) => {
                  SafetyInstructionCardWizard.setScopeFields(
                    stateProxy,
                    "subject"
                  );
                },
                title: arxs.t("field.subject"),
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.involved_people"),
          fields: [
            [
              {
                name: "responsible",
                type: "cardlist",
                props: {
                  modules: [OriginModuleEnum.Employee],
                  condensed: true,
                },
              },
            ],
            [
              {
                name: "relationships",
                type: "relationshiplist",
                props: {
                  types: [RelationshipType.CoResponsible, RelationshipType.Cc],
                },
              },
            ],
          ],
        },
        {
          title: arxs.t("wizard.common.form"),
          fields: [
            [
              {
                name: "formDefinition",
                props: { module: OriginModuleEnum.SafetyInstructionCard },
                onChange: (stateProxy) => SafetyInstructionCardWizard.onFormChange(stateProxy),
              },
            ],
          ],
        },
        {
          title: arxs.t("kanban.common.signatories"),
          fields: [
            [{ name: "needsSignature" }],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.PreventionAdvisor],
                  required: true,
                  singleSelection: true,
                  index: 1,
                },
              },
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.HierarchicalLine],
                  singleSelection: true,
                  index: 2,
                },
              },
            ],
            [
              {
                name: "signatories",
                type: "signatorylist",
                props: {
                  types: [SignatoryType.Other],
                  index: 3,
                },
              },
            ],
          ],
          toggleFieldVisibility: () => {
            return [
              {
                needsSignature: {
                  default: "false",
                  false: [
                    "signingPreventionAdvisor",
                    "signingHierarchicalLine",
                    "signatories",
                  ],
                  true: [],
                },
              },
            ];
          },
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              {
                name: "documents",
                type: "document",
                onChange: (stateProxy) => SafetyInstructionCardWizard.onDocumentsChange(stateProxy),
              },
            ],
          ],
        },
      ],
      lookups: {
        safetyInstructionCardMap: {},
        legalStructureMap: {},
        branchMap: {},
      },
      addNewState: InstructionCardStatus.InProcess,
    },
    board: {
      lookups: {
        tagMap: {},
        safetyInstructionCards: [],
        employeeMap: {},
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        userRoleMap: {},
        labourmeanMap: {},
        equipmentMap: {},
        protectionEquipmentMap: {},
      },
      gridColums: [
        { name: "status" },
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "branch" },
        { name: "building" },
        { name: "location" },
        { name: "title" },
        { name: "description" },
        { name: "createdAt" },
        { name: "activationDate" },
        { name: "responsible" },
        { name: "coResponsibles" },
        { name: "subject_uniqueNumber" },
        { name: "subject_sort" },
        { name: "subject_kind" },
        { name: "subject_type" },
      ],
      cardProps: {
        showImage: true,
        showSubjectImage: true,
      },
      boardClass: SafetyInstructionCardBoard,
      bucketConfig: [
        {
          module: module,
          status: InstructionCardStatus.InProcess,
          onDrop: (state) =>
            SafetyInstructionCardActions.dropActions().onDropInProcess(state),
          requiredActions: ["set_inprocess"],
        },
      ],
      views: [BoardViewType.KanBan, BoardViewType.Grid, BoardViewType.Tree],
      reports: [
        {
          name: "SafetyInstructionCard",
          filter: cards => !cards.any(x => x.status !== InstructionCardStatus.InProcess),
        }
      ],
      documents: [
        {
          type: ObjectDocumentType.MainDocument,
          filter: cards => cards.any(x => ((((x.attachmentInfo || {}).attachments || []).filter((y : Attachment) => y.type == ObjectDocumentType.MainDocument) || {}).value || []).any((z : AttachmentValue) => !z.isDeleted)),
        }
      ]
    },
    cardClass: InstructionCardCard,
  };
};
export default getSafetyInstructionCardMetadata;
