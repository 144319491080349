import arxs from "infra/arxs";
import TaskLikeBoard from "modules/facilitymanagement/tasks/TaskLikeBoard";

class InspectionBoard extends TaskLikeBoard {
  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.inspections) {
        lookups.pristine = lookups.inspections
          .orderByDescending((card) => card.createdAt)
          .map((card) => ({
            ...card,
            activities: card.numberOfMessages,
            selected: () => !!stateProxy.getter("selected")[card.id],
          }));
        delete lookups.inspections;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new InspectionBoard();
