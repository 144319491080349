import React, { useState, useEffect } from "react";

import './Smileys.scss';

export default function Smileys(props) {
    const [data, setData] = useState();

    const values = [
        { value: 1, icon: "fas fa-grin", color: "status-completed" },
        { value: 2, icon: "fas fa-smile", color: "status-active" },
        { value: 3, icon: "fas fa-meh", color: "status-warning" },
        { value: 4, icon: "fas fa-angry", color: "status-severe" },
        { value: 5, icon: "fas fa-tired", color: "status-error" }
    ];

    useEffect(() => {
        props.onChange(data);
    }, [data]);

    const renderSmiley = (smiley) => {
        const selected = ((data || {}).value || (props.data || {}).value) === smiley.value;
        return <div key={smiley.value} className={`risk-smiley ${smiley.color} ${selected && "selected"}`} onClick={() => setData({ value: smiley.value })}>
            <i className={`${smiley.icon}`}></i>
        </div>
    }

    return <div className="risk-thumbs">
        {values.map(x => renderSmiley(x))}
    </div>
};