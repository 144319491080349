import React, { Component } from 'react';
import Badge from 'components/controls/Badge';

import './FolderButton.scss';

export default class FolderButton extends Component {
  handleClick = (event) => {
    if (this.props["onClick"]) {
      this.props.onClick(this.props.item);
    }
  }

  render() {
    const active = this.props.active;

    const item = this.props.item;
    const { label, className } = item;

    let count = typeof item.count === "function" ? item.count() : item.count;

    return <div
      className={`folder-button${active ? " active" : ""}${className ? ` ${className}` : ""}`}
      onClick={this.handleClick}
    >
      {label}
      {count !== undefined ? <Badge>{count}</Badge> : ""}
    </div>;
  }
}