const createPopupState = (getState, setState, props) => {
  const state = {
    state: {
      popups: [],
    },
    ...props,
    show: (props) => {
      const popup = createPopupState(getState, setState, props);
      const popups = (getState().popups || []).concat(popup);
      setState({ popups });
    },
    close: () => {
      const popups = (getState().popups || []).slice(0, -1);
      setState({ popups });
    },
  };
  return state;
};
export default createPopupState;
