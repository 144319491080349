import Icons from './regular.svg';

function loadIcons(arxs) {
  return fetch(Icons)
    .then(response => response.text())
    .then(x => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(x, "image/svg+xml");
      const symbols = dom.getElementsByTagName('symbol');
      const map = Array.prototype.slice.call(symbols).toDictionary(x => x.id, x => x.outerHTML.replaceAll("symbol", "svg"));
      return {
        get: (key) => map[key],
        getByModule: (module) => {
          const key = arxs.enums.icons[module];
          if (!key) {
            arxs.logger.error("Icon key not found for {module}", module);
            return null;
          }
          const icon = map[key];
          if (!icon) {
            arxs.logger.error("Icon not found for key {key}", key);
            return null;
          }
          return icon;
        }
      };
    });
}

export default loadIcons;