import React, { useState, useEffect } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';
import FieldSet from 'components/controls/FieldSet';
import { RelationshipType } from 'infra/api/contracts';

import './FollowUpTaskLikePopup.scss';


export const createFollowUpTaskLikePopup = (value, onSubmit, onCancel, title) => {
  const state = {
    title: title,
    content: <FollowUpTaskLikePopup value={value} onSubmit={onSubmit} onCancel={onCancel} />,
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function FollowUpTaskLikePopup(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    const value = props.value || {};
    setData({
      id: value.id,
      targetDate: value.targetDate,
      relationships: (value.relationships || []).filter(r => r.type === RelationshipType.Assignee),
    })
  }, [props.value]);

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => props.onSubmit && data && props.onSubmit(data);

  const rows = [
    [
      {
        name: "targetDate",
      },
    ],
    [{
      name: "relationships",
      type: "relationshiplist",
      noHeader: true,
      props: {
        types: [RelationshipType.Assignee],
      }
    },],
  ];

  return <div className={`followup-tasklike-popup ${props.className || ""}`}>
    <div className="followup-tasklike-popup-body">
      <FieldSet schemaName="FollowUpTask" rows={rows} data={data} setData={setData} />
      <div className="buttons">
        <Button key={`followup-tasklike-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`followup-tasklike-confirm`} className="icon" onClick={confirm} disabled={false}>{arxs.t("common.create")}</Button>
      </div>
    </div>
  </div>;
}