/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RelationshipType {
    CoResponsible = 'CoResponsible',
    Cc = 'Cc',
    Responsible = 'Responsible',
    PreventionAdvisor = 'PreventionAdvisor',
    Creator = 'Creator',
    RiskAnalysisParticipant = 'RiskAnalysisParticipant',
    Authorization = 'Authorization',
    Assignee = 'Assignee',
    Owner = 'Owner',
    PreventionAdvisorSignatory = 'PreventionAdvisorSignatory',
    HierarchicalLineSignatory = 'HierarchicalLineSignatory',
    OtherSignatory = 'OtherSignatory'
}

export function RelationshipTypeFromJSON(json: any): RelationshipType {
    return RelationshipTypeFromJSONTyped(json, false);
}

export function RelationshipTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelationshipType {
    return json as RelationshipType;
}

export function RelationshipTypeToJSON(value?: RelationshipType | null): any {
    return value as any;
}

