import React, { Component } from 'react';
import TreeView from 'components/controls/treeview/TreeView'
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import Toaster from 'components/util/Toaster';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import TagLabel from 'components/controls/tags/TagLabel'

import './TagTreeView.scss';
import { OriginModuleEnum } from 'infra/api/contracts';

class TagTreeView extends Component {
    lookups = {
        tags: [],
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups,
            selected: {},
            mobileTags: []
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ pristine: lookups.tags, }, this.refresh))
        };
    }

    refresh = () => {
        const { pristine, selected } = this.state;

        const tags = [...(pristine || [])];

        const mobileTags = tags.map(tag => ({
            ...tag, render: () => <TagLabel
                name={tag.name}
                id={tag.id}
                checkBox
                checked={!!selected[tag.id]}
                key={tag.id}
                readOnly />
        }));

        this.setState({ tags, mobileTags });
    }

    onItemClick = (item, children) => {
        this.props.onItemClick(item, children);
    }

    onSelectTag = (item) => {
        if (item && item.type === "Item") {
            const selected = this.state.selected;

            if (selected[item.id]) {
                delete selected[item.id];
            } else {
                selected[item.id] = true;
            }

            this.setState({ selected });

            this.props.onSelect(item.id);
        }
    }

    onItemsLoad = (items) => {
        if (this.props.onItemsLoad) {
            this.props.onItemsLoad(items);
        }
    }

    onAddItem = (value, typeId) => {
        const tags = this.state.tags;

        if (tags.some(x => !x.isDeleted && x.typeId === typeId && x.name.toLowerCase() === value.toLowerCase())) {
            Toaster.error(arxs.t("controls.tagtreeview.messages.error.double"));
            return;
        }

        // TODO: Do we wait for invalidation to see the new item
        //  , or render it immediately and have the invalidation loop refresh the list
        if (!!value) {
            arxs.ApiClient.shared.tags.add({ value, typeId })
                .then(() => Toaster.success(arxs.t("controls.tagtreeview.messages.saved")));
        }
    }

    onDeleteItem = (context, id) => {
        const confirmation = createInputPopup(context, arxs.t("tag_management.delete_confirmation"), () => arxs.ApiClient.shared.tags.delete(id)
            .then(() => Toaster.success(arxs.t("controls.tagtreeview.messages.deleted"))));

        context.inputPopup.show(confirmation);
    }

    onEditItem = (value, id) => {
        if (!!value) {
            arxs.ApiClient.shared.tags.edit(id, value)
                .then(() => Toaster.success(arxs.t("controls.tagtreeview.messages.saved")));
        } else {
            Toaster.error(arxs.t("controls.tagtreeview.messages.error.empty"))
        }
    }

    removeTagsFromStructure = (items) => {
        for (let item of items) {
            if (item.items && item.items.length > 0) {
                if (item.items[0].type === arxs.codeElementTypes.item) {
                    item.items = [];
                } else {
                    this.removeTagsFromStructure(item.items);
                }
            }
        }

        return items;
    };

    render() {
        const { tags, mobileTags } = this.state;

        return (
            <GlobalContext.Consumer>
                {(context) =>
                    <div className="tagtreeview">
                        <TreeView
                            items={context.platform.isMobile ? mobileTags : tags}
                            includeItems={context.platform.isMobile ? true : this.props.includeItems}
                            onItemClick={context.platform.isMobile ? this.onSelectTag : this.onItemClick}
                            onItemsLoad={this.onItemsLoad}
                            onAddNewItem={this.onAddItem}
                            onDeleteItem={this.onDeleteItem}
                            onEditItem={this.onEditItem}
                            itemIcon="fas fa-tag"
                            addItemText={arxs.t("controls.tagtreeview.add_item")}
                            allowEditLeafs={this.props.allowEdit && arxs.isActionAllowed("Tag.Write")}
                            allowEditBranches={this.props.allowEdit && arxs.isActionAllowed("Tag.Settings.Write")}
                            module={OriginModuleEnum.Tag}
                            securityContext={arxs.securityContext.buildForUserContext()}
                            // allowImportExport={true}
                            // allCodes={true}
                        />
                    </div>}
            </GlobalContext.Consumer>
        );
    }
}
export default TagTreeView;