import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import GlobalContext from 'infra/GlobalContext';
import DropDown from 'components/controls/DropDown';
import Toaster from 'components/util/Toaster';
import { Spinner } from 'components/animations/Spinner';

import './UserRoleRightsManagement.scss';

export const createRightsManagement = (role) => {
    const state = {
        title: `${arxs.t("actions.userrole.rights_management.title")} - ${role.name}`,
        content: <UserRoleRightsManagement role={role} />,
        fitContentWidth: true,
        // maximized: true
    };

    return state;
};

export class UserRoleRightsManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pristine: [],
            data: [],
            role: this.props.role
        };

    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentDidMount() {
        const { role } = this.state;
        arxs.ApiClient.masterdata.userRole.getRights(role.id)
            .then(x => this.setState({ pristine: x, data: x }));
    }

    refresh = () => {
    }

    onSubmit = async () => {
        const { role, data, pristine } = this.state;
        const changed = [];
        for (const entry of data) {
            const pristineActionId = pristine.filter(x => x.module === entry.module)[0].actionId;
            if (entry.actionId !== pristineActionId) {
                changed.push(entry);
            }
        }

        arxs.ApiClient.masterdata.userRole.setRights(role.id, changed)
            .then(() => Toaster.success(arxs.t("actions.userrole.rights_management.updated")));
    }

    onChange = (value, item) => {
        const { data } = this.state;

        const changed = data.map(entry => entry.module === item.module ? { ...entry, actionId: value && value.id } : entry);

        this.setState({ data: changed, changed });
    }

    render() {
        const { data, changed } = this.state;

        const submit = () => {
            return data && changed && <Fragment>
                <HorizontalSeparator />
                <div className="rightsmanagement-submit" onClick={this.onSubmit}>
                    {arxs.t("actions.userrole.rights_management.submit")}
                </div>
            </Fragment>;
        }

        const renderModule = (context, name) => {

            const title = arxs.modules.titles[name] || name;
            const icon = arxs.modules.icons[name];

            return <Fragment>
                <i className={icon}></i>
                <div className="rightsManagement-details-moduleentry-module-name">{title}</div>
            </Fragment>
        }

        const renderRights = (context, item) => {
            const items = item.availableRights.map(x => ({ id: x.actionId, name: arxs.t(`right.${x.right.replace(/\./g, '_').toLowerCase()}`) }));
            return <DropDown
                items={items}
                selected={items.filter(x => x.id === item.actionId)[0]}
                onChange={(value) => this.onChange(value, item)}
            />
        }

        const renderEntry = (context, entry) => {
            return <Fragment><div className="rightsmanagement-detials-moduleentry-module">{renderModule(context, entry.module)}</div>
                <div className="rightsmanagement-details-modulenetry-rights">{renderRights(context, entry)}</div>
            </Fragment>
        }


        const renderModules = (context) => {
            return data ? (data.filter(x => x.actionId)
                .orderBy(entry => arxs.modules.titles[entry.module] || entry.module))
                .concat(data.filter(x => !x.actionId)
                    .orderBy(entry => arxs.modules.titles[entry.module] || entry.module))
                .map(entry => <div className="rightsmanagement-details-moduleentry" key={entry.module}>
                    {renderEntry(context, entry)}
                </div>) : <div className='spinner-container'><Spinner /></div>;

        }

        return (
            <GlobalContext.Consumer>
                {(context) => <div className="rightsmanagement">
                    <div className="rightsmanagement-details">
                        {renderModules(context)}
                    </div>
                    {submit()}
                </div>}
            </GlobalContext.Consumer>
        );
    }
}