const createPromptPopupState = (setState, props) => {
  var state = {
    ...props,
    show: (props) => {
      setState(createPromptPopupState(setState, props));
    },
    close: () => {
      setState(createPromptPopupState(setState));
    }
  };
  return state;
};
export default createPromptPopupState; 