import { useEffect, useRef } from 'react';
// Hook
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export function useGatedInitialValue(setter, isLoaded, currentValue, initialValue) {
    const prevIsLoaded = usePrevious(isLoaded);

    useEffect(() => {
        if (isLoaded === false || prevIsLoaded === true) {
            return;
        }
        if (!currentValue) {
            setter(initialValue);
        }
    }, [isLoaded]);
}

export function createScheduledActionManager() {
    let disposed = false;
    
    const handleMap = {};
    const actionMap = {};

    const schedule = (name, handle, delay) => {
        if (disposed) {
            return;
        }
        
        const id = actionMap[name];
        if (id !== undefined) {
            clearTimeout(id);
        }
        actionMap[name] = setTimeout(handle, delay);
    };

    const throttle = (name, handle, delay) => {
        if (disposed) {
            return;
        }

        if (handleMap[name] === undefined) {
            actionMap[name] = setTimeout(() => {
                const handle = handleMap[name];
                delete handleMap[name];
                delete actionMap[name];
                handle();
            }, delay);
        }

        handleMap[name] = handle;
    };

    const dispose = () => {
        disposed = true;
        for (const id of Object.values(actionMap)) {
            clearTimeout(id);
        }
    };
    
    return {
        schedule,
        throttle,
        dispose
    };
}

export function useIsMounted() {
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}