import React, { useState } from 'react';
import arxs from 'infra/arxs';
import Button from 'components/controls/Button';

import Kinney from './Kinney';
import FourFactor from './FourFactor';
import Matrix from './Matrix';
import Thumbs from './Thumbs';
import Smileys from './Smileys';

import './RiskValueSelectionPopup.scss';

export const createRiskValueSelectionPopup = (method, value, onSubmit, onCancel) => {
  const getTitle = () => {
    switch (method) {
      case "kinney":
      case "4factor":
      case "matrix":
      case "thumbs":
      case "smileys":
        return arxs.t("controls.form.risk_analysis.popup_title", { method: `${arxs.t("controls.form.risk_analysis." + method)}` })
      default: arxs.t("controls.form.risk_analysis.popup_title_simple");
    }
  }
  const state = {
    title: getTitle(),
    content: <RiskValueSelectionPopup value={value} onSubmit={onSubmit} onCancel={onCancel} method={method} />,
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function RiskValueSelectionPopup(props) {
  const [data, setData] = useState({});

  const close = () => props.onCancel && props.onCancel();
  const confirm = () => props.onSubmit && props.onSubmit(data);

  const setNewData = (state) => {
    setData(state);
  }

  const renderRiskValueControl = () => {
    switch (props.method) {
      case "kinney": return <Kinney data={props.value} readOnly={false} onChange={setNewData} />;
      case "4factor": return <FourFactor data={props.value} readOnly={false} onChange={setNewData} />;
      case "matrix": return <Matrix data={props.value} readOnly={false} onChange={setNewData} />;
      case "thumbs": return <Thumbs data={props.value} readOnly={false} onChange={setNewData} />;
      case "smileys": return <Smileys data={props.value} readOnly={false} onChange={setNewData} />;
      default: break;
    }
  }

  return <div className={`risk-value-selection-popup ${props.className || ""}`}>
    <div className="risk-value-selection-popup-body">
      {renderRiskValueControl()}
      <div className="buttons">
        <Button key={`risk-value-selection-cancel`} className="icon alternative" onClick={close}>{arxs.t("common.cancel")}</Button>
        <Button key={`risk-value-selection-confirm`} className="icon" onClick={confirm}>{arxs.t("common.save")}</Button>
      </div>
    </div>
  </div>;
}