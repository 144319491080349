import React, { Component } from 'react';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';

import './SelectionLabel.scss';

export interface SelectionLabelProps {
    onClick(value: any): void
    title?: string,
}

export default class Selectionlabel extends Component<SelectionLabelProps> {
    render() {
        const { title } = this.props;

        return <GlobalContext.Consumer>
            {context => <div className="selection-label">
                <div className="selection-label-action"  onClick={this.props.onClick} >
                    {arxs.t("item_list.select_item", { name: title || "" })}<i className="fas fa-plus"></i>
                </div>
            </div>}
        </GlobalContext.Consumer>;
    }
}