import arxs from "infra/arxs";
import { OriginModuleEnum, TaskStatus } from "infra/api/contracts";
import TasklikeActions from "modules/facilitymanagement/tasks/TasklikeActions";
import { createCompleteRiskAnalysisPopup } from "modules/safety/riskanalysis/CompleteRiskAnalysisPopup";
import { createFollowUpTaskLikePopup } from "modules/facilitymanagement/tasks/FollowUpTaskLikePopup";
import { createAmendActualDurationTaskLikePopup } from "modules/facilitymanagement/tasks/AmendActualDurationTaskLikePopup";

class RiskAnalysisActions extends TasklikeActions {
  constructor() {
    super(OriginModuleEnum.RiskAnalysis);
  }

  getConfiguration(arxs) {
    return {
      path: "/riskanalysis",
      resource: arxs.ApiClient.safety.riskAnalysis,
      translations: {
        "completed_confirmation": arxs.t("actions.riskanalysis.completed_confirmation"),
        "not_all_required_fields_filled": "Niet alle verplichte vragen zijn beantwoord.",
        "activate_confirmation": arxs.t("actions.riskanalysis.activate_confirmation"),
        "activate_confirmation_question": arxs.t("actions.riskanalysis.activate_confirmation_question"),
        "toprocess_confirmation": arxs.t("actions.riskanalysis.toprocess_confirmation"),
        "toprocess_confirmation_question": arxs.t("actions.riskanalysis.toprocess_confirmation_question"),
        "verified_confirmation": arxs.t("actions.riskanalysis.verified_confirmation"),
        "verify_confirmation_question": arxs.t("actions.riskanalysis.verify_confirmation_question"),
        "hold_confirmation": arxs.t("actions.riskanalysis.hold_confirmation"),
        "hold_confirmation_question": arxs.t("actions.riskanalysis.hold_confirmation_question"),
        "unhold_confirmation": arxs.t("actions.riskanalysis.unhold_confirmation"),
        "unhold_confirmation_question": arxs.t("actions.riskanalysis.unhold_confirmation_question"),
        "create_follow_up_confirmation": arxs.t("actions.riskanalysis.create_follow_up_confirmation"),
        "create_follow_up": arxs.t("planning.follow_up_riskanalysis.title"),
        "no_subject": arxs.t("actions.riskanalysis.no_subject"),
        "create_task": arxs.actions.titles["create_riskanalysis"],
      },
      createCompletePopup: createCompleteRiskAnalysisPopup,
      createFollowUpPopup: createFollowUpTaskLikePopup,
      createAmendActualDurationPopup: createAmendActualDurationTaskLikePopup
    };
  }

  getGlobalActions(module) {
    let globalActions = [{
      name: "create_riskanalysis",
      module: module,
      icon: arxs.actions.icons["create_riskanalysis"],
      getTitle: () => arxs.actions.titles["create_riskanalysis"],
      onClick: (state) => this.createRiskAnalysis(state, module),
    }];
    return globalActions;
  }

  createRiskAnalysis = (state, module) => {
    return this.createTasklike(state, module, OriginModuleEnum.RiskAnalysis, "/riskanalysis/create");
  }
  
  dropActions = () => {
    return {
      onDropInInProcess: (state) => {
        this.setInProcess(state);
      },
      onDropInOnHold: (state) => {
        switch (state.item.status) {
          case TaskStatus.InProcess:
          case TaskStatus.ExecutionOverdue:
          case TaskStatus.ToVerify:
          case TaskStatus.Active:
            this.hold(state);
            break;
          default:
            return;
        }
      },
      onDropInActive: (state) => {
        switch (state.item.status) {
          case TaskStatus.InProcess:
            this.directActivate(state);
            break;
          case TaskStatus.OnHold:
          case TaskStatus.ToVerify:
            this.activate(state);
            break;
          default:
            return;
        }
      },
      onDropInCompleted: (state) => {
        switch (state.item.status) {
          case TaskStatus.InProcess:
          case TaskStatus.OnHold:
          case TaskStatus.ExecutionOverdue:
          case TaskStatus.Active:
            this.complete(state);
            break;
          case TaskStatus.ToVerify:
            this.verify(state);
            break;
          default:
            return;
        }
      },
      onDropInMultiYearPlan: (state)=> {
        this.transferToMultiYearPlan(state);
      }
    };
  };
}
export default new RiskAnalysisActions();
