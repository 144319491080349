import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";

export default class FormItemEditorRiskController extends FormItemEditorController {

    methods = [
        { id: "kinney", name: `${arxs.t("controls.form.risk_analysis.kinney")}` },
        { id: "4factor", name: `${arxs.t("controls.form.risk_analysis.4factor")}` },
        { id: "matrix", name: `${arxs.t("controls.form.risk_analysis.matrix")}` },
        { id: "smileys", name: `${arxs.t("controls.form.risk_analysis.smileys")}` },
        { id: "thumbs", name: `${arxs.t("controls.form.risk_analysis.thumbs")}` }
    ];
}