// LEAVE THIS! I know it seems obsolete, but this prevents a compiler error:
// ts(1228) all files must be modules when the isolatedmodules flag is true
export {};

// This is necessary so typescript realizes we want to extend the Array prototype
declare global {
  interface Date {
    withoutTime(): Date;
    subtractSeconds(numOfSeconds: number): Date;
    addSeconds(numOfSeconds: number): Date;
    format_yyyyMMddhhmmsshhh(d: Date): string;
  }
}

window.Date.prototype.withoutTime = function () {
  var d = new Date(this);
  d.setHours(0, 0, 0, 0);
  return d;
};

window.Date.prototype.subtractSeconds = function (numOfSeconds: number) {
  var d = new Date(this);
  d.setSeconds(d.getSeconds() - numOfSeconds);

  return d;
};

window.Date.prototype.addSeconds = function (numOfSeconds: number) {
  var d = new Date(this);
  d.setSeconds(d.getSeconds() + numOfSeconds);

  return d;
};

window.Date.prototype.format_yyyyMMddhhmmsshhh = function () {
  var d = new Date(this)
  function pad(num: any, size: number) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }

  return (
    d.getUTCFullYear() +
    pad(d.getUTCMonth() + 1, 2) +
    pad(d.getUTCDate(), 2) +
    pad(d.getUTCHours(), 2) +
    pad(d.getUTCMinutes(), 2) +
    pad(d.getUTCSeconds(), 2) +
    pad(d.getUTCMilliseconds(), 3)
  );
};
