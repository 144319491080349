// @ts-nocheck
import React, { Fragment } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { useDrop } from 'react-dnd';

import { Card } from './Card';
import Badge from 'components/controls/Badge';

import './Bucket.scss';

export interface BucketProps {
    title: string,
    cards: Array<any>,
    selected?: any,
    onToggle: (value: any) => void,
    onCheckChange: (value: any) => void,
    onDrop?: (item: IItem) => void,
    canDrop?: (item: IItem) => boolean,
    status?: string,
    getCardProps?: () => any,
    resolveSubject?: boolean,
    icon?: string,
    shortcut?: string,
    elementRef?: any,
}

export interface IItem {
    type: string,
    objectId: string,
    status: string,
    module: string,
    actions: Array<string>
}

export const Bucket: React.FC<BucketProps> = (props) => {

    const [, drop] = useDrop({
        accept: 'Card',
        drop: props.onDrop,
        canDrop: props.canDrop,
    });

    let header = <Fragment>
        {props.icon && <i className={props.icon} />}
        {props.title}
        <Badge>{props.cards.length}</Badge>
    </Fragment>;

    if (props.shortcut) {
        header = <Link to={props.shortcut}>
            {header}
            {props.shortcut && <Fragment>
                <div className="spacer"></div>
                <i className="fa fa-chevron-right" />
            </Fragment>}
        </Link>;
    }

    const scrollToIndex = props.cards
        .flatMap((x: any, i: number) => props.selected && x.id === props.selected.id ? [i] : [])[0];

    return (
        <div className="list-wrapper" ref={props.elementRef}>
            <div className="list" ref={drop}>
                <div className="list-header">
                    {header}
                </div>
                <div className="list-body">
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                width={width}
                                height={height}
                                scrollToIndex={scrollToIndex}
                                scrollToAlignment={"center"}
                                rowCount={props.cards.length}
                                rowHeight={165}
                                rowRenderer={
                                    ({ index, key, style }) => {
                                        const card = props.cards[index];
                                        return card && (<div key={key} style={style}>
                                            {props.resolveSubject
                                                ? <Card
                                                    objectId={card.id}
                                                    module={card.module}
                                                    checked={card.checked}
                                                    onToggle={props.onToggle}
                                                    onCheckChange={props.onCheckChange}
                                                    status={props.status}
                                                    actions={card.actions}
                                                    getCardProps={props.getCardProps}
                                                    selected={props.selected && props.selected.id === card.id}
                                                    />
                                                : <Card
                                                    data={card}
                                                    onToggle={props.onToggle}
                                                    checked={card.checked}
                                                    onCheckChange={props.onCheckChange}
                                                    status={props.status}
                                                    actions={card.actions}
                                                    getCardProps={props.getCardProps}
                                                    selected={props.selected && props.selected.id === card.id}
                                                    />
                                            }
                                        </div>);
                                    }
                                }
                            />
                        )}
                    </AutoSizer>
                </div>
            </div>
        </div>);
};
