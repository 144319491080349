/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PeriodicalStatus {
    InProcess = 'InProcess',
    OnHold = 'OnHold',
    Active = 'Active',
    Completed = 'Completed'
}

export function PeriodicalStatusFromJSON(json: any): PeriodicalStatus {
    return PeriodicalStatusFromJSONTyped(json, false);
}

export function PeriodicalStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PeriodicalStatus {
    return json as PeriodicalStatus;
}

export function PeriodicalStatusToJSON(value?: PeriodicalStatus | null): any {
    return value as any;
}

