import React from "react";

import arxs from "infra/arxs";

import { CardClassProps } from "components/card/CardContracts";

export default function CardInternalNumber(props: CardClassProps) {
    const { card } = props;

    const getSubject = () => {
        if (card.subject) {
            var subjectInfo = arxs.Api.lookups.resolveSubject(card.subject);
            return subjectInfo;
        }
    };
  
    const subject = getSubject();
    if (subject) {
        return subject.internalNumber && (
            <div>{subject.internalNumber}</div>
        );
    }

    if (card.internalName) {
        return <div>{card.internalName}</div>;
    }

    if (card.internalNumber) {
        return <div>{card.internalNumber}</div>;
    }

    if (card.internalReference) {
        return <div>{card.internalReference}</div>;
    }

    return <></>;
}