import React, { Component } from "react";
import ProgressRing from "../ProgressRing";
import GlobalContext from "infra/GlobalContext";
import { createDocumentPreview } from "./DocumentPreview";
import arxs from "infra/arxs";
import { TextArea } from "components/controls/TextArea";

import "./DocumentLabel.scss";
import { OriginModuleEnum } from "infra/api/contracts";

export default class DocumentLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUploading: true,
      isUploaded: false,
      fileType: this.getFileType(),
      readOnly: this.props["readOnly"],
      editing: false,
      value: this.props["name"] || "",
      dirtyValue: this.props["name"] || "",
      documentMetadata: arxs.moduleMetadataRegistry.get(OriginModuleEnum.Document),
      id: this.props["id"] || "",
    };
  }

  onDelete = (event) => {
    if (this.props["onDelete"]) {
      this.props.onDelete();
    }
  };

  onEdit = (event) => {
    event.stopPropagation();

    this.setState({ editing: true });
  };

  onSubmit = (event) => {
    const { dirtyValue, id } = this.state;
    this.setState({ editing: false, value: dirtyValue }, () =>
      this.triggerRename(dirtyValue, id)
    );
  };

  triggerRename = (value, id) => {
    if (this.props["onRename"]) {
      this.props.onRename(value, id);
    }
  };

  componentDidMount() {
    if (!this.props.status || this.props.status === "done") {
      this.setState({ isUploaded: true, isUploading: false });
    }
  }
  
  handleDragStart = (e) => {
    if (this.props.contentType === "weblink") {
      return;
    }

    let url = this.props.url || this.props.previewUrl || this.state.url;
    if (!url) {
      arxs.ApiClient.shared.attachment
        .retrieve({
          module: this.props.module,
          objectId: this.props.objectId,
          documentId: this.props.documentId,
          storedFileId: this.props.storedFileId
        })
        .then(x => {
          this.setState({ url: x.downloadUrl });
        });
    }

    if (url) {
      try
      {
        const uri = new URL(url);
        const segments = uri.pathname.split("/");
        const fileName = segments[segments.length - 1];
        const proxiedUrl = `${window.location.origin}/proxy/${encodeURIComponent(this.state.url)}`;
        e.dataTransfer.setData("DownloadURL", `application/octet-stream:${fileName}:${proxiedUrl}`);
      } catch {
        console.error("Failed to parse URL: ", this.state.url);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      switch (this.props.status) {
        case "done": this.setState({ isUploading: false, isUploaded: true }); break;
        case "uploading": this.setState({ isUploading: true, isUploaded: false }); break;
        default: break;
      }
    }

    if (prevProps.name !== this.props.name) {
      this.setState({ value: this.props.name, dirtyValue: this.props.name });
    }
  }

  getFileType = () => {
    const { contentType, name, previewUrl, documentId, storedFileId } = this.props;

    if (contentType) {
      if (contentType.startsWith("image")) {
        return "fas fa-image";
      } else {
        switch (contentType) {
          case "weblink":
            return "far fa-globe";
          case "application/pdf":
            return "far fa-file-pdf";
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "fas fa-file-word";
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "far fa-file-excel";
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return "far fa-file-powerpoint";
          case "application/x-zip-compressed":
          case "application/zip":
            return "far fa-file-archive";
          default:
            break;
        }
      }
    }

    if (name) {
      const extension = /(?:\.([^.]+))?$/.exec(name.toLowerCase())[1];
      switch (extension) {
        case "pdf":
          return "far fa-file-pdf";
        case "xls":
        case "xlst":
        case "xlsx":
          return "far fa-file-excel";
        case "doc":
        case "dot":
        case "docx":
        case "txt":
          return "fas fa-file-word";
        case "ppt":
        case "pptx":
          return "far fa-file-powerpoint";
        case "m4v":
        case "mp4":
        case "mov":
        case "mkv":
        case "avi":
        case "mpeg":
        case "mpg":
          return "far fa-file-video";
        default:
          break;
      }
    }

    if (documentId && !storedFileId && previewUrl) {
      return "far fa-globe";
    }

    return "far fa-file";
  };

  handlePreview = (context) => {
    const { isUploaded, documentMetadata } = this.state;
    const {
      name,
      contentType,
      documentId,
      storedFileId,
      module,
      objectId,
      id,
      isWeblink,
      url,
      attachmentId
    } = this.props;

    const renderPreview = () => {
      let fileType;
      let documentType;
      let isEditableExtension = false;

      if (contentType) {
        switch (contentType) {
          case "application/vnd.ms-excel":
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            fileType = "xlsx";
            documentType = "cell";
            isEditableExtension = true;
            break;
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            fileType = "pptx";
            documentType = "slide";
            isEditableExtension = true;
            break;
          case "application/msword":
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            fileType = "docx";
            documentType = "word";
            isEditableExtension = true;
            break;
          default:
            break;
        }
      } else {
        if (name) {
          const extension = /(?:\.([^.]+))?$/.exec(
            name.toLowerCase()
          )[1];
          switch (extension) {
            case "xls":
            case "xlst":
            case "xlsx":
              fileType = "xlsx";
              documentType = "cell";
              isEditableExtension = true;
              break;
            case "doc":
            case "dot":
            case "docx":
              fileType = "docx";
              documentType = "word";
              isEditableExtension = true;
              break;

            case "ppt":
            case "pptx":
              fileType = "pptx";
              documentType = "slide";
              isEditableExtension = true;
              break;
            default:
              break;
          }
        }
      }

      const editInfo = {
        getUrl: `${process.env.REACT_APP_API_ENDPOINT}/api/shared/attachment/getfile?module=${module}&objectId=${objectId}&attachmentid=${attachmentId}&tenantid=${arxs.Identity.profile.tenant}&userName=${arxs.Identity.profile.username}`,
        saveUrl: `${process.env.REACT_APP_API_ENDPOINT}/api/shared/attachment/saveeditedfile?attachmentid=${attachmentId}&name=${name}&module=${module}&objectId=${objectId}&tenantid=${arxs.Identity.profile.tenant}&userName=${arxs.Identity.profile.username}`,
        key: `${attachmentId}_${id}`,
        fileType: fileType,
        documentType: documentType,
      };

      const downloadInfo = {
        url: documentUrl,
      };
      const previewInfo = {
        url: previewUrl,
        name: name,
        documentManagementUrl:
          documentId &&
          Object.keys(arxs.Identity.profile?.allowedActions).some(
            (x) => x === "Document.Read"
          ) &&
          `${documentMetadata.base.route}/${documentId}`,
      };

      if (isUploaded) {
        if (previewUrl && previewUrl.length > 0) {
          const { module, objectId } = this.props;
          if (module) {
            if (objectId) {
              const moduleResource = arxs.moduleMetadataRegistry.getResourceByModule(module);
              moduleResource.getById(objectId).then((subject) => {
                let isEditAllowed = false;
                if (
                  isEditableExtension &&
                  subject &&
                  subject.actions &&
                  subject.actions.length > 0 &&
                  subject.actions.map(x => x.split(":")[0]).includes("edit") &&
                  !this.props.disableInlineEdit
                ) {
                  isEditAllowed = true;
                }
                let documentPreview = createDocumentPreview(
                  previewInfo,
                  downloadInfo,
                  isEditAllowed ? editInfo : undefined
                );
                this.setState({ documentPreview }, () =>
                  context.popup.show(this.state.documentPreview)
                );
              });
            } else {
              let documentPreview = createDocumentPreview(
                previewInfo,
                downloadInfo,
                false
              );
              this.setState({ documentPreview }, () =>
                context.popup.show(this.state.documentPreview)
              );
            }
          }
        } else {
          window.open(documentUrl, "_blank", "noopener noreferrer");
        }
      }
    }

    if (!module && !objectId) return;

    let documentUrl = "";
    let previewUrl = "";

    if (isWeblink) {
      window.open(url, "_blank", "noopener noreferrer");
    } else {
      if (documentId || storedFileId) {
        arxs.ApiClient.shared.attachment.retrieve({ module: module, objectId: objectId, documentId: documentId, storedFileId: storedFileId })
          .then((result) => {
            documentUrl = result.downloadUrl;
            previewUrl = result.previewUrl;
            if (documentUrl && previewUrl) {
              renderPreview()
            } else if (previewUrl || documentUrl) {
              window.open(previewUrl || documentUrl, "_blank", "noopener noreferrer");
            }
          })
      } else {
        documentUrl = this.props.previewUrl;
        previewUrl = this.props.previewUrl;
        renderPreview();
      }
    }
  };

  onKeyDown = (event) => {
    // This prevents upstream onKeyDown handlers from stealing keys (e.g. space selects treeview element)
    event.stopPropagation();

    switch (event.keyCode) {
      case 9:
      case 13:
        this.onSubmit(event);
        break;
      case 27:
        this.setState({ editing: false, dirtyValue: null });
        break;
      default:
        break;
    }
  };

  onBlur = (event) => {
    this.onSubmit(event);
  };

  preventDefaultClick = (event) => {
    event.stopPropagation();
  };

  render() {
    const { uploadAnimationPercentage, name, documentId } = this.props;

    const {
      isUploaded,
      isUploading,
      fileType,
      readOnly,
      editing,
      value,
      dirtyValue,
    } = this.state;

    const labelEditInput = (context) => {
      const field = {
        name: "document-name",
        getter: () => dirtyValue,
        setter: (value) => {
          this.setState({ dirtyValue: value });
        },
      };
      return (
        <div className="document-name-input">
          <TextArea
            autoFocus
            className="input"
            field={field}
            onClick={this.preventDefaultClick}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            placeholder={arxs.t("controls.document_label.empty")}
          />
        </div>
      );
    };

    const label = (context) => {
      if (editing) {
        return labelEditInput(context);
      } else {
        return (
          <div className="document-preview" key={value}>
            {value}
          </div>
        );
      }
    };

    const renderActions = () => {
      if (isUploaded && !readOnly) {
        return (
          <div className="document-label-actions">
            <div
              className="document-label-remove"
              title={arxs.t("controls.document_label.delete")}
              onClick={this.onDelete}
            >
              <i className="far fa-trash-alt"></i>
            </div>
            {!documentId && <div className="document-label-rename" onClick={this.onEdit}>
              <span title={arxs.t("controls.document_label.rename")}>
                <i className="fa fa-pencil"></i>
              </span>
            </div>}
          </div>
        );
      }
    };

    const archivedClassName = this.props.isArchived ? " archived" : "";

    return (
      <GlobalContext.Consumer>
        {(context) => (
          <a href={this.state.url} className={`document-label${archivedClassName}`} draggable={readOnly} onDragStart={this.handleDragStart} onClick={(e) => e.preventDefault()}>
            <div
              className={`document-file-type ${readOnly ? "readOnly" : "allowDelete"
                }`}
            >
              {isUploading && (
                <ProgressRing
                  radius={22}
                  stroke={2}
                  progress={uploadAnimationPercentage}
                  onClick={this.onDelete}
                  //https://trello.com/c/zaN1NRai/252-themestategetvariable-werkt-niet-meer-werd-maar-op-1-plaats-gebruikt-en-daar-wordt-het-nu-gebypassed-met-een-vaste-waarde
                  //color={context.theme.getVariable("--arxs-icon-color")}
                  color="#4d79fd"
                />
              )}
              {isUploaded && (
                <div
                  className={`document-file-type-icon ${documentId ? "document" : ""}`}
                  onClick={() => this.handlePreview(context)}
                >
                  <i className={fileType}></i>
                </div>
              )}{" "}
            </div>
            <div
              className="document-file-name"
              title={name}
              onClick={() => this.handlePreview(context)}
            >
              {isUploading && name}
              {isUploaded && label(context)}
            </div>
            {renderActions()}
          </a>
        )}
      </GlobalContext.Consumer>
    );
  }
}
