import React from 'react';

import arxs from 'infra/arxs';
import { SecurityContext } from 'infra/SecurityContext';
import { ChecklistModel } from './Contracts';
import { ChecklistItemModel } from './Contracts';

import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import GlobalContext from 'infra/GlobalContext';
import Checklist from './Checklist';

import './Checklists.scss';

export interface ChecklistsProps {
  readOnly?: boolean,
  editable?: boolean,
  value: ChecklistModel[],
  onChange(value: ChecklistModel[]): void,
  className?: string,
  securityContext: SecurityContext,
}

export default function Checklists(props: ChecklistsProps) {
  const handleAdd = (context: any) => {
    const value = props.value || [];

    const confirmAdd = (title: string) => {
      props.onChange(value.concat({ id: arxs.uuid.generate(), title, items: [] }));
    }
    const addPopup = createInputPopup(context, arxs.t("checklist.add_checklist_prompt"), confirmAdd, true, true);
    context.inputPopup.show(addPopup);
  };

  const handleChange = (original: ChecklistModel, modified?: ChecklistModel) => {
    const value = props.value || [];
    if (modified) {
      props.onChange(value.replace(original, modified));
    } else {
      props.onChange(value.remove(original));
    }
  };

  const handleDrop = (target: ChecklistItemModel, dragged: ChecklistItemModel) => {
    const checklists = props.value;

    const targetChecklist = checklists.filter(x => x.items.any(i => i.id === target.id))[0];
    const draggedChecklist = checklists.filter(x => x.items.any(i => i.id === dragged.id))[0];

    if (targetChecklist === draggedChecklist) {
      const newValue = {
        ...targetChecklist,
        items: (targetChecklist.items || [])
          .flatMap(x => x.id === target.id ? dragged : (x.id === dragged.id ? target : x))
      };

      handleChange(targetChecklist, newValue);
    } else {
      const newChecklists = checklists
        .replace(draggedChecklist, { ...draggedChecklist, items: draggedChecklist.items.filter(x => x.id !== dragged.id) })
        .replace(targetChecklist, { ...targetChecklist, items: targetChecklist.items.concat(dragged) })
        ;
      props.onChange(newChecklists);
    }
  }

  const { readOnly, editable, className, securityContext } = props;
  const value = props.value || [];

  return <GlobalContext.Consumer>
    {
      context => <div className={`checklists ${className || ''}`}>
        {value.map(checklist => <Checklist
          key={checklist.id}
          value={checklist}
          onChange={(modified) => handleChange(checklist, modified)}
          onDrop={handleDrop}
          readOnly={readOnly}
          editable={editable}
          securityContext={securityContext} />)}
        {!(readOnly || (editable === false)) && <div className="checklists-action-add" onClick={() => handleAdd(context)}>
          {arxs.t("checklist.add_checklist")} <i className="fas fa-plus"></i>
        </div>}
      </div>
    }
  </GlobalContext.Consumer>;
}