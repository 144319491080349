import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';
import { OriginModuleEnum } from 'infra/api/contracts';

class PeriodicalWizard extends WizardController {
    validate(stateProxy, getCurrentFieldValue, preValidation) {
        const reference = (getCurrentFieldValue("reference") || {}).notification;
        const schedule = getCurrentFieldValue("schedule");

        const getNotificationCountInDays = (pattern, numberOf) => {
            const now = new Date(new Date().setHours(0, 0, 0, 0));

            if (pattern === "Week") {
                numberOf = numberOf * 7;
            }

            switch (pattern) {
                case "Day":
                case "Week": return now.setDate(now.getDate() + numberOf);
                case "Month": return now.setMonth(now.getMonth() + numberOf);
                default: return;
            }
        }

        if (reference && reference.notificationCount && reference.notificationPattern) {
            if (schedule && schedule.notificationCount && schedule.notificationPattern) {
                const trueRefCount = getNotificationCountInDays(reference.notificationPattern, reference.notificationCount);
                const trueScheduleCount = getNotificationCountInDays(schedule.notificationPattern, schedule.notificationCount);
                if (trueRefCount > trueScheduleCount) {
                    preValidation["schedule"] = { error: arxs.t("wizard.validation.schedule_notification_too_late") };
                }
            };
        }

        return preValidation;
    }

    isReferenceSubjectCombinedInstallation = (stateProxy) => {
        const getValue = this.buildGetValue(stateProxy);
        const reference = getValue("reference");

        if (reference && reference.subjects && reference.subjects.some(x => x.module === OriginModuleEnum.CombinedInstallation)){
            return true;
        }

        return false;
    }
}
export default new PeriodicalWizard();
