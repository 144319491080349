import React, { Component, Fragment } from 'react';
import Collapsible from 'react-collapsible';

import arxs from 'infra/arxs';
import { RelationshipType, SignatoryType, MultiYearPlanStatus } from "infra/api/contracts";
import Field from 'components/controls/Field';
import ItemList from 'components/controls/ItemList';
import TagOverview from 'components/controls/tags/TagOverview';
import RelationshipList from 'components/controls/RelationshipList';
import CodeElementList from 'components/controls/codeElements/CodeElementList';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import CardList from 'components/controls/cardlist/CardList';
import { Card } from 'components/card/Card';
import Scheduler from "components/controls/scheduler/Scheduler";
import SignatoryList from 'components/controls/SignatoryList';

import './InformationTab.scss';

export default class InformationTab extends Component {
  constructor(props) {
    super(props);

    const metadata = arxs.moduleMetadataRegistry.get(this.props.module);
    this.state = { metadata };
  }

  lookups = {
    codeElementsById: {},
    legalStructureMap: {},
    branchMap: {},
    contactMap: {}
  }

  stateProxy = {
    getField: (propName) => {
      return this.props.pristine[propName];
    }
  }

  componentDidMount() {
    this.subscriptions = {
      lookups: arxs.Api.lookups
        .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  renderField = (def, getField) => {
    if (def.name === "planning") {
      const start = getField({ name: "start" }).getter();
      const end = getField({ name: "end" }).getter();

      const planning = start && end ? `${arxs.dateTime.formatLongDate(start)} ${arxs.dateTime.formatTime(start)}u - ${arxs.dateTime.formatTime(end)}u` : "";

      return <div className={`field full-width`} key="planning">
        <label>{arxs.t(`field.planning`)}</label>
        {`${planning}`}
      </div>
    }

    const field = getField(def);

    if (def.name === "contacts") {
      const contacts = (field.getter && field.getter()) || [];

      if (contacts && contacts.length > 0 && this.state.contactMap) {
        const contact = this.state.contactMap[contacts[0].id] || {};

        if (contact) {
          return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key={contact.id}><label>{arxs.t(`field.contact`)}</label>{`${contact.firstname} ${contact.surname}`}</div>
        }
      }
    }
    if (def.name === "tags") {
      const { module } = this.props;
      const tags = (field.getter && field.getter()) || [];
      return <TagOverview key={def.name} module={module} tags={tags} readOnly={true} />
    }
    if (def.name === "addresses") {
      const addresses = (field.getter && field.getter()) || [];
      const preferredAddress = addresses && ((addresses.some(x => x.isPreferred) && addresses.filter(x => x.isPreferred)[0])
        || addresses[0]);

      if (preferredAddress) {
        return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key={preferredAddress.id}><label>{arxs.t(`field.preferredAddress`)}</label>{`${preferredAddress.street} ${preferredAddress.number}`}<br />{`${preferredAddress.zipCode} ${preferredAddress.city}`}<br />{`${preferredAddress.country}`}</div>
      }
    }
    if (def.name === "emails") {
      const emails = (field.getter && field.getter()) || [];
      const preferredEmail = emails && ((emails.some(x => x.isPreferred) && emails.filter(x => x.isPreferred)[0])
        || emails[0]);

      if (preferredEmail) {
        return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key={preferredEmail.id}><label>{arxs.t(`field.preferredEmail`)}</label>{`${preferredEmail.email}`}</div>
      }
    }
    if (def.name === "phones") {
      const phones = (field.getter && field.getter()) || [];
      const preferredPhone = phones && ((phones.some(x => x.isPreferred) && phones.filter(x => x.isPreferred)[0])
        || phones[0]);

      if (preferredPhone) {
        return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key={preferredPhone.id}><label>{arxs.t(`field.preferredPhone`)}</label>{`${preferredPhone.number}`}</div>
      }
    }
    if (def.children) {
      return <ItemList key={def.name} field={field} title={def.title} names={def.children} readOnly={true} />;
    }
    if (def.name === "relationships") {
      const relationships = field.getter ? field.getter() : [];
      return <div className={"field full-width"} key={def.name}>
        <RelationshipList
          key={def.name}
          field={field}
          value={relationships}
          readOnly={true}
          types={(def.props || {}).types || [RelationshipType.Owner, RelationshipType.Responsible, RelationshipType.CoResponsible, RelationshipType.Cc, RelationshipType.PreventionAdvisor, RelationshipType.Assignee]}
          titles={(def.props || {}).titles || {}}
        /></div>
    }
    if (def.name === "signatories") {
      const signatories = field.getter ? field.getter() : [];
      const signatures = (getField({ name: "signatures" }).getter() || []);
      return <div className={"field full-width"} key={def.name}>
        <SignatoryList
          key={def.name}
          field={field}
          value={signatories}
          readOnly={true}
          types={(def.props || {}).types || [SignatoryType.PreventionAdvisor, SignatoryType.HierarchicalLine, SignatoryType.Other]}
          titles={(def.props || {}).titles || {}}
          signatures={signatures}
        /></div>
    }

    if (def.name === "sort" || def.name === "kind") {
      return;
    }
    if (def.name === "type") {
      const schemaName = this.state.metadata.base.name;
      const schema = arxs.Api.getSchema(schemaName) || {};
      const properties = schema.properties || {};

      const getArrayOrConvert = (x) => Array.isArray(x) ? x : (x || []);
      
      // The classic version of MYP used to support defining only sort or kind and leaving the rest blank
      // As such we want to display the first codeElement-field that is non-null in order "type -> kind -> sort"
      const type = ["type", "kind", "sort"]
        .map(name => properties[name] ? getField({ name }) : null)
        .flatMap(field => field && field.getter ? getArrayOrConvert(field.getter()) : [])
        .take(1);

      return <div className="field full-width" key="def.name">
        <label>{arxs.t(`field.category`)}</label>
        <CodeElementList readOnly={true} value={type} key={def.name} />
      </div>;
    }
    if (def.name === "incidentTime") {
      if (def.props && def.props.dateAndTime) {
        const incidentTime = field.getter ? field.getter() : "";
        return <Fragment key={def.name}>
          <div className={`field`} key="incidentDate">
            <div className="label">
              <label>{arxs.t(`field.incidentDateOnly`)}:</label>
            </div>
            <div className="value">
              {arxs.dateTime.formatDate(incidentTime)}
            </div>
          </div>
          <div>
            <div className={`field`} key="incidentTime">
              <div className="label">
                <label>{arxs.t(`field.incidentTimeOnly`)}:</label>
              </div>
              <div className="value">
                {arxs.dateTime.formatTime(incidentTime)}
              </div>
            </div>
          </div>
        </Fragment>;
      }
    }
    if (def.name === "inactivityDuration") {
      if (def.props && def.props.fromDate && def.props.toDate) {
        const fromDateDef = { name: def.props.fromDate };
        const fromDate = getField(fromDateDef);
        const toDateDef = { name: def.props.toDate };
        const toDate = getField(toDateDef);
        const inactivityDuration = fromDate.getter && toDate.getter && arxs.dateTime.daysBetween(fromDate.getter(), toDate.getter());
        return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key={def.name}>
          <label>{arxs.t(`field.inactivityDuration`)}</label>
          {`${inactivityDuration} ${arxs.t("unit.day")}`}
        </div>;
      }
    }
    if (def.name === "legalStructureAuthorizations"
      || def.name === "legalStructures") {
      const legalStructures = field.getter ? field.getter() : [];

      return <div className={`field full-width`} key="legalStructures">
        <div className="label">
          <label>{arxs.t(`field.legalStructures`)}:</label>
        </div>
        <div className="value">
          <CardList
            className="field"
            key="legalStructures"
            value={(legalStructures || []).map(x => ({
              id: x.id,
              module: "SchoolGroup",
              readOnly: true
            }))}
            readOnly={true}
            hideTitle={true}
            condensed={true} />
        </div>
      </div>
    }
    if (def.name === "branchAuthorizations"
      || def.name === "branches") {
      const branches = field.getter ? field.getter() : [];

      return <div className={`field full-width`} key="branches">
        <div className="label">
          <label>{arxs.t(`field.branches`)}:</label>
        </div>
        <div className="value">
          <CardList
            className="field"
            key="branches"
            value={(branches || []).map(x => ({
              id: x.id,
              module: "School",
              readOnly: true
            }))}
            readOnly={true}
            hideTitle={true}
            condensed={true} />
        </div>
      </div>
    }
    if (def.name === "employeeAuthorizations") {
      const employeeAuthorizations = field.getter ? field.getter() : [];

      return <div className={`field full-width`} key="employee">
        <div className="label">
          <label>{arxs.t(`field.employee`)}:</label>
        </div>
        <div className="value">
          <CardList
            className="field"
            key="employeeAuthorizations"
            value={employeeAuthorizations.map(x => ({
              id: x.id,
              module: "Employee",
              readOnly: true
            }))}
            readOnly={true}
            hideTitle={true}
            condensed={true} />
        </div>
      </div>
    }
    if (def.name === "userRoleAuthorizations") {
      const userRoleAuthorizations = field.getter ? field.getter() : [];

      return <div className={`field full-width`} key="userRole">
        <div className="label">
          <label>{arxs.t(`field.userRole`)}:</label>
        </div>
        <div className="value">
          <CardList
            className="field"
            key="userRoleAuthorizations"
            value={userRoleAuthorizations.map(x => ({
              id: x.id,
              module: "UserRole",
              readOnly: true
            }))}
            readOnly={true}
            hideTitle={true}
            condensed={true} />
        </div>
      </div>
    }
    if (def.name === "assignments") {
      const assignments = field.getter ? field.getter() : [];

      if (assignments
        && this.state.legalStructureMap
        && this.state.branchMap
        && this.state.codeElementsById) {
        return assignments.orderBy(x => !x.isPreferred)
          .map((x, i) => <div className="field-container-single-lines" key={x.id}>
            {i >= 1 && <HorizontalSeparator />}
            <div className={`field`} key="function">
              <div className="label">
                <label>{arxs.t(`field.function_fraction`)}:</label>
              </div>
              <div className="value">
                {`${x.function ? this.state.codeElementsById[x.function.id].name : ''}`} {x.fraction ? (<span>{x.fraction.numerator ? x.fraction.numerator : 0}/{x.fraction.denominator ? x.fraction.denominator : 0} </span>) : ''}
              </div>
            </div>
            <div className={`field`} key="division">
              <div className="label">
                <label>{arxs.t(`field.division`)}:</label>
              </div>
              <div className="value">{`${x.division ? this.state.codeElementsById[x.division.id].name : ''}`}
              </div>
            </div>
            <div className={`field`} key="statute">
              <div className="label">
                <label>{arxs.t(`field.statute`)}:</label>
              </div>
              <div className="value">{`${x.statute ? this.state.codeElementsById[x.statute.id].name : ''}`}
              </div>
            </div>
            <div className={`field`} key="legalstructure">
              <div className="label">
                <label>{arxs.t(`field.legalStructure`)}:</label>
              </div>
              <div className="value">{`${x.legalStructure && this.state.legalStructureMap[x.legalStructure.id] ? this.state.legalStructureMap[x.legalStructure.id].name : ''}`}
              </div>
            </div>
            <div className={`field`} key="branch">
              <div className="label">
                <label>{arxs.t(`field.branch`)}:</label>
              </div>
              <div className="value">{`${x.branch && this.state.branchMap[x.branch.id] ? this.state.branchMap[x.branch.id].name : ''}`}
              </div>
            </div>
          </div>)
      }
    }
    if (def.name === "energySupplies") {
      const energySupplies = field.getter ? field.getter() : [];
      return <div className="field full-width" key={`${def.name}_container`}>
        <label>{arxs.t(`field.energySupplies`)}</label>
        <CodeElementList readOnly={true} value={energySupplies} key={def.name} />
      </div>;
    }
    if (def.name === "reference") {
      let reference = field.getter ? JSON.parse(field.getter()) : {};

      return <div className={`field full-width`} key="reference">
        <div className="label">
          <label>{arxs.t(`field.reference`)}:</label>
        </div>
        <div className="value">
          <Card
            key="reference"
            data={reference}
            readOnly={true}
          />
        </div>
      </div>
    }
    if (def.name === "schedule") {
      const schedule = field.getter ? field.getter() : {};

      const props = {
        notificationPattern: {
          title: arxs.t("wizard.field.creation_pattern"),
        }
      };

      return <div className="field full-width" key={`${def.name}_container`}>
        <label>{arxs.t(`field.schedule`)}</label>
        <div className="value">
          <Scheduler
            title={arxs.t(`field.schedule`)}
            value={schedule}
            readOnly={true}
            props={props}
          />
        </div>
      </div>;
    }
    if (def.name === "period") {
      const end = getField({ name: "end" }).getter();
      const status = getField({ name: "status" }).getter();

      const period = (status !== MultiYearPlanStatus.ToPlan) ? end && new Date(end).getFullYear() : "";
      return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key="period"><label>{arxs.t(`field.period`)}</label>{`${period}`}</div>
    }
    if (def.name === "riskAnalysisValue") {
      let value = field.getter ? field.getter() : {};
      value = value === "" ? "{}" : value;
      const riskValue = JSON.parse(value).value;
      return <div className={`field ${field.fullWidth ? 'full-width' : ''}`} key="riskValue">
        <label>{arxs.t(`field.riskAnalysisValue`)}</label>
        {riskValue ? <div className='badge riskValue'>{riskValue}</div> : <div className='value' style={{ height: "30px" }} />}
      </div>
    }


    return <Field key={def.name} field={field} className="full-width" />
  }

  render() {
    const schemaName = this.state.metadata.base.name;
    const schema = arxs.Api.getSchema(schemaName) || {};
    const properties = schema.properties || {};

    const pristine = this.props.pristine;

    const getCurrentFieldValue = fieldName => pristine[fieldName];
    const getField = (def) => {
      const { name, fullWidth, title, unit } = def;
      const field = properties[name];

      const isComposedField = ["inactivityduration", "period"].includes(name.toLowerCase());

      if (!field && !isComposedField) {
        arxs.logger.info("Field {fieldName} not found in {schema}", name, schemaName);
        return { name };
      }

      const getter = () => getCurrentFieldValue(name);
      const setter = value => { };

      return {
        name: name,
        schema: field,
        required: schema.required && schema.required.indexOf(name) > -1,
        fullWidth: fullWidth,
        readOnly: true,
        title: title,
        getter,
        setter,
        props: def.props,
        unit: unit
      };
    };

    return <Fragment>
      {
        this.props.definitions
          .map((def, i) =>
            <Collapsible key={def.title || `hidden-${i}`} trigger={def.title || `hidden-${i}`} open={def.initiallyOpen} triggerOpenedClassName={`${!def.title ? "hide" : ""}`}>
              {(def.fields || [])
                .filter((def) => !def.isVisible || def.isVisible(this.stateProxy))
                .filter((def) => def.productType === undefined || (def.productType === arxs.productType))
                .map(field => this.renderField(field, getField))}
            </Collapsible>)
      }
    </Fragment>;
  }
}
