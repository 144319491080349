/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MultiYearPlanStatus {
    ToPlan = 'ToPlan',
    Planned = 'Planned',
    Completed = 'Completed',
    Overdue = 'Overdue',
    Closed = 'Closed'
}

export function MultiYearPlanStatusFromJSON(json: any): MultiYearPlanStatus {
    return MultiYearPlanStatusFromJSONTyped(json, false);
}

export function MultiYearPlanStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiYearPlanStatus {
    return json as MultiYearPlanStatus;
}

export function MultiYearPlanStatusToJSON(value?: MultiYearPlanStatus | null): any {
    return value as any;
}

