import React from 'react';

export default class RadioOption extends React.Component {
  render() {
    return (<div className="radio-group-option">
      <input
        type="radio"
        checked={this.props.value}
        onChange={this.props.onChange}
        disabled={this.props.readOnly}
      />
      {this.props.children}
    </div>)
  }
}