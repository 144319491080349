import React, { Component, Fragment } from "react";
import { FormValueAttachmentType, ObjectDocumentType } from "infra/api/contracts";
import { createUpload } from 'components/controls/upload/Upload';
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import Avatar from "components/controls/images/Avatar";
import arxs from "infra/arxs";
import { Image } from "components/controls/images/Image";
import { createImagePreview } from "components/controls/images/ImagePreview";
import DocumentLabel from "components/controls/documents/DocumentLabel";
import DropDownMenu from "components/controls/DropDownMenu";

const actionMap = {
    "create_task_onCompletion": { icon: "far fa-tasks", getTitle: () => arxs.t("actions.task.create"), requiredAction: "NotificationDefect.Write" },
    "create_myp_onCompletion": { icon: "far fa-globe", getTitle: () => arxs.t("kanban.actions.add_to_myp"), requiredAction: "GlobalPreventionPlan.Write" }
}


export default class FormItemController extends Component {
    lookups = {
        employeeMap: {},
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.lookups = {
            ...this.lookups,
            ...this.getAdditionalLookups(),
        };

        this.state = {
            ...this.lookups,
            ...this.getAdditionalState(),
            metadata: {}
        };

        this.serialize.bind(this);
        this.deserialize.bind(this);
        this.setter.bind(this);
        this.getter.bind(this);
        this.getSettings.bind(this);
    }

    getAdditionalLookups() {
        return {};
    }

    getAdditionalState() {
		return {};
	}

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };

        const metadata = this.props.module ? arxs.moduleMetadataRegistry.get(this.props.module) : {};

        this.setState({ metadata });
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh() {
    }

    serialize(deserializedValue) {
        throw new Error("Not implemented");
    }

    deserialize(serializedValue) {
        throw new Error("Not implemented");
    }

    setter(newValue) {
        const newData = { id: this.props.item.id, ...this.props.data, value: this.serialize(newValue) };
        this.props.onChange(newData);
    }

    getter() {
        if (!this.props.data || this.props.data.value === undefined) {
            return this.deserialize(this.props.item.data);
        }
        return this.deserialize((this.props.data || {}).value);
    }

    getSettings() {
        return JSON.parse(this.props.item.settings || "{}");
    }

    sanitizeValue = (value) => {
        if (value.indexOf("- ") === 0) {
            return value.substring(2);
        }
        if (value.indexOf("-") === 0) {
            return value.substring(1);
        }

        return value;
    }

    renderHeader = (title, titleOnly) => {
        const { data, attachmentsExpanded } = this.props;

        const numberOfComments = ((data || {}).attachments || []).filter(x => x.type === FormValueAttachmentType.Comment).length;
        const numberOfMedia = ((data || {}).attachments || []).filter(x => x.type === FormValueAttachmentType.Media).flatMap(media => media.value).length;
        const numberOfActions = ((data || {}).attachments || []).filter(x => x.type === FormValueAttachmentType.Action).length;

        return <div className="form-control-title">
            <div className="form-control-title-value">
                {this.props.required && <span> *</span>}{title}
            </div>
            {!titleOnly &&
                <div className="form-control-subtitle">
                    <i className="far fa-comment-edit"></i>
                    <span className="form-comment-count">
                        {numberOfComments}
                    </span>
                    <span className="form-bullet">•</span>
                    <i className="far fa-photo-video"></i>
                    <span className="form-media-count">
                        {numberOfMedia}
                    </span>
                    <span className="form-bullet">•</span>
                    <i className="far fa-exclamation-triangle"></i>
                    <span className="form-action-count">
                        {numberOfActions}
                    </span>
                    {(numberOfActions > 0 || numberOfMedia > 0 || numberOfComments > 0) &&
                        <div className="form-control-attachment-chevron" onClick={() => this.props.onExpandAttachments(!attachmentsExpanded)}>
                            {attachmentsExpanded && <i className="fas fa-chevron-double-up"></i>}
                            {!attachmentsExpanded && <i className="fas fa-chevron-double-down"></i>}
                        </div>
                    }
                </div>}
        </div>
    }

    renderFooter = (context) => {
        const { attachmentsExpanded } = this.props;
        return <Fragment>{attachmentsExpanded && this.renderAttachments(context)}
            {!this.props.readOnly && this.renderActionSection(context)}
        </Fragment>
    }

    renderAttachments = (context) => {
        const data = this.props.data || {};
        const { readOnly } = this.props;

        const renderAttachmentDefaults = (attachment) => {
            const employee = (this.state.employeeMap || {})[attachment.createdBy];

            return <Fragment>
                <label>{arxs.dateTime.timeAgo(attachment.createdAt)}</label>
                <div className="attachment-author">
                    <Avatar
                        src={((employee || {}).images || []).url}
                        fullName={(employee || {}).name}
                    />
                    <div className="attachment-author-name">{(employee || {}).name}</div>
                    {!readOnly && attachment.createdBy === arxs.Identity.profile.id && <div className="attachment-action" onClick={() => this.handleDeleteAttachment(context, attachment)}><i className="far fa-trash-alt"></i></div>}
                </div>
            </Fragment>
        }

        const renderComment = (attachment) => {
            return <div className="attachment-container">
                {renderAttachmentDefaults(attachment)}
                <div className="attachment-content">{attachment.value}</div>

            </div>
        }
        const renderAction = (attachment) => {
            return <div className="attachment-container">
                {renderAttachmentDefaults(attachment)}
                <div className="attachment-content"><i className="far fa-exclamation-triangle"></i> {actionMap[attachment.value].getTitle()}</div>

            </div>
        }

        const renderMedia = (attachment) => {
            let imageCollection = [];
            let imageResult = [];
            let documentResult = [];

            let counter = 0;

            if (attachment.value && Array.isArray(attachment.value)) {
                for (var record of attachment.value) {
                    counter++;
                    if (record && record.type && record.type === ObjectDocumentType.FormImage) {
                        imageCollection.push(record);
                        const resultImg = <Image
                            key={`image-${counter}`}
                            src={arxs.ImageProxy.resizeImage(record.previewUrl || record.url, 60, 60)}
                            alt={record.name}
                            className="preview"
                            onClick={() => context.popup.show(createImagePreview(imageCollection))}
                        />
                        imageResult.push(resultImg);
                    } else {
                        documentResult.push(<DocumentLabel
                            key={`document-${counter}`}
                            id={record.name}
                            name={record.name}
                            url={record.previewUrl}
                            previewUrl={record.previewUrl}
                            isUploaded={record.previewUrl ? true : false}
                            contentType={record.contentType}
                            readOnly={true}
                            documentId={record.documentId}
                            module={this.props.module}
                            objectId={this.props.objectId}
                            storedFileId={record.storedFileId}>
                        </DocumentLabel>)
                        documentResult.push(record.value)
                    }
                }
            }

            return <div className="attachment-container">
                {renderAttachmentDefaults(attachment)}
                <div className="attachment-content">
                    {imageResult.concat(documentResult)}
                </div>

            </div>
        }

        const renderAttachment = (attachment) => {
            switch (attachment.type) {
                case FormValueAttachmentType.Media: return renderMedia(attachment);
                case FormValueAttachmentType.Comment: return renderComment(attachment);
                case FormValueAttachmentType.Action: return renderAction(attachment);
                default: return;
            }
        }

        return <div className="form-control-attachments">
            {(data.attachments || []).map((attachment, i) => (<div className="form-control-attachments-row" key={`attachment-${i}`}>
                {renderAttachment(attachment)}
            </div>))}
        </div>
    }

    renderActionSection = (context) => {
        return <div className="form-control-options">
            <div className="form-control-options-right">
                <div className="form-control-options-option" onClick={() => this.handleAddComment(context)}><i className="far fa-comment-edit"></i>{arxs.t("forms.controls.add_note")}</div>
                <div className="form-control-options-option" onClick={() => this.handleAddFiles(context)}><i className="far fa-photo-video"></i>{arxs.t("forms.controls.add_media")}</div>
                <div className="form-control-options-option">{this.renderActions(context)}</div>
            </div>
        </div>;
    }

    getNewAttachment = (type, value) => {
        if (type === FormValueAttachmentType.Media) {
            const blobs = value.documents.map(x => ({ contentType: x.contentType, url: x.previewUrl, name: x.name, refId: arxs.uuid.generate(), type: x.type, hash: x.hash }));

            return { id: arxs.generateUniqueId(), type: type, value: blobs, createdBy: arxs.Identity.profile.id, createdAt: new Date().toISOString() };
        }

        return { id: arxs.generateUniqueId(), type: type, value: value, createdBy: arxs.Identity.profile.id, createdAt: new Date().toISOString() };
    }

    handleAddAction = (context, action) => {
        const data = this.props.data || {};
        const newData = { id: this.props.item.id, ...data, attachments: (data.attachments || []).concat(this.getNewAttachment(FormValueAttachmentType.Action, action)) };
        this.setState({ data: newData, attachmentsExpanded: true }, () => this.props.onChange(newData, true));
    }

    handleAddFiles = (context) => {
        const { metadata } = this.state;
        const data = this.props.data || {};

        const onSubmit = (media) => {
            const newData = { id: this.props.item.id, ...data, attachments: (data.attachments || []).concat(this.getNewAttachment(FormValueAttachmentType.Media, media)) };
            this.setState({ data: newData, attachmentsExpanded: true }, () => {
                context.popup.close();
                this.props.onChange(newData, true);
            });
        };

        const uploadPopup = createUpload(this.props.module, null, onSubmit, metadata.singleFileDocumentTypes, [], [ObjectDocumentType.FormImage, ObjectDocumentType.FormDocument]);
        context.popup.show(uploadPopup);
    }

    handleAddComment = (context) => {
        const data = this.props.data || {};

        const onSubmit = (comment) => {
            const newData = { id: this.props.item.id, ...data, attachments: (data.attachments || []).concat(this.getNewAttachment(FormValueAttachmentType.Comment, comment)) };
            this.setState({ data: newData, attachmentsExpanded: true }, () => this.props.onChange(newData, true));
        };

        const addCommentPopup = createInputPopup(
            context,
            arxs.t("forms.comment"),
            onSubmit,
            true,
            true,
            arxs.t("forms.comment_placeholder"),
            arxs.t("common.save"),
            arxs.t("common.cancel"),
            true
        );

        context.inputPopup.show(addCommentPopup);
    }

    handleDeleteAttachment = (context, attachment) => {
        const data = this.props.data || {};

        const attachments = data.attachments.filter(x => x.id !== attachment.id);

        const newData = { id: this.props.item.id, ...data, attachments };

        this.props.onChange(newData);
    }

    renderActions = (context) => {
        const { control } = this.props;

        if (control.type !== "instruction") {
            let actionItems = [];

            for (const actionKey of Object.keys(actionMap)) {
                if (arxs.isActionAllowed(actionMap[actionKey].requiredAction)) {
                    actionItems.push({ ...actionMap[actionKey], onClick: () => this.handleAddAction(context, actionKey) });
                }
            }

            return (<div className="form-item-actions">
                <DropDownMenu
                    id="form-item-actions-menu"
                    className="section action icon"
                    items={actionItems}
                    state={{
                        context: context,
                    }}>
                    <span>{arxs.t("kanban.actions.title")}</span>
                </DropDownMenu>
            </div>);
        }
    }

}