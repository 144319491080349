export function getOrRetry<T>(getter: () => T | null | undefined, retries?: number, delay?: number): Promise<T> {
  let retriesOrDefault = retries || 5;
  let delayOrDefault = delay || 100;
  return new Promise<T>((resolve, reject) => {
    function attempt(): void {
      const result = getter();
      if (result) {
        resolve(result);
      } else if (retriesOrDefault > 0) {
        setTimeout(attempt, delayOrDefault);
        retriesOrDefault--;
        delayOrDefault *= 2;
      } else {
        reject(new Error('Number of retries exhausted'));
      }
    }
    attempt();
  });
}