import React, { useState, useEffect } from "react";
import arxs from "infra/arxs";
import Button from "components/controls/Button";
import FieldSet from "components/controls/FieldSet";
import { AttachmentType, ObjectDocumentType } from "infra/api/contracts";

import "./CompleteTaskLikePopup.scss";

export const createCompleteTaskLikePopup = (
  value,
  onSubmit,
  onCancel,
  title,
  taskLikeModule,
  taskLikeMapName
) => {
  const state = {
    title: title,
    content: (
      <CompleteTaskLikePopup
        value={value}
        onSubmit={onSubmit}
        onCancel={onCancel}
        correlationKey={new Date().format_yyyyMMddhhmmsshhh()}
        module={taskLikeModule}
        taskLikeMapName={taskLikeMapName}
      />
    ),
    onSubmit: onSubmit,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function CompleteTaskLikePopup(props) {
  const [data, setData] = useState({ id: props.value.id });
  const [lookups] = useState({
    planningMomentsBySubjectMap: {},
    [props.taskLikeMapName]: {},
  });
  const [planningMomentsBySubjectMap, setPlanningMomentsBySubjectMap] =
    useState();
  const [map, setMap] = useState();
  const [taskLike, setTaskLike] = useState();

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          const lookup = values[key] || {};
          switch (key) {
            case "planningMomentsBySubjectMap":
              setPlanningMomentsBySubjectMap(lookup);
              break;
            case props.taskLikeMapName:
              setMap(lookup);
              break;
            default:
              break;
          }
        }
      }),
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  useEffect(() => {
    const { id } = props.value;

    let taskLike;

    if (map) {
      taskLike = map[id];

      if (taskLike) {
        setTaskLike({ ...taskLike });

        setData({
          id: id,
          actualDuration: taskLike.actualDuration,
          estimatedDuration: taskLike.estimatedDuration,
        });
      }
    }
  }, [map]);

  useEffect(() => {
    if (
      data.actualDuration &&
      data.end &&
      data.actualDuration != taskLike.actualDuration
    ) {
      const startTime =
        new Date(data.end).getTime() - data.actualDuration * 1000 * 60 * 60;

      setData({
        ...data,
        start: new Date(startTime),
      });
    }
  }, [data.actualDuration]);

  useEffect(() => {
    let startEnd;

    if (planningMomentsBySubjectMap && taskLike) {
      const planningMoments =
        planningMomentsBySubjectMap[`${props.module}:${taskLike.id}`] || [];
      const planningMoment = planningMoments.filter(
        (x) => x.start && x.end && !x.isDeleted
      )[0];
      if (planningMoment) {
        startEnd = { start: planningMoment.start, end: planningMoment.end };
      }
    }

    if (taskLike) {
      const endTime = (startEnd || {}).end || new Date();
      const duration = taskLike.actualDuration || taskLike.estimatedDuration || 1;
      const startTime = new Date(endTime).getTime() - duration * 1000 * 60 * 60;

      startEnd = { start: startTime, end: endTime, actualDuration: taskLike.actualDuration };
    }

    setData({
      ...data,
      start: (startEnd ? new Date(startEnd.start) : null),
      end: (startEnd ? new Date(startEnd.end) : null),
      actualDuration: (startEnd || {}).actualDuration,
    });
  }, [planningMomentsBySubjectMap, taskLike]);

  const close = () => props.onCancel && props.onCancel();
  const confirm = () =>
    props.onSubmit && data && data.start && data.end && props.onSubmit(data);

  const getAttachmentInfo = (value) => {
    let result = { attachments: [], storedFiles: [] };

    for (const type of value.map((x) => x.type).distinct((x) => x)) {
      let values = [];
      let storedFiles = [];

      for (const file of value.filter((x) => x.type === type)) {
        const newId = arxs.uuid.generate();
        values.push({
          id: newId,
          type: AttachmentType.StoredFile,
          props: { name: file.name },
          isDeleted: false,
        });
        storedFiles.push({
          id: newId,
          contentType: file.contentType,
          url: file.url,
          name: file.name,
          hash: file.hash,
        });
      }

      const attachmentValue = { type: type, value: values };
      result.attachments = (result.attachments || []).concat([attachmentValue]);
      result.storedFiles = (result.storedFiles || []).concat(storedFiles);
    }

    return result;
  };

  const rows = [
    [
      {
        name: "start",
        props: { showTime: true },
      },
      {
        name: "end",
        props: { showTime: true },
      },
    ],
    [
      {
        name: "actualCost",
      },
      {
        name: "actualDuration",
      },
    ],
    [
      {
        name: "remark",
        multiLine: true,
      },
    ],
    [
      {
        name: "attachments",
        type: "Files",
        serialize: (def, data, value) => {
          if (value) {
            const attachmentInfo = getAttachmentInfo(value);
            return {
              ...data,
              attachmentInfo,
            };
          }
          return {
            ...data,
            attachmentInfo: {},
          };
        },
        noHeader: true,
        props: {
          correlationKey: props.correlationKey,
          documentType: ObjectDocumentType.MainDocument,
        },
      },
    ],
  ];

  return (
    <div className={`complete-tasklike-popup ${props.className || ""}`}>
      <div className="complete-tasklike-popup-body">
        <FieldSet
          schemaName="CompleteTask"
          rows={rows}
          data={data}
          setData={setData}
        />
        <div className="buttons">
          <Button
            key={`complete-tasklike-cancel`}
            className="icon alternative"
            onClick={close}
          >
            {arxs.t("common.cancel")}
          </Button>
          <Button
            key={`complete-tasklike-confirm`}
            className="icon"
            onClick={confirm}
            disabled={
              !data.id || !data.start || !data.end 
            }
          >
            {arxs.t("common.complete")}
          </Button>
        </div>
      </div>
    </div>
  );
}
