import React, { useState, useEffect } from "react";

import './Thumbs.scss';

export default function Thumbs(props) {
    const [data, setData] = useState();

    const icon = "fas fa-thumbs-up";

    const values = [
        { value: 1, color: "status-completed" },
        { value: 2, color: "status-active" },
        { value: 3, color: "status-warning" },
        { value: 4, color: "status-severe" },
        { value: 5, color: "status-error" }
    ];

    useEffect(() => {
        props.onChange(data);
    }, [data]);

    const renderThumb = (thumb) => {
        const selected = ((data || {}).value || (props.data || {}).value) === thumb.value;
        return <div key={thumb.value} className={`risk-thumb ${thumb.color} ${selected && "selected"}`}
            onClick={() => setData({ value: thumb.value })}>
            <i className={`${icon}`}></i>
        </div>
    }

    return <div className="risk-thumbs">
        {values.map(x => renderThumb(x))}
    </div>
};