import React, { useRef, useState, useEffect } from 'react';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';

import Toaster from 'components/util/Toaster';
import Button from 'components/controls/Button';

import './Import.scss';

export default function Import(props) {
    const [metadata, setMetadata] = useState({});
    const [title, setTitle] = useState();
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (props.module) {
            setTitle(arxs.modules.titles[props.module]);
            setMetadata(arxs.moduleMetadataRegistry.get(props.module));
        }
    }, [props]);

    const inputRef = useRef()

    const getExportFormat = () => {
        Toaster.notify(arxs.t("settings.import.templateRequested"));

        metadata.settings.getImportResource()
            .getImportTemplate()
            .then((resObj) => {
                const objUrl = window.URL.createObjectURL(resObj);
                var fileLink = document.createElement("a");
                fileLink.href = objUrl;
                fileLink.download = `import.template.${title}.xlsx`;
                fileLink.click();
            });
    };

    const handleImport = (context, e) => {
        setErrors([]);
        inputRef.current.click();
    };

    const processImportResult = (result) => {
        if (result && result.length > 0) {
            setErrors(result);
        }
        else {
            Toaster.success(arxs.t("settings.import.success"));
        }
    }

    const executeImport = (e) => {
        e.stopPropagation();
        e.preventDefault();

        var file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');

                Toaster.notify(arxs.t("settings.import.started"));
                metadata.settings.getImportResource()
                    .importData({ base64File: base64String })
                    .then(result => processImportResult(result));

            };

            reader.readAsDataURL(file);
        }

        e.target.value = null;
    }

    const renderResult = (context) => {
        if (errors && errors.length !== 0) {
            return <ul className="validation-summary">
                {errors.map((e, i) => <li key={`validation-${i}`}>{e}</li>)}
            </ul>
        }
    }

    return <GlobalContext.Consumer>
        {(context) => <div className="import-settings">
            <div className="import-settings-body">
                <div className="import-settings-actions">
                    <Button className="import-settings-button export" onClick={() => getExportFormat()} title={arxs.t("settings.import.export_template")}>
                        <i className="fas fa-cloud-download-alt"></i>
                    </Button>
                    <input type='file' id='file' ref={inputRef} style={{ display: 'none' }} onChange={executeImport} />
                    <Button className="import-settings-button import" onClick={() => handleImport(context)} title={arxs.t("settings.import.title")}>
                        <i className="fas fa-cloud-upload-alt"></i>
                    </Button>
                </div>

                {renderResult(context)}
            </div>
        </div>}
    </GlobalContext.Consumer>;
}
