import React from "react";
import arxs from "infra/arxs";
import Field from "components/controls/Field";

export default function FieldSet(props) {
  const { schemaName, rows, data, setData } = props;

  const schema = arxs.Api.getSchema(schemaName);
  const fieldRows = rows.map((row) =>
    row.map((def) => ({
      name: def.name,
      type: def.type,
      props: def.props,
      schema: schema.properties[def.name] || arxs.Api.getSchema(def.type),
      getter: () =>
        def.deserialize ? def.deserialize(def, data) : data[def.name],
      setter: (value) =>
        setData(
          def.serialize
            ? def.serialize(def, data, value)
            : { ...data, [def.name]: value }
        ),
      multiLine: def.multiLine,
      noHeader: def.noHeader,
      values: def.values,
      unit: def.unit,
      isLoaded: def.isLoaded,
      readOnly: props.readOnly,
    }))
  );

  return (
    <>
      {fieldRows.map((row, i) => (
        <div key={i} className="row">
          {row.map((field) => (
            <Field key={field.name} field={field} noHeader={field.noHeader} props={field.props}  />
          ))}
        </div>
      ))}
    </>
  );
}
