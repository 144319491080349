import React, { Component } from 'react';
import Form from 'components/controls/form/Form';

import './FormTab.scss';

export default class FormTab extends Component {
    render() {
        const { card, module } = this.props;

        return <div className="form-tab">
            <Form
                key={`frm-${card.id}`}
                module={module}
                readOnly={true}
                cards={[card]}
            />
        </div>;
    }
}
