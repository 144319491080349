import DocumentSigningWizardController from 'modules/DocumentSigningWizardController';
import arxs from 'infra/arxs';

class DecommissioningWizard extends DocumentSigningWizardController {
    getRequiredFields(getCurrentFieldValue) {
        return [];
    }

    setTitleInitialValue = (stateProxy, override) => {
        const title = stateProxy.getField("title");
        const subject = stateProxy.getField("subject");
        if ((!title || override) && subject) {
            const subscription = arxs.Api.lookups.subscribeToId(subject.module, subject.id
                , (asset) => {
                    const uniqueNumber = asset.uniqueNumber;
                    stateProxy.setField("title", arxs.t("wizard.decommissioning.short_title", { uniqueNumber }));
                    subscription.dispose();
                });
        }
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        this.validateSignature(getCurrentFieldValue, preValidation);

        return preValidation;
    }
}
export default new DecommissioningWizard();
