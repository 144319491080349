import React from "react";
import { marked } from "marked";
import * as DOMPurify from "dompurify";

import FormItemController from "./FormItemController";
import GlobalContext from "infra/GlobalContext";

import "./Instruction.scss";

export default class Instruction extends FormItemController {
    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <div className="form-control instruction">
                    <div
                        className="markdown"
                        dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(marked.parse(this.props.title || "")),
                        }}
                    /></div>
            }
        </GlobalContext.Consumer>)
    }
}