import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import AssetActions from "../AssetActions";
import { OriginModuleEnum } from "infra/api/contracts";

class BuildingActions {
  getActions = (module) => {
    let actions = AssetActions.getActions(module);

    actions = actions.filter((x) => x.name !== "archive");

    actions.push({
      name: "archive",
      module: module,
      icon: arxs.actions.icons["archive"],
      getTitle: () => arxs.actions.titles["archive"],
      onClick: (state) => this.archive(state),
    });

    return actions;
  };

  archive = (state) => {
    const ids = state["ids"] || [state.item.objectId];

    const hazardousSubstances = state.stateProxy.getter("hazardousSubstances");

    const assetModules = [
      OriginModuleEnum.Labourmeans,
      OriginModuleEnum.Pbm,
      OriginModuleEnum.EquipmentInstallation,
      OriginModuleEnum.HazardousSubstance,
      OriginModuleEnum.IntangibleAsset,
      OriginModuleEnum.Room
    ];

    let unarchivableBuildings = [];

    const cards = state.cards || [];

    const cardsWithInboundAssetLinks = cards.filter((x) =>
      x.inboundLinks.some((y) => assetModules.includes(y.module))
    );

    const hazardousSubstancesWithBuildingUsed = hazardousSubstances
      .filter(x => ((x.storageDetails || []).location || {}).module === OriginModuleEnum.Building && ids.includes(((x.storageDetails || []).location ||{}).id))
      .map(x => x.uniqueNumber);

    if (hazardousSubstancesWithBuildingUsed.length !== 0) {
      unarchivableBuildings.push(...hazardousSubstancesWithBuildingUsed);
    }

    if (cardsWithInboundAssetLinks.length !== 0) {
      const promises = [];

      for (let i = 0; i < cardsWithInboundAssetLinks.length; i++) {
        const cardWithInboundAssetLinks = cardsWithInboundAssetLinks[0];
        const inboundAssetLinks = cardWithInboundAssetLinks.inboundLinks.filter(
          (x) => assetModules.includes(x.module)
        );

        for (let j = 0; j < inboundAssetLinks.length; j++) {
          const inboundAssetLink = inboundAssetLinks[j];
          const moduleInScope = inboundAssetLink.module;

          const promise = arxs.moduleMetadataRegistry
            .getResourceByModule(moduleInScope)
            .getById(inboundAssetLink.id)
            .then((x) => {
              if (
                !x.isDeleted &&
                !unarchivableBuildings.includes(
                  cardWithInboundAssetLinks.uniqueNumber
                )
              ) {
                unarchivableBuildings.push(
                  cardWithInboundAssetLinks.uniqueNumber
                );
              }
            });

          promises.push(promise);
        }
      }

      Promise.all(promises).then(() => {
        if (unarchivableBuildings.length !== 0) {
          Toaster.error(
            arxs.t("actions.archive_unarchivable_building", {
              buildings: unarchivableBuildings.join(", "),
            })
          );
        } else {
          this.doArchive(ids);
        }
      });
    } else if (!unarchivableBuildings.some(x => x)){
      this.doArchive(ids);
    }
  };

  doArchive = (ids) => {
    return arxs.moduleMetadataRegistry
      .getResourceByModule(OriginModuleEnum.Building)
      .archive(ids)
      .then(() => Toaster.success(arxs.t("actions.archive_confirmation")));
  };
}
export default new BuildingActions();
