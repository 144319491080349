import { process } from '@progress/kendo-data-query';
import Toaster from 'components/util/Toaster';

/*

export interface ColumnDefinition {
  field: string,
  orderIndex: integer,
  width: string,
}

export interface VisibleBucket {
}

export interface ViewItem {
  columns: Array<string>
  dataState: KendoDataState,
}

export interface ViewState {
  columnDefinitions: Array<ColumnDefinition>,
  visibleBuckets: Array<VisibleBucket>,
  searchTerm: string

  gridState: KendoGridState,

  activeViewDirty: boolean, 
  activeView: ViewItem,
  views: Array<ViewItem>,
  pristineViews: array<ViewItem>,
}

*/

const _dateIso8601Pattern = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

export default class Views {
  MODULE_SETTINGS_PREFIX = "board.views"
  MODULE_SETTINGS_USER_KEY = `${this.MODULE_SETTINGS_PREFIX}.user`;
  // Allows to define a key for global views, one for user-specific views
  // They are stored in the database with a user-affinity and only the user's settings are returned
  MODULE_SETTINGS_KEYS = [this.MODULE_SETTINGS_USER_KEY];

  constructor(arxs, createInputPopup, getState, setState, module) {
    this.arxs = arxs;
    this.createInputPopup = createInputPopup;

    this.KENDO_GRID_CUSTOM_OPERATORS = {
      "deq": (value, current) => arxs.dateTime.dateEquals(new Date(value), new Date(current))
    };

    if (!module) {
      console.log("Views.js is missing required module argument");
    }

    this.module = module;
    this.getState = getState;
    this.setStateRaw = setState;
    this.setState = (state) => {
      const serialized = this.serializeSettingsToLocalStorage(state);
      window.localStorage.setItem(`board-${module}`, serialized);
      return this.setStateRaw(state);
    };
    this.setStateAndPersist = (state) => {
      return setState(state)
        .then(() => arxs.Api.moduleSettings.save(this.serializeToModuleSetting(module, this.MODULE_SETTINGS_KEYS, getState())));
    };
    const state = getState();
    this.defaultColumnDefinitions = state.gridColumns.map(this.mapToColumnDefinition).toDictionary(field => field.name, field => ({ width: field.width, title: field.title }));
    this.defaultvisibleBuckets = state.buckets.toDictionary(bucket => bucket, _ => (true));
  }

  serializeSettingsToLocalStorage = (state) => {
    const boardState = this.getState();
    const activeView = state.activeView === undefined ? boardState.activeView : state.activeView;
    const dataState = state.gridState === undefined ? (boardState.gridState || {}).dataState : (state.gridState || {}).dataState;
    const columnDefinitions = state.columnDefinitions === undefined ? boardState.columnDefinitions : state.columnDefinitions;
    const visibleBuckets = state.visibleBuckets === undefined ? boardState.visibleBuckets : state.visibleBuckets;
    const serialized = JSON.stringify({
      activeView: (activeView || {}).title,
      searchTerm: state.searchTerm === undefined ? boardState.searchTerm : state.searchTerm,
      dataState: this.serializeDataState(dataState),
      columns: Object.keys(columnDefinitions || {}).map(col => ({ f: col, i: columnDefinitions[col].orderIndex, w: columnDefinitions[col].width })),
      buckets: Object.keys(visibleBuckets || {}).map(bucket => bucket),
    });
    return serialized;
  }

  deserializeSettingsFromLocalStorage = (serialized) => {
    const originalState = this.getState();
    const defaultDataState = { skip: 0, take: 60 };
    const dataState = serialized.dataState ? this.deserializeDataState(serialized.dataState) : defaultDataState;
    const state = {
      activeViewTitle: serialized.activeView || originalState.activeViewTitle,
      searchTerm: serialized.searchTerm || originalState.searchTerm,
      gridState: this.createGridState(originalState.visibleCards || [], dataState, false),
      columnDefinitions: serialized.columns ? serialized.columns.toDictionary(c => c.f, c => ({ orderIndex: c.i, width: c.w })) : originalState.columnDefinitions,
      visibleBuckets: serialized.buckets ? serialized.buckets.toDictionary(c => c, c => ({})) : originalState.visibleBuckets,
    };
    return state;
  }

  mapToColumnDefinition = (nameOrDefinition) => {
    if (typeof nameOrDefinition === "string") {
      return { name: nameOrDefinition };
    }
    return nameOrDefinition;
  };

  initialize = () => {
    const modulesAndKeys = this.MODULE_SETTINGS_KEYS.map(key => ({ module: this.module, key }));
    try {
      const serialized = JSON.parse(window.localStorage.getItem(`board-${this.module}`) || "{}");
      const state = this.deserializeSettingsFromLocalStorage(serialized);
      this.setStateRaw(state);
    } catch { }

    this.arxs.Api.moduleSettings.getValues(modulesAndKeys)
      .then(settings => {
        const state = this.deserializeFromModuleSetting(settings);
        const views = state.views || [];
        const activeViewTitle = this.getState().activeViewTitle;
        const activeView = views.filter(view => view.title === activeViewTitle)[0];
        this.setState({ views: state.views, activeView });
      });
  }

  detectChanges = () => {
    const state = this.getState();
    const { activeView } = state;

    let isDirty = false;
    if (activeView) {
      if (activeView.readOnly) {
        // If a view like "Standard" is selected, we can always save a new View
        isDirty = true;
      } else {
        // Otherwise the activeView needs to be dirty
        const stored = {
          columnDefinitions: activeView.columnDefinitions,
          visibleBuckets: activeView.visibleBuckets,
          dataState: activeView.dataState,
          searchTerm: activeView.searchTerm
        };
        const current = {
          columnDefinitions: state.columnDefinitions,
          visibleBuckets: state.visibleBuckets,
          dataState: state.gridState.dataState,
          searchTerm: state.searchTerm
        };

        isDirty = JSON.stringify(stored) !== JSON.stringify(current);
      }
    }

    if (state.activeViewDirty !== isDirty) {
      this.setStateRaw({ activeViewDirty: isDirty });
    }
  };

  serializeDataState = (dataState) => {
    const mapOperator = (operator) => {
      if (typeof operator === "function") {
        operator = Object.entries(this.KENDO_GRID_CUSTOM_OPERATORS).filter(x => x[1] === operator).map(x => x[0])[0];
        if (!operator) {
          this.arxs.logger.error("Operator of grid filter not found.", { dataState });
        }
      }

      return operator;
    }

    if (!dataState) {
      return dataState;
    }

    const newDataState = {
      skip: dataState.skip,
      take: dataState.take,
      group: dataState.group,
      sort: dataState.sort,
      filter: dataState.filter && {
        filters: dataState.filter.filters && dataState.filter.filters.map(columnFilter => ({
          logic: columnFilter.logic,
          filters: columnFilter.filters && columnFilter.filters.map(fieldFilter => ({ ...fieldFilter, operator: mapOperator(fieldFilter.operator) }))
        })),
        logic: dataState.filter.logic
      }
    }

    return newDataState;
  }

  deserializeDataState = (dataState) => {
    if (!dataState) {
      return;
    }

    if (dataState.filter && dataState.filter.filters && dataState.filter.filters.length > 0) {
      for (let columnFilter of dataState.filter.filters) {
        if (columnFilter.filters && columnFilter.filters.length > 0) {
          for (let fieldFilter of columnFilter.filters) {
            if (Object.keys(this.KENDO_GRID_CUSTOM_OPERATORS).some(x => x === fieldFilter.operator)) {
              if (new Date(fieldFilter.value)) {
                fieldFilter.value = new Date(fieldFilter.value);
              }
              fieldFilter.operator = this.KENDO_GRID_CUSTOM_OPERATORS[fieldFilter.operator || "deq"];
            } else if (fieldFilter.value && fieldFilter.value.length > 4 && _dateIso8601Pattern.test(fieldFilter.value)) {
              const date = new Date(fieldFilter.value);
              fieldFilter.value = date;
            }
          }
        }
      }
    }

    return dataState;
  }

  serializeToModuleSetting = (module, keys, state) => {
    return keys
      .map(key =>
      ({
        module,
        key,
        value: JSON.stringify({
          views: state.views
            .filter(x => x.title !== this.arxs.t("kanban.common.standard"))
            .filter(x => !x.key || x.key === key)
            .map(x => ({
              title: x.title,
              dataState: this.serializeDataState(x.dataState),
              columns: Object.keys(x.columnDefinitions).map(col => ({ f: col, i: x.columnDefinitions[col].orderIndex, w: x.columnDefinitions[col].width })),
              buckets: Object.keys(x.visibleBuckets).map(bucket => bucket),
              searchTerm: x.searchTerm
            }))
        })
      }));
  }

  deserializeFromModuleSetting = (settings) => {
    const views = [{ title: this.arxs.t("kanban.common.standard"), readOnly: true }]
      .concat(settings
        .flatMap(x => x.views
          .map(v => ({
            title: v.title,
            dataState: this.deserializeDataState(v.dataState),
            columnDefinitions: (v.columns || []).toDictionary(c => c.f, c => ({ orderIndex: c.i, width: c.w })),
            visibleBuckets: (v.buckets || []).toDictionary(c => c, c => ({})),
            searchTerm: v.searchTerm
          }))))
      ;
    const activeViewName = settings.reduce((acc, x) => acc || x.activeView, null);
    const activeView = views.filter(x => x.title === activeViewName)[0] || views[0];
    return { activeView, views };
  }

  createGridState = (data, dataState, doNotRefresh) => {
    const dataStateAllData = {
      ...dataState,
      skip: 0,
      take: data.length
    };
    return {
      result: doNotRefresh ? { data } : process(data, dataStateAllData),
      dataState: dataState
    };
  }

  changeView = (view) => {
    const state = this.getState();
    if (state.activeView === view) return;

    if (view.title === this.arxs.t("kanban.common.standard")) {
      this.resetToStandard();
    } else {
      this.setState({
        activeView: view,
        columnDefinitions: view.columnDefinitions || this.defaultColumnDefinitions,
        visibleBuckets: view.visibleBuckets || this.defaultvisibleBuckets,
        gridState: this.createGridState(state.visibleCards || [], view.dataState),
        searchTerm: view.searchTerm || ''
      });
    }
  }

  resetToStandard = () => {
    const state = this.getState();
    const view = {
      title: this.arxs.t("kanban.common.standard")
      , filter: {}
    }
    this.setState({
      activeView: view,
      columnDefinitions: this.defaultColumnDefinitions,
      visibleBuckets: this.defaultvisibleBuckets,
      gridState: this.createGridState(state.visibleCards || [], { skip: 0, take: 60 }),
      searchTerm: ''
    });
  }

  removeView = (context, view) => {
    const deleteView = () => {
      const state = this.getState();
      const views = state.views;
      const index = views.indexOf(view);
      views.splice(index, 1);

      if (state.activeView === view) {
        const activeView = views.filter(view => view.title === this.arxs.t("kanban.common.standard"))[0];
        this.setStateAndPersist({
          views,
          activeView,
          columnDefinitions: this.defaultColumnDefinitions,
          visibleBuckets: this.defaultvisibleBuckets,
          gridState: state.visibleCards && this.createGridState(state.visibleCards, {}),
          searchTerm: ''
        });
      } else {
        this.setStateAndPersist({ views });
      }

      Toaster.success(this.arxs.t("actions.views.delete_confirmation"));
    };

    const confirmation = this.createInputPopup(context, this.arxs.t("actions.views.delete_confirmation_question"), deleteView);

    context.inputPopup.show(confirmation);
  }

  renameView = (view, title, context) => {
    const state = this.getState();
    const views = state.views;

    const executeAction = (title) => {
      if (views.some(x => x.title.toLowerCase() === title.toLowerCase() && view.title !== title)) {
        Toaster.error(this.arxs.t("kanban.views.duplicatename"));
        return;
      }

      view.title = title;

      const activeView = state.activeView;

      const callback = () => {
        Toaster.success(this.arxs.t("kanban.views.saved"));
      };

      this.setStateAndPersist({ views, activeView }).then(callback);
    };

    if (!title) {
      const inputPopup = this.createInputPopup(context,
        this.arxs.t("controls.viewdropdown.rename"),
        (title) => executeAction(title),
        true,
        true,
        view.title,
        this.arxs.t("common.save"),
        this.arxs.t("common.cancel"),
        false,
        view.title);

      context.inputPopup.show(inputPopup);
    }
  }

  addView = (title, context) => {
    const state = this.getState();
    const views = state.views;

    const executeAction = (title) => {
      if (views.some(x => x.title.toLowerCase() === title.toLowerCase())) {
        Toaster.error(this.arxs.t("kanban.views.duplicatename", { name: title }));
        return;
      }

      let match = views.filter(x => x.title === title)[0];
      if (!match) {
        match = {
          title,
          columnDefinitions: state.columnDefinitions,
          dataState: state.gridState && state.gridState.dataState,
          visibleBuckets: state.visibleBuckets,
          searchTerm: state.searchTerm
        };
        views.push(match);
      }

      const callback = () => {
        Toaster.success(this.arxs.t("kanban.views.saved"));
      };

      return this.setStateAndPersist({ views, activeView: match }).then(callback);
    };

    if (!title) {
      const inputPopup = this.createInputPopup(context,
        this.arxs.t("controls.viewdropdown.add"),
        (title) => executeAction(title),
        true,
        true,
        '',
        this.arxs.t("common.save"),
        this.arxs.t("common.cancel"),
        false);

      context.inputPopup.show(inputPopup);
    }
  }

  saveView = () => {
    const state = this.getState();
    const activeView = state.activeView;
    const callback = () => {
      Toaster.success(this.arxs.t("kanban.views.saved"));
    };
    if (activeView && !activeView.readOnly) {
      activeView.columnDefinitions = { ...state.columnDefinitions };
      activeView.dataState = { ...state.gridState.dataState };
      activeView.visibleBuckets = { ...state.visibleBuckets };
      activeView.searchTerm = state.searchTerm;
      this.setStateAndPersist({ activeView }).then(callback);
    } else {
      this.addView().then(callback);
    }
  }
}
