import React, { useState, useEffect } from 'react';
import Field from 'components/controls/Field';
import arxs from 'infra/arxs';

import './Notification.scss';

function deserialize(data) {
  return {
    notificationPattern: data ? data.notificationPattern : null,
    notificationCount: data ? data.notificationCount : 1,
  };
}

function serialize(data) {
  if ((data && data.notificationPattern === null) || data.notificationCount === 0) {
    return undefined;
  }

  return {
    notificationPattern: data.notificationPattern,
    notificationCount: data.notificationCount,
  };
}

export default function Notification(props) {
  const { onChange } = props;
  const schema = arxs.Api.getSchema("Notification");

  const [data, setData] = useState(null);

  const getFieldValue = (fieldName) => data ? data[fieldName] : undefined;

  useEffect(() => {
    if (!data && props.value) {
      setData(deserialize(props.value));
    }
  }, [props.value, data]);

  const getField = (fieldName) => {
    return {
      name: fieldName,
      title: ((props ||{})[fieldName] || {}).title || "",
      code: "",
      schema: schema.properties[fieldName],
      readOnly: props.readOnly,
      getter: () => getFieldValue(fieldName),
      setter: fieldValue => {
        if (onChange) {
          const newData = { ...data, [fieldName]: fieldValue };
          setData(newData);
          onChange(serialize(newData));
        }
      },
    };
  };

  return (
    <div className={`notification ${props.className || ""}`}>
      <div className="row">
        <Field field={getField("notificationPattern")} />
        <Field field={getField("notificationCount")} />
      </div>
    </div>
  );
}