import React, { Component } from "react";
import { Activities } from "components/shell/DetailsPane/Activities";

import CardDataSource from "infra/CardDataSource";
import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";

import ActivityOverviewObjects from "./ActivityOverviewObjects";

import "./ActivityOverview.scss";
import _ from "lodash";

class ActivityOverview extends Component {
  constructor(props) {
    super(props);

    let criteria = {
      searchTerm: () => this.state.searchTerm,
      modules: () =>
        arxs.moduleMetadataRegistry.supportedModules.toDictionary(
          (x) => x,
          (x) => x
        ),
    };

    this.state = {
      dataSource: new CardDataSource(criteria),
      cards: [],
    };
  }

  componentDidMount() {
    this.state.dataSource.setRefresh(this.refresh);

    arxs.ApiClient.shared.activities
      .objectsWithActivities()
      .then((objects) => this.setState({ objects }, this.refresh));
  }

  componentWillUnmount() {
    if (this.state.dataSource) {
      this.state.dataSource.dispose();
    }
  }

  refresh = () => {
    const objects = this.state.objects;
    if (!objects) return;

    const idMap = objects.toDictionary(
        (x) => x.object.id,
        (x) => x
    );

    const cards = this.state.dataSource.get(
        (x) => idMap[x.id],
        (x) => idMap[x.id].lastMessage,
        true
    );

    this.setState({ cards });
  }

  handleSelect = (card) => {
    this.setState({ selected: card });
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="activity-overview-wrapper">
            {(!context.platform.isPhone || !this.state.selected)
              && (<ActivityOverviewObjects
                  objects={this.state.objects}
                  cards={this.state.cards}
                  selected={this.state.selected}
                  onSelectCard={this.handleSelect}
                  searchTerm={this.state.searchTerm}
                  onChangeSearchTerm={(searchTerm) => this.setState({ searchTerm }, this.refresh)}
                />)}
            {(!context.platform.isPhone || this.state.selected)
              && (<div className="activity-overview-object-activities-pane">
                {this.state.selected && (
                  <div className="activity-overview-object-activities-list">
                    <Activities
                      objectId={this.state.selected.id}
                      module={this.state.selected.module}
                      inputLocationAtBottom
                    />
                  </div>
                )}
                {context.platform.isPhone && <button className="icon" onClick={() => this.handleSelect(null)}>
                    <i className="fas fa-chevron-left"></i>
                  </button>}
              </div>)}              
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
export default ActivityOverview;

// This allows to use the context inside of the component via this.context
ActivityOverview.contextType = GlobalContext;