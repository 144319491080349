import React, { useState } from 'react';
import Dropzone from 'react-dropzone-uploader';

import arxs from 'infra/arxs';
import { ObjectDocumentType } from 'infra/api/contracts';

import Toaster from 'components/util/Toaster';
import DocumentLabel from 'components/controls/documents/DocumentLabel';

import "./FileDropzone.scss";

const determineDocumentType = (fileName, overrideDocumentType) => {
  const pattern = /(?:\.([^.]+))?$/;
  const extension = pattern.exec(fileName.toLowerCase())[1];
  switch (extension) {
    case "png":
    case "jpg":
    case "jpeg":
    case "jpe":
    case "jfif":
    case "jfi":
    case "gif":
    case "bmp":
    case "tif":
    case "tiff":
    case "webp":
      return ObjectDocumentType.Image;
    default:
      return overrideDocumentType || ObjectDocumentType.AdditionalDocument;
  }
};

export default function FileDropzone(props) {
  const [fileUrlMap, setFileUrlMap] = useState({});
  const [uploadFileMeta, setUploadFileMeta] = useState([]);

  const { correlationKey } = props;
  const overrideDocumentType = props.documentType;

  const toBlobTagsString = (tags) => {
    if (tags === undefined) {
      return undefined;
    }

    const tagPairs = [];
    for (const key in tags) {
      if (Object.prototype.hasOwnProperty.call(tags, key)) {
        const value = tags[key];
        tagPairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }

    return tagPairs.join("&");
  }

  const getUploadParams = async ({ file, meta }) => {
    const documentType = determineDocumentType(meta.name, overrideDocumentType);
    const putAuthorization = await arxs.ApiClient.shared.blob.getPutAuthorization(meta.name, documentType);

    setFileUrlMap({ ...fileUrlMap, [meta.name]: putAuthorization });

    const tags = { correlationKey: correlationKey };

    const params = {
      meta: meta,
      url: putAuthorization,
      method: 'PUT',
      body: file,
      headers: {
        'x-ms-date': new Date().toISOString(),
        'x-ms-version': '2019-12-12',
        'x-ms-blob-type': 'BlockBlob',
        'x-ms-tags': toBlobTagsString(tags)
      }
    };

    return params;
  }

  const triggerOnChange = (uploadFileMeta) => {
    if (props.onChange) {
      // Matches UploadApi.Image and UploadApi.Document
      props.onChange(uploadFileMeta.map(meta => ({
        url: meta.blobUrl.split('?')[0],
        hash: meta.md5,
        contentType: meta.meta.type,
        name: meta.title || meta.meta.name,
        type: meta.documentType,
        isPreferred: false,
      })));
    }
  }

  const handleChangeStatus = (fileWithMeta, status) => {
    const meta = fileWithMeta.meta;
    const xhr = fileWithMeta.xhr;
    const documentType = determineDocumentType(meta.name, overrideDocumentType);

    const cancelAndRemove = () => {
      fileWithMeta.cancel();
      fileWithMeta.remove();
    }
    let newUploadFileMeta = [...uploadFileMeta];

    switch (status) {
      case "done":
        newUploadFileMeta = newUploadFileMeta
          .concat({
            meta,
            blobUrl: xhr.responseURL,
            md5: xhr.getResponseHeader("Content-MD5"),
            documentType: documentType
          });

        triggerOnChange(newUploadFileMeta);
        break;
      case "removed":
        const metaToRemove = newUploadFileMeta.find((m) => m.meta.id === meta.id);
        const toRemove = newUploadFileMeta.indexOf(metaToRemove);
        if (toRemove > -1) {
          newUploadFileMeta.splice(toRemove, 1);
        }

        triggerOnChange(newUploadFileMeta);
        break;
      case "rejected_file_type":
        Toaster.error(arxs.t("controls.upload.error.not_allowed"));
        break;
      case "error_upload":
        Toaster.error(arxs.t("controls.upload.error.upload_error"));
        break;
      case "error_file_size":
        Toaster.error(arxs.t("controls.upload.error.upload_error_filesize"));
        cancelAndRemove();
        break;
      default:
        break;
    }
    setUploadFileMeta(newUploadFileMeta);
  };

  const layout = ({ input, previews, submitButton, dropzoneProps, files }) => {
    return (
      <div {...dropzoneProps}>
        <div className="upload-dropzone-body">
          {input}
          {previews}
        </div>
      </div>
    )
  };

  const previewComponent = (props) => {
    const { meta, fileWithMeta } = props;
    const documentType = determineDocumentType(meta.name, overrideDocumentType);
    const { name, percent } = meta

    let instanceMeta = uploadFileMeta.find((m) => m.meta.id === meta.id) || {};

    const cancelAndRemove = () => {
      fileWithMeta.cancel();
      fileWithMeta.remove();
    };

    const onRename = (value, id) => {
      instanceMeta.title = value;
      uploadFileMeta[uploadFileMeta.indexOf(instanceMeta)] = instanceMeta;

      setUploadFileMeta(uploadFileMeta);
    }

    return (
      <DocumentLabel
        id={name}
        contentType={instanceMeta && instanceMeta.meta && instanceMeta.meta.type}
        documentType={documentType}
        name={instanceMeta.title || name}
        uploadAnimationPercentage={percent}
        onDelete={cancelAndRemove}
        allowEdit
        onRename={onRename}>
      </DocumentLabel>
    );
  };

  const inputComponent = ({ accept, files, getFilesFromEvent, onFiles }) => {
    const input = (
      <input
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        multiple
        onChange={e => {
          const chosenFiles = getFilesFromEvent(e);
          onFiles(chosenFiles);
        }}
      />
    );

    const empty = (
      <label key="upload-input" className="upload-input-empty">
        <i className="fas fa-plus"></i>
        <h3>{arxs.t("controls.upload.input")}</h3>
        {input}
      </label>
    );

    const notEmpty = (
      <label key="upload-input" className="upload-input">
        <i className="fas fa-plus"></i>
        {input}
      </label>
    );

    return files && files.length > 0 ? notEmpty : empty;
  };

  return <Dropzone
    getUploadParams={getUploadParams}
    onChangeStatus={handleChangeStatus}
    LayoutComponent={layout}
    PreviewComponent={previewComponent}
    InputComponent={inputComponent}
    minSizeBytes={1}
  ></Dropzone>;
}