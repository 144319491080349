import OlMap from 'ol/Map';
import OlLayerGroup from 'ol/layer/Group';
import { METERS_PER_UNIT } from 'ol/proj/Units';

import arxs from 'infra/arxs';

/**
 * Helper class for the OpenLayers map.
 *
 * @class
 */
export class MapUtil {

  /**
   * Calculates the appropriate map resolution for a given scale in the given
   * units.
   *
   * See: https://gis.stackexchange.com/questions/158435/
   * how-to-get-current-scale-in-openlayers-3
   *
   * @method
   * @param {number} scale The input scale to calculate the appropriate
   *                       resolution for.
   * @param {string} units The units to use for calculation (m or degrees).
   * @return {number} The calculated resolution.
   */
  static getResolutionForScale(scale, units) {
    let dpi = 25.4 / 0.28;
    let mpu = METERS_PER_UNIT[units];
    let inchesPerMeter = 39.37;

    return parseFloat(scale) / (mpu * inchesPerMeter * dpi);
  }

  /**
   * Returns the appropriate scale for the given resolution and units.
   *
   * @method
   * @param {number} resolution The resolutions to calculate the scale for.
   * @param {string} units The units the resolution is based on, typically
   *                       either 'm' or 'degrees'.
   * @return {number} The appropriate scale.
   */
  static getScaleForResolution(resolution, units) {
    var dpi = 25.4 / 0.28;
    var mpu = METERS_PER_UNIT[units];
    var inchesPerMeter = 39.37;

    return parseFloat(resolution) * mpu * inchesPerMeter * dpi;
  }

  /**
   * Returns all layers of a collection. Even the hidden ones.
   *
   * @param {ol.Map|ol.layer.Group} collection The collection to get the layers
   *                                           from. This can be an ol.layer.Group
   *                                           or an ol.Map.
   * @param {Function} [filter] A filter function that receives the layer.
   *                            If it returns true it will be included in the
   *                            returned layers.
   * @return {Array} An array of all Layers.
   */
  static getAllLayers(collection, filter = (() => true)) {
    if (!(collection instanceof OlMap) && !(collection instanceof OlLayerGroup)) {
      arxs.logger.error('Input parameter collection must be from type `ol.Map`' +
        'or `ol.layer.Group`.');
      return [];
    }

    var layers = collection.getLayers().getArray();
    var allLayers = [];

    layers.forEach(function (layer) {
      if (layer instanceof OlLayerGroup) {
        MapUtil.getAllLayers(layer).forEach((layeri) => {
          if (filter(layeri)) {
            allLayers.push(layeri);
          }
        });
      }
      if (filter(layer)) {
        allLayers.push(layer);
      }
    });
    return allLayers;
  }

  /**
   * Get a layer by its key (ol_uid).
   *
   * @param {ol.Map} map The map to use for lookup.
   * @param {string} ol_uid The ol_uid of a layer.
   * @return {ol.layer.Layer} The layer.
   */
  static getLayerByOlUid = (map, ol_uid) => {
    const layers = MapUtil.getAllLayers(map);
    const layer = layers.find((l) => {
      return ol_uid === l.ol_uid.toString();
    });
    return layer;
  }

  /**
   * Get information about the LayerPosition in the tree.
   *
   * @param {ol.layer.Layer} layer The layer to get the information.
   * @param {ol.layer.Group|ol.Map} [groupLayerOrMap] The groupLayer or map
   *                                                  containing the layer.
   * @return {Object} An object with these keys:
   *    {ol.layer.Group} groupLayer The groupLayer containing the layer.
   *    {Integer} position The position of the layer in the collection.
   */
  static getLayerPositionInfo(layer, groupLayerOrMap) {
    const groupLayer = groupLayerOrMap instanceof OlLayerGroup
      ? groupLayerOrMap
      : groupLayerOrMap.getLayerGroup();
    const layers = groupLayer.getLayers().getArray();
    let info = {};

    if (layers.indexOf(layer) < 0) {
      layers.forEach((childLayer) => {
        if (childLayer instanceof OlLayerGroup && !info.groupLayer) {
          info = MapUtil.getLayerPositionInfo(layer, childLayer);
        }
      });
    } else {
      info.position = layers.indexOf(layer);
      info.groupLayer = groupLayer;
    }
    return info;
  }

  /**
   * Checks whether the resolution of the passed map's view lies inside of the
   * min- and max-resolution of the passed layer, e.g. whether the layer should
   * be displayed at the current map view resolution.
   *
   * @param {ol.layer.Layer} layer The layer to check.
   * @param {ol.Map} map The map to get the view resolution for comparison
   *     from.
   * @return {boolean} Whether the resolution of the passed map's view lies
   *     inside of the min- and max-resolution of the passed layer, e.g. whether
   *     the layer should be displayed at the current map view resolution. Will
   *     be `false` when no `layer` or no `map` is passed or if the view of the
   *     map is falsy or does not have a resolution (yet).
   */
  static layerInResolutionRange(layer, map) {
    const mapView = map && map.getView();
    const currentRes = mapView && mapView.getResolution();
    if (!layer || !mapView || !currentRes) {
      // It is questionable what we should return in this case, I opted for
      // false, since we cannot sanely determine a correct answer.
      return false;
    }
    const layerMinRes = layer.getMinResolution(); // default: 0 if unset
    const layerMaxRes = layer.getMaxResolution(); // default: Infinity if unset
    // minimum resolution is inclusive, maximum resolution exclusive
    const within = currentRes >= layerMinRes && currentRes < layerMaxRes;
    return within;
  }
}

export default MapUtil;
