import arxs from 'infra/arxs';
import { RRule } from 'rrule';
import { LinkType, OriginModuleEnum } from "infra/api/contracts";

class BoardController {
    constructor() {
        this.enrichCard = this.enrichCard.bind(this);
        this.getCardSearchTerms = this.getCardSearchTerms.bind(this);
        this.loadData = this.loadData.bind(this);
    }
    enrichCard(card, stateProxy) {
        return {
            ...card,
        };
    }

    getCardSearchTerms(card) {
        return [
            card.uniqueNumber
        ];
    }

    getFullExportActions() {
        return [];
    }

    loadData() {
        arxs.logger.error("loadData() not implemented");
    }

    getCodeElementValue = (ref, codeElementsById) => {
        if (!ref || !codeElementsById) return "";
        return this.getLookupValue(codeElementsById, ref);
    }

    getCodeElement = (ref, codeElementsById) => {
        if (!ref || !codeElementsById) return "";
        return codeElementsById[ref.id];
    }

    getLookupValue = (lookup, ref) => {
        const match = lookup && ref && lookup[ref.id];
        return (match && match.name) || (ref && ref.name) || "";
    }

    getLookupValueWithFieldNames = (lookup, ref, fieldNames) => {
        const match = lookup && ref && lookup[ref.id];
        return (match && fieldNames.map(fn => match[fn]).join(' ')) || ""
    }

    getEmployeeValue = (refs, employeeMap) => {
        if (!refs || !employeeMap) return "";
        if (refs.length) {
            return refs.map(ref => ref.employee).map(employee => this.getLookupValue(employeeMap, employee)).join(", ");
        }
        return this.getLookupValue(employeeMap, refs);
    }

    getContactValue = (refs, contactMap) => {
        if (!refs || !contactMap) return "";
        if (refs.length) {
            return refs.map(ref => this.getLookupValueWithFieldNames(contactMap, ref, ["firstname", "surname"])).join(", ");
        }
        return this.getLookupValueWithFieldNames(contactMap, refs, ["firstname", "surname"]);
    }

    getRoleValue = (refs, userRoleMap) => {
        if (!refs || !userRoleMap) return "";
        if (refs.length) {
            return refs.map(ref => ref.userRole).map(userRole => this.getLookupValue(userRoleMap, userRole)).join(", ");
        }
        return this.getLookupValue(userRoleMap, refs);
    }

    getEmployeesAndRoleValues = (refs, employeeMap, userRoleMap) => {
        const employees = [this.getEmployeeValue(refs.filter(ref => Object.keys(ref).includes("employee")), employeeMap)];
        const roles = [this.getRoleValue(refs.filter(ref => Object.keys(ref).includes("userRole")), userRoleMap)];

        return (employees.concat(roles)).filter(values => values !== "").join(", ");
    }

    getRevisionDate = (card) => {
        const schedule = card.schedule;
        if (schedule && schedule.recurrenceRule
            && schedule.recurrenceRule.indexOf("FREQ=NONE") === -1
            && schedule.recurrenceRule.indexOf("FREQ=undefined") === -1) {
            const options = RRule.parseString(schedule.recurrenceRule);
            let dtstart = new Date();
            if (schedule.start) {
                const startDate = new Date(schedule.start);
                if (dtstart < startDate) {
                    dtstart = startDate;
                }
            }
            const rule = new RRule({ ...options, dtstart, count: 1 });
            const dates = rule.all();
            const possibleNextRevisionDate = (dates[0]).subtractSeconds(1);
            if (schedule.end) {
                const endDate = new Date(schedule.end);
                if (endDate < possibleNextRevisionDate) {
                    return undefined;
                }
            }
            return possibleNextRevisionDate;
        } else {
            return card.revisionDate ? new Date(card.revisionDate) : undefined;
        }
    }

    getUniqueNumbersOfCombinedInstallations = (stateProxy, links) => {
        const linksToMap = (links || []).filter(x => x.module === OriginModuleEnum.CombinedInstallation && x.type === LinkType.PartOf);

        if (linksToMap.some(x => x)) {
            return linksToMap.map(x => this.getLookupValueWithFieldNames(stateProxy.getter("combinedInstallationMap"), x, ["uniqueNumber"]))
                .join(", ")
        }

        return "";
    }

    getUniqueNumbersOfPeriodicals = (stateProxy, links) => {
        const linksToMap = (links || []).filter(x => x.module === OriginModuleEnum.Periodical && x.type === LinkType.Subject);

        if (linksToMap.some(x => x)) {
            return linksToMap.map(x => this.getLookupValueWithFieldNames(stateProxy.getter("periodicalMap"), x, ["uniqueNumber"]))
                .join(", ")
        }

        return "";
    }
}
export default BoardController;