import { TaskRequestStatus } from "infra/api/contracts";
import arxs from "infra/arxs";
import TaskLikeBoard from "./TaskLikeBoard";

class TaskBoard extends TaskLikeBoard {
  async loadData(requestedLookups, stateProxy) {

    let tasks;
    let taskRequests;

    const getTasks = () => {
      return tasks
        .orderByDescending((card) => card.createdAt)
        .map((card) => ({
          ...card,
          activities: card.numberOfMessages,
          selected: () => !!stateProxy.getter("selected")[card.id],
        })
        );
    }

    const getTaskRequests = () => {
      return taskRequests
        .filter((x) => x.status === TaskRequestStatus.Pending || x.status === TaskRequestStatus.Refused)
        .orderByDescending((card) => card.createdAt)
        .map((card) => ({
          ...card,
          activities: card.numberOfMessages,
          selected: () => !!stateProxy.getter("selected")[card.id],
        })
        );
    }

    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.tasks) {
        tasks = lookups.tasks;
        delete lookups.tasks;
      }

      if (lookups.taskRequests) {
        taskRequests = lookups.taskRequests;
        delete lookups.taskRequests;
      }

      if (tasks && !taskRequests) {
        lookups.pristine = getTasks();
      }

      if (taskRequests && !tasks) {
        lookups.pristine = getTaskRequests();
      }

      if (tasks && taskRequests) {
        lookups.pristine = getTaskRequests().concat(getTasks());
      }

      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new TaskBoard();
