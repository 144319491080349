import React, { Component } from 'react';
import Button from 'components/controls/Button';

import './OptionPopup.scss';

class OptionPopup extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.handle = this.handle.bind(this);
    this.state = {};
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose();
    }

    if (this.props.close) {
      this.props.close();
    }
  }

  handle(option) {
    this.close();

    if (option.handle) {
      option.handle();
    }
  }

  render() {
    const options = this.props.options || [];
    return this.props.options && (
      <div className="option-popup-backdrop">
        <div className={`option-popup ${this.props.className || ""}`}>
          <h1>{this.props.title}</h1>
          <div className="option-popup-close" onClick={this.close}>
            <i className="fa fa-times"></i>
          </div>
          <div className="option-popup-body">
            <div className="option-options">
              {options.map((option, i) => <Button key={`option-popup-option-${i}`} className="icon" onClick={() => this.handle(option)}>{option.title}</Button>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OptionPopup;