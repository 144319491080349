import React, { Component, Fragment } from 'react';
import Badge from 'components/controls/Badge';
import arxs from 'infra/arxs'
import GlobalContext from 'infra/GlobalContext';

import './ActivityDropdown.scss';

export class ActivityDropdown extends Component {
    lookups = {
        employeeMap: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            total: 0,
            employeeMap: {},
        };
        this.subscription = {
            update: () => { },
            dispose: () => { }
        };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }))
        };

        this.subscription = arxs.Api.subscribeToEndpoint(
            "/api/shared/activity"
            , x => this.setState({ total: x.total }));
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }

        this.subscription.dispose();
    }

    render() {
        const total = this.state.total;

        const navigateToActivityOverview = (context) => {
            context.menu.close();
            this.props.history.push("/shared/activityoverview");
        };

        // Disabled dropdown for now
        // https://trello.com/c/5XCJhedw/321-his-link-bovenaan-gewoon-laten-doorklikken-naar-https-vnextarxsbe-shared-activityoverview-geen-dropdown-meer-tonen

        return (
            <GlobalContext.Consumer>
                {(context) => <div className="tile activities" onClick={() => navigateToActivityOverview(context)}>
                    <i className="fa fa-comment-alt"></i>
                    {total > 0 && (<Fragment>
                        {total > 0 && (<Badge className="red">{total}</Badge>)}
                    </Fragment>)}
                </div>}
            </GlobalContext.Consumer>
        );
    }
}