import { ArXsState } from "infra/arxs";
import ModuleMetadata, { BoardViewType } from "modules/ModuleMetadata";
import BranchWizard from "./BranchWizard";
import BranchActions from "./BranchActions";
import BranchBoard from "./BranchBoard";
import { ObjectDocumentType, OriginModuleEnum } from "infra/api/contracts";
import { ProductType } from "infra/Types";
import BranchCard from "./BranchCard";

const getBranchMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.School;

  return {
    module,
    title: arxs.t("modules.branch"),
    icon: "far fa-school",
    path: "/branch",
    base: {
      name: "Branch",
      route: "/branch",
      getResource: () => arxs.Api.getResource("branch"),
      subscribe: (id, module, handler) =>
        arxs.Api.lookups.subscribeToId(module, id, handler),
      readAction: "School.Read",
      writeAction: "School.Write",
    },
    settings: {
      name: "BranchSettings",
      route: "/branch/settings",
      getResource: () => arxs.Api.getResource("branch").settings(),
      readAction: "School.Settings.Write",
      writeAction: "School.Settings.Write",
    },
    detailspane: [
      {
        title: arxs.t("details_pane.information"),
        initiallyOpen: true,
        fields: [
          { name: "name" },
          { name: "internalName" },
          { name: "abbreviation" },
          { name: "companyNumber" },
          {
            name: "schoolNumbers",
            children: [{ name: "number" }],
            productType: ProductType.Helios,
          },
          { name: "educationDome", productType: ProductType.Helios },
          { name: "educationPortal", productType: ProductType.Helios },
          { name: "dateOfCreation" },
          { name: "businessType" },
        ],
      },
      {
        title: arxs.t("details_pane.location"),
        initiallyOpen: true,
        fields: [
          { name: "legalStructure" },
          { name: "geoLocation" },
        ],
      },
      {
        title: arxs.t("details_pane.contact"),
        initiallyOpen: true,
        fields: [{ name: "addresses" }, { name: "emails" }, { name: "phones" }],
      },
      {
        title: arxs.t("details_pane.tags"),
        initiallyOpen: true,
        fields: [{ name: "tags" }],
      },
    ],
    allowedDocumentTypes: [
      ObjectDocumentType.Image,
      ObjectDocumentType.FloorPlan,
      ObjectDocumentType.LandRegister,
      ObjectDocumentType.WorkRules,
      ObjectDocumentType.AdditionalDocument
    ],
    singleFileDocumentTypes: [ObjectDocumentType.Image],
    statuses: [],
    actions: BranchActions.getActions(OriginModuleEnum.School),
    wizard: {
      wizardClass: BranchWizard,
      steps: [
        {
          title: arxs.t("details_pane.identification"),
          fields: [
            [
              {
                name: "legalStructure",
                onChange: (stateProxy) =>
                  BranchWizard.setAllowedNaceBellsAndParitairComitesAndTechnicalBusinessUnitsForLegalStructure(
                    stateProxy
                  ),
                onLoad: (stateProxy) =>
                  BranchWizard.setAllowedNaceBellsAndParitairComitesAndTechnicalBusinessUnitsForLegalStructure(
                    stateProxy
                  ),
              },
            ],
            [{ name: "name" }],
            [{ name: "abbreviation" }, { name: "internalName" }],
            [{ name: "companyNumber" }, { name: "rszNumber" }],
            [
              {
                name: "schoolNumbers",
                type: "itemlist",
                productType: ProductType.Helios,
                children: [{ name: "number" }],
              },
            ],
            [
              {
                name: "educationPortal",
                code: "EducationPortal",
                productType: ProductType.Helios,
              },
              {
                name: "educationDome",
                code: "EducationDome",
                parent: "educationPortal",
                productType: ProductType.Helios,
              },
            ],
            [
              { name: "dateOfCreation" },
              { name: "businessType", code: "CompanyType" },
            ],
            [
              {
                name: "establishmentNumbers",
                type: "itemlist",
                children: [{ name: "establishmentNumber" }],
              },
            ],
            [
              {
                name: "geoLocation",
                onChange: (stateProxy) => {
                  BranchWizard.addDefaultAddress(stateProxy);
                },
              },
            ],
            [
              {
                name: "addresses",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "country", width: "100px" },
                  { name: "zipCode", width: "60px" },
                  { name: "city", width: "150px" },
                  { name: "street" },
                  { name: "number", width: "60px" },
                  { name: "bus", width: "50px" },
                  { name: "type", code: "School.Address.Type", width: "150px" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  BranchWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    BranchWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [
              {
                name: "emails",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "email" },
                  { name: "type", code: "School.Email.Type" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  BranchWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    BranchWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [
              {
                name: "extraData",
                type: "itemlist",
                children: [
                  { name: "name" },
                  { name: "type", code: "School.ExtraData.Type" },
                ],
              },
            ],
            [
              {
                name: "phones",
                type: "itemlist",
                children: [
                  { name: "isPreferred" },
                  { name: "number" },
                  { name: "type", code: "School.Phone.Type" },
                ],
                onChange: (stateProxy, fieldName, row, childName) => {
                  BranchWizard.onChangePreferredInItemList(
                    stateProxy,
                    fieldName,
                    row,
                    childName
                  ).then(() =>
                    BranchWizard.setFirstItemInItemListAsPreferred(
                      stateProxy,
                      fieldName
                    )
                  );
                },
              },
            ],
            [{ name: "tags", type: "tag" }],
          ],
        },
        {
          title: arxs.t("details_pane.details"),
          fields: [
            [
              {
                name: "technicalBusinessUnits",
                type: "itemlist",
                filter: (stateProxy, row) =>
                  BranchWizard.getFilteredIds(
                    stateProxy,
                    "technicalBusinessUnits",
                    row
                  ),
              },
            ],
            [
              {
                name: "naceBells",
                type: "itemlist",
                filter: (stateProxy, row) =>
                  BranchWizard.getFilteredIds(stateProxy, "naceBells", row),
              },
            ],
            [
              {
                name: "paritairComites",
                type: "itemlist",
                filter: (stateProxy, row) =>
                  BranchWizard.getFilteredIds(
                    stateProxy,
                    "paritairComites",
                    row
                  ),
              },
            ],
            [
              {
                name: "landRegistries",
                type: "itemlist",
                children: [
                  { name: "section" },
                  { name: "parcelNumber" },
                  {
                    name: "area",
                    unit: arxs.t("unit.square_meter").toLowerCase(),
                  },
                ],
              },
            ],
          ],
        },
        {
          title: arxs.t("details_pane.attachments"),
          fields: [
            [
              { name: "images", type: "image" },
              { name: "documents", type: "document" },
            ],
          ],
        },
      ],
      lookups: {
        legalStructureMap: {},
        branches: [],
        codeElements: {},
      },
    },
    board: {
      allowDuplicate: false,
      lookups: {
        branches: {},
        legalStructureMap: {},
        tagMap: {},
        codeElementsById: {},
        employeeMap: {},
        periodicalMap: {},
      },
      gridColums: [
        { name: "activities" },
        { name: "uniqueNumber" },
        { name: "numberOfImages" },
        { name: "numberOfDocuments" },
        { name: "tags" },
        { name: "legalStructure" },
        { name: "name" },
        { name: "abbreviation" },
        { name: "internalName" },
        { name: "address" },
        { name: "geoLocation" },
        { name: "phone" },
        { name: "email" },
        { name: "companyNumber", width: "175px" },
        { name: "establishmentNumberNames" },
        { name: "schoolNumberNames" },
        { name: "rszNumber" },
        { name: "periodical_uniqueNumber" },
        { name: "owner" },
        { name: "createdAt" },
      ],
      cardProps: {
        showImage: true,
      },
      boardClass: BranchBoard,
      views: [BoardViewType.Grid, BoardViewType.Map],
    },
    cardClass: BranchCard
  };
};
export default getBranchMetadata;
