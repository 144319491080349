import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";

const DraggableFormListItem = (props) => {
    const { item, dragHandleProps, commonProps } = props;
    const { readOnly, onItemChange, onDeleteItem, codeElementsById, settings } = commonProps;
    const selectedValue = item && item.value;

    let values = [];

    if (settings && settings.title) {
        values = (((codeElementsById || {})[settings.title] || {}).children || [])
            .filter(x => x.isActive)
            .map(x => x.name);
    }

    const onValueChange = (state) => {
        onItemChange({ ...item, value: state });
    };
    const handleDelete = () => {
        onDeleteItem(item.id);
    };

    return <div className="point-of-attention-container" key={(item && item.id) || 0}>
        <div className="point-of-attention-line">
            {!readOnly && <div className="drag-handle" {...dragHandleProps}>
                <i className="fa fas fa-grip-vertical"></i>
            </div>}
            <AutoCompleteTextBox
                id={`value-${item.id}`}
                key={`value-${item.id}`}
                className={`point-of-attention-value`}
                items={values}
                value={selectedValue}
                onChange={onValueChange}
                readOnly={readOnly}
                placeholder={arxs.t("forms.items.pointsofattention.description")}
            />
            <div className="point-of-attention-actions">
                {!readOnly && item && <div className="point-of-attention-action" onClick={handleDelete}>
                    <i className="far fa-trash-alt"></i>
                </div>}
            </div>
        </div>
    </div>;
}

export default class PointsOfAttention extends FormItemEditorController {
    lookups = {
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    getDraggableItemComponent() { return DraggableFormListItem };

    renderItems = (context) => {
        const { codeElementsById } = this.state;
        const { field, readOnly } = this.props;
        const issueKind = ((this.state.codeElements || {})["IssueKind"] || [])[0] || {};
        const types = (issueKind.children || [])
            .orderBy(x => x.isActive ? x.name : "ZZZZZZZZ" + x.name)
            .map(x => ({ id: x.id, name: x.name, isActive: x.isActive }));
        const value = field.getter();
        const data = value.data || [];
        const settings = value.settings || {};

        let selectedType;
        if (settings && codeElementsById) {
            if (arxs.uuid.isValid(settings.title)) {
                selectedType = settings.title
                    ? { id: settings.title, name: (codeElementsById[settings.title] || {}).name }
                    : undefined;
            } else if (settings.title) {
                selectedType = { name: settings.title };
            }
        }
        
        const onItemChange = (state) => {
            let newData = data.filter(x => x.id !== state.id);
            newData.push(state);
            field.setter({ ...value, data: newData });
        };
        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };
        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData });
        };
        const onTypeChange = (state) => {
            field.setter({ ...value, settings: { ...settings, title: state.id } });
        };
        const onTitleAdd = (state) => {
            if (state) {
                field.setter({ ...value, settings: { ...settings, title: state.id || state.name } });
            }
        };

        return <div className="point-of-attention">
            <DropDown
                id={`type-${data.id}`}
                key={`type-${data.id}`}
                className="point-of-attention-type"
                items={types}
                selected={selectedType}
                onChange={onTypeChange}
                onAdd={onTitleAdd}
                readOnly={readOnly}
                disableClearButton={true}
                placeholder={arxs.t("forms.items.pointsofattention.type")}
            />
            {this.renderDraggableList(data, { readOnly, codeElementsById, settings, onItemChange, onDeleteItem })}
            {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
            </div>}
        </div>;

    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderItems(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}