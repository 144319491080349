import React, { Component, Fragment } from 'react';
import DropDownMenu from 'components/controls/DropDownMenu';
import arxs from 'infra/arxs';

import './ViewDropDown.scss';

export default class ViewDropDown extends Component {
  add = (e) => {
    if (this.props["onAdd"]) {
      this.props.onAdd();
    }
  }

  change = (e, view) => {
    if (this.props["onChange"]) {
      this.props.onChange(view);
    }
  }

  rename = (e, view) => {
    if (this.props["onRename"]) {
      this.props.onRename(view);
    }
  }

  remove = (e, view) => {
    if (this.props["onRemove"]) {
      this.props.onRemove(view);
    }
  }

  render() {
    const items = [{
      className: "header",
      content: <Fragment>{arxs.t("controls.viewdropdown.title")}</Fragment>,
    }]
      .concat((this.props.data || [])
        .orderBy(x => x.title)
        .map(x => ({
          className: x === this.props.selected ? "selected" : null,
          content: <Fragment>
            {x === this.props.selected && <div className="bullet-glowing"></div>}
            <div className="title">{x.title}</div>
            {!x.readOnly && <div className="buttons">
              <i className="fa fa-pencil" onClick={e => this.rename(e, x)}></i>
              <i className="far fa-trash-alt" onClick={e => this.remove(e, x)}></i>
            </div>}
          </Fragment>,
          onClick: e => this.change(e, x)
        })))
      .concat([{
        className: "footer",
        content: <Fragment>{arxs.t("controls.viewdropdown.add")} <i className="fa fa-plus"></i></Fragment>,
        onClick: this.add
      }]);
    return <DropDownMenu id={this.props.id} className="view-dropdown" items={items}>
      <i className="fas fa-filter"></i>
      {this.props.selected ? this.props.selected.title : arxs.t("controls.common.select")}
    </DropDownMenu>;
  }
}