import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import { createCardLookup } from "components/shell/CardLookup/CardLookup";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import GlobalActions from "modules/GlobalActions";
import ClassicActions from "modules/ClassicActions";
import { OriginModuleEnum, TaskStatus } from "infra/api/contracts";
import TaskActions from "../tasks/TaskActions";

class TaskRequestActions {
  getActions = (module, excludeGlobals) => {
    return GlobalActions.getActions(module)
      .concat(ClassicActions.getActions(module))
      .filter((_) => !excludeGlobals)
      .concat([
        {
          name: "accept",
          module: module,
          icon: arxs.actions.icons["accept"],
          getTitle: () => arxs.actions.titles["accept"],
          onClick: (state) => this.accept(state),
        },
        {
          name: "accept",
          module: module,
          icon: arxs.actions.icons["accept"],
          getTitle: () => arxs.actions.titles["accept_and_activate"],
          onClick: (state) => this.acceptAndActivate(state),
        },
        {
          name: "accept",
          module: module,
          icon: arxs.actions.icons["accept"],
          getTitle: () => arxs.actions.titles["accept_and_complete"],
          onClick: (state) => this.acceptAndComplete(state),
        },
        {
          name: "refuse",
          module: module,
          icon: arxs.actions.icons["refuse"],
          getTitle: () => arxs.actions.titles["refuse"],
          onClick: (state) => this.refuse(state),
        },
        {
          name: "unrefuse",
          module: module,
          icon: arxs.actions.icons["unrefuse"],
          getTitle: () => arxs.actions.titles["unrefuse"],
          onClick: (state) => this.unrefuse(state),
        },
      ]);
  };

  getGlobalActions = (module) => {
    return [{
      name: "create_taskrequest",
      module: module,
      icon: "far fa-bell-exclamation",
      getTitle: () => arxs.t("actions.task_request.create"),
      onClick: (state) => this.createTaskRequest(state, module),
      singleSelectionOnly: true
    }];
  }

  createTaskRequest = (state, module) => {
    const ids = state["ids"];
    const history = state["history"];
    if (ids.length > 0) {
      const subjects = ids.map(id => ({ id, module }));
      history.push({ pathname: `/taskrequest/create`, state: { subjects } });
    }
    return new Promise(() => {});
  };

  acceptAndAddToExistingTask = (context, ids, cards, completedOnly) => {
    const onApplyFilter = (state) => {
      if (Object.entries(state).length !== 1) {
        arxs.logger.error(
          "only 1 selection allowed for task lookup when accepting taskrequest!"
        );
        Toaster.error(arxs.t("actions.error.selection_exceeded"));
        context.popup.close();
      }

      const taskId = Object.keys(state)[0];

      const data = { ids: ids, taskId: taskId };

      arxs.ApiClient.facilitymanagement.taskRequest
        .accept(data)
        .then(() =>
          Toaster.success(arxs.t("actions.task_request.accept_confirmation"))
        );

      context.popup.close();
    };

    const legalStructures = cards
      .filter((x) => x.legalStructure)
      .distinct((x) => x.legalStructure.id);

    if (legalStructures.length === 1) {
      const securityContext = arxs.securityContext.buildForContext(
        "Task.Write",
        legalStructures[0]
      );
      const filterPredicate = (card) => {
        if (card) {
          return card.status !== TaskStatus.MultiYearPlan && (!completedOnly || card.status === TaskStatus.Completed);
        }
        return false;
      }

      const cardLookup = createCardLookup({
        modules: [OriginModuleEnum.Task],
        onApplyFilter,
        singleSelection: true,
        securityContext,
        filterPredicate
      });
      context.popup.show(cardLookup);
    }
  };

  accept = (state) => {
    const context = state.context;
    const ids = (state.ids || [state.item.objectId]).filter(x => x);
    const cards = (state.cards || [state.item.card]).filter(x => x);
    const options = [];

    options.push({
      title: arxs.t("actions.task_request.accept_create_new"),
      handle: () => this.acceptAndCreateNewTask(ids),
    });
    options.push({
      title: arxs.t("actions.task_request.accept_add_to_existing"),
      handle: () => this.acceptAndAddToExistingTask(context, ids, cards),
    });
    options.push({
      title: arxs.t("actions.task_request.accept_add_to_completed"),
      handle: () => this.acceptAndAddToExistingTask(context, ids, cards, true)
    })

    context.optionPopup.show(
      arxs.t("actions.task_request.accept_choice"),
      options
    );
  };

  acceptAndActivate = (state) => {
    const history = state.history;
    const ids = (state.ids || [state.item.objectId]).filter(x => x);
    return this.acceptAndCreateNewTask(ids, history, TaskStatus.Active);
  };

  acceptAndCreateNewTask = (ids, history, status) => {
    if (ids.length === 0) {
      return new Promise(() => {});
    }

    const isSingleRecord = ids.length === 1;

    return arxs.ApiClient.facilitymanagement.taskRequest
      .accept({ ids: ids, status: status || TaskStatus.InProcess })
      .then((taskId) => {
        if (isSingleRecord && history) {
          history.push({
            pathname: `/task/edit/${taskId}`,
          });
        }

        Toaster.success(arxs.t("actions.task_request.accept_confirmation"))
      });
  }

  acceptAndComplete = (state) => {
    const ids = (state.ids || [state.item.objectId]).filter(x => x);
    if (ids.length === 0) {
      return;
    }

    const isSingleRecord = ids.length === 1;

    arxs.ApiClient.facilitymanagement.taskRequest
      .accept({ ids: ids, status: isSingleRecord ? null : TaskStatus.Completed })
      .then((taskId) => {
        if (isSingleRecord) {
          TaskActions.complete({ ids: [taskId], context: state.context });
        }

        Toaster.success(arxs.t("actions.task_request.accept_confirmation"))
      });
  }

  refuse = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      arxs.ApiClient.facilitymanagement.taskRequest
        .refuse(data)
        .then(() =>
          Toaster.success(arxs.t("actions.task_request.refuse_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.task_request.refuse_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation"),
      arxs.t("actions.refuse"),
      arxs.t("common.cancel")
    );

    context.inputPopup.show(confirmation);
  };

  unrefuse = (state) => {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = () => {
      const data = { ids: ids };
      arxs.ApiClient.facilitymanagement.taskRequest
        .unrefuse(data)
        .then(() =>
          Toaster.success(arxs.t("actions.task_request.unrefuse_confirmation"))
        );
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.task_request.unrefuse_confirmation_question"),
      executeAction
    );
    context.inputPopup.show(confirmation);
  };

  acceptAndHold = (state) => {
    const ids = state.ids || [state.item.objectId];
    const history = state.history;
    const context = state.context;

    const data = { ids: ids, taskId: null };

    const executeAction = (motivation) => {
      arxs.ApiClient.facilitymanagement.taskRequest
        .accept(data)
        .then((taskId) => {
          history.push({
            pathname: `/task/edit/${taskId}`,
            state: { status: "OnHold", motivation: motivation },
          });
        });
    };

    const confirmation = createInputPopup(
      context,
      arxs.t("actions.task_request.accept_hold_confirmation_question"),
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  };
}
export default new TaskRequestActions();