import WizardController from 'modules/WizardController';
import arxs from 'infra/arxs';

class SupplierWizard extends WizardController {

  validate(stateProxy, getCurrentFieldValue, preValidation) {
    return preValidation;
  }

  addDefaultAddress = (stateProxy) => {
    const codeElements = stateProxy.getter("codeElements");
    const root = codeElements["Supplier.Address.Type"]
      .filter(x => x.isActive && x.tenantId === arxs.Identity.tenant)[0];
    if (root && root.children && root.children.length > 0) {
      const defaultAddressType = arxs.t("code_elements.built-in.defaultAddressType").toLowerCase();
      const activeChildren = root.children.filter(x => x.isActive);
      const addressType = activeChildren
        .filter(x => x.name.toLowerCase() === defaultAddressType)
        .coalesceIfEmpty(activeChildren)[0];

      const geoLocation = stateProxy.getField("geoLocation");
      const addresses = stateProxy.getField("addresses");
      if (geoLocation && (!addresses || addresses.length === 0)) {
        stateProxy.setField("addresses", [{
          isPreferred: true,
          city: geoLocation.city,
          country: geoLocation.country || "België",
          number: geoLocation.number,
          street: geoLocation.street,
          zipCode: geoLocation.zipCode,
          type: addressType ? { id: addressType.id } : null,
        }]);
      }
    }
  }
}
export default new SupplierWizard();