import React, { Component } from 'react';
import arxs from 'infra/arxs';
import GlobalContext from 'infra/GlobalContext';
import { StarGrid } from 'components/animations/StarGrid';
import { Spinner } from 'components/animations/Spinner';

import './Loading.scss';

export class Loading extends Component {
  render() {
    return (<GlobalContext.Consumer>
      {(context) => (
      <div className="loading">
        <div className="splash">
          <StarGrid />
          <div className="logo">
            <img src="images/logo-transparent-small.png" alt="ArXs" />
            <h1>{arxs.productName}</h1>
          </div>
          <div className="splash-content">
            <div className="device">
              {context.platform.isPhone && <img src="images/img_iphone.png" alt="Device" />}
              {context.platform.isTablet && <img src="images/img_login_tablet_landscape.png" alt="Device" />}
              {context.platform.isDesktop && <img src="images/img_macbook.png" alt="Device" />}
            </div>
            <Spinner />
          </div>
        </div>
      </div>
    )}</GlobalContext.Consumer>);
  }
}
