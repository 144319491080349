import React, { useState, useEffect } from "react";

import GeoLocationLabel from "components/layouts/geo/GeoLocationLabel";
import { OriginModuleEnum } from "infra/api/contracts";

import "./CardLocation.scss";

interface CardLocationProps {
    card: any;
    wrapLocation?: boolean;
}

const _modulesThatShowInheritedGeoLocation = [
    OriginModuleEnum.Building,
];

export default function CardLocation(props: CardLocationProps) {
    const { card } = props;

    const [legalStructureAndBranch, setLegalStructureAndBranch] = useState("");
    const [buildingAndLocation, setBuildingAndLocation] = useState("");

    useEffect(() => {
        setLegalStructureAndBranch([card.legalStructure, card.branch].filter(x => typeof x === "string" && x.length > 0).join(" - "));
    }, [card.legalStructure, card.branch]);

    useEffect(() => {
        setBuildingAndLocation([card.building, card.location].filter(x => typeof x === "string" && x.length > 0).join(" - "));
    }, [card.building, card.location]);

    return <>
        <div>{legalStructureAndBranch}</div>
        {props.wrapLocation && <div>{buildingAndLocation}</div>}
        {!props.wrapLocation && <>
            <div>{typeof card.building === "string" && card.building}</div>
            <div>{typeof card.location === "string" && card.location}</div>
        </>}
        {card.geoLocation
            && (!card.geoLocation.isInherited || _modulesThatShowInheritedGeoLocation.contains(card.module))
            && (<GeoLocationLabel value={card.geoLocation} />)}
    </>;
}