import { OriginModuleEnum, } from "infra/api/contracts";

const _tasklikeModules = [OriginModuleEnum.Task, OriginModuleEnum.PeriodicMaintenance, OriginModuleEnum.PeriodicControl];
const _whitelistedMatrix = {
    "instruction": [..._tasklikeModules, OriginModuleEnum.InstructionCard, OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning, OriginModuleEnum.Consultancy, OriginModuleEnum.RiskAnalysis],
    "images": [..._tasklikeModules, OriginModuleEnum.InstructionCard, OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning, OriginModuleEnum.Consultancy, OriginModuleEnum.RiskAnalysis],
    "icons": [..._tasklikeModules, OriginModuleEnum.InstructionCard, OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning, OriginModuleEnum.Consultancy],
    "checkbox": [..._tasklikeModules, OriginModuleEnum.RiskAnalysis],
    "select": [..._tasklikeModules, OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning, OriginModuleEnum.RiskAnalysis],
    "remark": [..._tasklikeModules, OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning, OriginModuleEnum.RiskAnalysis],
    "riskAndPreventionMeasures": [OriginModuleEnum.SafetyInstructionCard],
    "pointsOfAttention": [OriginModuleEnum.SafetyInstructionCard],
    "requirements": [OriginModuleEnum.SafetyInstructionCard],
    "recommendation": [OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning],
    "periodicals": [OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning],
    "additionalSafetyRequirements": [OriginModuleEnum.Commissioning],
    "riskMeasurement": [OriginModuleEnum.RiskAnalysis],
    "riskAnalysis": [..._tasklikeModules],
    "preRecommendation": [OriginModuleEnum.Consultancy],
    "link": [OriginModuleEnum.Commissioning, OriginModuleEnum.OutOfCommissioning],
};

const _knownControls = [{
    id: 1,
    name: "Vinkje",
    type: "checkbox",
    dataType: "boolean",
    icon: "far fa-check-square"
},
{
    id: 2,
    name: "OK/NOK/NVT",
    type: "select",
    dataType: "boolean",
    props: JSON.stringify({
        values: [{ "ok": true }, { "niet ok": false }, { "nvt": null }]
    }),
    icon: "fas fa-check"
},
{
    id: 3,
    name: "Instructie",
    type: "instruction",
    dataType: "string",
    icon: "far fa-info",
},
{
    id: 4,
    name: "Opmerkingen",
    type: "remark",
    dataType: "string",
    icon: "far fa-comment-exclamation"
},
{
    id: 5,
    name: "Aandachtspunten",
    type: "pointsOfAttention",
    dataType: "array",
    icon: "far fa-lightbulb-exclamation"
},
{
    id: 6,
    name: "Vereisten",
    type: "requirements",
    dataType: "array",
    icon: "far fa-list-ol"
},
{
    id: 7,
    name: "Pictogrammen",
    type: "icons",
    dataType: "array",
    icon: "far fa-construction"
},
{
    id: 8,
    name: "Illustraties",
    type: "images",
    dataType: "array",
    icon: "far fa-images"
},
{
    id: 9,
    name: "Risico en preventiemaatregelen",
    type: "riskAndPreventionMeasures",
    dataType: "array",
    icon: "far fa-headphones"
},
{
    id: 10,
    name: "Periodiek",
    type: "periodicals",
    dataType: "array",
    icon: "far fa-calendar-alt"
},
{
    id: 11,
    name: "Advies",
    type: "recommendation",
    dataType: "object",
    icon: "far fa-user-check"
},
{
    id: 12,
    name: "Risicoweging",
    type: "riskAnalysis",
    dataType: "object",
    icon: "far fa-balance-scale-right"
},
{
    id: 13,
    name: "Risicobeoordeling",
    type: "riskMeasurement",
    dataType: "array",
    icon: "far fa-ruler-triangle"
},
{
    id: 14,
    name: "Pre-advies",
    type: "preRecommendation",
    dataType: "array",
    icon: "far fa-user-check"
},
{
    id: 15,
    name: "Link",
    type: "link",
    dataType: "object",
    icon: "far fa-link"
},
{
    id: 16,
    name: "Bijkomende veiligheidsvereisten",
    type: "additionalSafetyRequirements",
    dataType: "array",
    icon: "far fa-user-check"
},

];

export const toControlKey = (control) => {
    const cloned = {
        type: control.type,
        props: control.props,
     };
    const json = JSON.stringify(cloned);
    return json;
};

const knownControlMap = _knownControls.toDictionary(x => toControlKey(x));
const knownControlIdMap = _knownControls.toDictionary(x => x.id);

const formControlRegistry = {
    getControls: () => _knownControls,
    getControl: (control) => knownControlMap[toControlKey(control)],
    getControlById: (id) => knownControlIdMap[id],
    getWhitelistedControls: (module) => _knownControls
        .filter(c => _whitelistedMatrix[c.type].includes(module)),
};
export default formControlRegistry;