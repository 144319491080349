/* tslint:disable */
/* eslint-disable */
/**
 * ArXs API
 * ArXs Helios API
 *
 * The version of the OpenAPI document: v1
 * Contact: info@arxs.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IconTypeEnum {
    All = 'All',
    CommandSign = 'CommandSign',
    WarningSign = 'WarningSign',
    DangerousSubstancesSign = 'DangerousSubstancesSign',
    ProhebitionSign = 'ProhebitionSign',
    FireSafetySign = 'FireSafetySign',
    RescueSign = 'RescueSign',
    Other = 'Other',
    Adr = 'ADR'
}

export function IconTypeEnumFromJSON(json: any): IconTypeEnum {
    return IconTypeEnumFromJSONTyped(json, false);
}

export function IconTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): IconTypeEnum {
    return json as IconTypeEnum;
}

export function IconTypeEnumToJSON(value?: IconTypeEnum | null): any {
    return value as any;
}

