import BoardController from "modules/BoardController";
import arxs from "infra/arxs";
import { RelationshipType, MultiYearPlanStatus } from "infra/api/contracts";

class MultiYearPlanBoard extends BoardController {
  enrichCard(card, stateProxy) {
    const subject =
      (card.subject && arxs.Api.lookups.resolveSubject(card.subject)) || {};

    let riskValue = card.riskAnalysisValue === "" ? "{}" : card.riskAnalysisValue;
    riskValue = JSON.parse(riskValue).value;

    return {
      ...card,

      sortId: card.sort && card.sort.id,
      sort: this.getCodeElementValue(
        card.sort,
        stateProxy.getter("codeElementsById")
      ),
      kindId: card.kind && card.kind.id,
      kind: this.getCodeElementValue(
        card.kind,
        stateProxy.getter("codeElementsById")
      ),
      typeId: card.type && card.type.id,
      type: this.getCodeElementValue(
        card.type,
        stateProxy.getter("codeElementsById")
      ),

      legalStructureId: card.legalStructure && card.legalStructure.id,
      legalStructure: this.getLookupValue(
        stateProxy.getter("legalStructureMap"),
        card.legalStructure
      ),

      branchId: card.branch && card.branch.id,
      branch: this.getLookupValue(stateProxy.getter("branchMap"), card.branch),
      building: this.getLookupValue(
        stateProxy.getter("buildingMap"),
        card.building
      ),
      location: this.getLookupValue(
        stateProxy.getter("locationMap"),
        card.location
      ),
      responsible: this.getEmployeeValue(
        card.relationships.filter(
          (x) => x.type === RelationshipType.Responsible
        ),
        stateProxy.getter("employeeMap")
      ),
      coResponsibles: this.getEmployeesAndRoleValues(
        card.relationships.filter(
          (x) => x.type === RelationshipType.CoResponsible
        ),
        stateProxy.getter("employeeMap"), stateProxy.getter("userRoleMap")
      ),
      owner: this.getEmployeeValue(
        card.relationships.filter((x) => x.type === RelationshipType.Creator),
        stateProxy.getter("employeeMap")
      ),
      createdAt: card.createdAt && new Date(card.createdAt),
      start: card.start && new Date(card.start),
      end: card.end && new Date(card.end),
      period: (card.status !== MultiYearPlanStatus.ToPlan) ? card.end && new Date(card.end).getFullYear().toString() : null,

      tags: card.tags,
      subject_uniqueNumber: subject.uniqueNumber,
      subject_sort: this.getCodeElementValue(
        subject.sort,
        stateProxy.getter("codeElementsById")
      ),
      subject_kind: this.getCodeElementValue(
        subject.kind,
        stateProxy.getter("codeElementsById")
      ),
      subject_type: this.getCodeElementValue(
        subject.type,
        stateProxy.getter("codeElementsById")
      ),
      subject_internalReference: subject.internalName || subject.internalNumber,
      riskValue: riskValue
    };
  }

  getCardSearchTerms(card) {
    return [
      card.uniqueNumber,
      card.legalStructure,
      card.branch,
      card.building,
      card.location,
      (card.geoLocation || {}).street,
      (card.geoLocation || {}).city,
      card.sort,
      card.kind,
      card.type,
      card.title,
      card.description,
      card.internalReference,
      card.description,
      card.responsible,
      card.owner,
      card.coResponsibles,
      "" + card.period,
      card.subject_internalReference,
      ...(card.tags || []).map((x) => x.name),
    ];
  }

  async loadData(requestedLookups, stateProxy) {
    return arxs.Api.lookups.subscribe(requestedLookups, (lookups) => {
      if (lookups.multiYearPlans) {
        lookups.pristine = lookups.multiYearPlans
          .orderByDescending((card) => card.createdAt)
          .map((card) => {
            return {
              ...card,
              activities: card.numberOfMessages,
              selected: () => !!stateProxy.getter("selected")[card.id],
            };
          });
        delete lookups.multiYearPlans;
      }
      stateProxy.setter(lookups, () => Promise.resolve({ lookups }));
    });
  }
}
export default new MultiYearPlanBoard();