const createMenuState = (setState, isOpen) => {
  const toggle = (state) => setState(createMenuState(setState, state));
  const state = {
    isOpen: isOpen,
    toggle: () => toggle(!isOpen),
    open: () => toggle(true),
    close: () => toggle(false),
  };
  return state;
};
export default createMenuState; 