import React, { Component } from 'react';
import arxs from 'infra/arxs';
import AvatarEditor from 'react-avatar-editor'
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';

import './AvatarEditor.scss';
import { ObjectDocumentType } from 'infra/api/contracts';

export const createAvatarEditor = (avatar, userName, onSubmit) => {
    const state = {
        title: arxs.t("controls.avatar_editor.title"),
        content: <ArXsAvatarEditor avatar={avatar} userName={userName} onSubmit={onSubmit} />,
        fitContentWidth: true,
    };
    return state;
};

class ArXsAvatarEditor extends Component {

    zoomDirection = {
        in: "in",
        out: "out"
    }

    rotateDirection = {
        right: "right",
        left: "left"
    }

    constructor(props) {
        super(props);

        this.state = {
            scale: 1,
            rotation: 0
        };
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {

    }

    onRotate = (rotateDirection) => {
        const { rotation } = this.state;

        if (rotateDirection === this.rotateDirection.right) {
            this.setState({ rotation: rotation + 5 });
        } else {
            this.setState({ rotation: rotation - 5 });
        }
    }

    onZoom = (zoomDirection) => {
        const { scale } = this.state;

        if (zoomDirection === this.zoomDirection.in) {
            this.setState({ scale: scale + 0.1 });
        } else {
            this.setState({ scale: scale - 0.1 });
        }
    }

    onSubmit = () => {
        if (this.editor) {
            const canvas = this.editor.getImage();

            canvas.toBlob((blob) => this.saveImage(blob), 'image/jpeg');
        }
    }

    saveImage = (blob) => {
        const { avatar, userName, onSubmit } = this.props;
        arxs.ApiClient.shared.blob.getPutAuthorization(`${userName}_avatar`, ObjectDocumentType.Image)
            .then(putAuthorizationUrl => {
                let hash;

                const headers = {
                    'x-ms-date': new Date().toISOString(),
                    'x-ms-version': '2019-12-12',
                    'x-ms-blob-type': 'BlockBlob'
                };

                var ajaxRequest = new XMLHttpRequest();

                ajaxRequest.onreadystatechange = function (aEvt) {
                    if (ajaxRequest.readyState === 4) {
                        hash = ajaxRequest.getResponseHeader("Content-MD5");

                        const image = {
                            url: putAuthorizationUrl,
                            hash: hash,
                            type: ObjectDocumentType.Image,
                            name: `${userName}_avatar`,
                            contentType: avatar.contentType,
                            isPreferred: true
                        };

                        onSubmit(image);
                    }
                };

                ajaxRequest.open('PUT', putAuthorizationUrl, true);
                ajaxRequest.setRequestHeader('Content-Type', avatar.contentType);

                for (const key of Object.keys(headers)) {
                    ajaxRequest.setRequestHeader(key, headers[key]);
                }

                ajaxRequest.send(blob);
            });
    }

    setEditorRef = (editor) => (this.editor = editor)

    render() {
        const { avatar } = this.props;
        const { scale, rotation } = this.state;
        return <div className="avatar-editor">
            <div className="avatar-editor-avatar">
                <AvatarEditor
                    crossOrigin="anonymous"
                    ref={this.setEditorRef}
                    image={avatar.previewUrl}
                    width={250}
                    height={250}
                    scale={scale}
                    rotate={rotation}
                    borderRadius={125}
                />
            </div>
            <div className="avatar-editor-controls">
                <div className="avatar-editor-controls-zoom">
                    <div className="avatar-editor-controls-zoom control" title={arxs.t("controls.avatar_editor.zoom_out")} onClick={() => this.onZoom(this.zoomDirection.out)}>
                        <i className="fa fa-search-minus"></i>
                    </div>
                    <div className="avatar-editor-controls-zoom control" title={arxs.t("controls.avatar_editor.zoom_in")} onClick={() => this.onZoom(this.zoomDirection.in)}>
                        <i className="fa fa-search-plus"></i>
                    </div>
                </div>
                <div className="avatar-editor-controls-rotation">
                    <div className="avatar-editor-controls-rotation control" title={arxs.t("controls.avatar_editor.rotate_left")} onClick={() => this.onRotate(this.rotateDirection.left)}>
                        <i className="far fa-undo"></i>
                    </div>
                    <div className="avatar-editor-controls-rotation control" title={arxs.t("controls.avatar_editor.rotate_right")} onClick={() => this.onRotate(this.rotateDirection.right)}>
                        <i className="far fa-undo fa-flip-horizontal"></i>
                    </div>
                </div>
            </div>
            <HorizontalSeparator />
            <div className="avatar-editor-submit" onClick={this.onSubmit}>
                {arxs.t("controls.avatar_editor.submit")}
            </div>
        </div>
    }
}
export default ArXsAvatarEditor;