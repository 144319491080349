import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import GlobalContext from "infra/GlobalContext";
import FormItemEditorController from "./FormItemEditorController";
import ModuleSelector from "components/controls/ModuleSelector";
import DropDown from "components/controls/DropDown";
import { TextArea } from "components/controls/TextArea";
import { OriginModuleEnum } from "infra/api/contracts";

const _modules = [
    OriginModuleEnum.SafetyInstructionCard,
    OriginModuleEnum.InstructionCard,
    OriginModuleEnum.Consultancy,
    // OriginModuleEnum.Task,
    // OriginModuleEnum.PeriodicControl,
    // OriginModuleEnum.PeriodicMaintenance,
    // OriginModuleEnum.RiskAnalysis,
    // OriginModuleEnum.GlobalPreventionPlan,
    // OriginModuleEnum.Document,
];

const _actionKeys = {
    select: "select",
    create: "create",
    createOrReuse: "create_or_reuse"
};

export default class Link extends FormItemEditorController {
    lookups = {}

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups,
        }
    }

    _actions = [
        {
            id: _actionKeys.select,
            name: arxs.t("forms.items.link.select_existing"),
        },
        {
            id: _actionKeys.create,
            name: arxs.t("forms.items.link.create_new"),
            includeModules: [OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.InstructionCard]
        },
        {
            id: _actionKeys.createOrReuse,
            name: arxs.t("forms.items.link.create_new_or_reuse_most_recent"),
            includeModules: [OriginModuleEnum.SafetyInstructionCard, OriginModuleEnum.InstructionCard]
        }
    ]

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    getActionsForModule = (module) => {
        return this._actions.filter(x => !x.includeModules || x.includeModules.includes(module));
    };

    renderControl = (context) => {
        const { field, readOnly } = this.props;
        const value = field.getter();
        const data = value.data || [];

        const handleActionChange = (action) => {
            field.setter({ ...value, data: { ...data, action: action ? action.id : null } });
        };

        const handleModulesChange = (modules) => {
            if (modules) {
                field.setter({ ...value, data: { ...data, modules: modules.filter(x => x.id).map(x => x.id) } });
            }
        };

        const actions = this.getActionsForModule((data.modules || [])[0]);
        const titleField = {
            getter: () => data.title,
            setter: (value) => field.setter({ ...value, data: { ...data, title: value } }),
            readOnly: this.props.readOnly,
        };

        return <div className="link-container">
            <ModuleSelector
                className="module-selector"
                modules={_modules}
                selected={data.modules || []}
                onChange={handleModulesChange}
                readOnly={readOnly}
                singleSelection={true}
            />
            <DropDown
                className="action-selector"
                selected={actions.firstOrDefault(x => x.id === data.action)}
                items={actions}
                onChange={handleActionChange}
                />
            {(data.action === _actionKeys.create || data.action === _actionKeys.createOrReuse) && <TextArea
                className="object-title"
                field={titleField}
                placeholder={arxs.t("forms.items.link.link_title_override")}
            />}
        </div>;
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderControl(context)}
                    </div>
                    {this.renderEnd()}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}