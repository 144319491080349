import React, { Fragment, useState, useEffect } from "react";
import arxs from "infra/arxs";
import Badge from "../Badge";

export default function RiskAnalysisValue(props) {
  const [deserialized, setDeserialized] = useState();
  const [displayValue, setDisplayValue] = useState(0);
  const [color, setColor] = useState("status-error");

  useEffect(() => {
    const deserialize = (rawValue) => {
      try {
        const payload = rawValue && JSON.parse(rawValue);
        return payload;
      } catch {
        arxs.logger.error("RiskAnalysisValue: Couldn't deserialize {rawValue}", rawValue);
      }
    };
    let ds;
    switch (props.type) {
      case "kinney":
      case "4factor":
      case "matrix":
        if (props.value) {
          if (typeof (props.value) === "string") {
            ds = deserialize(props.value) || {};
          } else {
            ds = props.value || {};
          }
        };
        break;
      case "number":
        ds = deserialize(props.value);
        break;
      default:
        ds = props.value;
        break;
    }

    setDeserialized(ds);

  }, [props]);

  useEffect(() => {
    if (deserialized) {
      let dv = 0;
      switch (props.type) {
        case "kinney":
        case "4factor":
          dv = Object.keys(deserialized || {})
            .filter(x => ["severity", "chance", "exposure", "avertability"].includes(x))
            .reduce((prev, cur) => (prev || 1) * deserialized[cur], 0) || 0;
          break;
        case "matrix":
          dv = ((deserialized || {}).col || 0) * ((deserialized || {}).row || 0);
          break;
        case "thumbs":
        case "smileys":
          dv = Number(deserialized.value || 0);
          break;
        case "number":
          dv = deserialized.value;
          break;
        default: break;
      };

      setDisplayValue(dv);
    }
  }, [deserialized]);

  useEffect(() => {
    let c = "status-error";

    if (props.type === "matrix") {
      switch (true) {
        case (displayValue < 5): c = "status-completed"; break;
        case (displayValue < 11): c = "status-warning"; break;
        case (displayValue < 20): c = "status-severe"; break;
        default: break;
      }
    } else {
      if (props.type === "thumbs" || props.type === "smileys") {
        switch (displayValue) {
          case 1: c = "status-completed"; break;
          case 2: c = "status-active"; break;
          case 3: c = "status-warning"; break;
          case 4: c = "status-severe"; break;
          default: break;
        }
      } else {
        switch (true) {
          case (displayValue < 20): c = "status-completed"; break;
          case (displayValue < 70): c = "status-active"; break;
          case (displayValue < 200): c = "status-warning"; break;
          case (displayValue < 400): c = "status-severe"; break;
          default: break;
        }
      }
    }

    setColor(c);
  }, [displayValue])

  const getSmiley = () => {
    switch (displayValue) {
      case 1: return <i className={`fas fa-grin ${color}`}></i>;
      case 2: return <i className={`fas fa-smile ${color}`}></i>;
      case 3: return <i className={`fas fa-meh ${color}`}></i>;
      case 4: return <i className={`fas fa-angry ${color}`}></i>;
      case 5: return <i className={`fas fa-tired ${color}`}></i>;
      default: break;
    }
  }

  const getThumb = () => {
    if (displayValue) {
      return <i className={`fas fa-thumbs-up ${color}`}></i>;
    }
  }

  const renderBadge = () => {
    return <Badge className={color}>{(displayValue || 0).toFixed(2)}</Badge>;
  }

  switch (props.type) {
    case "kinney":
    case "4factor":
    case "number":
    case "matrix":
      return renderBadge();
    case "thumbs":
      return <Fragment>{props.includeRiskValue && renderBadge()}{getThumb()}</Fragment>;
    case "smileys":
      return <Fragment>{props.includeRiskValue && renderBadge()}{getSmiley()}</Fragment>;

    default: return <span>{displayValue}</span>;
  }
};