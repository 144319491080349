import React, { Component } from "react";
import GlobalContext from "infra/GlobalContext";
import arxs from "infra/arxs";
import { createUpload } from "components/controls/upload/Upload";
import { createCardLookup } from "components/shell/CardLookup/CardLookup";
import DocumentLabel from "components/controls/documents/DocumentLabel";
import Collapsible from "react-collapsible";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import Toaster from "components/util/Toaster";

import "./DocumentOverview.scss";
import {
  OriginModuleEnum,
  AttachmentInfo,
  ObjectDocumentType,
  AttachmentType,
  DocumentManagementDocument,
} from "infra/api/contracts";
import { SecurityContext } from "infra/SecurityContext";

export interface IModule {
  name: string;
  documentTypes: Array<string>;
}

export interface IDocument {
  type: string;
  name?: string;
  url?: string;
  previewUrl?: string;
  contentType?: string;
  hash?: string;
  id: string;
  attachmentid: string;
  objectId?: string;
  storedFileId?: string;
}

export interface IWeblink {
  type: string;
  name: string;
  url: string;
  id?: string;
  objectId?: string;
  storedFileId?: string;
}

export interface IAttachmentInfo {
  value: string;
  id?: string;
}

export interface IDocumentOverviewProps {
  module: OriginModuleEnum;
  readOnly: boolean;
  readOnlyTypes?: Array<{ type: ObjectDocumentType, message: string }>;
  onDelete(value: IDocument): void;
  onAdd(value: {
    documents: Array<IDocument>;
    weblinks: Array<IWeblink>;
  }): void;
  onRename(value: IAttachmentInfo): void;
  className?: string;
  objectId?: string;
  securityContext?: SecurityContext;
  attachmentInfo: AttachmentInfo;
  correlationKey?: string;
  disableInlineEdit?: boolean;
}

export interface IDocumentOverviewState {
  upload?: any;
  documentTypes: Array<string>;
  singleFileDocumentTypes: Array<string>;
  cardLookup?: any;
  documentMap: { [key: string]: DocumentManagementDocument },
}

export default class DocumentOverview extends Component<
  IDocumentOverviewProps,
  IDocumentOverviewState
> {
  state: IDocumentOverviewState = {
    documentTypes: [],
    singleFileDocumentTypes: [],
    documentMap: {},
  };

  subscriptions: any = null;

  lookups: any = {
    documentMap: {}
  };

  componentDidMount() {
    const meta = arxs.moduleMetadataRegistry.get(this.props.module);
    this.setState({
      documentTypes: meta.allowedDocumentTypes,
      singleFileDocumentTypes: meta.singleFileDocumentTypes || [],
    });

    this.subscriptions = {
      lookups: arxs.Api.lookups
        .subscribe(this.lookups, (lookups: any) => this.setState({ ...lookups }))
    };
  }

  componentWillUnmount() {
    if (this.subscriptions) {
      this.subscriptions.lookups.dispose();
    }
  }

  handleUploadFiles = (
    context: {
      popup: {
        close: () => void;
        show: (arg0: React.Component<{}, {}, any>) => void;
      };
    },
    documentType: any
  ) => {
    const { module, correlationKey } = this.props;
    const { singleFileDocumentTypes, documentTypes } = this.state;

    const onSubmit = (payload: any) => {
      context.popup.close();
      if (this.props.onAdd) {
        this.props.onAdd({ documents: payload.documents, weblinks: [] });
      }
    };

    let upload = createUpload(
      module,
      null,
      onSubmit,
      singleFileDocumentTypes,
      documentType !== undefined
        ? [documentType]
        : documentTypes.filter((x) => x !== ObjectDocumentType.Image),
      null,
      correlationKey
    );
    this.setState({ upload: upload }, () =>
      context.popup.show(this.state.upload)
    );
  };

  handleAddDocuments = (
    context: {
      popup: {
        close: () => void;
        show: (arg0: React.Component<{}, {}, any>) => void;
      };
    },
    documentType: any
  ) => {
    const { securityContext, module } = this.props;

    const onApplyFilter = (selectedDocuments: any) => {
      context.popup.close();

      var documents: any = Object.keys(selectedDocuments).map((x: any) => ({
        type: documentType,
        documentId: x,
        id: arxs.uuid.generate(),
        name: undefined,
      }));

      documents.map((doc: any) => {
        arxs.ApiClient.shared.documentManagement
          .getById(doc.documentId)
          .then((document) => {
            if (document) {
              const mainDoc = ((
                (document.attachmentInfo || {}).attachments || []
              ).filter((x) => x.type === ObjectDocumentType.MainDocument)[0]
                .value || [])[0];

              const mainDocInfo: any = (
                (document.attachmentInfo || {}).storedFiles || []
              ).filter((x: any) => x.id === mainDoc.id)[0];

              doc.name = document.title;
              doc.contentType = (mainDocInfo || {}).contentType;
              doc.url =
                (mainDocInfo || {}).url ||
                (mainDoc &&
                  mainDoc.type === AttachmentType.Weblink &&
                  mainDoc.props &&
                  mainDoc.props["url"]);
            }
            this.props.onAdd({ documents: [doc], weblinks: [] });
          });
      });
    };

    const cardLookup = createCardLookup({
      modules: [OriginModuleEnum.Document],
      onApplyFilter,
      securityContext,
      viewMode: "tree",
      module,
    });

    this.setState({ cardLookup }, () =>
      context.popup.show(this.state.cardLookup)
    );
  };

  handleAddWebLink = (context: any, documentType: any) => {
    const executeAction = (weblink: string) => {
      if (!arxs.isValidUrl(weblink)) {
        Toaster.error("Geen geldige weblink");
      } else {
        const parsedUrl = arxs.parseURL(weblink);
        const weblinkInfo = {
          type: documentType,
          name: parsedUrl.hostname,
          url: weblink,
        };

        if (this.props.onAdd) {
          this.props.onAdd({ weblinks: [weblinkInfo], documents: [] });
        }

        context.inputPopup.close();
      }
    };

    const webLink = createInputPopup(
      context,
      arxs.t("controls.document_overview.actions.weblink"),
      (weblink: string) => executeAction(weblink),
      true,
      true,
      "",
      arxs.t("common.save"),
      arxs.t("common.cancel")
    );

    context.inputPopup.show(webLink);
  };

  onDelete = (document: IDocument) => {
    if (this.props.onDelete) {
      this.props.onDelete(document);
    }
  };

  onRename = (value: string, id: string) => {
    if (this.props.onRename) {
      this.props.onRename({ id, value });
    }
  };

  render() {
    const { attachmentInfo, readOnly, readOnlyTypes, className } = this.props;
    const { singleFileDocumentTypes } = this.state;

    let documentsByType: any = {};
    let weblinksByType: any = {};

    let documentAttachments = ((attachmentInfo || {}).attachments || []).filter(
      (x) =>
        x.type &&
        ![
          ObjectDocumentType.Image,
          ObjectDocumentType.FormImage,
          ObjectDocumentType.FormDocument,
        ].includes(x.type)
    );

    const documents = ((attachmentInfo || {}).storedFiles || [])
      .concat((attachmentInfo || {}).documents || [])
      .filter((x) =>
        documentAttachments
          .flatMap((y) => y.value?.filter((x) => !x.isDeleted))
          .map((z) => z && z.id)
          .includes(x.id)
      )
      .map((x) => ({
        ...x,
        name:
          (
            (
              (documentAttachments
                .flatMap((y) => y.value)
                .filter((z) => z && z.id === x.id) || [])[0] || {}
            ).props || {}
          ).name || x.name,
      }));

    const distinctDocumentTypes = documentAttachments
      .filter((x) => x.type)
      .map((x) => x.type || "")
      .distinct((x) => x);

    for (const documentType of distinctDocumentTypes) {
      documentsByType[documentType] = documents.filter((x) =>
        (
          (documentAttachments.filter((x) => x.type === documentType) || [])[0]
            .value || []
        )
          .flatMap((x) => x.id)
          .includes(x.id)
      );

      weblinksByType[documentType] = (
        documentAttachments.filter((x) => x.type === documentType)[0].value ||
        []
      ).filter((x) => x.type === AttachmentType.Weblink && !x.isDeleted);
    }

    const canAddFromDocumentManagement = (documentType: any) => {
      const { module } = this.props;
      if (
        module !== OriginModuleEnum.Document ||
        (module === OriginModuleEnum.Document &&
          documentType !== ObjectDocumentType.MainDocument)
      ) {
        return true;
      }

      return false;
    };
    return (
      <GlobalContext.Consumer>
        {(context) => (
          <div className="document-overview">
            {this.state.documentTypes
              .filter(
                (x: string) =>
                  x.toLowerCase() !== ObjectDocumentType.Image.toLowerCase()
              )
              .map((documentType: string) => (
                <Collapsible
                  trigger={
                    arxs.documentTypes.titles[documentType.toLowerCase()]
                  }
                  open={true}
                  key={documentType}
                >
                  <div className={`document_type ${className || ""}`}>
                    <div
                      className={`document-content ${
                        documentType === ObjectDocumentType.MainDocument &&
                        " required"
                      }`}
                    >
                      {(documentsByType[documentType] || [])
                        .map((document: any) => ({
                          item: document,
                          id: document.id,
                          name: document.name,
                          contentType: document.contentType,
                          storedFileId: document.storedFileId,
                          attachmentId: document.id,
                          documentId: document.documentId,
                          previewUrl: !document.storedFileId && (document.url || (document.props ||{}).url),
                          disableInlineEdit: this.props.disableInlineEdit,
                          isArchived: !!(this.state.documentMap[document.documentId] || {}).isDeleted
                        }))
                        .concat((weblinksByType[documentType] || [])
                          .map((weblink: any) => (
                            {
                              item: weblink,
                              id: weblink.id,
                              name: weblink.props.name,
                              url: weblink.props.url,
                              contentType: "weblink",
                              isWeblink: true,
                              isArchived: false,
                            })))
                        .orderBy((x: any) => x.name.toLowerCase())
                        .map((x: any, i: any) => {
                          return <DocumentLabel
                            key={x.id}
                            id={x.id}
                            name={x.name}
                            type={documentType}
                            contentType={x.contentType}
                            onDelete={() => this.onDelete(x.item)}
                            onRename={this.onRename}
                            readOnly={readOnly}
                            module={this.props.module}
                            objectId={this.props.objectId || (this.props.attachmentInfo || {}).objectId}
                            storedFileId={x.storedFileId}
                            attachmentId={x.id}
                            documentId={x.documentId}
                            previewUrl={x.previewUrl}
                            disableInlineEdit={x.disableInlineEdit}
                            url={x.url}
                            isWeblink={x.isWeblink}
                            isArchived={x.isArchived}
                          />
                        })
                      }
                    </div>

                    {!readOnly
                      && !(readOnlyTypes || []).some((x) => x.type == documentType)
                      && (!singleFileDocumentTypes.some((x) => x === documentType)
                        || ((documentsByType[documentType] || []).length === 0
                          && (weblinksByType[documentType] || []).length ===0)
                        )
                      && (
                        <div className="document-actions">
                          <div
                            className="action"
                            onClick={() =>
                              this.handleUploadFiles(context, documentType)
                            }
                            title={arxs.t(
                              "controls.document_overview.actions.upload"
                            )}
                          >
                            <i className="far fa-file-upload"></i>
                          </div>
                          {canAddFromDocumentManagement(documentType) && (
                            <div
                              className="action"
                              onClick={() =>
                                this.handleAddDocuments(context, documentType)
                              }
                              title={arxs.t(
                                "controls.document_overview.actions.document_management"
                              )}
                            >
                              <i className="fas fa-books"></i>
                            </div>
                          )}
                          <div
                            className="action"
                            onClick={() =>
                              this.handleAddWebLink(context, documentType)
                            }
                            title={arxs.t(
                              "controls.document_overview.actions.weblink"
                            )}
                          >
                            <i className="far fa-globe"></i>
                          </div>
                        </div>
                      )}

                    {(readOnlyTypes || []).some((x) => x.type == documentType)
                      && <label className="document-overview-read-only-message">{(readOnlyTypes || []).map(x => x.message)}</label>}
                  </div>
                </Collapsible>
              ))}
          </div>
        )}
      </GlobalContext.Consumer>
    );
  }
}
