import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import DropDown from "components/controls/DropDown";
import ToggleButton from "components/controls/ToggleButton";
import { OriginModuleEnum } from "infra/api/contracts";

export default class Recommendation extends FormItemEditorController {
    lookups = {
        codeElements: {},
        codeElementsById: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.lookups
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
    }

    getConclusions = () => {
        const { codeElements } = this.state;
        let code;

        switch (this.props.targetModule || this.props.module) {
            case OriginModuleEnum.Commissioning:
                code = "Commissioning.Conclusion";
                break;
            case OriginModuleEnum.OutOfCommissioning:
                code = "OutOfCommissioning.Conclusion";
                break;
            default: break;
        }

        if (!code) {
            return [];
        }

        const conclusionKind = ((codeElements || {})[code] || [])[0] || {};
        const conclusions = (conclusionKind.children || [])
            .map(x => ({ id: x.id, name: x.name }));
        return conclusions;
    }

    renderRecommendation = (context) => {
        const { codeElementsById } = this.state;
        const { field, readOnly } = this.props;
        const value = field.getter();
        const data = value.data || {};

        const conclusions = this.getConclusions();
        const selectedConclusion = data && data.conclusion && codeElementsById ? { id: data.conclusion, name: (codeElementsById[data.conclusion] || {}).name } : undefined;
        const status = data.status;
        const statusValues = ["FAVORABLE", "UNFAVORABLE"];

        const onItemChange = (state) => {
            field.setter({ ...value, data: state });
        };

        const onConclusionChange = (state) => {
            onItemChange({ ...data, conclusion: state.id });
        }

        const onStatusChange = (state) => {
            onItemChange({ ...data, status: state });
        }

        const getToggleTranslation = (value) => {
            return arxs.t(`enums.ConclusionStatusEnum.${value}`)
        }

        return <div className="recommendation">
            <ToggleButton
                value={status}
                values={statusValues}
                textSelector={getToggleTranslation}
                onChange={(_, value) => onStatusChange(value)}
                readOnly={readOnly}
            />
            <DropDown
                id={`conclusion`}
                key={`conclusion`}
                className="conclusion"
                items={conclusions}
                selected={selectedConclusion}
                onChange={onConclusionChange}
                readOnly={readOnly}
                placeholder={arxs.t("forms.items.recommendation.conclusion")}
            />
        </div>;
    }

    render() {
        return (<GlobalContext.Consumer>
            {
                (context) => <Fragment>
                    {this.renderBegin()}
                    <div className="form-item-control-container">
                        {this.renderTitle(context)}
                        {this.renderRecommendation(context)}
                    </div>
                    {this.renderEnd(true)}
                </Fragment>
            }
        </GlobalContext.Consumer>)
    }
}