import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";

class EmployeeActions {
  getActions = (module) => {
    return [
      {
        name: "out_of_service",
        module: module,
        icon: arxs.actions.icons["archive"],
        getTitle: () => arxs.actions.titles["out_of_service"],
        onClick: (state) => this.setOutOfService(state),
      },
      {
        name: "in_service",
        module: module,
        icon: arxs.actions.icons["reactivate"],
        getTitle: () => arxs.actions.titles["reactivate"],
        onClick: (state) => this.setInService(state),
      },
      {
        name: "sick",
        module: module,
        icon: arxs.actions.icons["sick"],
        getTitle: () => arxs.actions.titles["sick"],
        onClick: (state) => this.setSick(state),
      },
    ];
  };

  setOutOfService = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.masterdata.employee
      .setOutOfService(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.employee.out_of_service_confirmation"))
      );
  };

  setSick = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.masterdata.employee
      .setSick(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.employee.sick_confirmation"))
      );
  };

  setInService = (state) => {
    const ids = state.ids || [state.item.objectId];
    return arxs.ApiClient.masterdata.employee
      .setInService(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.employee.reactivate_confirmation"))
      );
  };

  dropActions = () => {
    return {
      onDropInActive: (state) => {
        this.setInService(state);
      },
      onDropInSick: (state) => {
        this.setSick(state);
      },
      onDropInOutOfService: (state) => {
        this.setOutOfService(state);
      },
    };
  };
}
export default new EmployeeActions();
