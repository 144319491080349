import React, { Component } from 'react';
import arxs from 'infra/arxs';
import TreeView from 'components/controls/treeview/TreeView'
import GlobalContext from 'infra/GlobalContext';
import { ProductType } from 'infra/Types';
import Toaster from 'components/util/Toaster';

import './CodeElementManagement.scss';

export default class CodeElementManagement extends Component {
  lookups = {
  }

  constructor(props) {
    super(props);

    this.state = {
      ...this.lookups,
      selected: {},
      errors: [],
      code: ""
    };
  }

  getModule() {
    return this.props.module;
  }

  componentWillUnmount() {
  }

  componentDidMount() {
  }

  refresh = () => {
  }

  onItemClick = (item, children) => {
  }

  onSelectTag = (item) => {
  }

  onItemsLoad = (items) => {
  }

  onAddItem = (value, typeId) => {
  }

  onDeleteItem = (context, id) => {
  }

  onEditItem = (value, id) => {
  }

  setInputRef = (input) => { this.inputRef = input }

  processImportResult = (result) => {
    if (result && !Array.isArray(result)) {
      if (result.status && result.status !== 200) {
        Toaster.error(arxs.t("settings.import.error"));
      }
    } else
      if (result && result.length > 0) {
        this.setState({ errors: result })
      }
      else {
        Toaster.success(arxs.t("settings.import.success"));
      }
  }

  onExport = (context, code, name) => {
    this.setState({ code }, () => {
      Toaster.notify(arxs.t("settings.import.templateRequested"));

      arxs.ApiClient.masterdata.codeElements.getImportTemplate(code).then((resObj) => {
        const objUrl = window.URL.createObjectURL(resObj);
        var fileLink = document.createElement("a");
        fileLink.href = objUrl;
        fileLink.download = `instellingen.${name}.xlsx`;
        fileLink.click();
      });
    })
  }

  onImport = (context, code) => {
    this.setState({ code, validationErrors: [] }, () => {
      this.inputRef.click();
    })
  }

  executeImport = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { code } = this.state;

    var file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '');

        Toaster.notify(arxs.t("settings.import.started"));
        arxs.ApiClient.masterdata.codeElements
          .importData({ code: code, base64File: base64String })
          .then(result => this.processImportResult(result));

      };

      reader.readAsDataURL(file);
    }

    e.target.value = null;
  }

  renderImportResult = (context) => {
    const { errors } = this.state;
    if (errors && errors.length !== 0) {
      return <ul className="validation-summary">
        {errors.map((e, i) => <li key={`validation-${i}`}>{e}</li>)}
      </ul>
    }
  }

  render() {
    const module = this.getModule();
    const meta = arxs.moduleMetadataRegistry.get(module);
    const settingsWriteAction = meta.settings.writeAction;
    let excludeCodes = [];
    let additionalCodes = meta.settings.additionalCodes || [];

    if (arxs.productType === ProductType.Hyperion) {
      excludeCodes = ["HazardousSubstance.EducationAdvice", "EducationDome", "EducationPortal"];
    }

    return (
      <GlobalContext.Consumer>
        {(context) =>
          <div className="codeelementtreeview">
            {this.renderImportResult(context)}
            <TreeView
              allCodes={true}
              onItemClick={context.platform.isMobile ? this.onSelectTag : this.onItemClick}
              onItemsLoad={this.onItemsLoad}
              onAddNewItem={this.onAddItem}
              onDeleteItem={this.onDeleteItem}
              onEditItem={this.onEditItem}
              itemIcon="fad fa-folder"
              allowEditBranches={arxs.isActionAllowed(settingsWriteAction)}
              module={module}
              securityContext={arxs.securityContext.buildForUserContext()}
              excludeCodes={excludeCodes}
              onExport={this.onExport}
              onImport={this.onImport}
              additionalCodes={additionalCodes}
            />
            <input type='file' id='file' ref={this.setInputRef} style={{ display: 'none' }} onChange={this.executeImport} />
          </div>}
      </GlobalContext.Consumer>
    );
  }
}