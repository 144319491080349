import React, { Component } from 'react';

import './Spacer.scss';

class Spacer extends Component {
  render() {
    let style = {
      width: this.props.width,
      height: this.props.height
    };
    return (
      <div className={`spacer ${this.props.className || ""}`} style={style}>{this.props.children}</div>
    );
  }
}
export default Spacer;