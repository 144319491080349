import React, { Fragment } from "react"
import ViewDropDown from 'components/controls/views/ViewDropDown';
import Button from 'components/controls/Button';
import GlobalContext from "infra/GlobalContext";

export default function ViewsRenderer(props) {
  const { controller } = props;

  if (!controller) {
    return <></>;
  }

  const { views, activeView, activeViewDirty } = controller.getState();

  return <GlobalContext.Consumer>
    {
      context => (<Fragment>
        <ViewDropDown
          data={views}
          selected={activeView}
          onAdd={(title) => controller.addView(title, context)}
          onChange={controller.changeView}
          onRename={(view, title) => controller.renameView(view, title, context)}
          onRemove={(view) => controller.removeView(context, view)} />
        <Button disabled={(activeView && activeView.readOnly) || !activeViewDirty} className="save" onClick={controller.saveView}>
          <i className="fas fa-save"></i>
        </Button>
      </Fragment>)
    }
  </GlobalContext.Consumer>;
}