import React, { useState, useEffect, useRef } from 'react';

import './TextArea.scss';

const InternalTextArea = function (props) {
  const ref = useRef();
  const [cursor, setCursor] = useState(0)

  const value = props.value;

  useEffect(() => {
    if (props.autoFocus) {
      setFocus(this.state.ref.current);
    }
  }, []);

  useEffect(() => {
    const input = ref.current
    if (input) {
        input.setSelectionRange(cursor, cursor)
    }
  }, [ref, cursor, value])

  const formatInput = (value) => {
    if (!props.multiLine || (props.className && props.className.indexOf("input") > -1)) {
      // eslint-disable-next-line no-control-regex
      return value.replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/g, " ");
    }
    return value;
  };

  const setFocus = (input) => {
    input.focus();
    const length = input.value.length;
    input.setSelectionRange(length, length);
  };

  const onChange = (event) => {
    if (props.readOnly) return;
    setCursor(event.target.selectionStart);
    const newValue = formatInput(event.target.value);
    props.onChange(newValue);
  };

  if (props.readOnly) {
    return <div className="textarea">{value}</div>;
  }

  return (
    <textarea
      ref={ref}
      autoCorrect="off" spellCheck="false"
      className={props.className}
      placeholder={props.placeholder}
      dir={props.dir}
      value={value}
      onChange={onChange}
      readOnly={props.readOnly}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
      maxLength={props.maxLength}
      rows="1"
      title={value}
    ></textarea>);
}

export function TextArea(props) {
  const onChange = (newValue) => {
    props.field.setter(newValue);
  };

  const field = props.field;
  const value = field.getter();
  const readOnly = field.readOnly || props.readOnly;
  if (readOnly) {
    return <div className="textarea">{value}</div>;
  }

  const className = [props.className, props.isEditing && "editing"].filter(x => x).join(" ");

  return (
    <InternalTextArea
      className={className}
      value={value}
      title={value}
      onChange={onChange}
      readOnly={!!readOnly}
      multiLine={field.multiLine}
      maxLength={field.maxLength}
      dir={props.dir}
      placeholder={props.placeholder}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      onBlur={props.onBlur}
    ></InternalTextArea>);
}