import arxs from 'infra/arxs';
import Toaster from 'components/util/Toaster';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import { createPlanningMomentEditor } from './PlanningMomentEditor';
import TaskActions from 'modules/facilitymanagement/tasks/TaskActions';
import MaintenanceActions from 'modules/facilitymanagement/maintenances/MaintenanceActions';
import InspectionActions from 'modules/facilitymanagement/inspections/InspectionActions';
import RiskAnalysisActions from 'modules/safety/riskanalysis/RiskAnalysisActions';
import { OriginModuleEnum } from 'infra/api/contracts';

class PlanningActions {
  complete = (state) => {
    const subject = state.planningMoment.subject;

    if (subject.module === OriginModuleEnum.Task) {
      TaskActions.complete({
        ...state,
        ids: [subject.id]
      });
    } else if (subject.module === OriginModuleEnum.PeriodicMaintenance) {
      MaintenanceActions.complete({
        ...state,
        ids: [subject.id]
      });
    } else if (subject.module === OriginModuleEnum.PeriodicControl) {
      InspectionActions.complete({
        ...state,
        ids: [subject.id]
      });
    } else if (subject.module === OriginModuleEnum.RiskAnalysis) {
      RiskAnalysisActions.complete({
        ...state,
        ids: [subject.id]
      });
    } else {
      arxs.logger.error("PlanningActions.complete doesn't support subject of module {module}", subject.module);
    }
  }

  createFollowUp = (state) => {
    const subject = state.planningMoment.subject;
    const props = { cards: [subject] };

    if (subject.module === OriginModuleEnum.Task) {
      TaskActions.createFollowUp(props);
    } else if (subject.module === OriginModuleEnum.PeriodicMaintenance) {
      MaintenanceActions.createFollowUp(props);
    } else if (subject.module === OriginModuleEnum.PeriodicControl) {
      InspectionActions.createFollowUp(props);
    } else if (subject.module === OriginModuleEnum.RiskAnalysis) {
      RiskAnalysisActions.createFollowUp(props);
    } else {
      arxs.logger.error("PlanningActions.complete doesn't support subject of module {module}", subject.module);
    }
  }

  edit = (state) => {
    const { context, planningMoment, onSubmit, onCancel } = state;

    const popup = createPlanningMomentEditor(planningMoment
      , (data) => {
        context.popup.close();

        if (onSubmit) {
          onSubmit(data);
        }

        arxs.ApiClient.facilitymanagement.planning.post(data)
          .then(result => Toaster.success(arxs.t('actions.planning.planning_edited')));
      }
      , () => {
        if (onCancel) {
          onCancel();
        }
        context.popup.close();
      });

    context.popup.show(popup);
  }

  delete = (state) => {
    const { context, planningMoment } = state;

    const popup = createInputPopup(context
      , arxs.t("actions.planning.delete_confirmation")
      , () => {
        arxs.ApiClient.facilitymanagement.planning.delete(planningMoment.id, planningMoment.assigneeId)
          .then(() => Toaster.success(arxs.t("actions.planning.planning_deleted")));
      });

    context.inputPopup.show(popup);
  }
}
export default new PlanningActions();