import React, { Component, Fragment } from 'react';
import GlobalContext from 'infra/GlobalContext';
import CheckBox from 'components/controls/CheckBox';
import arxs from 'infra/arxs'

import './CodeElementLabel.scss';

const _groupCodes = [
    "TypeGroup",
    "NormGroup",
    "EmptyGroup"
];

export default class CodeElementLabel extends Component {
    state = {
        path: []
    }

    lookups = {
        codeElementsById: {},
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => {
        if (this.state.codeElementsById) {
            let currentCodeElement = this.state.codeElementsById[this.props.data.id];
            
            const path = [];
            let i = 0;

            while (currentCodeElement && currentCodeElement.parentId && path.length < 3 && i < 5) {
                const { code } = currentCodeElement;

                if (!_groupCodes.contains(code)) {
                    path.push(currentCodeElement);
                }

                currentCodeElement = this.state.codeElementsById[currentCodeElement.parentId];
                i++;
            }

            this.setState({ path: path.reverse() });
        }
    }

    handleCheckboxClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.data);
        }
    }

    onDelete = (event) => {
        if (this.props["onDelete"]) {
            this.props.onDelete(this.props.id);
        }
    }

    render() {
        const { checkBox, checked, readOnly, allowDelete } = this.props
        const { path } = this.state;

        const renderCheckBox = () => {
            if (checkBox && path.length > 0) {
                const codeElement = path[path.length - 1];

                return <div className="codeElement-checkbox">
                    <CheckBox name={codeElement.name} id={codeElement.id} key={codeElement.id} checked={checked} onChange={(e) => e.stopPropagation()} onClick={this.handleCheckboxClick} />
                </div>;
            }
        }

        const renderActions = () => {
            if (!readOnly && allowDelete) {
                return <div className="codeElement-actions">
                    <div className="codeElement-remove"
                        onClick={this.onDelete}
                        title={arxs.t('controls.codeelement_label.delete')}>
                        <i className="far fa-trash-alt"></i>
                    </div>
                </div>
            }
        }

        const renderNode = (node) => {
            if (node && node.name) {
                return [<div key={node.id} className={`codeElement-type-name ${checked && 'selected'}`} title={node.name} id={node.id}>
                    {node.name}
                </div>];
            }

            return [];
        }

        return (
            <GlobalContext.Consumer>
                {(context) =>
                    <div className={`codeElement-label ${checked && 'selected'}`} onClick={this.handleCheckboxClick}>
                        <div className={`codeElement-icon-wrapper ${readOnly ? "readOnly" : "allowDelete"}`}>
                            <div className={`codeElement-icon ${checked && 'selected'}`}>
                                <i className='fal fa-share-alt' ></i>
                            </div>
                        </div>
                        <div className="codeElement-info-wrapper">
                            {path.flatMap(renderNode)}
                        </div>
                        {renderActions()}
                        {renderCheckBox()}
                    </div>}
            </GlobalContext.Consumer >
        );
    }
}          