import { ArXsState } from "infra/arxs";
import { OriginModuleEnum } from "infra/api/contracts";

import ModuleMetadata from "modules/ModuleMetadata";
import UserRoleCard from "./UserRoleCard";

const getUserRoleMetadata = (arxs: ArXsState): ModuleMetadata => {
  const module = OriginModuleEnum.UserRole;

  return {
    module,
    base: {
      name: "",
      route: "",
      getResource: (module?: string) => ({ get: () => new Promise(() => {}), set: (data: any) => new Promise(() => {}) }),
      subscribe: (id: string, module: string, handler: (x: any) => any) => {},
      readAction: "",
      writeAction: "",
      createAction: "",
    },
    allowedDocumentTypes: [],
    actions: [],
    statuses: [],
    title: arxs.t("modules.planning"),
    icon: "far fa-user-tag",
    path: "/configuration/rolemanagement",
    cardClass: UserRoleCard,
    canSubscribe: false,
    canNavigateTo: false,
  };
};

export default getUserRoleMetadata;
