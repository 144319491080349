const createOptionPopupState = (setState, title, options, props) => {
  var state = {
    ...props,
    title: title || null,
    options: options || null,
    show: (title, options, props) => {
      setState(createOptionPopupState(setState, title, options, props));
    },
    close: () => {
      setState(createOptionPopupState(setState));
    }
  };
  return state;
};
export default createOptionPopupState; 