import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import { createUnexpirePopup } from "./UnexpirePopup";
import GlobalActions from "modules/GlobalActions";
import ClassicActions from "modules/ClassicActions";

class AssetActions {
  getActions = (module) => {
    let assetActions = GlobalActions.getActions(module)
      .concat(ClassicActions.getActions(module))
      .concat([
        {
          name: "request_commissioning",
          module: module,
          icon: "far fa-sign-in",
          getTitle: () => arxs.t("actions.commissioning.request"),
          onClick: (state) => this.requestCommissioning(state, module),
        },
        {
          name: "cancel_commissioning",
          module: module,
          icon: "far fa-ban",
          getTitle: () => arxs.t("actions.commissioning.cancel"),
          onClick: (state) => this.cancelCommissioning(state, module),
        },
        {
          name: "request_decommissioning",
          module: module,
          icon: "far fa-sign-out",
          getTitle: () => arxs.t("actions.decommissioning.request"),
          onClick: (state) => this.requestDecommissioning(state, module),
        },
        {
          name: "cancel_decommissioning",
          module: module,
          icon: "far fa-ban",
          getTitle: () => arxs.t("actions.decommissioning.cancel"),
          onClick: (state) => this.cancelDecommissioning(state, module),
        },
        {
          name: "unexpire",
          module: module,
          icon: "far fa-ban",
          getTitle: () => arxs.t("actions.unexpire.request"),
          onClick: (state) => this.unexpire(state, module),
        },
        {
          name: "create_safetyinstructioncard",
          module: module,
          icon: "far fa-shield-alt",
          getTitle: () => arxs.actions.titles["create_safetyinstructioncard"],
          onClick: (state) => this.createSafetyInstructionCard(state, module),
        },
        {
          name: "create_instructioncard",
          module: module,
          icon: "far fa-file-alt",
          getTitle: () => arxs.actions.titles["create_instructioncard"],
          onClick: (state) => this.createInstructionCard(state, module),
        },
      ]);
    
    return assetActions;
  }

  requestCommissioning = (state, module) => {
    const ids = state["ids"];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .requestCommissioning(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.commissioning.requested_successfully"))
      );
  }

  cancelCommissioning = (state, module) => {
    const ids = state["ids"];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .cancelCommissioning(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.commissioning.request_cancelled"))
      );
  }
  
  requestDecommissioning = (state, module) => {
    const ids = state["ids"];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .requestDecommissioning(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.decommissioning.requested_successfully"))
      );
  }

  cancelDecommissioning = (state, module) => {
    const ids = state["ids"];
    return arxs
      .moduleMetadataRegistry
      .getResourceByModule(module)
      .cancelDecommissioning(ids)
      .then(() =>
        Toaster.success(arxs.t("actions.decommissioning.request_cancelled"))
      );
  }

  takeOutOfCommission = (state, module) => {
    const handle = (isShort) => {
      this.executeClassicAction(
        "/OutOfCommissioning/CreateStart",
        "/OutOfCommissioning/Create",
        {
          ids: state.ids,
          origin: module,
          isShort,
        }
      );
    };

    state.context.optionPopup.show(
      arxs.t("actions.out_of_commissioning.create"),
      [
        {
          title: arxs.t("actions.out_of_commissioning.normal"),
          handle: () => handle(false),
        },
        {
          title: arxs.t("actions.out_of_commissioning.short"),
          handle: () => handle(true),
        },
      ]
    );
  }

  unexpire = (state, module) => {
    const context = state.context;

    const executeAction = (data) => {
      arxs
        .moduleMetadataRegistry
        .getResourceByModule(module)
        .unExpire(data)
        .then(() =>
          Toaster.success(arxs.t("actions.unexpire.completed_confirmation"))
        );

      context.popup.close();
    };

    const ids = state["ids"];
    const unexpireAssetPoppup = createUnexpirePopup(
      ids,
      executeAction,
      context.popup.close,
      module
    );
    context.popup.show(unexpireAssetPoppup);
  }

  createSafetyInstructionCard = (state, module) => {
    let { cards, history, item } = state;

    if (!cards && item){
      cards = [item.card];
    }

    if (cards.length === 1) {
      const {
        id,
        module,
        legalStructureId,
        branchId,
        buildingId,
        locationId,
      } = cards[0];

      const state =  {
        legalStructure: { id: legalStructureId },
        branch: { id: branchId },
        building: { id: buildingId },
        location: { id: locationId },
        subject: { id, module },
      };
      history.push({ pathname: `/safetyinstructioncard/create`, state });
    }
    return new Promise(() => { });
  }

  
  createInstructionCard = (state, module) => {
    let { cards, history, item } = state;

    if (!cards && item){
      cards = [item.card];
    }

    if (cards.length === 1) {
      const {
        id,
        module,
        legalStructureId,
        branchId,
        buildingId,
        locationId,
      } = cards[0];

      const state =  {
        legalStructure: { id: legalStructureId },
        branch: { id: branchId },
        building: { id: buildingId },
        location: { id: locationId },
        subject: { id, module },
      };
      history.push({ pathname: `/instructioncard/create`, state });
    }
    return new Promise(() => { });
  }
}
export default new AssetActions();
