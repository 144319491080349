import React, { useRef, useState, useEffect } from 'react';
import { BryntumGantt } from '@bryntum/gantt-react-thin';
import { ProjectModel } from '@bryntum/gantt-thin';
import { LocaleManager } from '@bryntum/core-thin';
import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs';
import { OriginModuleEnum } from 'infra/api/contracts';

import '@bryntum/core-thin/core.classic-light.css';
import '@bryntum/grid-thin/grid.classic-light.css';
import '@bryntum/gantt-thin/gantt.classic-light.css';
import './Gantt.scss';

export default function Gantt(props) {
  const ref = useRef();

  LocaleManager.applyLocale('Nl');

  const lookups = {
    employees: []
  };

  // const [ganttData, setGanttData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [ganttConfig, setGanttConfig] = useState({});
  const [tasks, setTasks] = useState([]);
  const [resources, setResources] = useState([]);
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const subscriptions = {
      lookups: arxs.Api.lookups.subscribe(lookups, (values) => {
        for (var key of Object.keys(values)) {
          switch (key) {
            case "employees": setEmployees(values[key]); break;
            default: break;
          }
        }
      })
    };

    return () => {
      subscriptions.lookups.dispose();
    };
  }, [lookups]);

  useEffect(() => {

    const mapSection = (proj, section) => {
      const sectionId = section.id !== "00000000-0000-0000-0000-000000000000" ? section.id : arxs.uuid.generate();

      let ganttNode = {
        name: section.title,
        id: generateId(proj.id, sectionId),
        sectionId: sectionId,
        rootId: proj.id,
        expanded: true,
        link: { module: section.module, id: section.objectId },
      };

      if (!section.sections || section.sections.length === 0) {
        ganttNode.startDate = section.startDate && (new Date(section.startDate)).toISOString();
        ganttNode.constraintType = "startnoearlierthan";
        ganttNode.constraintDate = ganttNode.startDate;

        if (section.duration && section.duration !== 0) {
          ganttNode.duration = section.duration;
          ganttNode.endDate = ganttNode.startDate && (arxs.dateTime.addDays(ganttNode.startDate, section.duration)).toISOString();
        }
      } else {
        ganttNode.children = (section.sections || []).map(section => mapSection(proj, section));
      }

      return ganttNode;
    };

    const setGanttProject = () => {
      const getTasks = () => {
        const items = (props.data || [])
          .flatMap(x => x.cards)
          .filter(x => !x.isDeleted);

        const tasks = items.map(proj => {

          const gantt = { title: proj.title, id: proj.id, sections: [] };

          return {
            id: generateId(proj.id),
            name: `${proj.uniqueNumber} - ${proj.title}`,
            rootId: proj.id,
            link: { module: OriginModuleEnum.Project, id: proj.id },
            startDate: proj.startDate,
            children: ((proj.gantt || gantt).sections || []).map(section => mapSection(proj, section)),
          }
        });

        return tasks;
      }

      const getResources = () => {
        return employees.map(employee => {
          const hasAvatar = employee.images && employee.images.length > 0;
          return {
            id: arxs.getStableId(OriginModuleEnum.Employee, employee.id),
            name: employee.name,
            image: hasAvatar,
            imageUrl: hasAvatar === true ? employee.images[0].url : null
          }
        });
      };

      const getAssignments = () => {
        let assignments = [];

        const getAssignmentFromTask = (task) => {
          if (task.link) {
            const taskId = task.link.id;
            const taskModule = task.link.module;
            const subject = arxs.Api.lookups.resolveSubject({ id: taskId, module: taskModule });
            if (subject && subject.responsible) {
              assignments = assignments.concat({ id: generateId(task.id, subject.responsible.id), event: task.id, resource: arxs.getStableId(OriginModuleEnum.Employee, subject.responsible.id), })
            }

            if (task.children) {
              task.children.map(childTask => getAssignmentFromTask(childTask));
            }
          }
        }

        for (const task of (tasks || [])) {
          getAssignmentFromTask(task);
        }

        return assignments;
      };

      setTasks(getTasks());
      setAssignments(getAssignments());
      setResources(getResources());

      setGanttConfig({
        // taskRenderer: taskRenderer, 
        columns:[
          {
            type: 'name',
            width: '350px',
            renderer: ({ record }) => renderTaskName(record)
          },
          { type: 'startdate', format: 'YYYY-MM-DD', width: '100px' },
          { type: 'enddate', format: 'YYYY-MM-DD', width: '100px' },
          { type: 'duration', width: '100px' },
          {
            type: 'resourceassignment',
            text: 'Verantwoordelijke',
            width: 100,
            showAvatars: true,
            editor: false
          }
        ],
        viewPreset: {
          base: "weekAndDayLetter",
        },
        contextMenuFeature: false,
        headerMenuFeature: false,
        headerContextMenuFeature: false,
        timeAxisHeaderMenuFeature: false,
        sortFeature: false,
        columnResizeFeature: false,
        taskMenuFeature: false,
        cellMenuFeature: false,
        cellEditFeature: false,
        taskDragFeature: false,
        timeRangesFeature: {
          showCurrentTimeLine: true
        },
        projectLinesFeature: false,
        readOnly: true,
        bufferCoef: 2,
        infiniteScroll: false,
        scrollTaskIntoViewOnCellClick: true
      });
    }

    const refresh = () => {
      setGanttProject();
    };

    refresh();
  }, [props.data, employees])

  const generateId = (projectId, childId) => arxs.getStableId(props.module, projectId, childId);

  if (!props.data || props.data.length === 0) {
    return [];
  };

  const taskRenderer = ({ taskRecord, renderData }) => {
    return;
  }

  const openDetailsPane = (event, card) => {
    props.onToggle(event, card);
  }

  const renderTaskName = (record) => {
    const objectId = record.data.link.id;
    const module = record.data.link.module;

    console.log(record);

    return <div>{record.data.name}</div>;

    // return <div className="gantt-task-card-container" onClick={(event) => openDetailsPane(event, { id: objectId, module })}>
    //   <Card
    //     objectId={objectId}
    //     module={module}
    //     mini
    //     readOnly />
    // </div>
  }

  return <GlobalContext.Consumer>
    {(context) => <div className="gantt-container">
      <BryntumGantt
        ref={ref}
        calendars = {[{
          id: 'general',
          intervals: [
            {
              recurrentStartDate: 'on Sat at 0:00',
              recurrentEndDate: 'on Mon at 0:00',
              isWorking: false
            }
          ]
        }]}
        tasks={tasks}
        assignments={assignments}
        resources={resources}
        {...ganttConfig}
      />
    </div>}
  </GlobalContext.Consumer>;
}